import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useTheme, useMediaQuery } from '@material-ui/core';
import styled, { css } from 'styled-components';

import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import TextBlock from './TextBlock';
import { TemplateType } from 'helpers/constants/templateType';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { getThemedColors } from 'services/contributions.service';

const activeTemplate = TemplateType.TemplateOne;
const DATE_VIEW = 'MMMM Do YYYY';

export const formatTime = date => {
  return moment(date).format(DATE_VIEW);
};

export const sortByStartTime = session => {
  const startTime = moment(session.startTime);
  return startTime.unix();
};

const EnrollmentBlock = ({ enrollment: { fromDate, toDate } }) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  const contribution = useContribution();
  const { themedColor, themedCardBackgroundColor } = getThemedColors(contribution);

  const EnrolDateMainDiv = styled.div``;
  const EnroleDateP = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  `;
  const MainDivDate = styled.div`
    display: flex;
  `;
  const DateStart = styled.div`
    ${({ activeTemplate }) =>
      activeTemplate &&
      css`
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #282b2b;
      `}
  `;
  const DateEnd = styled.div`
    ${({ activeTemplate }) =>
      activeTemplate &&
      css`
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #282b2b;
      `}
  `;
  return (
    <>
      {/* <EnrolDateMainDiv>
        <EnroleDateP>Enrollment Dates</EnroleDateP>
        <MainDivDate>
          <DateStart activeTemplate={true}>
            {formatTime(fromDate)} {'-'}
          </DateStart>
          <DateEnd activeTemplate={true}>{formatTime(toDate)}</DateEnd>
        </MainDivDate>
      </EnrolDateMainDiv> */}
      <Card
        style={{ backgroundColor: themedCardBackgroundColor, color: themedColor }}
        mobileView={mobileView}
        maxHeight
      >
        <CardHeader mobileView={mobileView}>
          <PageTitleSecond style={{ color: themedColor }} mobileView={mobileView}>
            Enrollment Dates
          </PageTitleSecond>
        </CardHeader>
        {formatTime(fromDate) === formatTime(toDate) ? (
          <CardBody mobileView={mobileView}>
            <Grid container>
              <Grid item md={6} sm={6} xs={12}>
                <TextBlock
                  style={{ color: themedColor }}
                  mobileView={mobileView}
                  image={null}
                  title="To"
                  text={formatTime(toDate)}
                />
              </Grid>
            </Grid>
          </CardBody>
        ) : (
          <CardBody mobileView={mobileView}>
            <Grid container spacing={2}>
              <Grid item md={6} sm={6} xs={12}>
                <TextBlock
                  style={{ color: themedColor }}
                  mobileView={mobileView}
                  image={null}
                  title="From"
                  text={formatTime(fromDate)}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <TextBlock
                  style={{ color: themedColor }}
                  mobileView={mobileView}
                  image={null}
                  title="To"
                  text={formatTime(toDate)}
                />
              </Grid>
            </Grid>
          </CardBody>
        )}
      </Card>
    </>
  );
};

EnrollmentBlock.propTypes = {
  enrollment: PropTypes.shape({
    fromDate: PropTypes.string,
    toDate: PropTypes.string,
  }).isRequired,
};

export default EnrollmentBlock;
