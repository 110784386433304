import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import CommunityList from 'components/Containers/CommunityList';
import { useDispatch, useSelector } from 'react-redux';
import useAccount from 'hooks/useAccount';
import { UserRoles } from 'helpers/constants';
import InfoIcon from '@material-ui/icons/Info';
import CommunityHeader from './Components/CommunityHeader';
import { COMMUNITIES_TYPES } from '../../constants';
import navigationIcon from '../../assets/submenu.svg';
import { toggleCommunitySidebar } from 'actions/community';
import { Box, useMediaQuery, useTheme, Tooltip, Typography } from '@material-ui/core';
import CommunityAllPostsSvg from 'components/Icons/CommunityAllPosts';

const SideBarContainer = styled.div`
  padding: 0px 8px;
`;

const CommunityHeaderContent = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledToggleButtonGroupContainer = styled.div`
  display: block;
`;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  display: flex !important;
  gap: 4px;
  width: 100% !important;
  padding-bottom: 8px;
  padding-top: 0px;
  max-height: 48px;
  justify-content: space-evenly;
`;

const StyledToggleButton = styled(ToggleButton)`
  text-transform: none !important;
  border-width: 0px !important;
  padding: 12px 0px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 22px;
  word-wrap: break-word;
  color: rgba(0, 0, 0, 0.87);

  &:hover {
    background-color: unset !important;
  }
  &.Mui-selected {
    background-color: unset !important;
    border-bottom: 2px solid #215c73 !important;
    border-radius: 0px;
    color: #215c73 !important;
    font-weight: 800 !important;
  }
`;

const StyledAllPostsContainer = styled.div`
  padding: 10px 8px;
  background: #f3f3f3;
  border-radius: 4px;
  display: flex;
  gap: 12px;
  justify-content: start;
  align-items: center;
  border-left: 4px solid #215c73;
  opacity: 1;
  img {
    margin-left: 0;
    margin-right: 0;
  }
`;

function CommunitiesSideBar({ dashboardLinkComponent, isLaunchPlan }) {
  const { currentRole, roles, user } = useAccount();
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const [containerHeight, setContainerHeight] = useState(0);
  const isCoach = currentRole === UserRoles.cohealer || currentRole === UserRoles.cohealerAssistant;
  const isCoachAndClientRolesAvailable = roles.includes(UserRoles.client) && roles.includes(UserRoles.cohealer);
  const showTabs =
    isCoachAndClientRolesAvailable &&
    (isLaunchPlan === false ||
      (isLaunchPlan === true && user?.isPartnerCoach) ||
      (isLaunchPlan === true && user?.havePaidTierPurchase));
  const [communityType, setCommunityType] = useState(showTabs ? 'All' : 'JoinedCommunities');
  const selector = state => state?.communities;
  const {
    totalUnreadPostCountAllCommunities,
    totalUnreadPostCountMyCommunities,
    totalUnreadPostCountJoinedCommunities,
  } = useSelector(selector);

  const getChip = count => {
    return (
      <Tooltip title={count} arrow>
        <Typography
          style={{
            backgroundColor: '#215C73',
            borderRadius: 10,
            fontSize: '10px',
            fontWeight: '900',
            height: '16px',
            color: 'white',
            padding: '1px 5px',
            textAlign: 'center',
          }}
        >
          {mobileView ? (count > 9 ? '9+' : count) : count > 99 ? '99+' : count}
        </Typography>
      </Tooltip>
    );
  };

  const calculateHeight = () => {
    setContainerHeight(containerRef.current?.offsetHeight || 0);
  };

  useEffect(() => {
    calculateHeight();
    window.addEventListener('resize', calculateHeight);
    return () => window.removeEventListener('resize', calculateHeight);
  }, [containerRef.current]);

  return (
    <SideBarContainer>
      <div ref={containerRef}>
        <CommunityHeader>
          <CommunityHeaderContent>
            {!mobileView && (
              <Box
                borderRadius="50%"
                alignSelf=",userflex-start"
                left={22}
                top={22}
                position="absolute"
                sx={{ cursor: 'pointer', transition: 'top 4s ease-in-out' }}
                onClick={() => {
                  dispatch(toggleCommunitySidebar());
                }}
              >
                <Tooltip title="Access Main Menu" arrow>
                  <img style={{ cursor: 'pointer' }} width="28px" src={navigationIcon} />
                </Tooltip>
              </Box>
            )}
            {dashboardLinkComponent}
          </CommunityHeaderContent>
        </CommunityHeader>
        {showTabs && (
          <StyledToggleButtonGroupContainer>
            <StyledToggleButtonGroup
              color="primary"
              value={communityType}
              exclusive
              onChange={(e, newVal) => {
                if (newVal !== null) setCommunityType(newVal);
              }}
            >
              <StyledToggleButton value="All">
                <div style={{ display: 'flex', gap: '4px', alignItems: 'center', fontFamily: 'Avenir' }}>
                  All
                  {totalUnreadPostCountAllCommunities > 0 && getChip(totalUnreadPostCountAllCommunities)}
                </div>
              </StyledToggleButton>
              <StyledToggleButton value="MyCommunities">
                <div style={{ display: 'flex', gap: '4px', alignItems: 'center', fontFamily: 'Avenir' }}>
                  {COMMUNITIES_TYPES.MyCommunities}
                  {totalUnreadPostCountMyCommunities > 0 && getChip(totalUnreadPostCountMyCommunities)}
                </div>
              </StyledToggleButton>
              <StyledToggleButton value="JoinedCommunities">
                <div style={{ display: 'flex', gap: '4px', alignItems: 'center', fontFamily: 'Avenir' }}>
                  {COMMUNITIES_TYPES.JoinedCommunities}
                  {totalUnreadPostCountJoinedCommunities > 0 && getChip(totalUnreadPostCountJoinedCommunities)}
                </div>
              </StyledToggleButton>
            </StyledToggleButtonGroup>
          </StyledToggleButtonGroupContainer>
        )}
        {communityType === 'All' && (
          <StyledAllPostsContainer>
            <CommunityAllPostsSvg width="40px" height="40px" />
            <span style={{ color: 'rgba(0, 0, 0, 0.87),', fontWeight: '500', fontSize: '18px', lineHeight: '22px' }}>
              All Posts
            </span>
            <Tooltip
              title={
                <div style={{ textAlign: 'center' }}>
                  This feed includes posts from all communities you have created or joined.
                </div>
              }
              arrow
            >
              <InfoIcon style={{ cursor: 'pointer' }} htmlColor="#C9B382" fontSize="small" />
            </Tooltip>
          </StyledAllPostsContainer>
        )}
        <span
          style={{
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '18px',
            wordWrap: 'break-word',
            color: 'rgba(0, 0, 0, 0.5)',
            display: 'block',
            paddingTop: '8px',
            paddingBottom: '0px',
          }}
        >
          To access individual communities, click below.
        </span>
      </div>
      <CommunityList communityType={communityType} isCoach={isCoach} containerHeight={containerHeight} />
    </SideBarContainer>
  );
}

CommunitiesSideBar.propTypes = {
  dashboardLinkComponent: PropTypes.node.isRequired,
};

export default CommunitiesSideBar;
