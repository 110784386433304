import React, { useState, useEffect } from 'react';
import CoachImage from 'assets/chatlogo.png';
import { useHttp, useAccount } from 'hooks';
import { redirectTo } from 'services/links';
import Modal from 'components/UI/Modal';
import { connect, useDispatch } from 'react-redux';
import Loader from 'components/UI/Loader';
import Grid from '@material-ui/core/Grid';
import * as contributionActions from 'actions/contributions';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import Button from 'components/FormUI/Button';
import TemplateOne from 'assets/congratulations.png';
import styled from 'styled-components';
import { UpdateUserProgressBarData } from 'services/user.service';
import { updateUser } from 'actions/update-user';
import { useLocation, useParams } from 'react-router-dom';
import * as userActions from 'actions/user';
import { colors } from 'utils/styles';
import InfoIcon from '@material-ui/icons/Info';
import { TOOLTIP } from '../../../../../constants';
import Tooltip from '@material-ui/core/Tooltip';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Joyride, {
  CallBackProps,
  STATUS,
  Step,
  EVENTS,
  StoreHelpers,
  BeaconRenderProps,
  TooltipRenderProps,
} from 'react-joyride';
import '../../Payment.scss';
import { getLatestApproved, getUpcomingCreated } from 'services/contributions.service';
import { useHistory } from 'react-router-dom';
const StyledImgCongrats = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
  display: inline-block;
  // border-radius: 40px;
  align-items: center;
  z-index: 1;
`;
const StyledImg = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 40px;
  align-items: center;
  z-index: 1;
`;
const VerificationCard = ({ setShowLoader = () => {} }) => {
  const { state } = useLocation();
  const { request, loading } = useHttp();
  const { user } = useAccount();
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down('md'));

  if (loading) {
    return <Loader />;
  }

  const onVerificate = () => {
    if (user.isStandardAccount) {
      request(`api/Payment/generate-account-verification-link?forStandardAccount=true`, 'POST').then(({ link }) => {
        redirectTo(link);
      });
    } else {
      request(`api/Payment/generate-account-verification-link`, 'POST').then(({ link }) => {
        redirectTo(link);
      });
    }
    userActions.getProfile(user.id, dispatch);
  };

  let joyRideHelpers;

  const togglePopup = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <Grid item xs={10} classes={{ root: 'card-container' }}>
        <Card id={'verify'} maxHeight>
          <CardHeader>
            <PageTitleSecond>Payment processor verification</PageTitleSecond>
          </CardHeader>
          <CardBody className="card-body-container">
            <p className="card-body-container__text">
              {!user.isStandardAccount && !user.transfersEnabled
                ? 'To make sales, finish setting up your payment processor.'
                : user.isStandardAccount && !user.standardAccountTransfersEnabled
                ? 'To make sales, finish setting up your payment processor.'
                : 'Your information has been verified!'}
            </p>
            {(!user.isStandardAccount && !user.transfersEnabled) ||
              (user.isStandardAccount && !user.standardAccountTransfersEnabled && (
                <Grid container justify={downMd ? 'flex-start' : 'flex-end'} lg={7} sm={12}>
                  <Button autoWidth variant="primary" onClick={onVerificate}>
                    Verify
                  </Button>
                  <Tooltip
                    style={{ marginTop: '10px', marginLeft: '10px' }}
                    title="Please complete your stripe account verification."
                    arrow
                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                  >
                    <InfoIcon htmlColor={colors.lightBrown} />
                  </Tooltip>
                </Grid>
              ))}
          </CardBody>
        </Card>
        <Modal
          title=""
          isOpen={showModal}
          onCloseText="OK"
          hiddenCancel
          onSubmit={togglePopup}
          onCancel={togglePopup}
          disableFooter
        >
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <StyledImgCongrats previewImg={TemplateOne}></StyledImgCongrats>
            <div style={{ fontSize: '24px', color: '#215C73', fontWeight: 800 }}>CONGRATS!</div>
            <div
              style={{ color: '#A58B62', fontSize: '16px', marginTop: '13px', fontWeight: 450, fontFamily: 'Avenir' }}
            >
              You are fully set up & ready to transform lives!
            </div>
            <Button
              autoWidth
              backgroundColor={'#C9B382'}
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                marginBottom: '10px',
              }}
              onClick={togglePopup}
            >
              Let’s do this!
            </Button>
          </div>
        </Modal>
      </Grid>
    </>
  );
};

export default VerificationCard;
