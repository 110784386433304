import Modal from 'components/UI/Modal';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import '../ApplicationForm.scss';
import Select from 'components/UI/Select';

export const DEFAULT_SELECETED_TEMPLATE = 'no-template-selected';

function ApplicationTemplateModal({ isOpen, forms, onClose, onSubmit, onBack }) {
  const [formTemplate, setFormTemplate] = useState(DEFAULT_SELECETED_TEMPLATE);
  const [error, setError] = useState(null);
  const [isForm, setIsForm] = useState(false);
  useEffect(() => {
    if (Object.keys(forms).length == 0) {
      setIsForm(true);
    }
  }, []);
  return (
    <Modal
      className="application-template-modal"
      isOpen={isOpen}
      title="Select a Prior Application Form as Template"
      cancelTitle="Back"
      submitTitle="Next"
      isBackButton
      onCancel={() => {
        setFormTemplate(DEFAULT_SELECETED_TEMPLATE);
        onClose();
      }}
      onBack={() => {
        setFormTemplate(DEFAULT_SELECETED_TEMPLATE);
        setIsForm(false);
        onBack();
      }}
      onSubmit={() => {
        if (isForm) {
          return;
        }
        if (formTemplate === DEFAULT_SELECETED_TEMPLATE) {
          setError(true);
          return;
        }
        onSubmit(formTemplate);
      }}
    >
      <Select
        fullWidth
        className={`select ${formTemplate === DEFAULT_SELECETED_TEMPLATE ? 'default' : 'null'}`}
        margin="dense"
        defaultIcon={true}
        value={formTemplate}
        onChange={({ target: { value } }) => {
          setFormTemplate(value);
        }}
        items={[{ title: 'Select Prior Template', value: DEFAULT_SELECETED_TEMPLATE, disabled: true }].concat(
          forms.map(form => {
            return {
              title: form.contributionName,
              value: form,
            };
          }),
        )}
      />
      {error && <span className="text-danger">Please select a template to continue</span>}
      {isForm && (
        <span className="text-danger">
          There is no Service with an Application form attached, Please create your Application form from scratch.
        </span>
      )}
    </Modal>
  );
}

ApplicationTemplateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  forms: PropTypes.shape([]).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default ApplicationTemplateModal;
