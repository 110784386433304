import React from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Button from 'components/FormUI/Button';
import styled from 'styled-components';
import '../Banner.scss';
import moment from 'moment';

const StyledButton = styled(Button)`
  background-color: #0e536b;
  padding: 6px 14px;
  min-width: 95px;
  width: 95px;
  font-size: 12px;
  font-weight: 700;
`;
const SubscriptionCanceledBanner = ({ data, handleNoShowBanner = () => {} }) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const BannerButton = mobileView ? StyledButton : Button;
  return (
    <>
      {data?.map(d => (
        <div key={d.id} className={`banner-container ${mobileView && 'banner-container__mobile'}`}>
          <p className="banner-container__text">
            {`${d.clientName} has cancelled their subscription to ${
              d.contributionName
            }. Their access will end on ${moment(d.currentPeriodEnd).format('MM/DD/YYYY')}.`}
          </p>
          <BannerButton
            variant="primary"
            className="banner-container__button"
            autoWidth
            onClick={() => handleNoShowBanner({ id: d.id, isViewedByCoach: true })}
          >
            OK
          </BannerButton>
        </div>
      ))}
    </>
  );
};

export default SubscriptionCanceledBanner;
