import React from 'react';

const RecurringCircleIcon = ({ width = '17', height = '17', color = '#215C73' }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.41304 11.3791C2.60344 9.43354 2.63544 6.38394 4.51544 4.49034C5.25211 3.74479 6.21022 3.2576 7.24664 3.10154L7.19144 1.43994C5.73636 1.61487 4.38355 2.27788 3.35384 3.32074C0.834643 5.85674 0.804242 9.95194 3.25544 12.5455L1.86264 13.9471L6.27064 14.1879L6.25864 9.52074L4.41304 11.3791ZM9.73064 1.81194L9.74264 6.47914L11.5882 4.62154C13.3978 6.56874 13.3658 9.61834 11.4858 11.5103C10.7492 12.2559 9.79106 12.7431 8.75464 12.8991L8.80984 14.5599C10.2647 14.3842 11.6175 13.7216 12.6482 12.6799C15.1666 10.1423 15.197 6.04714 12.7458 3.45514L14.1386 2.05194L9.73064 1.81194Z"
        fill="white"
      />
    </svg>
  );
};

export default RecurringCircleIcon;
