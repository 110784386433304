import React from 'react';

const DropdownIcon = ({ width = '10', height = '7', color = '#213649', availability = false }) => {
  return availability === false ? (
    <svg width={width} height={height} viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L5 5L9 1" stroke={color} stroke-width="1.5" stroke-linecap="round" />
    </svg>
  ) : (
    <svg
      viewBox="0 0 24 24"
      role="presentation"
      className="MuiSelect-icon"
      style={{ width: '1.5rem', height: '1.5rem', marginRight: '9px' }}
    >
      <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" style={{ fill: 'black' }} />
    </svg>
  );
};

export default DropdownIcon;
