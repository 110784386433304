import React from 'react';

const CircleSvg = ({ fill, isContributions = false }) => {
  return (
    <svg
      width={isContributions ? '12' : '8'}
      height={isContributions ? '12' : '8'}
      viewBox="0 0 8 8"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="4" r="4" fill={fill} />
    </svg>
  );
};

export default CircleSvg;
