import React from 'react';

const CommunityLikeSvg = ({ width = '24px', height = '24px', color = '#215C73' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 17" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.73776 1.48829C10.8505 0.492856 12.3021 -0.0388038 13.7945 0.00248117C15.2869 0.0437662 16.7069 0.654863 17.7629 1.71029C18.8177 2.76498 19.4291 4.1832 19.4719 5.67424C19.5146 7.16528 18.9853 8.61619 17.9926 9.72954L9.73582 17.998L1.48094 9.72954C0.48706 8.61561 -0.0426743 7.16346 0.000492064 5.67122C0.0436584 4.17898 0.65645 2.75988 1.71306 1.70526C2.76967 0.650642 4.18993 0.0405313 5.68225 0.000183178C7.17456 -0.0401649 8.62571 0.492311 9.73776 1.48829ZM16.3841 3.08611C15.6805 2.38366 14.7348 1.97691 13.741 1.94922C12.7471 1.92152 11.7803 2.27497 11.0386 2.93713L9.73874 4.1036L8.4379 2.93811C7.69953 2.27784 6.73733 1.92372 5.7471 1.94779C4.75686 1.97187 3.81301 2.37233 3.1076 3.0677C2.40218 3.76306 1.98822 4.70107 1.94994 5.69086C1.91166 6.68064 2.25194 7.64783 2.90154 8.39559L9.73679 15.2416L16.572 8.39657C17.2189 7.6521 17.5593 6.68994 17.5244 5.70431C17.4895 4.71868 17.082 3.78299 16.3841 3.08611Z"
        fill={color}
      />
    </svg>
  );
};

export default CommunityLikeSvg;
