import React from 'react';

const CommunityCommentSvg = ({ width = '24px', height = '24px', color = '#215C73' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.5 18C7.81886 18 6.17548 17.5015 4.77766 16.5675C3.37984 15.6335 2.29037 14.306 1.64703 12.7528C1.00368 11.1996 0.835356 9.49057 1.16333 7.84173C1.4913 6.1929 2.30085 4.67834 3.4896 3.4896C4.67834 2.30085 6.1929 1.4913 7.84174 1.16333C9.49057 0.835355 11.1996 1.00368 12.7528 1.64703C14.306 2.29037 15.6335 3.37984 16.5675 4.77766C17.5015 6.17547 18 7.81886 18 9.5C18 10.9053 17.66 12.2304 17.0556 13.3977L18 18L13.3977 17.0556C12.2304 17.66 10.9044 18 9.5 18Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CommunityCommentSvg;
