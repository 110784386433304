import React, { useRef } from 'react';
import { useUnifiedCommunity } from 'hooks';
import { useEffect } from 'react';
import styled from 'styled-components';
import Chip from '@mui/material/Chip';
import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Select from 'components/FormUI/Select';
import { useState } from 'react';
import { SearchBarComponent } from '.';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import TuneIcon from '@mui/icons-material/Tune';
import SearchIcon from '@mui/icons-material/Search';
import { Box, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { colors } from 'utils/styles';
import List from '@mui/material/List';
import { StyledCloseIcon } from 'components/App/GettingStarted/GettingStarted.styles';
import { determineColorToUse } from 'services/contributions.service';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, CircularProgress } from '@material-ui/core';
import { determineDarkThemedColorToUse } from 'services/contributions.service';
import { Form, Formik } from 'formik';
import { lightOrDark } from 'utils/utils';
import { toggleCommunitySidebar } from 'actions/community';
import * as pageActions from 'actions/page';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const styles = makeStyles(theme => ({
  button: {
    color: 'gray',
    border: '1px solid gray',
    textTransform: 'None',
    fontSize: '14px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px solid gray',
        borderColor: 'gray',
      },
      '&:hover fieldset': {
        border: '1px solid gray',
        borderColor: 'gray',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid gray',
        borderColor: 'gray',
      },
    },
  },
}));

const NotifictaionIcon = styled.div`
  position: absolute;
  background-color: ${colors.goldenColor};
  width: 16px;
  height: 16px;
  border-radius: 10px;
  right: 0px;
  top: 0px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;
const StyledSearchBarCard = styled.div`
  width: 100%;
  max-width: 530px !important;
  height: 40px;
  background-color: ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? '' : '#ffffff')};
  display: flex;
  justify-content: ${({ mobileView, isSearchBarShown, normalCommunity }) =>
    mobileView && !isSearchBarShown && !normalCommunity ? 'space-between' : 'right'};
  align-items: center;

  ${({ normalCommunity }) =>
    normalCommunity &&
    `
  border-radius: 23px;
  `}
`;

const StyledModalHeader = styled.div`
  padding: 8px 20px;
  height: 45px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
`;

const KeywordsPanel = styled.div`
  width: ${({ mobileView }) => (mobileView ? '365px' : '380px')};
  color: whitesmoke;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  border-radius: 4px;
  padding: 5px 0px;
`;

const StyledKeyword = styled.div`
  color: ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? 'white' : 'black')};
  cursor: pointer;
  padding: 0px 15px;
  border-radius: 20px;
  margin-bottom: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  border-bottom: 1px solid #ededed;
  max-width: 338px;
  overflow: hidden;
  border-radius: 0;
  font-size: 14px;
`;

const StyledModalTitle = styled.span`
  font-size: 16px;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.19px;
  color: ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? 'white' : 'rgba(0, 0, 0, 0.87)')};
`;

const StyledSelect = styled(Select)`
  width: calc(100 % - 33px);
  border-color: #dbe7f2;
`;

const SearchBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const StyledModalFooter = styled.div`
  // border-top: 1px solid #e7e7e7;
  padding: 10px 20px;

  & button + button {
    margin-left: ${props => (!props.help ? '10px' : '0px')};
  }
`;

const StyledModalBody = styled.div`
  padding: 10px 20px;
  flex: 1;
  max-height: 70vh;
  /* overflow-y: scroll; */
  overflow: auto;

  ::-webkit-scrollbar {
    // width: 5px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #215c73;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: #215c73;
  }
`;

const StyledFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const FilterContainer = styled.div`
  ${({ mobileView }) =>
    !mobileView &&
    `
`}
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  justify-content: right;
`;
const FilterMenu = styled.div`
  width: 300px;
  background-color: ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? '#2D2F31' : '#ffffff')};
  box-shadow: 0 0px 10px 0px rgba(64, 60, 67, 0.16);
  position: absolute;
  bottom: -285px;
  right: ${({ isSearchBarShown }) => (isSearchBarShown ? '-40px' : '-88px')};
  z-index: 100;
`;

const SearchAndFilterButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  .styled-button {
    height: 40px;
    width: 40px;
    align-content: center;
    text-align: center;
    color: ${({ filterColor }) => `${filterColor} !important`};

    &:hover {
      box-shadow: ${({ filterBorderColor }) => `0px 1px 2px 0px ${filterBorderColor} !important`};
      border: 1px solid ${({ darkModeEnabled }) => (darkModeEnabled ? '#3a3a3a' : '#dbe7f2')};
      cursor: pointer;
      border-radius: 4px;
      background-color: ${({ darkModeEnabled }) =>
        darkModeEnabled ? 'rgba(50, 50, 50, 1)' : 'rgba(235, 235, 235, 1)'};
    }
  }
`;

const StyledAutoComplete = styled(Autocomplete)`
  .MuiOutlinedInput-root {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    gap: 5px;
  }
`;

const CustomTagDisplayHashtag = ({ option, onDelete, isDarkThemeApplied, numOfTags }) => {
  const giveLength = numOfTags => {
    if (numOfTags === 1) {
      return 24;
    } else if (numOfTags === 2) {
      return 8;
    } else {
      return 7;
    }
  };
  const maxLength = giveLength(numOfTags); // Set the maximum length for the displayed text
  const displayText =
    option?.length > maxLength
      ? option.substring(0, maxLength).toLowerCase() + '..' // Truncate the text
      : option;
  const chipStyle = {
    color: 'white',
    border: '1px solid white',
  };

  return <Chip label={displayText} onDelete={null} style={isDarkThemeApplied ? chipStyle : {}} />;
};

const CustomTagDisplay = ({ option, onDelete, isDarkThemeApplied, numOfTags }) => {
  const giveLength = numOfTags => {
    if (numOfTags === 1) {
      return 24;
    } else if (numOfTags === 2) {
      return 8;
    } else {
      return 7;
    }
  };
  const maxLength = giveLength(numOfTags); // Set the maximum length for the displayed text
  const displayText =
    option.title.length > maxLength
      ? option.title.substring(0, maxLength) + '..' // Truncate the text
      : option.title;
  const chipStyle = {
    color: 'white',
    border: '1px solid white',
  };

  return <Chip label={displayText} onDelete={null} style={isDarkThemeApplied ? chipStyle : {}} />;
};

function CustomOption({ label, selected, style }) {
  return <Typography style={{ fontWeight: selected ? '600' : '500' }}>{label}</Typography>;
}

const CustomListbox = props => {
  return <List style={{ maxHeight: '100px', overflow: 'auto' }} {...props} />;
};

const useStyles = makeStyles(theme => ({
  customAutocompleteDark: {
    '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
      width: '0px',
      minWidth: '0px !important',
      color: 'white !important',
    },
    '& .MuiOutlinedInput-root': {
      paddingRight: '30px !important',
    },
  },
  customAutocomplete: {
    '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
      width: '0px',
      minWidth: '0px !important',
    },
    '& .MuiOutlinedInput-root': {
      paddingRight: '30px !important',
    },
  },
  popupIndicator: {
    color: 'white !important',
  },
}));

const SearchAndFilter = ({
  keywordsLoaded,
  onSearchChange,
  keywords,
  normalCommunity,
  searchedWord,
  onKeywordClick,
  setFilterData,
  filterParams,
  notificationsCount,
  contribution,
  users,
  hashtags,
  communities,
  onCrossClick,
  isKeywordMenuOpen,
  selectedKeyWord,
  onClearFilter,
  onSubmitFilter,
}) => {
  const { isUnifiedCommunity } = useUnifiedCommunity();
  const [selectedOption, setSelectedOption] = useState(null);
  const theme = useTheme();
  const dispatch = useDispatch();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const participantsLoader = useSelector(state => state.contributions?.loadingParticipants);
  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);
  const colorToUse = determineColorToUse(activeContribution);
  const classNames = useStyles();
  const [isCommunityFocused, setIsCommunityFocused] = useState(false);
  const [isSearchBarShown, setIsSearchBarShown] = useState(false);
  const [isHashtagFocused, setIsHashtagFocused] = useState(false);
  const [isPeopleFocused, setIsPeopleFocused] = useState(false);
  const textColorBadge = lightOrDark(colorToUse.PrimaryColorCode);
  const classes = styles();
  const dropdownRef = useRef();
  const [byCommunityOptions, setByCommunityOptions] = useState([{ id: 'NONE', title: 'NONE' }]);
  const [selectedCommunities, setSelectedCommunities] = useState([]);
  const { isCommunitySidebarOpen: isCommunityOpen } = useSelector(state => state?.communities);
  const onFilterClick = () => {
    setIsFilterMenuOpen(!isFilterMenuOpen);
  };

  const handleChange = selectedOption => {
    setSelectedOption(selectedOption);
  };

  const handleOutsideClick = e => {
    if (!isFilterMenuOpen) {
      return;
    }
    if (
      dropdownRef?.current &&
      !dropdownRef?.current?.contains(e.target) &&
      !e?.target?.className?.includes('dropdown')
    ) {
      setIsFilterMenuOpen(false);
    }
  };

  useEffect(() => {
    const tempCommunityOptions = [...byCommunityOptions];
    if (communities && isUnifiedCommunity) {
      communities.map(communiy =>
        tempCommunityOptions.push({
          value: communiy.id,
          title: communiy.title,
        }),
      );
      setByCommunityOptions([...tempCommunityOptions]);
    }
  }, [communities]);
  // useEffect(() => {
  //   document.addEventListener('mousedown', handleOutsideClick);
  //   return () => {
  //     document.removeEventListener('mousedown', handleOutsideClick);
  //   };
  // });

  const Loader = () => {
    return (
      <Grid container justifyContent="center" className="mb-4 mt-4">
        <CircularProgress color="primary" />
      </Grid>
    );
  };

  const onClearClick = clear => {
    if (clear) {
      setFilterData({
        byPeople: [],
        byHashtag: [],
        byCommunity: [],
      });
      onClearFilter();
    } else {
      setIsFilterMenuOpen(!isFilterMenuOpen);
      setFilterData({
        byPeople: [],
        byHashtag: [],
        byCommunity: [],
      });
      onClearFilter();
    }
  };

  const generateHTMLText = (text, searchedWord) => {
    let newSentence;
    if (!!searchedWord?.length) {
      let matchPattern = text?.split(' ');
      newSentence = matchPattern?.map(word => {
        if (
          word.toLowerCase()?.includes(searchedWord.toLowerCase()) ||
          searchedWord.toLowerCase()?.includes(word.toLowerCase())
        ) {
          return `<span style=" font-weight: bold;">${word}</span>`;
        } else {
          return word;
        }
      });
      return newSentence.join(' ');
    }
  };
  const sortedUsers = users
    ?.map(item => ({
      title: item.title,
      value: item.value,
    }))
    .slice()
    .sort((a, b) => a.title.localeCompare(b.title));

  const filterColor = isDarkThemeEnabled
    ? 'white'
    : isUnifiedCommunity
    ? colors?.darkOceanBlue
    : colorToUse?.AccentColorCode;

  const filterBorderColor = isDarkThemeEnabled ? 'white' : isUnifiedCommunity ? '#DBE7F2' : colorToUse?.AccentColorCode;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '530px', alignItems: 'flex-end' }}>
      <StyledSearchBarCard
        normalCommunity={normalCommunity}
        isDarkThemeEnabled={isDarkThemeEnabled}
        isUnifiedCommunity={isUnifiedCommunity}
        mobileView={mobileView}
        isSearchBarShown={isSearchBarShown}
      >
        {mobileView && !isSearchBarShown && isUnifiedCommunity && (
          <Box
            onClick={() => {
              dispatch(pageActions.toggleMobileSidebar());
              if (!isCommunityOpen) dispatch(toggleCommunitySidebar());
            }}
            display="flex"
            alignItems="center"
            style={{ cursor: 'pointer', marginRight: '10px' }}
          >
            <ArrowBackIosIcon fontSize="medium" style={{ color: '#215C73' }} />{' '}
            <Typography style={{ color: '#215C73', fontSize: '14px', fontWeight: 800 }}>All Communities</Typography>
          </Box>
        )}
        {isSearchBarShown && (
          <SearchBarContainer mobileView={mobileView}>
            <SearchBarComponent
              placeholder="Search by Keywords or Hashtags"
              searchFilter={searchedWord}
              onSearchFilterChange={onSearchChange}
              onCrossClick={onCrossClick}
              onCrossIconClick={() => {
                setIsSearchBarShown(false);
              }}
              mobileView={mobileView}
            />
          </SearchBarContainer>
        )}
        <FilterContainer mobileView={mobileView}>
          {notificationsCount > 0 && (
            <NotifictaionIcon
              style={
                isUnifiedCommunity
                  ? { color: 'white' }
                  : { backgroundColor: colorToUse?.PrimaryColorCode, color: textColorBadge }
              }
            >
              {notificationsCount}
            </NotifictaionIcon>
          )}
          {isFilterMenuOpen && (
            <Formik
              initialValues={{
                byPeople: filterParams?.users,
                byHashtag: filterParams?.taggs,
                byCommunity: filterParams?.communities,

                unifiedCommunity: isUnifiedCommunity,
              }}
              onSubmit={values => {
                setIsFilterMenuOpen(!isFilterMenuOpen);
                onSubmitFilter(values);
              }}
            >
              {({ values, setFieldValue, handleSubmit }) => {
                return (
                  <Form className="dropdown" ref={dropdownRef}>
                    <FilterMenu
                      isSearchBarShown={isSearchBarShown}
                      className="dropdown"
                      isDarkThemeEnabled={isDarkThemeEnabled}
                    >
                      <StyledModalHeader className="dropdown">
                        <StyledModalTitle isDarkThemeEnabled={isDarkThemeEnabled}>Filter By:</StyledModalTitle>
                        <StyledCloseIcon onClick={() => setIsFilterMenuOpen(!isFilterMenuOpen)} />
                      </StyledModalHeader>{' '}
                      <StyledModalBody className="filter-menu-body dropdown">
                        {!isUnifiedCommunity && (
                          <Grid item sm={12} xs={12} className="dropdown">
                            {/* <p style={{ margin: 2, color: isDarkThemeEnabled ? 'white' : colorToUse?.AccentColorCode }}>
                            By People
                          </p> */}
                            {/* <StyledSelect
                            name="byPeople"
                            className="dropdown"
                            value={values.byPeople}
                            disabled={false}
                            searchEnabled={true}
                            variant="outlined"
                            multiple
                            input={<OutlinedInput color="#DBE7F2" margin="dense" />}
                            fullWidth
                            items={[{ title: 'NONE', value: 'NONE' }].concat(sortedUsers)}
                            biggerTiles={true}
                            onChange={e => {
                              let values = e.target.value;
                              if (values.length > 1 && values.includes('NONE')) {
                                const firstElement = values[0];
                                const lastElement = values[values.length - 1];
                                if (firstElement === 'NONE') {
                                  values = values.filter(val => val != 'NONE');
                                } else if (lastElement === 'NONE') {
                                  values = values.filter(val => val === 'NONE');
                                } else {
                                  values = values;
                                }
                              }
                              setFieldValue('byPeople', values);
                            }}
                          /> */}
                            {participantsLoader ? (
                              <>
                                <CircularProgress size={'20px'} color="primary" />
                              </>
                            ) : (
                              <StyledAutoComplete
                                multiple
                                limitTags={1}
                                clearIcon={e => {}}
                                error={false}
                                name="byPeople"
                                options={users}
                                disableCloseOnSelect
                                value={values.byPeople}
                                getOptionLabel={item => item.title}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    onFocus={() => setIsPeopleFocused(true)}
                                    onBlur={() => setIsPeopleFocused(false)}
                                    placeholder={values.byPeople.length === 0 && 'Select People'}
                                    style={
                                      !isUnifiedCommunity && isDarkThemeEnabled
                                        ? { border: '1px solid white', borderRadius: '4px', color: 'white' }
                                        : {}
                                    }
                                  />
                                )}
                                sx={{ width: '100%' }}
                                onChange={(e, newValue) => {
                                  let values = newValue;
                                  if (values.length > 1 && values.some(e => e.value === 'NONE')) {
                                    const firstElementValue = values[0].value;
                                    const lastElementValue = values[values.length - 1].value;
                                    if (firstElementValue === 'NONE') {
                                      values = values.filter(val => val.value !== 'NONE');
                                    } else if (lastElementValue === 'NONE') {
                                      values = values.filter(val => val.value === 'NONE');
                                    } else {
                                      values = values;
                                    }
                                  }
                                  if (values.length === 0) {
                                    setFieldValue('byPeople', []);
                                  } else {
                                    setFieldValue('byPeople', values);
                                  }
                                }}
                                renderTags={(value, getTagProps) => {
                                  const numOfTags = value.length;
                                  const limitTags = 2;
                                  return (
                                    <>
                                      {value.slice(0, limitTags).map((option, index) => (
                                        <CustomTagDisplay
                                          key={option.title}
                                          option={option}
                                          {...getTagProps({ index })}
                                          isDarkThemeApplied={!isUnifiedCommunity && isDarkThemeEnabled}
                                          numOfTags={numOfTags}
                                        />
                                      ))}
                                      <div
                                        style={
                                          !isUnifiedCommunity && isDarkThemeEnabled
                                            ? { color: 'white', marginLeft: '2px' }
                                            : { marginLeft: '2px' }
                                        }
                                      >
                                        {numOfTags > limitTags && ` +${numOfTags - limitTags}`}
                                      </div>
                                    </>
                                  );
                                }}
                                className={
                                  isDarkThemeEnabled ? classNames.customAutocompleteDark : classNames.customAutocomplete
                                }
                                classes={{
                                  popupIndicator:
                                    !isUnifiedCommunity && isDarkThemeEnabled ? classNames.popupIndicator : {},
                                }}
                                renderOption={(props, option, { selected }) => (
                                  <li {...props} style={{ borderBottom: '1px solid #e7e7e7' }}>
                                    <CustomOption label={option.title} selected={selected} />
                                  </li>
                                )}
                              />
                            )}
                          </Grid>
                        )}

                        <Grid style={{ marginTop: '10px' }} item sm={12} xs={12} className="dropdown">
                          {/* <p style={{ margin: 2, color: isDarkThemeEnabled ? 'white' : colorToUse?.AccentColorCode }}>
                          By Hashtags
                        </p> */}
                          {/* <StyledSelect
                          name="byHashtag"
                          className="dropdown"
                          value={values.byHashtag}
                          disabled={false}
                          searchEnabled={true}
                          variant="outlined"
                          multiple
                          input={<OutlinedInput color="#DBE7F2" margin="dense" />}
                          fullWidth
                          items={['NONE'].concat(hashtags)?.map(item => ({
                            title: item,
                            value: item,
                          }))}
                          biggerTiles={true}
                          SearchActive={true}
                          onChange={e => {
                            let values = e.target.value;
                            if (values.length > 1 && values.includes('NONE')) {
                              const firstElement = values[0];
                              const lastElement = values[values.length - 1];
                              if (firstElement === 'NONE') {
                                values = values.filter(val => val != 'NONE');
                              } else if (lastElement === 'NONE') {
                                values = values.filter(val => val === 'NONE');
                              } else {
                                values = values;
                              }
                            }
                            setFieldValue('byHashtag', values);
                          }}
                        /> */}
                          <StyledAutoComplete
                            multiple
                            limitTags={1}
                            clearIcon={e => {}}
                            error={false}
                            name="byHashtag"
                            options={hashtags}
                            disableCloseOnSelect
                            value={values.byHashtag}
                            getOptionLabel={item => item}
                            renderInput={params => (
                              <TextField
                                {...params}
                                onFocus={() => setIsHashtagFocused(true)}
                                onBlur={() => setIsHashtagFocused(false)}
                                placeholder={values.byHashtag.length === 0 && 'Select Hashtags'}
                                style={
                                  !isUnifiedCommunity && isDarkThemeEnabled
                                    ? {
                                        border: '1px solid white',
                                        borderRadius: '4px',
                                      }
                                    : {}
                                }
                              />
                            )}
                            sx={{ width: '100%' }}
                            onChange={(e, newValue) => {
                              let values = newValue;
                              if (values.length > 1 && values.includes('NONE')) {
                                const firstElement = values[0];
                                const lastElement = values[values.length - 1];
                                if (firstElement === 'NONE') {
                                  values = values.filter(val => val != 'NONE');
                                } else if (lastElement === 'NONE') {
                                  values = values.filter(val => val === 'NONE');
                                } else {
                                  values = values;
                                }
                              }
                              if (values.length === 0) {
                                setFieldValue('byHashtag', []);
                              } else {
                                setFieldValue('byHashtag', values);
                              }
                            }}
                            renderTags={(value, getTagProps) => {
                              const numOfTags = value.length;
                              const limitTags = 2;
                              return (
                                <>
                                  {value.slice(0, limitTags).map((option, index) => (
                                    <CustomTagDisplayHashtag
                                      key={option?.title}
                                      option={option}
                                      {...getTagProps({ index })}
                                      isDarkThemeApplied={!isUnifiedCommunity && isDarkThemeEnabled}
                                      numOfTags={numOfTags}
                                    />
                                  ))}
                                  <div
                                    style={
                                      !isUnifiedCommunity && isDarkThemeEnabled
                                        ? { color: 'white', marginLeft: '2px' }
                                        : { marginLeft: '2px' }
                                    }
                                  >
                                    {numOfTags > limitTags && ` +${numOfTags - limitTags}`}
                                  </div>
                                </>
                              );
                            }}
                            className={
                              isDarkThemeEnabled ? classNames.customAutocompleteDark : classNames.customAutocomplete
                            }
                            classes={{
                              popupIndicator:
                                !isUnifiedCommunity && isDarkThemeEnabled ? classNames.popupIndicator : {},
                            }}
                            renderOption={(props, option, { selected }) => (
                              <li
                                {...props}
                                style={
                                  !isUnifiedCommunity && isDarkThemeEnabled
                                    ? {
                                        borderBottom: '1px solid #e7e7e7',
                                        background: 'rgba(0, 0, 0, 0.87)',
                                        color: 'white',
                                      }
                                    : { borderBottom: '1px solid #e7e7e7' }
                                }
                              >
                                <CustomOption
                                  style={
                                    !isUnifiedCommunity && isDarkThemeEnabled
                                      ? { background: 'rgba(0, 0, 0, 0.87)', color: 'white' }
                                      : {}
                                  }
                                  label={option}
                                  selected={selected}
                                />
                              </li>
                            )}
                          />
                        </Grid>
                        {isUnifiedCommunity && (
                          <Grid style={{ marginTop: '10px' }} item sm={12} xs={12} className="dropdown">
                            {/* <p style={{ margin: 2, color: isDarkThemeEnabled ? 'white' : colorToUse?.AccentColorCode }}>
                            By Communities
                          </p> */}
                            {/* <StyledSelect
                            name="byCommunity"
                            className="dropdown"
                            value={values.byCommunity}
                            disabled={false}
                            variant="outlined"
                            searchEnabled={true}
                            multiple
                            input={<OutlinedInput color="#DBE7F2" margin="dense" />}
                            fullWidth
                            items={[{ id: 'NONE', title: 'NONE' }].concat(communities)?.map(item => ({
                              title: item.title,
                              value: item.id,
                            }))}
                            biggerTiles={true}
                            onChange={e => {
                              let values = e.target.value;
                              if (values.length > 1 && values.includes('NONE')) {
                                const firstElement = values[0];
                                const lastElement = values[values.length - 1];
                                if (firstElement === 'NONE') {
                                  values = values.filter(val => val != 'NONE');
                                } else if (lastElement === 'NONE') {
                                  values = values.filter(val => val === 'NONE');
                                } else {
                                  values = values;
                                }
                              }
                              setFieldValue('byCommunity', values);
                            }}
                          /> */}
                            <StyledAutoComplete
                              multiple
                              limitTags={1}
                              clearIcon={e => {}}
                              error={false}
                              name="byCommunity"
                              options={communities}
                              disableCloseOnSelect
                              value={values.byCommunity}
                              getOptionLabel={item => item.title}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  onFocus={() => setIsCommunityFocused(true)}
                                  onBlur={() => setIsCommunityFocused(false)}
                                  placeholder={values?.byCommunity.length === 0 && 'Select Communities'}
                                  style={
                                    !isUnifiedCommunity && isDarkThemeEnabled
                                      ? { border: '1px solid white', borderRadius: '4px' }
                                      : {}
                                  }
                                />
                              )}
                              sx={{ width: '100%' }}
                              onChange={(e, newValue) => {
                                let values = newValue;
                                if (values.length > 1 && values.some(e => e.id === 'NONE')) {
                                  const firstElementId = values[0].id;
                                  const lastElementId = values[values.length - 1].id;
                                  if (firstElementId === 'NONE') {
                                    values = values.filter(val => val.id !== 'NONE');
                                  } else if (lastElementId === 'NONE') {
                                    values = values.filter(val => val.id === 'NONE');
                                  } else {
                                    values = values;
                                  }
                                }
                                if (values.length === 0) {
                                  setFieldValue('byCommunity', []);
                                } else {
                                  setFieldValue('byCommunity', values);
                                }
                              }}
                              renderTags={(value, getTagProps) => {
                                const numOfTags = value.length;
                                const limitTags = 2;

                                return (
                                  <>
                                    {value.slice(0, limitTags).map((option, index) => (
                                      <CustomTagDisplay
                                        key={option.title}
                                        option={option}
                                        {...getTagProps({ index })}
                                        isDarkThemeApplied={!isUnifiedCommunity && isDarkThemeEnabled}
                                        numOfTags={numOfTags}
                                      />
                                    ))}
                                    <div
                                      style={
                                        !isUnifiedCommunity && isDarkThemeEnabled
                                          ? { color: 'white', marginLeft: '2px' }
                                          : { marginLeft: '2px' }
                                      }
                                    >
                                      {numOfTags > limitTags && ` +${numOfTags - limitTags}`}
                                    </div>
                                  </>
                                );
                              }}
                              className={classNames.customAutocomplete}
                              classes={{
                                popupIndicator:
                                  !isUnifiedCommunity && isDarkThemeEnabled ? classNames.popupIndicator : {},
                              }}
                              renderOption={(props, option, { index, selected }) => (
                                <li
                                  {...props}
                                  style={
                                    !isUnifiedCommunity && isDarkThemeEnabled
                                      ? {
                                          borderBottom: '1px solid #e7e7e7',
                                          background: 'rgba(0, 0, 0, 0.87)',
                                          color: 'white',
                                        }
                                      : { borderBottom: '1px solid #e7e7e7' }
                                  }
                                >
                                  <CustomOption
                                    style={
                                      !isUnifiedCommunity && isDarkThemeEnabled
                                        ? { background: 'rgba(0, 0, 0, 0.87)', color: 'white' }
                                        : {}
                                    }
                                    label={option.title}
                                    selected={selected}
                                  />
                                </li>
                              )}
                              // PaperProps={{
                              //   style: {
                              //     maxHeight: '100px !important',
                              //     overflow: 'auto !important',
                              //   },
                              // }}
                            />
                          </Grid>
                        )}
                      </StyledModalBody>{' '}
                      <StyledModalFooter className="dropdown">
                        <StyledFooterContainer className="modal-footer-container dropdown">
                          <Button
                            style={
                              isDarkThemeEnabled
                                ? { color: 'white', border: '1.25px solid white' }
                                : { color: 'black', border: '1.25px solid black' }
                            }
                            className="filter-cancel-button"
                            onClick={() => {
                              onClearClick(false);
                            }}
                            variant="outlined"
                          >
                            Clear
                          </Button>
                          <Button
                            style={{ background: colorToUse?.PrimaryColorCode, color: 'white' }}
                            className="filter-submit-button"
                            type="submit"
                          >
                            Apply
                          </Button>
                        </StyledFooterContainer>
                      </StyledModalFooter>
                    </FilterMenu>
                  </Form>
                );
              }}
            </Formik>
          )}
          <SearchAndFilterButtons
            darkModeEnabled={!isUnifiedCommunity && isDarkThemeEnabled}
            filterColor={filterColor}
            filterBorderColor={filterBorderColor}
          >
            {!isSearchBarShown && (
              <div
                className="styled-button"
                onClick={() => {
                  setIsSearchBarShown(true);
                }}
              >
                <SearchIcon htmlColor={filterColor} fontSize={mobileView ? '10px' : '14px'} />
              </div>
            )}
            <div className="styled-button" onClick={onFilterClick}>
              <TuneIcon htmlColor={filterColor} fontSize={mobileView ? '10px' : '14px'} />
            </div>
          </SearchAndFilterButtons>
          {notificationsCount > 0 && (
            <div
              onClick={() => {
                onClearClick(true);
              }}
              style={{
                fontSize: '10px',
                cursor: 'pointer',
                position: 'absolute',
                bottom: -15,
                right: 7,
                fontWeight: 900,
                color: filterColor,
              }}
            >
              Clear
            </div>
          )}
        </FilterContainer>
      </StyledSearchBarCard>
      {isKeywordMenuOpen && (
        <>
          {keywordsLoaded ? (
            <>
              {keywords?.length > 0 ? (
                <KeywordsPanel mobileView={mobileView}>
                  {keywords?.map(item => (
                    <StyledKeyword
                      isDarkThemeEnabled={isDarkThemeEnabled}
                      dangerouslySetInnerHTML={{ __html: generateHTMLText(item, searchedWord) }}
                      onClick={() => onKeywordClick({ keyword: item, skip: 0 })}
                    />
                  ))}
                </KeywordsPanel>
              ) : (
                <KeywordsPanel mobileView={mobileView}>
                  <StyledKeyword isDarkThemeEnabled={isDarkThemeEnabled} onClick={() => {}}>
                    No search results found
                  </StyledKeyword>
                </KeywordsPanel>
              )}
            </>
          ) : (
            <KeywordsPanel mobileView={mobileView}>
              <StyledKeyword isDarkThemeEnabled={isDarkThemeEnabled}>Loading...</StyledKeyword>
            </KeywordsPanel>
          )}
        </>
      )}
    </div>
  );
};

export default SearchAndFilter;
