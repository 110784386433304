import {
  ONE_TO_ONE_AVALIBILTY,
  ADD_TO_CALENDAR,
  SCHEDULE_CLIENT,
  EXTRA_CALENDAR_SETTING,
  REMOVE_MASTER_CALENDAR_EMAIL,
  PREVIEW_MASTER_CALENDAR_EVENT,
  TOGGLE_MASTER_CALENDAR_SIDEBAR,
  MANAGE_MASTER_AVAILABILITY,
  MASTER_AVAILABILITY_GET_SLOTS,
  PRE_SELECTED_DATE,
} from 'actions/masterCalendarSidebar.actions';

const initialState = {
  sideBarActions: {
    updateOneToOneAvailability: false,
    addToCalendar: false,
    scheduleClient: false,
    extraCalendarSetting: null,
    removeMasterCalendarEmail: '',
    previewMasterCalendarEvent: null,
    isMasterCalendarSidebarOpen: false,
  },
  manageAvailabilitySelection: 'confirmed-session',
  selectedMasterCalendarContribution: null,
  selectedMasterCalendarDate: null,
};

const masterCalendarReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ONE_TO_ONE_AVALIBILTY:
      return { ...state, sideBarActions: { ...state.sideBarActions, updateOneToOneAvailability: payload } };
    case ADD_TO_CALENDAR:
      return { ...state, sideBarActions: { ...state.sideBarActions, addToCalendar: payload } };
    case SCHEDULE_CLIENT:
      return { ...state, sideBarActions: { ...state.sideBarActions, scheduleClient: payload } };
    case EXTRA_CALENDAR_SETTING:
      return { ...state, sideBarActions: { ...state.sideBarActions, extraCalendarSetting: payload } };
    case REMOVE_MASTER_CALENDAR_EMAIL:
      return { ...state, sideBarActions: { ...state.sideBarActions, removeMasterCalendarEmail: payload } };
    case PREVIEW_MASTER_CALENDAR_EVENT:
      return { ...state, sideBarActions: { ...state.sideBarActions, previewMasterCalendarEvent: payload } };
    case TOGGLE_MASTER_CALENDAR_SIDEBAR:
      return {
        ...state,
        sideBarActions: {
          ...state.sideBarActions,
          isMasterCalendarSidebarOpen: payload,
        },
      };
    case MANAGE_MASTER_AVAILABILITY:
      return {
        ...state,
        manageAvailabilitySelection: payload,
      };
    case PRE_SELECTED_DATE:
      return {
        ...state,
        selectedMasterCalendarDate: payload,
      };
    case MASTER_AVAILABILITY_GET_SLOTS:
      return {
        ...state,
        selectedMasterCalendarContribution: payload,
      };
    default:
      return state;
  }
};

export default masterCalendarReducer;
