import { TextField } from '@material-ui/core';
import Modal from 'components/UI/Modal';
import { Formik } from 'formik';
import PropTypes, { string } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import styled from 'styled-components';

const TITLE_LIMIT = 50;

const StyledTextArea = styled(TextField)`
  & .Mui-focused {
  .MuiOutlinedInput-notchedOutline {
    border-color: ${({ borderColor }) => borderColor};
  }
`;

const validationSchema = Yup.object().shape({
  contributionId: Yup.string().required(),
  title: Yup.string().max(TITLE_LIMIT).required('Title is a required field'),
});

const initialValues = {
  title: '',
};

function SectionModal({ contributionId, title, onSubmit, onCancel, isOpen, section, colorToUse }) {
  const formRef = useRef(null);
  const [submitTitle, setSubmitTitle] = useState('');

  useEffect(() => {
    setSubmitTitle(title?.toLowerCase().includes('create') ? 'Create' : 'Done');
  }, [title]);

  return (
    <Modal
      className="create-section-popup"
      isOpen={isOpen}
      title={title}
      applyTheming
      submitTitle={submitTitle}
      hiddenCancel
      onCancel={onCancel}
      onSubmit={() => formRef.current.handleSubmit()}
    >
      <Formik
        innerRef={formRef}
        initialValues={{ ...initialValues, contributionId, title: section?.sectionTitle ?? '' }}
        validationSchema={validationSchema}
        onSubmit={values => {
          onSubmit(values);
        }}
      >
        {({ setFieldValue, values, errors }) => (
          <>
            <StyledTextArea
              name="title"
              className="input-field"
              id="outlined-basic"
              margin="normal"
              variant="outlined"
              fullWidth
              autoFocus
              borderColor={colorToUse?.AccentColorCode}
              value={values.title}
              error={Boolean(errors.title)}
              placeholder="Title"
              onChange={e => setFieldValue('title', e.target.value.trimStart())}
              inputProps={{ maxLength: TITLE_LIMIT }}
            />
            <span className="modal-error-message">{errors?.title ? errors.title : null}</span>
          </>
        )}
      </Formik>
    </Modal>
  );
}

SectionModal.propTypes = {
  contributionId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  section: PropTypes.shape({ sectionTitle: string }).isRequired,
};

export default SectionModal;
