import { useCallback, useEffect, useState } from 'react';

const useMakeStickyOnScroll = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [stickyTop, setStickyTop] = useState(0);
  const [targetElement, setTargetElement] = useState(null);
  const [parentContainerId, setParentContainerId] = useState(null);

  const handleScroll = useCallback(() => {
    if (window.scrollY >= stickyTop) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  }, [stickyTop, targetElement]);

  useEffect(() => {
    if (isSticky && targetElement) {
      targetElement.style.position = 'sticky';
      targetElement.style.top = '10px';
      targetElement.style.zIndex = '100';
    } else if (targetElement) {
      targetElement.style.position = '';
      targetElement.style.top = '';
      targetElement.style.zIndex = '';
    }
  }, [isSticky, targetElement]);

  useEffect(() => {
    const parentContainer = document.getElementById(parentContainerId) ?? window;
    if (targetElement && parentContainer) {
      const offsetTop = targetElement.offsetTop - 10;
      setStickyTop(offsetTop);

      parentContainer.addEventListener('scroll', handleScroll);

      return () => {
        parentContainer.removeEventListener('scroll', handleScroll);
      };
    }

    return () => {};
  }, [targetElement, parentContainerId]);

  return {
    setTargetElement,
    setParentContainerId,
  };
};

export default useMakeStickyOnScroll;
