import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { FieldArray, getIn } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import { StyledSlider, StyledInput, Switch } from 'components/switch/style';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import './CustomQuestions.scss';
import Loader from 'components/UI/Loader';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';

const StyledTextField = styled(TextField)`
  .MuiInputBase-input.Mui-disabled {
    background-color: #dfe3e4;
    color: #4a4a4a;
  }
  .MuiOutlinedInput-input {
    font-family: 'Avenir';
    padding: 16px;
  }
`;

const StyledSelect = styled(Select)`
  .MuiSelect-select {
    font-family: 'Avenir';
    padding: 14.5px 14px;
  }
`;

const StyledFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledButtonOutlined = styled(Button)`
  border-radius: 5px !important;
  border: 1.25px solid black !important;
  text-transform: unset !important;
  color: black !important;
  min-width: 119px !important;
  font-size: 14px !important;
  font-weight: 800 !important;
`;

const StyledQuestionContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 24px;
`;

const defaultQuestionValue = {
  name: '',
  isOptional: false,
  questionType: 'Radio',
  options: [],
  isSaved: false,
};

const questionTypes = [
  { text: 'Phone Number', value: 'PhoneNo' },
  { text: 'Single Choice', value: 'Radio' },
  { text: 'Multiple Choice', value: 'CheckBox' },
  { text: 'Written Answer', value: 'Text' },
];

const TEXT_LIMIT = 1000;
const QUESTION_LIMIT = 40;

function CustomQuestions({
  customEnrollmentFormRef,
  values,
  className,
  customQuestionsFormRef,
  handleSubmit,
  isPhoneNumberAdded,
  isCoachNumberAdded,
}) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const hidePhoneOption = isPhoneNumberAdded && !isCoachNumberAdded;
  const [savingQuestions, setSavingQuestions] = useState(false);
  const [questionError, setQuestionError] = useState(false);
  useEffect(() => {
    if (!values) {
      return;
    }
    if (isPhoneNumberAdded && !isCoachNumberAdded) {
      if (
        customQuestionsFormRef.current?.values?.enrollmentForm?.enrollmentFormViewModel?.questions?.filter(
          question => question.isPhoneNumberAdded,
        )?.length !== 0
      ) {
        return;
      }
      const question = {
        name: 'Enter your Phone Number',
        questionType: 'PhoneNo',
        options: [],
        isOptional: false,
        isPhoneNumberAdded: true,
      };
      const questions =
        customQuestionsFormRef.current?.values?.enrollmentForm?.enrollmentFormViewModel?.questions || [];
      customQuestionsFormRef.current.setFieldValue('enrollmentForm.enrollmentFormViewModel.questions', [
        ...questions,
        question,
      ]);
    } else {
      const questions =
        customQuestionsFormRef.current?.values?.enrollmentForm?.enrollmentFormViewModel?.questions || [];
      customQuestionsFormRef.current.setFieldValue(
        'enrollmentForm.enrollmentFormViewModel.questions',
        questions.filter(question => !question?.isPhoneNumberAdded),
      );
    }
  }, [isPhoneNumberAdded, isCoachNumberAdded]);

  useEffect(() => {
    customQuestionsFormRef.current.setFieldValue(
      'enrollmentForm.enrollmentFormViewModel.isCustomEnrollmentFormAdded',
      values?.enrollmentForm?.isCustomEnrollmentFormAdded,
    );
    customQuestionsFormRef.current.setFieldValue(
      'enrollmentForm.isCustomEnrollmentFormAdded',
      values?.enrollmentForm?.isCustomEnrollmentFormAdded,
    );
  }, [values?.enrollmentForm]);

  const getSelected = value => {
    if (value) {
      return questionTypes.find(question => question.value === value)?.text;
    }
    return questionTypes.find(question => question.value === 'Radio')?.text;
  };

  const getPath = (index, value) => {
    return `enrollmentForm.enrollmentFormViewModel.questions[${index}].${value}`;
  };

  const getPlaceholder = question => {
    if (question.questionType === 'Email') {
      return 'Enter your email';
    }
    if (question.questionType === 'PhoneNo') {
      return 'Enter your phone number';
    }
    return 'Enter your question';
  };

  const handleDragEnd = (results, questions) => {
    const { source, destination } = results;
    if (!destination) return;

    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    const reOrderedQuestions = [...questions];
    const destinationQuestion = reOrderedQuestions[destination.index];
    const [removedSection] = reOrderedQuestions.splice(source.index, 1);
    reOrderedQuestions.splice(destination.index, 0, removedSection);

    customQuestionsFormRef.current.setFieldValue(
      'enrollmentForm.enrollmentFormViewModel.questions',
      reOrderedQuestions,
    );
  };

  const renderQuestion = (
    questions,
    question,
    index,
    errors,
    touched,
    setFieldValue,
    isValid,
    remove,
    parentProvided,
  ) => {
    return (
      <>
        <Droppable droppableId={`question-${index}`}>
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <StyledQuestionContainer>
                <span {...parentProvided.dragHandleProps}>
                  <DragIndicatorIcon className="drag-icon" />
                </span>
                <div className="question">
                  <div className="d-flex align-items-center" style={{ marginBottom: mobileView ? '15px' : '' }}>
                    <span className="question-title">
                      Question {index + 1} {question.isOptional ? '  (optional)' : ''}
                    </span>
                    <div className="optional-option">
                      <Switch className="switch" disabled={question.isPhoneNumberAdded}>
                        <StyledInput
                          disabled={question.isPhoneNumberAdded}
                          type="checkbox"
                          onClick={() => setFieldValue(getPath(index, 'isOptional'), !question.isOptional)}
                          checked={question.isOptional}
                        />
                        <StyledSlider disabled={question.isPhoneNumberAdded} className="slider round" />
                      </Switch>
                      <p>Optional</p>
                    </div>
                  </div>
                  <Grid container alignItems="center" spacing={1} className="question-container">
                    <Grid item xl={8} lg={8} md={8} sm={12} xs={12} style={{ position: 'relative' }}>
                      <StyledTextField
                        placeholder={getPlaceholder(question)}
                        value={question.name}
                        onChange={e => {
                          setFieldValue(getPath(index, 'name'), e.target.value);
                        }}
                        disabled={question.isPhoneNumberAdded}
                        fullWidth
                        variant="outlined"
                        id="margin-none"
                        inputProps={{ maxLength: TEXT_LIMIT }}
                        error={getIn(errors, getPath(index, 'name')) && getIn(touched, getPath(index, 'name'))}
                      />
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                      <StyledSelect
                        displayEmpty
                        disabled={question.isPhoneNumberAdded}
                        value={question.questionType}
                        renderValue={() => getSelected(question.questionType)}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        error={
                          getIn(errors, getPath(index, 'questionType')) &&
                          getIn(touched, getPath(index, 'questionType'))
                        }
                        onChange={e => {
                          setFieldValue(getPath(index, 'questionType'), e.target.value);
                          setFieldValue(getPath(index, 'options'), []);
                        }}
                      >
                        {questionTypes.map(type => {
                          if (hidePhoneOption && type.value === 'PhoneNo') {
                            return null;
                          }
                          return (
                            <MenuItem
                              style={{ display: 'flex', padding: '6px 16px', justifyContent: 'left' }}
                              value={type.value}
                            >
                              {type.text}
                            </MenuItem>
                          );
                        })}
                      </StyledSelect>
                    </Grid>
                  </Grid>
                  {getIn(errors, getPath(index, 'name')) && getIn(touched, getPath(index, 'name')) && (
                    <span className="question-error">{getIn(errors, getPath(index, 'name'))}</span>
                  )}

                  <div className="options-container">
                    {question.options &&
                      question.options.length > 0 &&
                      question.options.map((option, optionIndex) => {
                        return (
                          <>
                            <Grid className="option-container">
                              <Grid
                                xl={8}
                                lg={8}
                                md={8}
                                sm={12}
                                xs={12}
                                style={{ display: 'flex' }}
                                alignItems="center"
                              >
                                {question.questionType === 'Radio' && <Radio disabled />}
                                {question.questionType === 'CheckBox' && <Checkbox disabled />}
                                <StyledTextField
                                  placeholder="Add an option"
                                  value={option}
                                  onChange={e => {
                                    setFieldValue(`${getPath(index, 'options')}[${optionIndex}]`, e.target.value);
                                  }}
                                  fullWidth
                                  variant="outlined"
                                  id="margin-none"
                                  inputProps={{ maxLength: TEXT_LIMIT }}
                                  error={
                                    getIn(errors, `${getPath(index, 'options')}[${optionIndex}]`) &&
                                    getIn(touched, `${getPath(index, 'options')}[${optionIndex}]`)
                                  }
                                />
                                <IconButton
                                  onClick={() => {
                                    setFieldValue(
                                      getPath(index, 'options'),
                                      questions[index].options.filter(
                                        (origoption, origindex) => origindex !== optionIndex,
                                      ),
                                    );
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                            {getIn(errors, `${getPath(index, 'options')}[${optionIndex}]`) &&
                              getIn(touched, `${getPath(index, 'options')}[${optionIndex}]`) &&
                              getIn(errors, `${getPath(index, 'options')}[${optionIndex}]`)?.length > 3 && (
                                <>
                                  <Grid container spacing={2}>
                                    <Grid
                                      xl={8}
                                      lg={8}
                                      md={8}
                                      sm={12}
                                      xs={12}
                                      style={{ display: 'flex' }}
                                      className="question-error-container"
                                    >
                                      <Radio
                                        style={{
                                          visibility: 'hidden',
                                          paddingTop: 0,
                                          paddingBottom: 0,
                                        }}
                                      />
                                      <span className="question-error">
                                        {getIn(errors, `${getPath(index, 'options')}[${optionIndex}]`)}
                                      </span>
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                          </>
                        );
                      })}
                  </div>

                  {getIn(errors, `enrollmentForm.enrollmentFormViewModel.questions[${index}].options`) &&
                    getIn(getIn(touched, `enrollmentForm.enrollmentFormViewModel.questions[${index}].options`)) && (
                      <div className="text-danger my-2">
                        {typeof getIn(errors, `enrollmentForm.enrollmentFormViewModel.questions[${index}].options`) ===
                        'string'
                          ? getIn(errors, `enrollmentForm.enrollmentFormViewModel.questions[${index}].options`)
                          : ''}
                      </div>
                    )}

                  <Grid
                    container
                    className={`question-buttons-container ${question.options.length > 0 ? 'margin-top' : ''}`}
                  >
                    <Grid xs={6}>
                      {(question.questionType === 'Radio' || question.questionType === 'CheckBox') && (
                        <>
                          <Button
                            className="add-option"
                            onClick={() => {
                              setFieldValue(getPath(index, 'options'), [...question.options, '']);
                            }}
                          >
                            + Add an option
                          </Button>
                        </>
                      )}
                    </Grid>
                    <Grid xs={6} style={{ display: 'flex', flexWrap: 'wrap' }} justifyContent="flex-end">
                      <StyledButtonOutlined
                        variant="outlined"
                        disabled={question.isPhoneNumberAdded}
                        color="dark"
                        onClick={() => {
                          remove(index);
                          if (index === 0) {
                            setFieldValue('enrollmentForm.isCustomEnrollmentFormAdded', false);
                            setFieldValue('enrollmentForm.enrollmentFormViewModel.isCustomEnrollmentFormAdded', false);
                          }
                        }}
                      >
                        Delete
                      </StyledButtonOutlined>

                      <Button
                        className="save-question"
                        style={{
                          marginTop: mobileView ? '15px' : '0px',
                          marginLeft: '10px',
                          padding: mobileView ? '8px' : '0px',
                        }}
                        onClick={() => {
                          setSavingQuestions(true);

                          setTimeout(() => {
                            setSavingQuestions(false);
                            handleSubmit();
                            setFieldValue(getPath(index, 'isSaved'), isValid ? true : false);
                          }, 1000);
                        }}
                        disableRipple
                      >
                        {question?.isSaved ? 'Update' : 'Save'}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </StyledQuestionContainer>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </>
    );
  };

  return (
    <Grid container className={`custom-enrollment-questions ${className ?? ''}`} ref={customEnrollmentFormRef}>
      <Grid item xs={12}>
        <FieldArray name="enrollmentForm.enrollmentFormViewModel.questions">
          {fieldArrayProps => {
            const { push, remove, form } = fieldArrayProps;
            const {
              values: {
                enrollmentForm: {
                  enrollmentFormViewModel: { questions },
                },
              },
              errors,
              touched,
              setFieldValue,
              isValid,
            } = form;
            return (
              <>
                <DragDropContext onDragEnd={results => handleDragEnd(results, questions, setFieldValue)}>
                  <Droppable droppableId="questions" type="question">
                    {provided => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {savingQuestions && <Loader />}
                        {questions.map((question, index) => (
                          <>
                            <Draggable draggableId={`question-${index}`} key={`question-${index}`} index={index}>
                              {provided => (
                                <div {...provided.draggableProps} ref={provided.innerRef}>
                                  {renderQuestion(
                                    questions,
                                    question,
                                    index,
                                    errors,
                                    touched,
                                    setFieldValue,
                                    isValid,
                                    remove,
                                    provided,
                                  )}
                                </div>
                              )}
                            </Draggable>
                          </>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                {getIn(errors, 'enrollmentForm.enrollmentFormViewModel.questions') &&
                  getIn(getIn(touched, 'enrollmentForm.enrollmentFormViewModel.questions')) && (
                    <div className="text-danger my-2">
                      {typeof getIn(errors, 'enrollmentForm.enrollmentFormViewModel.questions') === 'string'
                        ? getIn(errors, 'enrollmentForm.enrollmentFormViewModel.questions')
                        : ''}
                    </div>
                  )}
                {questions.length === 0 && <div style={{ marginBottom: '15px' }}>No questions added yet</div>}
                {questionError && (
                  <div style={{ marginTop: '30px' }}>
                    <CommonErrorMessage message="Enrollment form can’t have more than 40 questions." align="left" />
                  </div>
                )}
                <div className="add-question-container">
                  <Button
                    className={
                      questions.length === QUESTION_LIMIT
                        ? `add-question-disabled ${questions.length > 0 ? '' : 'mt-0'}`
                        : `add-question ${questions.length > 0 ? '' : 'mt-0'}`
                    }
                    onClick={() => {
                      if (questions.length < QUESTION_LIMIT) {
                        push(defaultQuestionValue);
                        setFieldValue('enrollmentForm.isCustomEnrollmentFormAdded', true);
                        setFieldValue('enrollmentForm.enrollmentFormViewModel.isCustomEnrollmentFormAdded', true);
                      }
                      if (questions.length === QUESTION_LIMIT) {
                        setQuestionError(true);
                      }
                    }}
                    disableRipple
                  >
                    <AddIcon /> Add Question
                  </Button>
                </div>
              </>
            );
          }}
        </FieldArray>
      </Grid>
    </Grid>
  );
}

CustomQuestions.propTypes = {
  customEnrollmentFormRef: PropTypes.node.isRequired,
  values: PropTypes.shape([]).isRequired,
  className: PropTypes.string.isRequired,
  customQuestionsFormRef: PropTypes.node.isRequired,
};

export default React.forwardRef(CustomQuestions);
