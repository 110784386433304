import React, { useEffect, useRef, useState } from 'react';
import classes from './ContactsPage.module.scss';
import CreateCampaignMain from 'pages/CreateCampaign/Components/Containers/MainContainer/MainContainer';
import Stepper from 'pages/CreateCampaign/Components/Stepper/Stepper';
import useRouter from 'hooks/useRouter';
import { Formik, Form } from 'formik';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { useTheme, useMediaQuery, Box, Toolbar, FormControl, InputLabel } from '@material-ui/core';
import Buttons from 'pages/CreateContribution/components/Buttons';
import InfoIcon from '@material-ui/icons/Info';
import * as Yup from 'yup';
import SearchBox from 'pages/EmailCampaigns/Components/SearchBox/SearchBox';
import TableContainer from '@material-ui/core/TableContainer';
import { ContactsServices } from 'pages/Contacts/Services/Contacts.service';
import Table from '@material-ui/core/Table';
import MUIButton from '@mui/material/Button';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { FormControlLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Select from 'components/UI/Select';
import MUISelect from '@material-ui/core/Select';
import { OutlinedInput, MenuItem } from '@material-ui/core';
import Modal from 'components/UI/Modal';
import CreateSegmentModal from './Modals/CreateSegmentModal/CreateSegmentModal';
import AllClients from 'pages/Contacts/Clients/Clients';
import AllLeads from 'pages/Contacts/Leads/Leads';
import Button from 'components/FormUI/Button';
import { createCampaignServices } from 'pages/CreateCampaign/Services/CreateCampaign.service';
import { useHeader } from 'hooks/usePageActions';
import { FALSE } from 'sass';
import useCampaign from 'pages/CreateCampaign/Hooks/useCampaign';
import { saveCampaignToLS, setSendEmailTo, setUserContacts } from 'actions/campaign';
import { useDispatch } from 'react-redux';
import Loader from 'components/UI/Loader';
import { CampaignStatus } from 'pages/CreateCampaign/Constants/constants';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import CrossIcon from '@material-ui/icons/Clear';
import { colors } from 'utils/styles';
import { iteratee } from 'lodash';

const StyledCloseIcon = styled(CrossIcon)`
  margin: 5px;
  height: 15px;
  width: 15px;
  &:hover {
    cursor: pointer;
  }
`;
const StyledComingSoonLabel = styled.div`
  display: flex;
  /* width: 5.75rem; */
  height: 1.375rem;
  padding: 0.625rem 1.25rem;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  border-radius: 0.25rem;
  border: 1px solid #215c73;
  background: var(--Cohere-Primary-Blue, #215c73);

  .text {
    color: var(--Cohere-Primary-White, #fff);
    text-align: center;
    font-family: Avenir;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 800;
    line-height: 1.64063rem; /* 218.75% */
  }
`;
const ContactsPage = () => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { history, query } = useRouter();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const isEditing = !!query?.id;
  const campaignFormRef = useRef();
  const [isEditPanelOpen, setIsEditPanelOpen] = useState(false);
  const [showClients, setshowClients] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [allSelected, setAllSelected] = useState(true);
  const [contacts, setContacts] = useState({});
  const [selectedClients, setSelectedClients] = useState([]);
  const [allLeads, setAllLeads] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [redirectTo, setRedirectTo] = useState(null);
  const [errorPopUpState, seterrorPopUpState] = useState(false);
  const [segmentsList, setSegmentsList] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [showLeads, setshowLeads] = useState(false);
  const [isClientsSelected, setIsClientsSelected] = useState(true);
  const [isLeadsSelected, setIsLeadsSelected] = useState(true);
  const [editingData, setEditingData] = useState(null);
  const [createSengmentModalOpen, setCreateSengmentModalOpen] = useState(false);
  const backButtonText = showClients || showLeads ? 'Back' : 'Back';
  const headerTitle = showClients || showLeads ? 'Contacts in Campaign' : '';
  const [offset, setOffset] = useState(0);
  const { campaign, isCreateCampagnPage, userContacts, sendEmailTo } = useCampaign();
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedClients?.length === 0) {
      setIsClientsSelected(false);
    } else {
      setIsClientsSelected(true);
    }
  }, [selectedClients?.length]);

  useEffect(() => {
    if (selectedLeads?.length === 0) {
      setIsLeadsSelected(false);
    } else {
      setIsLeadsSelected(true);
    }
  }, [selectedLeads?.length]);

  useEffect(() => {
    if (isCreateCampagnPage && campaign === null) {
      history.replace(`/create-campaign/title`);
    }
  }, [campaign]);

  useEffect(() => {
    setIsLoading(true);
    getSegmentsList();
    getNormalClients();
    if (campaign?.segmentId != 'none' && campaign?.segmentId != null) {
      getSegmentContactsList(campaign?.segmentId);
      setIsEditPanelOpen(true);
    }
  }, [campaign]);

  const getNormalClients = () => {
    createCampaignServices
      .getUserContacts()
      .then(res => {
        dispatch(setUserContacts(res));
        setContacts(res);
        setAllClients(res?.clients?.filter(item => item.isDeleted === false)?.map(c => c.id));
        setAllLeads(res?.leads?.filter(item => item.isDeleted === false)?.map(l => l.id));
        if (campaignFormRef.current.values.segment === 'none') {
          setSelectedClients(res?.clients?.filter(item => item.isDeleted === false)?.map(c => c.id));
          setSelectedLeads(res?.leads?.map(l => l.id));
        } else if (campaign?.recipients?.length > 0) {
          const recipientClients = campaign?.recipients?.filter(recipient => !recipient.isLead);
          const recipientLeads = campaign?.recipients?.filter(recipient => recipient.isLead);
          if (recipientClients?.length > 0) {
            setSelectedClients(recipientClients?.filter(item => item.isDeleted === false)?.map(c => c.userId));
            setIsClientsSelected(true);
          } else {
            setSelectedClients([]);
            setIsClientsSelected(false);
          }
          if (recipientLeads?.length > 0) {
            setSelectedLeads(recipientLeads?.map(c => c.userId));
            setIsLeadsSelected(true);
          } else {
            setSelectedLeads([]);
            setIsLeadsSelected(false);
          }
        } else if (sendEmailTo?.length > 0) {
          const clients = sendEmailTo?.filter(c => !c.isLead);
          const leads = sendEmailTo?.filter(c => c.isLead);

          if (clients?.length > 0) {
            setSelectedClients(clients?.filter(item => item.isDeleted === false)?.map(c => c.id));
            setIsClientsSelected(true);
          } else {
            setSelectedClients([]);
            setIsClientsSelected(false);
          }
          if (leads?.length > 0) {
            setSelectedLeads(leads?.map(l => l.id));
            setIsLeadsSelected(true);
          } else {
            setSelectedLeads([]);
            setIsLeadsSelected(false);
          }
        } else {
          setSelectedClients(res?.clients?.filter(item => item.isDeleted === false)?.map(c => c.id));
          setSelectedLeads(res?.leads?.map(l => l.id));
        }
      })
      .catch(error => {})
      .finally(() => {
        setIsLoading(false);
      });
  };
  // useEffect(() => {
  //   if (isClientsSelected && isLeadsSelected) {
  //     setAllSelected(true);
  //   } else {
  //     setAllSelected(false);
  //   }
  // }, [isClientsSelected, isLeadsSelected]);

  const submitHandler = values => {
    setIsLoading(true);
    const selectedClientsData = contacts?.clients
      ?.filter(client => selectedClients.includes(client.id))
      ?.map(c => ({
        userId: c.id,
        email: c.email,
        fullName: c.firstName + c.lastName,
        firstName: c.firstName,
        lastName: c.lastName,
        isLead: false,
      }));
    const selectedLeadsData = contacts?.leads
      ?.filter(lead => selectedLeads.includes(lead.id))
      ?.map(l => ({
        userId: l.id,
        email: l.email,
        fullName: l.firstName + l.lastName,
        firstName: l.firstName,
        lastName: l.lastName,
        isLead: true,
      }));

    let recipients = [];
    if (isLeadsSelected && isClientsSelected) {
      recipients = [...selectedClientsData, ...selectedLeadsData];
    } else if (isLeadsSelected) {
      recipients = [...selectedLeadsData];
    } else if (isClientsSelected) {
      recipients = [...selectedClientsData];
    } else {
      //no contacts selected so return from here
      setIsLoading(false);
      return;
    }

    const data = {
      ...campaign,
      recipients,
      segmentId: values?.segment ?? null,
    };
    if (isEditing && campaign?.status === CampaignStatus.Approved) {
      createCampaignServices
        .updateApprovedCampaign(data)
        .then(res => {
          dispatch(saveCampaignToLS(res));
          setIsLoading(false);
          history.replace(redirectTo);
        })
        .catch(err => {
          setIsLoading(false);
        });
    } else {
      createCampaignServices
        .updateDraftCampaign(data)
        .then(res => {
          dispatch(saveCampaignToLS(res));
          setIsLoading(false);
          dispatch(setSendEmailTo(null));
          history.replace(redirectTo);
        })
        .catch(err => {
          setIsLoading(false);
        });
    }
  };

  const getSegmentContactsList = id => {
    setIsLoading(true);
    if (id === 'none') {
      setContacts(userContacts);
      setAllClients(userContacts?.clients?.filter(item => item.isDeleted === false).map(c => c.id));
      setAllLeads(userContacts?.leads?.filter(item => item.isDeleted === false).map(l => l.id));
      if (userContacts?.clients?.length > 0) {
        setSelectedClients(userContacts?.clients.filter(item => item.isDeleted === false)?.map(c => c.id));
        setIsClientsSelected(true);
      } else {
        setAllClients([]);
        setSelectedClients([]);
        setIsClientsSelected(false);
      }

      if (userContacts?.leads?.length > 0) {
        setSelectedLeads(userContacts?.leads?.map(l => l.id));
        setIsLeadsSelected(true);
      } else {
        setAllLeads([]);
        setSelectedLeads([]);
        setIsLeadsSelected(false);
      }
      setIsLoading(false);
    } else {
      ContactsServices.getSegmentedContacts(id).then(res => {
        const recipientClients = res?.clients?.filter(recipient => !recipient.isLead);
        const recipientLeads = res?.leads?.filter(recipient => recipient.isLead);
        setContacts({ clients: recipientClients, leads: recipientLeads });
        if (recipientClients?.length > 0) {
          setSelectedClients(recipientClients.filter(item => item.isDeleted === false)?.map(c => c.id));
          setIsClientsSelected(true);
        } else {
          setSelectedClients([]);
          setAllClients([]);
          setIsClientsSelected(false);
        }
        if (recipientLeads?.length > 0) {
          setSelectedLeads(recipientLeads?.map(c => c.id));
          setIsLeadsSelected(true);
        } else {
          setAllLeads([]);
          setSelectedLeads([]);
          setIsLeadsSelected(false);
        }
        setIsLoading(false);
      });
    }
  };

  const handleBackClick = e => {
    if (showClients) {
      setshowClients(false);
    } else if (showLeads) {
      setshowLeads(false);
    } else {
      dispatch(setSendEmailTo(null));
      history.goBack();
      if (history.length === 1) {
        history.push('/dashboard');
      }
    }
  };

  const getSegmentsList = () => {
    ContactsServices.getSegmentContactsList().then(res => {
      setSegmentsList(res.reverse());
    });
  };

  const setSegmentId = segmentId => {
    if (campaignFormRef?.current && segmentId) {
      campaignFormRef.current.setFieldValue('segment', segmentId);
      getSegmentContactsList(segmentId);
    }
  };
  return (
    <CreateCampaignMain
      headerProps={{
        backButtonText,
        headerTitle,
        handleBackClick,
      }}
    >
      <Formik
        innerRef={campaignFormRef}
        enableReinitialize
        initialValues={{
          segment: campaign?.segmentId ?? 'none',
        }}
        validationSchema={Yup.object().shape({
          segment: Yup.string(),
        })}
        onSubmit={submitHandler}
      >
        {({ values, setFieldValue, handleBlur, handleChange, handleSubmit, errors, touched }) => {
          return (
            <>
              {isLoading && <Loader />}
              {showClients && (
                <AllClients
                  showTopBar={false}
                  showFilter={false}
                  clients={editingData != null ? contacts?.clients : contacts?.clients}
                  setClients={setSelectedClients}
                  markedClients={selectedClients}
                />
              )}
              {showLeads && (
                <AllLeads
                  showTopBar={false}
                  showFilter={false}
                  leads={editingData != null ? contacts?.leads : contacts?.leads}
                  setLeads={setSelectedLeads}
                  markedLeads={selectedLeads}
                />
              )}
              {!showClients && !showLeads && (
                <>
                  <Card id={'createCampaign'} mobileView={mobileView}>
                    <CardHeader mobileView={mobileView}>
                      <div className={classes.stepper_container}>
                        <Stepper
                          activeStep={2}
                          handleSubmit={({ to }) => {
                            if (isLeadsSelected === false && isClientsSelected === false) {
                              seterrorPopUpState(true);
                            } else {
                              setRedirectTo(to);
                              handleSubmit(values);
                            }
                          }}
                        />
                      </div>
                    </CardHeader>
                    <CardBody className={classes.form_body} mobileView={mobileView}>
                      <form className={classes.form_container}>
                        <div className={classes.title_class}>Choose Contacts</div>
                        <div className={classes.topBar}>
                          {/* <Tooltip
                            className="cursor-pointer"
                            title="Coming soon..."
                            open={tooltipOpen}
                            arrow
                            onClose={() => {
                              setTooltipOpen(false);
                            }}
                          > */}

                          <Button
                            style={{ marginRight: '10px' }}
                            className={classes.segmentBtn}
                            onClick={() => {
                              if (values.segment === 'none') {
                                setIsEditPanelOpen(!isEditPanelOpen);
                              } else {
                                setIsEditPanelOpen(!isEditPanelOpen);
                                setFieldValue('segment', 'none');
                                getNormalClients();
                              }
                            }}
                            type="button"
                            autoWidth
                            onMouseOver={() => {
                              setTooltipOpen(true);
                            }}
                          >
                            {values.segment === 'none' ? 'Segment Contacts' : 'Remove Segment'}
                          </Button>
                          {/* <MUIButton variant="contained" disableElevation="true" style={imagBtnStyle} disabled={true}>
                            Segment Contacts
                          </MUIButton>
                          <StyledComingSoonLabel>
                            <div className="text">Coming Soon</div>
                          </StyledComingSoonLabel> */}
                          {/* </Tooltip> */}

                          {/* <SearchBox
                            containerClassName={classes.search}
                            placeholder="Search contacts"
                            onChange={() => { }}
                          /> */}
                        </div>

                        {isEditPanelOpen && (
                          <div className={classes.editPanel}>
                            <div style={{ width: '300px' }}>
                              <MUISelect
                                className={classes.select}
                                style={{ backgroundColor: 'white' }}
                                labelId="select-label"
                                label="Select"
                                name="segment"
                                variant="outlined"
                                placeholder="Select a segment"
                                value={values.segment}
                                fullWidth
                                input={<OutlinedInput margin="dense" />}
                                onChange={e => {
                                  setFieldValue('segment', e.target.value);
                                  getSegmentContactsList(e.target.value);
                                }}
                                classes={{
                                  select: classes.Mui_select,
                                }}
                              >
                                <MenuItem value="none">Select a segment</MenuItem>
                                {segmentsList?.map(value => {
                                  return <MenuItem value={value.id}>{value.name}</MenuItem>;
                                })}
                              </MUISelect>
                            </div>
                            {values.segment != 'none' && (
                              <Button
                                className={classes.editBtn}
                                onClick={() => {
                                  if (values.segment != 'none') {
                                    const filteredItems = segmentsList?.filter(item => item.id === values.segment);
                                    setEditingData(filteredItems[0]);
                                    setCreateSengmentModalOpen(true);
                                  }
                                }}
                                type="button"
                                autoWidth
                              >
                                Edit
                              </Button>
                            )}
                            <Button
                              className={classes.createSegmentBtn}
                              onClick={() => {
                                setEditingData(null);
                                setTimeout(() => {
                                  setCreateSengmentModalOpen(true);
                                }, 1000);
                              }}
                              type="button"
                              autoWidth
                            >
                              Create New Segment
                            </Button>
                          </div>
                        )}
                        <div className={classes.table}>
                          <TableContainer className={classes.tableContainer}>
                            <Table className="" aria-label="contacts table">
                              <TableHead>
                                <TableRow>
                                  <TableCell className={classes.headerTableCell}>
                                    Lists
                                    {/* <FormControlLabel
                                      className={classes.headerLabel}
                                      checked={allSelected}
                                      onChange={() => {
                                        seterrorPopUpState(false);
                                        if (allSelected) {
                                          setIsClientsSelected(false);
                                          setIsLeadsSelected(false);
                                          setAllSelected(false);
                                        } else {
                                          setAllSelected(true);
                                          setIsClientsSelected(true);
                                          setIsLeadsSelected(true);
                                          setSelectedClients(allClients);
                                          setSelectedLeads(allLeads);
                                        }
                                      }}
                                      control={<Checkbox color="primary" />}
                                      label={<div className={classes.headerCellLabel}>Lists</div>}
                                      name="selected"
                                      disabled={false}
                                    /> */}
                                  </TableCell>
                                  <TableCell className={classes.headerTableCell}>Contacts</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell className={classes.bodyTableCell}>
                                    <FormControlLabel
                                      className={classes.bodyCellLabel}
                                      checked={isClientsSelected}
                                      onChange={() => {
                                        setIsClientsSelected(!isClientsSelected);
                                        seterrorPopUpState(false);
                                        // if (isClientsSelected === false) {
                                        //   setSelectedClients(allClients);
                                        // }
                                      }}
                                      control={<Checkbox color="primary" />}
                                      label={
                                        <div className="d-flex align-items-center" style={{ gap: '8px' }}>
                                          <div
                                            className={classes.bodyCellValue}
                                            style={
                                              isClientsSelected
                                                ? {}
                                                : {
                                                    pointerEvents: 'none',
                                                    opacity: '0.5',
                                                  }
                                            }
                                            onClick={() => {
                                              if (isClientsSelected) {
                                                setshowClients(true);
                                              }
                                            }}
                                          >
                                            Clients
                                          </div>
                                          <Tooltip title="Customers who have PAID for one of your services appear under your clients list automatically.">
                                            <InfoIcon htmlColor="#c9b382" />
                                          </Tooltip>
                                        </div>
                                      }
                                      name="selected"
                                      disabled={false}
                                    />
                                  </TableCell>
                                  <TableCell className={classes.bodyTableCell}>
                                    <div
                                      className={classes.bodyCellValue}
                                      style={
                                        isClientsSelected
                                          ? {}
                                          : {
                                              pointerEvents: 'none',
                                              opacity: '0.5',
                                            }
                                      }
                                      onClick={() => {
                                        if (isClientsSelected) {
                                          setshowClients(true);
                                        }
                                      }}
                                    >
                                      {selectedClients?.length || allClients?.length}
                                    </div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell className={classes.bodyTableCell}>
                                    <FormControlLabel
                                      className={classes.bodyCellLabel}
                                      checked={isLeadsSelected}
                                      onChange={() => {
                                        // if (isLeadsSelected === false) {
                                        //   setSelectedLeads(allLeads);
                                        // }
                                        setIsLeadsSelected(!isLeadsSelected);
                                        seterrorPopUpState(false);
                                      }}
                                      control={<Checkbox color="primary" />}
                                      label={
                                        <div className="d-flex align-items-center" style={{ gap: '8px' }}>
                                          <div
                                            // className={classes.bodyCellLabel}
                                            className={classes.bodyCellValue}
                                            style={
                                              isLeadsSelected
                                                ? {}
                                                : {
                                                    pointerEvents: 'none',
                                                    opacity: '0.5',
                                                  }
                                            }
                                            onClick={() => {
                                              if (isLeadsSelected) {
                                                setshowLeads(true);
                                              }
                                            }}
                                          >
                                            Leads
                                          </div>
                                          <Tooltip title="Leads who have opted in for a FREE service such as a lead magnet, workshop or other, appear under your Leads list automatically. This can be changed later.">
                                            <InfoIcon htmlColor="#c9b382" />
                                          </Tooltip>
                                        </div>
                                      }
                                      name="selected"
                                      disabled={false}
                                    />
                                  </TableCell>
                                  <TableCell className={classes.bodyTableCell}>
                                    <div
                                      className={classes.bodyCellValue}
                                      style={
                                        isLeadsSelected
                                          ? {}
                                          : {
                                              pointerEvents: 'none',
                                              opacity: '0.5',
                                            }
                                      }
                                      onClick={() => {
                                        if (isLeadsSelected) {
                                          setshowLeads(true);
                                        }
                                      }}
                                    >
                                      {selectedLeads?.length || allLeads?.length}
                                    </div>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </form>
                    </CardBody>
                  </Card>
                  <Buttons
                    nextButtonTitle="Save and Next"
                    formId="create-campaign-form"
                    onNextClickHandler={event => {
                      const to = isEditing ? `/edit-campaign/${query?.id}/design` : '/create-campaign/design';
                      setRedirectTo(to);
                      handleSubmit(event);
                    }}
                    disabled={(!isLeadsSelected && !isClientsSelected) || isLoading}
                    backButtonTitle="Back"
                    backClickHandler={event => {
                      history.replace(isEditing ? `/edit-campaign/${query?.id}/template` : '/create-campaign/template');
                      handleSubmit(event);
                    }}
                  />
                  {errorPopUpState && (
                    <div
                      className="sticky"
                      style={{
                        top: offset >= 110 ? 0 : 110,
                        width: '45%',
                        right: 350,
                        backgroundColor: 'white',
                        marginInline: '9%',
                        border: '1px solid #DBE7F2',
                        borderRadius: '4px',
                        zIndex: 1,
                        transitionDuration: '1s',
                      }}
                    >
                      <div style={{ padding: '15px 10px 10px 15px' }}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'flex-end',
                          }}
                        >
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <InfoIcon style={{ transform: 'rotateX(180deg)' }} htmlColor={colors.darkOceanBlue} />
                            <div
                              style={{
                                fontFamily: 'Avenir',
                                fontWeight: '700',
                                fontSize: '16px',
                                lineHeight: '24px',
                                color: '#1E1E1E',
                                marginInline: '10px',
                              }}
                            >
                              Before you proceed...
                            </div>
                          </div>
                          <StyledCloseIcon
                            onClick={() => {
                              seterrorPopUpState(false);
                            }}
                            fontSize="10px"
                            style={{ color: 'black' }}
                          />
                        </div>

                        <div
                          style={{
                            fontFamily: 'Avenir',
                            fontWeight: '400',
                            fontSize: '16px',
                            lineHeight: '22px',
                            color: '#1E1E1E',
                            marginTop: '10px',
                            marginInline: '35px',
                          }}
                        >
                          Please choose contacts to proceed.
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          );
        }}
      </Formik>

      {createSengmentModalOpen && (
        <CreateSegmentModal
          isOpen={createSengmentModalOpen}
          onClose={() => {
            setCreateSengmentModalOpen(false);
            getSegmentsList();
          }}
          editing={editingData}
          setSegmentId={setSegmentId}
        />
      )}
    </CreateCampaignMain>
  );
};

export default ContactsPage;
