import React, { useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getUnReadPostCountForCommunities } from 'services/community.service';

const useStyles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: theme.palette.colors.darkOceanBlue2,
    borderRadius: 10,
    color: theme.palette.common.white,
    fontSize: 11,
    fontWeight: theme.typography.fontWeightBold,
    height: 20,
    padding: '3px 8px',
    marginBottom: '5px',
  },
  wrappercard: {
    backgroundColor: theme.palette.colors.darkOceanBlue2,
    borderRadius: 4,
    color: theme.palette.common.white,
    fontSize: 11,
    fontWeight: theme.typography.fontWeightBold,
    height: 19,
    padding: '2px 8px',
    marginBottom: '5px',
  },
}));

export const CommunityUnreadMessagesCount = ({ coachSidebar = false }) => {
  const classNames = useStyles();
  const [unreadCount, setUnreadCount] = useState(0);
  const selector = state => state?.communities;
  const { totalUnreadPostCountAllCommunities } = useSelector(selector);

  const fetchUnreadCount = async () => {
    const count = await getUnReadPostCountForCommunities();
    setUnreadCount(count);
  };

  useEffect(() => {
    fetchUnreadCount();
  }, [totalUnreadPostCountAllCommunities]);

  if (unreadCount == 0) {
    return null;
  }

  return <Typography className={classNames.wrapper}>{unreadCount}</Typography>;
};
