import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import VerificationCard from './components/VerificationCard/VerificationCard';
import BankAccountCard from './components/BankAccountCard/BankAccountCard';
import BalanceCard from './components/BalanceCard/BalanceCard';
import SalesSummaryCard from './components/SalesSummaryCard/SalesSummaryCard';
import CouponsCard from './components/coupons';
import { connect, useDispatch, useSelector } from 'react-redux';
import Button from 'components/FormUI/Button';
import { useLocation, useParams } from 'react-router-dom';
import * as contributionActions from 'actions/contributions';
import './Payment.scss';
import * as userActions from 'actions/user';
import ChatAndGetStartedButtons from 'pages/Dashboard/components/CohealerDashboard/LiveChat/LiveChatContainer';
import Joyride, {
  CallBackProps,
  STATUS,
  Step,
  EVENTS,
  StoreHelpers,
  BeaconRenderProps,
  TooltipRenderProps,
} from 'react-joyride';
import { useHistory } from 'react-router-dom';
import CoachImage from 'assets/chatlogo.png';
import styled from 'styled-components';
import { UpdateUserProgressBarData } from 'services/user.service';
import { updateUser } from 'actions/update-user';
import TemplateOne from 'assets/congratulations.png';
import Modal from 'components/UI/Modal';
import { useHttp, useAccount, useRouter } from 'hooks';
import { getLatestApproved, getUpcomingCreated } from 'services/contributions.service';
import Loader from 'components/UI/Loader';
const StyledGrid = styled(Grid)`
  padding: 30px 15px 30px 25px;
`;
const StyledImg = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 40px;
  align-items: center;
  z-index: 1;
`;
const StyledImgCongrats = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
  display: inline-block;
  // border-radius: 40px;
  align-items: center;
  z-index: 1;
`;

function Payment() {
  const { user } = useAccount();
  const dispatch = useDispatch();
  const [stepIndex, setStepIndex] = useState(4);
  const [showLoader, setShowLoader] = useState(false);
  const { state } = useLocation();
  const initialStepBankAccount = state?.from === 'bankAccount';
  const initialStepVerify = state?.from === 'verify';
  const showCompletedGetStart = useSelector(state => state.contributions.showCompletedGetStart);
  const refreshStep = showCompletedGetStart === 'bankAccountRefresh';
  const refreshStep1 = showCompletedGetStart === 'verifyRefresh';
  const [run, setRun] = useState(false);
  const history = useHistory();
  const PopUpBank = useSelector(state => state.contributions.showPopUpBank);
  const [contibId, setContibId] = useState(null);
  const { query } = useRouter();

  let joyRideHelpers;
  useEffect(() => {
    userActions.getProfile(user.id, dispatch);
    getLatestApproved().then(res => {
      setContibId(res);
    });
  }, []);

  useEffect(() => {
    if (query.from) {
      history.push('/account/payment', { from: query.from });
    }
  }, [query]);

  useEffect(() => {
    if (
      initialStepBankAccount === false &&
      initialStepVerify === false &&
      refreshStep === false &&
      refreshStep1 === false
    ) {
      if (user?.userProgressbarData?.IsBankAccountConnected && user?.userProgressbarData?.IsPlanPurchased) {
        setSteps([]);
      } else if (
        user?.userProgressbarData?.IsBankAccountConnected === true &&
        user?.userProgressbarData?.IsPlanPurchased === false
      ) {
        setSteps([
          {
            disableBeacon: true,
            hideFooter: true,
            content: (
              <div className="step4">
                <div
                  className="image"
                  style={{
                    height: '70px',
                    width: '70px',
                    backgroundColor: 'white',
                    position: 'absolute',
                    borderRadius: '100px',
                    top: -45,
                    left: 150,
                    borderColor: '#215C73',
                    borderWidth: '5px',
                  }}
                >
                  <StyledImg previewImg={CoachImage} />
                </div>
                <div
                  style={{
                    fontSize: '18px',
                    color: '#215C73',
                    display: 'flex',
                    alignSelf: 'flex-start',
                    fontWeight: 850,
                    fontFamily: 'Avenir',
                  }}
                >
                  Verify Your Informationa
                </div>
                <div
                  style={{
                    color: 'black',
                    fontSize: '16px',
                    marginTop: '13px',
                    fontWeight: 350,
                    fontFamily: 'Avenir',
                    textAlign: 'left',
                  }}
                >
                  Verify your information to create a Service and collect revenue.
                </div>
                <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
                  <Button
                    className="btn-left"
                    autoWidth
                    backgroundColor={'white'}
                    invert
                    style={{
                      marginTop: '40px',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      width: '100%',
                      borderColor: '#A58B62',
                      borderWidth: '1px',
                      borderRadius: '5px',
                      color: '#A58B62',
                    }}
                    onClick={() => {
                      dispatch(contributionActions.setPopUpBank(true));
                      updateUserF('isPlan');
                      // setRun(false);
                    }}
                  >
                    Mark as Complete
                  </Button>

                  {/* <Button
                  className="btn-right"
                  autoWidth
                  invert
                  style={{
                    marginTop: '40px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    width: '30%',
                    borderColor: '#215C73',
                    borderWidth: '1px',
                    borderRadius: '5px',
                    color: '#215C73',
                    marginLeft: '10px',
                  }}
                  onClick={() => {
                    joyRideHelpers.skip();
                    setRun(false);
                  }}
                >
                  Next
                </Button> */}
                </div>
              </div>
            ),
            placement: 'left',
            target: '#verify22',
          },
          {
            disableBeacon: true,
            hideFooter: true,
            content: (
              <div className="step4">
                <div
                  className="image"
                  style={{
                    height: '70px',
                    width: '70px',
                    backgroundColor: 'white',
                    position: 'absolute',
                    borderRadius: '100px',
                    top: -45,
                    left: 150,
                    borderColor: '#215C73',
                    borderWidth: '5px',
                  }}
                >
                  <StyledImg previewImg={CoachImage} />
                </div>
                <div
                  style={{
                    fontSize: '18px',
                    color: '#215C73',
                    display: 'flex',
                    alignSelf: 'flex-start',
                    fontWeight: 850,
                    fontFamily: 'Avenir',
                  }}
                >
                  Connect Your Bank111
                </div>
                <div
                  style={{
                    color: 'black',
                    fontSize: '16px',
                    marginTop: '13px',
                    fontWeight: 350,
                    fontFamily: 'Avenir',
                    textAlign: 'left',
                  }}
                >
                  Please connect your bank account to receive earned revenue!
                </div>
                <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
                  {user?.userProgressbarData?.IsBankAccountConnected === false && (
                    <Button
                      className="btn-left"
                      autoWidth
                      backgroundColor={'white'}
                      invert
                      style={{
                        marginTop: '40px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        width: '100%',
                        borderColor: '#A58B62',
                        borderWidth: '1px',
                        borderRadius: '5px',
                        color: '#A58B62',
                      }}
                      onClick={() => {
                        updateUserF();
                        dispatch(contributionActions.setPopUpBank(false));
                        // setRun(false);
                      }}
                    >
                      Mark as Complete
                    </Button>
                  )}
                </div>
              </div>
            ),
            placement: 'left',
            target: '#connectBank11',
          },
          {
            disableBeacon: true,
            hideFooter: true,
            content: (
              <div className="step4">
                <div
                  className="image"
                  style={{
                    height: '70px',
                    width: '70px',
                    backgroundColor: 'white',
                    position: 'absolute',
                    borderRadius: '100px',
                    top: -45,
                    left: 150,
                    borderColor: '#215C73',
                    borderWidth: '5px',
                  }}
                >
                  <StyledImg previewImg={CoachImage} />
                </div>
                <div
                  style={{
                    fontSize: '18px',
                    color: '#215C73',
                    display: 'flex',
                    alignSelf: 'flex-start',
                    fontWeight: 850,
                    fontFamily: 'Avenir',
                  }}
                >
                  Verify Your Informationb
                </div>
                <div
                  style={{
                    color: 'black',
                    fontSize: '16px',
                    marginTop: '13px',
                    fontWeight: 350,
                    fontFamily: 'Avenir',
                    textAlign: 'left',
                  }}
                >
                  Verify your information to create a Service and collect revenue.
                </div>
                <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
                  <Button
                    className="btn-left"
                    autoWidth
                    backgroundColor={'white'}
                    invert
                    style={{
                      marginTop: '40px',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      width: '100%',
                      borderColor: '#A58B62',
                      borderWidth: '1px',
                      borderRadius: '5px',
                      color: '#A58B62',
                    }}
                    onClick={() => {
                      dispatch(contributionActions.setPopUpBank(true));
                      updateUserF('isPlan');
                      // setRun(false);
                    }}
                  >
                    Mark as Complete
                  </Button>

                  {/* <Button
                  className="btn-right"
                  autoWidth
                  invert
                  style={{
                    marginTop: '40px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    width: '30%',
                    borderColor: '#215C73',
                    borderWidth: '1px',
                    borderRadius: '5px',
                    color: '#215C73',
                    marginLeft: '10px',
                  }}
                  onClick={() => {
                    joyRideHelpers.skip();
                    setRun(false);
                  }}
                >
                  Next
                </Button> */}
                </div>
              </div>
            ),
            placement: 'left',
            target: '#verify22',
          },
          {
            disableBeacon: true,
            hideFooter: true,
            content: (
              <div className="step4">
                <div
                  className="image"
                  style={{
                    height: '70px',
                    width: '70px',
                    backgroundColor: 'white',
                    position: 'absolute',
                    borderRadius: '100px',
                    top: -45,
                    left: 150,
                    borderColor: '#215C73',
                    borderWidth: '5px',
                  }}
                >
                  <StyledImg previewImg={CoachImage} />
                </div>
                <div
                  style={{
                    fontSize: '18px',
                    color: '#215C73',
                    display: 'flex',
                    alignSelf: 'flex-start',
                    fontWeight: 850,
                    fontFamily: 'Avenir',
                  }}
                >
                  Connect Your Bank111
                </div>
                <div
                  style={{
                    color: 'black',
                    fontSize: '16px',
                    marginTop: '13px',
                    fontWeight: 350,
                    fontFamily: 'Avenir',
                    textAlign: 'left',
                  }}
                >
                  Please connect your bank account to receive earned revenue!
                </div>
                <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
                  {user?.userProgressbarData?.IsBankAccountConnected === false && (
                    <Button
                      className="btn-left"
                      autoWidth
                      backgroundColor={'white'}
                      invert
                      style={{
                        marginTop: '40px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        width: '100%',
                        borderColor: '#A58B62',
                        borderWidth: '1px',
                        borderRadius: '5px',
                        color: '#A58B62',
                      }}
                      onClick={() => {
                        updateUserF();
                        dispatch(contributionActions.setPopUpBank(false));
                        // setRun(false);
                      }}
                    >
                      Mark as Complete
                    </Button>
                  )}
                </div>
              </div>
            ),
            placement: 'left',
            target: '#connectBank11',
          },
          {
            disableBeacon: true,
            hideFooter: true,
            content: (
              <div className="step4">
                <div
                  className="image"
                  style={{
                    height: '70px',
                    width: '70px',
                    backgroundColor: 'white',
                    position: 'absolute',
                    borderRadius: '100px',
                    top: -45,
                    left: 150,
                    borderColor: '#215C73',
                    borderWidth: '5px',
                  }}
                >
                  <StyledImg previewImg={CoachImage} />
                </div>
                <div
                  style={{
                    fontSize: '18px',
                    color: '#215C73',
                    display: 'flex',
                    alignSelf: 'flex-start',
                    fontWeight: 850,
                    fontFamily: 'Avenir',
                  }}
                >
                  Verify Your Informationc
                </div>
                <div
                  style={{
                    color: 'black',
                    fontSize: '16px',
                    marginTop: '13px',
                    fontWeight: 350,
                    fontFamily: 'Avenir',
                    textAlign: 'left',
                  }}
                >
                  Verify your information to create a Service and collect revenue.
                </div>
                <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
                  {user?.userProgressbarData?.IsPlanPurchased === false && (
                    <Button
                      className="btn-left"
                      autoWidth
                      backgroundColor={'white'}
                      invert
                      style={{
                        marginTop: '40px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        width: '100%',
                        borderColor: '#A58B62',
                        borderWidth: '1px',
                        borderRadius: '5px',
                        color: '#A58B62',
                      }}
                      onClick={() => {
                        dispatch(contributionActions.setPopUpBank(true));
                        updateUserF('isPlan');
                        // setRun(false);
                      }}
                    >
                      Mark as Complete
                    </Button>
                  )}

                  {/* <Button
                  className="btn-right"
                  autoWidth
                  invert
                  style={{
                    marginTop: '40px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    width: '30%',
                    borderColor: '#215C73',
                    borderWidth: '1px',
                    borderRadius: '5px',
                    color: '#215C73',
                    marginLeft: '10px',
                  }}
                  onClick={() => {
                    joyRideHelpers.skip();
                    setRun(false);
                  }}
                >
                  Next
                </Button> */}
                </div>
              </div>
            ),
            placement: 'left',
            target: '#verify',
          },
          {
            disableBeacon: true,
            hideFooter: true,
            content: (
              <div className="step4">
                <div
                  className="image"
                  style={{
                    height: '70px',
                    width: '70px',
                    backgroundColor: 'white',
                    position: 'absolute',
                    borderRadius: '100px',
                    top: -45,
                    left: 150,
                    borderColor: '#215C73',
                    borderWidth: '5px',
                  }}
                >
                  <StyledImg previewImg={CoachImage} />
                </div>
                <div
                  style={{
                    fontSize: '18px',
                    color: '#215C73',
                    display: 'flex',
                    alignSelf: 'flex-start',
                    fontWeight: 850,
                    fontFamily: 'Avenir',
                  }}
                >
                  Connect Your Banka
                </div>
                <div
                  style={{
                    color: 'black',
                    fontSize: '16px',
                    marginTop: '13px',
                    fontWeight: 350,
                    fontFamily: 'Avenir',
                    textAlign: 'left',
                  }}
                >
                  Please connect your bank account to receive earned revenue!
                </div>
                <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
                  {user?.userProgressbarData?.IsBankAccountConnected === false && (
                    <Button
                      className="btn-left"
                      autoWidth
                      backgroundColor={'white'}
                      invert
                      style={{
                        marginTop: '40px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        width: '100%',
                        borderColor: '#A58B62',
                        borderWidth: '1px',
                        borderRadius: '5px',
                        color: '#A58B62',
                      }}
                      onClick={() => {
                        updateUserF();
                        dispatch(contributionActions.setPopUpBank(false));
                        // setRun(false);
                      }}
                    >
                      Mark as Complete
                    </Button>
                  )}
                </div>
              </div>
            ),
            placement: 'left',
            target: '#connectBank',
          },
        ]);
        setStepIndex(4);
        setRun(true);
      } else if (
        user?.userProgressbarData?.IsBankAccountConnected === false &&
        user?.userProgressbarData?.IsPlanPurchased === true
      ) {
        setSteps([
          {
            disableBeacon: true,
            hideFooter: true,
            content: (
              <div className="step4">
                <div
                  className="image"
                  style={{
                    height: '70px',
                    width: '70px',
                    backgroundColor: 'white',
                    position: 'absolute',
                    borderRadius: '100px',
                    top: -45,
                    left: 150,
                    borderColor: '#215C73',
                    borderWidth: '5px',
                  }}
                >
                  <StyledImg previewImg={CoachImage} />
                </div>
                <div
                  style={{
                    fontSize: '18px',
                    color: '#215C73',
                    display: 'flex',
                    alignSelf: 'flex-start',
                    fontWeight: 850,
                    fontFamily: 'Avenir',
                  }}
                >
                  Verify Your Informationd
                </div>
                <div
                  style={{
                    color: 'black',
                    fontSize: '16px',
                    marginTop: '13px',
                    fontWeight: 350,
                    fontFamily: 'Avenir',
                    textAlign: 'left',
                  }}
                >
                  Verify your information to create a Service and collect revenue.
                </div>
                <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
                  <Button
                    className="btn-left"
                    autoWidth
                    backgroundColor={'white'}
                    invert
                    style={{
                      marginTop: '40px',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      width: '100%',
                      borderColor: '#A58B62',
                      borderWidth: '1px',
                      borderRadius: '5px',
                      color: '#A58B62',
                    }}
                    onClick={() => {
                      dispatch(contributionActions.setPopUpBank(true));
                      updateUserF('isPlan');
                      // setRun(false);
                    }}
                  >
                    Mark as Complete
                  </Button>

                  {/* <Button
                  className="btn-right"
                  autoWidth
                  invert
                  style={{
                    marginTop: '40px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    width: '30%',
                    borderColor: '#215C73',
                    borderWidth: '1px',
                    borderRadius: '5px',
                    color: '#215C73',
                    marginLeft: '10px',
                  }}
                  onClick={() => {
                    joyRideHelpers.skip();
                    setRun(false);
                  }}
                >
                  Next
                </Button> */}
                </div>
              </div>
            ),
            placement: 'left',
            target: '#verify22',
          },
          {
            disableBeacon: true,
            hideFooter: true,
            content: (
              <div className="step4">
                <div
                  className="image"
                  style={{
                    height: '70px',
                    width: '70px',
                    backgroundColor: 'white',
                    position: 'absolute',
                    borderRadius: '100px',
                    top: -45,
                    left: 150,
                    borderColor: '#215C73',
                    borderWidth: '5px',
                  }}
                >
                  <StyledImg previewImg={CoachImage} />
                </div>
                <div
                  style={{
                    fontSize: '18px',
                    color: '#215C73',
                    display: 'flex',
                    alignSelf: 'flex-start',
                    fontWeight: 850,
                    fontFamily: 'Avenir',
                  }}
                >
                  Connect Your Bank111
                </div>
                <div
                  style={{
                    color: 'black',
                    fontSize: '16px',
                    marginTop: '13px',
                    fontWeight: 350,
                    fontFamily: 'Avenir',
                    textAlign: 'left',
                  }}
                >
                  Please connect your bank account to receive earned revenue!
                </div>
                <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
                  {user?.userProgressbarData?.IsBankAccountConnected === false && (
                    <Button
                      className="btn-left"
                      autoWidth
                      backgroundColor={'white'}
                      invert
                      style={{
                        marginTop: '40px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        width: '100%',
                        borderColor: '#A58B62',
                        borderWidth: '1px',
                        borderRadius: '5px',
                        color: '#A58B62',
                      }}
                      onClick={() => {
                        updateUserF();
                        dispatch(contributionActions.setPopUpBank(false));
                        // setRun(false);
                      }}
                    >
                      Mark as Complete
                    </Button>
                  )}
                </div>
              </div>
            ),
            placement: 'left',
            target: '#connectBank11',
          },
          {
            disableBeacon: true,
            hideFooter: true,
            content: (
              <div className="step4">
                <div
                  className="image"
                  style={{
                    height: '70px',
                    width: '70px',
                    backgroundColor: 'white',
                    position: 'absolute',
                    borderRadius: '100px',
                    top: -45,
                    left: 150,
                    borderColor: '#215C73',
                    borderWidth: '5px',
                  }}
                >
                  <StyledImg previewImg={CoachImage} />
                </div>
                <div
                  style={{
                    fontSize: '18px',
                    color: '#215C73',
                    display: 'flex',
                    alignSelf: 'flex-start',
                    fontWeight: 850,
                    fontFamily: 'Avenir',
                  }}
                >
                  Verify Your Informatione
                </div>
                <div
                  style={{
                    color: 'black',
                    fontSize: '16px',
                    marginTop: '13px',
                    fontWeight: 350,
                    fontFamily: 'Avenir',
                    textAlign: 'left',
                  }}
                >
                  Verify your information to create a Service and collect revenue.
                </div>
                <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
                  <Button
                    className="btn-left"
                    autoWidth
                    backgroundColor={'white'}
                    invert
                    style={{
                      marginTop: '40px',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      width: '100%',
                      borderColor: '#A58B62',
                      borderWidth: '1px',
                      borderRadius: '5px',
                      color: '#A58B62',
                    }}
                    onClick={() => {
                      dispatch(contributionActions.setPopUpBank(true));
                      updateUserF('isPlan');
                      // setRun(false);
                    }}
                  >
                    Mark as Complete
                  </Button>

                  {/* <Button
                  className="btn-right"
                  autoWidth
                  invert
                  style={{
                    marginTop: '40px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    width: '30%',
                    borderColor: '#215C73',
                    borderWidth: '1px',
                    borderRadius: '5px',
                    color: '#215C73',
                    marginLeft: '10px',
                  }}
                  onClick={() => {
                    joyRideHelpers.skip();
                    setRun(false);
                  }}
                >
                  Next
                </Button> */}
                </div>
              </div>
            ),
            placement: 'left',
            target: '#verify22',
          },
          {
            disableBeacon: true,
            hideFooter: true,
            content: (
              <div className="step4">
                <div
                  className="image"
                  style={{
                    height: '70px',
                    width: '70px',
                    backgroundColor: 'white',
                    position: 'absolute',
                    borderRadius: '100px',
                    top: -45,
                    left: 150,
                    borderColor: '#215C73',
                    borderWidth: '5px',
                  }}
                >
                  <StyledImg previewImg={CoachImage} />
                </div>
                <div
                  style={{
                    fontSize: '18px',
                    color: '#215C73',
                    display: 'flex',
                    alignSelf: 'flex-start',
                    fontWeight: 850,
                    fontFamily: 'Avenir',
                  }}
                >
                  Connect Your Bank111
                </div>
                <div
                  style={{
                    color: 'black',
                    fontSize: '16px',
                    marginTop: '13px',
                    fontWeight: 350,
                    fontFamily: 'Avenir',
                    textAlign: 'left',
                  }}
                >
                  Please connect your bank account to receive earned revenue!
                </div>
                <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
                  {user?.userProgressbarData?.IsBankAccountConnected === false && (
                    <Button
                      className="btn-left"
                      autoWidth
                      backgroundColor={'white'}
                      invert
                      style={{
                        marginTop: '40px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        width: '100%',
                        borderColor: '#A58B62',
                        borderWidth: '1px',
                        borderRadius: '5px',
                        color: '#A58B62',
                      }}
                      onClick={() => {
                        updateUserF();
                        dispatch(contributionActions.setPopUpBank(false));
                        // setRun(false);
                      }}
                    >
                      Mark as Complete
                    </Button>
                  )}
                </div>
              </div>
            ),
            placement: 'left',
            target: '#connectBank11',
          },
          {
            disableBeacon: true,
            hideFooter: true,
            content: (
              <div className="step4">
                <div
                  className="image"
                  style={{
                    height: '70px',
                    width: '70px',
                    backgroundColor: 'white',
                    position: 'absolute',
                    borderRadius: '100px',
                    top: -45,
                    left: 150,
                    borderColor: '#215C73',
                    borderWidth: '5px',
                  }}
                >
                  <StyledImg previewImg={CoachImage} />
                </div>
                <div
                  style={{
                    fontSize: '18px',
                    color: '#215C73',
                    display: 'flex',
                    alignSelf: 'flex-start',
                    fontWeight: 850,
                    fontFamily: 'Avenir',
                  }}
                >
                  Connect Your Bankb
                </div>
                <div
                  style={{
                    color: 'black',
                    fontSize: '16px',
                    marginTop: '13px',
                    fontWeight: 350,
                    fontFamily: 'Avenir',
                    textAlign: 'left',
                  }}
                >
                  Please connect your bank account to receive earned revenue!
                </div>
                <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
                  {user?.userProgressbarData?.IsBankAccountConnected === false && (
                    <Button
                      className="btn-left"
                      autoWidth
                      backgroundColor={'white'}
                      invert
                      style={{
                        marginTop: '40px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        width: '100%',
                        borderColor: '#A58B62',
                        borderWidth: '1px',
                        borderRadius: '5px',
                        color: '#A58B62',
                      }}
                      onClick={() => {
                        updateUserF();
                        dispatch(contributionActions.setPopUpBank(false));
                        // setRun(false);
                      }}
                    >
                      Mark as Complete
                    </Button>
                  )}
                </div>
              </div>
            ),
            placement: 'left',
            target: '#connectBank',
          },
        ]);
        setStepIndex(5);
        setRun(true);
      } else if (
        user?.userProgressbarData?.IsBankAccountConnected === false &&
        user?.userProgressbarData?.IsPlanPurchased === false
      ) {
        setRun(true);
      }
    } else if (refreshStep1 || initialStepVerify) {
      setSteps([
        {
          disableBeacon: true,
          hideFooter: true,
          content: (
            <div className="step4">
              <div
                className="image"
                style={{
                  height: '70px',
                  width: '70px',
                  backgroundColor: 'white',
                  position: 'absolute',
                  borderRadius: '100px',
                  top: -45,
                  left: 150,
                  borderColor: '#215C73',
                  borderWidth: '5px',
                }}
              >
                <StyledImg previewImg={CoachImage} />
              </div>
              <div
                style={{
                  fontSize: '18px',
                  color: '#215C73',
                  display: 'flex',
                  alignSelf: 'flex-start',
                  fontWeight: 850,
                  fontFamily: 'Avenir',
                }}
              >
                Verify Your Informationf
              </div>
              <div
                style={{
                  color: 'black',
                  fontSize: '16px',
                  marginTop: '13px',
                  fontWeight: 350,
                  fontFamily: 'Avenir',
                  textAlign: 'left',
                }}
              >
                Verify your information to create a Service and collect revenue.
              </div>
              <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
                <Button
                  className="btn-left"
                  autoWidth
                  backgroundColor={'white'}
                  invert
                  style={{
                    marginTop: '40px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    width: '100%',
                    borderColor: '#A58B62',
                    borderWidth: '1px',
                    borderRadius: '5px',
                    color: '#A58B62',
                  }}
                  onClick={() => {
                    dispatch(contributionActions.setPopUpBank(true));
                    updateUserF('isPlan');
                    // setRun(false);
                  }}
                >
                  Mark as Complete
                </Button>

                {/* <Button
                className="btn-right"
                autoWidth
                invert
                style={{
                  marginTop: '40px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  width: '30%',
                  borderColor: '#215C73',
                  borderWidth: '1px',
                  borderRadius: '5px',
                  color: '#215C73',
                  marginLeft: '10px',
                }}
                onClick={() => {
                  joyRideHelpers.skip();
                  setRun(false);
                }}
              >
                Next
              </Button> */}
              </div>
            </div>
          ),
          placement: 'left',
          target: '#verify22',
        },
        {
          disableBeacon: true,
          hideFooter: true,
          content: (
            <div className="step4">
              <div
                className="image"
                style={{
                  height: '70px',
                  width: '70px',
                  backgroundColor: 'white',
                  position: 'absolute',
                  borderRadius: '100px',
                  top: -45,
                  left: 150,
                  borderColor: '#215C73',
                  borderWidth: '5px',
                }}
              >
                <StyledImg previewImg={CoachImage} />
              </div>
              <div
                style={{
                  fontSize: '18px',
                  color: '#215C73',
                  display: 'flex',
                  alignSelf: 'flex-start',
                  fontWeight: 850,
                  fontFamily: 'Avenir',
                }}
              >
                Connect Your Bank111
              </div>
              <div
                style={{
                  color: 'black',
                  fontSize: '16px',
                  marginTop: '13px',
                  fontWeight: 350,
                  fontFamily: 'Avenir',
                  textAlign: 'left',
                }}
              >
                Please connect your bank account to receive earned revenue!
              </div>
              <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
                {user?.userProgressbarData?.IsBankAccountConnected === false && (
                  <Button
                    className="btn-left"
                    autoWidth
                    backgroundColor={'white'}
                    invert
                    style={{
                      marginTop: '40px',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      width: '100%',
                      borderColor: '#A58B62',
                      borderWidth: '1px',
                      borderRadius: '5px',
                      color: '#A58B62',
                    }}
                    onClick={() => {
                      updateUserF();
                      dispatch(contributionActions.setPopUpBank(false));
                      // setRun(false);
                    }}
                  >
                    Mark as Complete
                  </Button>
                )}
              </div>
            </div>
          ),
          placement: 'left',
          target: '#connectBank11',
        },
        {
          disableBeacon: true,
          hideFooter: true,
          content: (
            <div className="step4">
              <div
                className="image"
                style={{
                  height: '70px',
                  width: '70px',
                  backgroundColor: 'white',
                  position: 'absolute',
                  borderRadius: '100px',
                  top: -45,
                  left: 150,
                  borderColor: '#215C73',
                  borderWidth: '5px',
                }}
              >
                <StyledImg previewImg={CoachImage} />
              </div>
              <div
                style={{
                  fontSize: '18px',
                  color: '#215C73',
                  display: 'flex',
                  alignSelf: 'flex-start',
                  fontWeight: 850,
                  fontFamily: 'Avenir',
                }}
              >
                Verify Your Informationg
              </div>
              <div
                style={{
                  color: 'black',
                  fontSize: '16px',
                  marginTop: '13px',
                  fontWeight: 350,
                  fontFamily: 'Avenir',
                  textAlign: 'left',
                }}
              >
                Verify your information to create a Service and collect revenue.
              </div>
              <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
                <Button
                  className="btn-left"
                  autoWidth
                  backgroundColor={'white'}
                  invert
                  style={{
                    marginTop: '40px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    width: '100%',
                    borderColor: '#A58B62',
                    borderWidth: '1px',
                    borderRadius: '5px',
                    color: '#A58B62',
                  }}
                  onClick={() => {
                    dispatch(contributionActions.setPopUpBank(true));
                    updateUserF('isPlan');
                    // setRun(false);
                  }}
                >
                  Mark as Complete
                </Button>

                {/* <Button
                className="btn-right"
                autoWidth
                invert
                style={{
                  marginTop: '40px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  width: '30%',
                  borderColor: '#215C73',
                  borderWidth: '1px',
                  borderRadius: '5px',
                  color: '#215C73',
                  marginLeft: '10px',
                }}
                onClick={() => {
                  joyRideHelpers.skip();
                  setRun(false);
                }}
              >
                Next
              </Button> */}
              </div>
            </div>
          ),
          placement: 'left',
          target: '#verify22',
        },
        {
          disableBeacon: true,
          hideFooter: true,
          content: (
            <div className="step4">
              <div
                className="image"
                style={{
                  height: '70px',
                  width: '70px',
                  backgroundColor: 'white',
                  position: 'absolute',
                  borderRadius: '100px',
                  top: -45,
                  left: 150,
                  borderColor: '#215C73',
                  borderWidth: '5px',
                }}
              >
                <StyledImg previewImg={CoachImage} />
              </div>
              <div
                style={{
                  fontSize: '18px',
                  color: '#215C73',
                  display: 'flex',
                  alignSelf: 'flex-start',
                  fontWeight: 850,
                  fontFamily: 'Avenir',
                }}
              >
                Connect Your Bank111
              </div>
              <div
                style={{
                  color: 'black',
                  fontSize: '16px',
                  marginTop: '13px',
                  fontWeight: 350,
                  fontFamily: 'Avenir',
                  textAlign: 'left',
                }}
              >
                Please connect your bank account to receive earned revenue!
              </div>
              <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
                {user?.userProgressbarData?.IsBankAccountConnected === false && (
                  <Button
                    className="btn-left"
                    autoWidth
                    backgroundColor={'white'}
                    invert
                    style={{
                      marginTop: '40px',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      width: '100%',
                      borderColor: '#A58B62',
                      borderWidth: '1px',
                      borderRadius: '5px',
                      color: '#A58B62',
                    }}
                    onClick={() => {
                      updateUserF();
                      dispatch(contributionActions.setPopUpBank(false));
                      // setRun(false);
                    }}
                  >
                    Mark as Complete
                  </Button>
                )}
              </div>
            </div>
          ),
          placement: 'left',
          target: '#connectBank11',
        },
        {
          disableBeacon: true,
          hideFooter: true,
          content: (
            <div className="step4">
              <div
                className="image"
                style={{
                  height: '70px',
                  width: '70px',
                  backgroundColor: 'white',
                  position: 'absolute',
                  borderRadius: '100px',
                  top: -45,
                  left: 150,
                  borderColor: '#215C73',
                  borderWidth: '5px',
                }}
              >
                <StyledImg previewImg={CoachImage} />
              </div>
              <div
                style={{
                  fontSize: '18px',
                  color: '#215C73',
                  display: 'flex',
                  alignSelf: 'flex-start',
                  fontWeight: 850,
                  fontFamily: 'Avenir',
                }}
              >
                Verify Your Information
              </div>
              <div
                style={{
                  color: 'black',
                  fontSize: '16px',
                  marginTop: '13px',
                  fontWeight: 350,
                  fontFamily: 'Avenir',
                  textAlign: 'left',
                }}
              >
                Verify your information to create a Service and collect revenue.
              </div>
              <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
                {user?.userProgressbarData?.IsPlanPurchased === false && (
                  <div>
                    <Button
                      className="btn-left"
                      autoWidth
                      backgroundColor={'white'}
                      invert
                      style={{
                        marginTop: '40px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        width: '100%',
                        borderColor: '#A58B62',
                        borderWidth: '1px',
                        borderRadius: '5px',
                        color: '#A58B62',
                      }}
                      onClick={() => {
                        updateUserF('isPlan');
                        dispatch(contributionActions.setPopUpBank(false));
                        // setRun(false);
                      }}
                    >
                      Mark as Complete
                    </Button>
                    <Button
                      className="btn-right"
                      autoWidth
                      invert
                      style={{
                        marginTop: '10px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        width: '100%',
                        borderColor: '#215C73',
                        borderWidth: '1px',
                        borderRadius: '5px',
                        color: '#215C73',
                        // marginLeft: '10px',
                      }}
                      onClick={() => {
                        dispatch(contributionActions.setPopUpBank(true));
                        updateUserF('isPlan', true);
                      }}
                    >
                      Complete & Next
                    </Button>
                  </div>
                )}

                {/* <Button
                className="btn-right"
                autoWidth
                invert
                style={{
                  marginTop: '40px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  width: '30%',
                  borderColor: '#215C73',
                  borderWidth: '1px',
                  borderRadius: '5px',
                  color: '#215C73',
                  marginLeft: '10px',
                }}
                onClick={() => {
                  joyRideHelpers.skip();
                  setRun(false);
                }}
              >
                Next
              </Button> */}
              </div>
            </div>
          ),
          placement: 'left',
          target: '#verify',
        },
        {
          disableBeacon: true,
          hideFooter: true,
          content: (
            <div className="step4">
              <div
                className="image"
                style={{
                  height: '70px',
                  width: '70px',
                  backgroundColor: 'white',
                  position: 'absolute',
                  borderRadius: '100px',
                  top: -45,
                  left: 150,
                  borderColor: '#215C73',
                  borderWidth: '5px',
                }}
              >
                <StyledImg previewImg={CoachImage} />
              </div>
              <div
                style={{
                  fontSize: '18px',
                  color: '#215C73',
                  display: 'flex',
                  alignSelf: 'flex-start',
                  fontWeight: 850,
                  fontFamily: 'Avenir',
                }}
              >
                Connect Your Bank
              </div>
              <div
                style={{
                  color: 'black',
                  fontSize: '16px',
                  marginTop: '13px',
                  fontWeight: 350,
                  fontFamily: 'Avenir',
                  textAlign: 'left',
                }}
              >
                Please connect your bank account to receive earned revenue!
              </div>
              <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
                {user?.userProgressbarData?.IsBankAccountConnected === false && (
                  <div>
                    <Button
                      className="btn-left"
                      autoWidth
                      backgroundColor={'white'}
                      invert
                      style={{
                        marginTop: '40px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        width: '100%',
                        borderColor: '#A58B62',
                        borderWidth: '1px',
                        borderRadius: '5px',
                        color: '#A58B62',
                      }}
                      onClick={() => {
                        updateUserF();
                        dispatch(contributionActions.setPopUpBank(false));
                        // setRun(false);
                      }}
                    >
                      Mark as Complete
                    </Button>
                    <Button
                      className="btn-left"
                      autoWidth
                      backgroundColor={'white'}
                      invert
                      style={{
                        marginTop: '10px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        width: '100%',
                        borderColor: '#215C73',
                        borderWidth: '1px',
                        borderRadius: '5px',
                        color: '#215C73',
                      }}
                      onClick={() => {
                        updateUserF('', true);
                        dispatch(contributionActions.setPopUpBank(false));
                        // setRun(false);
                      }}
                    >
                      Complete & Next
                    </Button>
                  </div>
                )}
              </div>
            </div>
          ),
          placement: 'left',
          target: '#connectBank',
        },
      ]);
      setStepIndex(4);
      setRun(true);
    } else if ((refreshStep || initialStepBankAccount) && PopUpBank === true) {
      setSteps([
        {
          disableBeacon: true,
          hideFooter: true,
          content: (
            <div className="step4">
              <div
                className="image"
                style={{
                  height: '70px',
                  width: '70px',
                  backgroundColor: 'white',
                  position: 'absolute',
                  borderRadius: '100px',
                  top: -45,
                  left: 150,
                  borderColor: '#215C73',
                  borderWidth: '5px',
                }}
              >
                <StyledImg previewImg={CoachImage} />
              </div>
              <div
                style={{
                  fontSize: '18px',
                  color: '#215C73',
                  display: 'flex',
                  alignSelf: 'flex-start',
                  fontWeight: 850,
                  fontFamily: 'Avenir',
                }}
              >
                Verify Your Informationi
              </div>
              <div
                style={{
                  color: 'black',
                  fontSize: '16px',
                  marginTop: '13px',
                  fontWeight: 350,
                  fontFamily: 'Avenir',
                  textAlign: 'left',
                }}
              >
                Verify your information to create a Service and collect revenue.
              </div>
              <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
                <Button
                  className="btn-left"
                  autoWidth
                  backgroundColor={'white'}
                  invert
                  style={{
                    marginTop: '40px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    width: '100%',
                    borderColor: '#A58B62',
                    borderWidth: '1px',
                    borderRadius: '5px',
                    color: '#A58B62',
                  }}
                  onClick={() => {
                    dispatch(contributionActions.setPopUpBank(true));
                    updateUserF('isPlan');
                    // setRun(false);
                  }}
                >
                  Mark as Complete
                </Button>

                {/* <Button
                className="btn-right"
                autoWidth
                invert
                style={{
                  marginTop: '40px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  width: '30%',
                  borderColor: '#215C73',
                  borderWidth: '1px',
                  borderRadius: '5px',
                  color: '#215C73',
                  marginLeft: '10px',
                }}
                onClick={() => {
                  joyRideHelpers.skip();
                  setRun(false);
                }}
              >
                Next
              </Button> */}
              </div>
            </div>
          ),
          placement: 'left',
          target: '#verify22',
        },
        {
          disableBeacon: true,
          hideFooter: true,
          content: (
            <div className="step4">
              <div
                className="image"
                style={{
                  height: '70px',
                  width: '70px',
                  backgroundColor: 'white',
                  position: 'absolute',
                  borderRadius: '100px',
                  top: -45,
                  left: 150,
                  borderColor: '#215C73',
                  borderWidth: '5px',
                }}
              >
                <StyledImg previewImg={CoachImage} />
              </div>
              <div
                style={{
                  fontSize: '18px',
                  color: '#215C73',
                  display: 'flex',
                  alignSelf: 'flex-start',
                  fontWeight: 850,
                  fontFamily: 'Avenir',
                }}
              >
                Connect Your Bank111
              </div>
              <div
                style={{
                  color: 'black',
                  fontSize: '16px',
                  marginTop: '13px',
                  fontWeight: 350,
                  fontFamily: 'Avenir',
                  textAlign: 'left',
                }}
              >
                Please connect your bank account to receive earned revenue!
              </div>
              <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
                {user?.userProgressbarData?.IsBankAccountConnected === false && (
                  <Button
                    className="btn-left"
                    autoWidth
                    backgroundColor={'white'}
                    invert
                    style={{
                      marginTop: '40px',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      width: '100%',
                      borderColor: '#A58B62',
                      borderWidth: '1px',
                      borderRadius: '5px',
                      color: '#A58B62',
                    }}
                    onClick={() => {
                      updateUserF();
                      dispatch(contributionActions.setPopUpBank(false));
                      // setRun(false);
                    }}
                  >
                    Mark as Complete
                  </Button>
                )}
              </div>
            </div>
          ),
          placement: 'left',
          target: '#connectBank11',
        },
        {
          disableBeacon: true,
          hideFooter: true,
          content: (
            <div className="step4">
              <div
                className="image"
                style={{
                  height: '70px',
                  width: '70px',
                  backgroundColor: 'white',
                  position: 'absolute',
                  borderRadius: '100px',
                  top: -45,
                  left: 150,
                  borderColor: '#215C73',
                  borderWidth: '5px',
                }}
              >
                <StyledImg previewImg={CoachImage} />
              </div>
              <div
                style={{
                  fontSize: '18px',
                  color: '#215C73',
                  display: 'flex',
                  alignSelf: 'flex-start',
                  fontWeight: 850,
                  fontFamily: 'Avenir',
                }}
              >
                Verify Your Informationj
              </div>
              <div
                style={{
                  color: 'black',
                  fontSize: '16px',
                  marginTop: '13px',
                  fontWeight: 350,
                  fontFamily: 'Avenir',
                  textAlign: 'left',
                }}
              >
                Verify your information to create a Service and collect revenue.
              </div>
              <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
                <Button
                  className="btn-left"
                  autoWidth
                  backgroundColor={'white'}
                  invert
                  style={{
                    marginTop: '40px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    width: '100%',
                    borderColor: '#A58B62',
                    borderWidth: '1px',
                    borderRadius: '5px',
                    color: '#A58B62',
                  }}
                  onClick={() => {
                    dispatch(contributionActions.setPopUpBank(true));
                    updateUserF('isPlan');
                    // setRun(false);
                  }}
                >
                  Mark as Complete
                </Button>

                {/* <Button
                className="btn-right"
                autoWidth
                invert
                style={{
                  marginTop: '40px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  width: '30%',
                  borderColor: '#215C73',
                  borderWidth: '1px',
                  borderRadius: '5px',
                  color: '#215C73',
                  marginLeft: '10px',
                }}
                onClick={() => {
                  joyRideHelpers.skip();
                  setRun(false);
                }}
              >
                Next
              </Button> */}
              </div>
            </div>
          ),
          placement: 'left',
          target: '#verify22',
        },
        {
          disableBeacon: true,
          hideFooter: true,
          content: (
            <div className="step4">
              <div
                className="image"
                style={{
                  height: '70px',
                  width: '70px',
                  backgroundColor: 'white',
                  position: 'absolute',
                  borderRadius: '100px',
                  top: -45,
                  left: 150,
                  borderColor: '#215C73',
                  borderWidth: '5px',
                }}
              >
                <StyledImg previewImg={CoachImage} />
              </div>
              <div
                style={{
                  fontSize: '18px',
                  color: '#215C73',
                  display: 'flex',
                  alignSelf: 'flex-start',
                  fontWeight: 850,
                  fontFamily: 'Avenir',
                }}
              >
                Connect Your Bank111
              </div>
              <div
                style={{
                  color: 'black',
                  fontSize: '16px',
                  marginTop: '13px',
                  fontWeight: 350,
                  fontFamily: 'Avenir',
                  textAlign: 'left',
                }}
              >
                Please connect your bank account to receive earned revenue!
              </div>
              <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
                {user?.userProgressbarData?.IsBankAccountConnected === false && (
                  <Button
                    className="btn-left"
                    autoWidth
                    backgroundColor={'white'}
                    invert
                    style={{
                      marginTop: '40px',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      width: '100%',
                      borderColor: '#A58B62',
                      borderWidth: '1px',
                      borderRadius: '5px',
                      color: '#A58B62',
                    }}
                    onClick={() => {
                      updateUserF();
                      dispatch(contributionActions.setPopUpBank(false));
                      // setRun(false);
                    }}
                  >
                    Mark as Complete
                  </Button>
                )}
              </div>
            </div>
          ),
          placement: 'left',
          target: '#connectBank11',
        },
        {
          disableBeacon: true,
          hideFooter: true,
          content: (
            <div className="step4">
              <div
                className="image"
                style={{
                  height: '70px',
                  width: '70px',
                  backgroundColor: 'white',
                  position: 'absolute',
                  borderRadius: '100px',
                  top: -45,
                  left: 150,
                  borderColor: '#215C73',
                  borderWidth: '5px',
                }}
              >
                <StyledImg previewImg={CoachImage} />
              </div>
              <div
                style={{
                  fontSize: '18px',
                  color: '#215C73',
                  display: 'flex',
                  alignSelf: 'flex-start',
                  fontWeight: 850,
                  fontFamily: 'Avenir',
                }}
              >
                Connect Your Bank
              </div>
              <div
                style={{
                  color: 'black',
                  fontSize: '16px',
                  marginTop: '13px',
                  fontWeight: 350,
                  fontFamily: 'Avenir',
                  textAlign: 'left',
                }}
              >
                Please connect your bank account to receive earned revenue!
              </div>
              <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
                {user?.userProgressbarData?.IsBankAccountConnected === false && (
                  <div>
                    <Button
                      className="btn-left"
                      autoWidth
                      backgroundColor={'white'}
                      invert
                      style={{
                        marginTop: '40px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        width: '100%',
                        borderColor: '#A58B62',
                        borderWidth: '1px',
                        borderRadius: '5px',
                        color: '#A58B62',
                      }}
                      onClick={() => {
                        updateUserF();
                        dispatch(contributionActions.setPopUpBank(false));
                        // setRun(false);
                      }}
                    >
                      Mark as Complete
                    </Button>
                    <Button
                      className="btn-left"
                      autoWidth
                      backgroundColor={'white'}
                      invert
                      style={{
                        marginTop: '10px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        width: '100%',
                        borderColor: '#215C73',
                        borderWidth: '1px',
                        borderRadius: '5px',
                        color: '#215C73',
                      }}
                      onClick={() => {
                        updateUserF('', true);
                        dispatch(contributionActions.setPopUpBank(false));
                        // setRun(false);
                      }}
                    >
                      Complete & Next
                    </Button>
                  </div>
                )}
              </div>
            </div>
          ),
          placement: 'left',
          target: '#connectBank',
        },
      ]);
      setStepIndex(4);
      setRun(true);
    }
  }, [user?.userProgressbarData?.IsBankAccountConnected, user?.userProgressbarData?.IsPlanPurchased, PopUpBank]);

  const [showModal, setShowModal] = useState(false);

  const togglePopup = () => {
    setShowModal(!showModal);
  };
  const [steps, setSteps] = useState([
    {
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step4">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Verify Your Informationk
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Verify your information to create a Service and collect revenue.
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '100%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                dispatch(contributionActions.setPopUpBank(true));
                updateUserF('isPlan');
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            {/* <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                setRun(false);
              }}
            >
              Next
            </Button> */}
          </div>
        </div>
      ),
      placement: 'left',
      target: '#verify22',
    },
    {
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step4">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Connect Your Bank111
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Please connect your bank account to receive earned revenue!
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            {user?.userProgressbarData?.IsBankAccountConnected === false && (
              <Button
                className="btn-left"
                autoWidth
                backgroundColor={'white'}
                invert
                style={{
                  marginTop: '40px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  width: '100%',
                  borderColor: '#A58B62',
                  borderWidth: '1px',
                  borderRadius: '5px',
                  color: '#A58B62',
                }}
                onClick={() => {
                  updateUserF();
                  dispatch(contributionActions.setPopUpBank(false));
                  // setRun(false);
                }}
              >
                Mark as Complete
              </Button>
            )}
          </div>
        </div>
      ),
      placement: 'left',
      target: '#connectBank11',
    },
    {
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step4">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Verify Your Informationl
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Verify your information to create a Service and collect revenue.
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '100%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                dispatch(contributionActions.setPopUpBank(true));
                updateUserF('isPlan');
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            {/* <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                setRun(false);
              }}
            >
              Next
            </Button> */}
          </div>
        </div>
      ),
      placement: 'left',
      target: '#verify22',
    },
    {
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step4">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Connect Your Bank111
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Please connect your bank account to receive earned revenue!
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            {user?.userProgressbarData?.IsBankAccountConnected === false && (
              <Button
                className="btn-left"
                autoWidth
                backgroundColor={'white'}
                invert
                style={{
                  marginTop: '40px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  width: '100%',
                  borderColor: '#A58B62',
                  borderWidth: '1px',
                  borderRadius: '5px',
                  color: '#A58B62',
                }}
                onClick={() => {
                  updateUserF();
                  dispatch(contributionActions.setPopUpBank(false));
                  // setRun(false);
                }}
              >
                Mark as Complete
              </Button>
            )}
          </div>
        </div>
      ),
      placement: 'left',
      target: '#connectBank11',
    },
    {
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step4">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Verify Your Information
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Verify your information to create a Service and collect revenue.
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            {user?.userProgressbarData?.IsPlanPurchased === false && (
              <Button
                className="btn-left"
                autoWidth
                backgroundColor={'white'}
                invert
                style={{
                  marginTop: '40px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  width: '100%',
                  borderColor: '#A58B62',
                  borderWidth: '1px',
                  borderRadius: '5px',
                  color: '#A58B62',
                }}
                onClick={() => {
                  dispatch(contributionActions.setPopUpBank(true));
                  updateUserF('isPlan');
                  // setRun(false);
                }}
              >
                Mark as Complete
              </Button>
            )}

            {/* <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                setRun(false);
              }}
            >
              Next
            </Button> */}
          </div>
        </div>
      ),
      placement: 'left',
      target: '#verify',
    },
    {
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step4">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Connect Your Bank
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Please connect your bank account to receive earned revenue!
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            {user?.userProgressbarData?.IsBankAccountConnected === false && (
              <Button
                className="btn-left"
                autoWidth
                backgroundColor={'white'}
                invert
                style={{
                  marginTop: '40px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  width: '100%',
                  borderColor: '#A58B62',
                  borderWidth: '1px',
                  borderRadius: '5px',
                  color: '#A58B62',
                }}
                onClick={() => {
                  updateUserF();
                  dispatch(contributionActions.setPopUpBank(false));
                  // setRun(false);
                }}
              >
                Mark as Complete
              </Button>
            )}
          </div>
        </div>
      ),
      placement: 'left',
      target: '#connectBank',
    },
  ]);

  const updateUserF = async (item, goToNextStep = false) => {
    let obj;
    if (item === 'isPlan') {
      obj = {
        id: user.id,
        userProgressbarData: {
          ...user.userProgressbarData,
          IsPlanPurchased: true,
        },
        progressBarPercentage: user.progressBarPercentage,
      };
    } else {
      obj = {
        id: user.id,
        userProgressbarData: {
          ...user.userProgressbarData,
          IsBankAccountConnected: true,
        },
        progressBarPercentage: user.progressBarPercentage,
      };
    }

    await UpdateUserProgressBarData(obj).then(res => {
      let u = {
        ...user,
        userProgressbarData: { ...res.userProgressbarData },
        progressBarPercentage: res.progressBarPercentage,
      };
      dispatch(updateUser(u));
      if (res.progressBarPercentage == 100) {
        setShowModal(true);
      }
      if (goToNextStep) {
        if (res.userProgressbarData?.IsPlanPurchased === false) {
          history.push('/account/payment', { from: 'verify' });
        } else if (res?.userProgressbarData?.IsBankAccountConnected === false) {
          dispatch(contributionActions.setPopUpBank(true));
          history.push('/account/payment', { from: 'bankAccount' });
        } else if (res?.userProgressbarData?.IntegrationsDone === false) {
          history.push('/account/integrations', { from: 'bankAccount' });
        } else if (res?.userProgressbarData?.IsProfileUploaded === false) {
          history.push('/account/profile');
        } else if (res?.userProgressbarData?.FirstContributionDone === false) {
          dispatch(contributionActions.setPopUp(true));
          dispatch(contributionActions.setShowCompletedGetStart('create'));
          history.push('/dashboard');
        } else if (res?.userProgressbarData?.IsWebsiteAdded === false) {
          history.push('/account/profile', { from: 'Manage Your Website Information' });
        } else if (res?.userProgressbarData?.InviteLink === false) {
          contibId === null ? console.log('Helloo') : history.push(`contribution-view/${contibId}/about`);
        }
      }
    });
    if (goToNextStep) {
      joyRideHelpers.next();
    } else {
      joyRideHelpers.skip();
    }
  };

  const handleJoyrideCallback = data => {
    const { status, type, index } = data;
    const nextStepIndex = index + 1;

    const finishedStatuses = ['finished', 'skipped'];
    if (finishedStatuses.includes(status)) {
      setRun(false);
      dispatch(contributionActions.setPopUpBank(false));
    }
    if ([EVENTS.STEP_AFTER].includes(type)) {
      setStepIndex(nextStepIndex);
    }
    if (data.action === 'close') {
      setRun(false);
    }

    console.groupCollapsed(type);
    console.groupEnd();
  };

  const getHelpers = helpers => {
    joyRideHelpers = helpers;
  };
  return (
    <StyledGrid container direction="column" alignItems="center" spacing={3}>
      {showLoader && <Loader />}
      <Joyride
        // tooltipComponent={Tooltip}
        callback={handleJoyrideCallback}
        continuous={true}
        getHelpers={getHelpers}
        run={run}
        stepIndex={stepIndex}
        showProgress={false}
        showSkipButton={false}
        steps={steps}
        styles={{
          options: {
            primaryColor: '#CDBA8F',
            zIndex: 10000000,
          },
        }}
      />
      <VerificationCard setShowLoader={setShowLoader} />
      <BankAccountCard setShowLoader={setShowLoader} type={'nonaffiliate'} />
      <BalanceCard setShowLoader={setShowLoader} />
      <SalesSummaryCard setShowLoader={setShowLoader} />
      <CouponsCard setShowLoader={setShowLoader} />
      <ChatAndGetStartedButtons setShowLoader={setShowLoader} page={'ProfilePage'} />
      <Modal
        title=""
        isOpen={showModal}
        onCloseText="OK"
        hiddenCancel
        onSubmit={togglePopup}
        onCancel={togglePopup}
        disableFooter
      >
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <StyledImgCongrats previewImg={TemplateOne}></StyledImgCongrats>
          <div style={{ fontSize: '24px', color: '#215C73', fontWeight: 800 }}>CONGRATS!</div>
          <div style={{ color: '#A58B62', fontSize: '16px', marginTop: '13px', fontWeight: 450, fontFamily: 'Avenir' }}>
            You are fully set up & ready to transform lives!
          </div>
          <Button
            autoWidth
            backgroundColor={'#C9B382'}
            style={{
              marginTop: '40px',
              paddingTop: '10px',
              paddingBottom: '10px',
              marginBottom: '10px',
            }}
            onClick={togglePopup}
          >
            Let’s do this!
          </Button>
        </div>
      </Modal>
    </StyledGrid>
  );
}

export default Payment;
