import React from 'react';
import classes from './DropdownType.module.scss';
import Select from 'components/UI/Select';
import AddChoicesModal from 'pages/CreateQuizes/Pages/QuizContent/Modals/AddChoices/AddChoices';
import DropDownChoiceOption from './DropdownTypeOption/dropdowntypeoption';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';

const DropdownType = ({ index: questionIndex, question, setFieldValue, onRemoveChoice }) => {
  return (
    <div className={classes.options_container}>
      {question?.options?.map((option, index) => {
        return (
          <>
            <DropDownChoiceOption
              setFieldValue={setFieldValue}
              key={option?.id}
              index={index}
              option={option}
              onRemoveChoice={onRemoveChoice}
              questionIndex={questionIndex}
            />
            {option.value.length > 200 && (
              <div>
                <CommonErrorMessage align="left" message="Option should not be more than 200 characters" />
              </div>
            )}
          </>
        );
      })}
    </div>
  );
};
export default DropdownType;
