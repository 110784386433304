import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@material-ui/core';
import styled from 'styled-components';

import { useHeader, useUnifiedCommunity } from 'hooks';
import { CommunityPage, NewsFeedPage } from 'pages';

import MainContainer from './MainContainer';

const StyledMainSection = styled.div`
  padding: 10px 55px 0px;
  height: 100%;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  ${({ mobileView }) => mobileView && `padding: 16px;`}
`;

const CommunityContainer = ({ match: { path } }) => {
  const { isUnifiedCommunity } = useUnifiedCommunity();
  // useHeader(isUnifiedCommunity ? 'All Posts' : 'Communities');
  useHeader('Communities');
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MainContainer>
      <StyledMainSection mobileView={mobileView}>
        <Switch>
          <Route component={NewsFeedPage} />
          {/* <Route component={CommunityPage} /> */}
          {/* <Route component={NewsFeedPage} /> */}
        </Switch>
      </StyledMainSection>
    </MainContainer>
  );
};

CommunityContainer.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
};

export default CommunityContainer;
