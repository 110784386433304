import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Modal from 'components/UI/Modal';
import { Rating, Box, TextField } from '@mui/material';
import Logo from 'components/FormUI/Logo';
import CloseIcon from '@material-ui/icons/Close';
import ThankYouModal from 'components/Modals/ThankYouModal';
import { sendUserFeedback } from 'services/user.service';
import { useAccount } from 'hooks';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  position: relative;
  width: 100%;
`;

const LogoContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  margin-right: -15px;
  ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? 'color: #fff' : 'color: black')}
`;

const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 10px 20px;
  background: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#2d2f31' : '#fff')};
  color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#fff' : '#000')};
  width: ${({ mobileView }) => (mobileView ? '350px' : '500px')};
`;

const FeedbackTitle = styled.div`
  margin-top: ${({ mobileView }) => (mobileView ? '20px' : '60px')};
  margin-bottom: ${({ mobileView }) => (mobileView ? '10px' : '20px')};
  font-size: 22px;
  font-weight: 900;
  text-align: center;
  font-family: Avenir;
  font-style: normal;
  line-height: normal;
  color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#fff' : '#000')};
`;

const FeedbackSubtitle = styled.div`
  margin-bottom: ${({ mobileView }) => (mobileView ? '5px' : '15px')};
  font-size: 16px;
  font-weight: 800;
  text-align: center;
  font-family: Avenir;
  font-style: normal;
  line-height: 130%;
  color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#fff' : '#000')};
`;

const NoFeedbackSubtitle = styled.div`
  text-decoration: underline;
  text-align: center;
  font-family: Avenir;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 16px;
  text-transform: capitalize;
  cursor: pointer;
  margin-top: ${({ mobileView }) => (mobileView ? '8px' : '')};
  color: ${({ color }) => color};
`;

const ButtonContainers = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-top: 26px;
`;

const FeedbackTextArea = styled(TextField)`
  width: 100%;
  height: 100px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#555' : '#ccc')};
  color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#fff' : '#000')};

  .MuiInputBase-inputMultiline {
    height: auto !important;
  }

  & .MuiInputBase-input::placeholder {
    color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#FFFFFF' : '#000000')};
    font-family: Avenir;
  }

  .MuiInputBase-root {
    padding: 10px;
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#FFFFFF' : '')};
  }

  & .MuiInputBase-root:hover {
    border-color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#FFFFFF' : '')};
  }

  & .MuiInputBase-input {
    color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#FFFFFF' : '')};
    caret-color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#FFFFFF' : '')};
    font-family: Avenir;
  }
`;

const FeedbackButton = styled.button`
  margin-bottom: 20px;
  padding: 12px 32px;
  background-color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#333' : '#C9B382')};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 900;

  ${({ disableBorder = true }) =>
    css`
      &:disabled {
        color: #9b9b9b;
        ${!disableBorder &&
        css`
          border: 1px solid #9b9b9b;
        `};
        background-color: #ededed;
      }
    `}
`;

const CustomRating = styled(Rating)({
  '& .MuiRating-iconEmpty': {
    color: '#C9B382',
  },
});

const FeatureFeedbackModal = ({
  isOpen,
  handleClose,
  handleFinishFeedback,
  feature,
  isDarkModeEnabled,
  mobileView,
  primaryColorCode = 'var(--Cohere-Primary-Blue, #215c73)',
}) => {
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [isThankYouPopupOpen, setIsThankYouOpen] = useState(false);
  const [NPSFeedbackType, setNPSFeedbackType] = useState(feature);
  const { user } = useAccount();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    switch (feature) {
      case 'Lead Magnet':
        setNPSFeedbackType('LeadMagnet');
        break;
      case 'Service':
        setNPSFeedbackType('Contribution');
        break;
      case 'Email Campaign':
        setNPSFeedbackType('EmailCampaign');
        break;
      case 'Session':
        setNPSFeedbackType('Sessions');
        break;
      default:
        setNPSFeedbackType('');
        break;
    }
  }, [feature]);

  const handleFeedbackSubmit = () => {
    setLoading(true);
    let testData = {
      userId: user?.id,
      rating: rating,
      feedback: feedback ? feedback : '',
      dontRemindMe: false,
      type: NPSFeedbackType,
    };

    sendUserFeedback(testData)
      .then(res => {
        handleOpenThankYou();
        handleClose();
      })
      .catch(console.dir)
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFeedbackPreference = () => {
    setLoading(true);
    let testData = {
      userId: user?.id,
      rating: 0,
      feedback: feedback ? feedback : '',
      dontRemindMe: true,
      type: NPSFeedbackType,
    };

    sendUserFeedback(testData)
      .then(res => {
        handleCancel();
      })
      .catch(console.dir)
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    handleClose();
    if (handleFinishFeedback) {
      handleFinishFeedback();
    }
  };

  const handleOpenThankYou = () => setIsThankYouOpen(true);

  const handleCloseThankYou = () => {
    setIsThankYouOpen(false);
    if (handleFinishFeedback) {
      handleFinishFeedback();
    }
  };

  return (
    <>
      <Modal
        className={isDarkModeEnabled ? 'feature-feedback-form-modal-dark' : 'feature-feedback-form-modal'}
        isOpen={isOpen}
        onCancel={handleCancel}
        disableFooter
        CrossIconHide
        disableOverlayClick
      >
        <FeedbackContainer mobileView={mobileView} isDarkModeEnabled={isDarkModeEnabled}>
          <HeaderContainer>
            <StyledCloseIcon isDarkThemeEnabled={isDarkModeEnabled} onClick={handleCancel} />
          </HeaderContainer>
          <LogoContainer>{mobileView ? <Logo startlogo /> : <Logo islarge startlogo />}</LogoContainer>
          <FeedbackTitle mobileView={mobileView} isDarkModeEnabled={isDarkModeEnabled}>
            Customer Feedback
          </FeedbackTitle>
          <FeedbackSubtitle mobileView={mobileView} isDarkModeEnabled={isDarkModeEnabled}>
            How would you rate the {feature} feature on Cohere?
          </FeedbackSubtitle>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <CustomRating
              name="customer-feedback-rating"
              value={rating}
              onChange={(event, newValue) => setRating(newValue)}
              sx={{
                color: '#C9B382',
                fontSize: mobileView ? '45px' : '60px',
              }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 1 }}>
              {[1, 2, 3, 4, 5].map(number => (
                <Box key={number} sx={{ flex: 1, textAlign: 'center' }}>
                  {number}
                </Box>
              ))}
            </Box>
          </Box>
          <FeedbackSubtitle style={{ marginTop: mobileView ? '10px' : '20px' }} isDarkModeEnabled={isDarkModeEnabled}>
            Your feedback <span style={{ fontWeight: 400 }}>(optional)</span>
          </FeedbackSubtitle>
          <FeedbackTextArea
            placeholder="Anything you would like to add? Your input is valuable to us"
            value={feedback}
            onChange={event => setFeedback(event.target.value)}
            isDarkModeEnabled={isDarkModeEnabled}
            multiline
            rows={3}
            inputProps={{ maxLength: 500 }}
            helperText={`${feedback.length}/500`}
            sx={{
              '& .MuiFormHelperText-root': {
                color: isDarkModeEnabled ? '#fff' : 'rgba(0, 0, 0, 0.87)',
                fontSize: '0.75rem !important',
                fontWeight: 'normal !important',
                fontFamily: 'Avenir',
                lineHeight: '1.5rem',
                letterSpacing: '0.08px',
                marginLeft: '0px',
                marginRight: '0px',
                textAlign: 'end',
              },
            }}
          />
          <ButtonContainers>
            <FeedbackButton onClick={handleFeedbackSubmit} disabled={rating === 0 || loading}>
              Send Feedback
            </FeedbackButton>
            <NoFeedbackSubtitle
              mobileView={mobileView}
              onClick={handleFeedbackPreference}
              isDarkModeEnabled={isDarkModeEnabled}
              color={primaryColorCode}
            >
              I prefer to not provide feedback
            </NoFeedbackSubtitle>
          </ButtonContainers>
        </FeedbackContainer>
      </Modal>

      <ThankYouModal
        isOpen={isThankYouPopupOpen}
        handleClose={handleCloseThankYou}
        isDarkModeEnabled={isDarkModeEnabled}
        mobileView={mobileView}
        title="Thank you for your feedback!"
        subtitle="We are grateful for your partnership. Your feedback is invaluable in helping us continually reflect and evolve to be the best possible platform for your business."
      />
    </>
  );
};

export default FeatureFeedbackModal;
