import React, { useMemo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CoachImage from 'assets/chatlogo.png';
import Button from 'components/FormUI/Button';
import { CircularProgress, ClickAwayListener, useMediaQuery, useTheme } from '@material-ui/core';
import { useAccount, useClientPreview, useRouter, useShallowEqualSelector } from 'hooks';
import ClickDropDown from 'components/UI/ClickDropDown';
import {
  determineDarkThemedColorToUse,
  getLatestApproved,
  getOneToOneSelfBookClient,
  updateApplicationFormPreviewStatus,
} from 'services/contributions.service';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import ContributionHeaderView, {
  StyledNavLink,
  StyledText,
  ApplicationFormBanner,
} from 'components/UI/ContributionHeaderView';
import * as contributionActions from 'actions/contributions';
import { ContributionStatus, ContributionType, UserRoles } from 'helpers/constants';
import Modal from 'components/UI/Modal';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import usePaidTier from 'hooks/usePaidTier';
import TemplateOne from 'assets/congratulations.png';
import * as S from './styled';
import { TemplateType } from 'helpers/constants/templateType';
import { connect, useDispatch } from 'react-redux';
import { determineColorToUse, getThemedColors } from 'services/contributions.service';
import { stickHeader, unstickHeader } from 'actions/header';
import { ClientPreviewModeOn } from 'actions/clientpreviewmode';
import { useLocation } from 'react-router-dom';
import { lightOrDark } from 'utils/utils';
import CreateApplicationFormModal from './ApplicationForm/CreateApplicationFormModal';
import styled from 'styled-components';
import { StyledDropDownItem } from 'components/UI/ClickDropDown';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import Joyride, {
  CallBackProps,
  STATUS,
  Step,
  EVENTS,
  StoreHelpers,
  BeaconRenderProps,
  TooltipRenderProps,
} from 'react-joyride';
import { UpdateUserProgressBarData } from 'services/user.service';
import { updateUser } from 'actions/update-user';
import { getUpcomingCreated } from 'services/contributions.service';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { redirectToAppIfIsCoachDomain } from 'utils/subdomains';
import { FaBedPulse } from 'react-icons/fa6';
import { isEmpty, isObject } from 'lodash';
import * as paidTier from 'selectors/paidTier';
import { setCohereAcademyStatus } from 'actions/update-user';
import { PAID_TIER_TITLES, TOOLTIP } from '../../../../constants';
import ScheduleMeetingModal from '../CalendarContributionView/ScheduleMeetingModal';
import AffiliateShareModal from '../CalendarContributionView/AffiliateModal';
import { ConversionMetricsModal } from 'components/Modals/ConversionMetricsModal';
import EasyBooking from 'pages/ContributionView/ContributionSessions/OneToOneSessions/EasyBooking/EasyBooking';
import SessionBookingThankyou from 'pages/ContributionView/ContributionSessions/OneToOneSessions/EasyBooking/components/SessionBookingThankyou';

const StyledImg = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 40px;
  align-items: center;
  z-index: 1;
`;

const HyperLink = styled.a`
  visibility: hidden;
`;
const StyledImgCongrats = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
  display: inline-block;
  // border-radius: 40px;
  align-items: center;
  z-index: 1;
`;

const StyledIconsWrapper = styled.div`
  display: flex;
  flex-direction: ${({ mobileView }) => (mobileView ? 'column' : '')};
  align-items: center;
  padding-right: ${({ mobileView }) => (mobileView ? '0px' : '0px')};
`;
const HeaderTabs = [
  { name: 'About', id: 'contribution-view-about-link' },
  { name: 'Sessions', id: 'contribution-view-sessions-link' },
  { name: 'Community', id: 'contribution-view-community-link' },
  { name: 'Notes', id: 'contribution-view-personal-notes-link' },
  { name: 'Team', id: 'contribution-view-team-link' },
  { name: 'Invite', id: 'contribution-view-invite-clients-link' },
  { name: 'Testimonials', id: 'contribution-view-reviews-link' },
  { name: 'Emails & SMS', id: 'contribution-view-reviews-link' },
  { name: 'Resources', id: 'contribution-view-resources-link' },
  { name: 'Preview', id: 'contribution-view-client-preview-link' },
  { name: 'Calendar', id: 'contribution-view-calendar-link' },
  { name: 'Analytics', id: 'contribution-view-analytics-link' },
];
const TRIAL_PERIOD_START_DATE = '2021-11-01';
const TRIAL_PERIOD_DAYS = 13;

const ContributionHeader = ({ isCoach, canAccess, onShareButtonClick, status, activeContribution, columnView }) => {
  const {
    match: { url },
    domain,
  } = useRouter();
  const anchorRef = useRef();
  const { clientPreviewMode } = useClientPreview();
  const contributionTimes = useSelector(state => state.contributions?.contributionTimes);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const isOneToOneType = activeContribution?.type === ContributionType.contributionOneToOne;
  const showCompletedGetStart = useSelector(state => state.contributions.showCompletedGetStart);
  const refreshStep = showCompletedGetStart === 'refreshInvite';
  let colorToUse = determineColorToUse(activeContribution);
  const isDarkModeEnabled = determineDarkThemedColorToUse(activeContribution);
  let textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const [affiliateModalOpen, setAffiliateModalOpen] = useState(false);
  const isInviteToJoin = useSelector(state => state.contributions.inviteCode);
  const urlWithCode = isInviteToJoin ? `${url}/${isInviteToJoin}` : url;
  const userRole = useShallowEqualSelector(state => state.account.currentRole);
  const { user } = useAccount();
  const { history } = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const isPurchased = activeContribution?.isPurchased;
  const contName = activeContribution?.title;
  const [showApplicationFormPopup, setShowApplicationFormPopup] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [contibId, setContibId] = useState(null);
  const [rescheduleSession, setRescheduleSession] = useState(false);
  const [isRescheduleModalOpen, setRescheduleModalState] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [analyticsModalOpened, setAnalyticsModalOpened] = useState(false);

  const [isScheduleMeetingModalOpen, setIsScheduleMeetingModalOpen] = useState(false);

  const [sessionInviteData, setSessionInviteData] = useState(null);
  const isCodeToJoin = useSelector(state => state?.contributions?.singleCode);
  const priceId = useSelector(state => state.contributions.priceCode);
  useEffect(() => {
    if (isCodeToJoin) {
      getSelfBookData();
    }
  }, []);

  const getSelfBookData = () => {
    getOneToOneSelfBookClient(isCodeToJoin.slice(2)).then(response => {
      setSessionInviteData(response);
    });
  };
  useEffect(() => {
    getLatestApproved().then(res => {
      setContibId(res);
    });
  }, []);
  const togglePopup = () => {
    setShowModal(!showModal);
  };
  // const { isLaunchPlan } = usePaidTier();
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  const isLaunchPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch;

  // Correct behavior - 100%

  useEffect(() => {
    if (activePaidTierCurrentPlan?.default) {
      dispatch(setCohereAcademyStatus(true));
    } else {
      dispatch(setCohereAcademyStatus(false));
    }
  }, [activePaidTierCurrentPlan, dispatch]);

  const handleAnalyticsModalClose = () => {
    setAnalyticsModalOpened(false);
  };

  const { type } = useContribution();
  const theme = useTheme();
  const activeTemplate = activeContribution?.activeTemplate || TemplateType.TemplateThree;
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const isMobileSidebarNeeded = useMediaQuery(theme.breakpoints.down('sm'));
  const maxWidth = useMediaQuery('(max-width: 1366px)');
  const removeBackgroundColor =
    !isCoach &&
    // type != ContributionType.contributionOneToOne &&
    activeTemplate != TemplateType.TemplateThree &&
    isMobileSidebarNeeded;
  const isCohealer = useMemo(() => userRole === UserRoles.cohealer, [userRole]);
  const isTeamTabVisible = useMemo(
    () =>
      (type === ContributionType.contributionCourse ||
        type === ContributionType.contributionMembership ||
        type === ContributionType.contributionCommunity) &&
      isCoach,
    [isCoach, type],
  );
  const isCalendarTabVisible = useMemo(
    () =>
      (type !== ContributionType.contributionOneToOne &&
        (type === ContributionType.contributionCourse ||
          type === ContributionType.contributionMembership ||
          type === ContributionType.contributionWorkshop ||
          type === ContributionType.contributionCommunity) &&
        // allowing for logged-in clients, avoiding guests (not logged in user))
        isObject(user) &&
        !isEmpty(user) &&
        userRole === UserRoles.cohealer &&
        !clientPreviewMode) ||
      (userRole === UserRoles.client &&
        isObject(user) &&
        !isEmpty(user) &&
        type !== ContributionType.contributionOneToOne &&
        (type === ContributionType.contributionCourse ||
          type === ContributionType.contributionMembership ||
          type === ContributionType.contributionWorkshop ||
          type === ContributionType.contributionCommunity) &&
        activeContribution?.isCalendarHidden === true),
    [type, userRole, user, activeContribution],
  );
  const trialPeriodStart = moment(user.createTime);
  const trialPeriodEnd = moment(user.createTime).add(TRIAL_PERIOD_DAYS, 'days');
  const todayDate = moment(moment());
  const { themedColor: themedTextColor, newThemedTextColor } = getThemedColors(activeContribution);
  const themedColor = isCoach ? themedTextColor : newThemedTextColor;
  const contributionViewPath = pathname?.includes('apply-contribution-view')
    ? 'apply-contribution-view'
    : 'contribution-view';
  const paymentInfo = activeContribution?.paymentInfo.paymentOptions;
  const stickH = () => {
    dispatch(stickHeader());
  };

  const unstickH = () => {
    dispatch(unstickHeader());
  };

  const [showApplicationFormBanner, setShowApplicationFormBanner] = useState(true);

  const setBannerPreviewedStatusInContribution = () => {
    const data = updateApplicationFormPreviewStatus(activeContribution.id).then(data => {
      setShowApplicationFormBanner(false);
    });
  };

  const inviteClientIsDisabled =
    status !== ContributionStatus?.approved ||
    (isLaunchPlan &&
      trialPeriodStart.isSameOrAfter(TRIAL_PERIOD_START_DATE, 'day') &&
      todayDate.isAfter(trialPeriodEnd));
  const teamTab = useMemo(
    () => (
      <StyledNavLink
        mobileView={mobileView}
        id={HeaderTabs[4].id}
        to={`/${contributionViewPath}/${activeContribution?.id}/team`}
        colorToUse={colorToUse}
        color={themedColor}
        activeTemplate={activeTemplate}
        type={activeContribution?.type}
        isCoach={isCoach}
        canAccess={canAccess}
        AboutLinkStyled={true}
        // style={{ textDecoration: 'none' }}
        onClick={unstickH}
      >
        {HeaderTabs[4].name}
      </StyledNavLink>
    ),
    [mobileView, url, themedColor],
  );
  const [run, setRun] = useState(
    !user?.userProgressbarData?.InviteLink || (user?.userProgressbarData?.InviteLink === true && refreshStep),
  );
  const [stepIndex, setStepIndex] = useState(0);
  let joyRideHelpers;

  const [steps, setSteps] = useState([
    {
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step4">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Invite New Clients & Impact Lives
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            You can invite clients to your service by sharing the link.
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            {refreshStep === false && (
              <Button
                className="btn-left"
                autoWidth
                backgroundColor={'white'}
                invert
                style={{
                  marginTop: '40px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  width: '100%',
                  borderColor: '#A58B62',
                  borderWidth: '1px',
                  borderRadius: '5px',
                  color: '#A58B62',
                }}
                onClick={() => {
                  updateUserF();
                  // setRun(false);
                }}
              >
                Mark as Complete
              </Button>
            )}
            {/* <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                // setRun(false);
              }}
            >
              Next
            </Button> */}
          </div>
        </div>
      ),
      placement: 'bottom',
      target: '#inviteclient-dropdown',
    },
  ]);

  const updateUserF = async click => {
    let obj = {
      id: user.id,
      userProgressbarData: {
        ...user.userProgressbarData,
        InviteLink: true,
        FirstContributionDone: true,
      },
    };

    await UpdateUserProgressBarData(obj).then(res => {
      let u = {
        ...user,
        userProgressbarData: { ...res.userProgressbarData },
        progressBarPercentage: res.progressBarPercentage,
      };
      dispatch(updateUser(u));
      if (res.progressBarPercentage == 100) {
        setShowModal(true);
      }
      if (res.userProgressbarData?.IsPlanPurchased === false) {
        redirectToAppIfIsCoachDomain(domain, '/account/payment', 'from=verify');
      } else if (res?.userProgressbarData?.IsBankAccountConnected === false) {
        redirectToAppIfIsCoachDomain(domain, '/account/payment', 'from=bankAccount');
      } else if (res?.userProgressbarData?.IntegrationsDone === false) {
        redirectToAppIfIsCoachDomain(domain, '/account/integrations', 'from=bankAccount');
      } else if (res?.userProgressbarData?.IsProfileUploaded === false) {
        redirectToAppIfIsCoachDomain(domain, '/account/profile');
      } else if (res?.userProgressbarData?.FirstContributionDone === false) {
        dispatch(contributionActions.setPopUp(true));
        redirectToAppIfIsCoachDomain(domain, '/dashboard');
      } else if (res?.userProgressbarData?.IsWebsiteAdded === false) {
        redirectToAppIfIsCoachDomain(domain, '/account/profile', 'from=Manage Your Website Information');
      } else if (res?.userProgressbarData?.InviteLink === false) {
        contibId === null ? console.log('Helloo') : history.push(`${contributionViewPath}/${contibId}/about`);
      }
    });
    joyRideHelpers.skip();
  };

  useEffect(() => {
    if (user?.userProgressbarData?.InviteLink === true && refreshStep) {
    } else if (!!user?.userProgressbarData?.InviteLink) {
      setSteps([]);
    }
  }, []);

  const getHelpers = helpers => {
    joyRideHelpers = helpers;
  };

  const handleJoyrideCallback = data => {
    const { status, type, index } = data;
    const nextStepIndex = index + 1;

    const finishedStatuses = ['finished', 'skipped'];
    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
    if ([EVENTS.STEP_AFTER].includes(type)) {
      setStepIndex(nextStepIndex);
    }
    if (data.action === 'close') {
      setRun(false);
    }

    console.groupCollapsed(type);
    console.groupEnd();
  };
  // const inviteClients = useMemo(
  //   () => (
  //     <>

  //       <S.StyledShareButton
  //         themedColor={themedColor}
  //         onClick={onShareButtonClick}
  //         disabled={
  //           status !== ContributionStatus.approved ||
  //           (isLaunchPlan &&
  //             trialPeriodStart.isSameOrAfter(TRIAL_PERIOD_START_DATE, 'day') &&
  //             todayDate.isAfter(trialPeriodEnd))
  //         }
  //       >
  //         <S.StyledShareIcon />
  //         {HeaderTabs[5].name}
  //       </S.StyledShareButton>
  //     </>
  //   ),
  //   [onShareButtonClick, status, isLaunchPlan],
  // );

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getScheduleClient = () => {
    if (contributionTimes === undefined) {
      return (
        <StyledDropDownItem inviteClient lastItem loader isDarkModeEnabled={isDarkModeEnabled}>
          <CircularProgress style={{ margin: 'auto' }} size={20} />
        </StyledDropDownItem>
      );
    }
    if (activeContribution?.isAccountNeeded) {
      return (
        <StyledDropDownItem
          inviteClient
          lastItem
          onClick={() => {
            setIsScheduleMeetingModalOpen(true);
            setIsDropDownOpen(false);
          }}
          isDarkModeEnabled={isDarkModeEnabled}
        >
          Schedule Client
        </StyledDropDownItem>
      );
    }
    return (
      <Tooltip
        title="To schedule a client, you must set up a 1:1 service with a complete booking experience selected on step one of creating a service."
        arrow
      >
        <StyledDropDownItem
          inviteClient
          lastItem
          onClick={() => {}}
          className="disabled"
          isDarkModeEnabled={isDarkModeEnabled}
          as="div"
        >
          Schedule Client
        </StyledDropDownItem>
      </Tooltip>
    );
  };

  const inviteClients = useMemo(() => {
    if (isOneToOneType) {
      return (
        <ClickAwayListener
          onClickAway={() => {
            setIsDropDownOpen(false);
          }}
        >
          <ClickDropDown
            id="inviteclient-dropdown"
            title={''}
            mobileView={mobileView}
            layover={run}
            // style={isCustomPrevButton ? { color: isCoach ? themedColor : newThemedTextColor } : {}}
            isCoach={true}
            clientPreviewMode={clientPreviewMode}
            isOpenNow={isDropDownOpen}
            isShareContributionLink={true}
            isOneToOne
          >
            <StyledDropDownItem
              inviteClient
              firstItem
              onClick={e => {
                onShareButtonClick(e);
                setIsDropDownOpen(false);
              }}
              isDarkModeEnabled={isDarkModeEnabled}
            >
              Client Self-booking Links
            </StyledDropDownItem>
            {getScheduleClient()}
          </ClickDropDown>
        </ClickAwayListener>
      );
    } else {
      return (
        <ClickAwayListener
          onClickAway={() => {
            setIsDropDownOpen(false);
          }}
        >
          <S.StyledInviteOneToOneContainer
            // id={'invite'}
            id={'inviteclient-dropdown'}
            color={themedColor}
            isDarkModeEnabled={isDarkModeEnabled}
            onClick={e => {
              e.stopPropagation();
              setIsDropDownOpen(!isDropDownOpen);
            }}
            style={{ marginRight: mobileView ? '8px' : '0px', background: 'none' }}
            mobileView={mobileView}
          >
            {windowWidth >= 1320 ? (
              <>
                <S.StyledShareIcon
                  onClick={e => {
                    e.stopPropagation();
                    setIsDropDownOpen(!isDropDownOpen);
                  }}
                />
                <p
                  style={{ margin: '0px', cursor: 'pointer' }}
                  onClick={e => {
                    e.stopPropagation();
                    setIsDropDownOpen(!isDropDownOpen);
                  }}
                >
                  Invite
                </p>
              </>
            ) : (
              <Tooltip
                className="cursor-pointer text-grey"
                title="Invite"
                arrow
                enterTouchDelay={TOOLTIP.ENTER_DELAY}
                leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
              >
                <S.StyledShareIcon
                  mobileView={mobileView}
                  onClick={e => {
                    e.stopPropagation();
                    setIsDropDownOpen(!isDropDownOpen);
                  }}
                />
              </Tooltip>
            )}
            <ClickDropDown
              id="inviteclient-dropdown"
              title={''}
              mobileView={mobileView}
              layover={run}
              // style={isCustomPrevButton ? { color: isCoach ? themedColor : newThemedTextColor } : {}}
              isCoach={true}
              clientPreviewMode={clientPreviewMode}
              isOpenNow={isDropDownOpen}
              showDownArrow={false}
            >
              <StyledDropDownItem
                inviteClient
                firstItem
                onClick={onShareButtonClick}
                isDarkModeEnabled={isDarkModeEnabled}
                disabled={
                  user?.isPartnerCoach
                    ? false
                    : status !== ContributionStatus.approved ||
                      (isLaunchPlan &&
                        trialPeriodStart.isSameOrAfter(TRIAL_PERIOD_START_DATE, 'day') &&
                        todayDate.isAfter(trialPeriodEnd))
                }
              >
                Clients
                <Tooltip
                  title="Click this to create paid or free links to share with your clients."
                  arrow
                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                >
                  <InfoIcon
                    style={{ marginLeft: '10px', marginBottom: '3px' }}
                    htmlColor={colorToUse.PrimaryColorCode}
                  />
                </Tooltip>
              </StyledDropDownItem>
              <StyledDropDownItem
                inviteClient
                lastItem
                onClick={() => {
                  setAffiliateModalOpen(true);
                }}
                isDarkModeEnabled={isDarkModeEnabled}
                disabled={status !== ContributionStatus.approved}
              >
                Affiliates
                <Tooltip
                  title="Click here to create paid or free shareable affiliate links to bring more clients to your services."
                  arrow
                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                >
                  <InfoIcon
                    style={{ marginLeft: '10px', marginBottom: '3px' }}
                    htmlColor={colorToUse.PrimaryColorCode}
                  />
                </Tooltip>
              </StyledDropDownItem>
            </ClickDropDown>
          </S.StyledInviteOneToOneContainer>
        </ClickAwayListener>
        // <S.StyledShareButton
        //   width="unset"
        //   id={'invite'}
        //   themedColor={themedColor}
        //   onClick={onShareButtonClick}
        // disabled={
        //   status !== ContributionStatus.approved ||
        //   (isLaunchPlan &&
        //     trialPeriodStart.isSameOrAfter(TRIAL_PERIOD_START_DATE, 'day') &&
        //     todayDate.isAfter(trialPeriodEnd))
        // }
        //   isDarkModeEnabled={isDarkModeEnabled}
        // >
        //   <S.StyledShareIcon />
        //   {HeaderTabs[5].name}
        // </S.StyledShareButton>
      );
      // return (
      //   <S.StyledShareButton
      //     id={'invite'}
      //     themedColor={themedColor}
      //     onClick={onShareButtonClick}
      //     disabled={
      //       status !== ContributionStatus.approved ||
      //       (isLaunchPlan &&
      //         trialPeriodStart.isSameOrAfter(TRIAL_PERIOD_START_DATE, 'day') &&
      //         todayDate.isAfter(trialPeriodEnd))
      //     }
      //     isDarkModeEnabled={isDarkModeEnabled}
      //   >
      //     <S.StyledShareIcon />
      //     {HeaderTabs[5].name}
      //   </S.StyledShareButton>
      // );
    }
  }, [onShareButtonClick, status, isLaunchPlan, isDropDownOpen, windowWidth, contributionTimes]);

  const viewAnalytics = useMemo(
    () => (
      <S.StyledShareButton
        width="unset"
        themedColor={themedColor}
        onClick={() => {
          setAnalyticsModalOpened(true);
        }}
        disabled={status !== ContributionStatus.approved}
        isDarkModeEnabled={isDarkModeEnabled}
        style={{ marginRight: windowWidth <= 1320 && windowWidth >= 576 ? '13px' : '', background: 'none' }}
      >
        {windowWidth >= 1320 ? (
          <S.StyledAnalyticsIcon />
        ) : (
          <Tooltip
            className="cursor-pointer text-grey"
            title={HeaderTabs[11].name}
            arrow
            enterTouchDelay={TOOLTIP.ENTER_DELAY}
            leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
          >
            <S.StyledAnalyticsIcon />
          </Tooltip>
        )}
        {windowWidth >= 1320 && HeaderTabs[11].name}
      </S.StyledShareButton>
    ),
    [status, themedColor, windowWidth],
  );

  const clientPreview = useMemo(
    () => (
      <S.StyledShareButton
        width="unset"
        themedColor={themedColor}
        onClick={() => {
          dispatch(ClientPreviewModeOn(`/contribution-view/${activeContribution?.id}/about`));
          // history.replace(`/contribution-view/${activeContribution?.id}/clientpreview/about`);
          // window.open(`${domain}/contribution-view/${activeContribution?.id}/clientpreview/about`, '_blank', '');
          // win.focus();
          if (anchorRef?.current) {
            anchorRef.current.click();
          }
        }}
        disabled={
          status !== ContributionStatus.approved ||
          (isLaunchPlan &&
            trialPeriodStart.isSameOrAfter(TRIAL_PERIOD_START_DATE, 'day') &&
            todayDate.isAfter(trialPeriodEnd))
        }
        isDarkModeEnabled={isDarkModeEnabled}
        style={{
          marginRight: windowWidth <= 1320 && windowWidth >= 576 ? '-11px' : '8px',
          marginLeft: windowWidth <= 1320 && windowWidth >= 576 ? '-27px' : '',
          background: 'none',
        }}
      >
        {windowWidth >= 1320 ? (
          <S.StyledPreviewIcon />
        ) : (
          <Tooltip
            className="cursor-pointer text-grey"
            title={HeaderTabs[9].name}
            arrow
            enterTouchDelay={TOOLTIP.ENTER_DELAY}
            leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
          >
            <S.StyledPreviewIcon />
          </Tooltip>
        )}
        {windowWidth >= 1320 && HeaderTabs[9].name}
      </S.StyledShareButton>
    ),
    [status, isLaunchPlan, themedColor, windowWidth],
  );

  const customIsActive = (match, location) => {
    // Implement your custom logic here to determine if the NavLink should be active
    if (location.pathname.includes('about')) {
      if (
        activeContribution.type == ContributionType.contributionOneToOne &&
        (activeContribution.isPurchased != false || activeContribution.purchaseStatus != 'unpurchased') &&
        activeContribution?.packagePurchases?.length > 0 &&
        isEmpty(user) === true
      ) {
        dispatch(contributionActions.setSingleCode(null));
      }
      return true;
    } else {
      return false;
    }
  };

  const customSessionIsActive = (match, location) => {
    // Implement your custom logic here to determine if the NavLink should be active
    if (location.pathname.includes('sessions') && !location.pathname.includes('community')) {
      if (
        activeContribution.type == ContributionType.contributionOneToOne &&
        (activeContribution.isPurchased != false || activeContribution.purchaseStatus != 'unpurchased') &&
        activeContribution?.packagePurchases?.length > 0 &&
        isEmpty(user) === true
      ) {
        dispatch(contributionActions.setSingleCode(null));
      }
      return true;
    } else {
      return false;
    }
  };

  const currentDate = moment();
  const startTimes = contributionTimes?.map(item => item.startTime);
  const filteredDates = startTimes?.filter(date => {
    const dateObj = moment(date);
    return dateObj.isSameOrAfter(currentDate, 'day');
  });

  return (
    <>
      <Joyride
        // tooltipComponent={Tooltip}
        callback={handleJoyrideCallback}
        continuous={true}
        getHelpers={getHelpers}
        run={run}
        stepIndex={stepIndex}
        showProgress={false}
        showSkipButton={false}
        steps={steps}
        styles={{
          options: {
            primaryColor: '#CDBA8F',
            zIndex: 10000000,
          },
        }}
      />
      <ContributionHeaderView
        mobileView={mobileView}
        removeBackgroundColor={removeBackgroundColor}
        columnView={columnView}
        isCoach={isCoach}
        contribution={activeContribution}
        maxwidth={maxWidth}
      >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: columnView ? 'center' : 'space-between' }}>
          <div style={columnView ? { display: 'flex', flexDirection: 'column', alignItems: 'center' } : {}}>
            {isCoach && type === ContributionType.contributionCommunity && (
              <StyledNavLink
                colorToUse={colorToUse}
                color={themedColor}
                activeTemplate={activeTemplate}
                type={activeContribution?.type}
                isCoach={isCoach}
                canAccess={canAccess}
                mobileView={mobileView}
                columnView={columnView}
                id={HeaderTabs[2].id}
                to={
                  activeContribution?.isCommunityFeatureHiddden === false
                    ? `${urlWithCode}/community`
                    : `${urlWithCode}/community/clients`
                }
                // style={{ textDecoration: 'none' }}
                onClick={unstickH}
              >
                {activeContribution?.isCommunityFeatureHiddden === false ? HeaderTabs[2].name : 'Members'}
              </StyledNavLink>
            )}
            {canAccess &&
              !isCoach &&
              type === ContributionType.contributionCommunity &&
              activeContribution?.isCommunityFeatureHiddden === false && (
                <StyledNavLink
                  colorToUse={colorToUse}
                  color={themedColor}
                  activeTemplate={activeTemplate}
                  type={activeContribution?.type}
                  isCoach={isCoach}
                  canAccess={canAccess}
                  mobileView={mobileView}
                  columnView={columnView}
                  id={HeaderTabs[2].id}
                  to={`${urlWithCode}/community`}
                  // style={{ textDecoration: 'none' }}
                  onClick={unstickH}
                >
                  {HeaderTabs[2].name}
                </StyledNavLink>
              )}
            <StyledNavLink
              // maxwidth={maxWidth}
              colorToUse={colorToUse}
              color={themedColor}
              activeTemplate={activeTemplate}
              type={activeContribution?.type}
              isCoach={isCoach}
              canAccess={canAccess}
              mobileView={mobileView}
              columnView={columnView}
              id={HeaderTabs[0].id}
              AboutLinkStyled={true}
              onClick={unstickH}
              isActive={customIsActive}
              to={
                isCodeToJoin
                  ? `/${contributionViewPath}/${activeContribution?.id}/${isCodeToJoin}/about`
                  : isInviteToJoin
                  ? `/${contributionViewPath}/${activeContribution?.id}/${isInviteToJoin}/about`
                  : priceId
                  ? `/${contributionViewPath}/${activeContribution?.id}/${priceId}/about`
                  : pathname?.includes('clientpreview')
                  ? `/${contributionViewPath}/${activeContribution?.id}/clientpreview/about`
                  : `/${contributionViewPath}/${activeContribution?.id}/about`
              }
            >
              {HeaderTabs[0].name}
            </StyledNavLink>
            {!isCoach &&
            isPurchased === false &&
            type === ContributionType.contributionOneToOne &&
            paymentInfo.includes('PerSession') === false &&
            isInviteToJoin === null ? (
              <></>
            ) : !isCoach &&
              type === ContributionType.contributionCourse &&
              activeContribution?.sessions?.length === 0 &&
              isPurchased === false ? (
              <></>
            ) : (
              <StyledNavLink
                activeTemplate={activeTemplate}
                colorToUse={colorToUse}
                color={themedColor}
                type={activeContribution?.type}
                isCoach={isCoach}
                canAccess={canAccess}
                AboutLinkStyled={true}
                mobileView={mobileView}
                columnView={columnView}
                id={HeaderTabs[1].id}
                to={
                  isCodeToJoin
                    ? `/${contributionViewPath}/${activeContribution?.id}/${isCodeToJoin}/sessions`
                    : isInviteToJoin
                    ? `/${contributionViewPath}/${activeContribution?.id}/${isInviteToJoin}/sessions`
                    : priceId
                    ? `/${contributionViewPath}/${activeContribution?.id}/${priceId}/sessions`
                    : pathname?.includes('clientpreview')
                    ? `/${contributionViewPath}/${activeContribution?.id}/clientpreview/sessions`
                    : `/${contributionViewPath}/${activeContribution?.id}/sessions`
                }
                onClick={stickH}
                isActive={customSessionIsActive}
              >
                {HeaderTabs[1].name}
              </StyledNavLink>
            )}
            {canAccess &&
              isCalendarTabVisible &&
              (isCoach ? (
                <StyledNavLink
                  activeTemplate={activeTemplate}
                  colorToUse={colorToUse}
                  color={themedColor}
                  type={activeContribution?.type}
                  isCoach={isCoach}
                  canAccess={canAccess}
                  AboutLinkStyled={true}
                  mobileView={mobileView}
                  columnView={columnView}
                  id={HeaderTabs[10].id}
                  to={
                    isCodeToJoin
                      ? `/${contributionViewPath}/${activeContribution?.id}/${isCodeToJoin}/calendar`
                      : isInviteToJoin
                      ? `/${contributionViewPath}/${activeContribution?.id}/${isInviteToJoin}/calendar`
                      : priceId
                      ? `/${contributionViewPath}/${activeContribution?.id}/${priceId}/calendar`
                      : pathname?.includes('clientpreview')
                      ? `/${contributionViewPath}/${activeContribution?.id}/clientpreview/calendar`
                      : `/${contributionViewPath}/${activeContribution?.id}/calendar`
                  }
                  onClick={stickH}
                >
                  {HeaderTabs[10].name}
                </StyledNavLink>
              ) : (
                <StyledNavLink
                  activeTemplate={activeTemplate}
                  colorToUse={colorToUse}
                  color={themedColor}
                  type={activeContribution?.type}
                  AboutLinkStyled={true}
                  mobileView={mobileView}
                  columnView={columnView}
                  id={HeaderTabs[10].id}
                  to={
                    isCodeToJoin
                      ? `/${contributionViewPath}/${activeContribution?.id}/${isCodeToJoin}/calendar`
                      : isInviteToJoin
                      ? `/${contributionViewPath}/${activeContribution?.id}/${isInviteToJoin}/calendar`
                      : priceId
                      ? `/${contributionViewPath}/${activeContribution?.id}/${priceId}/calendar`
                      : pathname?.includes('clientpreview')
                      ? `/${contributionViewPath}/${activeContribution?.id}/clientpreview/calendar`
                      : `/${contributionViewPath}/${activeContribution?.id}/calendar`
                  }
                  onClick={stickH}
                >
                  {HeaderTabs[10].name}
                </StyledNavLink>
              ))}
            {isCoach &&
              type !== ContributionType.contributionCommunity &&
              type !== ContributionType.contributionOneToOne && (
                <StyledNavLink
                  activeTemplate={activeTemplate}
                  type={activeContribution?.type}
                  colorToUse={colorToUse}
                  color={themedColor}
                  isCoach={isCoach}
                  canAccess={canAccess}
                  AboutLinkStyled={true}
                  mobileView={mobileView}
                  columnView={columnView}
                  id={HeaderTabs[2].id}
                  to={
                    activeContribution?.isCommunityFeatureHiddden === false
                      ? `${urlWithCode}/community`
                      : `${urlWithCode}/community/clients`
                  }
                  // isActive={customCommunityIsActive}
                  // style={{ textDecoration: 'none' }}
                  onClick={unstickH}
                >
                  {activeContribution?.isCommunityFeatureHiddden === false ? HeaderTabs[2].name : 'Members'}
                </StyledNavLink>
              )}
            {canAccess &&
              !isCoach &&
              type !== ContributionType.contributionCommunity &&
              activeContribution?.isCommunityFeatureHiddden === false && (
                <StyledNavLink
                  activeTemplate={activeTemplate}
                  type={activeContribution?.type}
                  colorToUse={colorToUse}
                  color={themedColor}
                  isCoach={isCoach}
                  canAccess={canAccess}
                  AboutLinkStyled={true}
                  mobileView={mobileView}
                  columnView={columnView}
                  id={HeaderTabs[2].id}
                  to={`${urlWithCode}/community`}
                  // isActive={customCommunityIsActive}
                  // style={{ textDecoration: 'none' }}
                  onClick={unstickH}
                >
                  {HeaderTabs[2].name}
                </StyledNavLink>
              )}
            {!isPurchased &&
              !isCoach &&
              (type == ContributionType.contributionCourse ||
                type == ContributionType.contributionMembership ||
                type == ContributionType.contributionCommunity) &&
              activeContribution?.isCommunityFeatureHiddden === false && (
                <StyledText
                  style={{ color: isDarkModeEnabled ? themedColor : '' }}
                  colorToUse={colorToUse}
                  activeTemplate={activeTemplate}
                  type={activeContribution?.type}
                  isCoach={isCoach}
                  canAccess={canAccess}
                  mobileView={mobileView}
                  columnView={columnView}
                  loggedIn={isEmpty(user) ? false : true}
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  {HeaderTabs[2].name}
                </StyledText>
              )}
            {isCoach &&
              type == ContributionType.contributionOneToOne &&
              activeContribution?.isCommunityFeatureHiddden === false && (
                <StyledNavLink
                  activeTemplate={activeTemplate}
                  type={activeContribution?.type}
                  colorToUse={colorToUse}
                  color={themedColor}
                  isCoach={isCoach}
                  canAccess={canAccess}
                  AboutLinkStyled={true}
                  mobileView={mobileView}
                  columnView={columnView}
                  id={HeaderTabs[2].id}
                  to={`${urlWithCode}/community`}
                  // isActive={customCommunityIsActive}
                  // style={{ textDecoration: 'none' }}
                  onClick={unstickH}
                >
                  {HeaderTabs[2].name}
                </StyledNavLink>
              )}
            {canAccess && (
              <StyledNavLink
                activeTemplate={activeTemplate}
                colorToUse={colorToUse}
                color={themedColor}
                type={activeContribution?.type}
                isCoach={isCoach}
                canAccess={canAccess}
                AboutLinkStyled={true}
                mobileView={mobileView}
                columnView={columnView}
                id={HeaderTabs[8].id}
                to={`/${contributionViewPath}/${activeContribution?.id}/resources`}
                // style={{ textDecoration: 'none' }}
              >
                {HeaderTabs[8].name}
              </StyledNavLink>
            )}
            {canAccess && (
              <StyledNavLink
                activeContribution={activeContribution}
                activeTemplate={activeTemplate}
                colorToUse={colorToUse}
                color={themedColor}
                type={activeContribution?.type}
                isCoach={isCoach}
                canAccess={canAccess}
                AboutLinkStyled={true}
                mobileView={mobileView}
                columnView={columnView}
                id={HeaderTabs[3].id}
                to={`/${contributionViewPath}/${activeContribution?.id}/notes`}
                onClick={unstickH}
              >
                {HeaderTabs[3].name}
              </StyledNavLink>
            )}
            {/* {canAccess && (
              <StyledNavLink mobileView={mobileView} id={HeaderTabs[3].id} to={`${urlWithCode}/team`}>
                {HeaderTabs[4].name}
              </StyledNavLink>
            )} */}
            {isTeamTabVisible && teamTab}
            {/* {isCohealer && (
              <S.StyledShareButton
                id="invite"
                themedColor={themedColor}
                onClick={e => {
                  onShareButtonClick(e);
                }}
                disabled={inviteClientIsDisabled}
              >
                <S.StyledShareIcon />
                {HeaderTabs[5].name}
              </S.StyledShareButton>
            )} */}
            {isCoach && (
              <StyledNavLink
                activeTemplate={activeTemplate}
                colorToUse={colorToUse}
                type={activeContribution?.type}
                isCoach={isCoach}
                canAccess={canAccess}
                color={themedColor}
                AboutLinkStyled={true}
                mobileView={mobileView}
                columnView={columnView}
                id={HeaderTabs[6].id}
                to={`/${contributionViewPath}/${activeContribution?.id}/reviews`}
                onClick={unstickH}
              >
                {HeaderTabs[6].name}
              </StyledNavLink>
            )}
            {isCoach && (
              <StyledNavLink
                activeTemplate={activeTemplate}
                colorToUse={colorToUse}
                type={activeContribution?.type}
                isCoach={isCoach}
                canAccess={canAccess}
                color={themedColor}
                AboutLinkStyled={true}
                mobileView={mobileView}
                columnView={columnView}
                id={HeaderTabs[7].id}
                to={`/${contributionViewPath}/${activeContribution?.id}/communication`}
              >
                {HeaderTabs[7].name}
              </StyledNavLink>
            )}
            <HyperLink
              ref={anchorRef}
              href={`https://${domain}/contribution-view/${activeContribution?.id}/clientpreview/about`}
              target="_blank"
            />
          </div>
          <StyledIconsWrapper mobileView={mobileView}>
            {isCoach && viewAnalytics}
            {isCoach && clientPreview}
            {isCoach && inviteClients}
          </StyledIconsWrapper>
        </div>

        {/* {isCohealer && mobileView && inviteClients} */}
      </ContributionHeaderView>
      {isScheduleMeetingModalOpen === true && (
        <ScheduleMeetingModal
          isVisible={isScheduleMeetingModalOpen}
          closeModal={() => {
            setIsScheduleMeetingModalOpen(false);
          }}
          setRescheduleSession={() => {
            setRescheduleSession(true);
            setRescheduleModalState(true);
            setIsScheduleMeetingModalOpen(false);
          }}
          isCoach={isCoach}
          paymentInfo={activeContribution?.paymentInfo}
          setIsScheduleMeetingVisible={setIsScheduleMeetingModalOpen}
          selectedCalDate={moment(filteredDates[0])?.toDate()}
        />
      )}
      {affiliateModalOpen === true && (
        <AffiliateShareModal
          isVisible={affiliateModalOpen}
          closeModal={() => {
            setAffiliateModalOpen(false);
          }}
          isCoach={isCoach}
          setIsScheduleMeetingVisible={setAffiliateModalOpen}
        />
      )}
      <Modal
        applyTheming
        title="Join the Community"
        isOpen={isOpen}
        submitTitle="Close"
        hiddenCancel
        onCancel={() => {
          setIsOpen(false);
        }}
        dontCancelOnSideClick
        onSubmit={() => {
          setIsOpen(false);
        }}
        style={{ zIndex: '2000' }}
      >
        <p>Please join {contName} to get access to the community.</p>
      </Modal>
      <CreateApplicationFormModal
        showApplicationFormPopup={showApplicationFormPopup}
        setShowApplicationFormPopup={setShowApplicationFormPopup}
      />
      <Modal
        title=""
        isOpen={showModal}
        onCloseText="OK"
        hiddenCancel
        onSubmit={togglePopup}
        onCancel={togglePopup}
        disableFooter
      >
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <StyledImgCongrats previewImg={TemplateOne}></StyledImgCongrats>
          <div style={{ fontSize: '24px', color: '#215C73', fontWeight: 800 }}>CONGRATS!</div>
          <div style={{ color: '#A58B62', fontSize: '16px', marginTop: '13px', fontWeight: 450, fontFamily: 'Avenir' }}>
            You are fully set up & ready to transform lives!
          </div>
          <Button
            autoWidth
            backgroundColor={'#C9B382'}
            style={{
              marginTop: '40px',
              paddingTop: '10px',
              paddingBottom: '10px',
              marginBottom: '10px',
            }}
            onClick={togglePopup}
          >
            Let’s do this!
          </Button>
        </div>
      </Modal>

      {analyticsModalOpened && (
        <ConversionMetricsModal
          isOpen={analyticsModalOpened}
          handleClose={handleAnalyticsModalClose}
          contributionId={activeContribution.id}
          isDarkModeEnabled={isDarkModeEnabled}
          colorToUse={colorToUse}
        />
      )}
      <Modal
        height={'650px'}
        isOpen={isRescheduleModalOpen}
        onCancel={() => {
          setRescheduleModalState(false);
        }}
        PrimaryColor={colorToUse?.PrimaryColorCode}
        textColor={textColor}
        onSubmit={() => {
          setRescheduleModalState(false);
        }}
        title="Reschedule your session"
        noPadding={true}
        isBookingModal={true}
        disableFooter={true}
        CrossIconHide={!rescheduleSession}
      >
        {rescheduleSession ? (
          <EasyBooking
            clientPreviewMode={false}
            isSelfSchedule={true}
            rescheduleSession={rescheduleSession}
            setRescheduleSession={setRescheduleSession}
            isModal={true}
            isCoach={isCoach}
          />
        ) : (
          <SessionBookingThankyou
            setRescheduleSession={setRescheduleSession}
            rescheduleSession={rescheduleSession}
            isModal={true}
            handleCancel={() => setRescheduleModalState(false)}
          />
        )}
      </Modal>
    </>
  );
};

ContributionHeader.propTypes = {
  canAccess: PropTypes.bool.isRequired,
  onShareButtonClick: PropTypes.func,
  status: PropTypes.string,
  isDarkThemeEnabled: PropTypes.bool,
};

ContributionHeader.defaultProps = {
  onShareButtonClick: () => {},
  status: '',
  isDarkThemeEnabled: true,
};

const mapStateToProps = ({ contributions: { activeContribution } }) => ({
  activeContribution: activeContribution,
});
export default connect(mapStateToProps)(ContributionHeader);
