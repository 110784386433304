import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import MUISelect from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import { Icon } from '@mdi/react';
import { mdiChevronDown } from '@mdi/js';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { colors } from 'utils/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import styles from './select.module.scss';
import { IconButton, ListItemIcon, TextField } from '@material-ui/core';
import styled from 'styled-components';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ImageIcon from '@material-ui/icons/Image';
const StyledSearch = styled.div`
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 1px 5px;
  //  margin-top: 5px;
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const SelectStyled = styled(MUISelect)`
  .MuiSelect-select:focus {
    background-color: transparent !important;
  }
`;

const StyledInput = withStyles(() => ({
  root: {
    fontFamily: 'Avenir',
    lineHeight: `${24 / 16}rem`,
    letterSpacing: '0.15px',
    fontSize: `${18 / 16}rem`,
    color: ({ isDark }) => (isDark ? 'white' : 'rgba(0, 0, 0, 0.87)'),
    fontWeight: 500,
  },
  formControl: {
    'label + &': {
      marginTop: ({ labelTop }) => labelTop || 23,
    },
  },
  underline: {
    '&:hover:not(.Mui-disabled):before': {
      // borderBottom: props => (props.underLineColor ? `2px solid ${props.underLineColor}` : ''),
    },
    '&:after': {
      borderBottom: props =>
        props.roundedBorder
          ? 'none !important'
          : props.underLineColor
          ? `2px solid ${props.underLineColor}`
          : `2px solid #6486a2`,
    },
    '&:before': {
      borderColor: props => (props.roundedBorder ? 'none !important' : '#e7e7e7'),
      borderBottom: props => props.roundedBorder && 'none !important',
    },
    border: props =>
      props.roundedBorder && props.error && props.showHelperText === false
        ? '1px solid red'
        : props.roundedBorder
        ? '1px solid #e7e7e7'
        : 'none !important',
    borderRadius: props => (props.roundedBorder ? '4px' : 'none !important'),
    padding: '2px 8px !important',
  },
  error: {
    '&:after': {
      borderBottomColor: `${colors.fadedPurple} !important`,
    },
  },
}))(Input);
const StyledEmailInput = withStyles(() => ({
  root: {
    fontFamily: 'Avenir',
    lineHeight: `${24 / 16}rem`,
    letterSpacing: '0.15px',
    fontSize: '16px',
    color: ({ isDark }) => (isDark ? 'white' : 'rgba(0, 0, 0, 0.87)'),
    fontWeight: 400,
  },
  formControl: {
    'label + &': {
      marginTop: ({ labelTop }) => labelTop || 23,
    },
  },
  underline: {
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: props => (props.underLineColor ? `2px solid ${props.underLineColor}` : ''),
    },
    '&:after': {
      borderBottom: props => (props.underLineColor ? `2px solid ${props.underLineColor}` : `2px solid #6486a2`),
    },
    '&:before': {
      borderColor: '#e7e7e7',
    },
  },
  error: {
    '&:after': {
      borderBottomColor: `${colors.fadedPurple} !important`,
    },
  },
}))(Input);
const StyledMenuItem = withStyles(() => ({
  root: {
    width: '100%',
    fontFamily: 'Avenir',
    fontSize: `${14 / 16}rem`,
    lineHeight: '1rem',
    letterSpacing: '0.4px',
    color: props => {
      return props.textColor ? props.textColor : 'rgba(0, 0, 0, 0.87)';
    },
    fontWeight: '300',
    borderBottom: '1px solid #f2f2f2',
  },
  selected: {
    backgroundColor: 'transparent !important',
  },
}))(MenuItem);
const StyledMenuMailTemplateItem = withStyles(() => ({
  root: {
    width: '100%',
    fontFamily: 'Avenir',
    fontSize: `18px`,
    padding: '18px',
    lineHeight: '1rem',
    letterSpacing: '0.4px',
    color: props => {
      return props.textColor ? props.textColor : 'rgba(0, 0, 0, 0.87)';
    },
    fontWeight: '600 !important',
    borderBottom: '1px solid #f2f2f2',
  },
  selected: {
    backgroundColor: 'transparent !important',
  },
}))(MenuItem);
const StyledMenuMailTemplateItemAddAnother = withStyles(() => ({
  root: {
    width: '100%',
    fontFamily: 'Avenir',
    fontSize: `16px`,
    padding: '18px',
    lineHeight: '1rem',
    letterSpacing: '0.4px',
    color: props => {
      return props.textColor ? props.textColor : 'rgba(0, 0, 0, 0.87)';
    },
    fontWeight: '600 !important',
    borderBottom: '1px solid #f2f2f2',
  },
  selected: {
    backgroundColor: 'transparent !important',
  },
}))(MenuItem);

const StyledMenuCustomIcons = withStyles(() => ({
  root: {
    width: '100%',
    fontFamily: 'Avenir',
    fontSize: `${14 / 16}rem`,
    lineHeight: '1rem',
    letterSpacing: '0.4px',
    padding: '14px',
    color: props => {
      return props.textColor ? props.textColor : 'rgba(0, 0, 0, 0.87)';
    },
    fontWeight: '300',
    borderBottom: '1px solid #f2f2f2',
  },
  selected: {
    backgroundColor: 'transparent !important',
  },
}))(MenuItem);

const StyledMenuItemBold = withStyles(() => ({
  root: {
    fontFamily: 'Avenir',
    fontSize: `${14 / 16}rem`,
    lineHeight: '1rem',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 'bold !important',
    borderBottom: '1px solid #f2f2f2',
  },
  selected: {
    backgroundColor: 'transparent !important',
  },
}))(MenuItem);
const StyledMenuItemCommunity = withStyles(() => ({
  root: {
    fontFamily: 'Avenir',
    fontSize: `${14 / 16}rem`,
    lineHeight: '1rem',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.87)',
    // fontWeight: 'bold !important',
    borderBottom: '1px solid #f2f2f2',
    padding: '20px !important',
  },
  selected: {
    backgroundColor: 'transparent !important',
  },
}))(MenuItem);
const useStyles = makeStyles(() => ({
  labelRoot: {
    fontFamily: 'Avenir',
    lineHeight: '1rem',
    letterSpacing: '0.4px',
    fontSize: `${14 / 16}rem`,
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 300,
    transform: `translate(0, ${33 / 16}rem) scale(1)`,
    width: '100%',
  },
  labelRootEmail: {
    fontFamily: 'Avenir',
    lineHeight: '1rem',
    letterSpacing: '0.4px',
    fontSize: `16px`,
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 400,
    transform: `translate(0, ${33 / 16}rem) scale(1)`,
    width: '100%',
  },
  labelRootWhite: {
    fontFamily: 'Avenir',
    lineHeight: '1rem',
    letterSpacing: '0.4px',
    fontSize: `${14 / 16}rem`,
    color: 'white',
    fontWeight: 300,
    transform: `translate(0, ${33 / 16}rem) scale(1)`,
    width: '100%',
  },
  labelRootWhite: {
    fontFamily: 'Avenir',
    lineHeight: '1rem',
    letterSpacing: '0.4px',
    fontSize: `${14 / 16}rem`,
    color: 'white',
    fontWeight: 300,
    transform: `translate(0, ${33 / 16}rem) scale(1)`,
  },
  labelRootMobile: {
    fontFamily: 'Avenir',
    lineHeight: '1rem',
    letterSpacing: '0.4px',
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 300,
    transform: `translate(0, ${33 / 16}rem) scale(1)`,
    width: '100%',
  },
  labelCohort: {
    color: 'rgba(0, 0, 0, 0.87)',
    width: '100%',
    fontSize: '14px',
    transform: 'translate(0, 2.0625rem) scale(1)',
    fontFamily: 'Avenir',
    fontWeight: 400,
    lineHeight: '1rem',
  },
  labelShrink: {
    transform: 'translate(0, 4px)',
  },
  labelFocused: {
    color: 'inherit !important',
  },
  labelFocusedMobile: {
    color: 'inherit !important',
    width: '50%',
  },
  labelFormControl: {
    transform: `translate(0, ${32 / 16}rem) scale(1)`,
  },
  labelError: {
    color: 'inherit !important',
  },
  helperTextRoot: {
    textAlign: 'right',
    fontFamily: 'Avenir',
    lineHeight: `${24 / 16}rem`,
    letterSpacing: '0.08px',
    fontSize: `${12 / 16}rem`,
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 'normal',
  },
  helperTextError: {
    color: props =>
      props?.helperTextErrorColor ? `${props?.helperTextErrorColor} !important` : `${colors.fadedPurple} !important`,
    textAlign: 'left',
    fontSize: '1rem',
    letterSpacing: '0.1px',
  },
  menuListPadding: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

function getStyles(value, values) {
  let isBoldText = false;

  if (Array.isArray(values)) {
    isBoldText = values.includes(value);
  }

  if (typeof values === 'string') {
    isBoldText = value === values;
  }

  return {
    fontWeight: isBoldText ? 'bold' : 'normal',
  };
}

function RemoveButton(props) {
  return (
    <IconButton onClick={props.onRemove} aria-label="Remove">
      <ContentCopyIcon
        className={styles.hide}
        style={{ fontSize: 20, alignSelf: 'flex-end', marginRight: '10px', marginBottom: '2px' }}
      />
    </IconButton>
  );
}

const getTitle = title => {
  if (title?.length <= 40) {
    return title;
  } else {
    return title.substring(0, 40) + '...';
  }
};
function Select(props) {
  const {
    className,
    value,
    onChange,
    helperText,
    error,
    items,
    label,
    fullWidth,
    disabled,
    multiple,
    labelTop,
    errorHeight,
    onExited,
    icon,
    itemTextColor,
    openState,
    removeItem,
    onDelete,
    biggerTiles,
    searchEnabled,
    helperTextErrorColor = '',
    underLineColor = '',
    mobileView = false,
    defaultIcon = false,
    disableUnderline = false,
    showHelperText = true,
    isDarkThemeEnabled = false,
    MenuProps,
    emailMarketing = false,
    inputProps,
    isQuizSelect = false,
    roundedBorder = false,
    cohortSelector = false,
    ...rest
  } = props;
  const classes = useStyles({ helperTextErrorColor });
  const [searchWord, setSearchWord] = useState('');

  const filterItems = (items, val) => {
    if (val.length > 0) {
      return items.filter(i => i.title.toLowerCase().includes(val.toLowerCase())) || [];
    } else {
      return items || [];
    }
  };
  const ArrowIcon = props => (
    <Icon color={isDarkThemeEnabled ? 'white' : 'black'} path={mdiChevronDown} size={1} {...props} />
  );
  console.log('error----->', error);
  return (
    <FormControl className={className} disabled={disabled} fullWidth={fullWidth} error={Boolean(error)}>
      {label && (
        <InputLabel
          classes={{
            root: mobileView
              ? classes.labelRootMobile
              : isDarkThemeEnabled
              ? classes.labelRootWhite
              : emailMarketing
              ? classes.labelRootEmail
              : cohortSelector
              ? classes.labelCohort
              : classes.labelRoot,
            focused: classes.labelFocused,
            shrink: classes.labelShrink,
            error: classes.labelError,
          }}
        >
          {label}
        </InputLabel>
      )}
      <SelectStyled
        IconComponent={defaultIcon ? ArrowDropDownIcon : ArrowIcon}
        sx={{
          '& .MuiSelect-select:focus': {
            backgroundColor: 'transparent !important',
          },
        }}
        input={
          emailMarketing ? (
            <StyledEmailInput
              underLineColor={underLineColor}
              labelTop={labelTop}
              noUnderLine={true}
              isDark={isDarkThemeEnabled}
              disableUnderline={disableUnderline}
              {...inputProps}
            />
          ) : (
            <StyledInput
              underLineColor={underLineColor}
              labelTop={labelTop}
              noUnderLine={true}
              isDark={isDarkThemeEnabled}
              disableUnderline={disableUnderline}
              roundedBorder={roundedBorder}
              error={error || helperText}
              showHelperText={showHelperText}
              {...inputProps}
            />
          )
        }
        MenuProps={{
          getContentAnchorEl: () => null,
          disableAutoFocusItem: true,
          MenuListProps: {
            classes: {
              padding: classes.menuListPadding,
            },
          },
          onExited,
          ...MenuProps,
        }}
        disabled={disabled}
        multiple={multiple}
        value={value}
        onChange={icon ? () => {} : removeItem ? () => {} : onChange}
        open={openState}
        {...rest}
      >
        {searchEnabled ? (
          <StyledSearch>
            <TextField
              variant="standard"
              fullWidth
              value={searchWord}
              placeholder="Search here..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={e => {
                setSearchWord(e.target.value);
              }}
              noBorderBottom={true}
            />
          </StyledSearch>
        ) : null}
        {filterItems(items, searchWord)?.map(
          (
            {
              title: itemTitle,
              value: itemValue,
              disabled: isdisabled,
              icon: icon,
              customIcon: customIcon,
              deletionNotAllowed: deletionNotAllowed,
              isCustom: isCustom,
              remove: remove,
            },
            i,
          ) =>
            itemValue === 'Add Another Email' ? (
              <StyledMenuMailTemplateItemAddAnother
                /* eslint-disable-next-line */
                key={i}
                value={itemValue}
                disabled={isdisabled}
                style={getStyles(itemValue, value)}
                textColor={itemTextColor}
              >
                {itemTitle}
              </StyledMenuMailTemplateItemAddAnother>
            ) : customIcon ? (
              <StyledMenuCustomIcons
                /* eslint-disable-next-line */
                key={i}
                value={itemValue}
                disabled={isdisabled}
                style={getStyles(itemValue, value)}
                textColor={itemTextColor}
              >
                <ListItemIcon style={{ minWidth: '30px' }}>
                  <img src={customIcon} width="16px" height="18px" />
                </ListItemIcon>
                {itemTitle}
              </StyledMenuCustomIcons>
            ) : icon ? (
              <div
                onClick={() => {
                  onChange(itemValue);
                }}
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
              >
                {itemValue === 'Create Custom Category' ? (
                  <StyledMenuItemBold
                    title={itemTitle}
                    className={styles.myDIV}
                    /* eslint-disable-next-line */
                    key={i}
                    value={itemValue}
                    disabled={isdisabled}
                    style={getStyles(itemValue, value)}
                    textColor={itemTextColor}
                  >
                    {itemValue}
                  </StyledMenuItemBold>
                ) : (
                  <StyledMenuItem
                    title={itemTitle}
                    className={styles.myDIV}
                    /* eslint-disable-next-line */
                    key={i}
                    value={itemValue}
                    disabled={isdisabled}
                    style={getStyles(itemValue, value)}
                    textColor={itemTextColor}
                  >
                    {itemValue}
                  </StyledMenuItem>
                )}
                <ContentCopyIcon
                  className={styles.hide}
                  style={{ fontSize: 20, alignSelf: 'flex-end', marginRight: '10px', marginBottom: '2px' }}
                />
              </div>
            ) : removeItem ? (
              <div
                onClick={e => {
                  e.stopPropagation();
                  onChange(itemValue);
                }}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderBottom: '1px solid #f2f2f2',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    transition: 'background-color 0.3s', // Add smooth transition for hover effect
                    cursor: 'pointer', // Change cursor to indicate interactivity
                    backgroundColor: 'white',
                    width: '100%',
                  }}
                  onMouseEnter={e => {
                    e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.10)'; // Change background color on hover
                  }}
                  onMouseLeave={e => {
                    e.currentTarget.style.backgroundColor = 'white'; // Reset background color on hover out
                  }}
                >
                  {itemValue === 'Create Custom Category' ? (
                    <StyledMenuItemBold
                      title={itemTitle}
                      /* eslint-disable-next-line */
                      key={i}
                      value={itemValue}
                      disabled={isdisabled}
                      style={getStyles(itemValue, value)}
                    >
                      {itemValue}
                    </StyledMenuItemBold>
                  ) : (
                    <StyledMenuItem
                      title={itemTitle}
                      /* eslint-disable-next-line */
                      key={i}
                      value={itemValue}
                      disabled={isdisabled}
                      style={getStyles(itemValue, value)}
                    >
                      {itemValue}
                    </StyledMenuItem>
                  )}
                </div>
                {isCustom === true && deletionNotAllowed === false && (
                  <IconButton
                    style={{ padding: '6px 12px' }}
                    onClick={e => {
                      e.stopPropagation();
                      if (isCustom === true && deletionNotAllowed === false) {
                        onDelete([itemValue]);
                      }
                    }}
                    aria-label="Remove"
                  >
                    {isCustom === true && deletionNotAllowed === false && (
                      <div style={{ fontSize: '10px' }}>Remove</div>
                    )}
                  </IconButton>
                )}
              </div>
            ) : biggerTiles ? (
              <StyledMenuItemCommunity
                /* eslint-disable-next-line */
                key={i}
                value={itemValue}
                disabled={isdisabled}
                style={getStyles(itemValue, value)}
                textColor={itemTextColor}
              >
                {itemTitle}
              </StyledMenuItemCommunity>
            ) : isQuizSelect ? (
              <StyledMenuItem
                /* eslint-disable-next-line */
                key={i}
                value={itemValue}
                disabled={isdisabled}
                style={getStyles(itemValue, value)}
                textColor={itemTextColor}
              >
                {getTitle(itemTitle)}
              </StyledMenuItem>
            ) : (
              <StyledMenuItem
                /* eslint-disable-next-line */
                key={i}
                value={itemValue}
                disabled={isdisabled}
                style={getStyles(itemValue, value)}
                textColor={itemTextColor}
              >
                {itemTitle}
              </StyledMenuItem>
            ),
        )}
      </SelectStyled>
      {showHelperText && (
        <FormHelperText
          classes={{
            root: classes.helperTextRoot,
            error: classes.helperTextError,
          }}
          style={{
            minHeight: errorHeight,
          }}
        >
          {error || helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

Select.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      value: PropTypes.any,
    }),
  ).isRequired,
  label: PropTypes.string,
  labelTop: PropTypes.string,
  multiple: PropTypes.bool,
  errorHeight: PropTypes.string,
  onExited: PropTypes.func,
  itemTextColor: PropTypes.string,
};

Select.defaultProps = {
  className: '',
  fullWidth: false,
  helperText: null,
  error: null,
  disabled: false,
  label: null,
  multiple: false,
  labelTop: null,
  errorHeight: null,
  onExited: null,
  itemTextColor: '',
};

export default Select;
