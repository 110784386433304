import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Switch, Route, NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import * as paidTier from 'selectors/paidTier';
import { colors, devices } from 'utils/styles';
import * as contributionActions from 'actions/contributions';
import * as pageActions from 'actions/page';
// import navigationIcon from '../../assets/navigation-icon.png';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DropDownList from './DropDownList/DropDownList';
import Joyride, {
  CallBackProps,
  STATUS,
  EVENTS,
  Step,
  StoreHelpers,
  BeaconRenderProps,
  TooltipRenderProps,
} from 'react-joyride';
import Can from 'components/Auth/Can';
import Logo from 'components/FormUI/Logo';
import Button from 'components/FormUI/Button';
import { SIGNUP_TYPES, UserRoles } from 'helpers/constants';
import * as pageAction from '../../actions/page';

import SidebarDropDown from './SidebarDropDown';
import CreateContributionMenu from './CreateContributionMenu';
import CreateLeadMagnetMenu from './CreateLeadMagnetMenu';
import { CONTRIBUTION_COLORS, PAID_TIER_TITLES, ROUTES } from '../../constants';
import { useDispatch } from 'react-redux';
import CoachImage from 'assets/chatlogo.png';
import { useSelector } from 'react-redux';
import { useAccount, useRouter, useShallowEqualSelector } from 'hooks/index';
import { setCohereAcademyStatus } from 'actions/update-user';
import { Box, ClickAwayListener, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { fetchDomains } from 'actions/emailMarketing.actions';
import navigationIcon from '../../assets/menu.svg';
import { toggleCommunitySidebar } from 'actions/community';
import CreateQuizesModal from 'pages/CreateQuizes/Components/Modals/CreateModal/CreateModal';
import { setShowCreateModal } from 'actions/quizes';
import MasterCalanderSidebar from 'components/MasterCalanderSidebar/MasterCalanderSidebar';
import { toggleMasterCalendarSideBar } from 'actions/masterCalendarSidebar.actions';
import CommunitiesSideBar from './CommunitiesSideBar';

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: ${({ isCommunity, mobileView, isOnlyCommunity }) =>
    isCommunity && !mobileView && isOnlyCommunity ? '364px' : '290px'};
  min-height: 100vh;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 0px 4px 0 rgba(157, 157, 157, 0.3);
  position: relative;

  z-index: ${({ isCommunity }) => isCommunity && 200};
  position: ${({ position }) => position};
  left: ${({ left }) => left};
  ${({ applyTransition }) => {
    return applyTransition && `transition: left 0.5s ease-in-out`;
  }};
  /* transition: left 0.5s ease-in-out; */
  /* @media screen and (${devices.laptop}) {
    display: block;
  } */
`;

const CommunitySideBarContainer = styled(SidebarContainer)`
  overflow: initial;
  min-width: ${({ isCommunity, isResponsive }) =>
    isCommunity && !isResponsive ? '364px' : isResponsive ? '310px' : '270px'};
  max-width: ${({ isCommunity, isResponsive }) =>
    isCommunity && !isResponsive ? '364px' : isResponsive ? '342px' : '270px'};
`;

const SidebarHeader = styled.div`
  margin: 45px 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SidebarTerms = styled.div`
  margin: 60px 0 10px;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 13px;
  align-items: center;
  cursor: pointer;
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const StyledHeaderTitle = styled.div`
  margin: 0 auto;
  font-family: Avenir;
  font-size: 22.3px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
`;
const StyledAffiliateLink = styled(NavLink)`
  color: ${colors.gray};
  font-weight: initial;

  &.disableMe {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
`;

const StyledLink = styled(NavLink)`
  &.disableMe {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }

  ${({ isCommunityHeader }) => {
    return isCommunityHeader
      ? css`
          img {
            width: 54px;
            height: 54px;
          }
        `
      : '';
  }}
`;

const StyledImg = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 40px;
  align-items: center;
  z-index: 1;
`;
const StyledTermsLink = styled.a`
  color: ${colors.gray};
  font-weight: initial;

  &.disableMe {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
`;

const useStyles = makeStyles(theme => ({
  createButtonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  createButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 20px',
    minWidth: '130px !important',
    maxWidth: '130px !important',
    fontSize: '15px',

    '&:hover': {
      textDecoration: 'none',
    },
  },
  dropDownContainer: {},
}));

const DropDownSvg = fill => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill={fill}>
      <path
        d="M11.7472 0.937637L11.5707 0.761053L11.3939 0.937445L6.98047 5.34125L2.56705 0.937444L2.39028 0.761053L2.21369 0.937636L0.803692 2.34764L0.626916 2.52441L0.803692 2.70119L6.80369 8.70119L6.98047 8.87797L7.15725 8.70119L13.1572 2.70119L13.334 2.52441L13.1572 2.34764L11.7472 0.937637Z"
        fill={fill}
        stroke="white"
        stroke-width="0.5"
      />
    </svg>
  );
};

const SubMenuIconContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 8px 4px;
`;

function Sidebar(props) {
  // const [termsModal, setTermsModal] = useState(false);
  const theme = useTheme();
  const isResponsive = useMediaQuery('(max-width: 990px)');
  const { isCommunitySidebarOpen: isCommunityOpen } = useSelector(state => state?.communities);
  const { signupType } = useSelector(state => state.account);
  const { isMasterCalendarSidebarOpen: isMasterCalanderOpen } = useSelector(
    state => state.masterCalendar.sideBarActions,
  );
  const { currentRole, user: loggedInUser, roles } = useAccount();
  const { pathname } = useRouter();
  const isCoach = currentRole === UserRoles.cohealer;
  const isCoachAndClientRolesAvailable = roles.includes(UserRoles.client) && roles.includes(UserRoles.cohealer);
  const isAdmin = roles.includes(UserRoles.admin);
  const user = useAccount();
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const showCompletedGetStart = useSelector(state => state.contributions.showCompletedGetStart);
  const isAnyContributionJoinedOrCreated = useSelector(state => state.contributions.isAnyContributionJoinedOrCreated);
  const refreshStep = showCompletedGetStart === 'createRefresh';
  const { showModal } = useSelector(state => state.quizes);
  const showStep = showCompletedGetStart === 'create';
  const { path } = useRouteMatch();
  const isCohealer = currentRole === UserRoles.cohealer;
  const isClient = currentRole === UserRoles.client;
  const shouldShowViewSwitchLink = isCohealer || isClient;
  const isMasterCalendar = pathname.includes('calendar/master');
  const isCommunity = path.includes('community') && isAnyContributionJoinedOrCreated;
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const isSidebarComponent = isCommunity || (isMasterCalendar && isCoach);
  const isSidebarComponentOpen =
    (isCommunity && isCommunityOpen) || (isMasterCalendar && isCoach && isMasterCalanderOpen);

  const DropDownItems = [
    {
      title: 'Service',
      to: ROUTES.CREATE_CONTRIBUTION_BASIC,
    },
    {
      title: 'Lead Magnet',
      to: ROUTES.CREATE_LEAD_MAGNET_BASIC,
    },
    {
      title: 'Email Campaign',
      to: ROUTES.CREATE_CAMPAIGN_TITLE,
    },
    {
      title: 'Form/Quiz',
      to: '',
    },
  ];
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  // const { isLaunchPlan } = usePaidTier();
  const isLaunchPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch;

  // Correct behavior - 100%
  useEffect(() => {
    dispatch(fetchDomains());
  }, []);

  useEffect(() => {
    if (activePaidTierCurrentPlan?.default) {
      dispatch(setCohereAcademyStatus(true));
    } else {
      dispatch(setCohereAcademyStatus(false));
    }
  }, [activePaidTierCurrentPlan, dispatch]);

  const [stepIndex, setStepIndex] = useState(0);
  const shouldBeDisabled =
    currentRole === UserRoles.cohealer &&
    (signupType === SIGNUP_TYPES.TYPE_A ||
      signupType === SIGNUP_TYPES.TYPE_B ||
      loggedInUser.signupType === SIGNUP_TYPES.TYPE_B ||
      loggedInUser.signupType === SIGNUP_TYPES.TYPE_A ||
      isLaunchPlan);
  const [run, setRun] = useState(false);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  // const [isMasterCalanderOpen, setIsMasterCelanderOpen] = useState(false);
  const PopUp = useSelector(state => state.contributions.showPopUp);
  useEffect(() => {
    dispatch(contributionActions.setPopUp(false));
    if ((refreshStep || PopUp === true) && path.includes('dashboard')) {
      setRun(true);
    } else if ((showStep || PopUp === true) && path.includes('dashboard')) {
      setRun(true);
    } else {
      dispatch(contributionActions.setPopUp(false));
    }
  }, [PopUp, showCompletedGetStart]);

  let joyRideHelpers;

  const [steps, setSteps] = useState([
    {
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step4">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              borderColor: CONTRIBUTION_COLORS.PrimaryColorCode,
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Create a Service
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Click this button to create your first service. Services are called “Services” on Cohere, because we know
            you’re making huge impact on people’s lives!
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '20px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
                display: 'flex',
                justifyContent: 'center',
              }}
              onClick={() => {
                dispatch(pageActions.toggleMobileSidebar());
                dispatch(contributionActions.setPopUp(false));
                dispatch(contributionActions.setShowCompletedGetStart(null));
                setRun(false);
                if (user.user.userProgressbarData.FirstContributionDone === false) {
                  history.push('/create-contribution/basic');
                } else if (user.user.userProgressbarData.FirstContributionDone === true) {
                  history.push('/create-contribution/basic', { from: 'refreshContribution' });
                }
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'bottom',
      target: '#createService',
    },
  ]);

  const handleJoyrideCallback = data => {
    const { status, type, index, action } = data;
    const finishedStatuses = ['finished', 'skipped'];
    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
    if (finishedStatuses.includes(status) || action == 'close') {
      setRun(false);
      setStepIndex(null);
    }
    if (data.action === 'close') {
      setRun(false);
    }

    console.groupCollapsed(type);

    console.groupEnd();
  };
  const getHelpers = helpers => {
    joyRideHelpers = helpers;
  };

  const handleClickAway = () => {
    setDropDownOpen(false);
  };
  const handleMasterCalanderOpen = () => {
    dispatch(toggleMasterCalendarSideBar(!isMasterCalanderOpen));
  };

  const getStyledDashboardLink = isCommunityHeader => {
    return (
      <StyledLink
        isCommunityHeader={isCommunityHeader}
        className={!!shouldBeDisabled && !user?.user?.isPartnerCoach ? 'disableMe' : null}
        to="/dashboard"
      >
        <Logo startlogo={isCommunityHeader} />
      </StyledLink>
    );
  };

  return (
    <>
      <Joyride
        // tooltipComponent={Tooltip}
        callback={handleJoyrideCallback}
        continuous={true}
        getHelpers={getHelpers}
        run={run}
        stepIndex={stepIndex}
        showProgress={false}
        showSkipButton={false}
        steps={steps}
        styles={{
          options: {
            primaryColor: CONTRIBUTION_COLORS.AccentColorCode,
            zIndex: 10000000,
          },
        }}
      />
      {isCommunity && (
        <CommunitySideBarContainer
          isCommunity={isCommunity}
          isResponsive={isResponsive}
          position={isCommunityOpen ? 'relative' : 'absolute'}
          mobileView={mobileView}
        >
          <div
            style={
              isCohealer
                ? {}
                : {
                    position: 'fixed',
                    minWidth: isCommunity && !mobileView ? '364px' : mobileView ? '310px' : '270px',
                    maxWidth: isCommunity && !mobileView ? '364px' : mobileView ? '342px' : '270px',
                  }
            }
          >
            <CommunitiesSideBar dashboardLinkComponent={getStyledDashboardLink(true)} isLaunchPlan={isLaunchPlan} />
          </div>
        </CommunitySideBarContainer>
      )}
      {isMasterCalendar && isCoach && (
        <SidebarContainer
          style={{
            position: isMasterCalanderOpen ? 'relative' : 'absolute',
            width: isResponsive ? '100%' : 'auto',
          }}
        >
          <div style={isResponsive ? {} : { position: 'fixed', minWidth: '285px', height: '100vh', overflowY: 'auto' }}>
            <MasterCalanderSidebar
              handleMasterCalanderOpen={handleMasterCalanderOpen}
              user={user}
              shouldBeDisabled={shouldBeDisabled}
              mobileView={mobileView}
            />
          </div>
        </SidebarContainer>
      )}
      <SidebarContainer
        isOnlyCommunity={isCommunity}
        isCommunity={isSidebarComponent}
        position={isSidebarComponent ? (isSidebarComponentOpen ? 'absolute' : 'relative') : 'relative'}
        left={isSidebarComponent ? (isSidebarComponentOpen ? '-158%' : '0') : '0'}
        applyTransition
        mobileView={mobileView}
      >
        <div style={isCohealer ? {} : { minWidth: '270px' }}>
          <div>
            <SidebarHeader>
              {!mobileView && ((isMasterCalendar && isCoach) || isCommunity) && (
                <Box
                  borderRadius="50%"
                  alignSelf="flex-start"
                  left={22}
                  top={22}
                  position="absolute"
                  sx={{ cursor: 'pointer', transition: 'top 4s ease-in-out' }}
                  onClick={() => {
                    if (isCommunity) dispatch(toggleCommunitySidebar());
                    else handleMasterCalanderOpen();
                  }}
                >
                  <img style={{ cursor: 'pointer' }} width="28px" src={navigationIcon} />
                </Box>
              )}
              {getStyledDashboardLink()}

              {/* <Can
                roleName={UserRoles.cohealer}
                yes={() => (
                  <StyledLink
                    id={'createService'}
                    className={
                      user?.user?.isPartnerCoach === false && shouldBeDisabled === false
                        ? null
                        : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                        ? 'disableMe'
                        : shouldBeDisabled == true && user?.user?.isPartnerCoach === true
                        ? null
                        : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                        ? 'disableMe'
                        : null
                    }
                    
                    to="/create-contribution/basic"
                  >
                    <Button
                      onClick={() => {
                        dispatch(contributionActions.setErrorPopUp(false));
                      }}
                      autoWidth
                    >
                      Create a Contribution
                    </Button>
                  </StyledLink>
                )}
              /> */}

              {isCoach && !isAdmin && (
                <ClickAwayListener onClickAway={handleClickAway}>
                  <div id={'createService'} className={classes.createButtonWrapper}>
                    <Button
                      disabled={shouldBeDisabled}
                      onClick={() => {
                        dispatch(contributionActions.setErrorPopUp(false));
                        setDropDownOpen(!dropDownOpen);
                      }}
                      className={classes.createButton}
                    >
                      Create <DropDownSvg fill={shouldBeDisabled ? '#9b9b9b' : 'white'} />
                    </Button>
                    {dropDownOpen && (
                      <DropDownList containerClassName={classes.dropDownContainer} list={DropDownItems} />
                    )}
                  </div>
                </ClickAwayListener>
              )}

              <Can roleName={UserRoles.admin} yes={() => <StyledHeaderTitle>Admin</StyledHeaderTitle>} />
            </SidebarHeader>
          </div>
          <Switch>
            <Route path="/create-contribution">
              <CreateContributionMenu {...props} />
            </Route>
            <Route path="/edit-contribution/:id">
              <CreateContributionMenu {...props} />
            </Route>
            <Route path="/create-lead-magnet">
              <CreateLeadMagnetMenu {...props} />
            </Route>
            <Route path="/edit-lead-magnet/:id">
              <CreateLeadMagnetMenu {...props} />
            </Route>
            <Route component={SidebarDropDown}>
              <SidebarDropDown {...props} />
            </Route>
          </Switch>
          <SidebarTerms
          // onClick={() => {
          //   setTermsModal(true);
          // }}
          >
            {shouldShowViewSwitchLink &&
            isCoachAndClientRolesAvailable === true &&
            (isLaunchPlan === false ||
              (isLaunchPlan === true && user?.user?.isPartnerCoach) ||
              (isLaunchPlan === true && user?.user?.havePaidTierPurchase)) &&
            currentRole === UserRoles.client ? (
              <></>
            ) : /* <StyledAffiliateLink
              className={
                user?.user?.isPartnerCoach === false && shouldBeDisabled === false
                  ? null
                  : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                    ? 'disableMe'
                    : shouldBeDisabled == true && user?.user?.isPartnerCoach === true
                      ? null
                      : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                        ? 'disableMe'
                        : null
              }
              onClick={() => dispatch(pageAction.changeDropdown(true))}
              to={ROUTES.ROLE_SWITCH}
            >
              {`Change to ${isClient ? 'Coach' : 'Client'} View?`}
            </StyledAffiliateLink> */
            currentRole === UserRoles.cohealer ? (
              <></>
            ) : (
              /* <StyledAffiliateLink onClick={() => dispatch(pageAction.changeDropdown(true))} to={ROUTES.ROLE_SWITCH}>
                            {`Change to ${isClient ? 'Coach' : 'Client'} View?`}
                          </StyledAffiliateLink> */
              <></>
            )}

            <StyledTermsLink
              className={
                user?.user?.isPartnerCoach === false && shouldBeDisabled === false
                  ? null
                  : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                  ? 'disableMe'
                  : shouldBeDisabled == true && user?.user?.isPartnerCoach === true
                  ? null
                  : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                  ? 'disableMe'
                  : null
              }
              rel="noopener noreferer"
              href="https://www.cohere.live/privacy-policy"
              target="_blank"
            ></StyledTermsLink>
            {isCoach
              ? isCoachAndClientRolesAvailable && (
                  <StyledAffiliateLink
                    // onClick={() => dispatch(pageAction.changeDropdown(true))}
                    to={ROUTES.ROLE_SWITCH}
                  >
                    Client Dashboard
                  </StyledAffiliateLink>
                )
              : shouldShowViewSwitchLink &&
                isCoachAndClientRolesAvailable === true &&
                (isLaunchPlan === false ||
                  (isLaunchPlan === true && user?.user?.isPartnerCoach) ||
                  (isLaunchPlan === true && user?.user?.havePaidTierPurchase)) &&
                currentRole === UserRoles.client && (
                  <StyledAffiliateLink
                    className={
                      user?.user?.isPartnerCoach === false && shouldBeDisabled === false
                        ? null
                        : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                        ? 'disableMe'
                        : shouldBeDisabled == true && user?.user?.isPartnerCoach === true
                        ? null
                        : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                        ? 'disableMe'
                        : null
                    }
                    onClick={() => dispatch(pageAction.changeDropdown(true))}
                    to={ROUTES.ROLE_SWITCH}
                  >
                    Coach Dashboard
                  </StyledAffiliateLink>
                )}
            <StyledTermsLink rel="noopener noreferer" href="https://www.cohere.live/privacy-policy" target="_blank">
              Cohere Terms and Privacy
            </StyledTermsLink>
          </SidebarTerms>
          {/* <Modal
        title="Terms and Privacy"
        isOpen={termsModal}
        hiddenCancel
        submitTitle="Exit"
        onSubmit={() => {
          setTermsModal(false);
        }}
        onCancel={() => {
          setTermsModal(false);
        }}
      >
        <FlexDiv>
          <a
            target="_blank"
            rel="noopener noreferer"
            href="https://www.cohere.live/wp-content/uploads/2020/11/CoherePrivacyPolicy-1.pdf"
          >
            Cohere Privacy Policy
          </a>
          <a
            target="_blank"
            rel="noopener noreferer"
            href="https://www.cohere.live/wp-content/uploads/2020/11/TermsofUse.pdf"
          >
            Terms of Use
          </a>
          <a
            target="_blank"
            rel="noopener noreferer"
            href="https://www.cohere.live/wp-content/uploads/2020/11/UserAgreement.pdf"
          >
            User Agreement
          </a>
          <a
            target="_blank"
            rel="noopener noreferer"
            href="https://www.cohere.live/wp-content/uploads/2020/11/CohereFeeAuthorizationAgreement.pdf"
          >
            Cohere Fee Authorization Agreement
          </a>
          <a
            target="_blank"
            rel="noopener noreferer"
            href="https://www.cohere.live/wp-content/uploads/2020/11/CohereOne-to-OneandGroupCoursePaymentAgreement.pdf"
          >
            Cohere One-to-One and Group Course Payment Agreement
          </a>
        </FlexDiv>
      </Modal> */}
        </div>
      </SidebarContainer>
    </>
  );
}

Sidebar.propTypes = {};

Sidebar.defaultProps = {};

export default Sidebar;
