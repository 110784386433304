import React from 'react';

const CommunityEditSvg = ({ width = '24px', height = '24px', color = '#215C73' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 17" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.45823 12.2841C3.49395 12.2841 3.52966 12.2805 3.56537 12.2752L6.56895 11.7484C6.60466 11.7412 6.63859 11.7252 6.66359 11.6984L14.2332 4.12873C14.2498 4.11221 14.2629 4.09259 14.2719 4.07098C14.2808 4.04938 14.2855 4.02622 14.2855 4.00284C14.2855 3.97945 14.2808 3.95629 14.2719 3.93469C14.2629 3.91309 14.2498 3.89346 14.2332 3.87694L11.2654 0.9073C11.2314 0.873372 11.1868 0.855515 11.1386 0.855515C11.0904 0.855515 11.0457 0.873372 11.0118 0.9073L3.44216 8.47694C3.41537 8.50373 3.3993 8.53587 3.39216 8.57159L2.86537 11.5752C2.848 11.6708 2.85421 11.7693 2.88346 11.862C2.91271 11.9547 2.96411 12.0389 3.03323 12.1073C3.15109 12.2216 3.2993 12.2841 3.45823 12.2841ZM4.6618 9.1698L11.1386 2.6948L12.4475 4.00373L5.97073 10.4787L4.38323 10.7591L4.6618 9.1698ZM14.5707 13.7841H1.42787C1.1118 13.7841 0.856445 14.0394 0.856445 14.3555V14.9984C0.856445 15.0769 0.920731 15.1412 0.999302 15.1412H14.9993C15.0779 15.1412 15.1422 15.0769 15.1422 14.9984V14.3555C15.1422 14.0394 14.8868 13.7841 14.5707 13.7841Z"
        fill={color}
        fill-opacity="0.87"
      />
    </svg>
  );
};

export default CommunityEditSvg;
