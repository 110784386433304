import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { UserRoles } from 'helpers/constants';
import { useRouter } from 'hooks';
import ClientsContainer from 'components/Containers/ClientsContainer';
import ClientDetailViewContainer from 'components/Containers/ClientDetailViewContainer';
import DirectVideoCall from 'components/Containers/TestContainer';
import ApplicationsContainer from 'components/Containers/ApplicationsContainer';
import VerifyEmail from 'pages/Profile/VerifyEmail';
import CalendarContainer from 'components/Containers/CalendarContainer';
import { PublicRoute, CommonRoute, ProtectedRoute } from '../Routing';
import DashboardContainer from '../Containers/DashboardContainer';
import ContributionsContainer from '../Containers/ContributionsContainer';
import LeadMagnetsContainer from '../Containers/LeadMagnetsContainer';
import AccountContainer from '../Containers/AccountContainer';
import CommunityContainer from '../Containers/CommunityContainer';
import CreateContributionContainer from '../Containers/CreateContributionContainer';
import EditContributionContainer from '../Containers/EditContributionContainer';
import ContributionViewContainer from '../Containers/ContributionViewContainer';
import AffiliatesContainer from 'components/Containers/AffiliatesContainer';
import AdminContainer from '../Containers/AdminContainer';
import EngagementDataContainer from '../Containers/EngagementDataContainer';
import ConversationsContainer from '../Containers/ConversationsContainer';
import CohealerAbout from '../../pages/CohealerAbout/CohealerAbout';
import ConfirmEmail from './ConfirmEmail';
import RestorePassword from './RestorePassword';
import ChatProvider from './ChatProvider';
import VideoChat from './VideoChat';
import { TestVideoChat } from './TestVideoChat';
import { ModalsAndLoadersContainer } from './ModalsAndLoaders';
import DownloadIcalFile from 'pages/Profile/DownloadIcal';
import LeadMagnetViewContainer from 'components/Containers/LeadMagnetViewContainer';
import CreateLeadMagnetContainer from 'components/Containers/CreateLeadMagnetContainer';
import EditLeadMagnetContainer from 'components/Containers/EditLeadMagnetContainer';
import CreateCampaignContainer from 'components/Containers/CreateCampaignContainer';
import EmailCampaignsContainer from 'components/Containers/EmailCampaignsContainer';
import ContactsContainer from 'components/Containers/ContactsContainer';
import DomainAuthenticationPage from 'pages/Account/DomainAuthenticationPage/DomainAuthentication';
import EditCampaignContainer from 'components/Containers/EditCampaignContainer';
import ApprovedPost from 'pages/ContributionView/ContributionSocial/components/ApprovedPost';
import CreateQuizContainer from 'components/Containers/CreateQuizContainer';
import LeadDetailViewContainer from 'components/Containers/LeadDetailViewContainer';
import EditQuizContainer from 'components/Containers/EditQuizContainer';
import QuizViewContainer from 'components/Containers/QuizViewContainer';
import CreateQuizesModal from 'pages/CreateQuizes/Components/Modals/CreateModal/CreateModal';
import { setShowCreateModal } from 'actions/quizes';
import { useDispatch, useSelector } from 'react-redux';
import VideoCallContainer from 'components/Containers/VideoCallContainer';

const AppContent = () => {
  const { pathname, query } = useRouter();
  const dispatch = useDispatch();
  const { showModal } = useSelector(state => state.quizes);
  const pathName = pathname.split('/');
  const isRedirectedToModule = pathName.length === 5 && pathName[3] === 'sessions';
  const isredirectedToNotes = pathName.length === 4 && pathName[3] === 'notes';
  const regExCommunity = /\/contribution-view\/.*\/community\/social/;
  const isRedirectToCommunitySocial = regExCommunity.test(pathname);
  const isRedirectToSessions = query.isPurchased;

  return (
    <ChatProvider>
      <Switch>
        <ProtectedRoute
          roles={[UserRoles.cohealer, UserRoles.client]}
          path="/dashboard"
          component={DashboardContainer}
        />
        <Route path="/DownloadFile/:downloadLink?/:contribId?/:docId?/:File?" component={DownloadIcalFile} />
        <Route path="/video-call/:contributionId?/:sessId?/:sessTimeId?/:token?" component={DirectVideoCall} />
        <ProtectedRoute
          path="/video-call-without-token/:contributionId?/:sessId?/:sessTimeId?"
          component={DirectVideoCall}
        />

        <Route exact path="/account/verifyemail/:ticks/:userId" component={VerifyEmail} />
        <ProtectedRoute
          roles={[UserRoles.cohealer]}
          path="/postverification/:contributionId?/:postId?/:status?"
          component={ApprovedPost}
        />
        <ProtectedRoute roles={[UserRoles.cohealer]} path="/contributions" component={ContributionsContainer} />
        <ProtectedRoute roles={[UserRoles.cohealer]} path="/lead-magnets" component={LeadMagnetsContainer} />
        <ProtectedRoute roles={[UserRoles.cohealer]} path="/contacts" component={ContactsContainer} />
        <ProtectedRoute roles={[UserRoles.cohealer]} path="/emails" component={EmailCampaignsContainer} />
        <ProtectedRoute
          roles={[UserRoles.cohealer]}
          path="/account/integrations/authenticate-domain"
          component={DomainAuthenticationPage}
        />
        <ProtectedRoute roles={[UserRoles.cohealer, UserRoles.client]} path="/account" component={AccountContainer} />

        <ProtectedRoute
          roles={[UserRoles.cohealer]}
          path="/create-contribution"
          component={CreateContributionContainer}
        />
        <ProtectedRoute roles={[UserRoles.cohealer]} path="/create-campaign" component={CreateCampaignContainer} />
        <ProtectedRoute roles={[UserRoles.cohealer]} path="/edit-campaign/:id" component={EditCampaignContainer} />
        <ProtectedRoute roles={[UserRoles.cohealer]} path="/create-quiz" component={CreateQuizContainer} />
        <ProtectedRoute roles={[UserRoles.cohealer]} path="/edit-quiz/:id" component={EditQuizContainer} />
        <CommonRoute roles={[UserRoles.client]} path="/quiz-view/:id?" component={QuizViewContainer} />
        <ProtectedRoute roles={[UserRoles.cohealer]} path="/create-form" component={CreateQuizContainer} />
        <ProtectedRoute roles={[UserRoles.cohealer]} path="/edit-form/:id" component={EditQuizContainer} />
        <CommonRoute roles={[UserRoles.client]} path="/form-view/:id?" component={QuizViewContainer} />
        <CommonRoute path="/VideoCall" component={VideoCallContainer} />

        <ProtectedRoute
          roles={[UserRoles.cohealer]}
          path="/edit-contribution/:id"
          component={EditContributionContainer}
        />
        {isRedirectToCommunitySocial || isRedirectToSessions || isRedirectedToModule || isredirectedToNotes ? (
          <ProtectedRoute path="/contribution-view/:id" component={ContributionViewContainer} />
        ) : (
          <CommonRoute path="/contribution-view/:id" component={ContributionViewContainer} />
        )}
        {isRedirectToCommunitySocial || isRedirectToSessions || isRedirectedToModule || isredirectedToNotes ? (
          <ProtectedRoute path="/lead-magnet-view/:id" component={LeadMagnetViewContainer} />
        ) : (
          <CommonRoute path="/lead-magnet-view/:id" component={LeadMagnetViewContainer} />
        )}
        {isRedirectToCommunitySocial || isRedirectToSessions || isredirectedToNotes ? (
          <ProtectedRoute path="/apply-contribution-view/:id" component={ContributionViewContainer} />
        ) : (
          <CommonRoute path="/apply-contribution-view/:id" component={ContributionViewContainer} />
        )}
        <ProtectedRoute roles={[UserRoles.cohealer]} path="/create-lead-magnet" component={CreateLeadMagnetContainer} />
        <ProtectedRoute roles={[UserRoles.cohealer]} path="/edit-lead-magnet/:id" component={EditLeadMagnetContainer} />

        <ProtectedRoute roles={[UserRoles.admin]} path="/admin" component={AdminContainer} />
        <CommonRoute roles={[UserRoles.admin]} path="/user/feedback" component={EngagementDataContainer} />
        <ProtectedRoute roles={[UserRoles.cohealer, UserRoles.client]} path="/calendar" component={CalendarContainer} />
        <ProtectedRoute roles={[UserRoles.cohealer]} path="/affiliates" component={AffiliatesContainer} />
        <ProtectedRoute
          roles={[UserRoles.cohealer, UserRoles.client]}
          path="/applications"
          component={ApplicationsContainer}
        />
        <ProtectedRoute roles={[UserRoles.cohealer, UserRoles.client]} path="/clients" component={ClientsContainer} />
        <ProtectedRoute
          roles={[UserRoles.cohealer, UserRoles.client]}
          path="/client/:id"
          component={ClientDetailViewContainer}
        />
        <ProtectedRoute
          roles={[UserRoles.cohealer, UserRoles.client]}
          path="/lead/:id"
          component={LeadDetailViewContainer}
        />
        <ProtectedRoute
          roles={[UserRoles.cohealer, UserRoles.client]}
          path="/community"
          component={CommunityContainer}
        />
        {/* <ProtectedRoute
          roles={[UserRoles.cohealer, UserRoles.client]}
          path="/community/newsfeed"
          component={CommunityContainer}
        /> */}
        <ProtectedRoute
          roles={[UserRoles.cohealer, UserRoles.client]}
          path="/conversations"
          component={ConversationsContainer}
        />
        <ProtectedRoute roles={[UserRoles.cohealer, UserRoles.client]} path="/confirmEmail" component={ConfirmEmail} />
        <PublicRoute
          roles={[UserRoles.cohealer, UserRoles.client]}
          path="/restorePassword"
          component={RestorePassword}
        />
        <ProtectedRoute
          roles={[UserRoles.cohealer, UserRoles.client, UserRoles.admin]}
          path="/cohealer-about/:cohealerId"
          component={CohealerAbout}
        />
        <Redirect to="/dashboard" />
      </Switch>
      <VideoChat />
      <TestVideoChat />
      <ModalsAndLoadersContainer />
      <CreateQuizesModal
        isOpen={showModal}
        handleClose={() => {
          dispatch(setShowCreateModal(false));
        }}
        handleSubmit={() => {
          dispatch(setShowCreateModal(false));
        }}
      />
    </ChatProvider>
  );
};

export default AppContent;
