import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import Grid from '@material-ui/core/Grid';
import { TextField, Tooltip } from '@material-ui/core';
import { Autocomplete, Chip } from '@mui/material';
import axiosInstance from 'utils/axiosInstance';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';
import { dataURLtoFile } from 'utils/utils';
import InfoIcon from '@material-ui/icons/Info';
import { useHttp, useRouter } from 'hooks/index';
import { useAccount } from 'hooks';
import bannerplaceholder from 'assets/bannerplaceholder.svg';
import bannerplaceholdernew from 'assets/bannerplacholdenew.png';
import { BIO_MAX_LENGTH } from 'pages/Profile/ProfilePage';
import usePreferences from 'hooks/usePreferences';
import { sortBy } from 'lodash';
import { BusinessInfoIcon } from '../icons/BusinessInfo';
import EasyCropper from '../../../../components/ImageCropper/Cropper/EasyCropper';
import { UploadImageIcon } from '../icons/UploadImage';
import SubdomainSelection from './SubdomainSelection';
import { EditIcon } from '../icons/Edit';
import { TOOLTIP } from '../../../../constants';

const HiddenFileInput = styled.input.attrs({ type: 'file' })`
  display: none;
`;

const StyledTooltipWrap = styled.span`
  position: relative;
  padding-right: 7px;
  display: inline-block;
  //display: flex;
  align-items: center;
  justify-content: start;
`;

const StyledTooltip = styled(Tooltip)`
  position: absolute;
  right: -23px;
  top: calc(65% - 0px);
  transform: translateY(-50%);
`;

const BusinessInformation = ({ values, setFieldValues, profileLinkName = '', setProfileLinkName }) => {
  const [imageToCrop, setImageToCrop] = useState('');
  const [isPrimaryOpen, setIsPrimaryOpen] = useState(false);
  const [isBioOpen, setIsBioOpen] = useState(false);
  const primaryFileInput = React.createRef();
  const bioFileInput = React.createRef();
  const [showDomainPopup, setShowDomainPopup] = useState(false);
  const { preferences } = usePreferences();
  const { request } = useHttp();
  const { parentDomain } = useRouter();

  const profilePageViewModel = (values && values.profilePageViewModel) || {};
  const subtagLine = profilePageViewModel.subtagLine || '';
  const tagline = profilePageViewModel.tagline || '';
  const primaryBannerUrl = profilePageViewModel.primaryBannerUrl || '';
  const bioBannerUrl = profilePageViewModel.bioBannerUrl || '';
  const bioHeader = profilePageViewModel.bioHeader || '';
  const isPrimaryBannerVideo = profilePageViewModel.isPrimaryBannerVideo || false;
  const tags = profilePageViewModel.tags || [];
  const { user } = useAccount();
  const UserId = user?.id;

  const uploadFileHandler = (imageName, droppedFiles = []) => e => {
    let files;
    if (droppedFiles.length > 0) {
      files = droppedFiles;
    } else if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (!files || !files[0]) {
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      if (files[0].type.includes('video/')) {
        const formData = new FormData();
        const imageSrc = reader.result;

        formData.append('file', dataURLtoFile(imageSrc));
        request('/content/AddPublicFile', 'POST', formData, {
          'Content-Type': 'multipart/form-data',
        }).then(video => {
          setFieldValues('profilePageViewModel', {
            ...profilePageViewModel,
            primaryBannerUrl: video,
            isPrimaryBannerVideo: true,
          });
        });
        return;
      }
      setImageToCrop(reader.result?.toString() || '');
      if (imageName === 'bio') setIsBioOpen(true);
      if (imageName === 'primary') setIsPrimaryOpen(true);
    };
    reader.readAsDataURL(files[0]);
  };

  let emptyString = '';
  const fieldPrimaryBannerUrl = 'PrimaryBannerUrl';
  const fieldBioBannerUrl = 'BioBannerUrl';
  const fieldValue = null;
  const fieldinUserCollection = false;

  const onDeleteClick_PrimaryBanner = useCallback(() => {
    try {
      axiosInstance
        .post(
          `Content/RemoveImagesFromS3BucketAndAccountPage?imageUrl=${primaryBannerUrl}&field=${fieldPrimaryBannerUrl}&fieldValue=${fieldValue} &userId=${UserId}&inUserCollection=${fieldinUserCollection}`,
        )
        .then(res => {
          setFieldValues('profilePageViewModel', {
            ...profilePageViewModel,
            primaryBannerUrl: emptyString,
            isPrimaryBannerVideo: false,
          });
        });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const onDeleteClick_BioBanner = async () => {
    try {
      const response = await axiosInstance.post(
        `Content/RemoveImagesFromS3BucketAndAccountPage?imageUrl=${bioBannerUrl}&field=${fieldBioBannerUrl}&fieldValue=${fieldValue}&userId=${UserId}&inUserCollection=${fieldinUserCollection}`,
      );
      setFieldValues('profilePageViewModel', { ...profilePageViewModel, bioBannerUrl: emptyString });
    } catch (error) {
      console.error(error);
    }
  };

  const onBioBannerUploadClick = useCallback(() => {
    if (bioFileInput) {
      bioFileInput.current.click();
    }
  }, [bioFileInput]);
  const onPrimaryBannerUploadClick = useCallback(() => {
    if (primaryFileInput) {
      primaryFileInput.current.click();
    }
  }, [primaryFileInput]);

  return (
    <Grid id={'bussinessInfo'} container className="profile-section">
      <Grid item xs={12} className="section-title">
        {/* <BusinessInfoIcon /> */}
        <div style={{ fontFamily: 'Avenir', fontSize: '24px', fontWeight: 500, lineHeight: '24px' }}>
          Website and Business Information
        </div>
      </Grid>
      <Grid item xs={12} sm={4} md={12} lg={4} xl={2}>
        <Grid container spacing={12}>
          <Grid item sm={12} md={12} xs={12} className="profile-picture">
            <StyledTooltipWrap>
              <p className="field-title">Primary Banner</p>
              <StyledTooltip
                title="Primary video/image for the website"
                arrow
                enterTouchDelay={TOOLTIP.ENTER_DELAY}
                leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
              >
                <InfoIcon htmlColor="#47627B" />
              </StyledTooltip>
            </StyledTooltipWrap>

            <div className="banner-avatar-container">
              {isPrimaryBannerVideo ? (
                <ReactPlayer
                  muted
                  url={primaryBannerUrl || bannerplaceholdernew}
                  playing
                  width="100%"
                  height="100%"
                  loop
                />
              ) : (
                <img alt="banner" src={primaryBannerUrl || bannerplaceholdernew} />
              )}
              {primaryBannerUrl !== '' && (
                <span
                  className="remove-image-button"
                  onClick={onDeleteClick_PrimaryBanner}
                  onKeyUp={onDeleteClick_PrimaryBanner}
                  role="button"
                  tabIndex="0"
                >
                  <CloseIcon style={{ color: 'white', width: '16px', height: '16px' }} />
                </span>
              )}
              <span
                className="upload-image-button"
                onClick={onPrimaryBannerUploadClick}
                onKeyUp={onPrimaryBannerUploadClick}
                role="button"
                tabIndex="0"
              >
                <UploadImageIcon />
              </span>
              <HiddenFileInput
                onChange={uploadFileHandler('primary')}
                ref={primaryFileInput}
                accept="image/jpeg,image/png,image/gif,video/*,video/mpeg,video/mp4,video/avi,video/webm,video/mkv,video/mov,video/quicktime"
              />
              <p>Video or Image should be of standard size(16:9) for better outlook.</p>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={12}>
          <Grid item sm={12} md={12} xs={12} className="profile-picture">
            <StyledTooltipWrap>
              <p className="field-title">Bio Banner</p>
              <StyledTooltip
                title="Backgound image for the bio."
                arrow
                enterTouchDelay={TOOLTIP.ENTER_DELAY}
                leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
              >
                <InfoIcon htmlColor="#47627B" />
              </StyledTooltip>
            </StyledTooltipWrap>
            <div className="banner-avatar-container">
              <img alt="banner" src={bioBannerUrl || bannerplaceholdernew} />
              {bioBannerUrl !== '' && (
                <span
                  className="remove-image-button"
                  onClick={onDeleteClick_BioBanner}
                  onKeyUp={onDeleteClick_BioBanner}
                  role="button"
                  tabIndex="0"
                >
                  <CloseIcon style={{ color: 'white', width: '16px', height: '16px' }} />
                </span>
              )}
              <span
                className="upload-image-button"
                onClick={onBioBannerUploadClick}
                onKeyUp={onBioBannerUploadClick}
                role="button"
                tabIndex="0"
                style={{ bottom: '54px' }}
              >
                <UploadImageIcon />
              </span>
              <HiddenFileInput
                onChange={uploadFileHandler('bio')}
                ref={bioFileInput}
                accept="image/jpeg,image/png,image/gif,video/mpeg"
              />
              <p>Photo should be approx. 820x312px and up to 5 mb.</p>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={8} md={12} lg={8} xl={10}>
        <Grid container>
          <Grid item sm={12} md={12} xs={12}>
            <p className="field-title">Tagline</p>
            <TextField
              className="input-field"
              id="outlined-basic"
              margin="normal"
              variant="outlined"
              fullWidth
              value={tagline}
              onChange={e =>
                setFieldValues('profilePageViewModel', { ...profilePageViewModel, tagline: e.target.value })
              }
              FormHelperTextProps={{
                style: {
                  textAlign: 'right',
                  marginRight: 0,
                },
              }}
              inputProps={{ maxLength: 100 }}
              helperText={
                values?.profilePageViewModel?.tagline?.length === undefined
                  ? `0/100`
                  : `${values?.profilePageViewModel?.tagline?.length}/100`
              }
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sm={12} md={12} xs={12}>
            <p className="field-title">Sub Tagline</p>
            <TextField
              className="input-field"
              id="outlined-basic"
              margin="normal"
              variant="outlined"
              fullWidth
              value={subtagLine}
              onChange={e =>
                setFieldValues('profilePageViewModel', { ...profilePageViewModel, subtagLine: e.target.value })
              }
              FormHelperTextProps={{
                style: {
                  textAlign: 'right',
                  marginRight: 0,
                },
              }}
              inputProps={{ maxLength: 100 }}
              helperText={
                values?.profilePageViewModel?.subtagLine?.length === undefined
                  ? `0/100`
                  : `${values?.profilePageViewModel?.subtagLine?.length}/100`
              }
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sm={12} md={12} xs={12}>
            <p className="field-title">Bio Header</p>
            <TextField
              className="input-field"
              id="outlined-basic"
              margin="normal"
              variant="outlined"
              fullWidth
              value={bioHeader}
              onChange={e =>
                setFieldValues('profilePageViewModel', { ...profilePageViewModel, bioHeader: e.target.value })
              }
              FormHelperTextProps={{
                style: {
                  textAlign: 'right',
                  marginRight: 0,
                },
              }}
              inputProps={{ maxLength: 50 }}
              helperText={
                values?.profilePageViewModel?.bioHeader?.length === undefined
                  ? `0/50`
                  : `${values?.profilePageViewModel?.bioHeader?.length}/50`
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <p className="field-title">Bio</p>
            <TextField
              className="input-field"
              id="outlined-basic"
              margin="normal"
              variant="outlined"
              fullWidth
              value={values.Bio}
              multiline
              rows={6}
              onChange={e => setFieldValues('Bio', e.target.value)}
              FormHelperTextProps={{
                style: {
                  textAlign: 'right',
                  marginRight: 0,
                },
              }}
              inputProps={{ maxLength: BIO_MAX_LENGTH }}
              helperText={`${values.Bio.length}/${BIO_MAX_LENGTH}`}
            />
          </Grid>
        </Grid>
        <Grid container spacing={12}>
          <Grid item sm={12} md={12} xs={12}>
            <p className="field-title">My Domain</p>
            <TextField
              className="input-field"
              id="outlined-basic"
              margin="normal"
              variant="outlined"
              fullWidth
              disabled
              onClick={() => setShowDomainPopup(true)}
              value={profileLinkName ? profileLinkName.concat('.', parentDomain) : 'Set your domain'}
              InputProps={{
                endAdornment: <EditIcon />,
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={12}>
          <Grid item sm={12} md={12} xs={12}>
            <p className="field-title">Business Name</p>
            <TextField
              className="input-field"
              id="outlined-basic"
              margin="normal"
              variant="outlined"
              fullWidth
              value={values.BusinessName}
              onChange={e => setFieldValues('BusinessName', e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item sm={12} xs={12}>
            <p className="field-title">Categories</p>
            <Autocomplete
              multiple
              id="tags-selection-input"
              options={sortBy(
                preferences.filter(item => item.name !== 'Create Custom Category'),
                ['name'],
              ).map(({ name }) => name)}
              disableCloseOnSelect
              getOptionLabel={option => option}
              freeSolo
              renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => (
                  <Chip key={option} label={option} {...getTagProps({ index })} />
                ));
              }}
              value={tags}
              onChange={(e, newValue) => {
                if (newValue.length > 0) {
                  const index = preferences.findIndex(val => val.name === newValue[newValue.length - 1]);
                  if (index === -1) return;
                }
                setFieldValues('profilePageViewModel', { ...profilePageViewModel, tags: newValue });
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  className="input-field"
                  id="outlined-basic"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <SubdomainSelection
        isOpen={showDomainPopup}
        setIsOpen={setShowDomainPopup}
        currentDomain={profileLinkName}
        setNewDomain={newValue => {
          axiosInstance.post(`/User/AddProfileLinkName?profileName=${newValue}`).then(() => {
            setShowDomainPopup(false);
            setProfileLinkName(newValue);
          });
        }}
      />
      <EasyCropper
        title={`Crop Your ${isBioOpen ? 'Bio Banner' : 'Primary Banner'} Image`}
        submitTitle="Set Image"
        imageToCrop={imageToCrop}
        isOpen={isBioOpen || isPrimaryOpen}
        onClose={() => {
          setIsBioOpen(false);
          setIsPrimaryOpen(false);
          setImageToCrop('');
        }}
        setFieldValue={(a, b) => {
          if (isPrimaryOpen)
            setFieldValues('profilePageViewModel', {
              ...profilePageViewModel,
              primaryBannerUrl: b,
              isPrimaryBannerVideo: false,
            });
          if (isBioOpen) setFieldValues('profilePageViewModel', { ...profilePageViewModel, bioBannerUrl: b });
        }}
        field="file"
        shape="rectangle"
      />
    </Grid>
  );
};

BusinessInformation.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    accountId: PropTypes.string.isRequired,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    birthDate: PropTypes.string,
    businessType: PropTypes.string,
    occupation: PropTypes.string,
    certification: PropTypes.string,
    customBusinessType: PropTypes.string,
    timeZoneId: PropTypes.string,
    timeZone: PropTypes.shape({
      timeZones: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    timeZoneLoading: PropTypes.bool.isRequired,
    fetchTimeZones: PropTypes.func.isRequired,
    avatarUrl: PropTypes.string,
    customerLabelPreference: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    businessName: PropTypes.string,
    bio: PropTypes.string,
  }).isRequired,
  imageResponse: PropTypes.func.isRequired,
  values: PropTypes.shape({
    Bio: PropTypes.string,
    BusinessName: PropTypes.string,
    profileLinkName: PropTypes.string,
    profilePageViewModel: {
      subtagLine: PropTypes.string,
      tagline: PropTypes.string,
      primaryBannerUrl: PropTypes.string,
      bioBannerUrl: PropTypes.string,
      personalUrl: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.string),
      isPrimaryBannerVideo: PropTypes.bool.isRequired,
    },
  }).isRequired,
  setFieldValues: PropTypes.func.isRequired,
  profileLinkName: PropTypes.string.isRequired,
  setProfileLinkName: PropTypes.func.isRequired,
};

export default BusinessInformation;
