import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { NAVIGATION_ACTIONS } from 'actions/Navigation';
import { useAccount, useRouter, useShallowEqualSelector } from 'hooks';
import * as userService from 'services/user.service';
import * as paidTier from 'selectors/paidTier';
import * as accountPreferences from 'services/accountPreferences.service';
import * as userActions from 'actions/user';
import { SIGNUP_TYPES, UserRoles } from 'helpers/constants';
import { PAID_TIER_TITLES, ROUTES } from '../../constants';
import { getUserProfile } from 'services/user.service';
import { getAccountPreferences } from 'services/accountPreferences.service';
import { redirectToAppIfIsCoachDomain } from 'utils/subdomains';

function RoleSwitch() {
  const dispatch = useDispatch();
  const { signupType } = useSelector(state => state.account);
  const { roles, currentRole, user } = useAccount();
  const {
    location: { state, search },
  } = useRouter();
  const params = new URLSearchParams(search);
  const domain = state ? state.domain : params.get('domain') ?? null;
  const path = state ? state.path : params.get('path') ?? null;
  const [redirectTo, setRedirectTo] = useState(null);
  const isCoachAndClientRolesAvailable = roles.includes(UserRoles.client) && roles.includes(UserRoles.cohealer);
  // const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  // const shouldBeDisabled = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch && user.isCohealer;

  useEffect(() => {
    if (currentRole === UserRoles.cohealer) {
      Promise.all([
        userService.switchFromCoachToClient(),
        accountPreferences.setAccountPreferences({ userView: UserRoles.client }),
      ])
        .then(([auth, preferences]) => {
          dispatch(userActions.roleChange(preferences.userView));
          dispatch(NAVIGATION_ACTIONS.setIsTransitionedToCoachView(false));
          setRedirectTo(path || ROUTES.DASHBOARD);
        })
        .catch(() => setRedirectTo(ROUTES.DASHBOARD));
    } else if (currentRole === UserRoles.client) {
      Promise.all([
        userService.switchFromClientToCoach({}),
        accountPreferences.setAccountPreferences({ userView: UserRoles.cohealer }),
      ])
        .then(([auth, preferences]) => {
          dispatch(userActions.roleChange(preferences.userView));

          if (user.signupType === SIGNUP_TYPES.TYPE_A || signupType === SIGNUP_TYPES.TYPE_A) {
            setRedirectTo(ROUTES.ACCOUNT_COACH_BILLING);
          } else if (user.signupType === SIGNUP_TYPES.TYPE_B || signupType === SIGNUP_TYPES.TYPE_B) {
            setRedirectTo(ROUTES.ACCOUNT_BILLING_EXTENDED);
          }
          // CMP1-3126 set to true only if user clicked on change to coach view link
          // the path variable will be null in this case
          // otherwise - address to redirect to
          const isTransitionedToCoach = !path;

          dispatch(NAVIGATION_ACTIONS.setIsTransitionedToCoachView(isTransitionedToCoach));
          if (domain) {
            redirectToAppIfIsCoachDomain(domain, path);
          } else {
            setRedirectTo(path || ROUTES.DASHBOARD);
          }
        })
        .catch(() => setRedirectTo(path || ROUTES.DASHBOARD));
    } else if (!isCoachAndClientRolesAvailable && currentRole === UserRoles.client) {
      setRedirectTo(ROUTES.ADD_ROLE);
    }
  }, [setRedirectTo]);

  return redirectTo ? <Redirect to={redirectTo} /> : null;
}

export default RoleSwitch;
