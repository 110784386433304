import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import * as pageActions from 'actions/page';
import Drawer from '@material-ui/core/Drawer';
import * as contributionActions from 'actions/contributions';
import * as leadMagnetActions from 'actions/leadMagnets';
import Sidebar from 'components/UI/Sidebar';
import Header from 'components/UI/Header';
import { ContributionType } from 'helpers/constants';
import { useRouter } from 'hooks';
import Modal from 'components/UI/Modal';
import { LabelText } from 'components/UI/Text/TextStyles';
import { lighten } from 'polished';
import { toggleMasterCalendarSideBar } from 'actions/masterCalendarSidebar.actions';
const StyledMainContainer = styled.div`
  background-color: #fafafa;
  min-height: 100vh;
  display: flex;
`;

const StyledPreviewMainContainer = styled.div`
  background-color: #dfd8c9;
  min-height: 100vh;
  display: flex;
`;

const StyledRightSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  width: calc(100% - 270px);
`;

function MainContainer({ headerProps, children, sidebarProps, previewPage = false, PrimaryColorCode }) {
  const theme = useTheme();
  const { history, pathname } = useRouter();
  const dispatch = useDispatch();
  const isMobileSidebarNeeded = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileSidebarOpen = useSelector(state => state.page.isMobileSidebarOpen);
  const [resultant, setResultant] = useState({
    show: false,
    isLeadMagnet: false,
    duplicated: null,
  });
  const [msg, setMsg] = useState({
    title: '',
    desc: '',
  });

  const toggleMobilesidebar = () => {
    dispatch(pageActions.toggleMobileSidebar());
    dispatch(toggleMasterCalendarSideBar(false));
  };
  const { contribution, loading } = useSelector(({ contributions }) => ({
    contribution: contributions.activeContribution,
    loading: contributions.loading,
  }));
  const { activeLeadMagnet } = useSelector(s => s.leadMagnets);

  const duplicateIt = values => {
    dispatch(contributionActions.showDuplicateLoading());
    const data = {
      userId: contribution.userId,
      title: contribution.title,
      categories: contribution.categories,
      type: contribution.type,
      previewContentUrls: contribution.previewContentUrls,
      isWorkshop: contribution.isWorkshop,
      purpose: contribution.purpose,
      whoIAm: contribution.whoIAm,
      whatWeDo: contribution.whatWeDo,
      preparation: contribution.preparation,
      whoIAmLabel: contribution.whoIAmLabel,
      whatWeDoLabel: contribution.whatWeDoLabel,
      purposeLabel: contribution.purposeLabel,
      preparationLabel: contribution.preparationLabel,
      languageCodes: contribution.languageCodes,
      isSpeakingLanguageRequired: contribution.isSpeakingLanguageRequired,
      minAge: contribution.minAge,
      gender: contribution.gender,
      activeTemplate: contribution.activeTemplate,
      isCustomBrandingColorsActive: contribution.isCustomBrandingColorsActive,
      customLogo: contribution.customLogo,
      isLegacyColorsActive: contribution.isLegacyColorsActive,
      isMembersHiddenInCommunity: contribution.isMembersHiddenInCommunity,
      brandingColors: contribution.brandingColors,
      coachSelectedBrandingColors: contribution.coachSelectedBrandingColors,
      paymentInfo: {
        paymentOptions: [],
      },
      isCommunityFeatureHiddden: contribution.isCommunityFeatureHiddden,
      isLiveSessionMembersHidden: contribution.isLiveSessionMembersHidden,
      arePublicPostsAllowed: true,
      areClientPublicPostsAllowed: contribution.areClientPublicPostsAllowed,
      instagramUrl: contribution.instagramUrl,
      linkedInUrl: contribution.linkedInUrl,
      youtubeUrl: contribution.youtubeUrl,
      facebookUrl: contribution.facebookUrl,
      websiteUrl: contribution.websiteUrl,
      tiktokUrl: contribution?.tiktokUrl,
      isDuplicate: true,
      parentContributionId: contribution.id,
      customTitleForMeetYourCoach: contribution?.customTitleForMeetYourCoach,
      contributionTagline: contribution?.contributionTagline,
      isThankyouPageAdded: contribution.isThankyouPageAdded,
      thankyouPage: contribution.thankyouPage,
      viewPreparation: contribution?.viewPreparation,
      viewPurpose: contribution?.viewPurpose,
      viewWhatWeDo: contribution?.viewWhatWeDo,
      viewWhoIAm: contribution?.viewWhoIAm,
      allowClientsToInvite: contribution?.allowClientsToInvite,
      inviteClientType: contribution?.inviteClientType,
      isDownloadEnabled: contribution?.isDownloadEnabled,
      isGroupChatHidden: contribution?.isGroupChatHidden,
      markSessionsAutoComplete: contribution?.markSessionsAutoComplete,
      clientRedirectLink: contribution.clientRedirectLink,
    };
    if ([ContributionType.contributionOneToOne, ContributionType.contributionLocalEvent].includes(contribution.type)) {
      data.isLive = true;
    }
    setResultant(true);
    dispatch(contributionActions.saveDraftContribution(data))
      .then(res => {
        dispatch(contributionActions.hideDuplicateLoading());
        setMsg({
          title: 'Duplicated Successfully',
          desc: ' Your service has been duplicated. Please note that Step 3 & 4 may requires additional information.',
        });
        setResultant({
          show: true,
          duplicated: res.payload,
        });
      })
      .catch(err => {
        dispatch(contributionActions.hideDuplicateLoading());
        if (err?.response?.data?.errors[0]?.errorMessage) {
          setMsg({
            title: 'Duplication Error',
            desc: err?.response?.data?.errors[0]?.errorMessage,
          });
        } else {
          setMsg({
            title: 'Duplication Error',
            desc: 'There was an error while duplicating, Please try again later!',
          });
        }
        setResultant({
          show: true,
          duplicated: null,
        });
      });
  };

  const duplicateLeadMagnet = () => {
    dispatch(leadMagnetActions.showDuplicateLoading());
    const data = {
      ...(activeLeadMagnet || []),
      arePublicPostsAllowed: true,
      isDuplicate: true,
      parentLeadMagnetId: activeLeadMagnet.id,
    };
    if (data?.id) {
      delete data?.id;
    }
    setResultant(true);
    dispatch(leadMagnetActions.saveDraftLeadMagnet(data))
      .then(res => {
        dispatch(leadMagnetActions.hideDuplicateLoading());
        setMsg({
          title: 'Duplicated Successfully',
          desc: 'Your lead magnet has been duplicated. Please note that Step 3 may require additional information.',
        });
        setResultant({
          show: true,
          isLeadMagnet: true,
          duplicated: res,
        });
      })
      .catch(err => {
        dispatch(leadMagnetActions.hideDuplicateLoading());
        if (err?.response?.data?.errors[0]?.errorMessage) {
          setMsg({
            title: 'Duplication Error',
            desc: err?.response?.data?.errors[0]?.errorMessage,
          });
        } else {
          setMsg({
            title: 'Duplication Error',
            desc: 'There was an error while duplicating, Please try again later!',
          });
        }
        setResultant({
          show: true,
          isLeadMagnet: true,
          duplicated: null,
        });
      })
      .finally(() => {
        dispatch(leadMagnetActions.hideDuplicateLoading());
      });
  };
  const closeDuplicationModal = () => {
    setResultant({
      ...resultant,
      show: false,
    });
    if (resultant.duplicated) {
      if (resultant.isLeadMagnet) {
        history.push(`/edit-lead-magnet/${resultant.duplicated.id}`);
      } else {
        history.push(`/edit-contribution/${resultant.duplicated.id}/basic`);
      }
    }
  };

  const sidebar = <Sidebar duplicateIt={duplicateIt} {...{ ...sidebarProps, duplicateLeadMagnet }} />;

  return previewPage ? (
    <StyledPreviewMainContainer style={{ backgroundColor: lighten(0.1, PrimaryColorCode) }}>
      <StyledRightSection>
        <Header {...headerProps} previewPage />
        {children}
      </StyledRightSection>
      <Modal
        brandingColors={false}
        isCreatingContribution={true}
        isOpen={resultant.show}
        hiddenCancel={true}
        onCancel={closeDuplicationModal}
        onSubmit={closeDuplicationModal}
        title={msg.title}
      >
        <p>{msg.desc}</p>
      </Modal>
    </StyledPreviewMainContainer>
  ) : (
    <StyledMainContainer>
      {isMobileSidebarNeeded ? (
        <Drawer open={!isMobileSidebarOpen} onClose={toggleMobilesidebar}>
          {sidebar}
        </Drawer>
      ) : (
        sidebar
      )}
      <StyledRightSection className={pathname.includes('calendar/master') ? 'master-calendar-right-side' : ''}>
        <Header {...headerProps} />
        {children}
      </StyledRightSection>
      <Modal
        isOpen={resultant.show}
        hiddenCancel={true}
        onCancel={closeDuplicationModal}
        onSubmit={closeDuplicationModal}
        title={msg.title}
      >
        <p>{msg.desc}</p>
      </Modal>
    </StyledMainContainer>
  );
}

MainContainer.propTypes = {
  headerProps: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  sidebarProps: PropTypes.shape({}),
};

MainContainer.defaultProps = {
  headerProps: {},
  sidebarProps: {},
};

export default MainContainer;
