import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import styled, { css } from 'styled-components';

import { toRem, devices } from 'utils/styles';
import Button from 'components/FormUI/Button';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import Portal from './Portal';
import './Modal.scss';
import useAccount from 'hooks/useAccount';
import { TemplateType } from 'helpers/constants/templateType';
import { connect } from 'react-redux';
import { ContributionType } from 'helpers/constants';
import { determineColorToUse, determineDarkThemedColorToUse } from 'services/contributions.service';
import { lightOrDark } from 'utils/utils';
import { getThemedColors } from 'services/contributions.service';
import { useLeadMagnet } from 'pages/LeadMagnetView/hooks';
import { Icon } from '@mdi/react';
import { mdiShareVariant } from '@mdi/js';
import SavingIcon from 'components/Icons/SavingIcon';
import SavedIcon from 'components/Icons/SavedIcon';
const StyledModalOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  ${({ isDropdown, zIndex }) =>
    isDropdown
      ? css`
          z-index: ${zIndex ? zIndex : 200};
        `
      : css`
          z-index: ${zIndex ? zIndex : 200};
        `}

  ${({ mobileView }) =>
    mobileView &&
    css`
      padding: 20px 0;
    `}
    ${({ noOverlay }) =>
    noOverlay &&
    css`
      background-color: rgba(0, 0, 0, 0.05);
    `}
`;

const StyledModalContainer = styled.div`
  min-height: ${props => (props.minHeight ? props.minHeight : '200px')};
  width: ${props => props.width};
  height: ${props => props.height};
  max-width: ${props => (props.maxWidth ? props.maxWidth : '800px')};
  min-width: ${props => (props.minWidth ? props.minWidth : '')};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  s @media screen and (${devices.mobile}) {
    min-width: 400px;
  }
  ${({ isDarkThemeEnabled, wholeModalColor }) =>
    isDarkThemeEnabled
      ? `background: #2d2f31;`
      : wholeModalColor
      ? `background: ${wholeModalColor};`
      : `background: #fff;`};
`;

const StyledModalHeader = styled.div`
  padding: 10px 20px;
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  ${({ headerBorder }) => (headerBorder ? 'border-bottom: 1px solid #e7e7e7' : '')}
  ${({ noPadding }) => (noPadding ? 'padding: 10px 20px 10px 15px;' : ' padding: 10px 20px;')}
`;

const StyledModalTitle = styled.span`
  font-size: ${toRem(22)};
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.19px;
  ${({ notesModal }) =>
    notesModal &&
    `display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
   `}
  ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? `color: #fff` : `color: rgba(0, 0, 0, 0.87)`)};
  ${({ mobileView }) =>
    mobileView &&
    `
    display: flex;
    flex-direction: column;
   `}
`;
const StyledQuizTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;
const StyledSubText = styled.span`
  font-family: Avenir;
  font-size: 16px;
  font-weight: ${({ modalType }) => (modalType === 'Quizes' ? 500 : 400)};
  ${({ notesModal }) =>
    notesModal &&
    `font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;`}
`;
const StyledModalBody = styled.div`
  ${({ createPost, noPadding }) =>
    noPadding ? `padding: 0px 0px;` : createPost ? `padding: 0px 20px;` : `padding: 30px 20px;`};
  ${({ bodyBackgroundColor }) => bodyBackgroundColor && `background:${bodyBackgroundColor}`};
  flex: 1;
  max-height: 70vh;
  ${({ isBookingModal }) => isBookingModal && `max-height: 85vh;`};
  /* overflow-y: scroll; */
  overflow: auto;

  ::-webkit-scrollbar {
    // width: 5px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #215c73;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: #215c73;
  }
`;

const StyledModalFooter = styled.div`
  border-top: ${({ hideBorder }) => (hideBorder ? '' : '1px solid #e7e7e7')};
  padding: 10px 20px;

  & button + button {
    margin-left: ${props => (!props.help ? '10px' : '0px')};
  }
`;

const StyledFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  display: ${({ CrossIconHide }) => CrossIconHide} ? none;
  ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? 'color: #fff' : 'color: #black')}
`;

const StyledCrossIcon = styled.div`
  margin-left: 20px;
  ${({ mobileView }) =>
    mobileView
      ? `display: flex !important;
  align-self: flex-start;
  padding-top: 6px;`
      : ''}
`;
const Modal = React.memo(
  ({
    brandingColor = true,
    contribution,
    title = '',
    isOpen,
    onCancel,
    onBack,
    isBackButton,
    dontCancelOnSideClick,
    onSubmit,
    closePopUp = false,
    closePopUpFn = () => {},
    children,
    cancelTitle,
    submitTitle,
    disableConfirm,
    disableFooter,
    loading,
    form,
    buttons,
    hiddenCancel,
    subText = '',
    helperText,
    mobileView,
    disableSubmitClick,
    disableJourneyButton,
    footer,
    cancelInvert,
    extraFooterElements,
    disableOverlayClick,
    submitOnClose,
    helpModal,
    mobile,
    widthRequired,
    widthRequiredIs,
    bodyBackgroundColor = false,
    wholeModalColor = false,
    widthSpacific,
    maxWidth = false,
    minWidth,
    CrossIconHide,
    applyTheming,
    className = '',
    isHideJourneyButton,
    onHide,
    hideJourneyTitle,
    closeOnly,
    onClickAnyWhere,
    isScheduleMeetingModal,
    isCustomWaiver,
    isUnifiedCommunity,
    isDarkModeSelected,
    isCreatingContribution,
    modalType = false,
    scrollToBottom = () => {},
    ignoreDisable = false,
    createPost = true,
    cancelAsBack = false,
    backButton = () => {},
    reduceCancelButton = false,
    headerBorder = false,
    hideFooterBorder,
    shrinkCancelButton = false,
    isDropdown = false,
    closeClick = false,
    onCloseClick = () => {},
    cancelBtnStyle,
    submitBtnStyle,
    GuideComponent = null,
    minHeight = '200px',
    bodyStyles,
    zIndex,
    noPadding = false,
    isBookingModal = false,
    height,
    isCustomHeight = false,
    noOverlay = false,
    notesModal = false,
    width = '',
  }) => {
    const { currentRole, user } = useAccount();
    const leadMagnet = useLeadMagnet();

    const {
      themedColor: themedTextColor,
      newThemedTextColor,
      themedCardBackgroundColor: oldThemedCardBackgroundColor,
      newThemedCardColor,
    } = getThemedColors(contribution);
    const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);
    const isCoach = currentRole == 'Cohealer';
    const themedColor = isCoach ? themedTextColor : newThemedTextColor;
    const themedCardBackgroundColor = oldThemedCardBackgroundColor;
    const activeTemplate = contribution?.activeTemplate || TemplateType.TemplateThree;
    const type = contribution?.type;
    // const brandingColors = contribution?.brandingColors;
    // const isCustomBrandingColorsActive = contribution?.isCustomBrandingColorsActive;
    // const shouldChangeColor = type != ContributionType.contributionOneToOne;
    // const customBtnColor = isCustomBrandingColorsActive
    //   ? brandingColors?.PrimaryColorCode
    //   : activeTemplate === TemplateType.TemplateOne
    //   ? '#6999CA'
    //   : '#D08ACD';
    let colorToUse = determineColorToUse(contribution);
    const CONTRIBUTION_COLORS = {
      PrimaryColorCode: '#C9B382',
      AccentColorCode: '#116582',
      TertiaryColorCode: '#F6E8BO',
    };
    const textColor =
      title?.includes && title.includes('Custom Branding Colors')
        ? '#FFFFFF'
        : colorToUse?.TextColorCode === 'Auto'
        ? lightOrDark(colorToUse?.PrimaryColorCode)
        : colorToUse?.TextColorCode === '#000000'
        ? '#000000'
        : '#FFFFFF';
    const btnColor = brandingColor ? colorToUse?.PrimaryColorCode : CONTRIBUTION_COLORS.PrimaryColorCode;

    const bgColor = brandingColor ? colorToUse?.AccentColorCode : CONTRIBUTION_COLORS.AccentColorCode;
    const isDarkMode = isCreatingContribution
      ? false
      : isDarkThemeEnabled && isDarkModeSelected
      ? isDarkModeSelected
      : isDarkThemeEnabled;
    const submitButtonRef = useRef(null);

    useEffect(() => {
      const body = document.getElementsByTagName('body')[0];
      if (isOpen) {
        body.style.overflow = 'hidden';
      }
      return () => {
        body.style.overflow = 'auto';
      };
    }, [isOpen]);
    // useEffect(() => {
    //   document.body.style.overflow = isOpen ? 'hidden' : 'unset';
    //   return () => {
    //     document.body.style.overflow = 'unset';
    //   };
    // });
    const handleOnCancel = useCallback(() => {
      if (submitOnClose && submitButtonRef.current) {
        submitButtonRef.current.click();
      } else {
        if (closeClick) {
          onCloseClick();
        } else {
          onCancel();
        }
      }
    }, [onCancel, form, closeClick]);

    const overlayCancelHandler = useCallback(() => {
      if (!loading && !disableOverlayClick) {
        handleOnCancel();
      }
    }, [loading, disableOverlayClick, handleOnCancel]);

    return (
      <>
        {isOpen && (
          <Portal>
            <StyledModalOverlay
              onClick={!dontCancelOnSideClick && overlayCancelHandler}
              mobileView={mobileView}
              className={className}
              isDropdown={isDropdown}
              zIndex={zIndex}
              noOverlay={noOverlay}
            >
              <StyledModalContainer
                isDarkThemeEnabled={isDarkMode}
                wholeModalColor={wholeModalColor}
                className={`cohere-modal ${isDarkMode ? 'cohere-modal-dark' : ''}`}
                style={applyTheming ? { backgroundColor: themedCardBackgroundColor, color: themedColor } : {}}
                width={
                  widthRequired
                    ? (widthRequired = '800px')
                    : widthRequiredIs
                    ? (widthRequiredIs = '500px')
                    : ignoreDisable
                    ? '900px'
                    : width
                    ? width
                    : ''
                }
                maxWidth={maxWidth}
                minWidth={minWidth}
                minHeight={minHeight}
                height={isCustomHeight ? height : 'auto'}
                onClick={e => {
                  e.stopPropagation();
                  if (isScheduleMeetingModal) {
                    onClickAnyWhere();
                  }
                }}
              >
                {!CrossIconHide && (
                  <StyledModalHeader noPadding={noPadding} createPost className="header" headerBorder={headerBorder}>
                    {modalType === 'Quizes' ? (
                      <StyledQuizTitleWrapper>
                        {title.length > 0 && (title?.includes('Share') || title?.includes('Invite')) && (
                          <Icon path={mdiShareVariant} color="black" size={1} />
                        )}
                        <StyledModalTitle
                          isDarkThemeEnabled={isDarkMode}
                          style={applyTheming ? { color: themedColor } : isDarkMode ? { color: 'white' } : {}}
                          mobileView={mobileView}
                        >
                          {title} {subText.length > 0 && <StyledSubText>{subText}</StyledSubText>}
                        </StyledModalTitle>
                      </StyledQuizTitleWrapper>
                    ) : (
                      <StyledModalTitle
                        isDarkThemeEnabled={isDarkMode}
                        style={applyTheming ? { color: themedColor } : isDarkMode ? { color: 'white' } : {}}
                        mobileView={mobileView}
                        notesModal={notesModal}
                      >
                        {title}{' '}
                        {subText.length > 0 && (
                          <StyledSubText modalType={modalType} notesModal={notesModal}>
                            {subText === 'Saving...' && <SavingIcon />}
                            {subText === 'Saved' && <SavedIcon />}
                            {subText}
                          </StyledSubText>
                        )}
                      </StyledModalTitle>
                    )}
                    <StyledCrossIcon mobileView={mobileView}>
                      {GuideComponent}
                      <StyledCloseIcon
                        isDarkThemeEnabled={isDarkMode}
                        onClick={
                          closePopUp
                            ? closePopUpFn
                            : cancelAsBack
                            ? backButton
                            : closeClick
                            ? onCloseClick
                            : handleOnCancel
                        }
                        style={leadMagnet?.isDarkModeEnabled ? { color: 'white' } : {}}
                        CrossIconHide={CrossIconHide}
                        subText={subText}
                      />
                    </StyledCrossIcon>
                  </StyledModalHeader>
                )}
                {CrossIconHide && <div style={{ paddingTop: '16px' }} className="cross-icon-hide"></div>}
                <StyledModalBody
                  bodyBackgroundColor={bodyBackgroundColor}
                  createPost={createPost}
                  noPadding={noPadding}
                  isBookingModal={isBookingModal}
                  className="body"
                  style={{ ...bodyStyles }}
                >
                  {children}
                </StyledModalBody>
                {!disableFooter && (
                  <StyledModalFooter help hideBorder={hideFooterBorder}>
                    {helperText && <CommonErrorMessage message={helperText} align="right" />}
                    <StyledFooterContainer className="modal-footer-container">
                      {footer}
                      <div
                        style={{
                          width: helpModal && mobile ? '100%' : helpModal ? '50%' : '',
                          display: 'flex',
                          justifyContent: helpModal ? 'space-between' : '',
                          gap: helpModal ? '10px' : '',
                          marginBottom: '10px',
                        }}
                      >
                        {buttons.map(({ buttonTitle, ...rest }) => (
                          <Button removeLetterSpaccing={helpModal} key={buttonTitle} {...rest}>
                            {buttonTitle}
                          </Button>
                        ))}
                      </div>
                      <div
                        className="modal-footer-buttons"
                        style={{
                          display: helpModal ? 'flex' : '',
                          flexDirection: helpModal && mobile ? 'column-reverse' : '',
                          width: helpModal && mobile ? '100%' : helpModal ? '50%' : ' ',
                          justifyContent: helpModal ? 'flex-end' : '',
                        }}
                      >
                        {extraFooterElements}
                        {!hiddenCancel && (
                          <Button
                            className={
                              shrinkCancelButton
                                ? `cancel-understood-btn ${helpModal ? 'help' : ''} `
                                : reduceCancelButton
                                ? `cancel-reduced-btn ${helpModal ? 'help' : ''} `
                                : modalType === 'Quizes'
                                ? `cancel-btn-quizes ${helpModal ? 'help' : ''}`
                                : `cancel-btn ${helpModal ? 'help' : ''}`
                            }
                            disabled={loading}
                            onClick={isBackButton ? onBack : closeClick ? onCancel : handleOnCancel}
                            invert={cancelInvert}
                            removeLetterSpaccing={helpModal}
                            autoWidth
                            style={
                              cancelTitle === 'No thanks'
                                ? {
                                    marginBottom: helpModal ? '10px' : '',
                                    marginRight: !helpModal ? '5px' : '',
                                    border: isDarkMode ? `1px solid ${themedColor}` : `1px solid black`,
                                    paddingTop: helpModal ? '15px !important' : '',
                                    paddingBottom: helpModal ? '15px !important' : '',
                                    backgroundColor: disableConfirm
                                      ? btnColor
                                      : isDarkMode
                                      ? themedCardBackgroundColor
                                      : 'white',
                                    color: isDarkMode ? themedColor : reduceCancelButton ? '#215C73' : '',
                                    ...cancelBtnStyle,
                                  }
                                : cancelTitle === 'Never Mind'
                                ? {
                                    marginBottom: helpModal ? '10px' : '',
                                    marginRight: !helpModal ? '5px' : '',
                                    border: `1px solid ${btnColor}`,
                                    color: btnColor,
                                    paddingTop: helpModal ? '15px !important' : '',
                                    paddingBottom: helpModal ? '15px !important' : '',
                                    backgroundColor: 'white',
                                    border: `1px solid ${btnColor}`,

                                    ...cancelBtnStyle,
                                  }
                                : cancelTitle === 'Never mind'
                                ? {
                                    marginBottom: helpModal ? '10px' : '',
                                    marginRight: !helpModal ? '5px' : '',
                                    border: `1px solid ${btnColor}`,
                                    color: btnColor,
                                    paddingTop: helpModal ? '15px !important' : '',
                                    paddingBottom: helpModal ? '15px !important' : '',
                                    backgroundColor: disableConfirm ? btnColor : 'white',
                                    ...cancelBtnStyle,
                                  }
                                : modalType === 'Quizes'
                                ? {
                                    marginBottom: helpModal ? '10px' : '',
                                    // marginRight: !helpModal ? '5px' : '',
                                    border: `1px solid black`,
                                    paddingTop: helpModal ? '15px !important' : '',
                                    paddingBottom: helpModal ? '15px !important' : '',
                                    backgroundColor: 'white',
                                    color: 'black',
                                    ...cancelBtnStyle,
                                  }
                                : {
                                    marginBottom: helpModal ? '10px' : '',
                                    marginRight: !helpModal ? '5px' : '',
                                    border: disableConfirm
                                      ? `1px solid ${btnColor}`
                                      : isDarkMode
                                      ? `1px solid ${themedColor}`
                                      : `1px solid ${bgColor}`,
                                    paddingTop: helpModal ? '15px !important' : '',
                                    paddingBottom: helpModal ? '15px !important' : '',
                                    backgroundColor: disableConfirm
                                      ? btnColor
                                      : isDarkMode
                                      ? themedCardBackgroundColor
                                      : 'white',
                                    color: isDarkMode ? themedColor : reduceCancelButton ? '#215C73' : '',
                                    ...cancelBtnStyle,
                                  }
                            }
                          >
                            {cancelTitle}
                          </Button>
                        )}
                        {!disableConfirm && (
                          <Button
                            textColor={submitTitle === 'Choose Template' ? '#FFFFFF' : textColor}
                            backgroundColor={
                              disableSubmitClick
                                ? 'lightGray'
                                : submitTitle === 'I understand'
                                ? CONTRIBUTION_COLORS.PrimaryColorCode
                                : btnColor
                            }
                            className={`submit-btn ${helpModal ? 'help' : ''}`}
                            disabled={ignoreDisable ? false : disableSubmitClick || loading}
                            onClick={e => {
                              disableSubmitClick || loading ? scrollToBottom() : onSubmit(e);
                            }}
                            form={form}
                            type={form ? 'submit' : 'button'}
                            removeLetterSpaccing={helpModal}
                            autoWidth
                            style={{
                              marginBottom: helpModal ? '10px' : '',
                              marginLeft: '15px',
                              color: isCreatingContribution || brandingColor ? 'white' : '',
                              ...submitBtnStyle,
                            }}
                          >
                            {submitTitle}
                          </Button>
                        )}
                        {isHideJourneyButton && (
                          <Button
                            textColor={textColor}
                            backgroundColor={
                              disableJourneyButton
                                ? 'lightGray'
                                : submitTitle === 'I understand'
                                ? CONTRIBUTION_COLORS.PrimaryColorCode
                                : btnColor
                            }
                            className="submit-btn"
                            disabled={disableJourneyButton || loading}
                            onClick={onHide}
                            form={form}
                            type={form ? 'submit' : 'button'}
                            removeLetterSpaccing={helpModal}
                            autoWidth
                            style={{
                              marginBottom: helpModal ? '10px' : '',
                              marginLeft: mobileView ? '' : '5px',
                              marginTop: mobileView ? '15px' : '',
                              width: mobileView ? '100%' : '',
                            }}
                          >
                            {hideJourneyTitle}
                          </Button>
                        )}
                        {!disableConfirm && (
                          <button
                            disabled={disableSubmitClick || loading}
                            onClick={onSubmit}
                            form={form}
                            hidden="hidden"
                            type={form ? 'submit' : 'button'}
                            ref={submitButtonRef}
                          >
                            {submitTitle}
                          </button>
                        )}
                      </div>
                    </StyledFooterContainer>
                  </StyledModalFooter>
                )}
              </StyledModalContainer>
            </StyledModalOverlay>
          </Portal>
        )}
      </>
    );
  },
);

Modal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onBack: PropTypes.func,
  isBackButton: PropTypes.bool,
  children: PropTypes.node,
  cancelTitle: PropTypes.string,
  submitTitle: PropTypes.string,
  disableConfirm: PropTypes.bool,
  disableJourneyButton: PropTypes.bool,
  cancelInvert: PropTypes.bool,
  loading: PropTypes.bool,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      buttonTitle: PropTypes.string,
    }),
  ),
  form: PropTypes.string,
  hiddenCancel: PropTypes.bool,
  helperText: PropTypes.string,
  mobileView: PropTypes.bool,
  footer: PropTypes.node,
  extraFooterElements: PropTypes.node,
  disableSubmitClick: PropTypes.bool,
  disableFooter: PropTypes.bool,
  disableOverlayClick: PropTypes.bool,
  submitOnClose: PropTypes.bool,
  applyTheming: PropTypes.bool,
  closeOnly: PropTypes.bool,
  backButton: PropTypes.func,
  cancelAsBack: PropTypes.bool,
  reduceCancelButton: PropTypes.bool,
  hideFooterBorder: PropTypes.bool,
  shrinkCancelButton: PropTypes.bool,
  closeClick: PropTypes.bool,
  onCloseClick: PropTypes.func,
};

Modal.defaultProps = {
  title: '',
  isOpen: false,
  onCancel: () => {},
  onSubmit: () => {},
  onBack: () => {},
  onHide: () => {},
  onCloseClick: () => {},
  isBackButton: false,
  isHideJourneyButton: false,
  children: null,
  cancelTitle: 'Cancel',
  submitTitle: 'OK',
  removeTitle: 'Remove',
  disableConfirm: false,
  disableJourneyButton: false,
  cancelInvert: true,
  loading: false,
  buttons: [],
  form: null,
  hiddenCancel: false,
  helperText: '',
  mobileView: false,
  footer: null,
  extraFooterElements: null,
  disableSubmitClick: false,
  disableFooter: false,
  disableOverlayClick: false,
  submitOnClose: false,
  applyTheming: false,
  closeOnly: false,
  isCustomWaiver: false,
  isDarkModeSelected: false,
  backButton: () => {},
  cancelAsBack: false,
  reduceCancelButton: false,
  hideFooterBorder: false,
  shrinkCancelButton: false,
  closeClick: false,
};

const mapStateToProps = ({ contributions }) => ({
  contribution: contributions.activeContribution,
});
export default connect(mapStateToProps)(Modal);
