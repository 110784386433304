import React, { useEffect, useRef, useState } from 'react';
import { Autocomplete, InputAdornment, TextField, Typography, Box } from '@mui/material';
import { fetchContributionActions } from 'actions/contributions';
import { ContributionType } from 'helpers/constants';
import * as contributionActions from 'actions/contributions';
import * as contributionService from 'services/contributions.service';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'components/UI/Loader';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { isEmpty } from 'lodash';
import { ClickAwayListener, Popper } from '@material-ui/core';
import Chip from '@mui/material/Chip';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import DropdownIcon from 'pages/CreateQuizes/Pages/QuizContent/Assests/Icons/DropdownIcon';
import NoImage from 'assets/NoImage.png';
import { useMediaQuery, useTheme } from '@material-ui/core';
import styled from 'styled-components';
import CustomButton from 'components/FormUI/Button';
import { formatTime } from 'utils/utils';
import { setDefaultWeeklyData } from 'actions/oneToOneData';

const selector = state => ({
  contributionsForTable: state?.upcomingCreated?.upcomingCreated?.contributionsForTable || [],
  loadingContibutions: state?.upcomingCreated?.loading || false,
});

const StyledAutocomplete = styled(Autocomplete)`
  .MuiAutocomplete-inputRoot {
    padding-right: 20px !important;
  }
  .MuiAutocomplete-endAdornment {
    top: auto;
  }

  svg {
    color: black;
  }
`;

const StyledPopper = styled(Popper)`
  z-index: 1400 !important;
`;

const FooterContainer = styled(Box)`
  margin: 10px 0px;
  display: flex;
  width: 100%;
  justify-content: right;
  gap: 16px;
  margin-top: 5px !important;
  padding: 0px 16px;
`;

const StyledLine = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 10px;
  background-color: ${({ color }) => color};
  padding: 0px !important;
`;
const NextButton = styled(CustomButton)`
  height: 38px;
  width: 93px;
  gap: 0px;
  border-radius: 5px;
  opacity: 0px;
  display: flex;
  padding: 12px 28px;
  align-items: center;
  align-self: stretch;
  background: var(--Cohere-Secondary-Brown, #c9b382);
`;

function CustomOption({ option, selected, isMobileView }) {
  return (
    <div
      className="dropdownList"
      role="button"
      tabIndex="0"
      style={{
        // borderTop: '1px solid #f2f2f2',
        cursor: 'pointer',
      }}
    >
      <p
        className="dropdownList"
        style={{
          margin: '8px',
          color: '#2B2B2B',
          fontSize: '16px',
          fontWeight: '400',
          lineHeight: '20px',
          wordWrap: 'break-word',
          fontFamily: 'Avenir',
          display: 'flex',
          pointerEvents: 'none',
          alignItems: 'center',
        }}
      >
        <img
          style={{
            width: isMobileView ? '113px' : '121px',
            height: isMobileView ? '44px' : '48px',
            marginRight: '8px',
            borderRadius: '2px',
            border: '1px',
          }}
          src={option.image.length === 0 ? NoImage : option?.image}
          alt={option.label}
        />
        <span>{option.label}</span>
      </p>
    </div>
  );
}

const ContributionForm = ({
  contributionSelectionDisable,
  setpricingOption,
  availabilityModal = false,
  setFieldValue = () => {},
  formErrors,
  touched,
  selectedContribution = null,
  fromMasterCalendar,
  hasActiveContribution,
  setIsAvailabilityPanelExpanded = () => {},
  setIsExpansionDisabledAvailability = () => {},
  setOneToOneAvailability = () => {},
}) => {
  const { activeContribution } = useSelector(state => state.contributions);
  const masterCalendarArray = useSelector(state => state?.contributions?.masterCalendarArray);
  const { manageAvailabilitySelection, selectedMasterCalendarContribution } = useSelector(
    state => state.masterCalendar,
  );
  const [loadingContribution, setLoadingContribution] = useState(false);
  const { contributionsForTable, loadingContibutions } = useSelector(selector);
  const [contributionLabel, setContributionLabel] = useState('initial');
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [selectedClient, setSelectedClient] = useState([]);
  const [selectedContributionId, setSelectedContributionId] = useState([]);
  const [contributionOptions, setContributionOptions] = useState([]);
  const [tagsNumber, setTagsNumber] = useState(0);
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const { id } = useContribution();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const [contributionSelected, setContributionSelected] = useState(false);
  useEffect(() => {
    setLoadingContribution(true);
    if (selectedContribution != null) {
      setSelectedClient([selectedContribution[0]]);
      setTagsNumber(1);
      setFieldValue('contributions', [selectedContribution[0]]);
      dispatch(contributionActions.setMasterCalendarArray([selectedContribution[0]]));
    }

    contributionService
      .getContributionFromList('ContributionOneToOne', id)
      .then(res => {
        setLoadingContribution(false);
        const contributions = res.map(contrib => {
          return {
            label: contrib?.title || '',
            id: contrib?.id || '',
            image: contrib?.contributionImage || contrib?.image || '',
            createTime: formatTime(contrib?.createTime),
          };
        });
        const filteredContribution = contributions.filter(item => item.id === selectedMasterCalendarContribution);
        setContributionOptions(contributions);
        if (manageAvailabilitySelection === 'booking-availability') {
          setSelectedClient(filteredContribution);
          setFieldValue('contributions', contributions);
          if (selectedMasterCalendarContribution?.length === 0 || selectedMasterCalendarContribution === null) {
            if (filteredContribution?.length > 0) {
              dispatch(contributionActions.setMasterCalendarArray([filteredContribution[0]]));
            }
          }
        } else {
          if (availabilityModal && res?.length > 0) {
            setSelectedClient([contributions[0]]);
            setFieldValue('contributions', contributions);
            if (selectedMasterCalendarContribution?.length === 0 || selectedMasterCalendarContribution === null) {
              if (contributions?.length > 0) {
                dispatch(contributionActions.setMasterCalendarArray([contributions[0]]));
              }
            }
          }
        }

        const setInitialData = async () => {
          setLoadingContribution(true);
          if (availabilityModal === false && filteredContribution.length > 0) {
            if (filteredContribution?.length > 0) {
              setContributionLabel(filteredContribution[0]?.label);
              setSelectedContributionId(filteredContribution[0]?.id);
            }
          } else {
            if (contributions?.length > 0) {
              setContributionLabel(contributions[0]?.label);
              setSelectedContributionId(contributions[0]?.id);
            }
            if (masterCalendarArray?.length > 0) {
              if (filteredContribution?.length > 0) {
                dispatch(contributionActions.setMasterCalendarArray([filteredContribution[0]]));
              }
            } else {
              if (contributions?.length > 0) {
                dispatch(contributionActions.setMasterCalendarArray([contributions[0]]));
              }
            }
          }
          setLoadingContribution(false);
        };
        setInitialData();
      })
      .catch(error => {
        setLoadingContribution(false);
      });
  }, []);
  useEffect(() => {
    if (selectedClient?.length === 1 && selectedClient[0] != undefined) {
      if (availabilityModal === true) {
        dispatch(contributionActions.fetchCohealerContribution(selectedClient[0].id));
      } else {
        setLoadingContribution(false);
      }
    } else {
      dispatch(contributionActions.fetchCustomContributionTimesActions.success([]));
      dispatch(contributionActions.fetchContributionTimesActions.success([]));
      dispatch(contributionActions.fetchContributionActions.success(null));
    }
    setIsExpansionDisabledAvailability(false);
    setOneToOneAvailability(null);
  }, [selectedClient]);
  useEffect(() => {
    if (activeContribution != null && availabilityModal) {
      // dispatch(
      //   contributionActions.fetchCustomContributionTimes(
      //     selectedClient[0]?.id,
      //     activeContribution?.oneToOneSessionDataUi?.sessionDuration,
      //     activeContribution?.oneToOneSessionDataUi?.sessionDuration,
      //   ),
      // );
    } else {
      if (selectedContributionId != undefined && selectedContributionId.length > 0) {
        dispatch(contributionActions.fetchContributionTimes(selectedContributionId, true));
        setLoadingContribution(false);
      }
    }
  }, [activeContribution, selectedClient, selectedContributionId]);

  const handleOutsideClick = e => {
    if (!isDropdownVisible || !dropdownRef?.current) {
      return;
    }
    if (
      !e?.target?.className?.includes('dropdownList') &&
      !dropdownRef?.current?.contains(e.target) &&
      !e?.target?.className?.includes('MuiAutocomplete-option')
    ) {
      setIsDropdownVisible(false);
    }
  };
  function areAllObjectsPresent(childArray, parentArray) {
    return childArray.every(childObj =>
      parentArray.some(parentObj => Object.entries(childObj).every(([key, value]) => parentObj[key] === value)),
    );
  }
  function removeCommonObjects(childArray, parentArray) {
    const filteredParentArray = parentArray.filter(
      parentObj => !childArray.some(childObj => Object.keys(childObj).every(key => parentObj[key] === childObj[key])),
    );
    return filteredParentArray;
  }
  function isKeyWithValuePresent(key, value, arrayOfObjects) {
    return arrayOfObjects.some(obj =>
      Object.entries(obj).some(([objKey, objValue]) => objKey === key && objValue === value),
    );
  }

  const setContribution = async (selectedContributionId, selectedContributionLabel) => {
    if (!selectedContributionId) return;
    if (hasActiveContribution) {
      setLoadingContribution(true);
      const result = await contributionService.getCohealerContribution(selectedContributionId);
      setLoadingContribution(false);
      setpricingOption({ paymentInfo: result.paymentInfo, id: selectedContributionId });
      dispatch(fetchContributionActions.success(result));
    }
    setContributionLabel(selectedContributionLabel);
    setSelectedContributionId(selectedContributionId);
  };
  const handleNext = async () => {
    setLoadingContribution(true);
    const result = await contributionService.getCohealerContribution(selectedContributionId);
    setLoadingContribution(false);
    setpricingOption({ paymentInfo: result.paymentInfo, id: selectedContributionId });
    setContributionSelected(true);
    dispatch(fetchContributionActions.success(result));
  };
  const getItemLable = option => {
    if (isMobileView && option?.label?.length > 30) {
      return `${option?.label.substring(0, 30)}...`;
    }
    return option?.label;
  };

  const CustomTagDisplay = ({ option, onDelete, isDarkThemeApplied, numOfTags, availabilityModal }) => {
    const maxLength = 20; // Set the maximum length for the displayed text
    const displayText =
      option?.label?.length > maxLength
        ? option?.label?.substring(0, maxLength) + '...' // Truncate the text
        : option?.label;
    const chipStyle = {
      color: 'white',
      border: '1px solid white',
    };
    return availabilityModal ? (
      <Chip
        label={numOfTags > 1 ? displayText : option?.label}
        onDelete={() => {
          const updatedArray = selectedClient.filter(contrib => contrib.id !== option.id);
          setSelectedClient(updatedArray);
          dispatch(contributionActions.setMasterCalendarArray(updatedArray));
        }}
        style={
          isDarkThemeApplied
            ? chipStyle
            : { fontFamily: 'Avenir', marginRight: '3px', marginBottom: isMobileView ? '3px' : '' }
        }
      />
    ) : (
      <Chip
        label={numOfTags > 1 ? displayText : option?.label}
        onDelete={null}
        style={isDarkThemeApplied ? chipStyle : {}}
      />
    );
  };

  return (
    <>
      {loadingContribution && <Loader zIndex={1300} />}
      <div
        style={{
          flexDirectio: 'row',
          justifyContent: 'space-between',
          display: 'flex',
          padding: !availabilityModal && !hasActiveContribution ? '0px 16px' : '',
        }}
      >
        <div
          style={
            availabilityModal
              ? { fontSize: '16px', fontFamily: 'Avenir', fontWeight: '900', margin: 0, color: '#000000DE' }
              : {
                  fontSize: '16px',
                  fontFamily: 'Avenir',
                  margin: 0,
                  color: '#215C73',
                  fontWeight: '500',
                }
          }
        >
          {availabilityModal ? 'Update Availability For Which Services?' : 'Service'}
        </div>
        {availabilityModal && contributionOptions.length > 0 && (
          <div
            onClick={() => {
              if (selectedClient.length === contributionOptions.length) {
                setSelectedClient([]);
                setFieldValue('contributions', []);
                dispatch(contributionActions.setMasterCalendarArray([]));
              } else {
                setSelectedClient(contributionOptions);
                setFieldValue('contributions', contributionOptions);
                dispatch(contributionActions.setMasterCalendarArray(contributionOptions));
              }
              setIsAvailabilityPanelExpanded(false);
              setIsExpansionDisabledAvailability(false);
              setOneToOneAvailability(null);
              dispatch(contributionActions.fetchContributionActions.success(null));
              dispatch(contributionActions.fetchContributionTimesActions.success([]));
              dispatch(contributionActions.fetchCustomContributionTimesActions.success([]));
            }}
            style={{
              fontSize: '16px',
              fontFamily: 'Avenir',
              margin: 0,
              fontWeight: '400',
              cursor: 'pointer',
              textAlign: 'end',
            }}
          >
            {selectedClient.length === contributionOptions.length ? 'Deselect All Services' : 'Select All Services'}
          </div>
        )}
      </div>

      <ClickAwayListener onClickAway={handleOutsideClick}>
        <StyledAutocomplete
          multiple={availabilityModal ? true : false}
          name="contribution"
          fullWidth
          ref={dropdownRef}
          disabled={contributionSelectionDisable}
          style={
            availabilityModal
              ? {
                  marginBottom: '10px',
                  fontFamily: 'Avenir',
                  whiteSpace: 'break-spaces',
                  border: '1px solid #DFE3E4',
                  height: hasActiveContribution ? '40px' : '',
                  width: 'unset',
                  margin: !availabilityModal && !hasActiveContribution ? '4px 16px 16px 16px' : '',
                  borderRadius: '4px',
                }
              : {
                  marginBottom: '10px',
                  fontFamily: 'Avenir',
                  whiteSpace: 'break-spaces',
                  border: '1px solid #DFE3E4',
                  borderRadius: '4px',
                  borderBottom: availabilityModal ? '1px solid #979797' : '1px solid #DFE3E4',
                  height: hasActiveContribution ? '40px' : '',
                  width: 'unset',
                  margin: !availabilityModal && !hasActiveContribution ? '4px 16px 16px 16px' : '',
                }
          }
          id="select-contribution-self-book"
          clearIcon={false}
          value={availabilityModal ? selectedClient : contributionLabel === 'initial' ? '' : contributionLabel}
          defaultValue={[]}
          open={isDropdownVisible}
          PopperComponent={StyledPopper}
          options={contributionOptions || []}
          noOptionsText={
            <p
              className="dropdownList"
              style={{
                margin: '5px',
                color: 'rgba(0, 0, 0, 0.87)',
                fontSize: '0.875rem',
                fontFamily: 'Avenir',
                letterSpacing: '0.4px',
              }}
            >
              No Service Found
            </p>
          }
          renderTags={(value, getTagProps) => {
            const numOfTags = value.length;
            setTagsNumber(numOfTags);
            const limitTags = 4;
            return availabilityModal ? (
              <>
                {value.slice(0, limitTags).map((option, index) => (
                  <CustomTagDisplay
                    availabilityModal={availabilityModal}
                    key={option?.title}
                    option={option}
                    numOfTags={numOfTags}
                    {...getTagProps({ index })}
                  />
                ))}
                <div>{numOfTags > limitTags && ` +${numOfTags - limitTags}`}</div>
              </>
            ) : null;
          }}
          renderOption={(props, option, { selected }) =>
            availabilityModal ? (
              <div
                key={option?.id}
                {...props}
                className="dropdownList"
                onKeyDown={() => {}}
                role="button"
                tabIndex="0"
                style={{
                  cursor: 'pointer',
                  borderTop: '1px solid #f2f2f2',
                  backgroundColor: selected ? 'rgba(25, 118, 210, 0.08)' : '',
                }}
              >
                <p
                  className="dropdownList"
                  style={{
                    margin: '8px',
                    color: '#2B2B2B',
                    fontSize: '16px',
                    lineHeight: '20px',
                    wordWrap: 'break-word',
                    fontFamily: 'Avenir',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <img
                    style={{
                      width: '121px',
                      height: '48px',
                      marginRight: '8px',
                      borderRadius: '2px',
                      border: '1px',
                    }}
                    src={option.image.length === 0 ? NoImage : option?.image}
                    alt={option.label}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '4px',
                      fontWeight: selected ? '800' : '400',
                    }}
                  >
                    <span>{option.label}</span>
                    <span style={{ fontSize: '12px', opacity: '60%' }}>
                      Created at: {formatTime(option.createTime)}
                    </span>
                  </div>
                </p>
              </div>
            ) : (
              <div
                className="dropdownList"
                onKeyDown={() => {}}
                role="button"
                tabIndex="0"
                onClick={() => {
                  if (!option) {
                    return;
                  }
                  setContribution(option.id, option.label);
                  setIsDropdownVisible(false);
                  const filterContribution = contributionsForTable?.filter(contrib => contrib.id === option.id);
                  if (availabilityModal === false) {
                    setpricingOption({ paymentInfo: filterContribution[0].paymentInfo, id: option.id });
                  }
                }}
                style={{
                  borderTop: '1px solid #f2f2f2',
                  cursor: 'pointer',
                  backgroundColor: selected ? '#fafafa' : 'white',
                }}
              >
                <p
                  className="dropdownList"
                  style={{
                    margin: '8px',
                    color: '#2B2B2B',
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '20px',
                    wordWrap: 'break-word',
                    fontFamily: 'Avenir',
                    display: 'flex',
                    pointerEvents: 'none',
                    alignItems: 'center',
                  }}
                >
                  <img
                    style={{
                      width: '121px',
                      height: '48px',
                      marginRight: '8px',
                      borderRadius: '2px',
                      border: '1px',
                    }}
                    src={option.image.length === 0 ? NoImage : option?.image}
                    alt={option.label}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '4px',
                      fontWeight: selected ? '800' : '400',
                    }}
                  >
                    <span>{getItemLable(option)}</span>
                    <span style={{ fontSize: '12px', opacity: '60%' }}>
                      Created at: {formatTime(option.createTime)}
                    </span>
                  </div>
                </p>
              </div>
            )
          }
          onChange={async (event, option) => {
            if (availabilityModal) {
              setIsAvailabilityPanelExpanded(false);
              setIsExpansionDisabledAvailability(false);
              setOneToOneAvailability(null);
              let values = option;
              if (values.length === 0) {
                if (areAllObjectsPresent(values, selectedClient)) {
                  const updatedArray = removeCommonObjects(selectedClient, values);
                  setSelectedClient(updatedArray);
                  dispatch(contributionActions.setMasterCalendarArray(updatedArray));
                  if (updatedArray.length === 0) {
                    dispatch(contributionActions.setMasterCalendarArray([]));
                    dispatch(contributionActions.fetchContributionActions.success(null));
                    dispatch(contributionActions.fetchContributionTimesActions.success([]));
                    dispatch(contributionActions.fetchCustomContributionTimesActions.success([]));
                    dispatch(setDefaultWeeklyData());
                  }
                } else {
                  setContributionLabel('initial');
                  setSelectedClient([]);
                  dispatch(contributionActions.setMasterCalendarArray([]));
                }
              } else {
                if (areAllObjectsPresent(values, selectedClient)) {
                  setSelectedClient(values);
                  dispatch(contributionActions.setMasterCalendarArray(values));
                } else {
                  setSelectedClient(values);
                  dispatch(contributionActions.setMasterCalendarArray(values));
                }
              }
              setFieldValue('contributions', values);
            }
          }}
          label="Service"
          renderInput={params => (
            <TextField
              {...params}
              class="contributionLabelText"
              required
              variant="standard"
              onClick={e => {
                if (contributionSelectionDisable) return;
                const isArrowIconClicked =
                  !e.target.className.length || !e.target.className.includes('MuiInputBase-input');
                if (isArrowIconClicked) {
                  setIsDropdownVisible(!isDropdownVisible);
                } else {
                  if (!isDropdownVisible) {
                    setIsDropdownVisible(true);
                  }
                }
              }}
              onChange={e => {
                setIsAvailabilityPanelExpanded(false);
                setIsExpansionDisabledAvailability(false);
                setOneToOneAvailability(null);
                setIsDropdownVisible(false);
                setTimeout(() => {
                  setIsDropdownVisible(true);
                }, 300);
              }}
              placeholder={
                tagsNumber == 0 && !availabilityModal
                  ? 'Select 1:1 Service'
                  : tagsNumber == 0
                  ? 'Select 1:1 Service'
                  : availabilityModal && formErrors?.contributions && masterCalendarArray.length === 0
                  ? 'Select 1:1 Service'
                  : ''
              }
              value={contributionLabel}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ cursor: 'pointer', position: 'absolute', padding: '10px 5px', right: '2px' }}
                  >
                    <DropdownIcon color="#00000066" />
                  </InputAdornment>
                ),
              }}
              style={{ margin: contributionSelected ? '4px 3px 4px 8px' : '4px 8px' }}
            />
          )}
        />
      </ClickAwayListener>
      {(isEmpty(contributionLabel) || (availabilityModal && formErrors?.contributions && touched.contributions)) && (
        <div>
          <CommonErrorMessage message={'Service is required'} align="left" />
        </div>
      )}

      {!availabilityModal && !hasActiveContribution && (
        <div>
          <StyledLine color="#e7e7e7" />
          <FooterContainer mt={2}>
            <NextButton onClick={handleNext}>Next</NextButton>
          </FooterContainer>
        </div>
      )}
    </>
  );
};

export default ContributionForm;
