import Modal from 'components/UI/Modal';
import PropTypes from 'prop-types';
import React from 'react';

function ConfirmationModal({
  title,
  onSubmit,
  isOpen,
  onCancel,
  submitTitle = 'OK',
  cancelTitle = 'Cancel',
  children,
  ...rest
}) {
  return (
    <Modal
      className="confirmation-popup"
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={onSubmit}
      title={title}
      submitTitle={submitTitle}
      cancelTitle={cancelTitle}
      {...rest}
    >
      {children}
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.func.isRequired,
  submitTitle: PropTypes.string.isRequired,
  cancelTitle: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default ConfirmationModal;
