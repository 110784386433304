import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from './Attachments';
import './Attachment.scss';

const OneAttachment = ({ item, index = 0, removeAttachment, handleRemoveAttachment, handleImageClick }) => {
  const { width = 400, height = null, s3Link = '' } = item;
  const [dimensions, setDimensions] = useState({ width, height });

  return (
    <Wrapper
      index={index}
      removeAttachment={removeAttachment}
      handleRemoveAttachment={handleRemoveAttachment}
      isLandscape={dimensions.width > dimensions.height}
      isCreatePost
    >
      <img
        src={s3Link}
        alt=""
        onLoad={e => {
          const { width, height } = e.target;
          setDimensions({ width, height });
        }}
        onClick={() => handleImageClick(item)}
        style={{ maxHeight: '750px', width: '100%', height: dimensions?.height || '100%', objectFit: 'cover' }}
      />
    </Wrapper>
  );
};

export default OneAttachment;
