import React from 'react';
import styled from 'styled-components';

import logo from 'assets/logo.png';
import coherelogo from 'assets/coherelogo.png';
import coherelogoai from 'assets/coherelogoai.png';
const CohereLogo = styled.img`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5rem;
  width: 10rem;
  height: 90px;

  ${({ chatlogo }) =>
    chatlogo &&
    `
  width: 83px;
  height: 48px;
  margin-bottom: 10px;

  `}

  ${({ startlogo, islarge, isMasterCalendar }) =>
    startlogo &&
    `
    width: ${islarge ? '65px' : isMasterCalendar ? '55px' : '40px'};
    height: ${islarge ? '65px' : isMasterCalendar ? '55px' : '40px'};
    margin-bottom: 0px;
  `}
`;

const Logo = ({ chatlogo, startlogo = false, islarge = false, isai = false, isMasterCalendar = false }) => {
  return (
    <CohereLogo
      chatlogo={chatlogo}
      startlogo={startlogo}
      islarge={islarge}
      isMasterCalendar={isMasterCalendar}
      src={isai ? coherelogoai : startlogo ? coherelogo : logo}
    />
  );
};

export default Logo;
