import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { determineDarkThemedColorToUse } from 'services/contributions.service';

const StyledContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  ${({ leadCard }) =>
    leadCard &&
    css`
      border-radius: 0.25rem 0.25rem 0rem 0rem;
      border: 1px solid #e7e7e7;
    `}

  ${({ isDarkThemeEnabled, leadCard }) =>
    isDarkThemeEnabled === false &&
    (leadCard
      ? css``
      : css`
          box-shadow: 0 2px 4px 0 rgba(157, 157, 157, 0.3);
        `)}
  ${({ maxHeight }) =>
    maxHeight &&
    css`
      height: 100%;
    `}
`;

const StyledHeader = styled.div`
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  align-items: center;
  padding: 0 24px;
  justify-content: space-between;
  ${({ CardBgCol }) => CardBgCol && `background-color: #e9f0f7`};
  ${({ mobileView }) => mobileView && `padding: 16px;`}
`;

const StyledBody = styled.div`
  padding: ${({ padding }) => padding || '24px 24px'};

  ${({ mobileView }) => mobileView && `padding: 1.25rem 0.5rem;`}
  ${({ styleOverrides }) => styleOverrides}
`;

function Card({ children, contribution, ...rest }) {
  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);
  return (
    <StyledContainer isDarkThemeEnabled={isDarkThemeEnabled} {...rest}>
      {children}
    </StyledContainer>
  );
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
  maxHeight: PropTypes.bool,
};

Card.defaultProps = {
  maxHeight: false,
};

export { Card, StyledHeader as CardHeader, StyledBody as CardBody };
