import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import { useHttp, useAccount } from 'hooks';

import useContribution from 'pages/ContributionView/hooks/useContribution';
import { determineDarkThemedColorToUse, determineColorToUse } from 'services/contributions.service';
import TextField from 'components/UI/TextField';
import UploadAttachment from './UploadAttachment';
import './MessageInput.scss';
// import InputEmoji from 'react-input-emoji';
import Picker, { SKIN_TONE_MEDIUM_DARK } from 'emoji-picker-react';
import HappyEmoji from '../Icons/HappyEmoji';
import { ClickAwayListener, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import styled from 'styled-components';

const StyledPicker = styled.div`
  position: absolute;
  bottom: ${({ mobileView }) => (mobileView ? '40px' : '30px')};
  ${({ mobileView }) => !mobileView && `left: -100px;`};
  ${({ mobileView }) => mobileView && `right: -90px;`};
  z-index: 100;
`;

const StyledTextArea = styled(TextField)`
  & .Mui-focused {
  .MuiOutlinedInput-notchedOutline {
    border-color: ${({ borderColor }) => borderColor};
  }
`;
const MessageInput = ({
  onSendTextMessage,
  onSendMediaMessage,
  onTyping,
  contribution,
  members,
  channel,
  applyTheming,
}) => {
  const [messageText, setMessageText] = useState('');
  const [emojiPopup, setEmojiPopup] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [isUploadingAttachment, setIsUploadingAttachment] = useState(false);
  const { request } = useHttp();
  const { user } = useAccount();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const onEmojiClick = emojiObject => {
    setMessageText(prev => prev + emojiObject.emoji);
  };

  const sendMsgNotification = (msgId, msg) => {
    let tempThread = channel;
    let mems = [],
      isGroup = tempThread?.attributes?.ContributionId ? true : false;
    for (let m of members) {
      if (m.identity != user.email) {
        mems.push(`${m.identity}`);
      }
    }
    let params = {
      isGroupChat: isGroup,
      memberEmails: mems,
      channelSid: tempThread.sid,
      messageId: msgId,
      message: msg,
    };
    if (isGroup) {
      params['contributionId'] = tempThread?.attributes?.ContributionId;
    }

    request('api/Notification/SendChatPushNotification', 'POST', params)
      .then(token => {})
      .catch(() => {});
  };

  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);
  const colorToUse = determineColorToUse(contribution);

  const sendTextMessageAsync = async () => {
    if (!messageText || isSendingMessage) {
      return;
    }

    setIsSendingMessage(true);
    const messageSent = await onSendTextMessage(messageText);
    if (messageSent) {
      if (messageText.length > 150) {
        let newString = messageText.match(/.{1,150}/g);
        sendMsgNotification('', `${newString[0]}...`);
      } else {
        sendMsgNotification('', messageText);
      }
      setMessageText('');
    }
    setIsSendingMessage(false);
  };

  const sendMediaMessageAsync = async file => {
    if (isUploadingAttachment) {
      return;
    }

    setIsUploadingAttachment(true);
    await onSendMediaMessage(file);
    sendMsgNotification('', 'Media');
    setIsUploadingAttachment(false);
  };
  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      sendTextMessageAsync();
    }
  };
  return (
    <div className={isDarkThemeEnabled ? 'chat-message-input-dark-themed' : 'chat-message-input'}>
      {/* <InputEmoji
        placeholder="Type a message"
        multiline
        fullWidth
        applyTheming={isDarkThemeEnabled}
        rowsMax={6}
        onEnter={sendTextMessageAsync}
        value={messageText}
        onChange={e => {
          // setMessageText(e);
          // e.stopPropagation();
          // e.preventDefault();
          console.log("e -->", e)
        }}
        keepOpened={true}
      // onClick={(e) => e.stopPropagation()}
      /> */}
      <Grid
        container
        spacing={1}
        alignItems="center"
        className={`chat-message-input__container ${isDarkThemeEnabled ? 'is-dark' : ''}`}
      >
        <Grid item xs={11} lg={11}>
          <StyledTextArea
            placeholder="Type a message"
            variant="outlined"
            value={messageText}
            borderColor={colorToUse?.AccentColorCode}
            fullWidth
            onChange={e => setMessageText(e.target.value)}
            onKeyPress={handleKeyPress}
            style={{ color: isDarkThemeEnabled ? 'white' : 'black' }}
          />
        </Grid>
        <Grid item xs={1} lg={1} style={{ position: 'relative' }}>
          <HappyEmoji onClick={() => setEmojiPopup(!emojiPopup)} width="26px" height="26px" fill="#858585" />
          {emojiPopup && (
            <ClickAwayListener
              onClickAway={() => {
                setEmojiPopup(false);
              }}
            >
              <StyledPicker mobileView={mobileView}>
                <Picker onEmojiClick={onEmojiClick} disableAutoFocus={true} />
              </StyledPicker>
            </ClickAwayListener>
          )}
        </Grid>
      </Grid>
      <UploadAttachment
        className="chat-message-input__upload-attachment"
        isUploadingAttachment={isUploadingAttachment}
        onUploadAttachment={sendMediaMessageAsync}
      />
      <IconButton
        className="chat-message-input__send"
        color="inherit"
        onClick={sendTextMessageAsync}
        disabled={!messageText || isSendingMessage}
      >
        <SendIcon />
      </IconButton>
    </div>
  );
};

MessageInput.propTypes = {
  onSendTextMessage: PropTypes.func.isRequired,
  onSendMediaMessage: PropTypes.func.isRequired,
  onTyping: PropTypes.func.isRequired,
  applyTheming: PropTypes.bool,
};
MessageInput.defaultProps = {
  applyTheming: false,
};

export default MessageInput;
