import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { connect, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import * as R from 'ramda';
import _ from 'lodash';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import Button from 'components/FormUI/Button';
import { useMediaQuery, useTheme } from '@material-ui/core';
import * as calendarsActions from 'actions/calendars';
import CoachImage from 'assets/chatlogo.png';
import TemplateOne from 'assets/congratulations.png';
import Modal from 'components/UI/Modal';
import CrossIcon from '@material-ui/icons/Clear';
import InfoIcon from '@material-ui/icons/Info';
import { toRem, colors } from 'utils/styles';
import Joyride, {
  CallBackProps,
  STATUS,
  Step,
  EVENTS,
  StoreHelpers,
  BeaconRenderProps,
  TooltipRenderProps,
} from 'react-joyride';
import { StyledMainSection } from 'components/Containers/CreateContributionContainer';
import MainContainer from 'components/Containers/MainContainer';
import { useLoadMemberPodsByContribution } from 'components/MemberPods';
import * as contributionActions from 'actions/contributions';
import { useSaveContribution } from 'hooks/useSaveContribution';
import { getTimePeriodsForSessionsCollection, UTCtoLocalDateTime } from 'utils/datesAndMoney';
import { useRouter } from 'hooks';
import useAccount from 'hooks/useAccount';
import { ContributionType } from 'helpers/constants';
import { getCurrentSessionTime } from 'utils/getSessionTime';
import CommunityForm from 'pages/CreateContribution/components/CommunityForm';
import Buttons from './components/Buttons';
import LiveForm from './components/LiveForm';
import MembershipForm from './components/MembershipForm/index';
import { OneToOneForm } from './components/OneToOneForm';
import { generateOneToOneSlots, prepareSessions } from './utils';
import { useOtherEvents } from './hooks/useOtherEvents';
import { checkSessionDuration } from './hooks/useCheckSessionDuration';
import {
  communitySessionsValidationSchema,
  enrollmentFormSchema,
  liveSessionsValidationSchema,
  membershipSessionsValidationSchema,
  oneToOneSessionsValidationSchema,
  liveSessionsValidationSchemaEditing,
  membershipSessionsValidationSchemaEditing,
} from './contributionValidations';
import { LiveVideoProvider } from '../../constants';
import { date } from 'yup';
import { orderBy } from 'lodash';
import { UpdateUserProgressBarData } from 'services/user.service';
import { updateUser } from 'actions/update-user';
import { getLatestApproved, getUpcomingCreated } from 'services/contributions.service';
import CustomQuestions from './components/CustomQuestions';
const serializeTime = R.compose(R.head, R.split('+'));

const CustomInvitationBodyDefault = `When it's time for your session, please launch Cohere by going to the following URL to login: App.Cohere.Live.
If you need to contact the other session participant(s), this can also be done by going to App.Cohere.Live and then visiting \"Conversations\".
If you need to reschedule, this can done by going to App.Cohere.Live and then visiting the Sessions tab. 
If you are a Client, please go to \"My Journey\", click on the session, and then visit the \"Sessions\" tab to find the reschedule button. 
If you are a service provider, please open your Service and go to the \"Sessions\"
PLEASE NOTE, THIS IS A NO-REPLY EMAIL ACCOUNT.`;
const CustomInvitationBodyDefaultOneOnOne = `{CoachName} and {ClientName} have scheduled a video call for {ServiceName}. To join the call, simply click on the link provided in the invitation. If you are not logged in to Cohere, you will be prompted to do so before joining the call.

If you need to reschedule your session, you can do so here: {ServiceLink}. You may need to log into Cohere at app.cohere.live before accessing the link.
`;

const customInvitaionBodyText = {
  cohereVideo: `{CoachName} and {ClientName} have scheduled a video call for {ServiceName}. To join the call, simply click on the link provided in the invitation. If you are not logged in to Cohere, you will be prompted to do so before joining the call.

If you need to reschedule your session, you can do so here: {ServiceLink}. You may need to log into Cohere at app.cohere.live before accessing the link.
`,
  zoomVideo: `{CoachName} and {ClientName} have scheduled a video call for {ServiceName}. To join the call, simply click on the link provided in the invitation. 

If you need to reschedule your session, you can do so here:  {ServiceLink}. You may need to log into Cohere at app.cohere.live before accessing the link.
`,
  privateLink: `{CoachName} and {ClientName} have scheduled a video call for {ServiceName}. To join the call, simply click on the link provided in the invitation. 

If you need to reschedule your session, you can do so here:  {ServiceLink}. You may need to log into Cohere at app.cohere.live before accessing the link.
`,
  phoneCall: `{CoachName} and {ClientName} have a scheduled call for {ServiceName}. Participants will call {phoneNo}.  

If you need to reschedule your session, you can do so here:  {ServiceLink}. You may need to log into Cohere at app.cohere.live before accessing the link.
`,
  inPerson: `{CoachName} and {ClientName} have a scheduled meeting for {ServiceName}. Participants will meet at the location specified in this invite.

If you need to reschedule your meeting, you can do so here:  {ServiceLink}. You may need to log into Cohere at app.cohere.live before accessing the link.
`,
};

const StyledImg = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 40px;
  align-items: center;
  z-index: 1;
`;
const StyledCloseIcon = styled(CrossIcon)`
  margin: 5px;
  height: 15px;
  width: 15px;
  &:hover {
    cursor: pointer;
  }
`;
const StyledImgCongrats = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
  display: inline-block;
  // border-radius: 40px;
  align-items: center;
  z-index: 1;
`;

function Sessions({ contribution, saveContribution, saveContributionToLS, oneToOneData, calendars, fetchCalendars }) {
  const { user } = useAccount();
  const dispatch = useDispatch();
  const { contentId, sessionId } = useParams();
  const errorState = useSelector(state => state.contributions.error);
  const errorOccured = useSelector(state => state?.contributions.showError);
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const updating = useSelector(state => state.contributions?.updating);
  const [externalCalendarSetToDefault, setExternalCalendarSetToDefault] = useState(false);
  const serviceAgreementType = user?.serviceAgreementType;
  const isBetaUser = user?.isBetaUser;
  const showOnlyFreeOption = serviceAgreementType && isBetaUser ? true : false;
  const defaultCalendar = useMemo(() => calendars && calendars?.filter(c => c.isDefault == true), [calendars]);
  const defaultCalendarEmail = useMemo(() => defaultCalendar[0]?.emailAddress, [defaultCalendar]);
  const defaultGroupCalendar = useMemo(() => calendars && calendars?.find(c => c.isDefaultForGroupCourseInvites), [
    calendars,
  ]);
  const defaultGroupCalendarEmail = useMemo(() => defaultGroupCalendar?.emailAddress, [defaultGroupCalendar]);
  const [mysessions, setSessions] = useState([]);
  const [errorPopUpState, seterrorPopUpState] = useState(false);
  const [run, setRun] = useState(!user?.userProgressbarData?.FirstContributionDone);
  const [stepIndex, setStepIndex] = useState(4);
  const [selectedEmailCalendar, setSelectedEmailCalendar] = useState();
  const [offset, setOffset] = useState(0);
  const windowHeight = window.innerHeight;
  const scrollPosition = window.scrollY;
  const [addCustomEnrollmentQuestions, setAddCustomEnrollmentQuestions] = useState(
    contribution?.isCustomEnrollmentFormAdded,
  );
  const customEnrollmentFormRef = useRef(null);
  const customQuestionsFormRef = useRef(null);
  const firstRender = useRef(true);
  const [smsSessionReminderSettings, setSmsSessionReminderSettings] = useState([
    { smsPermissionsPerContribution: activeContribution?.smsPermissionsPerContribution },
    { subCategorySmsPermissionsPerContribution: activeContribution?.subCategorySmsPermissionsPerContribution },
    { customText: activeContribution?.customText },
  ]);
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollPosition = window.scrollY;
  //     setOffset(scrollPosition);
  //   };
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [offset]);

  useEffect(() => {
    if (activeContribution) {
      setSmsSessionReminderSettings([
        { smsPermissionsPerContribution: activeContribution?.smsPermissionsPerContribution },
        { subCategorySmsPermissionsPerContribution: activeContribution?.subCategorySmsPermissionsPerContribution },
        { customText: activeContribution?.customText },
      ]);
    }
  }, [activeContribution]);

  let joyRideHelpers;
  useEffect(() => {
    dispatch(contributionActions.fetchCustomContributionTimesActions.success({}));
    if (isEditing === true) {
      setRun(false);
    }
  }, []);

  useEffect(() => {
    // const toScroll = contentId || sessionId || location?.state?.sessionId || window?.history?.state?.sessionId;
    if (sessionId || window?.history?.state?.sessionId) {
      const idToScroll = sessionId || window?.history?.state?.sessionId;
      setTimeout(() => {
        const element = document.getElementById(idToScroll);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 1000);
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [sessionId]);

  const [showModal, setShowModal] = useState(false);

  const togglePopup = () => {
    setShowModal(!showModal);
  };
  const [contibId, setContibId] = useState(null);
  useEffect(() => {
    getLatestApproved().then(res => {
      setContibId(res);
    });
  }, []);
  const [steps, setSteps] = useState([
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                // setRun(false);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                // setRun(false);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                // setRun(false);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            {/* <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                setRun(false);
              }}
            >
              Next
            </Button> */}
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      floaterProps: { disableFlip: true },
      content: (
        <div className="step4">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Pre-Schedule Group Sessions and/or Add Self-Paced Modules
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            This is where you create your group course content! Your clients will get auto-booked for any upcoming LIVE
            group sessions that get scheduled here. They will be directed to a tab to see upcoming sessions and
            self-paced audio, video content, and PDFs.
          </div>
          <div
            className="buttons"
            style={{ width: '100%', flexDirection: 'column', display: 'flex', alignItems: 'flex-start' }}
          >
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '100%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            {/* <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '100%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                // marginLeft: '10px',
              }}
              onClick={() => {
                updateUserF(true);

                // joyRideHelpers.skip();
                // setRun(false);
              }}
            >
              Complete & Next
            </Button> */}
          </div>
        </div>
      ),
      placement: 'left',
      target: '#contributionSessions',
    },
    // {
    //   hideFooter: true,
    //   disableBeacon: true,
    //   content: (
    //     <div>
    //       <StyledImgCongrats previewImg={TemplateOne}></StyledImgCongrats>
    //       <div style={{ fontSize: '24px', color: '#215C73', fontWeight: 800 }}>CONGRATS!</div>
    //       <div style={{ color: '#A58B62', fontSize: '16px', marginTop: '13px', fontWeight: 450, fontFamily: 'Avenir' }}>
    //         You are fully set up & ready to transform lives!
    //       </div>
    //       <Button
    //         autoWidth
    //         backgroundColor={'#C9B382'}
    //         style={{
    //           marginTop: '40px',
    //           paddingTop: '10px',
    //           paddingBottom: '10px',
    //         }}
    //         onClick={() => {
    //           joyRideHelpers.skip();
    //         }}
    //       >
    //         Let’s do this!
    //       </Button>
    //     </div>
    //   ),
    //   placement: 'center',
    //   target: 'body',
    // },
  ]);

  const updateUserF = async (goToNextStep = false) => {
    let obj = {
      id: user.id,
      userProgressbarData: {
        ...user.userProgressbarData,
        FirstContributionDone: true,
      },
      progressBarPercentage: user.progressBarPercentage,
    };

    await UpdateUserProgressBarData(obj).then(res => {
      let u = {
        ...user,
        userProgressbarData: { ...res.userProgressbarData },
        progressBarPercentage: res.progressBarPercentage,
      };
      dispatch(updateUser(u));
      if (res.progressBarPercentage == 100) {
        setShowModal(true);
      }
      if (goToNextStep) {
        if (res.userProgressbarData?.IsPlanPurchased === false) {
          history.push('/account/payment', { from: 'verify' });
        } else if (res?.userProgressbarData?.IsBankAccountConnected === false) {
          history.push('/account/payment', { from: 'bankAccount' });
        } else if (res?.userProgressbarData?.IntegrationsDone === false) {
          history.push('/account/integrations', { from: 'bankAccount' });
        } else if (res?.userProgressbarData?.IsProfileUploaded === false) {
          history.push('/account/profile');
        } else if (res?.userProgressbarData?.FirstContributionDone === false) {
          dispatch(contributionActions.setPopUp(true));
          dispatch(contributionActions.setShowCompletedGetStart('create'));
          history.push('/dashboard');
        } else if (res?.userProgressbarData?.IsWebsiteAdded === false) {
          history.push('/account/profile', { from: 'Manage Your Website Information' });
        } else if (res?.userProgressbarData?.InviteLink === false) {
          contibId === null ? console.log('Helloo') : history.push(`contribution-view/${contibId}/about`);
        }
      }
    });
    joyRideHelpers.skip();
  };

  useEffect(() => {
    if (!!user?.userProgressbarData?.FirstContributionDone) {
      setSteps([]);
    }
  }, []);

  const getHelpers = helpers => {
    joyRideHelpers = helpers;
  };

  const handleJoyrideCallback = data => {
    const { status, type, index } = data;
    const nextStepIndex = index + 1;

    const finishedStatuses = ['finished', 'skipped'];
    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
    if ([EVENTS.STEP_AFTER].includes(type)) {
      setStepIndex(nextStepIndex);
    }
    if (data.action === 'close') {
      setRun(false);
    }

    console.groupCollapsed(type);
    console.groupEnd();
  };

  const setMysessions = dataArray => {
    setSessions(dataArray);
  };

  useEffect(() => {
    fetchCalendars();
  }, [fetchCalendars]);

  useLoadMemberPodsByContribution({ contributionId: contribution?.id, userId: user.id });
  const { history, query } = useRouter();
  const contributionFormRef = useRef();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const isEditing = Boolean(query.id);
  const { onSave: onSaveContribution } = useSaveContribution(isEditing);
  const [redirectTo, setRedirectTo] = useState('');
  const { otherEvents } = useOtherEvents(contribution?.type === ContributionType.contributionOneToOne);
  const isequal = (arr1, arr2) => {
    let result =
      arr1.length === arr2.length &&
      arr1.every(element => {
        return arr2.includes(element);
      });
    return result;
  };

  const findGreatestSessionTime = s => {
    return s.sessionTimes.reduce((g, c) => {
      if (moment(g.startTime).isBefore(c.startTime)) {
        return g;
      } else {
        return c;
      }
    });
  };

  const initializeExternalCalendarValue = () => {
    if (contribution?.type != 'ContributionOneToOne') {
      if (isEditing && externalCalendarSetToDefault === false) {
        setSelectedEmailCalendar(contribution?.externalCalendarEmail || null);
        return contribution?.externalCalendarEmail || null;
      } else if (contribution?.isExternalCalendarDisconnected === false) {
        setSelectedEmailCalendar(contribution?.externalCalendarEmail || defaultGroupCalendarEmail);
        return contribution?.externalCalendarEmail || defaultGroupCalendarEmail;
      } else {
        return null;
      }
    } else {
      if (isEditing && externalCalendarSetToDefault === false) {
        setSelectedEmailCalendar(contribution?.externalCalendarEmail || null);
        return contribution?.externalCalendarEmail || null;
      } else if (contribution?.isExternalCalendarDisconnected === false) {
        setSelectedEmailCalendar(contribution?.externalCalendarEmail || defaultCalendarEmail);
        return contribution?.externalCalendarEmail || defaultCalendarEmail;
      } else {
        return null;
      }
    }
  };
  useEffect(() => {
    if (updating == false) {
      if (
        errorOccured === true &&
        isEditing === true &&
        (errorState?.message != null || errorState?.Error?.Message != null) &&
        (errorState?.Error?.ExceptionName === 'ZoomException' ||
          errorState?.Error?.ExceptionName == 'ArgumentNullException' ||
          errorState?.message === `Your availability time frame windows are overlapping. Please fix this on step 3` ||
          errorState?.message === 'Your Refresh Token has been expired. Please reconnect your zoom account.')
      ) {
        seterrorPopUpState(true);
        dispatch(contributionActions.setErrorPopUp(true));
      } else if (redirectTo != '') {
        dispatch(contributionActions.setErrorPopUp(false));
        history.push(redirectTo);
      }
    }
  }, [errorState, activeContribution, errorOccured, updating]);

  useEffect(() => {
    if (addCustomEnrollmentQuestions && !firstRender.current) {
      setTimeout(() => {
        if (customEnrollmentFormRef.current) customEnrollmentFormRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 200);
    }
    firstRender.current = false;
  }, [addCustomEnrollmentQuestions]);

  const initialValuesLive = useMemo(() => {
    const currentDate = moment();
    return {
      sessions: contribution?.sessions || [
        {
          maxParticipantsNumber: 50,
          minParticipantsNumber: 2,
          sessionTimes: [getCurrentSessionTime(false, currentDate.clone().add(1, 'd'))],
          title: 'Session 1',
          name: 'Session',
          isAutoCompleteOn: true,
        },
      ],
      isZoomDefaultServiceProvider: contribution?.isZoomDefaultServiceProvider || false,
      enrollment: {
        anyTime: contribution?.enrollment?.anyTime || false,
        fromDate: contribution?.enrollment?.fromDate || UTCtoLocalDateTime(currentDate.clone().add(0, 'd')),
        toDate: contribution?.enrollment?.toDate || UTCtoLocalDateTime(currentDate.clone().add(1, 'd')),
      },
      liveVideoServiceProvider:
        contribution?.isZoomDefaultServiceProvider &&
        user?.isZoomEnabled &&
        contribution.isDefaultServiceProviderSet === false
          ? {
              providerName: 'Zoom',
            }
          : contribution?.liveVideoServiceProvider || {
              providerName: 'Cohere',
            },
      zoomWaitingRoomDisabled: contribution?.zoomWaitingRoomDisabled || false,
      customInvitationBody: contribution?.customInvitationBody || CustomInvitationBodyDefault,
      externalCalendarEmail: initializeExternalCalendarValue(),
      isDownloadEnabled: contribution?.isDownloadEnabled ?? false,
      markSessionsAutoComplete: contribution?.markSessionsAutoComplete || 'true',
      displaySessionTimeDates: contribution?.displaySessionTimeDates ?? true,
      enrollmentWithSpecificDateEnabled: contribution?.enrollmentWithSpecificDateEnabled,
      enableMaxParticipants: contribution?.enableMaxParticipants,
      participantsLimit: contribution?.participantsLimit,
      participantsEnrolled: contribution?.participantsEnrolled,
    };
  }, [contribution, calendars]);

  const initialValuesOneToOne = useMemo(
    () => ({
      liveVideoServiceProvider:
        contribution?.isZoomDefaultServiceProvider &&
        user?.isZoomEnabled &&
        contribution.isDefaultServiceProviderSet === false
          ? {
              providerName: 'Zoom',
            }
          : contribution?.liveVideoServiceProvider || {
              providerName: 'Cohere',
            },
      isZoomDefaultServiceProvider: contribution?.isZoomDefaultServiceProvider || false,
      zoomWaitingRoomDisabled: contribution?.zoomWaitingRoomDisabled || false,
      isPhoneNumberAdded: contribution?.isPhoneNumberAdded || false,
      isCoachNumberAdded: contribution?.isCoachNumberAdded || false,
      coachPhoneNumber: contribution?.coachPhoneNumber || '',
      customInvitationBody: contribution?.customInvitationBody || CustomInvitationBodyDefaultOneOnOne,
      markSessionsAutoComplete: contribution?.markSessionsAutoComplete || 'true',
      sessions: contribution?.availabilityTimes
        ? prepareSessions(contribution)
        : [
            {
              durations: [60],
              maxParticipantsNumber: 1,
              minParticipantsNumber: 1,
              sessionTimes: [getCurrentSessionTime(true)],
            },
          ],
      externalCalendarEmail: initializeExternalCalendarValue(),
      isExternalCalendarDisconnected: contribution?.isExternalCalendarDisconnected,
      bufferTimeBefore: contribution?.bufferTimeBefore || 0,
      bufferTimeAfter: contribution?.bufferTimeAfter || 0,
      minimumNotice: {
        type:
          !isEditing && contribution?.status === 'Draft'
            ? 'Hours'
            : contribution?.oneToOneSessionDataUi?.minimumNotice?.type === undefined
            ? 'Hours'
            : contribution?.oneToOneSessionDataUi?.minimumNotice?.type,
        value:
          !isEditing && contribution?.status === 'Draft'
            ? '4'
            : contribution?.oneToOneSessionDataUi?.minimumNotice?.value === undefined
            ? '4'
            : contribution?.oneToOneSessionDataUi?.minimumNotice?.value,
      },
      enrollmentForm: {
        isCustomEnrollmentFormAdded: contribution?.isCustomEnrollmentFormAdded ?? false,
      },
      selectedContributionIdForCopySettings: contribution?.selectedContributionIdForCopySettings || [],
      copySchedulingSettings: contribution?.CopySchedulingSettings || false,
      isAccountNeeded: contribution?.isAccountNeeded ?? true,
    }),
    [contribution, calendars],
  );
  const initialValuesMembership = useMemo(() => {
    const currentDate = moment();
    return {
      sessions: contribution?.sessions || [
        {
          maxParticipantsNumber: 50,
          minParticipantsNumber: 2,
          sessionTimes: [getCurrentSessionTime(false, currentDate.clone().add(1, 'd'))],
          title: 'Session 1',
          name: 'Session',
          isAutoCompleteOn: true,
        },
      ],
      isZoomDefaultServiceProvider: contribution?.isZoomDefaultServiceProvider || false,
      enrollment: {
        anyTime: contribution?.enrollment?.anyTime || true,
        fromDate: contribution?.enrollment?.fromDate || UTCtoLocalDateTime(currentDate.clone().add(0, 'd')),
        toDate: contribution?.enrollment?.toDate || UTCtoLocalDateTime(currentDate.clone().add(1, 'd')),
      },
      liveVideoServiceProvider:
        contribution?.isZoomDefaultServiceProvider &&
        user?.isZoomEnabled &&
        contribution.isDefaultServiceProviderSet === false
          ? {
              providerName: 'Zoom',
            }
          : contribution?.liveVideoServiceProvider || {
              providerName: 'Cohere',
            },
      zoomWaitingRoomDisabled: contribution?.zoomWaitingRoomDisabled || false,
      customInvitationBody: contribution?.customInvitationBody || CustomInvitationBodyDefault,
      externalCalendarEmail: initializeExternalCalendarValue(),
      isDownloadEnabled: contribution?.isDownloadEnabled ?? false,
      markSessionsAutoComplete: contribution?.markSessionsAutoComplete || 'true',
      enrollmentWithSpecificDateEnabled: contribution?.enrollmentWithSpecificDateEnabled,
      enableMaxParticipants: contribution?.enableMaxParticipants,
      participantsLimit: contribution?.participantsLimit,
      participantsEnrolled: contribution?.participantsEnrolled,
    };
  }, [contribution, calendars]);

  const initialValuesCommunity = useMemo(() => {
    const currentDate = moment();
    return {
      sessions: contribution?.sessions || [],
      liveVideoServiceProvider:
        contribution?.isZoomDefaultServiceProvider &&
        user?.isZoomEnabled &&
        contribution.isDefaultServiceProviderSet === false
          ? {
              providerName: 'Zoom',
            }
          : contribution?.liveVideoServiceProvider || {
              providerName: 'Cohere',
            },
      isZoomDefaultServiceProvider: contribution?.isZoomDefaultServiceProvider || false,
      enrollment: {
        anyTime: contribution?.enrollment?.anyTime || false,
        fromDate: contribution?.enrollment?.fromDate || UTCtoLocalDateTime(currentDate.clone().add(0, 'd')),
        toDate: contribution?.enrollment?.toDate || UTCtoLocalDateTime(currentDate.clone().add(1, 'd')),
      },
      zoomWaitingRoomDisabled: contribution?.zoomWaitingRoomDisabled || false,
      customInvitationBody: contribution?.customInvitationBody || CustomInvitationBodyDefault,
      externalCalendarEmail: initializeExternalCalendarValue(),
      isDownloadEnabled: contribution?.isDownloadEnabled ?? false,
      markSessionsAutoComplete: contribution?.markSessionsAutoComplete || 'true',
      enrollmentWithSpecificDateEnabled: contribution?.enrollmentWithSpecificDateEnabled,
      enableMaxParticipants: contribution?.enableMaxParticipants,
      participantsLimit: contribution?.participantsLimit,
      participantsEnrolled: contribution?.participantsEnrolled,
    };
  }, [contribution, calendars]);

  const saveHandler = isEditing ? saveContribution : saveContributionToLS;

  const submitLiveHandler = useCallback(
    (values, formikHelpers, to = '') => {
      const data = {
        ...values,
      };
      data.sessions = orderBy(data.sessions, session => findGreatestSessionTime(session).startTime, ['asc']);

      data.sessions.forEach(session => {
        session.sessionTimes.forEach(time => {
          time.startTime = serializeTime(time.startTime);
          time.endTime = serializeTime(time.endTime);
        });
      });
      const sortedArray = data.sessions.sort(function (x, y) {
        if (!x.isPrerecorded && !y.isPrerecorded) return y.sessionTimes[0].startTime - x.sessionTimes[0].startTime;
      });

      // if any sessions deleted, we have to sort the titles again
      let count = 1;
      for (let i = 0; i < data.sessions.length; i++) {
        if (!data.sessions[i].isPrerecorded) {
          data.sessions[i].title = `Session ${count}`;
          count++;
        }
      }

      data.enrollment.fromDate = UTCtoLocalDateTime(
        moment(data.enrollment.fromDate).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        }),
      );
      data.enrollment.toDate = UTCtoLocalDateTime(
        moment(data.enrollment.toDate).set({
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 999,
        }),
      );
      let newContribution;
      if (mysessions.length > 0) {
        const newArray = mysessions.map(item1 => {
          let item2;

          if (item1.isPrerecorded) {
            item2 = data.sessions.find(
              item =>
                item.isPrerecorded &&
                item.sessionTimes &&
                item.sessionTimes.some(st2 =>
                  item1.sessionTimes.some(st1 => st1?.prerecordedSession?.id === st2.prerecordedSession?.id),
                ),
            );
          } else if (item1.sessionTimes && item1.sessionTimes.length > 0) {
            item2 = data.sessions.find(
              item =>
                !item.isPrerecorded &&
                item.sessionTimes &&
                item.sessionTimes.some(st2 => item1.sessionTimes.some(st1 => st1?.startTime === st2?.startTime)),
            );
          } else {
            item2 = data.sessions.find(
              item =>
                (item.id !== undefined && item?.id === item1?.id) ||
                (item?.name === item1?.name && item?.title === item1?.title),
            );
          }

          if (item2) {
            return {
              ...item1,
              name: item2?.name !== item1?.name && item2?.id === item1?.id ? item2?.name : item1?.name,
            };
          }
          return item1;
        });
        newContribution = {
          ...contribution,
          ...data,
          sessions: newArray,
          externalCalendarEmail: data.externalCalendarEmail,
        };
      } else {
        const prerecordedSessions = data?.sessions.every(session => session.isPrerecorded === true);
        if (prerecordedSessions) {
          newContribution = {
            ...contribution,
            ...data,
            sessions: data?.sessions,
            externalCalendarEmail: data.externalCalendarEmail,
            isDefaultServiceProviderSet: true,
          };
        } else {
          newContribution = {
            ...contribution,
            ...data,
            sessions: sortedArray,
            externalCalendarEmail: data.externalCalendarEmail,
            isDefaultServiceProviderSet: true,
          };
        }
      }

      onSaveContribution(newContribution);
      if (isEditing === false) {
        saveHandler(newContribution);
        history.push(to || redirectTo);
      }
      // history.push(to || redirectTo);
    },
    [contribution, history, saveHandler, redirectTo, onSaveContribution, errorState, errorOccured, updating],
  );

  const submitMembershipHandler = useCallback(
    (values, formikHelpers, to = '') => {
      const data = {
        ...values,
      };
      data.sessions = orderBy(data.sessions, session => findGreatestSessionTime(session).startTime, ['asc']);
      data.sessions.forEach(session => {
        session.sessionTimes.forEach(time => {
          time.startTime = serializeTime(time.startTime);
          time.endTime = serializeTime(time.endTime);
        });
      });

      // if any sessions deleted, we have to sort the titles again
      let count = 1;
      for (let i = 0; i < data.sessions.length; i++) {
        if (!data.sessions[i].isPrerecorded) {
          data.sessions[i].title = `Session ${count}`;
          count++;
        }
      }

      data.enrollment.fromDate = UTCtoLocalDateTime(
        moment(data.enrollment.fromDate).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        }),
      );

      data.enrollment.toDate = UTCtoLocalDateTime(
        moment(data.enrollment.toDate).set({
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 999,
        }),
      );

      let newContribution;
      if (mysessions.length > 0) {
        const newArray = mysessions.map(item1 => {
          let item2;

          if (item1.isPrerecorded) {
            item2 = data.sessions.find(
              item =>
                item.isPrerecorded &&
                item.sessionTimes &&
                item.sessionTimes.some(st2 =>
                  item1.sessionTimes.some(st1 => st1.prerecordedSession.id === st2.prerecordedSession.id),
                ),
            );
          } else if (item1.sessionTimes && item1.sessionTimes.length > 0) {
            item2 = data.sessions.find(
              item =>
                !item.isPrerecorded &&
                item.sessionTimes &&
                item.sessionTimes.some(st2 => item1.sessionTimes.some(st1 => st1.startTime === st2.startTime)),
            );
          } else {
            item2 = data.sessions.find(
              item =>
                (item.id !== undefined && item.id === item1.id) ||
                (item.name === item1.name && item.title === item1.title),
            );
          }

          if (item2) {
            return {
              ...item1,
              name: item2.name !== item1.name ? item2.name : item1.name,
            };
          }
          return item1;
        });

        newContribution = {
          ...contribution,
          ...data,
          sessions: newArray,
          externalCalendarEmail: data.externalCalendarEmail,
        };
      } else {
        newContribution = {
          ...contribution,
          ...data,
          sessions: values?.sessions,
          externalCalendarEmail: data.externalCalendarEmail,
          isDefaultServiceProviderSet: true,
        };
      }

      onSaveContribution(newContribution);
      if (isEditing === false) {
        saveHandler(newContribution);
        history.push(to || redirectTo);
      }
    },
    [contribution, history, saveHandler, redirectTo, onSaveContribution],
  );

  const submitOneToOneHandler = async (values, formikHelpers, to = '', withoutValidation = false) => {
    if (!withoutValidation && checkSessionDuration(oneToOneData.selectedWeeks, oneToOneData.sessionDuration)) {
      return;
    }

    if (customQuestionsFormRef.current) {
      if (
        typeof customQuestionsFormRef.current.validateForm !== 'function' &&
        typeof customQuestionsFormRef.current.handleSubmit !== 'function'
      ) {
        return;
      }
      await customQuestionsFormRef.current.validateForm();
      await customQuestionsFormRef.current.handleSubmit();
      if (!_.isEmpty(customQuestionsFormRef.current.errors)) {
        return;
      }
      const customQuestionsValues = customQuestionsFormRef.current.values;
      // customQuestionsValues.enrollmentForm.isCustomEnrollmentFormAdded =
      //   values.enrollmentForm.isCustomEnrollmentFormAdded;
      values = { ...values, ...customQuestionsValues };
    }

    let data = {
      ...values,
    };

    let slotsId = null;

    if (data.enrollmentForm) {
      delete data.enrollmentForm;
      data = {
        ...data,
        ...values.enrollmentForm,
      };
    }

    data.availabilityTimes = [];
    data.durations = Array.isArray(data.sessions[0].durations)
      ? data.sessions[0].durations
      : [data.sessions[0].durations];

    data.sessions.forEach(session => {
      session.name = '1:1 Session';

      if (session.minParticipantsNumber !== 1) {
        session.minParticipantsNumber = 1;
      }

      if (session.maxParticipantsNumber !== 1) {
        session.maxParticipantsNumber = 1;
      }

      if (session.durations && !Array.isArray(session.durations)) {
        session.durations = [session.durations];
      }

      if (getTimePeriodsForSessionsCollection(session.sessionTimes).length) {
        slotsId = getTimePeriodsForSessionsCollection(session.sessionTimes)[0].id;
      }

      const { slots } = generateOneToOneSlots(oneToOneData, session.sessionTimes || [], otherEvents, []);

      data.availabilityTimes = data.availabilityTimes.concat(slots);

      data.oneToOneSessionDataUi =
        values.minimumNotice.value == 0 || values.minimumNotice.value == '0'
          ? { ...oneToOneData, minimumNotice: { type: 'Hours', value: 0 } }
          : { ...oneToOneData, minimumNotice: values.minimumNotice };

      data.durations = [oneToOneData.sessionDuration];

      data.sessionIncrements = [oneToOneData.startTimeIncrementDuration];

      data.availabilityTimesForUi = session.sessionTimes.map(R.omit(['startDate', 'endDate']));
    });

    const newContribution = {
      ...contribution,
      ...data,
      isDefaultServiceProviderSet: true,
    };

    onSaveContribution(newContribution);
    if (isEditing === false) {
      saveHandler(newContribution);
      history.push(to || redirectTo);
    }
  };

  const submitCommunityHandler = useCallback(
    (values, formikHelpers, to = '') => {
      const data = {
        ...values,
      };

      data.sessions = orderBy(data.sessions, session => findGreatestSessionTime(session).startTime, ['asc']);

      data.sessions.forEach(session => {
        session.sessionTimes.forEach(time => {
          time.startTime = serializeTime(time.startTime);
          time.endTime = serializeTime(time.endTime);
        });
      });

      // if any sessions deleted, we have to sort the titles again
      let count = 1;
      for (let i = 0; i < data.sessions.length; i++) {
        if (!data.sessions[i].isPrerecorded) {
          data.sessions[i].title = `Session ${count}`;
          count++;
        }
      }
      data.enrollment.fromDate = UTCtoLocalDateTime(
        moment(data.enrollment.fromDate).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        }),
      );
      data.enrollment.toDate = UTCtoLocalDateTime(
        moment(data.enrollment.toDate).set({
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 999,
        }),
      );
      const newContribution = {
        ...contribution,
        ...data,
        externalCalendarEmail: data.externalCalendarEmail,
        isDefaultServiceProviderSet: true,
      };

      onSaveContribution(newContribution);
      if (isEditing === false) {
        saveHandler(newContribution);
        history.push(to || redirectTo);
      }
    },
    [contribution, history, saveHandler, redirectTo, onSaveContribution],
  );

  if (!contribution) return null;

  let Form = null;
  const renderButtons = (submitHandler = () => {}) => (
    <Buttons
      backButtonTitle="Back"
      backClickHandler={event => {
        const to = query.id ? `/edit-contribution/${query.id}/details` : '/create-contribution/details';
        setRedirectTo(to);
        submitHandler(event, to);
      }}
      onNextClickHandler={event => {
        const to = query.id ? `/edit-contribution/${query.id}/payment` : '/create-contribution/payment';
        setRedirectTo(to);
        submitHandler(event, to, true);
      }}
      nextButtonTitle="Save and Next"
      formId="create-contribution-form"
    />
  );

  if (contribution.type === ContributionType.contributionCourse) {
    Form = (
      <Formik
        innerRef={contributionFormRef}
        enableReinitialize
        initialValues={initialValuesLive}
        validationSchema={isEditing ? liveSessionsValidationSchemaEditing : liveSessionsValidationSchema}
        onSubmit={submitLiveHandler}
      >
        {formProps => {
          if (isequal(formProps.values.sessions, mysessions)) {
            formProps.values.sessions = mysessions;
          }

          const saveData = (event, to, withValidation = false) => {
            if (isEditing || withValidation) {
              formProps.handleSubmit(event);
            } else {
              submitLiveHandler(formProps.values, formProps, to);
            }
          };
          return (
            <MainContainer
              sidebarProps={{
                saveHandler: (event, { to }) => {
                  setRedirectTo(to);
                  saveData(event, to);
                },
                errors: {},
              }}
            >
              <Joyride
                // tooltipComponent={Tooltip}
                callback={handleJoyrideCallback}
                continuous={true}
                getHelpers={getHelpers}
                run={run}
                stepIndex={stepIndex}
                showProgress={false}
                showSkipButton={false}
                steps={steps}
                styles={{
                  options: {
                    primaryColor: '#CDBA8F',
                    zIndex: 10000000,
                  },
                }}
              />
              <StyledMainSection id={'contributionSessions'} mobileView={mobileView}>
                {errorOccured && errorPopUpState && (
                  <div
                    className="sticky"
                    style={{
                      top: offset >= 110 ? 0 : 110,
                      width: '45%',
                      backgroundColor: 'white',
                      marginInline: '9%',
                      border: '1px solid #DBE7F2',
                      borderRadius: '4px',
                      zIndex: 1,
                      transitionDuration: '1s',
                    }}
                  >
                    <div style={{ padding: '15px 10px 10px 15px' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          justifyContent: 'space-between',
                          alignItems: 'flex-end',
                        }}
                      >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <InfoIcon style={{ transform: 'rotateX(180deg)' }} htmlColor={colors.darkOceanBlue} />
                          <div
                            style={{
                              fontFamily: 'Avenir',
                              fontWeight: '700',
                              fontSize: '16px',
                              lineHeight: '24px',
                              color: '#1E1E1E',
                              marginInline: '10px',
                            }}
                          >
                            Before you proceed...
                          </div>
                        </div>
                        <StyledCloseIcon
                          onClick={() => {
                            seterrorPopUpState(false);
                            // dispatch(contributionActions.setErrorPopUp(false));
                          }}
                          fontSize="10px"
                          style={{ color: 'black' }}
                        />
                      </div>

                      {errorState?.message ===
                      `Your availability time frame windows are overlapping. Please fix this on step 3` ? (
                        <div
                          style={{
                            fontFamily: 'Avenir',
                            fontWeight: '400',
                            fontSize: '16px',
                            lineHeight: '22px',
                            color: '#1E1E1E',
                            marginTop: '10px',
                            marginInline: '35px',
                          }}
                        >
                          You already have a session scheduled on the added date & time. Please add another date & time
                          in order to proceed with “Save and Exit” Please fill out missing field in order to continue
                        </div>
                      ) : errorState?.Error?.Message ? (
                        errorState?.Error?.Message.includes(
                          'You have exceeded the daily rate limit (100) of Meeting Create/Update API requests permitted for this particular user.',
                        ) ? (
                          <div
                            style={{
                              fontFamily: 'Avenir',
                              fontWeight: '400',
                              fontSize: '16px',
                              lineHeight: '22px',
                              color: '#1E1E1E',
                              marginTop: '10px',
                              marginInline: '35px',
                            }}
                          >
                            You have exceeded the daily rate limit (100) of Meeting Create/Update API requests permitted
                            for this particular user.
                          </div>
                        ) : (
                          <div
                            style={{
                              fontFamily: 'Avenir',
                              fontWeight: '400',
                              fontSize: '16px',
                              lineHeight: '22px',
                              color: '#1E1E1E',
                              marginTop: '10px',
                              marginInline: '35px',
                            }}
                          >
                            Your Zoom access token has expired.Please update your Zoom access token by reconnecting zoom
                            on the video tab.
                          </div>
                        )
                      ) : (
                        <div
                          style={{
                            fontFamily: 'Avenir',
                            fontWeight: '400',
                            fontSize: '16px',
                            lineHeight: '22px',
                            color: '#1E1E1E',
                            marginTop: '10px',
                            marginInline: '35px',
                          }}
                        >
                          {errorState?.message || errorState.Error?.Message}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <LiveForm
                  mysession={setMysessions}
                  {...formProps}
                  calendars={calendars}
                  smsReminderSettings={smsSessionReminderSettings}
                />
                {renderButtons(saveData)}
              </StyledMainSection>
              <Modal
                title=""
                isOpen={showModal}
                onCloseText="OK"
                hiddenCancel
                onSubmit={togglePopup}
                onCancel={togglePopup}
                disableFooter
              >
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: '10px' }}>
                  <StyledImgCongrats previewImg={TemplateOne}></StyledImgCongrats>
                  <div style={{ fontSize: '24px', color: '#215C73', fontWeight: 800 }}>CONGRATS!</div>
                  <div
                    style={{
                      color: '#A58B62',
                      fontSize: '16px',
                      marginTop: '13px',
                      fontWeight: 450,
                      fontFamily: 'Avenir',
                    }}
                  >
                    You are fully set up & ready to transform lives!
                  </div>
                  <Button
                    autoWidth
                    backgroundColor={'#C9B382'}
                    style={{
                      marginTop: '40px',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      marginBottom: '10px',
                    }}
                    onClick={togglePopup}
                  >
                    Let’s do this!
                  </Button>
                </div>
              </Modal>
            </MainContainer>
          );
        }}
      </Formik>
    );
  }

  if (contribution.type === ContributionType.contributionOneToOne) {
    const alreadyBooked = contribution?.availabilityTimes
      ? contribution.availabilityTimes.some(availabilityTime => Boolean(availabilityTime.bookedTimes.length))
      : false;

    Form = (
      <>
        <Formik
          innerRef={contributionFormRef}
          enableReinitialize
          initialValues={initialValuesOneToOne}
          validationSchema={oneToOneSessionsValidationSchema}
          onSubmit={submitOneToOneHandler}
        >
          {formProps => {
            const saveData = (event, to, withValidation = false) => {
              if (isEditing || withValidation) {
                console.log('formProps?.values IF:===> ', formProps?.values);
                formProps.handleSubmit(event);
              } else {
                console.log('formProps?.values else:===> ', formProps?.values);
                submitOneToOneHandler(formProps?.values, formProps, to, true);
              }
            };
            return (
              <MainContainer
                sidebarProps={{
                  saveHandler: (event, { to }) => {
                    setRedirectTo(to);
                    saveData(event);
                  },
                  errors: {},
                }}
              >
                <Joyride
                  // tooltipComponent={Tooltip}
                  callback={handleJoyrideCallback}
                  continuous={true}
                  getHelpers={getHelpers}
                  run={run}
                  stepIndex={stepIndex}
                  showProgress={false}
                  showSkipButton={false}
                  steps={steps}
                  styles={{
                    options: {
                      primaryColor: '#CDBA8F',
                      zIndex: 10000000,
                    },
                  }}
                />
                <StyledMainSection id={'contributionSessions'} mobileView={mobileView}>
                  {errorOccured && errorPopUpState && errorState?.message?.includes('zoom') && (
                    <div
                      className="sticky"
                      style={{
                        top: offset >= 110 ? 0 : 110,
                        width: '45%',
                        backgroundColor: 'white',
                        marginInline: '9%',
                        border: '1px solid #DBE7F2',
                        borderRadius: '4px',
                        zIndex: 1,
                        transitionDuration: '1s',
                      }}
                    >
                      <div style={{ padding: '15px 10px 10px 15px' }}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'flex-end',
                          }}
                        >
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <InfoIcon style={{ transform: 'rotateX(180deg)' }} htmlColor={colors.darkOceanBlue} />
                            <div
                              style={{
                                fontFamily: 'Avenir',
                                fontWeight: '700',
                                fontSize: '16px',
                                lineHeight: '24px',
                                color: '#1E1E1E',
                                marginInline: '10px',
                              }}
                            >
                              Before you proceed...
                            </div>
                          </div>
                          <StyledCloseIcon
                            onClick={() => {
                              seterrorPopUpState(false);
                              // dispatch(contributionActions.setErrorPopUp(false));
                            }}
                            fontSize="10px"
                            style={{ color: 'black' }}
                          />
                        </div>

                        {errorState?.message ===
                        `Your availability time frame windows are overlapping. Please fix this on step 3` ? (
                          <div
                            style={{
                              fontFamily: 'Avenir',
                              fontWeight: '400',
                              fontSize: '16px',
                              lineHeight: '22px',
                              color: '#1E1E1E',
                              marginTop: '10px',
                              marginInline: '35px',
                            }}
                          >
                            You already have a session scheduled on the added date & time. Please add another date &
                            time in order to proceed with “Save and Exit” Please fill out missing field in order to
                            continue
                          </div>
                        ) : errorState?.message?.includes('zoom') ? (
                          <div
                            style={{
                              fontFamily: 'Avenir',
                              fontWeight: '400',
                              fontSize: '16px',
                              lineHeight: '22px',
                              color: '#1E1E1E',
                              marginTop: '10px',
                              marginInline: '35px',
                            }}
                          >
                            Your Zoom access token has expired.Please update your Zoom access token by reconnecting zoom
                            on the video tab.
                          </div>
                        ) : (
                          <div
                            style={{
                              fontFamily: 'Avenir',
                              fontWeight: '400',
                              fontSize: '16px',
                              lineHeight: '22px',
                              color: '#1E1E1E',
                              marginTop: '10px',
                              marginInline: '35px',
                            }}
                          >
                            {errorState?.message || errorState.Error?.Message}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <OneToOneForm
                    {...formProps}
                    otherEvents={otherEvents}
                    alreadyBooked={alreadyBooked}
                    setSelectedEmailCalendar={setSelectedEmailCalendar}
                    selectedEmailCalendar={selectedEmailCalendar}
                    setAddCustomEnrollmentQuestions={setAddCustomEnrollmentQuestions}
                    customQuestionsFormRef={customQuestionsFormRef}
                    customEnrollmentFormRef={customEnrollmentFormRef}
                    CustomInvitationBodyDefault={CustomInvitationBodyDefaultOneOnOne}
                    customInvitaionBodyText={customInvitaionBodyText}
                    smsReminderSettings={smsSessionReminderSettings}
                    setExternalCalendarSetToDefault={setExternalCalendarSetToDefault}
                    externalCalendarSetToDefault={externalCalendarSetToDefault}
                  />

                  {/* {contribution && (
                    <Formik
                      innerRef={customQuestionsFormRef}
                      initialValues={{
                        enrollmentForm: {
                          isCustomEnrollmentFormAdded: addCustomEnrollmentQuestions,
                          enrollmentFormViewModel: {
                            isCustomEnrollmentFormAdded: addCustomEnrollmentQuestions,
                            contributionId: contribution?.id,
                            contributionName: contribution?.title,
                            userId: user?.id,
                            questions:
                              contribution?.enrollmentFormViewModel?.questions.map(question => {
                                delete question.userResponse;
                                return question;
                              }) ?? [],
                          },
                        },
                      }}
                      validationSchema={enrollmentFormSchema}
                    >
                      <CustomQuestions
                        customQuestionsFormRef={customQuestionsFormRef}
                        className={`${addCustomEnrollmentQuestions ? '' : 'd-none'}`}
                        customEnrollmentFormRef={customEnrollmentFormRef}
                        values={contributionFormRef.current?.values}
                      />
                    </Formik>
                  )} */}
                  {renderButtons(saveData)}
                </StyledMainSection>
                <Modal
                  title=""
                  isOpen={showModal}
                  onCloseText="OK"
                  hiddenCancel
                  onSubmit={togglePopup}
                  onCancel={togglePopup}
                  disableFooter
                >
                  <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: '10px' }}>
                    <StyledImgCongrats previewImg={TemplateOne}></StyledImgCongrats>
                    <div style={{ fontSize: '24px', color: '#215C73', fontWeight: 800 }}>CONGRATS!</div>
                    <div
                      style={{
                        color: '#A58B62',
                        fontSize: '16px',
                        marginTop: '13px',
                        fontWeight: 450,
                        fontFamily: 'Avenir',
                      }}
                    >
                      You are fully set up & ready to transform lives!
                    </div>
                    <Button
                      autoWidth
                      backgroundColor={'#C9B382'}
                      style={{
                        marginTop: '40px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        marginBottom: '10px',
                      }}
                      onClick={togglePopup}
                    >
                      Let’s do this!
                    </Button>
                  </div>
                </Modal>
              </MainContainer>
            );
          }}
        </Formik>
      </>
    );
  }

  if (contribution.type === ContributionType.contributionMembership) {
    Form = (
      <Formik
        innerRef={contributionFormRef}
        enableReinitialize
        initialValues={initialValuesMembership}
        validationSchema={isEditing ? membershipSessionsValidationSchemaEditing : membershipSessionsValidationSchema}
        onSubmit={submitMembershipHandler}
      >
        {formProps => {
          if (isequal(formProps.values.sessions, mysessions)) {
            formProps.values.sessions = mysessions;
          }
          const saveData = (event, to, withValidation = false) => {
            if (isEditing || withValidation) {
              formProps.handleSubmit(event);
            } else {
              submitMembershipHandler(formProps.values, formProps, to);
            }
          };
          return (
            <MainContainer
              sidebarProps={{
                saveHandler: (event, { to }) => {
                  setRedirectTo(to);
                  saveData(event, to);
                },
                errors: {},
              }}
            >
              <Joyride
                // tooltipComponent={Tooltip}
                callback={handleJoyrideCallback}
                continuous={true}
                getHelpers={getHelpers}
                run={run}
                stepIndex={stepIndex}
                showProgress={false}
                showSkipButton={false}
                steps={steps}
                styles={{
                  options: {
                    primaryColor: '#CDBA8F',
                    zIndex: 10000000,
                  },
                }}
              />
              <StyledMainSection id={'contributionSessions'} mobileView={mobileView}>
                {errorOccured && errorPopUpState && (
                  <div
                    className="sticky"
                    style={{
                      top: offset >= 110 ? 0 : 110,
                      width: '45%',
                      backgroundColor: 'white',
                      marginInline: '9%',
                      border: '1px solid #DBE7F2',
                      borderRadius: '4px',
                      zIndex: 1,
                      transitionDuration: '1s',
                    }}
                  >
                    <div style={{ padding: '15px 10px 10px 15px' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          justifyContent: 'space-between',
                          alignItems: 'flex-end',
                        }}
                      >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <InfoIcon style={{ transform: 'rotateX(180deg)' }} htmlColor={colors.darkOceanBlue} />
                          <div
                            style={{
                              fontFamily: 'Avenir',
                              fontWeight: '700',
                              fontSize: '16px',
                              lineHeight: '24px',
                              color: '#1E1E1E',
                              marginInline: '10px',
                            }}
                          >
                            Before you proceed...
                          </div>
                        </div>
                        <StyledCloseIcon
                          onClick={() => {
                            seterrorPopUpState(false);
                            // dispatch(contributionActions.setErrorPopUp(false));
                          }}
                          fontSize="10px"
                          style={{ color: 'black' }}
                        />
                      </div>

                      {errorState?.message ===
                      `Your availability time frame windows are overlapping. Please fix this on step 3` ? (
                        <div
                          style={{
                            fontFamily: 'Avenir',
                            fontWeight: '400',
                            fontSize: '16px',
                            lineHeight: '22px',
                            color: '#1E1E1E',
                            marginTop: '10px',
                            marginInline: '35px',
                          }}
                        >
                          You already have a session scheduled on the added date & time. Please add another date & time
                          in order to proceed with “Save and Exit” Please fill out missing field in order to continue
                        </div>
                      ) : errorState?.Error?.Message ? (
                        <div
                          style={{
                            fontFamily: 'Avenir',
                            fontWeight: '400',
                            fontSize: '16px',
                            lineHeight: '22px',
                            color: '#1E1E1E',
                            marginTop: '10px',
                            marginInline: '35px',
                          }}
                        >
                          Your Zoom access token has expired.Please update your Zoom access token by reconnecting zoom
                          on the video tab.
                        </div>
                      ) : (
                        <div
                          style={{
                            fontFamily: 'Avenir',
                            fontWeight: '400',
                            fontSize: '16px',
                            lineHeight: '22px',
                            color: '#1E1E1E',
                            marginTop: '10px',
                            marginInline: '35px',
                          }}
                        >
                          {errorState?.message || errorState.Error?.Message}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <MembershipForm
                  mysession={setMysessions}
                  {...formProps}
                  smsReminderSettings={smsSessionReminderSettings}
                />
                {renderButtons(saveData)}
              </StyledMainSection>
              <Modal
                title=""
                isOpen={showModal}
                onCloseText="OK"
                hiddenCancel
                onSubmit={togglePopup}
                onCancel={togglePopup}
                disableFooter
              >
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: '10px' }}>
                  <StyledImgCongrats previewImg={TemplateOne}></StyledImgCongrats>
                  <div style={{ fontSize: '24px', color: '#215C73', fontWeight: 800 }}>CONGRATS!</div>
                  <div
                    style={{
                      color: '#A58B62',
                      fontSize: '16px',
                      marginTop: '13px',
                      fontWeight: 450,
                      fontFamily: 'Avenir',
                    }}
                  >
                    You are fully set up & ready to transform lives!
                  </div>
                  <Button
                    autoWidth
                    backgroundColor={'#C9B382'}
                    style={{
                      marginTop: '40px',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      marginBottom: '10px',
                    }}
                    onClick={togglePopup}
                  >
                    Let’s do this!
                  </Button>
                </div>
              </Modal>
            </MainContainer>
          );
        }}
      </Formik>
    );
  }

  if (contribution.type === ContributionType.contributionCommunity) {
    Form = (
      <Formik
        innerRef={contributionFormRef}
        enableReinitialize
        initialValues={initialValuesCommunity}
        validationSchema={communitySessionsValidationSchema}
        onSubmit={submitCommunityHandler}
      >
        {formProps => {
          if (isequal(formProps.values.sessions, mysessions)) {
            formProps.values.sessions = mysessions;
          }

          const saveData = (event, to, withValidation = false) => {
            if (isEditing || withValidation) {
              formProps.handleSubmit(event);
            } else {
              submitCommunityHandler(formProps.values, formProps, to);
            }
          };
          return (
            <MainContainer
              sidebarProps={{
                saveHandler: (event, { to }) => {
                  setRedirectTo(to);
                  saveData(event, to);
                },
                errors: {},
              }}
            >
              <Joyride
                // tooltipComponent={Tooltip}
                callback={handleJoyrideCallback}
                continuous={true}
                getHelpers={getHelpers}
                run={run}
                stepIndex={stepIndex}
                showProgress={false}
                showSkipButton={false}
                steps={steps}
                styles={{
                  options: {
                    primaryColor: '#CDBA8F',
                    zIndex: 10000000,
                  },
                }}
              />
              <StyledMainSection id={'contributionSessions'} mobileView={mobileView}>
                {errorOccured && errorPopUpState && (
                  <div
                    className="sticky"
                    style={{
                      top: offset >= 110 ? 0 : 110,
                      width: '45%',
                      backgroundColor: 'white',
                      marginInline: '9%',
                      border: '1px solid #DBE7F2',
                      borderRadius: '4px',
                      zIndex: 1,
                      transitionDuration: '1s',
                    }}
                  >
                    <div style={{ padding: '15px 10px 10px 15px' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          justifyContent: 'space-between',
                          alignItems: 'flex-end',
                        }}
                      >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <InfoIcon style={{ transform: 'rotateX(180deg)' }} htmlColor={colors.darkOceanBlue} />
                          <div
                            style={{
                              fontFamily: 'Avenir',
                              fontWeight: '700',
                              fontSize: '16px',
                              lineHeight: '24px',
                              color: '#1E1E1E',
                              marginInline: '10px',
                            }}
                          >
                            Before you proceed...
                          </div>
                        </div>
                        <StyledCloseIcon
                          onClick={() => {
                            seterrorPopUpState(false);
                            // dispatch(contributionActions.setErrorPopUp(false));
                          }}
                          fontSize="10px"
                          style={{ color: 'black' }}
                        />
                      </div>

                      {errorState?.message ===
                      `Your availability time frame windows are overlapping. Please fix this on step 3` ? (
                        <div
                          style={{
                            fontFamily: 'Avenir',
                            fontWeight: '400',
                            fontSize: '16px',
                            lineHeight: '22px',
                            color: '#1E1E1E',
                            marginTop: '10px',
                            marginInline: '35px',
                          }}
                        >
                          You already have a session scheduled on the added date & time. Please add another date & time
                          in order to proceed with “Save and Exit” Please fill out missing field in order to continue
                        </div>
                      ) : errorState?.Error?.Message ? (
                        <div
                          style={{
                            fontFamily: 'Avenir',
                            fontWeight: '400',
                            fontSize: '16px',
                            lineHeight: '22px',
                            color: '#1E1E1E',
                            marginTop: '10px',
                            marginInline: '35px',
                          }}
                        >
                          Your Zoom access token has expired.Please update your Zoom access token by reconnecting zoom
                          on the video tab.
                        </div>
                      ) : (
                        <div
                          style={{
                            fontFamily: 'Avenir',
                            fontWeight: '400',
                            fontSize: '16px',
                            lineHeight: '22px',
                            color: '#1E1E1E',
                            marginTop: '10px',
                            marginInline: '35px',
                          }}
                        >
                          {errorState?.message || errorState.Error?.Message}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <CommunityForm
                  mysession={setMysessions}
                  {...formProps}
                  smsReminderSettings={smsSessionReminderSettings}
                />
                {renderButtons(saveData)}
              </StyledMainSection>
              <Modal
                title=""
                isOpen={showModal}
                onCloseText="OK"
                hiddenCancel
                onSubmit={togglePopup}
                onCancel={togglePopup}
                disableFooter
              >
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: '10px' }}>
                  <StyledImgCongrats previewImg={TemplateOne}></StyledImgCongrats>
                  <div style={{ fontSize: '24px', color: '#215C73', fontWeight: 800 }}>CONGRATS!</div>
                  <div
                    style={{
                      color: '#A58B62',
                      fontSize: '16px',
                      marginTop: '13px',
                      fontWeight: 450,
                      fontFamily: 'Avenir',
                    }}
                  >
                    You are fully set up & ready to transform lives!
                  </div>
                  <Button
                    autoWidth
                    backgroundColor={'#C9B382'}
                    style={{
                      marginTop: '40px',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      marginBottom: '10px',
                    }}
                    onClick={togglePopup}
                  >
                    Let’s do this!
                  </Button>
                </div>
              </Modal>
            </MainContainer>
          );
        }}
      </Formik>
    );
  }

  return Form || renderButtons();
}

Sessions.propTypes = {
  contribution: PropTypes.shape(),
  saveContribution: PropTypes.func.isRequired,
  saveContributionToLS: PropTypes.func.isRequired,
};

Sessions.defaultProps = {
  contribution: null,
};

const mapStateToProps = ({ contributions, oneToOneData, calendars }) => ({
  contribution: contributions.activeContribution,
  editContributionTimes: contributions.editContributionTimes,
  oneToOneData,
  calendars: calendars.calendarsAccounts,
  // busyTimes: calendars.busyTimes
});

const actions = {
  fetchCalendars: calendarsActions.fetchCalendars,
  saveContribution: contributionActions.saveContribution,
  saveContributionToLS: contributionActions.saveDraftContribution,
};

export default connect(mapStateToProps, actions)(Sessions);
