import React, { useCallback, useEffect, useRef, useState } from 'react';
import './SessionCard.scss';
import styled from 'styled-components';
import TemplateOne from 'assets/templateone11.png';
import Modal from 'components/UI/Modal';
import isEmpty from 'lodash/isEmpty';
import { Avatar, useMediaQuery, useTheme } from '@material-ui/core';
import * as R from 'ramda';
import { StylesProvider } from '@material-ui/core';
import ProgressBar from '@ramonak/react-progress-bar';
import { colors } from 'utils/styles';
import Button from 'components/FormUI/Button';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PlaceholderImage from 'assets/placeholder.jpg';
import { PreviewImage } from 'components/AdvertismentCard/style';
import { JourneySection } from 'helpers/constants/sections';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { openInNewWindow, redirectTo } from 'services/links';
import { ContributionType } from 'helpers/constants';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useAccount from 'hooks/useAccount';
import MoreHorizIcon from '@mui/icons-material/MoreVert';
import { style } from '@mui/system';
import ClickDropDown, { StyledDropDownItem } from 'components/UI/ClickDropDown';
import { mdiDotsVertical } from '@mdi/js';
import { mdiChevronDown } from '@mdi/js';
import axiosInstance from 'utils/axiosInstance';
import { useDispatch, useSelector } from 'react-redux';
import { setRefetchContribution } from 'actions/contributions';
import useRouter from 'hooks/useRouter';
import Loader from 'components/UI/Loader';
import WarningIcon from 'components/Icons/WarningIcon';

const StyledImage = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 6px;
  background-color: #e6e6e6;
  width: 100%;
  height: 90%;
  border: 1px solid #00000021;
  display: flex;
  justify-content: flex-end;
  ${({ mobileView }) =>
    mobileView &&
    `
 position: relative;
`}
`;

const StyledSessionRemaining = styled.div`
  color: ${({ clr }) => clr};
  width: ${({ width }) => width};
`;

const VerticalLine = styled.div`
  width: 5px;
  height: 17px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #215c73;
`;

const AvatarComponent = styled(Avatar)`
  ${({ mobileView }) => {
    return (
      mobileView &&
      `
      width: 70px;
      height: 70px;
      font-size: 25px;
      `
    );
  }}
`;

const StyledBarLabel = styled.div`
  font-size: 12px;
  color: #7b8180;
`;

const StyledDateTime = styled.div`
  // width: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // ${({ time, mobileView }) => time && `width: 28%`}
  ${({ isContribution }) => isContribution && `width: 100%`}
`;
const StyledDateTimeMobile = styled.div`
  width: 38%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${({ time, mobileView }) => time && `width: 25%`}
  ${({ isContribution }) => isContribution && `width: 100%`}
`;

const StyledDateandTimeLabel = styled.div`
  ${({ mobileView }) => (mobileView ? `width: 200px;` : ` width: 85px;`)}
  height: 24px;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #000000;
  margin-left: 3px;

  ${({ time }) =>
    time &&
    `
  text-transform: none;
  width: 50px;

  `}

  ${({ isContribution }) =>
    isContribution &&
    `
  align-items: flex-start;
  text-transform: none;
  width: 58px;
   `}

   ${({ isContribution, mobileView, oneToOne, width }) =>
    oneToOne &&
    mobileView &&
    isContribution &&
    `
   width: ${width};
   height: 28px;
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 21px;
   display: flex;
   align-items: center;
   color: #7B8180;
   margin-left: 0px;
 `}
`;

const StyledTimeZoneContainer = styled.div`
width: 32.09px;
height: 18.87px;
background - color: lightgray;
border - radius: 2.83116px;
text - align: center;
`;

const StyledTimeZoneValue = styled.div`
font - family: 'Poppins';
font - style: normal;
font - weight: 400;
  ${({ length }) => length > 3 && `font-size: 10.2121px;`}
  ${({ length }) => length <= 3 && `font-size: 13.2121px;`}
line - height: 19px;
color: #7b8180;
`;

const progressbarColor = colors.darkOceanBlue;

const StyledCoachName = styled.div`
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 19px;
  color: #215c73;
  margin-left: 10px;

  ${({ OneToOneMobileView }) =>
    OneToOneMobileView &&
    `
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 33px;
display: flex;
align-items: center;
color: #060606;
margin-left: 0px;
`}
  ${({ OneToOneMobileView, serviceProviderName }) =>
    OneToOneMobileView &&
    serviceProviderName &&
    `
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 21px;
display: flex;
align-items: center;
color: #7B8180;
margin-left: 0px;
margin-right: 5px;
`}
`;

const CircleContainer = styled.div`
  position: relative;
  display: inline-block;
  margin: 5px;
`;

const BlackCircle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #4a4a4a;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const OptionsList = styled.div`
  position: absolute;
  top: 35px;
  right: 0;
  display: ${({ open }) => (open ? 'block' : 'none')};
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
`;

const Option = styled.div`
  padding: 5px;
  cursor: pointer;
  border-radius: 4px;
  padding: 8px 12px 8px 12px;
  width: 122px;
  height: 34px;
  font-family: 'Avenir';
  font-style: normal;
  font-size: 14px;
  line-height: 130%;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const StyledWarningBar = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
`;
const StyledWarningText = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #ffffff;
`;
const StyledLink = styled.div`
  font-family: Avenir;
  font-size: 14px;
  font-weight: 800;
  line-height: 22px;
  color: #ffffff;
  padding-left: 24px;
  text-decoration: underline;
  cursor: pointer;
`;
const StyledEmail = styled.a`
  font-family: Avenir;
  font-size: 14px;
  font-weight: 800;
  line-height: 22px;
  text-decoration: underline;
  color: #ffffff;
  cursor: pointer;
`;

const getInitialSymbol = R.compose(R.toUpper, R.head);

const SessionCard = props => {
  const { sessioninfo, isContribution, section, isAccessRevokedByCoach, removeIndexFunction, index } = props;
  const { packagePurchases } = sessioninfo;
  const { user } = useAccount();
  const refetchContributons = useSelector(state => state?.contributions?.refetchContributons);
  const dispatch = useDispatch();
  const clientPackage = packagePurchases?.find(pkg => pkg?.userId === user?.id) || {};
  const { sessionNumbers, freeSessionNumbers, bookedSessionNumbers } = clientPackage || {};
  const completedPercentage = (bookedSessionNumbers / sessionNumbers) * 100;
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const DATE_VIEW = 'MMM Do, YYYY';
  const TIME_VIEW = 'h:mm a';

  const isOneToOne = sessioninfo?.type === ContributionType?.contributionOneToOne;
  const oneToOneSession = !isContribution && isOneToOne;
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
  const [selectedContribution, setSelectedContribution] = useState(false);
  const [removeAccessLoader, setremoveAccessLoader] = useState(false);
  const isDropDownRef = useRef(null);
  const disabledCardContainer = mobileView ? 'disabled-card-mobile' : 'disabled-card';
  const sessionCardContainer = mobileView ? 'session-card-mobile mainDiv' : 'session-card mainDiv';
  const disabledSessionCardView = sessioninfo?.isMembershipCancelled === true;
  const coachEmailAddress = sessioninfo?.authorEmail || sessioninfo?.serviceProviderEmail;
  const handleClosePartnerMadal = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  });

  const handleOutsideClick = e => {
    if (!isOpenDropDown) {
      return;
    }
    if (
      isDropDownRef &&
      isDropDownRef?.current &&
      !e?.target?.className?.includes('clickedInSide') &&
      !isDropDownRef?.current?.contains(e.target)
    ) {
      setIsOpenDropDown(false);
    }
  };

  const handleClickRemove = sessioninfo => {
    setIsShowConfirmModal(true);
    setSelectedContribution(sessioninfo);
  };

  const onCancel = () => {
    setIsOpenDropDown(false);
    setIsShowConfirmModal(false);
    setSelectedContribution(false);
  };
  const removeAccessFunction = val => {
    setremoveAccessLoader(true);
    return axiosInstance
      .post(
        `/Contribution/RevokeContributionAccess?contributionId=${sessioninfo.contributionId}&myJourneyAccessRevokeOnly=${val}`,
      )
      .then(() => {
        dispatch(setRefetchContribution(!refetchContributons));
        onCancel();
        setremoveAccessLoader(false);
      })
      .catch(() => {
        setremoveAccessLoader(false);
      });
  };
  const onClickSessionCard = e => {
    if (e?.target?.className?.baseVal || e.contributionTitle || e.contributionId) {
      // if (e.contributionTitle || e.contributionId) {
      //   handleClickRemove();
      // }
      setIsOpenDropDown(!isOpenDropDown);
    } else {
      if (!isOpenDropDown) {
        redirectToUrl();
      }
    }
  };

  const redirectToUrl = () => {
    sessioninfo?.isAccessRevokedByCoach || sessioninfo?.isSelfRemoved || sessioninfo?.isHiddenFromJourneyPage
      ? setModalVisible(true)
      : redirectTo(`/contribution-view/${sessioninfo.contributionId}/sessions`);
  };

  const getDateTime = s => {
    let sessionDateTime;
    switch (s) {
      case JourneySection.incompleted:
        sessionDateTime = sessioninfo?.inCompletedSesionTime;
        break;
      case JourneySection.upcoming:
        sessionDateTime = sessioninfo?.upComingSesionTime;
        break;
      case JourneySection.past:
        sessionDateTime = sessioninfo?.pastSesionTime;
        break;
      case JourneySection.modules:
        sessionDateTime = null;
        break;
      default:
        sessionDateTime = null;
        break;
    }
    const momentDate = sessionDateTime && moment(sessionDateTime).format(DATE_VIEW);
    const momentTime = sessionDateTime && moment(sessionDateTime).format(TIME_VIEW);
    return {
      date: momentDate,
      time: momentTime,
    };
  };

  return (
    <div
      id="mainDiv"
      // onClick={() => {
      //   sessioninfo?.isAccessRevokedByCoach
      //     ? setModalVisible(true)
      //     : redirectTo(`/contribution-view/${sessioninfo.contributionId}/sessions`);
      // }}
      onClick={e => {
        if (!disabledSessionCardView) {
          redirectToUrl();
        }
      }}
      className={disabledSessionCardView ? disabledCardContainer : sessionCardContainer}
      // style = {!!isContribution ? { width: '32%' } : {}}
    >
      {disabledSessionCardView && (
        <>
          <div className="disabled-card_header">
            <StyledWarningBar>
              <WarningIcon />
              <StyledWarningText>Access expired.</StyledWarningText>
            </StyledWarningBar>
            <StyledLink onClick={() => openInNewWindow(`/contribution-view/${sessioninfo.contributionId}/about`)}>
              Join Again?
            </StyledLink>
          </div>
          <div className="disabled-card_content-container"></div>
        </>
      )}
      <StyledImage
        className="mainDiv"
        previewImg={sessioninfo?.previewContentUrls[0] || PlaceholderImage}
        mobileView={mobileView}
      >
        {mobileView && ((!!isContribution && isOneToOne) || oneToOneSession) && (
          <div className="mainDiv" style={{ position: 'absolute', left: '25px', bottom: '-45px' }}>
            <StylesProvider className="mainDiv" injectFirst>
              <AvatarComponent
                className="Avatar minDiv"
                alt={`${!!isContribution ? sessioninfo?.serviceProviderName : sessioninfo?.authorName}`}
                src={sessioninfo?.authorAvatarUrl}
                mobileView={mobileView}
              >
                {`${
                  sessioninfo?.serviceProviderName
                    ? getInitialSymbol(sessioninfo?.serviceProviderName)
                    : getInitialSymbol(sessioninfo?.authorName)
                }`}
              </AvatarComponent>
            </StylesProvider>
          </div>
        )}
        {
          <CircleContainer
            onClick={e => {
              e.stopPropagation();
              onClickSessionCard(sessioninfo);
            }}
            className="cicleContainer"
          >
            <BlackCircle ref={isDropDownRef} className="clickedInSide">
              <div className="cicleContainer" style={{ color: 'white' }}>
                <MoreHorizIcon />
              </div>
              {isOpenDropDown && (
                <OptionsList className="clickedInSide" ref={isDropDownRef} open={[]}>
                  <Option onClick={() => handleClickRemove(sessioninfo)}>Remove</Option>
                </OptionsList>
              )}
            </BlackCircle>
          </CircleContainer>
        }
      </StyledImage>
      {/* <PreviewImage src={sessioninfo?.previewContentUrls[0] || PlaceholderImage} /> */}
      {!(mobileView && ((!!isContribution && isOneToOne) || oneToOneSession)) ? (
        <div className="session-card_title">
          {!!isContribution ? sessioninfo?.title : sessioninfo?.contributionTitle}
        </div>
      ) : (
        <div style={{ width: '100%', height: '80px' }}></div>
      )}
      {!(mobileView && ((!!isContribution && isOneToOne) || oneToOneSession)) ? (
        <div className="session-card_profile">
          <StylesProvider injectFirst>
            <AvatarComponent
              className="Avatar"
              alt={`${!!isContribution ? sessioninfo?.serviceProviderName : sessioninfo?.authorName}`}
              src={sessioninfo?.authorAvatarUrl}
              mobileView={mobileView}
            >
              {`${
                sessioninfo?.serviceProviderName
                  ? getInitialSymbol(sessioninfo?.serviceProviderName)
                  : getInitialSymbol(sessioninfo?.authorName)
              }`}
            </AvatarComponent>
          </StylesProvider>
          <StyledCoachName>
            {!!isContribution ? sessioninfo?.serviceProviderName : sessioninfo?.authorName}
          </StyledCoachName>
        </div>
      ) : (
        <div className="session-card_profileOneToOne">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <StyledCoachName OneToOneMobileView>
              {oneToOneSession
                ? sessioninfo?.contributionTitle.charAt(0).toUpperCase() + sessioninfo?.contributionTitle.slice(1)
                : sessioninfo?.title.charAt(0).toUpperCase() + sessioninfo?.title.slice(1)}
            </StyledCoachName>
            <div style={{ width: '100%', display: 'flex', marginTop: '5px', alignItems: 'center' }}>
              <StyledCoachName OneToOneMobileView serviceProviderName>
                {oneToOneSession
                  ? sessioninfo?.authorName.charAt(0).toUpperCase() + sessioninfo?.authorName.slice(1)
                  : sessioninfo?.serviceProviderName.charAt(0).toUpperCase() +
                    sessioninfo?.serviceProviderName.slice(1)}
              </StyledCoachName>
              {!isEmpty(clientPackage) ? (
                <>
                  <VerticalLine> | </VerticalLine>
                  <div
                    style={{
                      width: '100px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginLeft: '5px',
                    }}
                  >
                    <div style={{ width: '80%' }}>
                      <ProgressBar
                        completed={completedPercentage}
                        bgColor={colors?.darkOceanBlue2}
                        height="10px"
                        isLabelVisible={false}
                        width="100%"
                      />
                    </div>
                    <StyledSessionRemaining clr="#7B8180" width="10%">
                      {`${bookedSessionNumbers}`}/{`${sessionNumbers}`}
                    </StyledSessionRemaining>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      )}
      {isOneToOne && !mobileView && !isEmpty(clientPackage) && (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ width: '85%' }}>
            <ProgressBar
              completed={completedPercentage}
              bgColor={colors?.darkOceanBlue2}
              height="13px"
              isLabelVisible={false}
              width="100%"
            />
          </div>
          <StyledSessionRemaining clr={colors?.darkOceanBlue2} width="10%">
            {`${bookedSessionNumbers > sessionNumbers ? sessionNumbers : bookedSessionNumbers}`}/{`${sessionNumbers}`}
          </StyledSessionRemaining>
        </div>
      )}
      {!isContribution && !sessioninfo?.isPrerecorded && props?.section === 'past' && (
        <div
          className={
            !!isContribution ? 'session-card_bottompanel__datetime-cont' : 'session-card_bottompanel__datetime'
          }
        >
          {mobileView ? (
            <>
              {getDateTime(section).date != null && (
                <StyledDateTimeMobile isContribution={isContribution}>
                  <CalendarMonthIcon htmlColor={colors.lightBrown} sx={{ width: '15px', height: '15px' }} />
                  <StyledDateandTimeLabel mobileView={mobileView}>
                    {getDateTime(section).date === null ? '---' : getDateTime(section).date}
                  </StyledDateandTimeLabel>
                </StyledDateTimeMobile>
              )}
              {!isContribution && getDateTime(section).time != null && (
                <StyledDateTimeMobile time>
                  <AccessTimeIcon htmlColor={colors.lightBrown} sx={{ width: '15px', height: '15px' }} />
                  <StyledDateandTimeLabel time>
                    {getDateTime(section).time === null ? '---' : getDateTime(section).time}
                  </StyledDateandTimeLabel>
                </StyledDateTimeMobile>
              )}
              {!isContribution && sessioninfo?.timeZoneShortForm != null && getDateTime(section).time != null && (
                <StyledTimeZoneContainer length={sessioninfo?.timeZoneShortForm?.length}>
                  <StyledTimeZoneValue length={sessioninfo?.timeZoneShortForm?.length}>
                    {sessioninfo?.timeZoneShortForm}
                  </StyledTimeZoneValue>
                </StyledTimeZoneContainer>
              )}
            </>
          ) : (
            <>
              {getDateTime(section).date != null && (
                <StyledDateTime isContribution={isContribution}>
                  <CalendarMonthIcon htmlColor={colors.lightBrown} sx={{ width: '15px', height: '15px' }} />
                  <StyledDateandTimeLabel mobileView={mobileView}>
                    {getDateTime(section).date === null ? '---' : getDateTime(section).date}
                  </StyledDateandTimeLabel>
                </StyledDateTime>
              )}
              {!isContribution && getDateTime(section).time != null && (
                <StyledDateTime time>
                  <AccessTimeIcon htmlColor={colors.lightBrown} sx={{ width: '15px', height: '15px' }} />
                  <StyledDateandTimeLabel time>
                    {getDateTime(section).time === null ? '---' : getDateTime(section).time}
                  </StyledDateandTimeLabel>
                </StyledDateTime>
              )}
              {!isContribution && sessioninfo?.timeZoneShortForm != null && getDateTime(section).time != null && (
                <StyledTimeZoneContainer length={sessioninfo?.timeZoneShortForm?.length}>
                  <StyledTimeZoneValue length={sessioninfo?.timeZoneShortForm?.length}>
                    {sessioninfo?.timeZoneShortForm}
                  </StyledTimeZoneValue>
                </StyledTimeZoneContainer>
              )}
            </>
          )}
        </div>
      )}
      {props?.section != 'past' && (
        <>
          {!isOneToOne && (
            <div className="session-card_progressbarcontainer">
              <div className="session-card_bar">
                <ProgressBar
                  height="12px"
                  isLabelVisible={false}
                  completed={sessioninfo?.percentageCompleted}
                  bgColor={progressbarColor}
                />
              </div>
              <div className="session-card_label">
                <StyledBarLabel>{sessioninfo?.percentageCompleted}%</StyledBarLabel>
              </div>
            </div>
          )}
          <div
            className="session-card_bottompanel"
            style={!isContribution && sessioninfo?.isPrerecorded ? { display: 'flex', justifyContent: 'flex-end' } : {}}
          >
            <div
              style={
                mobileView
                  ? { display: 'flex', flexDirection: 'row', alignItems: 'center' }
                  : { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }
              }
            >
              {(!!isContribution || oneToOneSession) &&
                (mobileView ? (
                  isOneToOne && sessioninfo.upComingSesionTime ? (
                    <>
                      {getDateTime(section).date != null && (
                        <StyledDateTimeMobile isContribution={isContribution}>
                          <CalendarMonthIcon htmlColor={colors.lightBrown} sx={{ width: '15px', height: '15px' }} />
                          <StyledDateandTimeLabel mobileView={mobileView}>
                            {getDateTime(section).date === null ? '---' : getDateTime(section).date}
                          </StyledDateandTimeLabel>
                        </StyledDateTimeMobile>
                      )}
                      {!isContribution && getDateTime(section).time != null && (
                        <StyledDateTimeMobile time>
                          <AccessTimeIcon htmlColor={colors.lightBrown} sx={{ width: '15px', height: '15px' }} />
                          <StyledDateandTimeLabel time>
                            {getDateTime(section).time === null ? '---' : getDateTime(section).time}
                          </StyledDateandTimeLabel>
                        </StyledDateTimeMobile>
                      )}
                      {!isContribution && sessioninfo?.timeZoneShortForm != null && getDateTime(section).time != null && (
                        <StyledTimeZoneContainer length={sessioninfo?.timeZoneShortForm?.length}>
                          <StyledTimeZoneValue length={sessioninfo?.timeZoneShortForm?.length}>
                            {sessioninfo?.timeZoneShortForm}
                          </StyledTimeZoneValue>
                        </StyledTimeZoneContainer>
                      )}
                    </>
                  ) : (
                    <StyledDateTimeMobile isContribution={isContribution || oneToOneSession}>
                      <StyledDateandTimeLabel
                        width="75px"
                        isContribution={isContribution || oneToOneSession}
                        mobileView={mobileView}
                        oneToOne={isOneToOne}
                      >{`Joined on`}</StyledDateandTimeLabel>
                      <CalendarMonthIcon
                        htmlColor={colors.lightBrown}
                        sx={
                          (isContribution || oneToOneSession) && mobileView
                            ? { width: '25px', height: '25px' }
                            : { width: '15px', height: '15px' }
                        }
                      />
                      <StyledDateandTimeLabel
                        width="115px"
                        isContribution={isContribution || oneToOneSession}
                        mobileView={mobileView}
                        oneToOne={isOneToOne}
                      >
                        {moment(sessioninfo?.purchaseDateTime).format(DATE_VIEW)}
                      </StyledDateandTimeLabel>
                    </StyledDateTimeMobile>
                  )
                ) : isOneToOne && sessioninfo.upComingSesionTime ? (
                  <>
                    {getDateTime(section).date != null && (
                      <StyledDateTime isContribution={isContribution}>
                        <CalendarMonthIcon htmlColor={colors.lightBrown} sx={{ width: '15px', height: '15px' }} />
                        <StyledDateandTimeLabel mobileView={mobileView}>
                          {getDateTime(section).date === null ? '---' : getDateTime(section).date}
                        </StyledDateandTimeLabel>
                      </StyledDateTime>
                    )}
                    {!isContribution && getDateTime(section).time != null && (
                      <StyledDateTime time>
                        <AccessTimeIcon htmlColor={colors.lightBrown} sx={{ width: '15px', height: '15px' }} />
                        <StyledDateandTimeLabel time>
                          {getDateTime(section).time === null ? '---' : getDateTime(section).time}
                        </StyledDateandTimeLabel>
                      </StyledDateTime>
                    )}
                    {!isContribution && sessioninfo?.timeZoneShortForm != null && getDateTime(section).time != null && (
                      <StyledTimeZoneContainer length={sessioninfo?.timeZoneShortForm?.length}>
                        <StyledTimeZoneValue length={sessioninfo?.timeZoneShortForm?.length}>
                          {sessioninfo?.timeZoneShortForm}
                        </StyledTimeZoneValue>
                      </StyledTimeZoneContainer>
                    )}
                  </>
                ) : (
                  <StyledDateTime isContribution={isContribution || oneToOneSession}>
                    <StyledDateandTimeLabel
                      mobileView={mobileView}
                      isContribution={isContribution || oneToOneSession}
                    >{`Joined on`}</StyledDateandTimeLabel>
                    <CalendarMonthIcon htmlColor={colors.lightBrown} sx={{ width: '15px', height: '15px' }} />
                    <StyledDateandTimeLabel mobileView={mobileView}>
                      {moment(sessioninfo?.purchaseDateTime).format(DATE_VIEW)}
                    </StyledDateandTimeLabel>
                  </StyledDateTime>
                ))}
              {!isContribution && !sessioninfo?.isPrerecorded && !oneToOneSession && (
                <div
                  className={
                    !!isContribution ? 'session-card_bottompanel__datetime-cont' : 'session-card_bottompanel__datetime'
                  }
                >
                  {getDateTime(section).time != null && section != JourneySection.bookable && mobileView ? (
                    <>
                      <StyledDateTimeMobile isContribution={isContribution}>
                        <CalendarMonthIcon htmlColor={colors.lightBrown} sx={{ width: '15px', height: '15px' }} />
                        <StyledDateandTimeLabel mobileView={mobileView}>
                          {' '}
                          {getDateTime(section).date === null ? '---' : getDateTime(section).date}
                        </StyledDateandTimeLabel>
                      </StyledDateTimeMobile>
                      {!isContribution && (
                        <StyledDateTimeMobile time>
                          <AccessTimeIcon htmlColor={colors.lightBrown} sx={{ width: '15px', height: '15px' }} />
                          <StyledDateandTimeLabel time>
                            {getDateTime(section).time === null ? '---' : getDateTime(section).time}
                          </StyledDateandTimeLabel>
                        </StyledDateTimeMobile>
                      )}
                      {!isContribution && sessioninfo?.timeZoneShortForm != null && (
                        <StyledTimeZoneContainer length={sessioninfo?.timeZoneShortForm?.length}>
                          <StyledTimeZoneValue length={sessioninfo?.timeZoneShortForm?.length}>
                            {sessioninfo?.timeZoneShortForm}
                          </StyledTimeZoneValue>
                        </StyledTimeZoneContainer>
                      )}
                    </>
                  ) : (
                    getDateTime(section).time != null &&
                    section != JourneySection.bookable && (
                      <>
                        <StyledDateTime isContribution={isContribution}>
                          <CalendarMonthIcon htmlColor={colors.lightBrown} sx={{ width: '15px', height: '15px' }} />
                          <StyledDateandTimeLabel mobileView={mobileView}>
                            {' '}
                            {getDateTime(section).date === null ? '---' : getDateTime(section).date}
                          </StyledDateandTimeLabel>
                        </StyledDateTime>
                        {!isContribution && (
                          <StyledDateTime time>
                            <AccessTimeIcon htmlColor={colors.lightBrown} sx={{ width: '15px', height: '15px' }} />
                            <StyledDateandTimeLabel time>
                              {getDateTime(section).time === null ? '---' : getDateTime(section).time}
                            </StyledDateandTimeLabel>
                          </StyledDateTime>
                        )}
                        {!isContribution && sessioninfo?.timeZoneShortForm != null && (
                          <StyledTimeZoneContainer length={sessioninfo?.timeZoneShortForm?.length}>
                            <StyledTimeZoneValue length={sessioninfo?.timeZoneShortForm?.length}>
                              {sessioninfo?.timeZoneShortForm}
                            </StyledTimeZoneValue>
                          </StyledTimeZoneContainer>
                        )}
                      </>
                    )
                  )}
                </div>
              )}
            </div>
            {!mobileView && !isContribution && (
              <div
                style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
                className={
                  !sessioninfo?.isPrerecorded
                    ? 'session-card_bottompanel__joinbtn'
                    : 'session-card_bottompanel__modulebtn'
                }
              >
                <Button
                  ClientJourneyBtn
                  style={sessioninfo?.isPrerecorded ? { width: '100%' } : {}}
                  onClick={() => {
                    sessioninfo?.isAccessRevokedByCoach
                      ? setModalVisible(true)
                      : redirectTo(`/contribution-view/${sessioninfo.contributionId}/sessions`);
                  }}
                  style={{ padding: '.5rem', paddingLeft: '.75rem', height: 'auto', width: 'auto' }}
                >
                  {getDateTime(section).date === null && !sessioninfo?.isPrerecorded
                    ? `Book `
                    : !sessioninfo?.isPrerecorded
                    ? `Join `
                    : `View Content `}
                  <ArrowForwardIosIcon fontSize="7px" style={{ width: '1rem' }} />
                </Button>
              </div>
            )}
          </div>
          {mobileView && !isContribution && (
            <div style={{ width: '100%', marginTop: '10px' }}>
              <Button
                ClientJourneyBtn
                mobileView={mobileView}
                onClick={() => {
                  sessioninfo?.isAccessRevokedByCoach
                    ? setModalVisible(true)
                    : redirectTo(`/contribution-view/${sessioninfo.contributionId}/sessions`);
                }}
                style={{ padding: '.5rem', paddingLeft: '.75rem', height: 'auto', width: 'auto' }}
              >
                {section === JourneySection.bookable && !sessioninfo?.isPrerecorded
                  ? `Book `
                  : !sessioninfo?.isPrerecorded
                  ? `Join `
                  : `View Content `}
                <ArrowForwardIosIcon fontSize="7px" style={{ width: '1rem' }} />
              </Button>
            </div>
          )}
        </>
      )}

      <Modal
        isOpen={modalVisible}
        onSubmit={handleClosePartnerMadal}
        title={'Access Revoked'}
        hiddenCancel
        disableOverlayClick
        onCancel={handleClosePartnerMadal}
      >
        {sessioninfo?.isAccessRevokedByCoach ? (
          <p>Your access has been revoked by coach, please contact coach.</p>
        ) : sessioninfo?.isSelfRemoved ? (
          <p>You have removed your access from this service, please contact coach.</p>
        ) : (
          <></>
        )}
      </Modal>
      {isShowConfirmModal && (
        <Modal
          isOpen={isShowConfirmModal}
          onSubmit={() => removeAccessFunction(false)}
          onCancel={() => onCancel()}
          onHide={() => removeAccessFunction(true)}
          isHideJourneyButton={true}
          submitTitle={'Remove Access'}
          hideJourneyTitle={'Hide from My Journey'}
          disableSubmitClick={selectedContribution.isSelfRemoved}
          disableJourneyButton={selectedContribution.isHiddenFromJourneyPage}
          cancelTitle={'Cancel'}
          disableOverlayClick
          title={`Are you sure you want to remove ${
            selectedContribution.contributionTitle || selectedContribution.title
          }`}
          mobileView={mobileView}
          cancelBtnStyle={mobileView ? { width: '40%' } : {}}
          submitBtnStyle={mobileView ? { width: '58%', marginLeft: '0px' } : {}}
        >
          <p>By removing this service you will no longer have access to it.</p>
        </Modal>
      )}
      {removeAccessLoader && <Loader />}
    </div>
  );
};

export default SessionCard;
