import React from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import Button from 'components/FormUI/Button';

const Container = styled.div`
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 13px;
  padding: ${({ showVideo }) => !showVideo && '20px'};
  background-color: #f5f5f5;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  height: 141px;
  margin: 8px 0 20px;
  border-radius: 0.25rem;
`;

const MainDiv = styled.div`
  width: 'auto';
  position: absolute;
  padding: ${({ isSessionPage }) => (isSessionPage ? '40px 20px' : '20px')};
  /* top: 0px;
  bottom: 5px; */
  left: 5px;
  right: 5px;
`;

const StyledButtonWrap = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const FileDropZone = ({ isDisabled, type, multiple, placeholder, action, loading, progress }) => {
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop: files => action(files),
    accept: type,
    multiple,
    disabled: isDisabled || loading,
  });
  return (
    <Container
      showVideo
      {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      onClick={event => event.stopPropagation()} // avoid of triggering onClick twice https://github.com/react-dropzone/react-dropzone/issues/252
      isDisabled={isDisabled}
    >
      {loading ? (
        // <CircularProgressWithLabel value={progress} />
        <div>Importing...</div>
      ) : (
        <>
          <input {...getInputProps()} />
          <MainDiv {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
            <StyledButtonWrap>
              <Button onClick={() => {}}>Choose File</Button>
            </StyledButtonWrap>
            <div>{placeholder}</div>
          </MainDiv>
        </>
      )}
    </Container>
  );
};

export default FileDropZone;
