import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import communityImage from 'assets/community-overlay.png';
import Button from 'components/FormUI/Button';
import useRouter from 'hooks/useRouter';
import { ROUTES } from '../../../constants';

const StyledNoCommunitiesContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const StyledInnerDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
`;

const StyledImage = styled.img`
  max-width: ${({ mobileView }) => (mobileView ? '100%' : '345px')};
  max-height: 334px;
`;

const StyledTitle = styled.span`
  display: inline-block;
  color: rgba(0, 0, 0, 0.87);
  font-size: 24px;
  font-family: Avenir;
  font-weight: 800;
  line-height: 40.95px;
  word-wrap: break-word;
  text-align: center;
`;

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`;

const StyledSubtitle = styled.span`
  display: inline-block;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-family: Avenir;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
  text-align: center;
`;

const StyledButton = styled(Button)`
  height: 40px;
  width: fit-content;
  min-width: 157px;
  font-size: 14px;
  padding: 0px;
  font-weight: 900;
`;

function NoCommunity({ isCoach, mobileView }) {
  const { history } = useRouter();
  return (
    <StyledNoCommunitiesContainer>
      <StyledInnerDiv>
        <StyledImage src={communityImage} mobileView={mobileView} alt="assets/NoImage.png" />
        <StyledTextContainer>
          <StyledTitle>Welcome to your Cohere Communities!</StyledTitle>
          <StyledSubtitle>
            {isCoach
              ? 'It looks like you haven’t created any communities yet. Start by creating a service to offer community access to your clients.'
              : 'You will see your communities and posts after joining a service.'}
          </StyledSubtitle>
          {isCoach && (
            <StyledButton
              onClick={() => {
                history.push(ROUTES.CREATE_CONTRIBUTION_BASIC);
              }}
            >
              Create a Service
            </StyledButton>
          )}
        </StyledTextContainer>
      </StyledInnerDiv>
    </StyledNoCommunitiesContainer>
  );
}

NoCommunity.propTypes = {
  isCoach: PropTypes.bool.isRequired,
  mobileView: PropTypes.bool.isRequired,
};

export default NoCommunity;
