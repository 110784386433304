import React, { useState } from 'react';
import classes from './MasterCalanderSidebar.module.scss';
import ManageAvailability from './ManageAvailability/ManageAvailability';
import ConnectedCalendars from './ConnectedCalendars/ConnectedCalendars';
import { NavLink } from 'react-router-dom';
import filledNavigationIcon from '../../assets/filled-naviagtion-icon.png';
import { Box, Tooltip } from '@material-ui/core';
import Logo from 'components/FormUI/Logo';
import Button from 'components/FormUI/Button';
import styled from 'styled-components';
import ScheduleMeetingModal from 'pages/ContributionView/components/CalendarContributionView/ScheduleMeetingModal';
import { useDispatch } from 'react-redux';
import { scheduleClientAction } from 'actions/masterCalendarSidebar.actions';
import AttachedContributions from './AttachedOneOnOneContributions/AttachedContribution';

const SidebarHeader = styled.div`
  margin: 45px 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledLink = styled(NavLink)`
  &.disableMe {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
`;

const MasterCalanderSidebar = ({ handleMasterCalanderOpen, shouldBeDisabled, user, mobileView }) => {
  const dispatch = useDispatch();
  const handleScheduleClient = () => {
    dispatch(scheduleClientAction(true));
  };
  return (
    <>
      <SidebarHeader>
        {!mobileView && (
          <Box
            borderRadius="50%"
            alignSelf="flex-start"
            left={22}
            top={22}
            sx={{ cursor: 'pointer' }}
            position="absolute"
            onClick={handleMasterCalanderOpen}
          >
            <Tooltip title="Access Main Menu" arrow>
              <img width="28px" src={filledNavigationIcon} />
            </Tooltip>
          </Box>
        )}
        <StyledLink
          className={
            user?.user?.isPartnerCoach === false && shouldBeDisabled === false
              ? null
              : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
              ? 'disableMe'
              : shouldBeDisabled == true && user?.user?.isPartnerCoach === true
              ? null
              : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
              ? 'disableMe'
              : null
          }
          to="/dashboard"
        >
          <Logo isMasterCalendar={true} />
        </StyledLink>
        <Button onClick={handleScheduleClient} style={{ marginTop: '24px', minWidth: '150px' }}>
          Schedule Client
        </Button>
      </SidebarHeader>
      <div className={classes.container}>
        <ManageAvailability />
        <ConnectedCalendars />
        <AttachedContributions />
      </div>
    </>
  );
};

export default MasterCalanderSidebar;
