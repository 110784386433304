import React, { useEffect } from 'react';
import classes from './Notification.module.scss';
import CrossIconSvg from './CrossIconSvg';
import CheckIconSvg from './CheckIconSvg';
import { useDispatch, useSelector } from 'react-redux';
import { setCampaignNotification } from 'actions/campaign';
import { isEmpty } from 'lodash';
import ExclamationIconSvg from './ExclamationIconSvg';
import InfoIcon from '@material-ui/icons/Info';
import { useMediaQuery, useTheme } from '@material-ui/core';

const Notification = ({ notification, sidebar = false, component = false, slotsUpdation = false, isModal = false }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const campaignNotification = useSelector(state => state?.campaigns?.campaignNotification);
  useEffect(() => {
    setTimeout(() => {
      dispatch(setCampaignNotification({}));
    }, 5000);
  }, []);
  return slotsUpdation ? (
    <div
      className={
        slotsUpdation && component && isModal
          ? classes.mainslotsmodalcomponent
          : slotsUpdation && component
          ? classes.mainslotscomponent
          : component
          ? classes.maincomponent
          : sidebar
          ? classes.mainSidebar
          : classes.main
      }
    >
      <div className={classes.header}>
        <div className={classes.heading_container}>
          <div className={classes.check_box}>
            <CheckIconSvg />
          </div>
          <div className={classes.heading}>
            {campaignNotification.heading === 'DELETE'
              ? mobileView
                ? 'Slot(s) deleted!'
                : 'Availability slot(s) deleted successfully!'
              : notification?.heading}
          </div>
        </div>
        <div
          className={classes.cross_btn}
          onClick={() => {
            dispatch(setCampaignNotification(''));
          }}
        >
          <CrossIconSvg />
        </div>
      </div>
    </div>
  ) : (
    <div className={component ? classes.maincomponent : sidebar ? classes.mainSidebar : classes.main}>
      <div className={classes.header}>
        <div className={classes.heading_container}>
          {notification?.heading.includes('Unable to Reschedule') ? (
            <InfoIcon style={{ transform: 'rotate(180deg)' }} htmlColor={'#215c73'} />
          ) : (
            <div className={classes.check_box}>
              <CheckIconSvg />
            </div>
          )}
          <div className={classes.heading}>{notification?.heading}</div>
        </div>
        <div
          className={classes.cross_btn}
          onClick={() => {
            dispatch(setCampaignNotification(''));
          }}
        >
          {!component && <CrossIconSvg />}
        </div>
      </div>
      <div className={classes.message}>{notification?.message}</div>
    </div>
  );
};

export default Notification;
