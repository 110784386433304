import React, { useEffect, useMemo, useState } from 'react';
import classes from './Leads.module.scss';
import { StyledHeading } from '../Components/StyledComponents/StyledComponents';
import Buttons from '../Components/ContactsButtons/ContactsButtons';
import { PAGE_TYPES } from '../Components/ContactsButtons/ContactsButtons';
import { DummyLeads } from './DummyLeads';
import { FormControlLabel, Checkbox, useMediaQuery, useTheme } from '@material-ui/core';
import styled from 'styled-components';
import SearchBox from 'pages/EmailCampaigns/Components/SearchBox/SearchBox';
import { ClickAwayListener } from '@material-ui/core';
import FilterIcon from 'pages/EmailCampaigns/Styles/SVG/FilterSvg';
import LeadsTable from './Leads/LeadsTable';
import PaginationBar from 'pages/EmailCampaigns/Components/PaginationBar/PaginationBar';
import FilterLeads from '../Components/Filter/Filter';
import { FilterTypes } from '../Components/Filter/FilterConstants';
import useRouter from 'hooks/useRouter';
import { useHeader } from 'hooks/usePageActions';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'components/UI/Loader';
import moment from 'moment';
import { ContactsServices } from '../Services/Contacts.service';
import { downloadFile } from 'utils/utils';
import Modal from 'components/UI/Modal';
import ImportFileContent from '../Components/ImportFileContent/ImportFileContent';
import AddLeadModalForm from './LeadModals/EditLeadModalForm/EditLeadModalForm';
import AddContactTagModal from './LeadModals/AddContactTagModal';
import Select from 'components/UI/Select';
import { getSearchedAndFilteredData } from '../Utils/functions';
import { PER_PAGE_LEADS_COUNT } from 'pages/EmailCampaigns/Styles/Constants/constants';
import { isEmpty } from 'lodash';
import MainContainer from 'components/Containers/MainContainer';
import { StyledMainSection } from 'components/Containers/CreateContributionContainer';
import { fetchAllContactsAsync } from 'actions/contacts';
import { setSendEmailTo } from 'actions/campaign';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import useCampaign from 'pages/CreateCampaign/Hooks/useCampaign';
import Notification from 'pages/CreateCampaign/Components/Notification/Notification';

const StyledLabel = styled.div`
  font-family: 'Avenir';
  font-weight: 350;
  font-size: 14px;
  line-height: 16px;
  color: #4a4a4a;
`;

const FilterTag = styled.div`
  position: absolute;
  right: -5px;
  top: -5px;
  background-color: ${({ color }) => color ?? 'black'};
  width: 18px;
  height: 18px;
  border-radius: 9px;
`;

const AllLeads = ({ showTopBar = true, showFilter = true, leads = [], setLeads, markedLeads }) => {
  const {
    location: { pathname },
  } = useRouter();
  useHeader('Leads');
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoading, leads: LSLeads } = useSelector(state => state.contacts);
  const isCreateOrEditCampaignPage = pathname.includes('create-campaign') || pathname.includes('edit-campaign');
  const isPathForAllContacts = pathname.includes('contacts') && !isCreateOrEditCampaignPage;
  const [data, setData] = useState(isCreateOrEditCampaignPage ? leads : LSLeads);
  const [selectedLeads, setSelectedLeads] = useState(isCreateOrEditCampaignPage ? markedLeads : []);
  const [allSelected, setAllSelected] = useState(
    selectedLeads?.length > 0 && selectedLeads?.length === data?.length ? true : false,
  );
  const [filterPanelOpen, setFilterPanelOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchedKeyword, setSearchedKeyword] = useState('');
  const [appliedFilters, setAppliedFilters] = useState({});
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isDeleteContactsModalOpen, setIsDeleteContactsModalOpen] = useState(false);
  const [isUnderstandChecked, setIsUnderstandChecked] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [isAddTagModalOpen, setIsAddTagModalOpen] = useState(false);
  const [isImportFileModalOpen, setIsImportFileModalOpen] = useState(false);
  const [isAddLeadModalOpen, setIsAddLeadModalOpen] = useState(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { campaignNotification } = useCampaign();
  useEffect(() => {
    if (!isCreateOrEditCampaignPage) {
      setData(LSLeads);
    }
  }, [LSLeads]);

  useEffect(() => {
    if (selectedLeads?.length > 0 && selectedLeads?.length === data?.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [selectedLeads]);

  const handleSearch = e => {
    setSearchedKeyword(e.target.value.trim());
  };

  const handleFilterClick = () => {
    setFilterPanelOpen(!filterPanelOpen);
  };

  const isChecked = id => {
    return selectedLeads.includes(id);
  };

  const onCheckClicked = id => {
    if (isChecked(id)) {
      setSelectedLeads(selectedLeads.filter(leadId => leadId !== id));
      if (isCreateOrEditCampaignPage) {
        setLeads(selectedLeads.filter(leadId => leadId !== id));
      }
    } else {
      setSelectedLeads([...selectedLeads, ...[id]]);
      if (isCreateOrEditCampaignPage) {
        setLeads([...selectedLeads, ...[id]]);
      }
    }
  };

  const pageCount = useMemo(() => {
    const count = Math.ceil(
      getSearchedAndFilteredData(data, searchedKeyword?.toLocaleLowerCase(), appliedFilters)?.length /
        PER_PAGE_LEADS_COUNT,
    );
    return count;
  }, [data, searchedKeyword, appliedFilters]);

  useEffect(() => {
    setCurrentPage(1);
  }, [pageCount]);

  const handleExportClick = () => {
    setIsExporting(true);
    // const data = {
    //   text: searchedKeyword || undefined,
    //   fromDate: appliedFilters?.fromDate
    //     ? `${moment(appliedFilters?.fromDate).format('YYYY-MM-DD')}T${moment(appliedFilters?.fromDate).format(
    //         'hh:mm:ss',
    //       )}Z`
    //     : undefined,
    //   toDate: appliedFilters?.toDate
    //     ? `${moment(appliedFilters?.toDate).format('YYYY-MM-DD')}T${moment(appliedFilters?.toDate).format('hh:mm:ss')}Z`
    //     : undefined,
    //   tagFilter: appliedFilters?.tags,
    //   contributionFilter: appliedFilters?.contributions,
    // };
    const type = 'leads';
    ContactsServices.exportContacts(type)
      .then(res => {
        downloadFile(res, 'Leads List.csv');
      })
      .catch(err => {})
      .finally(() => {
        setIsExporting(false);
      });
  };

  const handleImportClick = () => {
    setIsImportFileModalOpen(true);
  };

  const handleAddLeadClick = () => {
    setIsAddLeadModalOpen(true);
  };

  const handleDeleteAll = () => {
    setIsDeleteContactsModalOpen(true);
  };
  const handleAddTag = () => {
    setIsAddTagModalOpen(true);
  };

  const handleSendEmail = () => {
    const data = LSLeads?.filter(c => selectedLeads?.includes(c.id));
    dispatch(setSendEmailTo(data));
    history.push('/create-campaign/title');
    // setIsEmailModalOpen(true);
  };

  const handleSubmitFilter = values => {
    setAppliedFilters(values);
    setIsFilterApplied(true);
    setFilterPanelOpen(false);
  };

  const renderLeads = () => {
    console.log('PageCount--->', pageCount);
    return (
      <div className={classes.container}>
        {!isEmpty(campaignNotification) && <Notification notification={campaignNotification} />}
        {showTopBar && (
          <div className={classes.topBar}>
            <StyledHeading>Leads</StyledHeading>
            <Buttons
              page={PAGE_TYPES.Leads}
              exportClickHandler={handleExportClick}
              importClickHandler={handleImportClick}
              addLeadClickHandler={handleAddLeadClick}
              exportBtnClassName={isExporting && classes.export_btn_disabled}
              importBtnClassName={isImporting && classes.import_btn_disabled}
            />
          </div>
        )}
        <div className={classes.searchBar}>
          <div className={classes.searchBar__left}>
            <FormControlLabel
              style={{ fontFamily: 'Avenir', marginRight: '5px' }}
              checked={allSelected}
              onChange={() => {
                setAllSelected(!allSelected);
                if (allSelected === false) {
                  setSelectedLeads([...data?.map(c => c.id)]);
                  if (isCreateOrEditCampaignPage) {
                    setLeads([...data?.map(c => c.id)]);
                  }
                } else {
                  setSelectedLeads([]);
                  if (isCreateOrEditCampaignPage) {
                    setLeads([]);
                  }
                }
              }}
              control={<Checkbox color="primary" />}
              label={<StyledLabel>Select all</StyledLabel>}
              name="allSelect"
              disabled={false}
            />
            {selectedLeads?.length > 0 && !isCreateOrEditCampaignPage && (
              <button className={classes.deleteBtn} type="button" onClick={handleDeleteAll}>
                Delete
              </button>
            )}
            {selectedLeads?.length > 0 && !isCreateOrEditCampaignPage && (
              <button className={classes.addTagBtn} type="button" onClick={handleAddTag}>
                Add Tag
              </button>
            )}
            {/* {selectedLeads?.length > 0 && !isCreateOrEditCampaignPage && (
            <button className={classes.addTagBtn} type="button" onClick={handleAddTag}>
              Convert to Clients
            </button>
          )} */}
            {selectedLeads?.length > 0 && !isCreateOrEditCampaignPage && (
              <button className={classes.sendEmailBtn} type="button" onClick={handleSendEmail}>
                Send an Email
              </button>
            )}
          </div>
          <div className={classes.searchBar__right}>
            <div>
              <SearchBox
                placeholder="Search"
                background="white"
                onChange={handleSearch}
                searchIconColor="rgba(0, 0, 0, 0.87)"
                containerClassName={mobileView ? classes.searchBar_input : ''}
              />
            </div>
            {showFilter && (
              <ClickAwayListener
                onClickAway={() => {
                  setFilterPanelOpen(false);
                }}
              >
                <div style={{ position: 'relative' }}>
                  <button className={classes.filterBtn} type="button" onClick={handleFilterClick}>
                    <FilterIcon /> Filter
                  </button>
                  {!isEmpty(appliedFilters) && <FilterTag color="#215C73" />}
                  {filterPanelOpen && (
                    <FilterLeads
                      type={FilterTypes.Lead}
                      value={appliedFilters}
                      onCancelClick={() => {
                        setFilterPanelOpen(false);
                      }}
                      onSubmitClick={handleSubmitFilter}
                      onClear={() => {
                        setAppliedFilters({});
                        setIsFilterApplied(false);
                        setFilterPanelOpen(false);
                      }}
                      contacts={LSLeads}
                    />
                  )}
                </div>
              </ClickAwayListener>
            )}
          </div>
        </div>
        <div className={classes.leadsTable}>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <LeadsTable
                leads={getSearchedAndFilteredData(data, searchedKeyword?.toLocaleLowerCase(), appliedFilters)?.slice(
                  (currentPage - 1) * PER_PAGE_LEADS_COUNT,
                  currentPage * PER_PAGE_LEADS_COUNT,
                )}
                isChecked={isChecked}
                onCheckClicked={onCheckClicked}
                searchedKeyword={searchedKeyword}
                handleAddLeadClick={handleAddLeadClick}
              />
              {pageCount > 0 && (
                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                  <div style={{ fontSize: '13px', fontWeight: '400', position: 'relative', bottom: '-28px' }}>
                    {currentPage * PER_PAGE_LEADS_COUNT - PER_PAGE_LEADS_COUNT === 0
                      ? 1
                      : currentPage * PER_PAGE_LEADS_COUNT - PER_PAGE_LEADS_COUNT + 1}{' '}
                    -{' '}
                    {pageCount != currentPage
                      ? currentPage * PER_PAGE_LEADS_COUNT
                      : getSearchedAndFilteredData(data, searchedKeyword?.toLocaleLowerCase(), appliedFilters)
                          ?.length}{' '}
                    of {getSearchedAndFilteredData(data, searchedKeyword?.toLocaleLowerCase(), appliedFilters)?.length}
                  </div>

                  <PaginationBar
                    paginationClassName={!showTopBar && classes.pagination}
                    count={pageCount}
                    handleChange={(e, page) => {
                      setCurrentPage(page);
                    }}
                    page={currentPage}
                  />
                </div>
              )}
            </>
          )}
        </div>

        {isImportFileModalOpen && (
          <ImportFileContent
            isLeadPage={true}
            title="Import CSV file"
            isOpen={isImportFileModalOpen}
            onCancel={() => {
              setIsImportFileModalOpen(false);
            }}
          />
        )}

        {isAddLeadModalOpen && (
          <AddLeadModalForm
            isOpen={isAddLeadModalOpen}
            onCancel={() => {
              setIsAddLeadModalOpen(false);
            }}
          />
        )}

        <Modal
          isOpen={isDeleteContactsModalOpen}
          title="Delete Contact(s)"
          submitTitle="Delete"
          onSubmit={() => {
            setIsDeleting(true);
            ContactsServices.deleteContact([...selectedLeads])
              .then(res => {
                setSelectedLeads([]);
                setIsUnderstandChecked(false);
                setIsDeleteContactsModalOpen(false);
                dispatch(fetchAllContactsAsync({}));
              })
              .catch(err => {})
              .finally(() => {
                setIsDeleting(false);
              });
          }}
          onCancel={() => {
            setIsDeleteContactsModalOpen(false);
          }}
          reduceCancelButton
          disableSubmitClick={!isUnderstandChecked || isDeleting}
        >
          <div className={classes.delete_modal_main}>
            <div className={classes.top_heading}>
              Are you sure you want to delete this/these contact(s)? Your action cannot be undone.
            </div>
            <div className={classes.form_control_label_container}>
              <FormControlLabel
                checked={isUnderstandChecked}
                onChange={() => {
                  setIsUnderstandChecked(!isUnderstandChecked);
                }}
                control={<Checkbox className={classes.checkbox_root} disableRipple={true} color="primary" />}
                className={classes.form_control}
                label={
                  <div className={classes.form_label}>
                    Please note: This action will remove the contact from all lists and services. They will need to
                    re-enroll to be added back to your contacts.
                  </div>
                }
                name="understand"
                disabled={false}
              />
            </div>
            <div>
              <div className={classes.contact_list_heading}>Following contact(s) will be deleted:</div>
              <div className={classes.contact_list}>
                {LSLeads?.filter(l => selectedLeads.includes(l.id))?.map((item, index) => {
                  const isLastIndex = index === selectedLeads?.length - 1;
                  return isLastIndex ? (
                    <div>{`${item?.firstName} ${item?.lastName}`}</div>
                  ) : (
                    <div>{`${item?.firstName} ${item?.lastName}, `}</div>
                  );
                })}
              </div>
            </div>
          </div>
        </Modal>

        {isAddTagModalOpen && (
          <AddContactTagModal
            contacts={LSLeads?.filter(lead => selectedLeads.includes(lead.id))}
            contactsList={LSLeads.map(c => ({ title: `${c.firstName} ${c.lastName}`, value: c.id }))}
            isOpen={isAddTagModalOpen}
            onClose={() => {
              setSelectedLeads([]);
              setIsAddTagModalOpen(false);
            }}
          />
        )}

        <Modal
          title="Send a One-Time Email"
          isOpen={isEmailModalOpen}
          onCancel={() => {
            setIsEmailModalOpen(false);
          }}
          onSubmit={() => {
            setIsEmailModalOpen(false);
          }}
          submitTitle="Send"
          reduceCancelButton
          widthRequired
        >
          <div className={classes.email_modal_main}>
            <div>
              <div className={classes.label}>Send a One-Time Email</div>
              <div className={classes.select_container}>
                <Select
                  className={classes.select}
                  value={['All']}
                  onChange={() => {}}
                  multiple
                  items={[{ title: 'All', value: 'All' }]}
                />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  };
  return (
    <>
      {isCreateOrEditCampaignPage ? (
        renderLeads()
      ) : (
        <MainContainer>
          <StyledMainSection mobileView={mobileView}>{renderLeads()}</StyledMainSection>
        </MainContainer>
      )}
    </>
  );
};

export default AllLeads;
