export const CHANGE_BOOKING_DAY = 'CHANGE_BOOKING_DAY';
export const SET_START_TIME = 'SET_START_TIME';
export const ADD_BOOKING_TIME = 'ADD_BOOKING_TIME';
export const REMOVE_BOOKING_TIME = 'REMOVE_BOOKING_TIME';
export const SET_START_DATE = 'SET_START_DATE';
export const SET_BLOCKED_DAYS = 'SET_BLOCKED_DAYS';
export const SET_END_DATE = 'SET_END_DATE';
export const SET_DURATION = 'SET_DURATION';
export const UPDATE_ONE_TO_ONE_DATA = 'UPDATE_ONE_TO_ONE_DATA';
export const SET_SESSION_DURATION = 'SET_SESSION_DURATION';
export const SET_SLOTS = 'SET_SLOTS';
export const SET_SESSION_DURATION_ERROR = 'SET_SESSION_DURATION_ERROR';
export const SET_START_TIME_INCREMENT_DURATION = 'SET_START_TIME_INCREMENT_DURATION';
export const SET_START_TIME_INCREMENT_DURATION_ERROR = 'SET_START_TIME_INCREMENT_DURATION_ERROR';
export const SET_DEFAULT_WEEKLY_ONE_ON_ONE_DATA = 'SET_DEFAULT_WEEKLY_ONE_ON_ONE_DATA';
export const SET_ONE_ON_ONE_DATA_MASTER_CAL = 'SET_ONE_ON_ONE_DATA_MASTER_CAL';

export const setSelectedWeeks = (index, data) => ({
  type: CHANGE_BOOKING_DAY,
  payload: { index, data },
});

export const setOneToOneTime = (index, data, timeType) => ({
  type: SET_START_TIME,
  payload: { index, data, timeType },
});

export const addBookingTime = addedTime => ({
  type: ADD_BOOKING_TIME,
  payload: addedTime,
});

export const setDefaultWeeklyData = addedTime => ({
  type: SET_DEFAULT_WEEKLY_ONE_ON_ONE_DATA,
  payload: addedTime,
});

export const setStartDate = data => ({
  type: SET_START_DATE,
  payload: data,
});

export const setBlockedDates = data => ({
  type: SET_BLOCKED_DAYS,
  payload: data,
});

export const setEndDate = data => ({
  type: SET_END_DATE,
  payload: data,
});

export const setDuration = data => ({
  type: SET_DURATION,
  payload: data,
});

export const removeBookingTime = index => ({
  type: REMOVE_BOOKING_TIME,
  payload: index,
});

export const setSessionDuration = time => ({
  type: SET_SESSION_DURATION,
  payload: time,
});

export const setSessionDurationError = text => ({
  type: SET_SESSION_DURATION_ERROR,
  payload: text,
});

export const setStartTimeIncrementDuration = time => ({
  type: SET_START_TIME_INCREMENT_DURATION,
  payload: time,
});

export const setStartTimeIncrementDurationError = text => ({
  type: SET_START_TIME_INCREMENT_DURATION_ERROR,
  payload: text,
});

export const updateOneToOneData = data => ({
  type: UPDATE_ONE_TO_ONE_DATA,
  payload: data,
});

export const setSlots = data => ({
  type: SET_SLOTS,
  payload: data,
});

export const setOneOnOneDataMasterCalendar = data => ({
  type: SET_ONE_ON_ONE_DATA_MASTER_CAL,
  payload: data,
});
