import React from 'react';

const CommunityAllPostsSvg = ({ width = '37px', height = '37px', color = '#C9B382' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1231_1726)">
        <path
          d="M36.0004 7.1807V33.7564C35.9844 33.7874 35.9628 33.8175 35.9544 33.8503C35.4951 35.6433 34.3952 36.498 32.5421 36.498C22.8182 36.498 13.0934 36.498 3.36943 36.498C1.34162 36.498 0.00415039 35.1596 0.00415039 33.1281C0.00415039 24.6101 0.00415039 16.0931 0.00415039 7.57518V7.1807H36.0004ZM18.0384 10.1337C14.5247 10.1337 11.0111 10.1337 7.49644 10.1337C6.66991 10.1337 6.26134 10.5375 6.26134 11.3565C6.26134 16.4867 6.26134 21.6168 6.26134 26.7478C6.26134 27.5913 6.66428 27.9914 7.51147 27.9914C14.5041 27.9914 21.4967 27.9914 28.4893 27.9914C29.3384 27.9914 29.7423 27.5913 29.7423 26.7506C29.7423 21.6318 29.7423 16.5139 29.7423 11.3951C29.7423 10.5281 29.3459 10.1346 28.4733 10.1346C24.9944 10.1346 21.5155 10.1346 18.0375 10.1346L18.0384 10.1337ZM8.45634 31.1378C8.2807 30.9378 8.18302 30.8053 8.06374 30.6964C7.61291 30.2812 6.99019 30.2803 6.57693 30.686C6.16366 31.0918 6.14864 31.7267 6.56472 32.171C6.9329 32.5636 7.31423 32.9449 7.70683 33.3131C8.1417 33.7207 8.7259 33.7254 9.16453 33.3197C9.56746 32.9458 9.95912 32.557 10.3311 32.1522C10.7321 31.7154 10.7096 31.0937 10.3104 30.6936C9.90182 30.284 9.27254 30.2775 8.82452 30.6907C8.70524 30.8006 8.61507 30.9424 8.4554 31.1387L8.45634 31.1378ZM15.1925 33.0529C15.5551 33.0529 15.9167 33.0585 16.2792 33.052C16.9038 33.0398 17.3641 32.5936 17.3688 32.0047C17.3725 31.4168 16.9114 30.9537 16.2924 30.9462C15.556 30.9368 14.8197 30.9368 14.0842 30.9462C13.469 30.9547 13.006 31.4233 13.0126 32.0094C13.0192 32.5814 13.4737 33.0351 14.0711 33.051C14.4449 33.0614 14.8187 33.0529 15.1925 33.0529ZM21.9381 30.9443C21.5756 30.9443 21.214 30.9387 20.8514 30.9453C20.2297 30.9565 19.7657 31.4055 19.761 31.9906C19.7572 32.5777 20.2184 33.0426 20.8364 33.0501C21.5728 33.0595 22.3091 33.0604 23.0446 33.0501C23.6635 33.0416 24.1228 32.5767 24.1172 31.9878C24.1115 31.3999 23.6494 30.9556 23.0248 30.9453C22.6623 30.9387 22.3007 30.9443 21.9381 30.9443Z"
          fill={color}
        />
        <path
          d="M36.0004 3.24342V5.07117H0.00509089C0.00509089 4.55835 -0.00805842 4.08122 0.0079086 3.60408C0.0407819 2.58407 0.443714 1.72373 1.28903 1.14986C1.72202 0.855877 2.25644 0.713114 2.74578 0.501785C12.9168 0.501785 23.0878 0.501785 33.2588 0.501785C33.3245 0.523388 33.3884 0.551565 33.456 0.566593C34.5399 0.80328 35.3035 1.43539 35.7487 2.44601C35.8604 2.6996 35.9187 2.97761 36.0013 3.24436L36.0004 3.24342Z"
          fill={color}
        />
        <path
          d="M27.6235 12.2657V17.9641C27.4948 17.8194 27.415 17.7293 27.3352 17.6391C26.5809 16.79 25.8267 15.94 25.0707 15.0928C24.5315 14.4889 23.9154 14.4701 23.3406 15.044C21.6415 16.7403 19.9443 18.4384 18.249 20.1375C18.1588 20.2286 18.0912 20.3413 18.0048 20.454C17.8695 20.345 17.7869 20.2821 17.7089 20.2145C16.5978 19.2433 15.4876 18.2721 14.3774 17.3C13.7322 16.7346 13.2513 16.7384 12.623 17.3113C11.2291 18.5793 9.8353 19.8472 8.40015 21.1518V12.2657H27.6235Z"
          fill={color}
        />
        <path
          d="M24.0985 17.1732C25.2875 18.5107 26.409 19.7702 27.5257 21.0335C27.5821 21.0974 27.6281 21.196 27.629 21.2796C27.6356 22.8002 27.6337 24.3208 27.6337 25.8621H8.37094C8.37094 25.2798 8.36624 24.7087 8.37657 24.1386C8.37845 24.0607 8.4442 23.9677 8.50619 23.9104C10.1423 22.4189 11.7813 20.9302 13.4212 19.4424C13.4466 19.4199 13.4794 19.4058 13.5367 19.3701C14.2881 20.0266 15.0442 20.6869 15.7994 21.3472C16.2577 21.7483 16.7151 22.1503 17.1735 22.5513C17.7718 23.0745 18.3052 23.0623 18.8707 22.4987C20.5378 20.8363 22.2021 19.1701 23.8646 17.5029C23.9538 17.4137 24.0139 17.2944 24.0975 17.1732H24.0985Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1231_1726">
          <rect width="36" height="35.9962" fill="white" transform="translate(0 0.501877)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CommunityAllPostsSvg;
