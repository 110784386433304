import React from 'react';
import { Link } from 'react-router-dom';

import { StyledTitleForVideo, StyledVideo, StyledButton } from '../GettingStarted.styles';
import { ROUTES } from 'constants.js';

const Services = ({ mobileView }) => {
  return (
    <>
      <StyledVideo controls="controls" preload="metadata">
        <source
          src={`https://coherepublic-prod.s3.amazonaws.com/DemoVideos/COHERE+CREATE+YOUR+FIRST+SERVICE+(VIDEO+3_4).mp4`}
        />
      </StyledVideo>

      <StyledTitleForVideo mobileView={mobileView}>
        Once you’ve completed the verification step, you can start creating and launching services on Cohere.
      </StyledTitleForVideo>
      <StyledTitleForVideo mobileView={mobileView}>On Cohere, services are called Services.</StyledTitleForVideo>

      <Link to={ROUTES.CREATE_CONTRIBUTION_BASIC} target="_blank">
        <StyledButton variant="primary">Create a Service</StyledButton>
      </Link>
    </>
  );
};

export default Services;
