import PropTypes from 'prop-types';
import React from 'react';

function CommunityHeader({ children }) {
  return <>{children}</>;
}

CommunityHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CommunityHeader;
