import { Chip, ClickAwayListener, Popper, TextField } from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import NoImage from 'assets/NoImage.png';
import * as R from 'ramda';
import { formatTime } from 'utils/utils';

const mergeUniqueObjectsById = (array1, array2) => {
  return R.unionWith((a, b) => a.id === b.id, array1, array2);
};

const StyledAutocomplete = styled(Autocomplete)`
  .MuiAutocomplete-inputRoot {
    padding-right: 20px !important;
  }
  .MuiAutocomplete-endAdornment {
    top: 0px;
  }
  svg {
    color: rgba(0, 0, 0, 0.54);
  }
`;

const StyledPopper = styled(Popper)`
  z-index: 1400 !important;
`;

const CustomTagDisplay = ({ option, handleTagDelete, isDarkThemeApplied, numOfTags, mobileView }) => {
  const maxLength = 25;
  const displayText =
    option?.label?.length > maxLength ? option?.label?.substring(0, maxLength) + '...' : option?.label;
  const chipStyle = {
    color: 'white',
    border: '1px solid white',
    paddingRight: '6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  return (
    <Chip
      label={numOfTags > 1 ? displayText : option?.label}
      onDelete={e => {
        e.stopPropagation();
        handleTagDelete(option);
      }}
      style={isDarkThemeApplied ? chipStyle : { fontFamily: 'Avenir', marginBottom: '3px', marginRight: '3px' }}
    />
  );
};

function CommunitiesAutoComplete({ items, setFieldValue, mobileView, values, ...rest }) {
  const dropdownRef = useRef(null);
  const [contributionLabel, setContributionLabel] = useState('initial');
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [selectedCommunities, setSelectedCommunities] = useState([]);
  const [tagsNumber, setTagsNumber] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = React.useRef(null);

  const handleVisibleTags = () => {
    if (!containerRef.current) return;
    setContainerWidth(containerRef.current.offsetWidth);
  };

  useEffect(() => {
    handleVisibleTags();
  }, [selectedCommunities]);

  const handleOutsideClick = e => {
    if (!isDropdownVisible || !dropdownRef?.current) {
      return;
    }
    if (
      !e?.target?.className?.includes('dropdownList') &&
      !dropdownRef?.current?.contains(e.target) &&
      !e?.target?.className?.includes('MuiAutocomplete-option')
    ) {
      setTimeout(() => {
        setIsDropdownVisible(false);
      }, 300);
    }
  };

  const handleTagDelete = optionToDelete => {
    const updatedCommunities = selectedCommunities.filter(community => community.id !== optionToDelete.id);
    setSelectedCommunities(updatedCommunities);
    setFieldValue('contributions', updatedCommunities);
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleOutsideClick}>
        <StyledAutocomplete
          disableCloseOnSelect
          id="select-contribution-self-book"
          multiple
          {...rest}
          name="contribution-social"
          fullWidth
          ref={dropdownRef}
          style={{
            fontFamily: 'Avenir',
            whiteSpace: 'break-spaces',
            border: '1px solid #DFE3E4',
            borderRadius: '4px',
            borderBottom: '1px solid #DFE3E4',
            height: '40px',
            width: 'unset',
            padding: '4px 4px 4px 8px',
          }}
          clearIcon={false}
          value={selectedCommunities}
          defaultValue={[]}
          open={isDropdownVisible}
          PopperComponent={StyledPopper}
          options={items}
          noOptionsText={
            <p
              className="dropdownList"
              style={{
                margin: '5px',
                color: 'rgba(0, 0, 0, 0.87)',
                fontSize: '0.875rem',
                fontFamily: 'Avenir',
                letterSpacing: '0.4px',
              }}
            >
              No Service Found
            </p>
          }
          renderOption={(props, option, { selected }) => (
            <div
              key={option.id}
              className="dropdownList"
              onKeyDown={() => {}}
              role="button"
              tabIndex="0"
              style={{
                borderTop: '1px solid #f2f2f2',
                cursor: 'pointer',
                backgroundColor: selectedCommunities.some(comm => comm.id === option?.id) ? '#fafafa' : 'white',
              }}
              {...props}
              onClick={(event: any) => {
                let values = [...selectedCommunities, option];

                if (values.length === 0) {
                  setFieldValue('contributions', []);
                  setSelectedCommunities([]);
                } else {
                  if (values.length > 1 && values.some(val => val.id === 'All')) {
                    const firstElement = values[0];

                    if (firstElement?.id === 'All') {
                      values = values.filter(val => val?.id !== 'All');
                    } else if (option.id === 'All') {
                      values = values.filter(val => val?.id === 'All');
                    } else {
                      values = values;
                    }
                  }

                  const isSelected = selectedCommunities.some(comm => comm.id === option.id);
                  let updatedSelection;

                  if (isSelected && option.id !== 'All') {
                    updatedSelection = selectedCommunities.filter(comm => comm.id !== option.id);
                  } else {
                    updatedSelection = mergeUniqueObjectsById(selectedCommunities, values);
                  }

                  setFieldValue('contributions', updatedSelection);
                  setSelectedCommunities(updatedSelection);
                }
              }}
            >
              <div
                className="dropdownList"
                style={{
                  margin: '8px',
                  color: '#2B2B2B',
                  fontSize: '16px',
                  lineHeight: '20px',
                  wordWrap: 'break-word',
                  fontFamily: 'Avenir',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img
                  style={{
                    width: '87px',
                    height: '48px',
                    marginRight: '8px',
                    borderRadius: '2px',
                    border: '1px',
                  }}
                  src={option?.image || NoImage}
                  alt={option?.label}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4px',
                    fontWeight: selectedCommunities.some(comm => comm.id === option?.id) ? '800' : '400',
                  }}
                >
                  <span
                    title={option?.label}
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      width: mobileView ? '65%' : '100%',
                    }}
                  >
                    {option?.label}
                  </span>
                  <span
                    title={
                      (option?.isJoinedCommunity ? 'Joined on: ' : 'Created on: ') +
                      formatTime(option?.isJoinedCommunity ? option.contributionJoinedDate : option.createTime)
                    }
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      width: mobileView ? '65%' : '100%',
                      fontSize: '12px',
                      opacity: '60%',
                    }}
                  >
                    {(option?.isJoinedCommunity ? 'Joined on: ' : 'Created on: ') +
                      formatTime(option?.isJoinedCommunity ? option.contributionJoinedDate : option.createTime)}
                  </span>
                </div>
              </div>
            </div>
          )}
          renderTags={(value, getTagProps) => {
            const numOfTags = value.length;
            setTagsNumber(numOfTags);
            const limitTags = mobileView ? 1 : 2;

            return (
              <>
                {value.slice(0, limitTags).map((option, index) => (
                  <CustomTagDisplay
                    key={option.id}
                    option={option}
                    mobileView={mobileView}
                    numOfTags={numOfTags}
                    handleTagDelete={option => handleTagDelete(option)}
                    {...getTagProps({ index })}
                  />
                ))}
                <div>{numOfTags > limitTags && ` +${numOfTags - limitTags}`}</div>
              </>
            );
          }}
          label="Service"
          renderInput={params => (
            <TextField
              {...params}
              className="contributionLabelText"
              required
              variant="standard"
              onClick={e => {
                const isArrowIconClicked =
                  !e.target.className.length || !e.target.className.includes('MuiInputBase-input');
                if (isArrowIconClicked) {
                  setIsDropdownVisible(!isDropdownVisible);
                } else {
                  if (!isDropdownVisible) {
                    setIsDropdownVisible(true);
                  }
                }
              }}
              onChange={e => {
                setIsDropdownVisible(false);
                setTimeout(() => {
                  setIsDropdownVisible(true);
                }, 300);
              }}
              onKeyDown={(event: any) => {
                if (event.key === 'Backspace') {
                  event.stopPropagation();
                }
              }}
              placeholder={selectedCommunities?.length === 0 ? 'Select communities' : ''}
              value={contributionLabel}
              style={{ margin: '0px' }}
            />
          )}
        />
      </ClickAwayListener>
    </>
  );
}

export default CommunitiesAutoComplete;
