const SECTIONS = [
  {
    name: 'Finish Getting Set Up',
    desc: 'Complete your account set up and first service.',
    link: 'https://www.cohere.live/how-to-library/#getsetup',
  },
  {
    name: 'Getting Verified',
    desc: 'Cohere verification and payment information.',
    link: 'https://www.cohere.live/how-to-library/#getverified',
  },
  {
    name: 'Editing Services',
    desc: 'Easily edit your services at anytime.',
    link: 'https://www.cohere.live/how-to-library/#editservices',
  },
  {
    name: 'Onboarding Clients',
    desc: 'Welcome your existing clients to the platform easily.',
    link: 'https://www.cohere.live/how-to-library/#onboardclients',
  },
  {
    name: 'Sharing Services',
    desc: 'What do the landing pages look like for my clients once they’re completed?',
    link: 'https://www.cohere.live/how-to-library/#sharingservices',
  },
  {
    name: 'Types of Services',
    desc: 'Coaches, teachers, healers, educators – this is for you!',
    link: 'https://www.cohere.live/how-to-library/#typesofservices',
  },
  {
    name: 'Recurring Revenue Types',
    desc: 'Recurring revenue for 1:1s',
    link: 'https://www.cohere.live/how-to-library/#recurring1:1s',
  },
  {
    name: 'Discount Codes',
    desc: 'Create discount codes for your clients to use on Cohere platform.',
    link: 'https://www.cohere.live/how-to-library/#discountcodes',
  },
  {
    name: 'Archiving & Completing Sessions',
    desc: 'Learn how to archive and complete sessions.',
    link: 'https://www.cohere.live/how-to-library/#archive',
  },
  {
    name: 'Zoom Connection',
    desc:
      'Learn how to connect your Zoom account to Cohere and which features are supported. Uninstall instructions are also provided.',
    link: 'https://www.cohere.live/how-to-library/#zoomconnection',
  },
  {
    name: 'Affiliate Links',
    desc: 'Create your affiliate link and share with others!',
    link: 'https://www.cohere.live/how-to-library/#affiliate-video',
  },
  {
    name: 'Sales Information',
    desc: '',
    link: 'https://www.cohere.live/how-to-library/#sales-information-video',
  },
  {
    name: 'Transfer Revenue',
    desc: '',
    link: 'https://www.cohere.live/how-to-library/#transfer-revenue-video',
  },
  {
    name: 'Add Team Member',
    desc: '',
    link: 'https://www.cohere.live/how-to-library/#add-team-member-video',
  },
  {
    name: 'Client Dashboard',
    desc: '',
    link: 'https://www.cohere.live/how-to-library/#client-dashboard-video',
  },
  {
    name: '1:1 Booking Availability',
    desc: '',
    link: 'https://www.cohere.live/how-to-library/#one-one-booking-video',
  },
  {
    name: 'Connect Calendar/s',
    desc: '',
    link: 'https://www.cohere.live/how-to-library/#connect-calendar-video',
  },
  {
    name: 'Create Services',
    desc: 'i. Group Service',
    link: 'https://www.cohere.live/how-to-library/#group-contribution',
  },
];

export default SECTIONS;
