import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useChatClientChannels, useChatClientChannelMembers, ChatClientChannelConnectionStatus } from 'hooks';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { LabelText } from 'components/UI/Text/TextStyles';
import { getThemedColors } from 'services/contributions.service';
import { determineDarkThemedColorToUse, determineColorToUse } from 'services/contributions.service';
import ChatsList from './ChatsList';

import './ChatsList.scss';

const ChatsListContainer = ({ channelIds, onChannelClick, channelLinkProvider, isContributionPage }) => {
  const [channelsReload, setChannelsReload] = useState(false);
  const {
    connectionStatus,
    channels,
    channelsForContribution,
    hasMoreChannels,
    isLoadingMoreChannels,
    loadMoreChannels,
  } = useChatClientChannels(channelIds, true, channelsReload);

  const contribution = useContribution();
  const colorToUse = determineColorToUse(contribution);

  const onLoadMoreChannels = useCallback(() => {
    if (hasMoreChannels && !isLoadingMoreChannels) {
      loadMoreChannels();
    }
  }, [connectionStatus]);
  const { themedColor } = getThemedColors(contribution);

  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);
  const reload = () => window.location.reload();
  return (
    <div className={isDarkThemeEnabled ? 'chats-list-container-dark' : 'chats-list-container'}>
      {(connectionStatus === ChatClientChannelConnectionStatus.pending ||
        connectionStatus === ChatClientChannelConnectionStatus.connecting) && <CircularProgress />}
      {connectionStatus === ChatClientChannelConnectionStatus.connectionError && (
        <LabelText style={{ color: themedColor, display: 'flex' }}>
          <div
            onClick={reload}
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
              fontFamily: 'Avenir',
              fontStyle: 'normal',
              fontWeight: '500',
              color: colorToUse?.AccentColorCode,
            }}
          >
            Click here to reload chat
          </div>
        </LabelText>
      )}
      {connectionStatus === ChatClientChannelConnectionStatus.connected && (
        <ChatsList
          channels={isContributionPage ? channelsForContribution : channels}
          onChannelClick={onChannelClick}
          channelLinkProvider={channelLinkProvider}
          isLoadingMoreChannels={isLoadingMoreChannels}
          onLoadMoreChannels={onLoadMoreChannels}
        />
      )}
    </div>
  );
};

ChatsListContainer.propTypes = {
  channelIds: PropTypes.arrayOf(PropTypes.string),
  onChannelClick: PropTypes.func,
  channelLinkProvider: PropTypes.func,
  isContributionPage: PropTypes.bool,
};

ChatsListContainer.defaultProps = {
  channelIds: null,
  onChannelClick: null,
  channelLinkProvider: null,
  isContributionPage: false,
};

export default ChatsListContainer;
