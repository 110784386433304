import React from 'react';

const CommunityLikeFilledSvg = ({ width = '24px', height = '24px', color = '#215C73' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 17" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.73776 1.48829C10.8505 0.492856 12.3021 -0.0388038 13.7945 0.00248117C15.2869 0.0437662 16.7069 0.654863 17.7629 1.71029C18.8177 2.76498 19.4291 4.1832 19.4719 5.67424C19.5146 7.16528 18.9853 8.61619 17.9926 9.72954L9.73582 17.998L1.48094 9.72954C0.48706 8.61561 -0.0426743 7.16346 0.000492064 5.67122C0.0436584 4.17898 0.65645 2.75988 1.71306 1.70526C2.76967 0.650642 4.18993 0.0405313 5.68225 0.000183178C7.17456 -0.0401649 8.62571 0.492311 9.73776 1.48829Z"
        fill={color}
      />
    </svg>
  );
};

export default CommunityLikeFilledSvg;
