import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { formatTimeWithComma } from 'utils/utils';
import * as calendarsActions from 'actions/calendars';
import { MenuItem, Select, useTheme, useMediaQuery } from '@material-ui/core';
import { useRouter } from 'hooks';
const viewTabButtonsData = ['Month', 'Week', 'Day'];

const DateNavigationComponent = ({ toolbar, calendarActiveView, isShortLabel = false, setShowDropDown = () => {} }) => {
  const { pathname } = useRouter();
  const { manageAvailabilitySelection, selectedMasterCalendarContribution } = useSelector(
    state => state.masterCalendar,
  );
  const masterCalendarArray = useSelector(state => state?.contributions?.masterCalendarArray);
  const [activeButton, setActiveButton] = useState(calendarActiveView);
  const [currentCalendarView, setCurrentCalendarView] = useState('');
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const smView = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const goToBack = () => {
    setShowDropDown(false);
    if (toolbar?.view === 'day') {
      toolbar.date.setDate(toolbar.date.getDate() - 1);
    } else if (toolbar?.view === 'week') {
      toolbar.date.setDate(toolbar.date.getDate() - 7);
    } else {
      toolbar.date.setMonth(toolbar.date.getMonth() - 1);
    }
    toolbar.onNavigate('prev');
  };

  const goToNext = () => {
    setShowDropDown(false);
    if (toolbar?.view === 'day') {
      toolbar.date.setDate(toolbar.date.getDate() + 1);
    } else if (toolbar?.view === 'week') {
      toolbar.date.setDate(toolbar.date.getDate() + 7);
    } else {
      toolbar.date.setMonth(toolbar.date.getMonth() + 1);
    }
    toolbar.onNavigate('next');
  };

  const goToCurrent = () => {
    setShowDropDown(false);
    const now = new Date();
    toolbar.date.setMonth(now.getMonth());
    toolbar.date.setYear(now.getFullYear());
    toolbar.date.setDate(now.getDate());
    toolbar.onNavigate('current');
  };

  const forwardSvg = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        style={{ width: '20px', height: '20px' }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.38287 14.773C7.1954 14.5855 7.09009 14.3312 7.09009 14.066C7.09009 13.8009 7.1954 13.5465 7.38287 13.359L10.6759 10.066L7.38287 6.77302C7.20072 6.58442 7.09992 6.33182 7.1022 6.06962C7.10448 5.80742 7.20965 5.55661 7.39506 5.3712C7.58046 5.18579 7.83128 5.08062 8.09347 5.07835C8.35567 5.07607 8.60827 5.17686 8.79687 5.35902L12.7969 9.35902C12.9843 9.54655 13.0897 9.80086 13.0897 10.066C13.0897 10.3312 12.9843 10.5855 12.7969 10.773L8.79687 14.773C8.60935 14.9605 8.35504 15.0658 8.08987 15.0658C7.82471 15.0658 7.5704 14.9605 7.38287 14.773Z"
          fill="#18181B"
        />
      </svg>
    );
  };

  const backwardSvg = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        style={{ width: '20px', height: '20px' }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.7969 5.35907C12.9843 5.5466 13.0897 5.80091 13.0897 6.06607C13.0897 6.33123 12.9843 6.58554 12.7969 6.77307L9.50387 10.0661L12.7969 13.3591C12.979 13.5477 13.0798 13.8003 13.0775 14.0625C13.0753 14.3247 12.9701 14.5755 12.7847 14.7609C12.5993 14.9463 12.3485 15.0515 12.0863 15.0537C11.8241 15.056 11.5715 14.9552 11.3829 14.7731L7.38287 10.7731C7.1954 10.5855 7.09009 10.3312 7.09009 10.0661C7.09009 9.80091 7.1954 9.5466 7.38287 9.35907L11.3829 5.35907C11.5704 5.1716 11.8247 5.06628 12.0899 5.06628C12.355 5.06628 12.6093 5.1716 12.7969 5.35907Z"
          fill="#18181B"
        />
      </svg>
    );
  };

  useEffect(() => {
    toolbar.onView(calendarActiveView.toLowerCase());
    setActiveButton(calendarActiveView);
    setCurrentCalendarView(calendarActiveView);
  }, [calendarActiveView]);

  return (
    <>
      {pathname.includes('contribution-view/') && mobileView ? (
        <div className="custom-calender-dropdown">
          <div>
            <div className="master-calender-toolbar-container">
              <button type="button" className="btn-back" onClick={goToBack}>
                {backwardSvg()}
              </button>
              <button type="button" className="btn-current">
                {toolbar?.label}
              </button>
              <button type="button" className="btn-next" onClick={goToNext}>
                {forwardSvg()}
              </button>
              <div
                className="current-date-status"
                role="button"
                tabIndex={0}
                onClick={goToCurrent}
                onKeyPress={goToCurrent}
              >
                <p style={{ margin: 'unset' }}>Today</p>
              </div>
            </div>
          </div>
          <Select
            variant="outlined"
            displayEmpty
            className="calender-current-view-select"
            value={currentCalendarView}
            MenuProps={{
              PaperProps: {
                style: { fontFamily: 'Avenir' },
              },
            }}
            onChange={() => {}}
          >
            {viewTabButtonsData.map(value => (
              <MenuItem
                value={value}
                onClick={() => {
                  setCurrentCalendarView(value);
                  setShowDropDown(false);
                  dispatch(calendarsActions.setCurrentCalendarView.request(value));
                }}
              >
                {value}
              </MenuItem>
            ))}
          </Select>
        </div>
      ) : (
        <>
          <div>
            <div className="master-calender-toolbar-container">
              <button type="button" className="btn-back" onClick={goToBack}>
                {backwardSvg()}
              </button>
              <button type="button" className="btn-current">
                {isShortLabel ? toolbar?.label?.slice(0, 3) : toolbar?.label}
              </button>
              <button type="button" className="btn-next" onClick={goToNext}>
                {forwardSvg()}
              </button>
              <div
                className="current-date-status"
                role="button"
                tabIndex={0}
                onClick={goToCurrent}
                onKeyPress={goToCurrent}
              >
                <p style={{ margin: 'unset' }}>Today</p>
              </div>
            </div>
          </div>
          {!mobileView && manageAvailabilitySelection === 'booking-availability' && (
            <div
              style={{
                fontFamily: 'Avenir',
                fontSize: '24px',
                fontWeight: '400',
              }}
            >
              {masterCalendarArray[0]?.label}
            </div>
          )}
          <div className="calendar-view-tab-container">
            {viewTabButtonsData.map((button, index) => (
              <button
                type="button"
                key={button}
                className={
                  activeButton.toLowerCase() === button.toLowerCase()
                    ? 'toolbar-view-tab-button active'
                    : 'toolbar-view-tab-button'
                }
                onClick={() => {
                  setShowDropDown(false);
                  setActiveButton(button);
                  toolbar.onView(toolbar.views[index]);
                  dispatch(calendarsActions.setCurrentCalendarView.request(button));
                }}
              >
                {button}
              </button>
            ))}
          </div>
        </>
      )}
    </>
  );
};

DateNavigationComponent.propTypes = {};
export default DateNavigationComponent;
