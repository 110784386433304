import React, { useCallback, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import AddBankAccountPopup from '../../../pages/Account/Payment/components/BankAccountCard/AddBankAccountPopup';
import { useHttp, useAccount } from 'hooks';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { redirectTo } from 'services/links';
import * as userActions from 'actions/user';

import Button from 'components/FormUI/Button';

import styled from 'styled-components';

import '../Banner.scss';
import BankInfoPlaid from './BankInfoPlaid';

const StyledButton = styled(Button)`
  background-color: #0e536b;
  padding: 6px 14px;
  min-width: 95px;
  width: 95px;
  font-size: 12px;
  font-weight: 700;
`;

const BankInfo = ({ userId, getUserProfile }) => {
  const { request } = useHttp();
  const { user } = useAccount();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const BannerButton = mobileView ? StyledButton : Button;
  const [isClick, setIsClick] = useState(false);
  const [ready, setReady] = useState(true);
  const dispatch = useDispatch();
  let opensFunc = () => null;

  const getUser = useCallback(() => {
    if (userId) {
      getUserProfile(userId);
    }
  }, [userId, getUserProfile]);

  const onVerificate = useCallback(() => {
    request('api/Payment/generate-account-verification-link', 'POST').then(({ link }) => {
      redirectTo(link);
    });
  }, [request]);
  const handleGetOpen = opens => {
    opensFunc = opens;
    opens();
  };
  const handleGetReady = ready => {
    setReady(ready);
  };
  const checkOnClick = () => {
    if (!user.transfersEnabled) {
      onVerificate();
    } else {
      redirectTo('/account/payment');
      // opensFunc();
      // setIsClick(true);
    }
  };

  const [addStripeBankAccountDataPopup, setAddStripeBankAccountDataPopup] = useState(false);
  return (
    (!user.transfersEnabled || !user.payoutsEnabled) && (
      <>
        <div className={`banner-container ${mobileView && 'banner-container__mobile'}`}>
          <p className="banner-container__text">
            {!user.transfersEnabled
              ? 'Verify your information to create a service and collect revenue.'
              : 'Connect your bank account to transfer earned revenue.'}
          </p>
          <div>
            <BannerButton
              disabled={!user.transfersEnabled && !ready}
              variant="primary"
              className="banner-container__button"
              autoWidth
              onClick={checkOnClick}
            >
              {!user.transfersEnabled ? 'Verify' : 'Connect'}
              {isClick && (
                <BankInfoPlaid
                  userId={userId}
                  getUserProfile={getUserProfile}
                  getOpen={handleGetOpen}
                  getReady={handleGetReady}
                />
              )}
            </BannerButton>
          </div>
        </div>
        <AddBankAccountPopup
          addStripeBankAccountDataPopup={addStripeBankAccountDataPopup}
          onCancel={() => {
            setAddStripeBankAccountDataPopup(false);
            getUser();
            userActions.getProfile(user.id, dispatch);
          }}
        />
      </>
    )
  );
};

BankInfo.propTypes = {
  userId: PropTypes.string,
  getUserProfile: PropTypes.func.isRequired,
};

BankInfo.defaultProps = {
  userId: null,
};

const mapStateToProps = ({ account }) => ({
  userId: account?.user?.id,
});

const actions = {
  getUserProfile: userActions.getProfile,
};

export default connect(mapStateToProps, actions)(BankInfo);
