import { IconGoogle } from 'components/Icons/services/IconGoogle';
import { IconOutlook } from 'components/Icons/services/IconOutlook';
import { IconYahoo } from 'components/Icons/services/IconYahoo';
import moment from 'moment';
import { getAllOneToOneSessionBookings, getSessionBookingsPerContribution } from 'services/contributions.service';
import { colors } from 'utils/styles';

export const getDomainIcon = (email, byDomain) => {
  const domain = byDomain || email.replace(/.*@/, '').split('.')[0];
  let Icon = () => '';
  switch (domain) {
    case 'gmail':
      Icon = IconGoogle;
      break;
    case 'outlook':
      Icon = IconOutlook;
      break;
    case 'yahoo':
      Icon = IconYahoo;
      break;
    default:
      Icon = () => '';
  }
  return Icon;
};

// const CONTRIBUTION_COLORS = [colors.darkGold, colors.fadedPurple, colors.fbBlue, colors.gold, colors.lightBrown];
const CONTRIBUTION_COLORS = [
  colors.darkOceanBlue,
  colors.darkGold,
  colors.lightGold,
  colors.gold,
  colors.lightBrown,
  colors.darkestBlue,
];
const EXTERNAL_CALENDAR_COLORS = ['#215C73', '#3AC962', '#7D9CB8', '#D55B5B', '#1BA6B9'];

let remaingContributionColors = [...CONTRIBUTION_COLORS];
let remainingCalendarColors = [...EXTERNAL_CALENDAR_COLORS];

let contributionColors = {};
let calendarColors = {};

const getContributionColor = contributionId => {
  let color = null;
  remaingContributionColors =
    remaingContributionColors.length > 0 ? remaingContributionColors : [...CONTRIBUTION_COLORS];
  if (contributionColors[contributionId]) {
    return contributionColors[contributionId];
  }
  color = remaingContributionColors.shift();
  contributionColors = { ...contributionColors, [contributionId]: color };
  return color;
};

export const getCalendarColor = emailAddress => {
  let color = null;
  remainingCalendarColors =
    remainingCalendarColors.length > 0 ? remainingCalendarColors : [...EXTERNAL_CALENDAR_COLORS];
  if (calendarColors[emailAddress]) {
    return calendarColors[emailAddress];
  }
  color = remainingCalendarColors.shift();
  calendarColors = { ...calendarColors, [emailAddress]: color };
  return color;
};

export const getEventsForCalendar = async ({ viewStartDate, viewEndDate, contributionId, updatedCalendars }) => {
  let data = [];
  const date = new Date();
  const userOffsetMinutes = date.getTimezoneOffset();
  const startDate = new Date(viewStartDate.setHours(0, 0, 0, 0));
  const endDate = new Date(viewEndDate.setHours(23, 59, 59, 999));

  if (contributionId) {
    data = await getSessionBookingsPerContribution({
      contributionId,
      startTime: new Date(startDate.setMinutes(startDate.getMinutes() - userOffsetMinutes)),
      endTime: new Date(endDate.setMinutes(endDate.getMinutes() - userOffsetMinutes)),
    });
  } else {
    data = await getAllOneToOneSessionBookings({
      startTime: new Date(startDate.setMinutes(startDate.getMinutes() - userOffsetMinutes)),
      endTime: new Date(endDate.setMinutes(endDate.getMinutes() - userOffsetMinutes)),
    });
  }
  const events = data.map(slot => {
    const {
      externalMasterCalendarEvent = {},
      availabilityTime = {},
      contributionId,
      type: contributionType,
      sessionTime,
      contributionName = '',
      clientName,
      emailAddress,
      sessionName = '',
      sessionId,
    } = slot;

    const isExternalEvent = Boolean(externalMasterCalendarEvent);

    if (isExternalEvent) {
      const {
        when: { start_timeZoned: startTime = '', end_timeZoned: endTime = '' } = {},
        title = '',
        id = '',
        organizer_email: organizerEmail,
      } = externalMasterCalendarEvent;
      const color = getCalendarColor(emailAddress);
      const getColorCodeByGrantId = (updatedCalendars, targetGrantId) => {
        if (updatedCalendars.length > 0) {
          for (const calendar of updatedCalendars) {
            const matchingSubCalendar = calendar.subCalendars.find(
              subCalendar => subCalendar.grant_id === targetGrantId,
            );
            if (matchingSubCalendar) {
              return calendar.colorCode;
            }
          }
        }

        return null;
      };

      return {
        start: moment(startTime).toDate(),
        end: moment(endTime).toDate(),
        title: `${title}`,
        // title: `${moment(startTime).format('h:mm A')} ${title}`,
        id,
        color: getColorCodeByGrantId(updatedCalendars, externalMasterCalendarEvent.grant_id) || color,
        contributionId,
        contributionType,
        contributionName,
        clientName,
        organizerEmail,
        emailAddress,
        isExternalEvent,
      };
    }
    if (availabilityTime) {
      const { startTime = '', endTime = '', bookedTimes = [{ sessionName: '' }], id, offset } = availabilityTime || {};
      const color = getContributionColor(contributionId);

      return {
        start: moment(startTime).toDate(),
        end: moment(endTime).toDate(),
        title: `${moment(startTime).format('h:mm A')}
              ${
                contributionType === 'ContributionOneToOne'
                  ? `${clientName}${contributionName ? `, ${contributionName}` : ''}`
                  : bookedTimes[0].sessionName
              }`,
        id: bookedTimes[0]?.id,
        color,
        contributionId,
        offset,
        contributionType,
        clientName,
        contributionName,
        sessionId,
        sessionTitle: `${
          contributionType === 'ContributionOneToOne'
            ? `${clientName}${contributionName ? `, ${contributionName}` : ''}`
            : bookedTimes[0].sessionName
        }`,
      };
    }
    if (sessionTime) {
      const { startTime = '', endTime = '', id = '' } = sessionTime;
      const color = getContributionColor(contributionId);

      return {
        start: moment(startTime).toDate(),
        end: moment(endTime).toDate(),
        title: `${moment(startTime).format('h:mm A')} ${sessionName}, ${contributionName}`,
        id,
        color,
        contributionId,
        contributionType,
        sessionId,
      };
    }
    return null;
  });
  return events;
};
