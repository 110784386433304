import { combineReducers } from 'redux';

import { memberPodsReducer } from 'reducers/MemberPods';
import { navigationReducer } from 'reducers/Navigation';
import { LOG_OUT } from 'actions/user';
import { removeItem } from 'services/localStorage.service';
import userReducer from './user.reducer';
import alertsReducer from './alerts.reducer';
import contributionsReducer from './contributions.reducer';
import upcomingCreatedWithType from './upcomingCreatedWithType';
import pageReducer from './page.reducer';
import clientPreviewModeReducer from './clientpreview.reducer';
import chatReducer from './chat.reducer';
import videoChatReducer from './videoChat.reducer';
import cohealerInfo from './cohealerInfo';
import dashboardSessions from './dashboardSessions';
import dashboardIncome from './dashboardIncome';
import upcomingCreated from './upcomingCreated';
import archived from './archived';
import userNotes from './userNotes';
import payout from './payout';
import calendars from './calendars';
import bankAccounts from './bankAccounts';
import cohealerIncome from './cohealerIncome';
import testVideoChart from './testVideoChart';
import oneToOneData from './oneToOneData.reducer';
import audioOutputDevices from './audioOutputDevices.reducer';
import paidTierOptions from './paidTier.reducer';
import communityPosts from './community';
import timeZoneReducer from './timeZone.reducer';
import testimonialsReducer from './testimonials.reducer';
import countryReducer from './country.reducer';
import statesReducer from './states.reducer';
import discountCodesReducer from './discountCodes.reducer';
import stickyHeaderReducer from './header.reducer';
import applicationsReducer from './applications.reducer';
import clientsReducer from './clients.reducer';
import communitySectionsReducer from './communitySections.reducer';
import easyBooking from './easyBooking.reducer';
import easyBookingThankYou from './easyBookingThankYou.reducer';
import leadMagnetsReducer from './leadMagnets.reducer';
import emailMarketingReducer from './emailMarketing.reducer';
import campaignReducer from './campaigns.reducer';
import contactsReducer from './contacts.reducer';
import QuizesReducer from './quizes.reducer';
import reducerInvitedClientData from './invitedClientData.reducer';
import masterCalendarReducer from './masterCalendar.reducer';
import communities from './communities.reducer';

const mainReducer = combineReducers({
  account: userReducer,
  emailMarketing: emailMarketingReducer,
  campaigns: campaignReducer,
  contacts: contactsReducer,
  applications: applicationsReducer,
  clients: clientsReducer,
  alerts: alertsReducer,
  contributions: contributionsReducer,
  paidTierOptions,
  upcomingCreatedWithType,
  page: pageReducer,
  clientPreviewMode: clientPreviewModeReducer,
  chat: chatReducer,
  clientPreviewMode: clientPreviewModeReducer,
  videoChat: videoChatReducer,
  cohealerInfo,
  dashboardSessions,
  dashboardIncome,
  upcomingCreated,
  archived,
  easyBooking,
  easyBookingThankYou,
  userNotes,
  payout,
  bankAccounts,
  cohealerIncome,
  communityPosts,
  testVideoChart,
  oneToOneData,
  calendars,
  audioOutputDevices,
  timeZone: timeZoneReducer,
  testimonials: testimonialsReducer,
  country: countryReducer,
  states: statesReducer,
  discountCodes: discountCodesReducer,
  memberPods: memberPodsReducer,
  navigation: navigationReducer,
  stickyHeader: stickyHeaderReducer,
  communitySections: communitySectionsReducer,
  leadMagnets: leadMagnetsReducer,
  quizes: QuizesReducer,
  invitedClient: reducerInvitedClientData,
  masterCalendar: masterCalendarReducer,
  communities,
});

const rootReducer = (state, action) => {
  if (action.type === LOG_OUT) {
    removeItem('persist:root');
  }

  return mainReducer(state, action);
};

export default rootReducer;
