import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useTheme, useMediaQuery } from '@material-ui/core';
import styled, { css } from 'styled-components';

import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import TextBlock from './TextBlock';
import { TemplateType } from 'helpers/constants/templateType';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { flatten, orderBy } from 'lodash';
import { format } from 'date-fns';
import Modal from 'components/UI/Modal';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ParticipantsIcon from '../icons/ParticipantsIcon';

export const DATE_VIEW = 'MMM DD, YYYY';
export const TIME_FORMAT = 'hh:mmA';
export const START_DATETIME_FORMAT = `${DATE_VIEW} ${TIME_FORMAT}`;
const DATE_WITHOUT_YEAR = 'MMM Do';

const formatTime = date => {
  return moment(date).format(DATE_WITHOUT_YEAR);
};

export const EnroleDateShowMore = styled.p`
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  cursor: pointer;
  text-decoration-line: underline;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0;
`;

export const StyledCalenderIcon = styled(CalendarMonthIcon)`
  vertical-align: middle;
  object-fit: contain;
  margin-right: 7px;
`;
const StyledParticipantsCount = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding-top: 16px;
`;
const StyledText = styled.div`
  font-family: Avenir;
  font-size: 14px;
  font-weight: 500;
  line-height: 21.7px;
  color: #000000;
`;

const CustomEnrollmentBlock = ({ enrollment, userNotLoggedIn, themedColor, colorToUse }) => {
  const fromDate = enrollment?.fromDate;
  const toDate = enrollment?.toDate;
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const EnrolDateMainDiv = styled.div``;
  const EnroleDateP = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
  `;
  const MainDivDate = styled.div`
    display: flex;
    justify-content: flex-start;
  `;
  const DateStart = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  `;

  const DateEnd = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #282b2b;
  `;

  return (
    <>
      <div
        style={{
          ...{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
          ...(userNotLoggedIn ? {} : { padding: '4px 20px' }),
        }}
      >
        <EnroleDateP style={{ color: colorToUse.AccentColorCode }}>Enrollment Dates</EnroleDateP>
        {formatTime(fromDate) === formatTime(toDate) ? (
          <MainDivDate>
            <DateStart style={{ color: themedColor }}>{`${formatTime(fromDate)}`} &nbsp;</DateStart>
          </MainDivDate>
        ) : (
          <MainDivDate>
            <DateStart style={{ color: themedColor }}>{`${formatTime(fromDate)} - `} &nbsp;</DateStart>
            <DateEnd style={{ color: themedColor }}>{formatTime(toDate)}</DateEnd>
          </MainDivDate>
        )}
      </div>

      {/* <Card mobileView={mobileView} maxHeight>
        <CardHeader mobileView={mobileView}>
          <PageTitleSecond mobileView={mobileView}>Enrollment Dates</PageTitleSecond>
        </CardHeader>
        <CardBody mobileView={mobileView}>
          <Grid container spacing={2}>
            <Grid item md={6} sm={6} xs={12}>
              <TextBlock mobileView={mobileView} image={null} title="From" text={formatTime(fromDate)} />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <TextBlock mobileView={mobileView} image={null} title="To" text={formatTime(toDate)} />
            </Grid>
          </Grid>
        </CardBody>
      </Card> */}
    </>
  );
};

CustomEnrollmentBlock.propTypes = {
  enrollment: PropTypes.shape({
    fromDate: PropTypes.string,
    toDate: PropTypes.string,
  }).isRequired,
  themedColor: PropTypes.string.isRequired,
  colorToUse: PropTypes.shape({
    AccentColorCode: PropTypes.string,
  }).isRequired,
};

export default CustomEnrollmentBlock;
