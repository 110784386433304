import React, { useEffect, useState } from 'react';
import { Box, Typography, FormControlLabel, Tooltip } from '@material-ui/core';
import Item from 'components/UI/SidebarDropDown/List/Item';
import Button from 'components/FormUI/Button';
import styled from 'styled-components';
import primaryPlusIcon from '../../../assets/primary-plus-icon.png';
import settingIcon from '../../../assets/setting-icon.png';
import crossIcon from '../../../assets/cross-icon.png';
import Checkbox from '@material-ui/core/Checkbox';
import classes from './ConnectedCalendars.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToCalendarAction,
  extraCalendarSettingAction,
  previewMasterCalendarEventAction,
  removeMasterCalendarEmailAction,
} from 'actions/masterCalendarSidebar.actions';
import { getCalendarColor } from 'utils/calendar';

const StyledOutlinedPrimaryButton = styled(Button)`
  background-color: white;
  color: #215c73;
  padding: 12px 16px 12px 0px;
  margin-top: 12px;
  font-size: 14px;
  min-width: 182px;
  display: flex;
  gap: 8px;
  white-space: nowrap;
  align-items: center;
  &:hover {
    background-color: white;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  width: 100%;
  margin-bottom: 0px;
`;

const ConnectedCalendars = () => {
  const { calendarsAccounts: calendars } = useSelector(state => state.calendars);
  const dispatch = useDispatch();
  const handleAddToCalendar = () => {
    dispatch(addToCalendarAction(true));
  };

  return (
    <>
      <Box className={classes.container} paddingTop="24px">
        <Item
          title="Connected Calendars"
          id="coach-connected-calendars-list"
          isList
          titleFontWeight={700}
          listPadding="0px"
          defaultExpanded={true}
          styledButton={
            <StyledOutlinedPrimaryButton onClick={handleAddToCalendar} variant="outlined">
              <div>
                <img src={primaryPlusIcon} width="22px" />
              </div>
              <Typography className={classes.add_calendar_label}>Add My Calendar</Typography>
            </StyledOutlinedPrimaryButton>
          }
        >
          {!!calendars?.length &&
            calendars?.map(calendar => {
              return (
                <div className={classes.form_control_container}>
                  <StyledFormControlLabel
                    control={
                      <Checkbox
                        className={classes.checkbox_root}
                        onChange={event => {
                          dispatch(
                            previewMasterCalendarEventAction({
                              ...calendar,
                              isPreviewMasterCalendarEvents: event?.target?.checked,
                            }),
                          );
                        }}
                        style={{ color: calendar.colorCode, background: 'transparent' }}
                        checked={calendar?.isPreviewMasterCalendarEvents}
                      />
                    }
                    label={
                      <Tooltip title={calendar?.emailAddress} arrow>
                        <div className={classes.label}>{calendar?.emailAddress}</div>
                      </Tooltip>
                    }
                  />
                  <Box display="flex" alignItems="center" style={{ gap: '8px' }}>
                    <div
                      onClick={() => {
                        dispatch(extraCalendarSettingAction(calendar));
                      }}
                      className={classes.setting_icon}
                    >
                      <img src={settingIcon} width="20px" />
                    </div>
                    {/* <div
                      onClick={() => {
                        dispatch(removeMasterCalendarEmailAction(calendar?.emailAddress));
                      }}
                      className={classes.close}
                    >
                      <img src={crossIcon} width="20px" />
                    </div> */}
                  </Box>
                </div>
              );
            })}
        </Item>
      </Box>
    </>
  );
};

export default ConnectedCalendars;
