import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Link, useRouteMatch } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { connect, useDispatch, useSelector } from 'react-redux';
import { toRem } from 'utils/styles';
import TypeOfCourse from 'pages/ContributionView/components/TypeOfCourse';
import PurchaseModalsAndButtons from 'pages/ContributionView/components/PurchaseModalsAndButtons/PurchaseModalsAndButtons';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { ContributionStatus, ContributionType, UserRoles, ContributionThemedColors } from 'helpers/constants';
import Button from 'components/FormUI/Button';
import Can from 'components/Auth/Can';
import { setCohereAcademyStatus } from 'actions/update-user';
import { useShallowEqualSelector } from 'hooks';
import { determineColorToUse, getThemedColors } from 'services/contributions.service';
import {
  CONTRIBUTION_COLORS,
  PAID_TIER_BUTTON_LABEL,
  PAID_TIER_TITLES,
  ROUTES,
  TOOLTIP,
  JOIN_CONTRIBUTION_STATUS,
} from '../../constants';
import CohereIcon from 'assets/icon.png';
import moment from 'moment';
import { useAccount, useClientPreview, useRouter } from 'hooks/index';
import usePaidTier from 'hooks/usePaidTier';
import { TemplateType } from 'helpers/constants/templateType';
import { redirectToAppIfIsCoachDomain } from 'utils/subdomains';
import * as paidTier from 'selectors/paidTier';
import { lightOrDark } from 'utils/utils';

const WrapperButtons = styled.div`
  display: flex;
  margin-top: 16px;
`;
const HeaderIcon = styled.img`
  margin-right: 20px;
  vertical-align: middle;
  object-fit: contain;
  width: 45px;
  height: auto;
`;

const StyledWrapper = styled.div`
  padding: 16px;
  color: ${props => props.themedColor} !important;
  background-color: ${props => props.themedBackgroundColor} !important;
`;

const PageTitle = styled.h1`
  font-family: 'Nexa';
  color: rgba(0, 0, 0, 0.87);
  font-size: ${toRem(20)};
  font-weight: 500;
  margin: 0;
`;

const isPathForContribution = path => path === '/contribution-view/:id';
const isPathForCohealerAbout = path => path === '/cohealer-about/:cohealerId';
const TRIAL_PERIOD_START_DATE = '2021-11-01';
const TRIAL_PERIOD_DAYS = 13;

function HeaderMobile({ title, contribution, isCustomPrevButton, onSendMessage, roleName, changeColor }) {
  const {
    path,
    params: { id },
  } = useRouteMatch();
  const theme = useTheme();
  const activeTemplaate = contribution?.activeTemplate || TemplateType.TemplateThree;
  let colorToUse = determineColorToUse(contribution);
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';

  const { status } = useContribution();
  const xsMobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const isFinalized = status === ContributionStatus.rejected;
  const { domain } = useRouter();
  const { currentRole, user } = useAccount();
  // const { isLaunchPlan } = usePaidTier();
  const dispatch = useDispatch();
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  const isLaunchPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch;

  // Correct behavior - 100%

  useEffect(() => {
    if (activePaidTierCurrentPlan?.default) {
      dispatch(setCohereAcademyStatus(true));
    } else {
      dispatch(setCohereAcademyStatus(false));
    }
  }, [activePaidTierCurrentPlan, dispatch]);
  const trialPeriodStart = moment(user.createTime);
  const trialPeriodEnd = moment(user.createTime).add(TRIAL_PERIOD_DAYS, 'days');
  const todayDate = moment(moment());
  const { clientPreviewMode } = useClientPreview();
  const { themedColor, themedBackgroundColor } = getThemedColors(contribution);

  return (
    <StyledWrapper themedColor={themedColor} themedBackgroundColor={themedBackgroundColor} changeColor={changeColor}>
      {isCustomPrevButton ? (
        <div className="d-flex align-items-center">
          <HeaderIcon src={CohereIcon} />
          <span className="h3 font-weight-light">{title}</span>
        </div>
      ) : (
        <PageTitle mobileView={xsMobileView}>
          {Boolean(Boolean(isPathForContribution(path) && contribution?.type) || isPathForCohealerAbout(path)) && (
            <div className="d-flex align-items-center">
              {activeTemplaate != TemplateType.TemplateThree &&
              contribution?.type != ContributionType.contributionOneToOne ? (
                <HeaderIcon src={contribution?.customLogo ? contribution?.customLogo : CohereIcon} />
              ) : (
                <HeaderIcon src={CohereIcon} />
              )}
              <div>
                <p style={{ color: themedColor }} className="h3 mb-0 font-weight-light">
                  {title}
                </p>
                {isPathForContribution(path) &&
                  currentRole === UserRoles.cohealer &&
                  isLaunchPlan &&
                  trialPeriodStart.isSameOrAfter(TRIAL_PERIOD_START_DATE, 'day') &&
                  todayDate.isAfter(trialPeriodEnd) && (
                    <p className="h5 text-muted font-weight-light">Please upgrade your account to invite clients</p>
                  )}
              </div>
            </div>
          )}
        </PageTitle>
      )}
      <WrapperButtons>
        {isPathForContribution(path) && (
          <TypeOfCourse
            handleUrlModal
            headerMobile={true}
            contribution={contribution}
            mobileView={mobileView}
            clientPreviewMode={clientPreviewMode}
          />
        )}
        {isPathForContribution(path) && (
          <PurchaseModalsAndButtons headerMobile={true} clientPreviewMode={clientPreviewMode} />
        )}
        {!isFinalized && !onSendMessage && (
          <Can
            roleName={UserRoles.cohealer}
            yes={() =>
              !clientPreviewMode ? (
                <Link
                  onClick={() => {
                    redirectToAppIfIsCoachDomain(domain, `/edit-contribution/${id}/basic`);
                  }}
                >
                  <Button
                    textColor={textColor}
                    backgroundColor={colorToUse.PrimaryColorCode}
                    mobileView={mobileView}
                    autoWidth
                  >
                    Edit Service
                  </Button>
                </Link>
              ) : null
            }
          />
        )}
        {onSendMessage && (
          <Can
            roleName={roleName}
            yes={() => (
              <Button mobileView={mobileView} autoWidth onClick={onSendMessage}>
                Send a Message
              </Button>
            )}
          />
        )}
      </WrapperButtons>
    </StyledWrapper>
  );
}

HeaderMobile.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  contribution: PropTypes.shape(),
  isCustomPrevButton: PropTypes.bool,
};

HeaderMobile.defaultProps = {
  contribution: null,
  isCustomPrevButton: false,
};

const mapStateToProps = ({ account, page, contributions }) => ({
  loading: account?.isLoading,
  title: page?.header,
  contribution: contributions.activeContribution,
});

export default connect(mapStateToProps)(HeaderMobile);
