import React, { useCallback, useEffect, useState } from 'react';
import { determineColorToUse, getThemedColors } from 'services/contributions.service';
import { Grid, ClickAwayListener, Box, CircularProgress, useMediaQuery, useTheme, Tooltip } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import HappyEmoji from 'components/UI/chats/Chat/Icons/HappyEmoji';
import Picker from 'emoji-picker-react';
import { formatPostTime } from 'utils/utils';
import { mdiBookmark, mdiPinOutline, mdiPinOffOutline, mdiDotsHorizontal } from '@mdi/js';
import CommunityDeleteSvg from 'components/Icons/CommunityDelete';
import CommunityEditSvg from 'components/Icons/CommunityEdit';
import CommunityLikeSvg from 'components/Icons/CommunityLike';
import CommunityLikeFilledSvg from 'components/Icons/CommunityLikeFilled';
import CommunityCommentSvg from 'components/Icons/CommunityComment';
import Linkify from 'react-linkify';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import { Formik } from 'formik';
import SendIcon from '@material-ui/icons/Send';
import map from 'lodash/map';
import some from 'lodash/some';
import find from 'lodash/find';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import * as _ from 'lodash';
import { UserRoles } from 'helpers/constants';
import { colors } from 'utils/styles';
import {
  addComment,
  addLike,
  notifyTaggedUsers,
  editPost,
  removeLike,
  markAllCommunitiesRead,
} from 'services/community.service';
import { Attachments } from '../Attachments/Attachments';
import CommunityCelebrationSvg from 'components/Icons/CommunityCelebration';
import {
  AvatarComponent,
  StyledClickDropDown,
  StyledContainer,
  StyledDate,
  StyledMessage,
  StyledMetaData,
  StyledName,
  StyledText,
  AvatarComponentComment,
  StyledButton,
  StyleTextLink,
  StyledProfileLink,
  StyledTextError,
} from './Post.styled';
import useContribution from '../../../hooks/useContribution';
import * as actions from '../../../../../actions/community';
import Icon from '../../../../../components/UI/Icon';
import ClickDropDown, { StyledDropDownItem } from '../../../../../components/UI/ClickDropDown';
import { useAccount, useRouter, usePartnerCoach, useUnifiedCommunity } from '../../../../../hooks';
import LinkPreview from '../LinkPreview/LinkPreview';
import Comment from '../Comment/Comment';
import UserMentionInput from 'components/UI/mentions/UserMentionInput';
import EventIcon from '@material-ui/icons/Event';
import { determineDarkThemedColorToUse } from 'services/contributions.service';
import moment from 'moment';
import { TemplateType } from 'helpers/constants/templateType';
import { addhttp, isValidUrl, lightOrDark } from 'utils/utils';
import styled from 'styled-components';
import './Post.scss';
import { useRef } from 'react';
import { toRem } from '../../../../../utils/styles';
import LikesModal from 'components/Modals/LikesModal';
import CreatePost from '../CreatePost/CreatePost';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { setPostsAsRead } from 'services/user.service';
import { DATE_FORMATS } from 'utils/datesAndMoney';

const ROW_HEIGHT = 20;

const StyledPostOuterContainer = styled.div`
  position: relative;
  width: 100%;
`;

const StyledCoachTag = styled.div`
  font-family: Avenir;
  font-weight: 500;
  border-radius: 4px;
  padding: 3px 8px;
  height: 22px;
  line-height: 17px;
  font-size: 10px;
  color: ${({ tagTextColor }) => tagTextColor};
  background-color: ${({ tagColor }) => tagColor};
  ${({ mobileView }) =>
    mobileView &&
    `
  padding: 2px 8px;
  place-content: center;
`}
`;
const StyledCommunityTag = styled.div`
  font-family: Avenir;
  font-weight: 500;
  border-radius: 4px;
  padding: 3px 8px;
  height: 22px;
  line-height: 17px;
  font-size: 10px;
  color: #a58b62;
  background-color: rgba(209, 185, 137, 0.1);
  ${({ mobileView }) =>
    mobileView &&
    `
  padding: 2px 8px;
  place-content: center;
`}
`;

const StyledCommunityName = styled.div`
  display: flex;
  align-items: center;
  font-family: Avenir;
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  color: #215c73;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 15px;

  &:hover {
    svg {
      display: inline-block !important;
    }
  }
`;

const StyledLikesCount = styled.span`
  margin-right: 5px;
`;

const StyledOpenInNewOutlinedIcon = styled(OpenInNewOutlinedIcon)`
  display: none !important;
  opacity: 45%;
  font-size: 14px !important;
  margin-left: 8px;
`;

const Post = ({
  modal,
  post,
  communityPosts,
  pods = [],
  defaultTextareaValue = '',
  backFunction,
  supportPopup,
  getActiveContribution,
  searchedWord,
  currentGroup,
  reloadPostsOnEdit,
  onHashtagClick,
  index,
  setShowAllPostsTag = null,
}) => {
  const divRef = useRef(null);
  const { isUnifiedCommunity } = useUnifiedCommunity();
  const contrib = useContribution();
  const contribution = isUnifiedCommunity ? post?.contribution : contrib;
  const colorToUse = determineColorToUse(isUnifiedCommunity ? {} : contribution);
  const isDarkThemeEnabled = determineDarkThemedColorToUse(isUnifiedCommunity ? {} : contribution);
  const { themedColor, themedCardBackgroundColor, themedBackgroundColor } = getThemedColors(
    isUnifiedCommunity ? {} : contribution,
  );
  const mentionInputRef = useRef(null);
  const { domain, history } = useRouter();
  const podIdPrefix = 'podId-';
  const contributionAuthor = useSelector(state => state.cohealerInfo.info);
  const theme = useTheme();
  const [showAllLikes, setShowAllLikes] = useState(false);
  // TODO move to redux
  const [item, setItem] = useState(post);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isMyLike, setIsMyLike] = useState(false);
  const [likeCount, setLikeCount] = useState(item?.likes?.length);
  const [showCommentInput, setShowCommentInput] = useState(true);
  const [showSingleComment, setShowSingleComment] = useState(true);
  const [showAllComments, setShowAllComments] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [likesModalOpen, setLikesModalOpen] = useState(false);
  const [commentPostPending, setCommentPostPending] = useState(false);
  const dispatch = useDispatch();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  // const { partnerCoaches } = usePartnerCoach(contribution?.id);
  const { user } = useAccount();
  const { currentRole } = useAccount();
  const isCoach = currentRole === UserRoles.cohealer;
  const isClient = currentRole === UserRoles.client;
  const isMyPost = user.id === item?.userInfo?.id;
  const scheduledTime = moment(post.scheduledTime).format('MMM DD, YYYY h:mm A');
  const [userMentions, setUserMentions] = useState([]);
  const [resetUserMentionInputValue, setResetUserMentionInputValue] = useState(false);
  const [textareaValue, setTextareaValue] = useState(defaultTextareaValue);
  const selector = state => state?.communities;
  const { totalUnreadPosts, totalUnreadPostCountAllCommunities, unReadContributionIds, communities } = useSelector(
    selector,
  );
  const btnColor = colorToUse?.PrimaryColorCode;
  const AccentColor = colorToUse?.AccentColorCode;
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';

  const [isExpanded, setIsExpanded] = useState(false);
  const MAX_CHARACTERS = 200;

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  const renderText = () => {
    const fullText = item.text
      .split('\n')
      .map((str, index) => (
        <div key={index} dangerouslySetInnerHTML={{ __html: generateHTMLText(str, searchedWord) }} />
      ));

    if (!isExpanded && item.text.length > MAX_CHARACTERS) {
      const truncatedText = item.text.substring(0, MAX_CHARACTERS) + '...';
      return (
        <>
          <span
            style={{ display: 'inline' }}
            dangerouslySetInnerHTML={{
              __html: generateHTMLText(truncatedText, searchedWord),
            }}
          />
          <button
            onClick={toggleText}
            style={{
              color: isUnifiedCommunity && !isDarkThemeEnabled ? '#215c73' : colorToUse?.AccentColorCode,
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              fontSize: '14px',
              fontWeight: '800',
              display: 'inline',
            }}
          >
            See More
          </button>
        </>
      );
    }

    return (
      <>
        {fullText}
        {item.text.length > MAX_CHARACTERS && (
          <button
            onClick={toggleText}
            style={{
              color: colorToUse?.AccentColorCode,
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              fontSize: '14px',
              fontWeight: '800',
              padding: '0px',
            }}
          >
            See Less
          </button>
        )}
      </>
    );
  };

  const onEmojiClick = emojiObject => {
    setTextareaValue(prev => prev + emojiObject.emoji);
  };

  const markAllPostsAsRead = () => {
    if (isUnifiedCommunity) {
      markAllCommunitiesRead().then(() => {
        unReadContributionIds.map(contId => {
          const community = communities.find(x => x.id === contId);
          if (community) {
            community.postsCount = 0;
          }
          dispatch(actions.updateCommunityUnreadCount(community));
          dispatch(
            actions.updateCommunitiesUnreadCount({
              totalUnreadPostCountAllCommunities: 0,
              totalUnreadPostCountMyCommunities: 0,
              totalUnreadPostCountJoinedCommunities: 0,
            }),
          );
        });
      });
    } else {
      setPostsAsRead({ contributionId: contrib?.id, userId: user.id });
    }
    setShowAllPostsTag(false);
  };

  useEffect(() => {
    const handleIntersect = entries => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        if (setShowAllPostsTag) {
          markAllPostsAsRead();
        }
      }
    };

    const observer = new IntersectionObserver(handleIntersect, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    });

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
    };
  }, [totalUnreadPostCountAllCommunities]);

  const users = [
    ...(contribution?.participants?.map(x => ({
      imageUrl: x.avatarUrl,
      display: `@${x.firstName} ${x.lastName}`,
      id: x.id,
    })) || []),
    ...(contribution?.contributionPartners?.map(x => ({
      imageUrl: x.avatarUrl,
      display: `@${x.firstName} ${x.lastName}`,
      id: x.userId,
    })) || []),
    ...pods.map(x => ({ id: `${podIdPrefix}${x.id}`, display: x.name })),
  ];

  if (user.id === contribution?.userId || contribution?.contributionPartners?.some(x => x.userId == user.id)) {
    users.push({
      imageUrl: null,
      display: `@channel`,
      id: 'channelId',
    });
  }
  if (contributionAuthor && !users.some(e => e.id === contributionAuthor.id)) {
    users.push({
      imageUrl: contributionAuthor.avatarUrl,
      display: `@${contributionAuthor.firstName} ${contributionAuthor.lastName}`,
      id: contributionAuthor.id,
    });
  }

  const fetchPosts = values => {
    // dispatch(actions.getAllPostsForContribution(`${contribution?.id}/1/${COUNT_POSTS_BY_PAGE}`)).then(() => { });
    // dispatch(
    //   actions.getAllPostsForContribution(
    //     `${contribution?.id}/1/${COUNT_POSTS_BY_PAGE}${!_.isEmpty(currentGroup) && currentGroup?.orderNo !== -1 ? `?groupId=${currentGroup.id}` : ''
    //     }`,
    //   ),
    // ).then(() => { });
    reloadPostsOnEdit();
  };

  useEffect(() => {
    const post = communityPosts?.posts?.find(p => p.id === item.id);
    if (post) {
      setItem(post);
    }
  }, [communityPosts]);

  const handleTogglePin = () => {
    editPost({
      ...item,
      userId: item.userInfo.id,
      isApprovalRequired: isCoach ? false : contribution?.isPostApprovalRequired,
      isPinned: !item.isPinned,
    }).then(() => {
      fetchPosts();
    });
  };
  const handleClickEdit = useCallback(() => {
    setModalOpened(true);
  }, [setModalOpened]);
  const handleModalClose = useCallback(() => {
    setModalOpened(false);
  }, [setModalOpened]);

  useEffect(() => {
    setIsMyLike(some(item.likes, { userInfo: { id: user.id } }));
  }, [item.likes, user.id]);

  const handleToggleLike = () => {
    if (isMyLike) {
      const currentLike = find(item.likes, { userInfo: { id: user.id } });
      removeLike(currentLike?.id).then(() => {
        const newLikes = filter(item.likes, like => like.id !== currentLike.id);
        setItem({ ...item, likes: [...newLikes] });
        setIsMyLike(false);
        setLikeCount(likeCount - 1);
      });
    } else {
      addLike({
        postId: item.id,
      }).then(data => {
        setItem({ ...item, likes: [...item.likes, data] });
        setIsMyLike(true);
        setLikeCount(likeCount + 1);
      });
    }
  };

  const handleToggleCommentLike = comment => {
    const currentLike = find(comment.likes, { userInfo: { id: user.id } });
    if (currentLike) {
      removeLike(currentLike?.id).then(() => {
        const newLikes = filter(comment.likes, like => like.id !== currentLike.id);
        comment.likes = newLikes;
        setLikeCount(likeCount - 1);
      });
    } else {
      addLike({
        postId: item.id,
        commentId: comment.id,
      }).then(data => {
        comment.likes.push(data);
        setLikeCount(likeCount + 1);
      });
    }
  };

  const handleClickDelete = () => {
    dispatch(actions.deletePostById(item.id)).then(() => {
      fetchPosts();
    });
  };
  const handleToggleCommentInput = () => {
    if (mentionInputRef.current) {
      mentionInputRef.current.scrollIntoView({
        top: mentionInputRef.current.offsetTop + 100,
        behavior: 'smooth',
        block: 'start',
      });
      mentionInputRef.current.focus();
    }
  };

  const handleCommentCreate = (e, { resetForm }) => {
    setCommentPostPending(true);
    setShowSingleComment(false);
    addComment({
      text: textareaValue,
      postId: item.id,
    })
      .then(data => {
        resetForm({});
        setItem({ ...item, comments: [...item.comments, data] });
        setCommentPostPending(false);
        setShowSingleComment(true);
      })
      .then(async () => {
        if (!isEmpty(userMentions)) {
          let mentionedUserIds;

          if (userMentions.some(e => e.id === 'channelId')) {
            mentionedUserIds = uniq([
              ...contribution?.participants?.map(x => x.id),
              ...(contribution?.contributionPartners && contribution?.contributionPartners.map(x => x.userId)),
              ...pods.flatMap(podItem => podItem.clientIds),
              contribution?.userId,
            ]);
          } else {
            mentionedUserIds = uniq([
              ...userMentions.filter(x => !x.id.includes(podIdPrefix)).map(x => x.id),
              ...userMentions
                .filter(x => x.id.includes(podIdPrefix))
                .flatMap(x =>
                  pods.filter(pod => `${podIdPrefix}${pod.id}` === x.id).flatMap(podItem => podItem.clientIds),
                ),
            ]).filter(x => isEmpty(x));
          }

          await notifyTaggedUsers({
            mentionAuthorUserName: `${user.firstName} ${user.lastName}`,
            message: textareaValue,
            contributionName: contribution?.title,
            ContributionId: contribution?.id,
            mentionedUserIds,
            PostId: item?.id,
            AuthorUserId: user?.id,
            replyLink: `https://${domain}/contribution-view/${contribution?.id}/community/social/${item.id}`,
          });
        }
        setTextareaValue('');
        setResetUserMentionInputValue(true);
      });
  };
  const handleKeyUp = useCallback((plainText, mentions) => {
    setTextareaValue(plainText);
    setUserMentions(mentions);
    setResetUserMentionInputValue(false);
  });
  const handleShowComments = () => {
    setShowAllComments(true);
  };
  const handleToggleShowLikes = () => {
    setShowAllLikes(!showAllLikes);
  };

  const onStartDirectChat = useCallback(
    participantId => {
      const url = addhttp(domain?.concat(`/conversations/all?chatId=${participantId}`));
      window.open(url, '_blank');
    },
    [domain],
  );

  const containsHTML = str => /<[a-z][\s\S]*>/i.test(str);

  const generateHTMLText = (text, searchedWord) => {
    let newSentence;
    if (!!searchedWord?.length) {
      let matchPattern = text?.split(' ');
      newSentence = matchPattern?.map(word => {
        const lowerCaseWord = word.toLowerCase();
        const lowerCaseSeaarchedWord = searchedWord.toLowerCase();
        if (
          lowerCaseWord.includes(lowerCaseSeaarchedWord) ||
          lowerCaseSeaarchedWord.includes(lowerCaseWord) ||
          word.includes('#')
        ) {
          if (containsHTML(word)) {
            const str = word.toString();
            const text = str.replace(/(<([^>]+)>)/gi, '');
            return `<span style="background-color: #f6e8ab;">${text}</span>`;
          } else {
            return `<span style="background-color: #f6e8ab;">${word}</span>`;
          }
        } else {
          return word;
        }
      });
      return newSentence.join(' ');
    }

    const pattern = /(?:[@#](?!(?:All\b))(?:Channel|\w+\s+\w+)|[#]\w+\s|[#]\w+|(?:https?:\/\/)?(?:www\.)?\S+)/gi;

    let matchPattern = text?.match(pattern);
    matchPattern = matchPattern?.map(pattern => pattern.trim());
    const newMatchedPattern = matchPattern?.map((tag, index) => {
      const isMatchedUser = contribution?.participants?.find(
        participant =>
          `@${participant.firstName} ${participant.lastName}` === `${matchPattern[index]} ${matchPattern[index + 1]}`,
      );

      if (
        `${matchPattern[index]} ${matchPattern[index + 1]}` === `@${user.firstName} ${user.lastName}` ||
        isMatchedUser
      ) {
        return `${matchPattern[index]} ${matchPattern[index + 1]}`;
      } else {
        return tag;
      }
    });
    let updatedText = text;
    if (matchPattern) {
      let filteredUser = [];
      filteredUser = newMatchedPattern?.filter((keyword, index) => {
        if (
          keyword.toLowerCase() === '@All'.toLowerCase() ||
          keyword.toLowerCase() === '@channel'.toLowerCase() ||
          keyword === `@${user.firstName} ${user.lastName}` ||
          keyword.includes('www.') ||
          keyword.includes('http:') ||
          keyword.includes('https:') ||
          keyword.includes('#') ||
          keyword[0] === '@'
        ) {
          return keyword;
        }
      });
      const a = contribution?.participants?.filter(participant => {
        const name = `@${participant.firstName} ${participant.lastName}`.trimEnd();
        const userMatched = matchPattern?.find(userName => name === userName);
        if (userMatched) {
          if (!filteredUser.includes(userMatched)) {
            filteredUser.push(name);
          }
        }
      });
      if (!matchPattern) {
        return updatedText;
      }
      const replacements = [];
      filteredUser.forEach((match, index) => {
        const isUrl = match.includes('http:') || match.includes('https:') || match.includes('www.');
        let replacement = ``;
        if (isUrl) {
          const urlWithProtocol = match.includes('http:') || match.includes('https:');
          if (urlWithProtocol) {
            replacement = `<span style="color: ${colorToUse.AccentColorCode};cursor:pointer"><a href=${match} target="_blank">${match}</a></span>`;
          } else {
            const link = `https://${match}`;
            replacement = `<span style="color: ${colorToUse.AccentColorCode};cursor:pointer"><a href=${link} target="_blank">${match}</a></span>`;
          }
        } else if (match) {
          if (match.startsWith('#')) {
            const [hashtag, ...followingWords] = match.split(' ');
            const hashtagText = hashtag;
            const nonHighlightedText = followingWords.join(' ');

            // Apply theme-based coloring
            replacement = isDarkThemeEnabled
              ? `<span style="color: ${colorToUse.AccentColorCode}; cursor: pointer;" class="hashtag-clickable">${hashtag}</span><span style="color: white; cursor: pointer;"> ${nonHighlightedText}</span>`
              : `<span style="color: ${colorToUse.AccentColorCode}; cursor: pointer;" class="hashtag-clickable">${hashtag}</span><span style="color: black; cursor: pointer;"> ${nonHighlightedText}</span>`;
          } else {
            replacement = `<span  style="color: ${colorToUse.AccentColorCode};cursor:default;" id="hashtagclick">${match}</span>`;
          }
          // if (match.startsWith('#')) {
          //   const [hashtag, ...followingWords] = match.split(' ');
          //   const hashtagText = hashtag;
          //   const nonHighlightedText = followingWords.length ? ` ${followingWords.join(' ')}` : ''; // Include non-highlighted text
          //   replacement = isDarkThemeEnabled
          //     ? `<span style="color: ${colorToUse.AccentColorCode};cursor:pointer;" class="hashtag-clickable" >${hashtag}<span style="color:white;cursor:pointer;">${nonHighlightedText}</span></span>`
          //     : `<span style="color: ${colorToUse.AccentColorCode};cursor:pointer;" class="hashtag-clickable" >${hashtag}<span style="color:white;cursor:pointer;">${nonHighlightedText}</span></span>`;
          // } else {
          //   replacement = `<span  style="color: ${colorToUse.AccentColorCode};cursor:pointer;" id="hashtagclick">${match}</span>`;
          // }
        } else {
          replacement = match; // Keep non-hashtag words unchanged
        }

        // updatedText = updatedText.replace(match, replacement);

        const placeholder = `{{PLACEHOLDER_${index}}}`;
        updatedText = updatedText.replace(match, placeholder);
        // Save the replacement for later
        replacements.push({ placeholder, replacement });
      });

      // Here we replaced placeholders with actual replacements after processing all matches
      replacements.forEach(({ placeholder, replacement }) => {
        updatedText = updatedText.replace(placeholder, replacement);
      });
    }
    if (updatedText === '') {
      updatedText = `</br>`;
    }

    return updatedText;
  };

  const getClassName = (isDarkThemeEnabled, isUnifiedCommunity, className) => {
    if (isUnifiedCommunity) {
      return isDarkThemeEnabled ? `${className}-dark-themed-unified` : `${className}-unified`;
    } else {
      return isDarkThemeEnabled ? `${className}-dark-themed` : className;
    }
  };
  const clickableHashtags = document.querySelectorAll('.hashtag-clickable');
  clickableHashtags.forEach(hashtagSpan => {
    hashtagSpan.addEventListener('click', () => {
      const clickedHashtag = hashtagSpan.dataset.hashtag;

      onHashtagClick(clickedHashtag);
    });
  });

  return (
    <StyledContainer
      className={isUnifiedCommunity && isDarkThemeEnabled ? 'dark-post-container' : ''}
      modal={modal}
      direction="row"
      xs={12}
      isUnifiedCommunity={isUnifiedCommunity}
    >
      <Grid container direction="row" justifyContent="space-between">
        <StyledProfileLink
          isMyPost={isMyPost}
          isDarkThemeEnabled={isDarkThemeEnabled}
          onClick={() => {
            if (!supportPopup && !isMyPost) {
              onStartDirectChat(item.userInfo.id);
            }
          }}
        >
          <Box component="div" display="flex" justifyContent="space-between" alignItems="center">
            {supportPopup && (
              <div>
                <ArrowBackIosIcon onClick={backFunction} style={{ color: '#4a4a4a' }} fontSize="small" />
              </div>
            )}
            <div style={{ display: 'flex', gap: '12px' }}>
              <AvatarComponent
                alt={`${item.userInfo.firstName}${item.userInfo.lastName}`}
                src={item.userInfo.avatarUrl}
                mobileView={mobileView}
              >
                {item.userInfo.firstName[0]}
                {item.userInfo.lastName[0]}
              </AvatarComponent>
              <Box component="div">
                {mobileView ? (
                  <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'baseline' }}>
                      <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <StyledName>
                          <div>
                            {' '}
                            {item.userInfo.firstName} {item.userInfo.lastName}
                          </div>
                          {item.isBubbled && <EmojiObjectsIcon style={{ color: colors.gold }} className="ml-1" />}
                        </StyledName>
                        <div
                          style={{
                            width: '4px',
                            height: '4px',
                            borderRadius: '50%',
                            backgroundColor: !isUnifiedCommunity && isDarkThemeEnabled ? 'white' : 'black',
                            display: 'inline-block',
                            opacity: '70%',
                          }}
                        />
                        {item.isScheduled && !isClient && (
                          <Tooltip title={'Scheduled for ' + scheduledTime} arrow>
                            <EventIcon
                              mobileView={mobileView}
                              size="10px"
                              className="ml-1 smoked-gary-color hoverable"
                              style={{
                                color:
                                  !isUnifiedCommunity && isDarkThemeEnabled
                                    ? 'rgba(255, 255, 255, 0.6)'
                                    : 'rgba(0, 0, 0, 0.6)',
                                fontSize: '20px',
                              }}
                            />
                          </Tooltip>
                        )}
                        <StyledDate
                          style={{ color: themedColor }}
                          title={moment(item?.createTime).format(DATE_FORMATS.DATE_TIME)}
                        >
                          {item.isScheduled && !isClient ? '' : formatPostTime(item?.createTime)}
                        </StyledDate>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '6px' }}>
                        {item?.userInfo?.isCohealer && (
                          <>
                            <div>
                              <StyledCoachTag
                                mobileView={mobileView}
                                tagTextColor={isUnifiedCommunity ? '#215c73' : 'white'}
                                tagColor={isUnifiedCommunity ? '#f1f8ff' : AccentColor}
                              >
                                COACH
                              </StyledCoachTag>
                            </div>
                          </>
                        )}
                        {item?.userInfo?.isCohealer &&
                          isUnifiedCommunity &&
                          item?.userInfo?.id === item?.contribution?.userId &&
                          isMyPost && (
                            <>
                              <div>
                                <StyledCommunityTag mobileView={mobileView}>MY COMMUNITY</StyledCommunityTag>
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <StyledName>
                      <div>
                        {' '}
                        {item.userInfo.firstName} {item.userInfo.lastName}
                      </div>
                      {item.isBubbled && <EmojiObjectsIcon style={{ color: colors.gold }} className="ml-1" />}
                    </StyledName>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '6px' }}>
                      {item?.userInfo?.isCohealer && (
                        <>
                          <div>
                            <StyledCoachTag
                              mobileView={mobileView}
                              tagTextColor={isUnifiedCommunity ? '#215c73' : 'white'}
                              tagColor={isUnifiedCommunity ? '#f1f8ff' : AccentColor}
                            >
                              COACH
                            </StyledCoachTag>
                          </div>
                        </>
                      )}
                      {item?.userInfo?.isCohealer &&
                        isUnifiedCommunity &&
                        item?.userInfo?.id === item?.contribution?.userId &&
                        isMyPost && (
                          <>
                            <div>
                              <StyledCommunityTag mobileView={mobileView}>MY COMMUNITY</StyledCommunityTag>
                            </div>
                          </>
                        )}
                    </div>
                    <div
                      style={{
                        width: '4px',
                        height: '4px',
                        borderRadius: '50%',
                        backgroundColor: !isUnifiedCommunity && isDarkThemeEnabled ? 'white' : 'black',
                        display: 'inline-block',
                        opacity: '70%',
                      }}
                    />
                    {item.isScheduled && !isClient && (
                      <Tooltip title={'Scheduled for ' + scheduledTime} arrow>
                        <EventIcon
                          mobileView={mobileView}
                          size="10px"
                          className="ml-1 smoked-gary-color hoverable"
                          style={{
                            color:
                              !isUnifiedCommunity && isDarkThemeEnabled
                                ? 'rgba(255, 255, 255, 0.6)'
                                : 'rgba(0, 0, 0, 0.6)',
                            fontSize: '20px',
                          }}
                        />
                      </Tooltip>
                    )}
                    <StyledDate
                      style={{ color: themedColor }}
                      title={moment(item?.createTime).format(DATE_FORMATS.DATE_TIME)}
                    >
                      {item.isScheduled && !isClient ? '' : formatPostTime(item?.createTime)}
                    </StyledDate>
                  </div>
                )}
                {isUnifiedCommunity && item?.contribution?.title && (
                  <StyledCommunityName
                    onClick={() => {
                      window.open(
                        `${window.location.origin}/contribution-view/${item?.contributionId}/community${
                          item.groupId ? '/'.concat(item?.groupId) : ''
                        }`,
                        '_blank',
                      );
                    }}
                  >
                    <Tooltip title={item?.contribution?.title} arrow>
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          maxWidth: mobileView && item?.group ? '120px' : mobileView ? '200px' : '350px',
                          display: 'inline-block',
                          overflow: 'hidden',
                          height: '15px',
                        }}
                      >
                        {item?.contribution?.title}
                      </span>
                    </Tooltip>{' '}
                    {item?.group && (
                      <div style={{ display: 'flex' }}>
                        -
                        <span style={{ fontSize: '14px' }}>
                          {isValidUrl(item.group.imageUrl) ? (
                            <img
                              src={item.group.imageUrl}
                              alt="group-icon"
                              style={{ width: '12px', height: '12px' }}
                              className="mx-1"
                            />
                          ) : (
                            item.group.imageUrl
                          )}
                        </span>
                        <Tooltip title={item.group.title} arrow>
                          <span
                            style={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxWidth: mobileView ? '120px' : '200px',
                              display: 'inline-block',
                              height: '14px',
                            }}
                          >
                            {item.group.title}
                          </span>
                        </Tooltip>
                      </div>
                    )}
                    <StyledOpenInNewOutlinedIcon />
                  </StyledCommunityName>
                )}
              </Box>
            </div>
          </Box>
        </StyledProfileLink>
        <StyledClickDropDown>
          <Box display="flex">
            {item?.isPinned && <Icon path={mdiPinOutline} left="20" top="3" size={0.9} />}
            {(isMyPost || isCoach) && (
              <ClickDropDown icon={mdiDotsHorizontal}>
                {isCoach && !isUnifiedCommunity && (
                  <StyledDropDownItem
                    style={{ color: themedColor, backgroundColor: themedBackgroundColor }}
                    onClick={handleTogglePin}
                  >
                    {item?.isPinned ? (
                      <>
                        <Icon color={themedColor} path={mdiPinOffOutline} size={0.9} />
                        Unpin at the top
                      </>
                    ) : (
                      <>
                        <Icon color={themedColor} path={mdiPinOutline} size={0.9} />
                        Pin to top
                      </>
                    )}
                  </StyledDropDownItem>
                )}
                {isMyPost && (
                  <StyledDropDownItem
                    style={{ color: themedColor, backgroundColor: themedBackgroundColor }}
                    onClick={handleClickEdit}
                  >
                    <CommunityEditSvg width="24px" height="24px" color={themedColor} />
                    Edit
                  </StyledDropDownItem>
                )}
                {(isMyPost || isCoach) && (
                  <StyledDropDownItem
                    style={{ color: themedColor, backgroundColor: themedBackgroundColor }}
                    onClick={handleClickDelete}
                  >
                    <CommunityDeleteSvg width="24px" height="24px" color={themedColor} />
                    Delete
                  </StyledDropDownItem>
                )}
              </ClickDropDown>
            )}
          </Box>
        </StyledClickDropDown>
      </Grid>
      <StyledText isUnifiedCommunity style={{ color: themedColor }}>
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <StyleTextLink
              style={{ color: isCoach ? colors.darkOceanBlue : colorToUse.AccentColorCode }}
              target="blank"
              href={decoratedHref}
              key={key}
            >
              {decoratedText}
            </StyleTextLink>
          )}
        >
          {!isEmpty(item.text) && renderText()}
        </Linkify>
      </StyledText>
      <div>
        <Attachments modal={modal} attachments={item.attachments} colorToUse={colorToUse} />
      </div>
      {!isEmpty(item?.links) && map(item?.links, (item, index) => <LinkPreview key={index} item={item} />)}

      {
        <StyledMetaData container>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
            <StyledMessage onClick={handleToggleLike}>
              {isMyLike && <CommunityLikeFilledSvg style={{ cursor: 'pointer' }} color={colorToUse?.AccentColorCode} />}
              {!isMyLike && <CommunityLikeSvg style={{ cursor: 'pointer' }} color={colorToUse?.AccentColorCode} />}
            </StyledMessage>
            <StyledMessage onClick={handleToggleCommentInput}>
              <CommunityCommentSvg
                style={{ cursor: 'pointer' }}
                height="24px"
                width="24px"
                color={colorToUse?.AccentColorCode}
              />
            </StyledMessage>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
            {likeCount > 0 && (
              <StyledMessage
                onClick={() => {
                  setLikesModalOpen(true);
                }}
              >
                <span style={{ fontSize: '14px', opacity: '60%' }}>
                  {likeCount === 1 ? '1 like' : `${likeCount} likes`}
                </span>
              </StyledMessage>
            )}
            <StyledMessage onClick={handleShowComments}>
              <span style={{ fontSize: '14px', opacity: '60%' }}>
                {item.comments.length === 0
                  ? ''
                  : item.comments.length === 1
                  ? '1 comment'
                  : `${item.comments.length} comments`}
              </span>
            </StyledMessage>
          </div>
        </StyledMetaData>
      }
      <div id="YYYY">
        {showAllComments ? (
          map(item.comments, comment => {
            return (
              <>
                <Comment
                  commentData={comment}
                  themedColor={themedColor}
                  themedCardBackgroundColor={themedCardBackgroundColor}
                  themedBackgroundColor={themedBackgroundColor}
                  isCoach={isCoach}
                  user={user}
                  generateHTMLText={generateHTMLText}
                  handleToggleCommentLike={handleToggleCommentLike}
                  allComments={item.comments}
                  fetchPosts={fetchPosts}
                  pods={pods}
                  contributionData={contribution}
                />
              </>
            );
          })
        ) : (
          <>
            {' '}
            {showSingleComment && (
              <Comment
                commentData={item?.comments[0]}
                themedColor={themedColor}
                themedCardBackgroundColor={themedCardBackgroundColor}
                themedBackgroundColor={themedBackgroundColor}
                generateHTMLText={generateHTMLText}
                isCoach={isCoach}
                user={user}
                handleToggleCommentLike={handleToggleCommentLike}
                allComments={item.comments}
                fetchPosts={fetchPosts}
                pods={pods}
                contributionData={contribution}
              />
            )}
          </>
        )}
      </div>

      <Formik
        enableReinitialize
        initialValues={{
          text: '',
        }}
        validateOnMount
        isInitialValid={false}
        onSubmit={handleCommentCreate}
        id="post-comment-form"
      >
        {formProps => {
          return (
            <div
              style={
                isUnifiedCommunity
                  ? {
                      marginTop: '12px',
                      backgroundColor: 'rgba(250, 250, 250, 1)',
                      padding: '5px 10px',
                      borderRadius: '10px',
                    }
                  : {
                      marginTop: '12px',
                    }
              }
              className="create-post-root"
            >
              <Grid container className="flex-flow-column">
                <Grid container className="flex-flow-row align-items-start">
                  <Grid container className="flex-flow-row align-items-start">
                    {!isUnifiedCommunity && (
                      <Box component="div" display="flex" alignItems="center">
                        <AvatarComponentComment
                          alt={`${user.firstName} ${user.lastName}`}
                          src={user.avatarUrl}
                          mobileView={mobileView}
                        >
                          {`${user.firstName && user.firstName[0]}${user.lastName && user.lastName[0]}`}
                        </AvatarComponentComment>
                        <Box component="div">
                          <StyledName>
                            {user.firstName} {user.lastName}
                          </StyledName>
                        </Box>
                      </Box>
                    )}
                    <StyledPostOuterContainer>
                      <Grid
                        className={`${getClassName(
                          isDarkThemeEnabled,
                          isUnifiedCommunity,
                          'create-post-container',
                        )} flex-flow-row`}
                        container
                      >
                        <div
                          className={getClassName(
                            isDarkThemeEnabled,
                            isUnifiedCommunity,
                            'create-post-mention-input-container',
                          )}
                          style={
                            isUnifiedCommunity
                              ? {}
                              : {
                                  backgroundColor: isDarkThemeEnabled ? 'rgba(0, 0, 0, 0.1)' : 'rgba(250, 250, 250, 1)',
                                  padding: '5px 10px',
                                  borderRadius: '10px',
                                }
                          }
                        >
                          <UserMentionInput
                            placeholder="Add a comment..."
                            isCreate={false}
                            defaultValue={textareaValue}
                            users={isUnifiedCommunity ? [] : users}
                            onInputChange={handleKeyUp}
                            resetInputValue={resetUserMentionInputValue}
                            inputRef={mentionInputRef}
                            type="comment"
                            onResetInputValueCallback={() => {
                              setShowAllComments(true);
                              setResetUserMentionInputValue(false);
                            }}
                          />
                        </div>
                      </Grid>
                      <div
                        style={{
                          position: 'absolute',
                          bottom: isUnifiedCommunity ? '0px' : '5px',
                          right: isUnifiedCommunity ? '0px' : '5px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <div style={{ marginLeft: '15px' }}>
                          <HappyEmoji
                            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                            width="20px"
                            height="20px"
                            fill="#858585"
                          />
                        </div>
                        {showEmojiPicker && (
                          <ClickAwayListener
                            onClickAway={() => {
                              setShowEmojiPicker(false);
                            }}
                          >
                            <div style={{ position: 'absolute', bottom: '30px', right: '0px' }}>
                              <Picker
                                previewConfig={{
                                  showPreview: false,
                                }}
                                theme={!isUnifiedCommunity && isDarkThemeEnabled ? 'dark' : 'light'}
                                height={340}
                                width={300}
                                onEmojiClick={onEmojiClick}
                                disableAutoFocus={true}
                              />
                            </div>
                          </ClickAwayListener>
                        )}
                        <StyledButton
                          btnColor={btnColor}
                          isDarkThemeEnabled={!isUnifiedCommunity && isDarkThemeEnabled}
                          disabled={commentPostPending || (textareaValue?.length <= 0 && userMentions.length <= 0)}
                          className={
                            commentPostPending || (textareaValue?.length <= 0 && userMentions.length <= 0)
                              ? 'disabled'
                              : ''
                          }
                          backgroundColor={colorToUse?.PrimaryColorCode}
                          autoWidth
                          type="submit"
                          id="post-create-form"
                          onClick={formProps.handleSubmit}
                        >
                          {commentPostPending ? (
                            <CircularProgress
                              size={24}
                              className="chat-messages-history-list__load-more-messages-progress"
                            />
                          ) : (
                            <SendIcon
                              style={{
                                color: textColor,
                                height: '14px',
                                width: '14px',
                                marginBottom: '0px',
                                marginLeft: '1px',
                              }}
                              className="smoked-gary-color hoverable"
                            />
                          )}
                        </StyledButton>
                      </div>
                    </StyledPostOuterContainer>
                  </Grid>
                  {!formProps.isValid && (
                    <StyledTextError mobileView={mobileView}>{formProps.errors.text}</StyledTextError>
                  )}
                </Grid>
              </Grid>
            </div>
          );
        }}
      </Formik>
      {index !== totalUnreadPosts - 1 && (
        <hr
          style={{
            width: '100%',
            marginBottom: '0px',
            backgroundColor: !isUnifiedCommunity && isDarkThemeEnabled ? 'white' : '',
          }}
        />
      )}
      {index === totalUnreadPosts - 1 && (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <hr
            style={{
              width: '100%',
              marginBottom: '0px',
              backgroundColor: !isUnifiedCommunity && isDarkThemeEnabled ? 'white' : '',
            }}
          />
          <div
            ref={divRef}
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '8px',
              width: '515px',
              marginLeft: '5px',
              marginRight: '5px',
            }}
          >
            <p
              style={{
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '22px',
                marginTop: '5px',
                marginBottom: '0px',
                textAlign: 'center',
                width: 'max-content',
                color: !isUnifiedCommunity && isDarkThemeEnabled ? 'white' : 'rgba(0, 0, 0, 0.6)',
              }}
            >
              You&apos;re All Caught Up
            </p>
            <CommunityCelebrationSvg
              width="29px"
              height="29px"
              color={!isUnifiedCommunity && isDarkThemeEnabled ? 'white' : 'black'}
            />
          </div>
          <hr
            style={{
              width: '100%',
              marginBottom: '0px',
              backgroundColor: !isUnifiedCommunity && isDarkThemeEnabled ? 'white' : '',
            }}
          />
        </div>
      )}
      {modalOpened && (
        <CreatePost
          isUnifiedCommunity={isUnifiedCommunity}
          onModalClose={handleModalClose}
          isOpen={modalOpened}
          item={item}
          isEdit={true}
          onSubmit={handleModalClose}
          contributionData={contribution}
          fetchPosts={fetchPosts}
          isEditing={true}
        />
      )}
      {likesModalOpen && (
        <LikesModal
          isOpen={likesModalOpen}
          handleClose={() => {
            setLikesModalOpen(false);
          }}
          isDarkModeEnabled={isUnifiedCommunity ? false : contribution?.isDarkModeEnabled}
          likes={item.likes}
        />
      )}
    </StyledContainer>
  );
};

Post.propTypes = {
  item: PropTypes.shape({}),
  communityPosts: PropTypes.object,
  currentGroup: PropTypes.shape({}),
  index: PropTypes.number.isRequired,
};

const mapStateToProps = ({ communityPosts, contributions, communitySections: { currentGroup } }) => ({
  communityPosts,
  contribution: contributions.activeContribution,
  currentGroup,
});

export default connect(mapStateToProps)(Post);
