import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { BiCalendar } from 'react-icons/bi';
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonWraper from 'components/FormUI/Button';
import { Box, Grid } from '@material-ui/core';
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import axiosInstance from 'utils/axiosInstance';
import { get } from 'lodash/fp';
import { Icon } from '@mdi/react';

import { mdiShareVariant, mdiPlusCircle } from '@mdi/js';
import { fetchClientContribution, fetchCohealerContribution, setSessionId } from 'actions/contributions';
import VideoButton from 'components/FormUI/Button';
import { determineColorToUse, determineDarkThemedColorToUse, getThemedColors } from 'services/contributions.service';
import { downloadFileByLink, getIpGlobal, lightOrDark } from 'utils/utils';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { useAccount, useClientPreview, usePartnerCoach, useVideoChat } from 'hooks';
import { UserRoles } from 'helpers/constants';
import './LiveSession.scss';
import { addToGoogleCalender, getVideoUrl } from 'services/contributions.service';
import ParticipantsModal from 'pages/ContributionView/ContributionSessions/CourseSessions/Session/ParticipantsModal';
import LaunchRecordedSessionVideo from 'components/SessionButtons/LaunchRecordedSessionVideo';
import { useSessionTimeAttachments } from 'hooks/useSessionTimeAttachments';
import { CircularProgressWithLabel } from 'components/UI/CircularProgressWithLabel/CircularProgressWithLabel';
import { faPlus, faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import { RECORDING_VIDEO_STATUS, TIMEZONES } from 'constants.js';
import ShareSessionRecordingContainer from 'pages/ContributionView/components/ShareModal/Content/ShareSessionRecordingContainer';
import PopupOptions from 'components/PopupOptions/PopupOptions';
import { useEndVideoChat } from 'hooks';
import { getCohealerContribution } from 'services/contributions.service';
import LaunchSessionTime from 'components/SessionButtons/LaunchSessionTime';
import BookSessionTime from '../../CourseSessions/ClientCourseSessions/BookSessionTime';
import JoinSessionTime from 'components/SessionButtons/JoinSessionTime';
import UnbookSessionTime from '../../CourseSessions/ClientCourseSessions/UnbookSessionTime';
import Modal from 'components/UI/Modal';
import { useRouter } from 'hooks';
import useVideoPlayer from 'hooks/useVideoPlayer';
import {
  useActiveContributionSessionById,
  useActiveContributionSessionTimeOrContentById,
} from 'pages/ContributionView/hooks';
import { isArray, isUndefined } from 'lodash';
import { fetchContributionActions } from 'actions/contributions';
import { LiveSessionConvertToSelfPaced } from './LiveSessionConvertToSelfPaced';
import { useMemo } from 'react';
import LiveSessionVideo from './LiveSessionVideo';
import { memberPodsSelector } from 'selectors/MemberPods';
import { redirectToAppIfIsCoachDomain } from 'utils/subdomains';
import { BodyText } from 'components/UI/Text/TextStyles';
import { AddRecordingPlusIcon } from '../icons';
import { StyledDisabledPrimaryDarkButtonFilled, StyledDisabledPrimaryDarkButtonOutlined } from '../shared';
import { DarkEnabledPrimaryFilledButton, DarkEnabledPrimaryOutlineButton } from '../styled';
import { toRem } from 'utils/styles';
import { Link } from 'react-router-dom';
import { activeContribSessTabViewSettingsSelector } from 'selectors/contribution';
import { Tooltip } from '@material-ui/core';
import { TOOLTIP } from '../../constants';
import FullyBookedIcon from 'pages/ContributionView/icons/FullyBookedIcon';

const StyledHeader = styled.div`
  display: flex;
  height: 70px;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  border-bottom: 1px solid #dfe3e4;
`;

const StyledBody = styled.div`
  padding: 20px 24px;
  width: 100%;
`;

const StyledBodyCenter = styled.div`
  margin: 20px 0;
  padding: 20px 0;
  display: flex;
  justify-content: ${({ isCoach }) => (isCoach ? 'flex-start' : 'center')};
  flex-wrap: wrap;
  ${({ mobileView }) =>
    mobileView &&
    css`
      padding: 0;
      margin: 0;
      justify-content: space-between;
    `}
`;

const StyledTitle = styled.div`
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: 27px;
`;

const StyledSpan = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.94px;
`;
const CalendarContainer = styled.div`
  width: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const CalendarMenu = styled.div`
  width: 220px;
  background-color: white;
  color: black;
  box-shadow: 0 0px 10px 0px rgba(64, 60, 67, 0.16);
  position: absolute;
  ${({ mobileView }) =>
    mobileView
      ? css`
          margin-top: 65px;
        `
      : css`
          margin-top: 45px;
        `}
  z-index: 1000;
`;

const StyledAttachText = styled.span`
  text-transform: initial;
  text-align: center;
  font-family: Avenir;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 900;
  line-height: 1rem; /* 114.286% */
`;

const StyledVideoContainer = styled.div`
  height: '465px';
  width: 100%;
`;

const StyledProgressWrap = styled(Box)`
  width: 40px;
  height: 40px;
  margin-right: 10px;
  margin-top: -8px;
  margin-bottom: -8px;
`;
const StyledSessionStatus = styled.p`
  display: flex;
  padding: 0rem 0.375rem;
  align-items: flex-start;
  gap: 0.625rem;
  width: fit-content;

  border-radius: 0.25rem;
  border: 1px solid ${({ borderColor }) => borderColor || 'var(--Cohere-Bluish-Grey-Tint-03, #7d9cb8)'};
  background: ${({ backgroundColor }) => backgroundColor || 'var(--Cohere-Primary-White, #fff)'};
  // temporary
  margin-top: 30px;
  ${({ mobileView }) =>
    mobileView &&
    css`
      margin-top: 0;
      max-width: fit-content;
    `}
  div {
    color: ${({ textColor }) => textColor || 'var(--Cohere-Bluish-Grey-Tint-02, #47627b)'};
    font-family: Avenir;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
`;
const StyledPContainer = styled.div`
  ${({ mobileView }) =>
    mobileView &&
    css`
      flex: 1 !important;
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-bottom: 0;
      p {
        font-size: 0.9375rem;
      }
      p:nth-child(1) {
        flex-basis: 50%;
        margin-bottom: 0;
      }
      p:nth-child(2) {
        flex-basis: 50%;
        padding-top: 0;
      }
    `}
  p {
    color: ${({ textColor }) => textColor || 'rgba(0, 0, 0, 0.87)'};
  }
`;
const StyledP = styled.p`
  color: ${({ textColor }) => textColor || 'rgba(0, 0, 0, 0.87)'};
  display: flex;
  gap: 5px;
`;
const StyledLiveSessionTimeInfoContainer = styled.div`
  ${({ mobileView, borderColor }) =>
    mobileView
      ? borderColor &&
        css`
          border-color: ${borderColor || 'var(--Cohere-Greys-Grey-Tint, #DFE3E4)'};
        `
      : css`
          border: none;
          padding-top: 2rem;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          padding-left: 0;
          padding-right: 0;
        `}
`;
const StyledRescheduleButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 1.375rem;
  align-self: stretch;
`;
const StyledMainContainer = styled.div`
  width: 100%;
  > div {
    width: 100%;
  }
  ${({ mobileView }) =>
    mobileView &&
    css`
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 0.625rem;
    `}
  ${({ isBookingCompleted }) =>
    isBookingCompleted &&
    css`
      opacity: 0.3;
    `}
`;
const StyledDataListWrapper = styled.div`
  ${({ mobileView }) =>
    mobileView &&
    css`
      flex-direction: column;
      gap: 0.625rem;
    `}
`;
const StyledTextAddCalendar = styled.span`
  color: ${({ textColor }) => textColor || '#282b2b'};
  font-family: Avenir;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 800;
  line-height: 1.25rem; /* 142.857% */
  margin-top: 1px;
`;
const StyledVideoButton = styled(VideoButton)`
  /* max-height: 2.375rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem; */
`;
const StyledBiCalendar = styled(BiCalendar)`
  path {
    fill: ${({ isDarkModeEnabled, themedColorBtn }) => (isDarkModeEnabled ? themedColorBtn : '#282b2b')};
  }
`;
const StyledTimezoneContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  .label-text {
    color: ${({ textColor }) => textColor || '#000'};
    font-family: Avenir;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
const StyledTimeZoneLabel = styled.a`
  display: inline-block;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
  vertical-align: middle;
  color: var(--Cohere-Primary-White, #fff);
  font-family: Avenir;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const LiveSessionBody = ({ convertedToSelfPaced, index }) => {
  const { selectedSessionId, selectedSessionTimeOrContentId } = useSelector(activeContribSessTabViewSettingsSelector);
  const { selectedSession } = useActiveContributionSessionById();
  const { selectedSessionTimeOrContent } = useActiveContributionSessionTimeOrContentById();
  const { currentRole, user, isClient } = useAccount();
  const { id: sessionId, maxParticipantsNumber, isPrerecorded, dateAvailable, sessionTimes } = selectedSession || {};
  const { startTime, endTime, participantsIds, isCompleted, ignoreDateAvailable, podId } =
    selectedSessionTimeOrContent || {};
  const contribution = useContribution();
  const dispatch = useDispatch();
  const { generateLiveVideoLink, generateLiveVideoLinkWithPoster } = useVideoPlayer({
    selectedSessionTimeOrContent,
  });
  const [isParticipantsModalOpen, setIsParticipantsModalOpen] = useState(false);
  const [completeButtonClicked, setCompleteButtonClicked] = useState(false);
  const [isVisibleCalendarDropdown, setIsVisibleCalendarDropdown] = useState(false);
  const [popupCopmonent, setPopupComponent] = useState(null);
  const [isPasscodeEnabled, setIsPasscodeEnabled] = useState(selectedSessionTimeOrContent?.isPassCodeEnabled);
  const [passCode, setPassCode] = useState(null);
  const [buttonState, setButtonState] = useState(false);
  const [shouldVideoBeDisabled, setShouldVideoBeDisabled] = useState(false);
  const [isRescheduleModalOpen, setRescheduleModalState] = useState(false);
  const [videoLinks, setVideoLinks] = useState(null);
  const { token } = useVideoChat();
  const { protocol, domain, baseUrl, query } = useRouter();
  const dropdownRef = useRef(null);
  const { contributionMemberPods } = useSelector(memberPodsSelector);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  // const { partnerCoaches } = usePartnerCoach(query?.id);
  const isPartnerCoach = contribution?.contributionPartners.some(obj => obj.userId === user.id);
  const [sharedRecordingAdded, setSharedRecordingAdded] = useState(false);
  const { id, isDarkModeEnabled, timeZoneId, timeZoneShortName } = contribution;
  const sessionTimeId = useMemo(() => selectedSessionTimeOrContent?.id, [selectedSessionTimeOrContent?.id]);
  const isContributionPurchased = contribution.isPurchased;
  const canAccess = currentRole === UserRoles.cohealer || (currentRole === UserRoles.client && isContributionPurchased);
  const isCoach = currentRole === UserRoles.cohealer;
  const { clientPreviewMode } = useClientPreview();
  const { progress, isFileUpload, handleUploadFile } = useSessionTimeAttachments(
    selectedSessionTimeOrContent.id,
    contribution.id,
  );
  const { timeZones } = useSelector(state => state.timeZone);
  const getTimeZoneLabel = ({ contributionTimeZoneId, timeZones = [] }) => {
    const timeZone = timeZones.find(item => item?.countryName === contributionTimeZoneId);

    if (timeZone) {
      return timeZone.name;
    }

    return TIMEZONES[contributionTimeZoneId]; // fallback value
  };
  let colorToUse = determineColorToUse(contribution);
  const textColor2 =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const timeZoneLabel = getTimeZoneLabel({ contributionTimeZoneId: timeZoneId, timeZones });
  const userProfileUrl = new URL('/account/profile', `${protocol}//${domain}`).toString();

  const availabilityDate = isPrerecorded ? startTime : dateAvailable;
  const dateAvailableMoment = moment(availabilityDate);
  const startTimeMoment = moment(startTime);
  const endTimeMoment = moment(endTime);
  const makeOptions = recordingInfos => {
    return recordingInfos.map((info, i) => ({
      text: `${i + 1} ${selectedSession?.title || ''} ${moment.utc(info.duration * 1000).format('HH:mm:ss')}`,
      value: {
        contributionId: id,
        roomId: info.roomId,
        status: info.status,
        compositionFileName: info?.compositionFileName,
        sessionTimeId: selectedSessionTimeOrContent.id,
        isConvertedToSelfPaced: info?.isConvertedToSelfPaced,
      },
    }));
  };
  const options = makeOptions(selectedSessionTimeOrContent.recordingInfos);

  const checkIfSessionTimeBooked = (selectedSessionTimeOrContent, userId) => {
    const { participantsIds } = selectedSessionTimeOrContent;

    if (!participantsIds || participantsIds.length === 0) {
      return false;
    }

    return participantsIds.includes(userId);
  };

  const isHasPreRecordings = selectedSession.prerecordedSession || selectedSessionTimeOrContent?.prerecordedSession;
  const isBooked = checkIfSessionTimeBooked(selectedSessionTimeOrContent, user.id);
  const isHasRecordings =
    selectedSessionTimeOrContent.recordingInfos.length > 0 ||
    isPrerecorded ||
    selectedSessionTimeOrContent?.zoomMeetingData?.recordingFileName;
  const isViewRecordingButton = (isHasPreRecordings && isBooked) || isHasRecordings;
  const {
    themedColor: themedTextColor,
    themedCardBackgroundColor: oldThemedCardBackgroundColor,
    themedBackgroundColor: oldThemedBackgroundColor,
    newThemedTextColor,
    newThemedCardColor,
    newThemedBackgroundColor,
    themedCardOutlineColor,
    themedColorBtn,
  } = getThemedColors(contribution);
  const textColor = isCoach ? themedTextColor : newThemedTextColor;
  const themedColor = isCoach && !clientPreviewMode ? themedTextColor : newThemedTextColor;
  const isCohealer = currentRole === UserRoles?.cohealer;
  const preRecordedSession = selectedSessionTimeOrContent?.prerecordedSession || selectedSession?.prerecordedSession;
  const shouldShowViewButton =
    isCohealer ||
    selectedSessionTimeOrContent?.isCompleted ||
    selectedSession?.prerecordedSession ||
    selectedSessionTimeOrContent?.prerecordedSession ||
    !!selectedSessionTimeOrContent?.zoomMeetingData?.recordingFileName;

  const isFirstVideoViewable = useMemo(() => {
    return selectedSession?.isPrerecorded
      ? preRecordedSession?.status !== RECORDING_VIDEO_STATUS?.available
      : selectedSessionTimeOrContent?.recordingInfos[0]?.status === RECORDING_VIDEO_STATUS?.available ||
          !!selectedSessionTimeOrContent?.zoomMeetingData?.recordingFileName;
  }, [selectedSession, preRecordedSession, selectedSessionTimeOrContent]);
  const isConvertToSelfPacedAvailable =
    isCohealer &&
    selectedSessionTimeOrContent?.isCompleted &&
    !isPrerecorded &&
    isHasRecordings &&
    isFirstVideoViewable &&
    contribution.type != 'ContributionOneToOne';
  const podName = contributionMemberPods?.[id]?.reduce((acc, next) => {
    return next.id === podId ? next.name : acc;
  }, '');

  const getVideoLinks = useCallback(() => {
    setVideoLinks(null);
    if (isFirstVideoViewable) {
      const linksPromises = selectedSessionTimeOrContent.recordingInfos.map(item => {
        return generateLiveVideoLinkWithPoster({
          contributionId: contribution.id,
          roomId: item.roomId,
          poster: item.thumbnailUrl,
        });
      });
      Promise.allSettled(linksPromises).then(links => {
        setVideoLinks(links.map(k => k.value && k.value));
      });
    }
  }, [isFirstVideoViewable, selectedSessionTimeOrContent, contribution.id]);
  useEffect(() => {
    getVideoLinks();
  }, [isFirstVideoViewable, selectedSessionTimeOrContent]);

  const handleRefreshList = useCallback(async () => {
    getCohealerContribution(contribution.id).then(res => dispatch(fetchContributionActions.success(res)));
  }, [contribution.id, getCohealerContribution]);
  const { Popup, onShowPopup, handleSessionComplete } = useEndVideoChat(handleRefreshList);
  const checkVideoStatus = status => {
    if (status !== RECORDING_VIDEO_STATUS.available) {
      return false;
    }
    return true;
  };

  const isPurchased = contribution?.isPurchased;
  const handleParticipantsModal = () => participantsIds?.length > 0 && setIsParticipantsModalOpen(true);
  const clientDisable = !moment(startTime).isAfter(moment().add(24, 'h')) || isCompleted;
  const isRescheduleDisabled = contribution?.serviceProviderName ? clientDisable : isCompleted;

  const isShowAddToCalendar = () => {
    const isUserMatched = participantsIds.find(participant => participant === user.id);
    if ((isCoach || isUserMatched) && !contribution.externalCalendarEmail) {
      return true;
    }
    return false;
  };

  const handleOutsideClick = e => {
    if (!isVisibleCalendarDropdown) {
      return;
    }
    if (
      dropdownRef &&
      dropdownRef?.current &&
      !e?.target?.className?.includes('clickedOutSide') &&
      !dropdownRef?.current?.contains(e.target)
    ) {
      setIsVisibleCalendarDropdown(false);
    }
  };

  const onClickAddToGoogleCalender = async (activeSessionTime, type = false) => {
    const data = {
      contributionId: contribution.id,
      sessionTimeId: activeSessionTime.id,
      isCoach,
      isDownloadable: type === 'Download',
    };
    const addToCalenderURL = await addToGoogleCalender(data);
    window.open(addToCalenderURL, '_blank');
    setIsVisibleCalendarDropdown(!isVisibleCalendarDropdown);
  };

  const shareRecording = (contributionId, sessionTimeId) => {
    try {
      return axiosInstance
        .post(
          `api/SharedRecording/addSharedRecordingInfo?contributionId=${contributionId}&sessionTimeId=${sessionTimeId}`,
        )
        .then(get('data'));
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const handleShareButtonClick = () => {
    shareRecording(id, sessionTimeId).then(res => {
      setPopupComponent(true);
      setPassCode(res.passCode);
    });
  };
  const handleClosePopup = () => {
    setPopupComponent(false);
  };
  const checkIsFuture = date => {
    return moment(date).isAfter(moment(), 'day');
  };
  const isVideoViewable = () => {
    if (
      selectedSessionTimeOrContent?.prerecordedSession?.extension?.toLowerCase()?.includes('jpg') ||
      selectedSessionTimeOrContent?.prerecordedSession?.extension?.toLowerCase()?.includes('png') ||
      selectedSessionTimeOrContent?.prerecordedSession?.extension?.toLowerCase()?.includes('jpeg') ||
      selectedSessionTimeOrContent?.prerecordedSession?.extension?.toLowerCase()?.includes('pdf') ||
      selectedSessionTimeOrContent?.prerecordedSession?.extension?.toLowerCase()?.includes('doc') ||
      selectedSessionTimeOrContent?.prerecordedSession?.extension?.toLowerCase()?.includes('xls')
    ) {
      if (ignoreDateAvailable) {
        if (checkIsFuture(startTime)) {
          setShouldVideoBeDisabled(true);
        } else {
          setShouldVideoBeDisabled(false);
        }
      } else {
        setShouldVideoBeDisabled(false);
      }
    } else {
      if (selectedSessionTimeOrContent?.mustWatchPriorSelfPacedRecords) {
        if (selectedSessionTimeOrContent?.ignoreDateAvailable) {
          if (checkIsFuture(startTime)) {
            setShouldVideoBeDisabled(true);
          } else {
            setShouldVideoBeDisabled(false);
          }
        }
        // for (let i = sessionNumber; i >= 0; i--) {
        //   if (sessions[i].isPrerecorded) {
        //     if (!sessions[i]?.sessionTimes[0]?.usersWhoViewedRecording?.includes(user.id)) {
        //       setShouldVideoBeDisabled(true);
        //     } else {
        //       if (shouldVideoBeDisabled && !checkIsFuture(startTime)) {
        //         setShouldVideoBeDisabled(false);
        //       } else if (sessions[i]?.sessionTimes[0]?.usersWhoViewedRecording?.includes(user.id)) {
        //         setShouldVideoBeDisabled(false);
        //       }
        //     }
        //     break;
        //   }
        // }
      } else if (selectedSessionTimeOrContent?.ignoreDateAvailable && checkIsFuture(startTime)) {
        setShouldVideoBeDisabled(true);
      }
    }
  };
  const checkCompleteBtnStatus = (isVideoViewed = undefined) => {
    if (
      // (!isUndefined(isVideoViewed) && isVideoViewed) ||
      selectedSessionTimeOrContent?.completedSelfPacedParticipantIds?.includes(user.id)
    ) {
      setButtonState(true);
    } else if (
      selectedSessionTimeOrContent?.prerecordedSession?.extension?.toLowerCase()?.includes('jpg') ||
      selectedSessionTimeOrContent?.prerecordedSession?.extension?.toLowerCase()?.includes('png') ||
      selectedSessionTimeOrContent?.prerecordedSession?.extension?.toLowerCase()?.includes('jpeg') ||
      selectedSessionTimeOrContent?.prerecordedSession?.extension?.toLowerCase()?.includes('pdf') ||
      selectedSessionTimeOrContent?.prerecordedSession?.extension?.toLowerCase()?.includes('doc')
    ) {
      setButtonState(false);
    } else {
      if (selectedSessionTimeOrContent?.usersWhoViewedRecording?.includes(user.id)) {
        setButtonState(false);
      } else {
        setButtonState(true);
      }
    }
  };
  const refetch = (isVideoViewed = undefined) => {
    // !isUndefined(isVideoViewed) && !isVideoViewed && isVideoViewable();
    isVideoViewable();
    checkCompleteBtnStatus(isVideoViewed);
  };
  const handleReschedule = e => {
    e.preventDefault();
    e.stopPropagation();
    // setRescheduleModalState(true);
    // const nextState = {
    //   sessionId: selectedSessionId,
    //   contributionId: contribution.id,
    // };
    const editUrl = `/edit-contribution/${contribution.id}/sessions/${selectedSessionId}/${selectedSessionTimeOrContentId}`;
    // window.history.pushState(nextState, '', editUrl);
    // const contributionUrl = new URL(editUrl, baseUrl).toString();
    // window.location.href = contributionUrl;
    redirectToAppIfIsCoachDomain(domain, editUrl);
  };
  const refreshContribution = useCallback(async () => {
    const userIp = await getIpGlobal();
    dispatch(fetchClientContribution(id, userIp));
  }, [dispatch, id]);
  const getPassCode = async () => {
    const res = await shareRecording(id, sessionTimeId);
    setPassCode(res.passCode);
    setSharedRecordingAdded(true);
  };
  // useEffect(() => {

  //   if (!sharedRecordingAdded) {
  //     getPassCode();
  //   }
  // }, [id, sessionTimeId]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  });

  useEffect(() => {
    if (isClient && token) {
      return () => refreshContribution();
    }
  }, [token, refreshContribution]);
  const renderSessionTimeActionsClient = (selectedSession, selectedSessionTimeOrContent, sessionTimeIndex) => {
    if (!isPurchased) {
      return null;
    }
    const { maxParticipantsNumber, sessionTimes } = selectedSession;
    const { participantsIds, videoRoomInfo } = selectedSessionTimeOrContent;

    const isOtherSessionTimeBooked = sessionTimes
      .filter(st => st.id !== selectedSessionTimeOrContent.id)
      .some(st => checkIfSessionTimeBooked(st, user.id));
    const hasAvailableSeats = participantsIds.length < maxParticipantsNumber;
    const isSelfPaced = selectedSession?.isPrerecorded;
    const isLaunched = !!videoRoomInfo && videoRoomInfo.isRunning;

    const isHasRecordings =
      selectedSessionTimeOrContent.recordingInfos.length > 0 ||
      selectedSessionTimeOrContent?.zoomMeetingData?.recordingFileName;
    const isHasPreRecordings = selectedSession.prerecordedSession || selectedSessionTimeOrContent?.prerecordedSession;

    const isViewRecordingButton = (isHasPreRecordings && isBooked) || isHasRecordings;
    const isBookButton = !isSelfPaced && !isBooked && !isOtherSessionTimeBooked && hasAvailableSeats;
    const isDisabledCompleteButton =
      checkIsFuture(selectedSessionTimeOrContent.startTime) ||
      selectedSessionTimeOrContent?.completedSelfPacedParticipantIds?.includes(user.id);

    const isSelfPacedRecording =
      isSelfPaced && (selectedSessionTimeOrContent?.prerecordedSession || selectedSession.prerecordedSession);

    return (
      <>
        {!isSelfPacedRecording && !selectedSessionTimeOrContent.isCompleted && (
          <div className="client-course-session-time-actions">
            {isBookButton && (
              <BookSessionTime
                contribution={contribution}
                session={selectedSession}
                sessionTime={selectedSessionTimeOrContent}
                onSessionTimeBooked={refreshContribution}
                mobileView={mobileView}
                isSessionView
              />
            )}
            {isBooked && (
              <>
                {!isSelfPaced && (
                  <UnbookSessionTime
                    contribution={contribution}
                    session={selectedSession}
                    sessionTime={selectedSessionTimeOrContent}
                    onSessionTimeUnbooked={refreshContribution}
                    mobileView={mobileView}
                    isSessionView
                  />
                )}
                {!isHasPreRecordings && (
                  <JoinSessionTime
                    user={user}
                    contribution={contribution}
                    session={selectedSession}
                    sessionTime={selectedSessionTimeOrContent}
                    isLaunched={isLaunched}
                    liveVideoServiceProvider={contribution?.liveVideoServiceProvider}
                    mobileView={mobileView}
                    isSessionView
                  />
                )}
              </>
            )}
          </div>
        )}

        {isViewRecordingButton && !isSelfPacedRecording && (
          <LaunchRecordedSessionVideo
            contribution={contribution}
            session={selectedSession}
            sessionTime={selectedSessionTimeOrContent}
            mobileView={mobileView}
            sessionTimeIndex={sessionTimeIndex}
            isSessionView
          />
        )}

        {/* {isSelfPaced && <Popup />} */}
      </>
    );
  };
  const renderSessionTimeActions = (session, selectedSessionTimeOrContent, sessionTimeIndex) => {
    const isHasRecordings =
      selectedSessionTimeOrContent.recordingInfos.length > 0 ||
      session?.isPrerecorded ||
      selectedSessionTimeOrContent?.zoomMeetingData?.recordingFileName;
    const btnBackgroundColor = contribution?.isDarkModeEnabled ? 'transparent' : null;
    return (
      <>
        {!session?.isPrerecorded ? (
          !selectedSessionTimeOrContent.isCompleted ? (
            <DarkEnabledPrimaryOutlineButton
              textColor={colorToUse.PrimaryColorCode}
              borderColor={colorToUse.PrimaryColorCode}
              variant="outline"
              autoWidth
              onClick={() => {
                setCompleteButtonClicked(true);
                onShowPopup(
                  contribution.id,
                  selectedSessionTimeOrContent.id,
                  !selectedSessionTimeOrContent.isCompleted,
                );
              }}
              backgroundColor={btnBackgroundColor}
              {...{ isDarkModeEnabled }}
            >
              Complete
            </DarkEnabledPrimaryOutlineButton>
          ) : (
            <DarkEnabledPrimaryOutlineButton
              autoWidth
              textColor={colorToUse.PrimaryColorCode}
              borderColor={colorToUse.PrimaryColorCode}
              variant="outline"
              onClick={() => {
                setCompleteButtonClicked(false);
                handleSessionComplete(contribution.id, selectedSessionTimeOrContent.id);
              }}
              backgroundColor={btnBackgroundColor}
              {...{ isDarkModeEnabled }}
            >
              Unmark as Complete
            </DarkEnabledPrimaryOutlineButton>
          )
        ) : null}

        {!session?.isPrerecorded && (
          <div>
            <LaunchSessionTime
              user={user}
              contribution={contribution}
              session={session}
              sessionTime={selectedSessionTimeOrContent}
              isPartnerCoach={isPartnerCoach}
            />
          </div>
        )}
        {/* {session?.isPrerecorded && isHasRecordings && (
          <Video isPreviewMode session={session} sessionTime={selectedSessionTimeOrContent} sessionTimeIndex={sessionTimeIndex} />
        )}
        {isHasRecordings && (       
          <LaunchRecordedSessionVideo
            contribution={contribution}
            session={session}
            sessionTime={selectedSessionTimeOrContent}
            mobileView={mobileView}
            isPreviewMode
          />
        )} */}
      </>
    );
  };

  const isOtherSessionTimeBooked = sessionTimes
    .filter(st => st.id !== selectedSessionTimeOrContent.id)
    .some(st => checkIfSessionTimeBooked(st, user.id));
  const hasAvailableSeats = participantsIds.length < maxParticipantsNumber;
  const isSelfPaced = selectedSession?.isPrerecorded;
  const isBookButton = !isSelfPaced && !isBooked && !isOtherSessionTimeBooked && hasAvailableSeats;
  const isBookingCompleted = !isBooked && !isBookButton && !isCoach;

  return (
    <>
      {isBookingCompleted && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: mobileView ? '25%' : '50%',
            zIndex: '1',
            position: 'absolute',
            right: mobileView ? '50px' : '430px',
            gap: '10px',
          }}
        >
          <FullyBookedIcon />
          <div style={{ fontFamily: 'Avenir', fontSize: '15px', fontWeight: 800, color: '#000000DE' }}>
            Fully Booked
          </div>
          <div
            style={{ fontFamily: 'Avenir', fontSize: '14px', fontWeight: 500, color: '#000000DE' }}
          >{`All ${maxParticipantsNumber} seats for this live session have been booked.`}</div>
        </div>
      )}
      <StyledMainContainer isBookingCompleted={isBookingCompleted} mobileView={mobileView}>
        <>
          <div
            style={
              false
                ? {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    ...(!mobileView && { marginTop: '10px' }),
                  }
                : {}
            }
          >
            {false && (
              <StyledTimezoneContainer>
                <div className="label-text" style={{ color: textColor2 }}>
                  Session Times
                </div>
                <StyledTimeZoneLabel
                  href={userProfileUrl}
                  style={{ backgroundColor: colorToUse?.PrimaryColorCode, color: textColor2 }}
                >
                  {timeZoneLabel}
                </StyledTimeZoneLabel>
              </StyledTimezoneContainer>
            )}
            {mobileView && (
              <>
                {isCoach && (
                  <StyledRescheduleButtonRow className="reschedule-button-row">
                    <DarkEnabledPrimaryFilledButton
                      // textColor={contribution?.isDarkModeEnabled ? textColor2 : textColor}
                      textColor={textColor2}
                      backgroundColor={colorToUse?.PrimaryColorCode}
                      autoWidth
                      // disabled={buttonState}
                      // disabled={isRescheduleDisabled}
                      onClick={handleReschedule}
                      // style={{ alignSelf: 'flex-end' }}
                      {...{ isDarkModeEnabled }}
                    >
                      Reschedule
                    </DarkEnabledPrimaryFilledButton>
                  </StyledRescheduleButtonRow>
                )}
              </>
            )}
          </div>
          <StyledLiveSessionTimeInfoContainer
            className="course-live-session-time"
            borderColor={contribution?.isDarkModeEnabled && themedCardOutlineColor}
            {...{ mobileView }}
          >
            {!isPrerecorded && (
              <>
                <div className="course-live-session-times-block-wrapper">
                  <StyledDataListWrapper className="course-live-session-times-wrapper" {...{ mobileView }}>
                    <StyledPContainer
                      className={ignoreDateAvailable ? '' : 'course-live-session-time__wrapper'}
                      style={{ flexBasis: '176px', flexGrow: 176 }}
                      {...{ textColor, mobileView }}
                    >
                      {isPrerecorded ? (
                        <>
                          {ignoreDateAvailable && (
                            <>
                              <StyledP className="course-live-session-time__label">Date Available:</StyledP>
                              <StyledP className="course-live-session-time__value">
                                {dateAvailableMoment.format('MMMM Do YYYY')}
                              </StyledP>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <StyledP className="course-live-session-time__label">Date:</StyledP>
                          <StyledP className="course-live-session-time__value">
                            {startTimeMoment.format('MMMM Do YYYY')}
                          </StyledP>
                        </>
                      )}
                    </StyledPContainer>
                    {!isPrerecorded && (
                      <StyledPContainer
                        className="course-live-session-time__wrapper"
                        style={{ flexBasis: '110px', flexGrow: 110 }}
                        {...{ textColor, mobileView }}
                      >
                        <StyledP className="course-live-session-time__label">Start Time:</StyledP>
                        <StyledP className="course-live-session-time__value">
                          {startTimeMoment.format('h:mm a')}
                        </StyledP>
                      </StyledPContainer>
                    )}
                    {!isPrerecorded && (
                      <StyledPContainer
                        className="course-live-session-time__wrapper"
                        style={{ flexBasis: '110px', flexGrow: 110 }}
                        {...{ textColor, mobileView }}
                      >
                        <StyledP className="course-live-session-time__label">End Time:</StyledP>
                        <StyledP className="course-live-session-time__value">{endTimeMoment.format('h:mm a')}</StyledP>
                      </StyledPContainer>
                    )}
                    {!isPrerecorded && (
                      <StyledPContainer
                        className="course-live-session-time__wrapper"
                        style={{ flexBasis: '110px', flexGrow: 110 }}
                        {...{ textColor, mobileView }}
                      >
                        <StyledP className="course-live-session-time__label">Time Zone:</StyledP>
                        <StyledP className="course-live-session-time__value" style={{ textDecoration: 'underline' }}>
                          {/* <a href={`${baseUrl}/account/profile`}>{timeZoneShortName || timeZoneLabel}</a> */}
                          <a href={userProfileUrl}>{timeZoneShortName || timeZoneLabel}</a>
                        </StyledP>
                      </StyledPContainer>
                    )}

                    {!isPrerecorded && (
                      <StyledPContainer
                        className="course-live-session-time__wrapper"
                        style={{ flexBasis: '110px', flexGrow: 110 }}
                        {...{ textColor, mobileView }}
                      >
                        <StyledP className="course-live-session-time__label">Status:</StyledP>
                        <StyledSessionStatus
                          className="course-live-session-time__value"
                          {...{
                            textColor: colorToUse?.AccentColorCode,
                            borderColor: colorToUse?.AccentColorCode,
                            backgroundColor: 'transparent',
                            mobileView,
                          }}
                        >
                          <div>{isCompleted ? 'Completed' : 'Upcoming'}</div>
                        </StyledSessionStatus>
                      </StyledPContainer>
                    )}
                    {!isPrerecorded && isCoach && !clientPreviewMode && (
                      <>
                        <StyledPContainer
                          className="course-live-session-time__wrapper course-live-session-time__wrapper--pointer"
                          style={{ flexBasis: '110px', flexGrow: 110 }}
                          onClick={handleParticipantsModal}
                          {...{ textColor, mobileView }}
                        >
                          <StyledP className="course-live-session-time__label">Participants:</StyledP>
                          <StyledP className="course-live-session-time__value">
                            {participantsIds.length}/{maxParticipantsNumber}
                          </StyledP>
                        </StyledPContainer>
                        {isParticipantsModalOpen && (
                          <ParticipantsModal
                            memberIds={participantsIds}
                            open={isParticipantsModalOpen}
                            onOpenChange={setIsParticipantsModalOpen}
                          />
                        )}
                      </>
                    )}
                    {!isPrerecorded &&
                      !isCoach &&
                      !isEmpty(user) &&
                      !clientPreviewMode &&
                      contribution.isLiveSessionMembersHidden === false && (
                        <StyledPContainer
                          className="course-live-session-time__wrapper"
                          style={{ flexBasis: '110px', flexGrow: 110 }}
                          {...{ textColor, mobileView }}
                        >
                          <StyledP className="course-live-session-time__label">Seats Available:</StyledP>
                          <StyledP className="course-live-session-time__value">
                            {maxParticipantsNumber - participantsIds.length}/{maxParticipantsNumber}
                          </StyledP>
                        </StyledPContainer>
                      )}
                    {podName && (
                      <StyledPContainer
                        className="course-live-session-time__wrapper"
                        style={{ flexBasis: '110px', flexGrow: 110 }}
                        {...{ textColor, mobileView }}
                      >
                        <StyledP className="course-live-session-time__label">Group:</StyledP>
                        <StyledP className="course-live-session-time__value">{podName}</StyledP>
                      </StyledPContainer>
                    )}
                    {isShowAddToCalendar() && (
                      <StyledPContainer
                        style={{
                          alignSelf: 'flex-start',
                          ...(mobileView &&
                            {
                              // paddingLeft: 'calc(50% - 0.875rem)',
                              // Note: uncomment below 2 lines to align it in 2nd column
                              // paddingLeft: '50%',
                              // paddingRight: 0,
                            }),
                        }}
                        className="course-live-session-time__wrapper course-live-session-time__wrapper--pointer"
                        {...{ textColor, mobileView }}
                      >
                        <StyledP
                          ref={dropdownRef}
                          onClick={() => setIsVisibleCalendarDropdown(!isVisibleCalendarDropdown)}
                          style={{ cursor: 'pointer', fontWeight: 'bold', ...(mobileView && { flexBasis: 'auto' }) }}
                          className="course-live-session-time__label"
                        >
                          <StyledBiCalendar style={{ fontSize: 20 }} {...{ isDarkModeEnabled, themedColorBtn }} />{' '}
                          <StyledTextAddCalendar {...{ textColor }}>Add to Calendar</StyledTextAddCalendar>{' '}
                          {!isVisibleCalendarDropdown ? (
                            <RiArrowDropDownLine style={{ fontSize: 20 }} />
                          ) : (
                            <RiArrowDropUpLine style={{ fontSize: 20 }} />
                          )}
                        </StyledP>
                        {isVisibleCalendarDropdown && (
                          <CalendarContainer className="clickedOutSide" ref={dropdownRef}>
                            <CalendarMenu className="clickedOutSide" mobileView={mobileView}>
                              <MenuItem
                                onClick={() => onClickAddToGoogleCalender(selectedSessionTimeOrContent, 'Download')}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  borderBottom: '0.5px solid gray',
                                  ...(isDarkModeEnabled && {
                                    backgroundColor: newThemedBackgroundColor,
                                    color: textColor,
                                  }),
                                }}
                              >
                                Download iCal
                              </MenuItem>
                              <MenuItem
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  ...(isDarkModeEnabled && {
                                    backgroundColor: newThemedBackgroundColor,
                                    color: themedColor,
                                  }),
                                }}
                                onClick={() => onClickAddToGoogleCalender(selectedSessionTimeOrContent)}
                              >
                                Add to Google Calendar
                              </MenuItem>
                            </CalendarMenu>
                          </CalendarContainer>
                        )}
                      </StyledPContainer>
                    )}
                    {/* {podName && (
                  <div className="course-session-time__wrapper">
                    <p className="course-session-time__label">Group</p>
                    <p className="course-session-time__value">{podName}</p>
                  </div>
                )} */}
                  </StyledDataListWrapper>
                </div>
              </>
            )}
          </StyledLiveSessionTimeInfoContainer>

          <StyledBodyCenter {...{ mobileView, isCoach }}>
            <div
              className={`${
                isPrerecorded ? 'course-live-session-time__self-paced-actions' : 'course-live-session-time__actions'
              }`}
              style={{
                flexWrap: isArray(videoLinks) && videoLinks?.length ? 'wrap' : 'wrap-reverse',
                ...(!isClient && { gap: '0.625rem' }),
                ...(mobileView &&
                  isArray(videoLinks) &&
                  videoLinks?.length && { width: '100%', flexWrap: 'wrap-reverse' }),
                justifyContent: 'flex-end',
                flexDirection: 'row-reverse',
              }}
            >
              {!isPrerecorded && isCoach && (
                <>
                  {isViewRecordingButton && (
                    <LaunchRecordedSessionVideo
                      contribution={contribution}
                      session={selectedSession}
                      sessionTime={selectedSessionTimeOrContent}
                      mobileView={mobileView}
                      isPreviewMode
                      refetch={refetch}
                      isSessionView
                    />
                  )}
                </>
              )}
              {isCoach
                ? renderSessionTimeActions(selectedSession, selectedSessionTimeOrContent, index)
                : renderSessionTimeActionsClient(selectedSession, selectedSessionTimeOrContent, index)}
            </div>
            <div
              className={`${
                isPrerecorded ? 'course-live-session-time__self-paced-actions' : 'course-live-session-time__actions'
              }`}
              style={{
                ...(mobileView && !isPrerecorded && isArray(videoLinks) && videoLinks?.length && { width: '100%' }),
                ...(!mobileView && { marginLeft: '2px' }),
              }}
            >
              {isCoach && !isPrerecorded && (
                <>
                  <div
                    style={{
                      marginTop: '0.625rem',
                      display: 'flex',
                      flexWrap: 'wrap',
                      ...(mobileView && {
                        // justifyContent: isArray(videoLinks) && videoLinks?.length ? 'space-between' : 'flex-end',
                        // justifyContent: isConvertToSelfPacedAvailable ? 'space-between' : 'flex-end',
                        justifyContent: 'flex-start',
                        gap: '0.625rem',
                        ...(isConvertToSelfPacedAvailable && { justifyContent: 'space-between', gap: 0 }),
                      }),
                    }}
                    className="course-live-session-time__attach-recording-container"
                  >
                    {!clientPreviewMode && (
                      <div className="course-live-session-time__attach-recording">
                        <input
                          accept="*"
                          className="d-none"
                          id={`attach-file-${selectedSessionTimeOrContent.id}`}
                          type="file"
                          onChange={handleUploadFile}
                          multiple
                        />
                        <label htmlFor={`attach-file-${selectedSessionTimeOrContent.id}`}>
                          <Button
                            disabled={isFileUpload}
                            classes={{
                              root: 'position-left text-capitalize attach-button',
                            }}
                            style={mobileView ? { paddingLeft: 0, paddingRight: 0, width: 'fit-content' } : {}}
                            disableRipple
                            component="span"
                            startIcon={
                              isFileUpload ? (
                                <StyledProgressWrap position="relative">
                                  <CircularProgressWithLabel // color={themedColor}
                                    value={progress}
                                    {...{ textColor, progressColor: colorToUse?.AccentColorCode }}
                                  />
                                </StyledProgressWrap>
                              ) : (
                                <span
                                  style={{
                                    height: '1.5rem',
                                    width: '1.5rem',
                                    borderRadius: 100,
                                    // backgroundColor: colorToUse?.PrimaryColorCode,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                  }}
                                >
                                  <AddRecordingPlusIcon fillColor={colorToUse?.PrimaryColorCode} />
                                </span>
                              )
                            }
                          >
                            {/* <Icon size={1} color={colorToUse?.PrimaryColorCode} path={mdiPlusCircle} /> */}
                            <Tooltip
                              title="For best results, upload video of size 16:9."
                              arrow
                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                            >
                              <StyledAttachText
                                className="regular-text"
                                style={{ color: colorToUse?.PrimaryColorCode }}
                              >
                                Add Recording
                              </StyledAttachText>
                            </Tooltip>
                          </Button>
                        </label>
                      </div>
                    )}
                    {(shouldShowViewButton || isFirstVideoViewable) && (
                      <>
                        {isFirstVideoViewable && isCohealer && (
                          <>
                            <div className="course-session-time__attach-recording">
                              <Button
                                classes={{
                                  root: 'position-right text-capitalize attach-button',
                                }}
                                style={mobileView ? { paddingRight: 0, width: 'fit-content' } : {}}
                                component="span"
                                onClick={handleShareButtonClick}
                                startIcon={
                                  <Icon size={1} color={colorToUse?.PrimaryColorCode} path={mdiShareVariant} />
                                }
                              >
                                <StyledAttachText
                                  className="regular-text"
                                  style={{ color: colorToUse?.PrimaryColorCode }}
                                >
                                  Share Recording
                                </StyledAttachText>
                              </Button>
                            </div>
                            {popupCopmonent && (
                              <ShareSessionRecordingContainer
                                applyTheming
                                onClose={handleClosePopup}
                                isOpen={popupCopmonent}
                                contributionId={id}
                                sessionTimeId={selectedSessionTimeOrContent?.id || ''}
                                isPasscodeEnabled={isPasscodeEnabled}
                                setIsPasscodeEnabled={setIsPasscodeEnabled}
                                passCode={passCode}
                              />
                            )}
                          </>
                        )}
                      </>
                    )}
                    {isConvertToSelfPacedAvailable && (
                      <LiveSessionConvertToSelfPaced {...{ options, selectedSession, isDarkModeEnabled }} />
                    )}
                  </div>
                </>
              )}
            </div>
          </StyledBodyCenter>

          {isArray(videoLinks) && videoLinks.length && (shouldShowViewButton || isFirstVideoViewable) && (
            // <StyledVideoContainer>
            //   <VideoCarousel videoSources={videoLinks} />
            // </StyledVideoContainer>
            <LiveSessionVideo
              videoSources={videoLinks}
              session={selectedSession}
              sessionTime={selectedSessionTimeOrContent}
            />
          )}
        </>
        {isRescheduleModalOpen && (
          <Modal
            isOpen={isRescheduleModalOpen}
            onCancel={() => {
              setRescheduleModalState(false);
            }}
            PrimaryColor={colorToUse?.PrimaryColorCode}
            textColor={textColor}
            onSubmit={() => {
              // setRedirectToEditContributionPage(true);
              redirectToAppIfIsCoachDomain(domain, `/edit-contribution/${contribution?.id}/sessions/${sessionId}`);
              setRescheduleModalState(false);
            }}
            title="Reschedule your session"
            hiddenCancel
            submitTitle="Reschedule Session"
            disableSubmitClick={isCompleted}
          >
            <BodyText color={themedColor}>
              To reschedule, please select a new time from your calendar. Also, please confirm with your client that the
              new time works for them prior to making the change. It will automatically update the time for all parties
              once you confirm.
              <br />
              {/* <br/>
              If you need to cancel with a client, your Client will need to cancel on their end.
              At this time, Cohere does not automate refunds and thus requires approval from your Client.
              If you think your client needs a refund, please email us at <a href="mailto:support@cohere.live">support@cohere.live</a>. */}
            </BodyText>
          </Modal>
        )}
        {completeButtonClicked && <Popup applyTheming />}
      </StyledMainContainer>
    </>
  );
};

export default LiveSessionBody;
