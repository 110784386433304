import React from 'react';

const FullyBookedIcon = ({ width, height, color = '#C9B382' }) => {
  return (
    <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="60" height="60" rx="30" fill="#F1F8FF" />
      <path
        d="M20.2857 47.5C19.3952 47.5 18.6332 47.1832 17.9996 46.5496C17.366 45.916 17.0487 45.1535 17.0476 44.2619V28.0714C17.0476 27.181 17.365 26.4189 17.9996 25.7853C18.6343 25.1517 19.3963 24.8344 20.2857 24.8333H21.9048V21.5952C21.9048 19.3556 22.6943 17.4467 24.2734 15.8687C25.8525 14.2906 27.7614 13.5011 30 13.5C32.2397 13.5 34.1491 14.2896 35.7282 15.8687C37.3073 17.4478 38.0963 19.3566 38.0952 21.5952V24.8333H39.7143C40.6048 24.8333 41.3673 25.1507 42.002 25.7853C42.6367 26.42 42.9535 27.182 42.9524 28.0714V44.2619C42.9524 45.1524 42.6356 45.915 42.002 46.5496C41.3684 47.1843 40.6058 47.5011 39.7143 47.5H20.2857ZM30 39.4048C30.8905 39.4048 31.653 39.088 32.2877 38.4544C32.9224 37.8208 33.2392 37.0582 33.2381 36.1667C33.2381 35.2762 32.9213 34.5142 32.2877 33.8806C31.6541 33.247 30.8916 32.9296 30 32.9286C29.1095 32.9286 28.3475 33.2459 27.7139 33.8806C27.0803 34.5152 26.763 35.2773 26.7619 36.1667C26.7619 37.0571 27.0792 37.8197 27.7139 38.4544C28.3486 39.089 29.1106 39.4058 30 39.4048ZM25.1429 24.8333H34.8571V21.5952C34.8571 20.246 34.3849 19.0992 33.4405 18.1548C32.496 17.2103 31.3492 16.7381 30 16.7381C28.6508 16.7381 27.504 17.2103 26.5595 18.1548C25.6151 19.0992 25.1429 20.246 25.1429 21.5952V24.8333Z"
        fill="#7D9CB8"
      />
    </svg>
  );
};

export default FullyBookedIcon;
