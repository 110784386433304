import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
// import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import * as R from 'ramda';
import { TOOLTIP } from '../../../../src/constants';
import { Grid, makeStyles, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { colors } from 'utils/styles';
import { get } from 'lodash/fp';
import { FieldArray } from 'formik';

import NoteModal from 'pages/ContributionView/components/NoteModal/NoteModalContainer';
import { useSessionAttachments } from 'hooks/useSessionAttachments';
import useContribution from '../../ContributionView/hooks/useContribution';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import { isEmptyOrNil } from 'utils/utils';
import './BottomPanel.scss';
import { CircularProgressWithLabel } from 'components/UI/CircularProgressWithLabel/CircularProgressWithLabel';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Axios from 'axios';
import { useRouter } from 'hooks';
import axiosInstance from 'utils/axiosInstance';
import Password from 'antd/lib/input/Password';
import { useDispatch } from 'react-redux';
import { setShowCreateModal } from 'actions/quizes';
import Button from 'components/FormUI/Button';

const useStyles = makeStyles(theme => ({
  display_control: {
    // display: 'grid',
    // gridTemplateColumns: '1fr 1fr 1fr',
    // gridTemplateColumns: '1fr',
    // gridRowGap: '15px',
    display: 'flex',
    // alignItems: 'center',
    paddingTop: '15px',
    gap: '16px',
    [theme.breakpoints.down('600')]: {
      display: 'block',
    },
  },
}));
const StyledAttachmentText = styled.span`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 800;
  line-height: 24.99px;
  text-align: left;
  color: #000000de;
`;
const StyledHeading = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 800;
  line-height: 22.12px;
  text-align: left;
  color: #000000de;
  padding-bottom: 6px;
`;
const StyledAddQuizFormBtn = styled(Button)`
  background-color: #f5f5f5;
  color: #215c73;
  padding: 9px 8px;
  font-size: 14px;
  font-weight: 800;
  border: 1px solid #e7e7e7;
  letter-spacing: 0px;
  margin-top: 15px;
  &:hover {
    background-color: #f5f5f5;
  }
`;
const StyledButton = styled.div`
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  color: #215c73;
  padding: 7px 20px 7px 4px;
  font-size: 14px;
  font-weight: 800;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  letter-spacing: 0px;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
`;
const StyledProgressWrap = styled(Box)`
  width: 40px;
  height: 40px;
  margin-right: 10px;
  margin-top: -8px;
  margin-bottom: -8px;
`;
const StyledAttachText = styled.span`
  color: #215c73;
  font-family: Avenir;
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
  text-align: left;
`;
const StyledFilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const StyledFile = styled.div`
  display: flex;
`;

const StyledFileLink = styled.a`
  // color: ${colors.darkOceanBlue};
  color: #000000de;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-left: -6px;
  padding: 6px 6px 6px 0;
  // max-width: 230px;

  &:hover {
    color: ${colors.darkOceanBlue};
  }
`;

const ZERO = 0;

export const BottomPanel = ({
  title,
  sessionTimeId,
  session,
  contributionId,
  attachments,
  sessionIndex,
  sessionTimeIndex,
  push,
  remove,
  form,
}) => {
  const classes = useStyles();
  const [modalOpened, setModalOpened] = useState(false);
  const { sessions } = form.values;
  const { query } = useRouter();
  const isEditing = Boolean(query.id);
  const dispatch = useDispatch();
  const {
    progress,
    isFileUpload,
    isCoach,
    handleUploadFile,
    handleDownloadFile,
    handleRemoveFile,
  } = useSessionAttachments(sessionTimeId, contributionId);

  const handleOpenModal = () => {
    setModalOpened(true);
  };

  const handleCloseModal = () => {
    setModalOpened(false);
  };

  const deleteIt = idx => {
    const file = attachments[idx];
    const data = {
      DocumentKeyWithExtension: file.documentKeyWithExtension,
      DocumentId: file.id,
      ContributionId: isEditing ? contributionId : '0',
      SessionId: '0',
    };

    if (isEditing) {
      axiosInstance
        .post('/Content/DeleteAttachment-Selfpaced', data)
        .then(res => {
          remove(idx);
        })
        .catch(() => {
          remove(idx);
        });
    } else {
      axiosInstance.post('/Content/DeleteSelfpacedVideoOnContributionCreation', data).then(res => {
        remove(idx);
      });
    }
  };

  const uploadIt = ({ target: { files } }) => {
    if (attachments.length < 5) {
      const formData = new FormData();
      const file = files[0];
      formData.append('file', file);
      axiosInstance
        .post('/Content/AddAttachmentOnCreate', formData, {
          'Content-Type': 'multipart/form-data',
        })
        .then(res => {
          if (res.status == 200) {
            push(res.data[0]);
          }
        });
    }
    // }
  };

  return (
    <div className="session-attachments">
      {/* <span tabIndex="0" role="button" className="h4 notes" onClick={handleOpenModal} onKeyPress={handleOpenModal}>
              <span>Personal Notes</span>
              <span className="ml-3 session-empty-panel-content">Open Notes</span>
      </span> */}
      <Grid container>
        <Grid item lg={6} sx={12}>
          <StyledAttachmentText>Attachments:</StyledAttachmentText>
          <div className={classes.display_control}>
            <StyledFilesWrapper style={{ width: '100%' }}>
              {isEmptyOrNil(attachments) ? (
                <span style={{ marginTop: '10px' }} className="session-empty-panel-content">
                  None
                </span>
              ) : (
                attachments?.map(
                  (
                    {
                      id: documentId,
                      contentType,
                      attachementUrl,
                      documentKeyWithExtension,
                      documentOriginalNameWithExtension,
                    },
                    idx,
                  ) => (
                    <div key={documentId} className="d-flex align-items-center">
                      {/* <Button
                  classes={{
                    root: 'text-lowercase regular-tex download-attach',
                  }}
                  onClick={handleDownloadFile}
                  disableRipple
                  data-document-id={documentId}
                  data-document-name={documentOriginalNameWithExtension}
                  data-content-type={contentType}
                >
                  <span>{`${R.inc(idx)}.`}</span>
                  <span className="ml-2">{documentOriginalNameWithExtension}</span>
                  
                </Button> */}
                      <Tooltip
                        title={documentOriginalNameWithExtension}
                        arrow
                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                      >
                        <StyledFileLink href={attachementUrl} download target="_blank">
                          <DescriptionOutlinedIcon style={{ fontSize: 26, color: '#000000DE' }} />
                          {/* {documentOriginalNameWithExtension?.length > 15
                      ? documentOriginalNameWithExtension.substr(0, 7) +
                      '...' +
                      documentOriginalNameWithExtension.substr(-10)
                      : documentOriginalNameWithExtension} */}
                          {documentOriginalNameWithExtension}
                        </StyledFileLink>
                      </Tooltip>
                      {isCoach && (
                        <CloseIcon
                          fontSize="small"
                          className="cursor-pointer"
                          onClick={() => {
                            deleteIt(idx);
                          }}
                          data-document-id={documentId}
                          data-document-extension={documentKeyWithExtension}
                        />
                      )}
                    </div>
                  ),
                )
              )}
            </StyledFilesWrapper>

            {isCoach && (
              <>
                <input
                  accept="*"
                  className="d-none"
                  id={`attach-file-${sessionIndex}-${sessionTimeIndex}`}
                  type="file"
                  // onChange={handleUploadFile}
                  onChange={uploadIt}
                  multiple
                />
                <div style={{ width: '100%' }}>
                  <label htmlFor={`attach-file-${sessionIndex}-${sessionTimeIndex}`} style={{ marginBottom: '0px' }}>
                    <StyledButton
                      disabled={attachments?.length >= 5}
                      classes={{
                        root: 'position-absolute text-capitalize attach-button',
                      }}
                      disableRipple
                      component="span"
                      autoWidth
                    >
                      {isFileUpload ? (
                        <StyledProgressWrap position="relative">
                          <CircularProgressWithLabel value={progress} />
                        </StyledProgressWrap>
                      ) : (
                        <AttachFileIcon />
                      )}
                      <StyledAttachText>Attach a File</StyledAttachText>
                      <Tooltip
                        title="You can attach up to 5 files per content per module"
                        arrow
                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        style={{ marginLeft: '5px' }}
                      >
                        <InfoIcon htmlColor={colors.lightBrown} />
                      </Tooltip>
                    </StyledButton>
                  </label>
                </div>
              </>
            )}
          </div>
        </Grid>

        {/* <Grid item lg={6} sx={12}>
          <StyledHeading style={{ margin: '0px' }}>Quiz/Form:</StyledHeading>
          <StyledAddQuizFormBtn
            autoWidth
            onClick={() => {
              dispatch(setShowCreateModal(true));
            }}
          >
            Add Quiz/Form
          </StyledAddQuizFormBtn>
        </Grid> */}
      </Grid>

      {/* {modalOpened && (
              <NoteModal
                noteProps={{
                  title,
                  classId: sessionId,
                  contributionId,
                }}
                isOpen={modalOpened}
                onModalClose={handleCloseModal}
                onConfirm={handleCloseModal}
              />    
        )} */}
    </div>
  );
};

BottomPanel.propTypes = {
  //   title: PropTypes.string.isRequired,
  sessionTimeId: PropTypes.string.isRequired,
  contributionId: PropTypes.string.isRequired,
  attachments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
