import React from 'react';
import styled from 'styled-components';
import Modal from 'components/UI/Modal';
import CloseIcon from '@material-ui/icons/Close';
import getInitials from 'utils/getInitials';
import { Avatar, useMediaQuery, useTheme } from '@material-ui/core';

const StyledModal = styled(Modal)`
  padding: ${({ mobileView }) => (mobileView ? '20px 5px !important' : '')};

  .cross-icon-hide {
    padding-top: 0px !important;
  }

  .body {
    padding: {({ mobileView }) => (mobileView ? '0px 4px !important' : '0px 16px !important')};
    max-height: 75vh !important;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  position: relative;
  width: 100%;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  margin-right: -10px;
  margin-top: 10px;
  ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? 'color: #fff' : 'color: black')}
`;

const LikesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#2d2f31' : '#fff')};
  color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#fff' : '#000')};
  width: ${({ mobileView }) => (mobileView ? '330px' : '380px')};
`;

const LikesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px 0px;
  width: 100%;
  text-align: left;
`;

const Title = styled.p`
  color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? 'white' : 'rgba(0, 0, 0, 0.87)')};
  font-style: normal;
  line-height: normal;
  margin-top: 15px;
  margin-bottom: 0px;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 800;
  text-align: left;
  width: 100%;
`;

const LikeItem = styled.li`
  padding: 10px 0px;
  color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#fff' : '#000')};
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
`;

const StyledAvatar = styled(Avatar)`
  height: 48px;
  width: 48px;
  margin-left: 0px !important;
`;

const LikesModal = ({ isOpen, handleClose, isDarkModeEnabled, likes }) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  const handleCancel = () => {
    handleClose();
  };

  return (
    <StyledModal
      isOpen={isOpen}
      onCancel={handleCancel}
      disableFooter
      CrossIconHide
      disableOverlayClick
      isDarkModeEnabled={isDarkModeEnabled}
      minHeight="unset"
    >
      <LikesContainer mobileView={mobileView} isDarkModeEnabled={isDarkModeEnabled}>
        <HeaderContainer>
          <Title isDarkModeEnabled={isDarkModeEnabled} mobileView={mobileView}>
            Likes
          </Title>
          <StyledCloseIcon isDarkThemeEnabled={isDarkModeEnabled} onClick={handleCancel} />
        </HeaderContainer>
        <LikesList>
          {likes && likes?.length > 0 ? (
            likes?.map((like, index) => (
              <LikeItem key={index} isDarkModeEnabled={isDarkModeEnabled}>
                <StyledAvatar
                  className="mx-2"
                  alt={`${like.userInfo?.firstName} ${like.userInfo?.lastName}`}
                  src={like.userInfo?.avatarUrl}
                  mobileView={mobileView}
                >
                  {`${getInitials(like.userInfo?.firstName + ' ' + like.userInfo?.lastName)}`}
                </StyledAvatar>
                {like.userInfo?.firstName} {like.userInfo?.lastName}
              </LikeItem>
            ))
          ) : (
            <LikeItem isDarkModeEnabled={isDarkModeEnabled}>No likes yet</LikeItem>
          )}
        </LikesList>
      </LikesContainer>
    </StyledModal>
  );
};

export default LikesModal;
