import React, { useEffect, useRef, useState } from 'react';
import classes from './EditableTextField.module.scss';
import EditButton from 'components/UI/ListButtons/EditButton';
import TextField from 'components/UI/TextField';
import classNames from 'classnames';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';

const InputField = ({
  value,
  onChange,
  setEditMode,
  mobileView,
  inputClasses,
  placeholder,
  maxLength,
  helperText,
  edit = false,
}) => {
  const inputRef = useRef();
  const cursorPositionRef = useRef(null);
  useEffect(() => {
    if (edit === false) {
      const handleOutsideClick = e => {
        if (e.target.className.includes('input')) {
          return;
        }
        setEditMode(false);
      };
      document.addEventListener('click', handleOutsideClick);
      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    }
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      if (cursorPositionRef.current !== null) {
        inputRef.current.setSelectionRange(cursorPositionRef.current, cursorPositionRef.current);
      }
    }
  }, [value]);
  const handleChange = e => {
    cursorPositionRef.current = e.target.selectionStart;
    onChange(e);
  };

  return (
    <>
      <TextField
        inputRef={inputRef}
        className={mobileView && classes.textfield_root_resposive}
        variant="outlined"
        placeholder={placeholder}
        inputClasses={{
          root: classNames(classes.input_root, { [classes.input_root_responsive]: mobileView }),
          notchedOutline: classes.input_notchedOutline,
          focused: classes.input_focused,
          ...inputClasses,
        }}
        multiline
        value={value || ''}
        onChange={handleChange}
        inputProps={{ maxLength: maxLength }}
      />
      {value?.length > 500 && <CommonErrorMessage align="left" message={helperText} />}
    </>
  );
};

export const EditableTextField = ({
  value,
  onChange,
  className,
  percentageValue = '',
  setPercentageValue = () => {},
  failed = false,
  mobileView,
  placeholder,
  inputClasses,
  containerClassName,
  helperText,
  maxLength,
}) => {
  let textClass = classes.text;
  let containerClass = classes.container;
  const [editMode, setEditMode] = useState(false);

  if (className) {
    textClass = `${textClass} ${className}`;
  }

  if (containerClassName) {
    containerClass = `${containerClass} ${containerClassName}`;
  }
  if (editMode) {
    return (
      <InputField
        value={value}
        onChange={onChange}
        setEditMode={setEditMode}
        mobileView={mobileView}
        inputClasses={inputClasses}
        placeholder={placeholder}
        helperText={helperText}
        maxLength={maxLength}
      />
    );
  }

  return (
    <div
      className={containerClass}
      onClick={() => {
        setEditMode(true);
      }}
    >
      <div className={textClass}>
        {value || placeholder} <span className={failed ? classes.failedAge : classes.passedAge}>{percentageValue}</span>
      </div>
      <div
        className={classes.edit_btn_container}
        onClick={() => {
          setEditMode(true);
        }}
      >
        <EditButton className={classes.editBtn} />
      </div>
    </div>
  );
};

export const EditableTextFieldContent = ({
  value,
  onChange,
  className,
  percentageValue = '',
  setPercentageValue = () => {},
  failed = false,
  mobileView,
  placeholder,
  inputClasses,
  containerClassName,
  helperText,
  maxLength,
  edit = false,
}) => {
  let textClass = classes.textContent;
  let containerClass = classes.container;
  const [editMode, setEditMode] = useState(edit);

  if (className) {
    textClass = `${textClass} ${className}`;
  }

  if (containerClassName) {
    containerClass = `${containerClass} ${containerClassName}`;
  }
  if (editMode) {
    return (
      <InputField
        value={value}
        onChange={onChange}
        setEditMode={setEditMode}
        mobileView={mobileView}
        inputClasses={inputClasses}
        placeholder={placeholder}
        helperText={helperText}
        maxLength={maxLength}
        multiline
        edit={edit}
      />
    );
  }

  return (
    <div
      className={containerClass}
      onClick={() => {
        setEditMode(true);
      }}
    >
      <div className={textClass}>
        {value || placeholder} <span className={failed ? classes.failedAge : classes.passedAge}>{percentageValue}</span>
      </div>
      <div
        className={classes.edit_btn_container}
        onClick={() => {
          setEditMode(true);
        }}
      >
        <EditButton className={classes.editBtn} />
      </div>
    </div>
  );
};
