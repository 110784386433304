import { handleActions } from 'redux-actions';
import * as actions from 'actions/community';

const initialState = {
  loading: true,
  limitReached: false,
  communities: [],
  error: null,
  isCommunitySidebarOpen: true,
  selectedContributionId: null,
  selectedContributionName: null,
  showConfirmationModal: false,
};

const communities = handleActions(
  new Map([
    [
      actions.fetchCommunitiesActions.request,
      state => ({
        ...state,
        loading: true,
      }),
    ],
    [
      actions.fetchCommunitiesActions.reset,
      state => ({
        ...state,
        communities: [],
      }),
    ],
    [
      actions.fetchCommunitiesActions.success,
      (state, { payload }) => {
        return {
          ...state,
          loading: false,
          communities: [...state.communities, ...payload.communities],
          limitReached: payload.limitReached,
          ...{ ...payload.rest, totalUnreadPosts: payload.rest.totalUnreadPostCountAllCommunities },
        };
      },
    ],
    [
      actions.fetchCommunitiesActions.error,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
      }),
    ],
    [
      actions.TOGGLE_COMMUNITY_SIDEBAR,
      (state, { payload }) => ({
        ...state,
        isCommunitySidebarOpen: !state.isCommunitySidebarOpen,
      }),
    ],
    [
      actions.UPDATE_COMMUNITY_UNREAD_COUNT,
      (state, { payload }) => {
        const communities = state.communities.map(community => {
          if (community.id === payload.id) {
            return payload;
          }
          return community;
        });

        return {
          ...state,
          communities,
        };
      },
    ],
    [
      actions.UPDATE_COMMUNITIES_UNREAD_COUNT,
      (state, { payload }) => ({
        ...state,
        ...payload,
      }),
    ],
    [
      actions.TOGGLE_CONFIRMATION_MODAL,
      (state, { payload }) => ({
        ...state,
        ...payload,
      }),
    ],
  ]),
  initialState,
);

export default communities;
