import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import useRouter from 'hooks/useRouter';
import Loader from 'components/UI/Loader';
import ContributionRecordings from 'pages/ContributionRecordings/ContributionRecordings';
import axiosInstance from 'utils/axiosInstance';
import { addhttp } from 'utils/utils';
import { getItem, removeItem, setItem, subscribe } from 'services/localStorage.service';
import { getCookieOfDomain, setCookie } from 'services/cookies.service';
import { SignUpSecondPage, SignInPage, ViewAsCoachPage, SignUpClientSecondPage } from 'pages';
import * as userActions from 'actions/user';
import ApplicationFormPage from 'pages/ApplicationForm/ApplicationFormPage';
import UnsubscribePage from 'pages/Unsubscribe/Unsubscribe';
import ResubscribePage from 'pages/ResubscribePage/Resubscribe';
import useAccount from 'hooks/useAccount';
import { isEmpty } from 'lodash';
import { setEasyBookingThankYouData } from 'actions/easyBookingThankYou';
import VideoCallContainer from 'components/Containers/VideoCallContainer';
import { isContributionCreatedOrJoined } from 'actions/contributions';
import { UserRoles } from 'helpers/constants';
import SignUpContainer from '../Containers/SignUpContainer';
import LogOut from './LogOut';
import RoleSwitch from './RoleSwitch';
import AppContent from './AppContent';
import { ROUTES } from '../../constants';
import CoachContent from './CoachContent';

function Routes({ clearUserErrors }) {
  const history = useHistory();
  const { domain, subdomain, parentDomain, location } = useRouter();
  const [subdomainExists, setSubdomainExists] = useState(false);
  const [tokenChecked, setTokenChecked] = useState(false);
  const dispatch = useDispatch();
  const { user, currentRole } = useAccount();
  const [checkUser, setCheckUser] = useState(false);

  const reload = () => window.location.reload();

  const checkGuestUser = () => {
    const pDomain = parentDomain.split(':3000')[0];
    const guestVideoCall = getCookieOfDomain('guestVideoCall', pDomain);
    const regex = /contribution-view\/([a-f0-9]{24})\//;
    const match = location.pathname.match(regex);
    if (user && (user?.guestContributionId || user.isSecondaryAccount || user.isSecondaryExisting)) {
      if (!match || match[1] !== user?.guestContributionId || guestVideoCall) {
        removeItem('guestContributionId');
        setCookie('guestContributionId', null, pDomain, 30);
        setCookie('guestVideoCall', null, pDomain, 30);
        dispatch(userActions.logOut);
        dispatch(setEasyBookingThankYouData.setData({}));
        reload();
      }
    }
    if (!match && isEmpty(user)) {
      dispatch(setEasyBookingThankYouData.setData({}));
    }
  };

  useEffect(() => {
    const pDomain = parentDomain.split(':3000')[0];
    const cookieToken = getCookieOfDomain('token', pDomain);
    const localToken = getItem('token');
    if (!cookieToken) {
      dispatch(userActions.logOut());
    }
    setItem('token', cookieToken);
    subscribe('token', ({ value }) => {
      setCookie('token', value, pDomain, 30);
    });
    if (localToken !== cookieToken && cookieToken) {
      axiosInstance
        .get('Auth/GetAccountInfo', {
          headers: {
            Authorization: `Bearer ${cookieToken}`,
          },
        })
        .then(sucs => {
          let payload = {
            ...sucs.data,
            currentRole: sucs.data?.account?.accountPreferences?.userView,
            account: {
              ...sucs.data?.account,
              oAuthToken: cookieToken,
            },
          };
          const guestContributionId =
            getItem('guestContributionId') ?? getCookieOfDomain('guestContributionId', pDomain);
          if (guestContributionId) {
            payload = {
              ...payload,
              user: {
                ...payload.user,
                guestContributionId,
              },
            };
          }
          setCheckUser(true);
          setItem('token', cookieToken);
          dispatch({ type: 'USER_SETUP', payload });
        })
        .catch(err => {})
        .finally(() => {
          setTokenChecked(true);
        });
    } else {
      setTokenChecked(true);
    }
    checkGuestUser();
  }, []);

  useEffect(() => {
    if (checkUser && !isEmpty(user)) {
      checkGuestUser();
    }
  }, [user?.guestContributionId]);

  useEffect(() => {
    if (user) {
      dispatch(
        isContributionCreatedOrJoined(
          currentRole === UserRoles.cohealer || currentRole === UserRoles.cohealerAssistant,
        ),
      );
    }
  }, [user]);

  useEffect(() => {
    if (subdomain) {
      axiosInstance
        .post(`/User/checkProfileLinkName?profileName=${subdomain}`)
        .then(res => {
          setSubdomainExists(!res.data);
          if (subdomain && res.data) {
            window.location.replace(addhttp(domain));
          }
        })
        .catch(e => setSubdomainExists(false));
    }
  }, [subdomain]);

  useEffect(() => {
    clearUserErrors();
  }, [clearUserErrors]);

  useEffect(() => {
    history.listen(() => {
      clearUserErrors();
    });
  }, [history, clearUserErrors]);

  return (
    <>
      {tokenChecked ? (
        <>
          {subdomainExists && (
            <Switch>
              <Route path="/contribution-view" component={CoachContent} />
              <Route path="/VideoCall" component={VideoCallContainer} />
              <Route path="/lead-magnet-view" component={CoachContent} />
              <Route path="/application-form/:id/:code?" component={ApplicationFormPage} />
              <Route path="/apply-contribution-view" component={CoachContent} />
              <Route path={ROUTES.ROLE_SWITCH} component={RoleSwitch} />
              <Route exact path="/share-recording/:contributionId/:sessionTimeId" component={ContributionRecordings} />
              <Route component={ViewAsCoachPage} />
            </Switch>
          )}
          {!subdomain && (
            <Switch>
              <Route path="/auth" component={SignUpContainer} />
              <Route path="/logout" component={LogOut} />
              <Route path="/application-form/:id/:code?" component={ApplicationFormPage} />
              <Route exact path="/share-recording/:contributionId/:sessionTimeId" component={ContributionRecordings} />
              <Route path={ROUTES.ROLE_SWITCH} component={RoleSwitch} />
              <Route exact path={ROUTES.ADD_ROLE} component={SignUpSecondPage} />
              <Route exact path={ROUTES.ADD_ROLE_CLIENT} component={SignUpClientSecondPage} />
              <Route exact path={ROUTES.SUBMIT_ADD_ROLE} component={SignInPage} />
              <Route exact path={ROUTES.UNSUBSCRIBE} component={UnsubscribePage} />
              <Route exact path={ROUTES.RESUBSCRIBE} component={ResubscribePage} />
              <Route component={AppContent} />
              <Route path="/apple-app-site-association" onEnter={reload} />
            </Switch>
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}

Routes.propTypes = {
  clearUserErrors: PropTypes.func.isRequired,
};

Routes.defaultProps = {};

const mapStateToProps = ({ account }) => ({
  userId: account?.user?.id,
  error: account?.error,
});

const actions = {
  clearUserErrors: userActions.clearUserErrors,
};

export default connect(mapStateToProps, actions)(Routes);
