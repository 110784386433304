import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { FieldArray, Field } from 'formik';
import Checkbox from '@material-ui/core/Checkbox';
import orderBy from 'lodash/orderBy';
import * as R from 'ramda';
import { useRouter } from 'hooks';
import Tooltip from '@material-ui/core/Tooltip';
import { useTheme, useMediaQuery } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Session from '../../Session';
import Button from 'components/FormUI/Button';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import { toRem, colors } from 'utils/styles';
import { useShallowEqualSelector } from 'hooks';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { getNextCurrentSessionTime } from 'utils/getSessionTime';
import { TOOLTIP, TIMEZONES } from 'constants.js';
import { ContributionType } from 'helpers/constants';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import * as calendarsActions from 'actions/calendars';
import SendInvitesFromModal from 'components/Modals/AddCalendarModal/SendInvitesFromModal.jsx';
import CloseIcon from '@material-ui/icons/Close';

const StyledTimeZoneLabel = styled.p`
  display: inline-block;
  font-size: ${toRem(13)};
  background: ${colors.darkOceanBlue};
  padding: 4px 8px;
  border-radius: 4px;
  margin: 0;
  margin-left: 10px;
  cursor: pointer;
  color: white;
  font-weight: bold;
`;

const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  margin: -5px 0 0 5px;
`;

const StyledEmail = styled.span`
  white-space: nowrap;
  width: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledCalendar = styled(StyledFlexContainer)`
  width: ${({ mobileView }) => (mobileView ? '120px' : '140px')};
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  padding: 6px;
  margin-right: 2px;
`;

const StyledFullWidthFlexContainer = styled(StyledFlexContainer)`
  width: 100%;
`;

const StyledCalendarTabContainer = styled(StyledFullWidthFlexContainer)`
  flex-direction: ${({ mobileView }) => (mobileView ? 'column' : 'row')};
  align-items: ${({ mobileView }) => (mobileView ? 'flex-start' : 'center')};
  justify-content: flex-end;
  overflow: hidden;
`;

const StyledCalendarsContainer = styled(StyledFlexContainer)`
  width: ${({ mobileView }) => (mobileView ? '290px' : '320px')};
  justify-content: ${({ onlyChild }) => (onlyChild ? 'flex-end' : 'flex-start')};
  overflow-x: auto;
  padding: ${({ mobileView }) => (mobileView ? '10px 0' : '10px 5px')};
  margin-left: ${({ mobileView }) => (mobileView ? '0' : '10px')};
`;

const StyledCheckbox = styled(Checkbox)`
  padding-left: 0 !important;
`;

const StyledButton = styled(Button)`
  margin: ${({ mobileView }) => (mobileView ? '10px 0' : '0 10px')};
  min-width: 180px;
`;

const StyledTooltip = styled(Tooltip)`
  cursor: pointer;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;

const StyledTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.115;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  ${({ mobileView }) => mobileView && `font-size: 16px`}
`;

const PARTICIPANTS_NUMBER = {
  MIN: 2,
  MAX: 10,
};

const Sessions = ({
  values,
  enrollment,
  mysession,
  sessions,
  errors,
  touched,
  providerName,
  type,
  setFieldValue,
  calendars,
  addCalendar,
}) => {
  const [sessionCount, setSessionCount] = useState(R.inc(sessions.filter(s => !s.isPrerecorded).length));
  const user = useShallowEqualSelector(state => state?.account?.user);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const sessionsCount = sessions.length - 1;
  const { history, query, params } = useRouter();
  const isEditing = Boolean(query?.id);
  const [dragging, isDragging] = useState(false);
  const [openSessionIndex, setOpenSessionIndex] = useState(isEditing ? null : sessions.length - 1);

  function findIndexById(array, id) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === id) {
        return i; // Return the index if ID matches
      }
    }
    return -1; // Return -1 if ID is not found
  }
  useEffect(() => {
    if (!isEditing) {
      setOpenSessionIndex(sessions.length - 1);
    }
    if (isEditing && query?.sessionId) {
      const index = findIndexById(sessions, query?.sessionId);
      setOpenSessionIndex(index);
    }
    if (isEditing) {
      setOpenSessionIndex(0);
    }
  }, [sessionsCount]);

  useEffect(() => {
    if (isEditing && query?.sessionId) {
      const index = findIndexById(sessions, query?.sessionId);
      setOpenSessionIndex(index);
      const idToScroll = query?.sessionId;
      setTimeout(() => {
        const element = document.getElementById(idToScroll);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 3000);
    }
  }, [query?.sessionId]);

  const handleEnd = r => {
    if (!r.destination) {
      return null;
    } else {
      const item = sessions.slice();
      const [order] = item.splice(r.source.index, 1);
      item.splice(r.destination.index, 0, order);
      mysession(item);
      // let itemSession;
      // itemSession = [];
      // itemSession = sessions;
      // let source = sessions[r.source.index];
      // let dest = sessions[r?.destination?.index];
      // itemSession[r.source.index] = dest;
      // itemSession[r?.destination?.index] = source;
    }
    isDragging(false);
  };
  const [isSendInvitesFromModalOpen, setIsSendInvitesFromModalOpen] = useState(false);
  const isCommunityType = type == ContributionType.contributionCommunity ? 'true' : 'false';
  const { pathname } = useRouter();
  const isCreate = pathname === '/create-contribution/sessions';

  const handleOpenSendInvitesFromModalOpen = e => {
    e.preventDefault();
    setIsSendInvitesFromModalOpen(true);
  };
  const handleCloseSendInvitesFromModalOpen = () => {
    setIsSendInvitesFromModalOpen(false);
  };

  const onRemoveCalendar = () => {
    setFieldValue('externalCalendarEmail', undefined);
  };

  const handleModalSubmit = useCallback(async () => {
    setIsSendInvitesFromModalOpen(false);
  }, []);

  return (
    <Grid item xs={12}>
      <Card mobileView={mobileView}>
        <CardHeader mobileView={mobileView}>
          <PageTitleSecond mobileView={mobileView}>Live Sessions and Self-Paced Modules</PageTitleSecond>
          {user.timeZoneId && (
            <NavLink to="/account/profile">
              <StyledTimeZoneLabel>{TIMEZONES[user.timeZoneId]}</StyledTimeZoneLabel>
            </NavLink>
          )}
          <Tooltip
            title="You can edit Service sessions, update times and even add additional sessions after the Service is created."
            arrow
            className="ml-2"
            enterTouchDelay={TOOLTIP.ENTER_DELAY}
            leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
          >
            <InfoIcon htmlColor={colors.lightBrown} />
          </Tooltip>
          {/* {isCommunityType == 'true' && (
            <>
              <StyledCalendarTabContainer mobileView={mobileView}>
                {!values.externalCalendarEmail ? (
                  <StyledFlexContainer>
                    <StyledButton mobileView={mobileView} autoWidth onClick={handleOpenSendInvitesFromModalOpen}>
                      Add my calendar
                    </StyledButton>
                    <StyledTooltip
                      title="Select the calendar you wish to send an invite from to your clients. If you do not add calendar invite will come from noreply@cohere.live."
                      arrow
                      enterTouchDelay={TOOLTIP.ENTER_DELAY}
                      leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                    >
                      <InfoIcon htmlColor={colors.lightBrown} />
                    </StyledTooltip>
                  </StyledFlexContainer>
                ) : (
                  <StyledCalendar> */}
          {/* <Icon /> */}
          {/* <StyledEmail className="ml-2">{values.externalCalendarEmail}</StyledEmail>
                    <StyledCloseIcon onClick={onRemoveCalendar} />
                  </StyledCalendar>
                )}
              </StyledCalendarTabContainer>
            </>
          )} */}
        </CardHeader>
        <CardBody mobileView={mobileView}>
          {' '}
          <FieldArray name="sessions">
            {({ push, remove, form }) => {
              const addHandler = data => {
                const lastSession = sessions[sessionsCount];
                const lastSessionTime =
                  lastSession && lastSession.sessionTimes.length > 0
                    ? lastSession.sessionTimes[lastSession.sessionTimes.length - 1]
                    : {};
                if (lastSessionTime.recurringSessionTime != null) {
                  const lastSessionUpdated = {
                    ...lastSession,
                    sessionTimes: [{ ...lastSession.sessionTimes[0], recurringSessionTime: null }],
                  };
                  remove(sessionsCount);
                  push(lastSessionUpdated);
                }
                push({
                  ...data,
                  title: data?.isPrerecorded ? `Module` : `Session ${sessionCount}`,
                  name: data?.isPrerecorded ? `Module` : `Session`,
                  minParticipantsNumber: lastSession ? lastSession.minParticipantsNumber : PARTICIPANTS_NUMBER.MIN,
                  maxParticipantsNumber: lastSession ? lastSession.maxParticipantsNumber : PARTICIPANTS_NUMBER.MAX,
                  dateAvailable: data?.isPrerecorded
                    ? getNextCurrentSessionTime(false, lastSessionTime, enrollment).startTime
                    : null,
                  sessionTimes: [getNextCurrentSessionTime(false, lastSessionTime, enrollment)],
                });

                if (!data?.isPrerecorded) {
                  setSessionCount(R.inc);
                }
              };

              const removeHandler = (id, isPrerecorded) => {
                remove(id);
                if (!isPrerecorded) {
                  setSessionCount(R.dec);
                }
              };
              return (
                <>
                  {sessions.length > 0 && (
                    <DragDropContext
                      onDragStart={() => {
                        isDragging(true);
                      }}
                      onDragEnd={handleEnd}
                    >
                      <Droppable disableInteractiveElementBlocking={true} droppableId="Session">
                        {provided => {
                          return (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                width: '100%',
                                // minHeight: 200,
                                overflow: 'hidden',
                              }}
                            >
                              {sessions?.map((item, index) => {
                                const key = `${index}`;
                                const isLast = index === sessions.length - 1;

                                return (
                                  <Draggable draggableId={key} index={index} isDragDisabled={!item.isPrerecorded}>
                                    {provided => {
                                      return (
                                        <div
                                          {...provided.dragHandleProps}
                                          {...provided.draggableProps}
                                          ref={provided.innerRef}
                                        >
                                          <Session
                                            dragging={dragging}
                                            key={key}
                                            index={index}
                                            session={item}
                                            providerName={providerName}
                                            isPrerecorded={item?.isPrerecorded}
                                            timesCount={item?.sessionTimes?.length || 1}
                                            onDelete={removeHandler}
                                            form={form}
                                            isLastIndex={isLast}
                                            isSessionOpen={openSessionIndex === index}
                                            setOpenSessionIndex={setOpenSessionIndex}
                                          />
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    </DragDropContext>
                  )}
                  <Button
                    type="button"
                    className="mr-3"
                    onClick={() => {
                      addHandler({ isPrerecorded: false });
                    }}
                    autoWidth
                  >
                    Create New Live Session
                  </Button>
                  <Button
                    type="button"
                    className="mt-2"
                    onClick={() =>
                      addHandler({
                        isPrerecorded: true,
                      })
                    }
                    autoWidth
                  >
                    Create New Self-Paced Module
                  </Button>
                </>
              );
            }}
          </FieldArray>
          {errors.sessions && touched.sessions && typeof errors.sessions === 'string' && (
            <CommonErrorMessage message={errors.sessions} />
          )}
        </CardBody>
      </Card>

      {isSendInvitesFromModalOpen && (
        <SendInvitesFromModal
          isOpen
          onModalClose={handleCloseSendInvitesFromModalOpen}
          onSubmit={handleModalSubmit}
          setFieldValue={setFieldValue}
          values={values}
          // onSubmit={handleModalSubmit}
          // calendars={calendars}
          addCalendar={addCalendar}
          contributionId={query.id}
          isCreate={isCreate}
          // lastAddedMail={lastAddedMail}
          // loadingLastEmail={loadingLastEmail}
        />
      )}
    </Grid>
  );
};

Sessions.propTypes = {
  enrollment: PropTypes.shape({
    toDate: PropTypes.string,
    fromDate: PropTypes.string,
    anyTime: PropTypes.bool,
  }),
  sessions: PropTypes.arrayOf(),
  errors: PropTypes.shape().isRequired,
  touched: PropTypes.shape().isRequired,
};

const mapStateToProps = ({ oneToOneData, contributions, calendars }) => ({
  calendars: calendars.calendarsAccounts,
  lastAddedMail: calendars.lastAddedEmail,
  loadingLastEmail: calendars.loadingLastEmail,
  accountsForConflictsCheck: calendars.accountsForConflictsCheck,
  contribution: contributions.activeContribution,
  loadingTimes: contributions.loadingTimes,
});
const actions = {
  fetchCalendars: calendarsActions.fetchCalendars,
  addCalendar: calendarsActions.addCalendar,
  getAcсountsForConflictsCheck: calendarsActions.getAcсountsForConflictsCheck,
  getAcсountForSendReminders: calendarsActions.getAcсountForSendReminders,
  removeCalendar: calendarsActions.removeCalendar,
};

Sessions.defaultProps = {
  enrollment: {},
  sessions: [],
};

export default connect(mapStateToProps, actions)(Sessions);
