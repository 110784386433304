import React, { useEffect } from 'react';
import usePaidTier from 'hooks/usePaidTier';
import List, { Item } from './List';
import * as paidTier from 'selectors/paidTier';
import { useDispatch } from 'react-redux';
import { CONTRIBUTION_COLORS, PAID_TIER_TITLES, ROUTES } from '../../../constants';
import {
  AllConversationsUnreadMessagesCount,
  CohealersConversationsUnreadMessagesCount,
  CommunityUnreadMessagesCount,
  DiscussionsConversationsUnreadMessagesCount,
} from './conversations/UnreadMessagesCount';
import useRouter from 'hooks/useRouter';
import { toggleCommunitySidebar } from 'actions/community';
import { openInNewWindow } from 'services/links';
import { SIGNUP_TYPES } from 'helpers/constants';
import { useSelector } from 'react-redux';
import { useAccount, useShallowEqualSelector } from 'hooks/index';
import { setCohereAcademyStatus } from 'actions/update-user';
function ClientMenu() {
  const { pathname } = useRouter();

  const dispatch = useDispatch();
  // const { isLaunchPlan, activePaidTierPlan } = usePaidTier();
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  const isLaunchPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch;

  // Correct behavior - 100%

  useEffect(() => {
    if (activePaidTierCurrentPlan?.default) {
      dispatch(setCohereAcademyStatus(true));
    } else {
      dispatch(setCohereAcademyStatus(false));
    }
  }, [activePaidTierCurrentPlan, dispatch]);

  const { user: loggedInUser } = useAccount();
  const user = useSelector(state => state.account);
  const shouldBeDisabled =
    isLaunchPlan ||
    user.signupType === SIGNUP_TYPES.TYPE_A ||
    user.signupType === SIGNUP_TYPES.TYPE_B ||
    loggedInUser.signupType === SIGNUP_TYPES.TYPE_B ||
    loggedInUser.signupType === SIGNUP_TYPES.TYPE_A;
  return (
    <List menubar>
      <Item
        id="client-menu-my-journey-link"
        to="/dashboard"
        title="My Journey"
        isActive={pathname?.includes('dashboard')}
      />
      <>
        <div
          onClick={() => {
            dispatch(toggleCommunitySidebar());
          }}
        >
          <Item
            id="client-menu-communities-all-link"
            to="/community"
            title="Communities"
            isActive={pathname?.includes('community')}
            endAdornment={<CommunityUnreadMessagesCount />}
          />
        </div>
      </>
      <Item
        id="client-menu-conversations-link"
        title="Conversations"
        isList
        defaultExpanded={pathname?.includes('conversations')}
      >
        <List menubar>
          <Item
            id="client-menu-all-conversations-link"
            to="/conversations/all"
            title="All"
            isActive={pathname?.includes('/conversations/all')}
            endAdornment={<AllConversationsUnreadMessagesCount />}
            subItem
          />
          <Item
            id="client-menu-coaches-conversations-link"
            to="/conversations/cohealers"
            title="1:1"
            isActive={pathname?.includes('/conversations/cohealers')}
            endAdornment={<CohealersConversationsUnreadMessagesCount />}
            subItem
          />
          <Item
            id="client-menu-group-conversations-link"
            to="/conversations/discussions"
            title="Group"
            isActive={pathname?.includes('/conversations/discussions')}
            endAdornment={<DiscussionsConversationsUnreadMessagesCount />}
            subItem
          />
        </List>
      </Item>
      {!shouldBeDisabled && user?.user?.id !== '65cc390b6c651039c09ae644' && (
        <span
          onClick={() => {
            openInNewWindow('https://cohereacademy.cohere.live/about');
          }}
        >
          {' '}
          <Item
            title="Cohere Academy"
            tooltipText="The Cohere Academy feature is available only to users with a Cohere Coach Subscription. Your clients will not see this feature."
          />{' '}
        </span>
      )}
    </List>
  );
}

export default ClientMenu;
