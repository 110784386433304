export const ONE_TO_ONE_AVALIBILTY = 'ONE_TO_ONE_AVALIBILTY';
export const ADD_TO_CALENDAR = 'ADD_TO_CALENDAR';
export const SCHEDULE_CLIENT = 'SCHEDULE_CLIENT';
export const EXTRA_CALENDAR_SETTING = 'EXTRA_CALENDAR_SETTING';
export const REMOVE_MASTER_CALENDAR_EMAIL = 'REMOVE_MASTER_CALENDAR_EMAIL';
export const PREVIEW_MASTER_CALENDAR_EVENT = 'PREVIEW_MASTER_CALENDAR_EVENT';
export const TOGGLE_MASTER_CALENDAR_SIDEBAR = 'TOGGLE_MASTER_CALENDAR_SIDEBAR';
export const MANAGE_MASTER_AVAILABILITY = 'MANAGE_MASTER_AVAILABILITY';
export const MASTER_AVAILABILITY_GET_SLOTS = 'MASTER_AVAILABILITY_GET_SLOTS';
export const PRE_SELECTED_DATE = 'PRE_SELECTED_DATE';

export const oneToOneAvailabilityAction = payload => {
  return {
    type: ONE_TO_ONE_AVALIBILTY,
    payload: payload,
  };
};

export const addToCalendarAction = payload => {
  return {
    type: ADD_TO_CALENDAR,
    payload: payload,
  };
};
export const scheduleClientAction = payload => {
  return {
    type: SCHEDULE_CLIENT,
    payload: payload,
  };
};
export const extraCalendarSettingAction = payload => {
  return {
    type: EXTRA_CALENDAR_SETTING,
    payload: payload,
  };
};
export const removeMasterCalendarEmailAction = payload => {
  return {
    type: REMOVE_MASTER_CALENDAR_EMAIL,
    payload: payload,
  };
};
export const previewMasterCalendarEventAction = payload => {
  return {
    type: PREVIEW_MASTER_CALENDAR_EVENT,
    payload: payload,
  };
};
export const toggleMasterCalendarSideBar = payload => {
  return {
    type: TOGGLE_MASTER_CALENDAR_SIDEBAR,
    payload: payload,
  };
};

export const toggleMasterAvailabilitySelection = payload => {
  return {
    type: MANAGE_MASTER_AVAILABILITY,
    payload: payload,
  };
};

export const setMasterAvailabilitySelectedContribution = payload => {
  return {
    type: MASTER_AVAILABILITY_GET_SLOTS,
    payload: payload,
  };
};

export const setPreSelectedMasterCalendarDate = payload => {
  return {
    type: PRE_SELECTED_DATE,
    payload: payload,
  };
};
