import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useField } from 'formik';
import { DateTimePicker as MaterialDateTimePicker, TimePicker, DatePicker } from '@material-ui/pickers';

import { colors } from 'utils/styles';
import { none } from 'ramda';
import useRouter from 'hooks/useRouter';

const useStyles = isSelfPaced =>
  makeStyles(theme => ({
    labelRoot: {
      fontFamily: 'Avenir !important',
      lineHeight: '1rem',
      letterSpacing: isSelfPaced ? 'inherit' : '0.4px',
      fontSize: isSelfPaced ? 'inherit' : `${14 / 16}rem`,
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: isSelfPaced ? theme.typography.fontWeightBold : theme.typography.fontWeightLight,
      transform: `translate(0, ${33 / 16}rem) scale(1)`,
      marginBottom: '0',
    },
    labelShrink: {
      transform: isSelfPaced ? 'inherit' : 'translate(0, 4px)',
    },
    labelFocused: {
      color: 'inherit !important',
      fontFamily: 'Avenir !important',
    },
    labelFormControl: {
      transform: `translate(0, ${32 / 16}rem) scale(1)`,
    },
    labelError: {
      color: 'inherit !important',
      fontFamily: 'Avenir !important',
    },
    inputRoot: {
      fontFamily: 'Avenir !important',
      lineHeight: `${24 / 16}rem`,
      letterSpacing: '0.15px',
      fontSize: isSelfPaced ? 'inherit' : `${18 / 16}rem`,
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: isSelfPaced ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    },
    inputFormControl: {
      'label + &': {
        marginTop: isSelfPaced ? 22 : 26,
      },
    },
    inputUnderline: {
      '&:after': {
        borderBottom: `2px solid #6486a2`,
      },
      '&:before': {
        borderColor: '#e7e7e7',
      },
    },
    inputUnderlineTrans: {
      '&:after': {
        borderBottom: 'transparent !important',
      },
      '&:before': {
        borderColor: 'transparent !important',
      },
    },
    inputError: {
      '&:after': {
        borderBottomColor: `${colors.fadedPurple} !important`,
      },
    },
    helperTextRoot: {
      textAlign: 'right',
      fontFamily: 'Avenir !important',
      lineHeight: `${24 / 16}rem`,
      letterSpacing: '0.08px',
      fontSize: `${12 / 16}rem`,
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 'normal',
    },
    helperTextError: {
      fontFamily: 'Avenir !important',
      color: `${colors.fadedPurple} !important`,
      textAlign: 'left',
      fontSize: '1rem',
      letterSpacing: '0.1px',
    },
  }));

const COMPONENTS = {
  date: DatePicker,
  time: TimePicker,
  datetime: MaterialDateTimePicker,
};

function DateTimePicker(props) {
  const { helperText, name, type, step, underline = true, isSelfPaced, ...rest } = props;
  const classes = useStyles(isSelfPaced)();
  const [field, meta, helpers] = useField(props);
  const { defaultHour, defaultMinute, defaultSecond, defaultMillisecond } = props;
  const Component = COMPONENTS[type];
  const defaultValue = field.value === null || field.value === undefined ? null : field.value;
  const { query, pathname } = useRouter();
  const isEditing = Boolean(query.id);

  return (
    <Component
      {...field}
      error={!isEditing && Boolean(meta.touched && meta.error)}
      helperText={!isEditing && ((meta.touched && meta.error) || helperText)}
      minutesStep={step}
      value={defaultValue}
      onError={err => {
        if (err !== meta.error && err !== '') {
          helpers.setError(err);
        }
      }}
      onChange={date => {
        setDefaultTimePart(date);
        helpers.setValue(date);
      }}
      InputLabelProps={
        isEditing
          ? {
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused,
                shrink: classes.labelShrink,
              },
            }
          : {
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused,
                shrink: classes.labelShrink,
                error: classes.labelError,
              },
            }
      }
      InputProps={
        isEditing
          ? {
              classes: {
                root: classes.inputRoot,
                formControl: classes.inputFormControl,
                underline: underline ? classes.inputUnderline : classes.inputUnderlineTrans,
              },
            }
          : {
              classes: {
                root: classes.inputRoot,
                formControl: classes.inputFormControl,
                underline: underline ? classes.inputUnderline : classes.inputUnderlineTrans,
                error: classes.inputError,
              },
            }
      }
      FormHelperTextProps={
        isEditing
          ? {
              classes: {
                root: classes.helperTextRoot,
              },
            }
          : {
              classes: {
                root: classes.helperTextRoot,
                error: classes.helperTextError,
              },
            }
      }
      {...rest}
    />
  );

  function setDefaultTimePart(date) {
    if (defaultHour) {
      date.set('hour', defaultHour);
    }
    if (defaultMinute) {
      date.set('minute', defaultMinute);
    }
    if (defaultSecond) {
      date.set('second', defaultSecond);
    }
    if (defaultMillisecond) {
      date.set('millisecond', defaultMillisecond);
    }
  }
}

DateTimePicker.propTypes = {
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['date', 'time', 'datetime']),
  step: PropTypes.number,
  disablePast: PropTypes.bool,
  isSelfPaced: PropTypes.bool,
};

DateTimePicker.defaultProps = {
  fullWidth: false,
  label: null,
  helperText: null,
  type: 'datetime',
  step: null,
  disablePast: true,
  isSelfPaced: false,
};

export default DateTimePicker;
