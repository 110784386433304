import useRouter from './useRouter';
import { useEffect, useState } from 'react';

const useUnifiedCommunity = () => {
  const { pathname } = useRouter();
  const [isUnifiedCommunity, setIsUnifiedCommunity] = useState(!pathname?.includes('contribution-view'));

  useEffect(() => {
    if (pathname) {
      setIsUnifiedCommunity(!pathname?.includes('contribution-view'));
    }
  }, [pathname]);

  return { isUnifiedCommunity };
};

export default useUnifiedCommunity;
