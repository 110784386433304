import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Field } from 'formik';
import moment from 'moment';
import { useShallowEqualSelector, useRouter } from 'hooks';
import styled from 'styled-components';
import * as calendarsActions from 'actions/calendars';
import DateTimePicker from 'components/FormUI/DateTimePicker';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import { useTheme, useMediaQuery, makeStyles } from '@material-ui/core';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import Checkbox from '@material-ui/core/Checkbox';
import { TOOLTIP } from '../../../../../constants.js';
import InfoIcon from '@material-ui/icons/Info.js';
import { colors } from '../../../../../utils/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Button from 'components/FormUI/Button';
import SendInvitesFromModal from 'components/Modals/AddCalendarModal/SendInvitesFromModal.jsx';
import CloseIcon from '@material-ui/icons/Close';
import { LiveVideoProvider } from 'constants.js';
import { RemoveCalendarModal } from 'components/Modals/AddCalendarModal/RemoveCalendar.jsx';
import { getDomainIcon } from 'utils/calendar';
import SettingsIcon from '@mui/icons-material/Settings';
import ExternalCalendarSettingsPopup from 'pages/MasterCalendarPage/components/ExternalCalendarSettingsPopup.jsx';
import { isArray } from 'lodash';
import { chooseProviderIcon } from '../../OneToOneForm.js';
import { Switch, StyledInput, StyledSlider } from 'components/switch/style';
import Input from 'components/FormUI/Input';
import TextField from 'components/UI/TextField/index.js';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';

const StyledCheckbox = styled(Checkbox)`
  padding-left: 0 !important;
`;

const StyledLabel = styled.div`
  font-weight: bold;
`;

const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  margin: -5px 0 0 5px;
`;

const StyledEmail = styled.span`
  white-space: nowrap;
  // width: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledCalendar = styled(StyledFlexContainer)`
  width: ${({ mobileView }) => (mobileView ? '120px' : '140px')};
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  padding: 6px;
  margin-right: 2px;
`;

const StyledCalendarWrapper = styled(StyledFlexContainer)`
  width: ${({ mobileView }) => mobileView && '120px'};
  justify-content: center;
  align-items: center;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  padding: 6px;
  margin-right: 2px;
  svg.settings-icon {
    cursor: pointer;
  }
`;

const StyledFullWidthFlexContainer = styled(StyledFlexContainer)`
  width: 100%;
`;

const StyledCalendarTabContainer = styled(StyledFullWidthFlexContainer)`
  flex-direction: ${({ mobileView }) => (mobileView ? 'column' : 'row')};
  align-items: ${({ mobileView }) => (mobileView ? 'flex-start' : 'center')};
  justify-content: flex-end;
  overflow: hidden;
`;

const StyledCalendarsContainer = styled(StyledFlexContainer)`
  width: ${({ mobileView, ForEditSessionsHeader }) => (mobileView && !ForEditSessionsHeader ? '290px' : 'fit-content')};
  justify-content: ${({ onlyChild, mobileView, ForEditSessionsHeader }) =>
    ForEditSessionsHeader ? 'flex-end' : mobileView ? 'flex-start' : onlyChild ? 'flex-end' : 'flex-end'};
  overflow-x: auto;
  padding: ${({ mobileView }) => (mobileView ? '10px 0' : '10px 5px')};
  margin-left: ${({ mobileView }) => (mobileView ? '0' : '10px')};
  gap: 0.5rem;
  /* max-width: ${({ mobileView }) => (mobileView ? '290px' : '500px')}; */
`;

const StyledButton = styled(Button)`
  margin: ${({ mobileView }) => (mobileView ? '10px 0' : '0 10px')};
  min-width: 180px;
`;

const StyledTooltip = styled(Tooltip)`
  cursor: pointer;
`;

const StyledTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.115;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  ${({ mobileView }) => mobileView && `font-size: 16px`}
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;
const StyledParticipantsLabel = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-family: Avenir;
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  line-height: 23.43px;
  letter-spacing: 0.25px;
  margin-top: 3px;
  margin-bottom: 5px;
`;
const StyledWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
`;
const useStyles = makeStyles({
  inputRoot: {
    width: '210px',
  },
});
const StyledAllowEnrollmentLabel = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-family: Avenir;
  font-size: ${({ mobileView }) => (mobileView ? '14px' : '16px')};
  font-style: normal;
  font-weight: 350;
  line-height: 24px;
  margin-top: 0px;
  margin-bottom: 5px;
`;
const initialLiveVideProviders = [
  LiveVideoProvider.Cohere,
  LiveVideoProvider.Custom,
  LiveVideoProvider.InPersonSession,
];
const EnrollmentDates = ({
  removeCalendar,
  values,
  setFieldValue,
  enrollment,
  calendars,
  addCalendar,
  errors,
  touched,
  validateForm = () => {},
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const wrapCalendars = useMediaQuery('(max-width:410px)');
  const [removedCalendarEmail, setRemovedCalendarEmail] = useState(false);
  const [liveVideoProviders, setLiveVideoProviders] = useState(initialLiveVideProviders);
  const user = useShallowEqualSelector(state => state?.account?.user);
  const [isSendInvitesFromModalOpen, setIsSendInvitesFromModalOpen] = useState(false);
  const [calendarForSettingsPopup, setCalendarForSettingsPopup] = useState(false);
  const [showExternalCalendarSettingsModal, setShowExternalCalendarSettingsModal] = useState(false);
  const { query, pathname } = useRouter();
  const isCreate = pathname === '/create-contribution/sessions';
  const CalendarsLengthForConflitCheck =
    calendars && isArray(calendars) ? calendars?.filter(c => c.isCheckConflictsEnabled || c.isDefault)?.length : 0;
  const [
    showExternalCalendarConnectionCompleteStepsModal,
    setShowExternalCalendarConnectionCompleteStepsModal,
  ] = useState({
    showStep1: false,
    showStep2: false,
  });
  const handleOpenSendInvitesFromModalOpen = e => {
    e.preventDefault();
    setIsSendInvitesFromModalOpen(true);
  };

  const handleCloseSendInvitesFromModalOpen = () => {
    setIsSendInvitesFromModalOpen(false);
  };

  const onRemoveCalendar = () => {
    setFieldValue('externalCalendarEmail', undefined);
  };

  const handleModalSubmit = useCallback(async () => {
    setIsSendInvitesFromModalOpen(false);
  }, []);

  const handleCloseRemoveCalendarModal = () => {
    setRemovedCalendarEmail(null);
  };

  const onRemovecalendarSubmit = () => {
    removeCalendar(removedCalendarEmail);
    setRemovedCalendarEmail(null);
  };
  // useEffect(() => {
  //   const defaultCal4Group = calendars && isArray(calendars) && calendars?.find(k => k.isDefaultForGroupCourseInvites);
  //   setSelectedEmailCalendar(defaultCal4Group ? defaultCal4Group?.emailAddress : null);
  //   setFieldValue('externalCalendarEmail', defaultCal4Group?.emailAddress);
  // }, []);
  useEffect(() => {
    validateForm();
  }, []);
  console.log('touched11', touched);
  return (
    <Grid item xs={12}>
      <Card mobileView={mobileView}>
        <CardHeader mobileView={mobileView}>
          <PageTitleSecond style={{ width: '300px' }} mobileView={mobileView}>
            Enrollment Dates
          </PageTitleSecond>
          <StyledCalendarTabContainer mobileView={mobileView}>
            <StyledCalendarsContainer
              onlyChild={CalendarsLengthForConflitCheck === 1}
              className={'no-scroll-container'}
              mobileView={mobileView}
              ForEditSessionsHeader
              // style={{ padding: accountsForConflictsCheck?.length > 0 ? '' : '0px' }}
              style={{ maxWidth: mobileView ? 290 : 550 }}
            >
              {/* {isCreate &&
                calendars &&
                isArray(calendars) &&
                calendars
                  ?.filter(k => k.isDefaultForGroupCourseInvites)
                  ?.map((calendar, i) => {
                    const { provider, emailAddress } = calendar;
                    const key = `${i}${emailAddress}`;
                    const Icon = getDomainIcon(null, provider);
                    return (
                      <StyledCalendarWrapper key={key}>
                        <Icon />
                        <Tooltip
                          title={emailAddress}
                          arrow
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        >
                          <StyledEmail className="ml-1">{emailAddress}</StyledEmail>
                        </Tooltip>
                        <StyledCloseIcon onClick={() => onRemoveCalendar()} />
                        <SettingsIcon
                          className="settings-icon"
                          onClick={() => {
                            setCalendarForSettingsPopup(calendar);
                            setShowExternalCalendarSettingsModal(true);
                          }}
                        />
                      </StyledCalendarWrapper>
                    );
                  })} */}
            </StyledCalendarsContainer>
            {!values.externalCalendarEmail ? (
              <StyledFlexContainer>
                <StyledButton mobileView={mobileView} autoWidth onClick={handleOpenSendInvitesFromModalOpen}>
                  Send Invites From
                </StyledButton>
                <StyledTooltip
                  title="Please select how you wish your clients to receive invites. By default your client will receive a calendar invite from noreply@cohere.live, which is recommended because it will keep your clients' emails private. If you select a connected calendar, invites will be send from this account and all clients will see each other's email."
                  arrow
                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                >
                  <InfoIcon htmlColor={colors.lightBrown} />
                </StyledTooltip>
              </StyledFlexContainer>
            ) : (
              <>
                <StyledCalendarsContainer
                  onlyChild={CalendarsLengthForConflitCheck === 1}
                  className={'no-scroll-container'}
                  mobileView={mobileView}
                  ForEditSessionsHeader
                  // style={{ padding: accountsForConflictsCheck?.length > 0 ? '' : '0px' }}
                  style={{ maxWidth: mobileView ? 290 : 550 }}
                >
                  {calendars &&
                    isArray(calendars) &&
                    calendars
                      ?.filter(k => k.emailAddress === values.externalCalendarEmail)
                      ?.map((calendar, i) => {
                        const { provider, emailAddress } = calendar;
                        const key = `${i}${emailAddress}`;
                        // const Icon = getDomainIcon(null, provider);
                        const Icon = chooseProviderIcon(provider);
                        return (
                          <StyledCalendarWrapper key={key}>
                            {Icon && <Icon className="provider-logo" />}
                            <Tooltip
                              title={emailAddress}
                              arrow
                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                            >
                              <StyledEmail className="ml-1">{emailAddress}</StyledEmail>
                            </Tooltip>
                            <Tooltip
                              title="Remove sending invites from selected account"
                              arrow
                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                            >
                              <StyledCloseIcon onClick={onRemoveCalendar} />
                            </Tooltip>
                            <Tooltip
                              title="Change account to send invites from"
                              arrow
                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                            >
                              <SettingsIcon className="settings-icon" onClick={handleOpenSendInvitesFromModalOpen} />
                            </Tooltip>
                          </StyledCalendarWrapper>
                        );
                      })}
                </StyledCalendarsContainer>
              </>
              // <StyledCalendar>
              //   {/* <Icon /> */}
              //   <StyledEmail className="ml-2">{values.externalCalendarEmail}</StyledEmail>
              //   <StyledCloseIcon onClick={onRemoveCalendar} />
              // </StyledCalendar>
            )}
          </StyledCalendarTabContainer>
        </CardHeader>
        <CardBody mobileView={mobileView}>
          <Grid container className="mb-2">
            <Field as={StyledCheckbox} name="enrollment.anyTime" type="checkbox" color="primary" />
            <StyledLabel className="mt-2">Anytime</StyledLabel>
            <Tooltip
              title="Check this box to let your clients enroll anytime. Alternatively, uncheck this box to create a specific enrollment window for your membership."
              arrow
              className="ml-2 mt-2"
              enterTouchDelay={TOOLTIP.ENTER_DELAY}
              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
            >
              <InfoIcon htmlColor={colors.lightBrown} />
            </Tooltip>
          </Grid>
          {!enrollment?.anyTime && (
            <Grid container spacing={4}>
              <Grid item md={6} xs={12}>
                <DateTimePicker
                  fullWidth
                  name="enrollment.fromDate"
                  type="date"
                  label="From"
                  minDate={moment().add(0, 'd')}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <DateTimePicker
                  fullWidth
                  name="enrollment.toDate"
                  defaultHour={23}
                  defaultMinute={59}
                  defaultSecond={59}
                  defaultMillisecond={999}
                  type="date"
                  label="To"
                  minDate={moment().add(0, 'd')}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <div>
                    <StyledAllowEnrollmentLabel mobileView={mobileView}>
                      Clients Must Enroll Within Specified Dates
                    </StyledAllowEnrollmentLabel>
                  </div>
                  <div style={{ marginBottom: '5px' }}>
                    <Tooltip
                      title="If enabled, you need to specify the enrollment dates. Clients must complete enrollment before the end-date; otherwise, enrollment for services will be closed."
                      arrow
                      className="ml-2"
                      enterTouchDelay={TOOLTIP.ENTER_DELAY}
                      leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                    >
                      <InfoIcon htmlColor={colors.lightBrown} />
                    </Tooltip>
                  </div>
                  <div style={{ marginLeft: '8px', marginTop: '2px' }}>
                    <Switch className="switch">
                      <StyledInput
                        type="checkbox"
                        onChange={e =>
                          setFieldValue('enrollmentWithSpecificDateEnabled', !values.enrollmentWithSpecificDateEnabled)
                        }
                        checked={values?.enrollmentWithSpecificDateEnabled}
                      />
                      <StyledSlider className="slider round" />
                    </Switch>
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
          <StyledWrapper>
            <StyledParticipantsLabel>Max Participant Count</StyledParticipantsLabel>
            <div style={{ marginBottom: '5px' }}>
              <Tooltip
                title="When maximum participant count is reached enrollment will be closed for the clients."
                arrow
                className="ml-2"
                enterTouchDelay={TOOLTIP.ENTER_DELAY}
                leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
              >
                <InfoIcon htmlColor={colors.lightBrown} />
              </Tooltip>
            </div>
            <div style={{ marginLeft: '8px', marginTop: '4px' }}>
              <Switch className="switch">
                <StyledInput
                  type="checkbox"
                  onChange={() => setFieldValue('enableMaxParticipants', !values.enableMaxParticipants)}
                  checked={values?.enableMaxParticipants}
                />
                <StyledSlider className="slider round" />
              </Switch>
            </div>
          </StyledWrapper>
          {values.enableMaxParticipants && (
            <Grid container md={12} xs={12}>
              <Grid md={12} xs={12}>
                <TextField
                  type="number"
                  value={values?.participantsLimit}
                  onChange={e => setFieldValue('participantsLimit', e.target.value)}
                  inputClasses={{ root: classes.inputRoot }}
                />
              </Grid>
              {touched?.participantsLimit && errors?.participantsLimit && (
                <CommonErrorMessage message={errors?.participantsLimit} align="left" />
              )}
            </Grid>
          )}
        </CardBody>
      </Card>

      {isSendInvitesFromModalOpen && (
        <SendInvitesFromModal
          isOpen
          onModalClose={handleCloseSendInvitesFromModalOpen}
          onSubmit={handleModalSubmit}
          setFieldValue={setFieldValue}
          values={values}
          // onSubmit={handleModalSubmit}
          // calendars={calendars}
          addCalendar={addCalendar}
          contributionId={query.id}
          isCreate={isCreate}
          // lastAddedMail={lastAddedMail}
          // loadingLastEmail={loadingLastEmail}
          isGroup
        />
      )}

      {removedCalendarEmail && (
        <RemoveCalendarModal
          isOpen={removedCalendarEmail}
          onCancel={handleCloseRemoveCalendarModal}
          onSubmit={onRemovecalendarSubmit}
        />
      )}
      {(showExternalCalendarSettingsModal ||
        showExternalCalendarConnectionCompleteStepsModal.showStep1 ||
        showExternalCalendarConnectionCompleteStepsModal.showStep2) && (
        <ExternalCalendarSettingsPopup
          isModalOpen={
            showExternalCalendarSettingsModal ||
            showExternalCalendarConnectionCompleteStepsModal.showStep1 ||
            showExternalCalendarConnectionCompleteStepsModal.showStep2
          }
          // connectedCalendars={calendars}
          connectedCalendar={calendarForSettingsPopup}
          onCloseModal={() => {
            setCalendarForSettingsPopup(null);
            setShowExternalCalendarSettingsModal(false);
            setShowExternalCalendarConnectionCompleteStepsModal({
              ...showExternalCalendarConnectionCompleteStepsModal,
              showStep1: false,
              showStep2: false,
            });
          }}
          setShowExternalCalendarConnectionCompleteStepsModal={setShowExternalCalendarConnectionCompleteStepsModal}
          showExternalCalendarConnectionCompleteStepsModal={showExternalCalendarConnectionCompleteStepsModal}
          // onConfirm={onRescheduleSlotSelection}
          isOneToOneContributionPage
        />
      )}
    </Grid>
  );
};

EnrollmentDates.propTypes = {
  enrollment: PropTypes.shape({
    toDate: PropTypes.string,
    fromDate: PropTypes.string,
    anyTime: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = ({ oneToOneData, contributions, calendars }) => ({
  calendars: calendars.calendarsAccounts,
  lastAddedMail: calendars.lastAddedEmail,
  loadingLastEmail: calendars.loadingLastEmail,
  accountsForConflictsCheck: calendars.accountsForConflictsCheck,
  contribution: contributions.activeContribution,
  loadingTimes: contributions.loadingTimes,
});
const actions = {
  fetchCalendars: calendarsActions.fetchCalendars,
  addCalendar: calendarsActions.addCalendar,
  getAcсountsForConflictsCheck: calendarsActions.getAcсountsForConflictsCheck,
  getAcсountForSendReminders: calendarsActions.getAcсountForSendReminders,
  removeCalendar: calendarsActions.removeCalendar,
};

export default connect(mapStateToProps, actions)(EnrollmentDates);
