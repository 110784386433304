import React, { useEffect, useState } from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';

import Button from 'components/FormUI/Button';

import styled from 'styled-components';

import '../Banner.scss';
import { redirectTo } from 'services/links';

const StyledButton = styled(Button)`
  background-color: #0e536b;
  padding: 6px 14px;
  // min-width: 95px;
  // width: 95px;
  font-size: 12px;
  font-weight: 700;
`;

const ZoomConnectionBanner = () => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const BannerButton = mobileView ? StyledButton : Button;
  const onClick = async () => {
    redirectTo('/account/video');
  };

  return (
    <div className={`banner-container ${mobileView && 'banner-container__mobile'}`}>
      <p className="banner-container__text">Action Required: Your Zoom Account Needs Reauthentication.</p>
      <BannerButton variant="primary" className="banner-container__button" autoWidth onClick={onClick}>
        Authenticate Now
      </BannerButton>
    </div>
  );
};

export default ZoomConnectionBanner;
