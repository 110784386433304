import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useTheme, useMediaQuery } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { ApplicationFormRow } from './ApplicationFormRow';

const BoldTableCell = withStyles({
  root: {
    fontWeight: 800,
  },
})(TableCell);
const StyledTableContainer = withStyles({
  root: {
    overflowX: 'auto',
    maxWidth: props => (props.mobileView ? '96vw' : '100%'),
    margin: '0 auto',
  },
})(TableContainer);

const ApplicationFormsTable = ({ rows }) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [openedIndex, setOpenedIndex] = useState(null);

  return (
    <>
      <StyledTableContainer component={Paper} mobileView={mobileView}>
        <Table className="applications-table" aria-label="applications-table">
          <TableHead>
            <TableRow>
              <BoldTableCell>Service</BoldTableCell>
              <BoldTableCell>Date</BoldTableCell>
              <BoldTableCell>Invite</BoldTableCell>
              <BoldTableCell>Edit</BoldTableCell>
              <BoldTableCell>Delete</BoldTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <ApplicationFormRow
                row={row}
                index={index}
                key={row.id}
                totalRows={rows.length}
                openedIndex={openedIndex}
                setOpenedIndex={setOpenedIndex}
              />
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
};
ApplicationFormsTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
};
ApplicationFormsTable.defaultProps = {
  rows: [],
};
export default ApplicationFormsTable;
