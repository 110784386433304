import styled from 'styled-components';
import { colors } from '../../../../../utils/styles';
import { Box } from '@material-ui/core';

export const StyledContainerLink = styled.a`
  display: flex;
  flex-direction: row;
  width: 100%;
  ${({ mobileView, noImage }) =>
    `
    min-height: ${noImage ? '' : mobileView ? '172px' : '96px'};
    `};
  ${({ noImage }) =>
    `
    padding: ${noImage ? '10px' : ''};
    `};
  margin-bottom: 8px;
  border-radius: 4px;
  background-color: rgba(250, 250, 250, 1);
  overflow: hidden;
  transition: all 0.2s;
  &:hover {
    box-shadow: 0 0 18px ${colors.shadowDark};
    text-decoration: none;
  }
`;
export const StyledImg = styled.div`
  ${({ bg }) =>
    `
    background-image: url(${bg});
    `};
  ${({ mobileView }) =>
    `
    width: ${mobileView ? '50%' : '196px'};
    `};
  ${({ mobileView }) =>
    `
    min-width: ${mobileView ? '50%' : '196px'};
    `};
  ${({ mobileView }) =>
    `
    min-height: ${mobileView ? '172px' : '96px'};
    `};
  background-size: cover;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;
export const StyledTitle = styled(Box)`
  padding: 0px 10px 0;
  margin: 0;
  font-weight: bolder;
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  color: ${colors.textMain};
`;
export const StyledDescription = styled(Box)`
  padding: 0 10px 10px;
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  color: ${colors.textSecondary};
`;
