import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { StyledContainerLink, StyledImg, StyledTitle, StyledDescription } from './LinkPreview.styled';
import isEqual from 'lodash/isEqual';
import { useMediaQuery, useTheme } from '@material-ui/core';

const LinkPreview = ({ item }) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  if (!item?.imageUrl && !item?.title && !item?.description) {
    return false;
  }
  return (
    <StyledContainerLink
      mobileView={mobileView}
      noImage={!item?.imageUrl}
      href={item.url}
      target="_blank"
      alt={item.title}
    >
      {item?.imageUrl && <StyledImg mobileView={mobileView} bg={item.imageUrl} />}
      <div style={{ gap: '8px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        {item?.title && <StyledTitle component="p">{item.title}</StyledTitle>}
        {item?.description && <StyledDescription component="p">{item.description}</StyledDescription>}
      </div>
    </StyledContainerLink>
  );
};
LinkPreview.propTypes = {
  item: PropTypes.shape({
    description: PropTypes.string,
    imageUrl: PropTypes.string,
    postId: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
  }),
};
const areEqual = (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
};
export default memo(LinkPreview, areEqual);
