import React from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@mui/material/TextField';
import { colors } from 'utils/styles';
import { makeStyles } from '@material-ui/styles';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { getThemedColors } from 'services/contributions.service';
import { debounce } from 'lodash';
import { ImCancelCircle } from 'react-icons/im';
import styled from 'styled-components';
import { determineDarkThemedColorToUse } from 'services/contributions.service';

const StyledImCancelCircle = styled(ImCancelCircle)`
  color: ${({ color }) => color};
  height: 20px;
  width: 20px;
  margin: 0px 15px;
  cursor: pointer;

  ${({ mobileView }) =>
    mobileView &&
    `
      height: 15px;
      width: 15px;
  `}
`;

const StyledTextField = styled(TextField)`
  ${({ NotForSearch }) =>
    NotForSearch &&
    `
    input {
      margin-bottom: auto !important;
      margin-top: -7px;
    }
  `}
  .Mui-disabled {
    pointer-events: none;
  }
`;

const styles = makeStyles(theme => ({
  input: {
    borderRadius: '4px',
    '& .MuiOutlinedInput-root': {
      height: '95px',
      fontSize: '18px',
      backgroundColor: 'rgba(250, 250, 250, 1)',
      borderColor: '#e7e7e7',
      '& fieldset': {
        border: '0px',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #e7e7e7',
        borderColor: '#e7e7e7',
      },
      '& input::placeholder': {
        fontFamily: 'Avenir',
        fontSize: '15px',
        fontWeight: '400px',
        lineHeight: '16px',
        opacity: '50%',
      },
    },
    '&.search .MuiOutlinedInput-root': {
      height: '40px',
      width: '340px',
    },
    '& .css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root': {
      paddingRight: '0px',
    },
  },
  inputMobile: {
    borderRadius: '4px',
    '& .MuiOutlinedInput-root': {
      height: '40px',
      width: 'calc(100vw - 72px)',
      fontSize: '18px',
      border: '1px solid #e7e7e7',
      backgroundColor: 'rgba(250, 250, 250, 1)',
      borderColor: '#e7e7e7',
      '& fieldset': {
        border: '0px',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #e7e7e7',
        borderColor: '#e7e7e7',
      },
      '& input::placeholder': {
        fontFamily: 'Avenir',
        fontSize: '10px',
        fontWeight: '350px',
        lineHeight: '16px',
        opacity: '50%',
      },
    },
    '& .css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root': {
      paddingRight: '0px',
    },
    '& .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input': {
      paddingTop: '11px',
    },
  },
  inputDarkTheme: {
    borderRadius: '4px',
    '& .MuiOutlinedInput-root': {
      height: '95px',
      fontSize: '18px',
      color: 'white',
      border: '1px solid #e7e7e7',
      borderColor: '#e7e7e7',
      '& fieldset': {
        border: '0px',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #e7e7e7',
        borderColor: '#e7e7e7',
      },
      '& input::placeholder': {
        fontFamily: 'Avenir',
        fontSize: '15px',
        fontWeight: '400px',
        lineHeight: '16px',
        opacity: '50%',
      },
    },
    '&.search .MuiOutlinedInput-root': {
      height: '40px',
      width: '340px',
    },
    '& .css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root': {
      paddingRight: '0px',
    },
  },
  inputDarkThemeMobile: {
    borderRadius: '4px',
    '& .MuiOutlinedInput-root': {
      height: '40px',
      width: 'calc(100vw - 72px)',
      fontSize: '18px',
      color: 'white',
      border: '1px solid #e7e7e7',
      borderColor: '#e7e7e7',
      '& fieldset': {
        border: '0px',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #e7e7e7',
        borderColor: '#e7e7e7',
      },
      '& input::placeholder': {
        fontFamily: 'Avenir',
        fontSize: '12px',
        fontWeight: '350px',
        lineHeight: '16px',
        opacity: '50%',
      },
    },
    '& .css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root': {
      paddingRight: '0px',
    },
  },
}));

const SearchBar = ({
  searchFilter,
  colorToUse,
  onSearchFilterChange,
  placeholder = 'Search',
  NotForSearch,
  onCrossClick,
  onCrossIconClick,
  mobileView,
  darkPlaceHolder = false,
  disabled = false,
}) => {
  const contribution = useContribution();
  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);
  const { themedColor } = getThemedColors(contribution);
  const classes = styles();

  const onChange = e => {
    onSearchFilterChange(e?.target?.value);
  };

  const className = (isDarkThemeEnabled, mobileView) => {
    if (isDarkThemeEnabled) {
      if (mobileView) {
        return classes.inputDarkThemeMobile;
      }
      return classes.inputDarkTheme;
    } else {
      if (mobileView) {
        return classes.inputMobile;
      }
      return classes.input;
    }
  };

  return (
    <>
      <StyledTextField
        NotForSearch={NotForSearch}
        placeholder={placeholder}
        className={`${className(isDarkThemeEnabled, mobileView)} ${NotForSearch ? '' : 'search'}`}
        value={searchFilter}
        onChange={darkPlaceHolder ? () => {} : onChange}
        disabled={disabled}
        sx={
          darkPlaceHolder && {
            input: {
              '&::placeholder': {
                opacity: 1,
              },
            },
          }
        }
        InputProps={
          !!NotForSearch
            ? {}
            : {
                startAdornment:
                  searchFilter?.length == 0 ? (
                    <InputAdornment position="start">
                      {' '}
                      <SearchIcon htmlColor={colors.gray} />{' '}
                    </InputAdornment>
                  ) : null,
                endAdornment:
                  searchFilter?.length > 0 ? (
                    <InputAdornment position="start">
                      {' '}
                      <div onClick={onCrossClick} style={{ marginRight: '11px', fontSize: '14px', cursor: 'pointer' }}>
                        clear
                      </div>
                    </InputAdornment>
                  ) : null,
              }
        }
        fullWidth
      />
      {!NotForSearch && (
        <CloseIcon
          onClick={onCrossIconClick}
          style={{
            position: 'absolute',
            top: '50%',
            right: '10px',
            transform: 'translateY(-50%)',
            cursor: 'pointer',
            color: themedColor || '#858585',
            fontSize: mobileView ? '20px' : '24px',
          }}
        />
      )}
    </>
  );
};

SearchBar.propTypes = {
  searchFilter: PropTypes.string.isRequired,
  onSearchFilterChange: PropTypes.func.isRequired,
};

export default SearchBar;
