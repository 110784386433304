import React from 'react';

const CommunityVideoSvg = ({ width = '24px', height = '24px', color = '#215C73' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 17" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5504 10.475L14.7504 8.325C15.0504 8.125 15.2004 7.85 15.2004 7.5C15.2004 7.15 15.0504 6.875 14.7504 6.675L11.5504 4.525C11.217 4.29167 10.8754 4.275 10.5254 4.475C10.1754 4.675 10.0004 4.975 10.0004 5.375V9.625C10.0004 10.025 10.1754 10.325 10.5254 10.525C10.8754 10.725 11.217 10.7083 11.5504 10.475ZM7.00037 15C6.45037 15 5.97971 14.8043 5.58837 14.413C5.19704 14.0217 5.00104 13.5507 5.00037 13V2C5.00037 1.45 5.19637 0.979333 5.58837 0.588C5.98037 0.196667 6.45104 0.000666667 7.00037 0H18.0004C18.5504 0 19.0214 0.196 19.4134 0.588C19.8054 0.98 20.001 1.45067 20.0004 2V13C20.0004 13.55 19.8047 14.021 19.4134 14.413C19.022 14.805 18.551 15.0007 18.0004 15H7.00037ZM7.00037 13H18.0004V2H7.00037V13ZM3.70037 19.875C3.15037 19.9417 2.65471 19.8083 2.21337 19.475C1.77204 19.1417 1.51771 18.7 1.45038 18.15L0.125375 7.225C0.0587083 6.675 0.200375 6.18333 0.550375 5.75C0.900375 5.31667 1.35038 5.05833 1.90038 4.975H1.95038C2.23371 4.925 2.48771 5 2.71237 5.2C2.93704 5.4 3.04971 5.65 3.05038 5.95C3.05038 6.2 2.96304 6.41667 2.78838 6.6C2.61371 6.78333 2.40104 6.9 2.15038 6.95H2.12538L3.47537 17.9L15.3254 16.45C15.6087 16.4167 15.8587 16.4833 16.0754 16.65C16.292 16.8167 16.417 17.0417 16.4504 17.325C16.4837 17.6083 16.417 17.8543 16.2504 18.063C16.0837 18.2717 15.8587 18.3923 15.5754 18.425L3.70037 19.875Z"
        fill={color}
      />
    </svg>
  );
};

export default CommunityVideoSvg;
