import React, { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Modal from 'components/UI/Modal';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { connect } from 'react-redux';
import moment from 'moment';

import * as calendarsActions from 'actions/calendars';
import { AddCalendarModalContent } from './AddCalendarModalContent';
import { ADD_CALENDAR_MODAL_FIELDS } from './AddCalendarModalConstants';
import { SEND_INVITE_MODAL_FIELDS } from './AddCalendarModalConstants';
import './AddCalendarModal.scss';
import ExternalCalendarSettingsPopupContent from 'pages/MasterCalendarPage/components/ExternalCalendarSettingsPopupContent';

const AddCalendarModal = ({
  isOpen,
  onModalClose,
  onSubmit,
  accountsForConflictsCheck,
  accountForSendReminders,
  putMailsForConfictCheck,
  putMailForCalendarReminders,
  lastAddedMail,
  oneToOneStartDate,
  oneToOneEndDate,
  fetchCalendars,
  getAcсountsForConflictsCheck,
  values,
  setFieldValue,
  contribution,
  showsetInviteCalendarRow,
  integrationPage,
  setSelectedEmailCalendar,
  isExternalCalendarDisconnected,
  setCalendarForSettingsPopup,
  setShowExternalCalendarSettingsModal,
  isOneToOneStep3Page = false,
  title = 'Add My Calendar',
  draftCallback = () => {},
  closeJoyRide = () => {},
  ...restProps
}) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const handleSubmit = (e, formProps) => {
    setSelectedEmailCalendar(formProps.values.externalCalendarEmail);
    putMailsForConfictCheck(
      formProps.values.checkedCalendars,
      moment(oneToOneStartDate).format('YYYY-MM-DD'),
      moment(oneToOneEndDate).format('YYYY-MM-DD'),
    );
    putMailForCalendarReminders(formProps.values.calendar);
    onSubmit();
  };
  useEffect(() => {
    if (isOpen) {
      closeJoyRide();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isExternalCalendarDisconnected === true) {
      fetchCalendars();
      getAcсountsForConflictsCheck();
    }
  }, []);

  const preparedCheckedCalendars =
    accountsForConflictsCheck?.length > 0 ? accountsForConflictsCheck.map(account => account.emailAddress) : [];

  return (
    <Formik
      initialValues={{
        [ADD_CALENDAR_MODAL_FIELDS.CHECKED_CALENDARS]: lastAddedMail ? [lastAddedMail] : preparedCheckedCalendars,
        [ADD_CALENDAR_MODAL_FIELDS.CHECKED_CALENDARS_ADD]: ['Add calendar'],
        [ADD_CALENDAR_MODAL_FIELDS.CALENDAR]: accountForSendReminders,
        [SEND_INVITE_MODAL_FIELDS.externalCalendarEmail]: (values && values?.externalCalendarEmail) || null,
      }}
      // enableReinitialize
    >
      {formProps => (
        <Modal
          applyTheming={false}
          isDarkModeSelected={false}
          isCreatingContribution
          title={title}
          className="add-my-calendar-modal"
          isOpen={isOpen}
          onCancel={onModalClose}
          // onSubmit={e => handleSubmit(e, formProps)}
          // submitTitle={mobileView ? 'Add' : 'Add calendar'}
          disableFooter
        >
          <>
            <AddCalendarModalContent
              {...restProps}
              setFieldValue={formProps.setFieldValue}
              mainSetFieldValue={setFieldValue}
              lastAddedMail={lastAddedMail}
              values={formProps.values}
              setSelectedEmailCalendar={setSelectedEmailCalendar}
              preparedCheckedCalendars={preparedCheckedCalendars}
              contribution={contribution}
              showsetInviteCalendarRow={showsetInviteCalendarRow}
              integrationPage={integrationPage}
              onOpenSettingsClick={selectedCalendar => {
                setCalendarForSettingsPopup(selectedCalendar);
                setShowExternalCalendarSettingsModal(true);
                onModalClose();
              }}
              isOneToOneStep3Page={isOneToOneStep3Page}
              draftCallback={draftCallback}
            />
          </>
        </Modal>
      )}
    </Formik>
  );
};

AddCalendarModal.propTypes = {
  onModalClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  oneToOneStartDate: PropTypes.shape(),
  oneToOneEndDate: PropTypes.shape(),
};

const mapStateToProps = ({ calendars, oneToOneData, contributions }) => ({
  contribution: contributions.activeContribution,
  accountsForConflictsCheck: calendars.accountsForConflictsCheck,
  accountForSendReminders: calendars.accountForSendReminders,
  oneToOneStartDate: oneToOneData?.startDay,
  oneToOneEndDate: oneToOneData?.endDay,
});

const actions = {
  putMailsForConfictCheck: calendarsActions.putMailsForConfictCheck,
  putMailForCalendarReminders: calendarsActions.putMailForCalendarReminders,
  fetchCalendars: calendarsActions.fetchCalendars,
  getAcсountsForConflictsCheck: calendarsActions.getAcсountsForConflictsCheck,
};

export default connect(mapStateToProps, actions)(AddCalendarModal);
