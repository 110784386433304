import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import * as actions from 'actions/contributions';
import { determineColorToUse } from 'services/contributions.service';
import { fetchContributionActions } from 'actions/contributions';
import { RECORDING_VIDEO_STATUS } from 'constants.js';
import { ContributionType } from 'helpers/constants';
import { TemplateType } from 'helpers/constants/templateType';

import Button from 'components/FormUI/Button';
import { useAccount, useVideoChatActions } from 'hooks';
import VideoPlayer from 'components/UI/VideoPlayer/VideoPlayer';
import PopupOptions from 'components/PopupOptions/PopupOptions';
import useVideoPlayer from 'hooks/useVideoPlayer';
import Popup from 'components/Popup/Popup';
import styled, { css } from 'styled-components';
import { UserRoles } from 'helpers/constants';
import { checkIsFuture } from 'utils/datesAndMoney';
import * as contentService from 'services/content.service';
import * as contributionService from 'services/contributions.service';
import { useDispatch } from 'react-redux';
import axiosInstance from 'utils/axiosInstance';
import * as R from 'ramda';
import { useOverlay } from './hooks/useOverlay';
import { lightOrDark } from 'utils/utils';
import ButtonWraper from 'components/FormUI/Button';
import { DarkEnabledPrimaryFilledButton } from 'pages/ContributionView/ContributionSessions/component/styled';

const StyledButton = styled(Button)`
  margin-left: 10px;
`;

const StyledBPButton = styled(Button)`
  padding: 10px;
  height: 42.5px;
  ${({ mobileView }) =>
    mobileView &&
    css`
      height: 48px;
      font-size: 0.875rem;
    `};

  ${({ mobileView, isCoach }) =>
    mobileView &&
    !isCoach &&
    css`
      padding: 8px 10px !important;
      min-width: 5rem;
      width: 100%;
    `};

  ${({ mobileView, isCoach }) =>
    mobileView &&
    isCoach &&
    css`
      min-width: 5rem;
      width: 100%;
    `};
`;

const LaunchRecordedSessionVideo = props => {
  const {
    session,
    contribution,
    contribution: { id, type, chat, title },
    sessionTime,
    mobileView,
    isPreviewMode,
    sessionTimeIndex,
    shouldBeDisabled = null,
    refetch,
    isSessionView = false,
    isOntoOneBottomPanel = false,
  } = props;

  const { currentRole, user } = useAccount();
  const isCoach = currentRole === UserRoles.cohealer;
  const activeTemplate = contribution?.activeTemplate || TemplateType.TemplateThree;
  const colorToUse = determineColorToUse(contribution);
  const bgColor = colorToUse.PrimaryColorCode;
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const [isPlayVideo, setIsPlayVideo] = useState(false);
  const [isShowOptionsPopup, setIsShowOptionsPopup] = useState(false);
  const [isShowDeleteOptionsPopup, setIsShowDeleteOptionsPopup] = useState(false);
  const [isShowInfoPopup, setIsShowInfoPopup] = useState(false);
  const dispatch = useDispatch();
  const { startVideoChat } = useVideoChatActions();
  const { loading, getVideoLink, getPreRecordedVideoLink, getPreRecordedZoomVideoLink, error, link } = useVideoPlayer({
    sessionTime,
  });
  const [isProcessingRecording, setIsProcessignRecording] = useState(false);

  useEffect(() => {
    const { recordingInfos = [] } = sessionTime;
    setIsProcessignRecording(recordingInfos?.filter(item => item.status === 0).length > 0);
  }, [sessionTime]);

  const setVideoAsWatched = () => {
    return axiosInstance
      .post(`/Contribution/UserViewedRecording`, {
        contributionId: id,
        sessionTimeId: sessionTime.id,
        userId: user.id,
      })
      .then(() => {
        if (!isCoach) {
          dispatch(actions.fetchClientContribution(id));
        }

        refetch();
      });
    // .then(() => {
    //   R.compose(
    //     dispatch,
    //     actions.updateSession,
    //   )({ sessionId: session.id, sessionTimeId: sessionTime.id, userId: user.id });
    // });
  };
  const makeOptions = recordingInfos => {
    if (session?.isPrerecorded) {
      return [
        {
          value: { contributionId: id, sessionId: session.id, status: RECORDING_VIDEO_STATUS.available },
        },
      ];
    }
    if (sessionTime?.zoomMeetingData?.recordingFileName) {
      return [
        {
          value: {
            contributionId: id,
            sessionId: session.id,
            status: RECORDING_VIDEO_STATUS.available,
            sessionTime,
          },
        },
      ];
    }
    return recordingInfos.map((info, i) => ({
      text: `${i + 1} ${session?.title || ''} ${title || ''} ${moment.utc(info.duration * 1000).format('HH:mm:ss')}`,
      value: {
        contributionId: id,
        roomId: info.roomId,
        status: info.status,
        compositionFileName: info?.compositionFileName,
        sessionTimeId: sessionTime.id,
      },
    }));
  };
  const options = makeOptions(sessionTime.recordingInfos);

  const checkVideoStatus = status => {
    if (status !== RECORDING_VIDEO_STATUS.available) {
      setIsShowOptionsPopup(false);
      setIsShowInfoPopup(true);
      return false;
    }
    return true;
  };

  const onCloseVideo = () => {
    setVideoAsWatched()
      .then(() => setIsPlayVideo(false))
      .catch(() => setIsPlayVideo(false));
  };

  const onClosePopup = () => {
    setIsShowOptionsPopup(false);
    setIsShowInfoPopup(false);
  };

  const onCloseDeletePopup = () => {
    setIsShowDeleteOptionsPopup(false);
  };
  const onSelectOption = option => {
    if (!checkVideoStatus(option.status)) return;
    getVideoLink(option);
    setIsShowOptionsPopup(false);
    setIsPlayVideo(true);
  };

  const onSelectDeleteOption = async option => {
    if (!checkVideoStatus(option.status)) return;
    await contentService.DeleteSessionTimeRecording(
      option.contributionId,
      option.sessionTimeId,
      option.roomId,
      option.compositionFileName,
    );
    const result = await contributionService.getCohealerContribution(option.contributionId);
    dispatch(fetchContributionActions.success(result));
    setIsShowDeleteOptionsPopup(false);
  };

  const onPlayVideo = () => {
    startVideoChat({
      contributionId: id,
      type,
      sessionId: session?.id,
      classId: sessionTime?.id,
      title: session?.title,
      chatId: chat?.sid,
      token: '',
    });

    if (options.length === 1) {
      if (!checkVideoStatus(options[0].value.status)) return;
      setIsPlayVideo(true);
      if (session?.isPrerecorded) {
        getPreRecordedVideoLink(options[0].value);
      } else if (sessionTime?.zoomMeetingData?.recordingFileName) {
        getPreRecordedZoomVideoLink(options[0].value);
      } else {
        getVideoLink(options[0].value);
      }
    } else {
      setIsShowOptionsPopup(true);
    }
  };

  const onDeleteVideo = async () => {
    if (options.length === 1) {
      await contentService.DeleteSessionTimeRecording(
        options[0].value.contributionId,
        options[0].value.sessionTimeId,
        options[0].value.roomId,
        options[0].value.compositionFileName,
      );
      const result = await contributionService.getCohealerContribution(options[0].value.contributionId);
      dispatch(fetchContributionActions.success(result));
      setIsShowDeleteOptionsPopup(false);
    } else {
      setIsShowDeleteOptionsPopup(true);
    }
  };

  const preRecordedSession = sessionTime?.prerecordedSession || session?.prerecordedSession;
  const isFirstVideoViewable = session?.isPrerecorded
    ? preRecordedSession?.status !== RECORDING_VIDEO_STATUS.available
    : sessionTime.recordingInfos[0]?.status === RECORDING_VIDEO_STATUS.available ||
      !!sessionTime?.zoomMeetingData?.recordingFileName;

  const isCohealer = currentRole === UserRoles.cohealer;
  const isDisabled = isCohealer ? false : !isFirstVideoViewable || checkIsFuture(session?.dateAvailable);

  const shouldShowViewButton =
    isCohealer ||
    sessionTime.isCompleted ||
    session?.prerecordedSession ||
    sessionTime?.prerecordedSession ||
    !!sessionTime?.zoomMeetingData?.recordingFileName;

  const gridProps = useOverlay({
    isCohealer,
    isPlayVideo,
    isPreviewMode,
    onPlayVideo,
    session,
    sessionTime,
    sessionTimeIndex,
    user,
    shouldBeDisabled,
  });
  const btnBackgroundColor = contribution?.isDarkModeEnabled ? 'transparent' : null;

  return (
    <>
      {contribution.type != ContributionType.contributionOneToOne ? (
        <Grid {...gridProps}>
          {(!isPreviewMode || !session?.isPrerecorded) && (shouldShowViewButton || isFirstVideoViewable) && (
            <>
              {!isSessionView && (
                <StyledButton
                  autoWidth
                  disabled={isDisabled}
                  // backgroundColor={bgColor}
                  mobileView={mobileView}
                  onClick={onPlayVideo}
                  invert
                  textColor={colorToUse.AccentColorCode}
                  borderColor={isDisabled ? '' : colorToUse.AccentColorCode}
                >
                  {isFirstVideoViewable ? 'View recording' : 'Processing Recording'}
                </StyledButton>
              )}
              {isFirstVideoViewable && isCohealer && (
                <ButtonWraper
                  // backgroundColor={bgColor}
                  // textColor={textColor}
                  invert
                  textColor={
                    contribution.type != ContributionType.contributionOneToOne
                      ? colorToUse.PrimaryColorCode
                      : colorToUse.AccentColorCode
                  }
                  borderColor={
                    contribution.type != ContributionType.contributionOneToOne
                      ? colorToUse.PrimaryColorCode
                      : colorToUse.AccentColorCode
                  }
                  // backgroundColor={btnBackgroundColor}
                  variant="outline"
                  onClick={onDeleteVideo}
                  autoWidth={contribution.type != ContributionType.contributionOneToOne}
                >
                  Delete Recording
                </ButtonWraper>
              )}
              {(!isFirstVideoViewable || isProcessingRecording) && (
                <StyledButton backgroundColor={bgColor} mobileView={mobileView} textColor={textColor} disabled>
                  Processing Recording
                </StyledButton>
              )}
            </>
          )}
        </Grid>
      ) : isOntoOneBottomPanel ? (
        <Grid style={{ display: 'flex', width: mobileView ? '100%' : '' }} {...gridProps}>
          {(!isPreviewMode || !session?.isPrerecorded) && shouldShowViewButton && (
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', width: mobileView ? '100%' : '' }}>
              {(isCohealer || mobileView || (!isCohealer && mobileView)) && (
                <StyledBPButton
                  autoWidth
                  disabled={isDisabled}
                  isCoach={isCoach}
                  // backgroundColor={bgColor}
                  mobileView={mobileView}
                  onClick={onPlayVideo}
                  invert
                  textColor={colorToUse.AccentColorCode}
                  borderColor={isDisabled ? '' : colorToUse.AccentColorCode}
                >
                  {isFirstVideoViewable ? 'View Recording' : 'Processing Recording'}
                </StyledBPButton>
              )}
              {isFirstVideoViewable && isCohealer && (
                <StyledBPButton
                  // backgroundColor={bgColor}
                  // textColor={textColor}
                  mobileView={mobileView}
                  isCoach={isCoach}
                  invert
                  textColor={
                    contribution.type != ContributionType.contributionOneToOne
                      ? colorToUse.PrimaryColorCode
                      : colorToUse.AccentColorCode
                  }
                  borderColor={
                    contribution.type != ContributionType.contributionOneToOne
                      ? colorToUse.PrimaryColorCode
                      : colorToUse.AccentColorCode
                  }
                  // backgroundColor={btnBackgroundColor}
                  autoWidth
                  onClick={onDeleteVideo}
                >
                  Delete Recording
                </StyledBPButton>
              )}
            </div>
          )}
        </Grid>
      ) : (
        <Grid {...gridProps}>
          {(!isPreviewMode || !session?.isPrerecorded) && (shouldShowViewButton || isFirstVideoViewable) && (
            <>
              {!isSessionView && !isFirstVideoViewable && isCoach === false && (
                <StyledButton
                  autoWidth
                  disabled={isDisabled}
                  // backgroundColor={bgColor}
                  mobileView={mobileView}
                  onClick={onPlayVideo}
                  invert
                  textColor={colorToUse.AccentColorCode}
                  borderColor={isDisabled ? '' : colorToUse.AccentColorCode}
                >
                  {'Processing Recording'}
                </StyledButton>
              )}
            </>
          )}
        </Grid>
      )}
      {isPlayVideo && (
        <VideoPlayer
          onCloseVideo={onCloseVideo}
          chatId={chat?.sid}
          link={link}
          error={error}
          loading={loading}
          isCohealer={isCohealer}
          session={session}
          sessionTime={sessionTime}
        />
      )}
      {isShowOptionsPopup && (
        <PopupOptions
          mobileView={mobileView}
          title="Please select the video you would like to view"
          options={options}
          onSelectOption={onSelectOption}
          open={isShowOptionsPopup}
          onClose={onClosePopup}
        />
      )}
      {isShowDeleteOptionsPopup && (
        <PopupOptions
          mobileView={mobileView}
          title="Please select the video you would like to delete"
          options={options}
          onSelectOption={onSelectDeleteOption}
          open={isShowDeleteOptionsPopup}
          onClose={onCloseDeletePopup}
        />
      )}
      {isShowInfoPopup && (
        <Popup
          title="Video is processing"
          text="Video is processing. Please, try out later!"
          open={isShowInfoPopup}
          onCloseText="OK"
          onClose={onClosePopup}
        />
      )}
    </>
  );
};

LaunchRecordedSessionVideo.defaultProps = {
  session: {},
  mobileView: false,
  isPreviewMode: false,
  sessionTimeIndex: false,
};

LaunchRecordedSessionVideo.propTypes = {
  contribution: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    chat: PropTypes.shape({
      sid: PropTypes.string,
    }),
  }).isRequired,
  sessionTimeIndex: PropTypes.number,
  session: PropTypes.shape({
    dateAvailable: PropTypes.string,
    id: PropTypes.string,
    isPrerecorded: PropTypes.bool,
    name: PropTypes.string,
    title: PropTypes.string,
    prerecordedSession: PropTypes.shape({
      contentType: PropTypes.string,
      documentKeyWithExtension: PropTypes.string,
      documentOriginalNameWithExtension: PropTypes.string,
      id: PropTypes.string,
      status: PropTypes.string,
    }),
    prerecordedSessions: PropTypes.shape({
      contentType: PropTypes.string,
      documentKeyWithExtension: PropTypes.string,
      documentOriginalNameWithExtension: PropTypes.string,
      id: PropTypes.string,
      status: PropTypes.string,
    }),
  }),
  sessionTime: PropTypes.shape({
    id: PropTypes.string,
    isCompleted: PropTypes.bool,
    recordingInfos: PropTypes.array.isRequired,
    prerecordedSession: PropTypes.shape({
      contentType: PropTypes.string,
      documentKeyWithExtension: PropTypes.string,
      documentOriginalNameWithExtension: PropTypes.string,
      id: PropTypes.string,
      status: PropTypes.string,
    }),
  }).isRequired,
  mobileView: PropTypes.bool,
  isPreviewMode: PropTypes.bool,
};

export default LaunchRecordedSessionVideo;
