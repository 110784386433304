import React from 'react';

const CommunityDeleteSvg = ({ width = '24px', height = '24px', color = '#215C73' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 17" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.28544 2.14123H5.14258C5.22115 2.14123 5.28544 2.07694 5.28544 1.99837V2.14123H10.714V1.99837C10.714 2.07694 10.7783 2.14123 10.8569 2.14123H10.714V3.42694H11.9997V1.99837C11.9997 1.36801 11.4872 0.855515 10.8569 0.855515H5.14258C4.51222 0.855515 3.99972 1.36801 3.99972 1.99837V3.42694H5.28544V2.14123ZM14.2854 3.42694H1.71401C1.39794 3.42694 1.14258 3.6823 1.14258 3.99837V4.5698C1.14258 4.64837 1.20686 4.71266 1.28544 4.71266H2.36401L2.80508 14.0519C2.83365 14.6609 3.33722 15.1412 3.94615 15.1412H12.0533C12.664 15.1412 13.1658 14.6627 13.1944 14.0519L13.6354 4.71266H14.714C14.7926 4.71266 14.8569 4.64837 14.8569 4.5698V3.99837C14.8569 3.6823 14.6015 3.42694 14.2854 3.42694ZM11.9158 13.8555H4.08365L3.65151 4.71266H12.3479L11.9158 13.8555Z"
        fill={color}
        fill-opacity="0.87"
      />
    </svg>
  );
};

export default CommunityDeleteSvg;
