import React, { useEffect, useState } from 'react';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import settingIcon from 'assets/setting-icon.png';
import Button from 'components/FormUI/Button';
import Select from 'components/FormUI/Select';
import Loader from 'components/UI/Loader';
import { AddCalendarModalBox } from './AddCalendarModalBox';
import { SendInviteCalendarModalBox } from './AddCalendarModalBox';
import { ADD_CALENDAR_MODAL_FIELDS } from './AddCalendarModalConstants';
import { SEND_INVITE_MODAL_FIELDS } from './AddCalendarModalConstants';
import { isArray, isEmpty, values } from 'lodash';
import GoogleColorIcon from 'assets/google-color-icon.png';
import Modal from 'components/UI/Modal';
import Select2 from 'components/UI/Select';
import { hasPrefixSuffix } from 'antd/lib/input/ClearableLabeledInput';
import { useContribution } from 'pages/ContributionView/hooks';
import useRouter from 'hooks/useRouter';

const StyledLabel = styled.span`
  font-weight: 800;
`;
const StyledLabelSecondary = styled.p`
  color: #a4a4a4;
  font-size: 12px;
  line-height: 14px;
`;
const StyledContent = styled.p`
  margin-top: 12px;
`;
const StyledRow = styled(Grid)`
  padding: 16px 0;
`;
const StyledButton = styled(Button)`
  min-width: 95px;
  width: 100%;
`;
const StyledLink = styled(Link)`
  font-size: 12px;
`;
const MessageText = styled.p`
  font-weight: 400;
  font-family: Avenir;
  font-size: 16px;
`;
// const firstSelectValue = [{ title: "Add calendar", value: "Add calendar" }];

export const AddCalendarModalContent = ({
  addCalendar,
  calendars = [],
  contributionId,
  isCreate,
  isMasterCalendar,
  lastAddedMail,
  values,
  loadingLastEmail,
  setFieldValue,
  mainSetFieldValue,
  preparedCheckedCalendars,
  showsetInviteCalendarRow,
  integrationPage,
  setSelectedEmailCalendar,
  isRemoveEmailCalendar,
  setIsRemoveEmailCalendar,
  onOpenSettingsClick,
  isOneToOneStep3Page = false,
  draftCallback,
}) => {
  const [email, setEmail] = useState(null);
  const contribution = useContribution();
  const { pathname, query } = useRouter();
  const [showAddAnotherCalendarModal, setShowAddAnotherCalendarModal] = useState(false);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const preparedValues =
    calendars?.length > 0
      ? calendars.map(el => ({
          title: `${el.emailAddress}${el.isDefault ? ' (default)' : ' '}`,
          value: el.emailAddress,
        }))
      : [];

  const getValues = () => {
    const preparedValues =
      calendars.length > 0
        ? calendars.map(el => ({
            title: `${el.emailAddress}${el.isDefault ? ' (default)' : ' '}`,
            value: el.emailAddress,
          }))
        : [];

    if (preparedValues.length > 0) {
      preparedValues.unshift({
        title: 'None',
        value: null,
      });
    }
    return preparedValues;
  };

  useEffect(() => {
    if (isRemoveEmailCalendar) {
      setFieldValue('externalCalendarEmail', null);
      setIsRemoveEmailCalendar(false);
    }
  }, []);

  // const renderAddToCalendarValue = (selected) => {
  // const Icon = getDomainIcon(selected);
  //   return <StyledValue>
  //      <Icon /> <span className="ml-2">{selected}</span>
  //    </StyledValue>
  //  }

  useEffect(() => {
    if (calendars?.length > 0) {
      calendars.map(item => {
        if (item.isDefault === true) {
          setEmail(item.emailAddress);
        }
      });
    }
  }, [calendars]);

  const onLinkClick = e => {
    draftCallback(e, isMasterCalendar, 'manageCalendar');
    // e.preventDefault();
    // addCalendar({ contributionId, isCreate: true, isMasterCalendar });
  };

  let selectDefaultValue = null;

  const invitesFromSelectDefaultValue = !contribution?.externalCalendarEmail
    ? []
    : {
        title: contribution.externalCalendarEmail,
        value: contribution.externalCalendarEmail,
      };

  if (lastAddedMail) {
    const foundCalendar = calendars && calendars.find(el => el.emailAddress === lastAddedMail);
    if (foundCalendar) {
      selectDefaultValue = {
        title: foundCalendar.emailAddress,
        value: foundCalendar.emailAddress,
      };
    }
  }

  useEffect(() => {
    if (!preparedCheckedCalendars?.length && lastAddedMail) {
      setFieldValue('checkedCalendars', [lastAddedMail]);
    }
  }, [lastAddedMail]);
  const chooseProviderIcon = mailProvider => {
    switch (mailProvider) {
      case 'gmail':
        return GoogleColorIcon;
      default:
        return null;
    }
  };
  console.log('pathname------>', pathname);
  return (
    <Grid container xs={12} className="main-container">
      {loadingLastEmail && <Loader />}
      <div className="connected-calendars-list-container">
        {isArray(calendars) && calendars.length > 0
          ? calendars.map(cal => {
              const ProviderImg = chooseProviderIcon(cal?.provider);
              return cal ? (
                <div className="connected-calendar-container">
                  <div className="account-summary">
                    {ProviderImg && <img className="provider-logo" src={ProviderImg} alt="provider-logo" />}
                    <div
                      className="email-address-title ml-2"
                      style={{
                        display: 'flex',
                        alignItems: mobileView ? 'flex-start' : 'center',
                        gap: '10px',
                        flexDirection: mobileView ? 'column' : 'row',
                      }}
                    >
                      <span>{cal?.emailAddress}</span>
                      {(cal?.isDefault || cal?.isDefaultForGroupCourseInvites) && (
                        <div
                          className="default-label-badge"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#116582',
                            width: 'fit-content',
                            padding: '2px 6px',
                            borderRadius: '5px',
                          }}
                        >
                          <p style={{ marginBottom: '0px', fontSize: '12px', color: 'white' }}>
                            Default for&nbsp;
                            {[cal?.isDefault && '1:1', cal?.isDefaultForGroupCourseInvites && 'Group']
                              .filter(k => !!k)
                              .join(', ')}
                            &nbsp;Invites
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <button type="button" className="btn-link btn-settings" onClick={() => onOpenSettingsClick(cal)}>
                    <img src={settingIcon} width="20px" />
                    {/* Settings */}
                  </button>
                </div>
              ) : null;
            })
          : null}
      </div>
      <button
        type="button"
        onClick={() => {
          if (!isEmpty(contribution) && pathname.includes('calendar/master') === false) {
            setShowAddAnotherCalendarModal(true);
          } else {
            addCalendar({ contributionId, isCreate: true, isMasterCalendar });
          }
        }}
        className="btn-link btn-add-another"
      >
        Add Another
      </button>
      {showAddAnotherCalendarModal && (
        <Modal
          isOpen={showAddAnotherCalendarModal}
          onSubmit={onLinkClick}
          title={'Please Confirm'}
          cancelTitle={'Cancel'}
          submitTitle={'Yes'}
          onCancel={() => {
            setShowAddAnotherCalendarModal(false);
          }}
          brandingColor={false}
          shrinkCancelButton={true}
          cancelBtnStyle={{
            border: '1px solid rgba(0, 0, 0, 0.87)',
            color: 'rgba(0, 0, 0, 0.87)',
          }}
        >
          <MessageText>
            You're about to be redirected to our partner, Nylas, to connect your calendar. Once the calendar is
            successfully added, you will be redirected back to the Integrations tab.
          </MessageText>
          <MessageText>To continue editing your service, go to Services and access your draft.</MessageText>
        </Modal>
      )}
      {isOneToOneStep3Page && (
        <div className="send-one-one-invites-from-container">
          <div className="invite-header-title">Send Invites From</div>
          <Select2
            fullWidth
            className={'select'}
            margin="dense"
            defaultIcon={true}
            value={values?.externalCalendarEmail}
            onChange={({ target: { value } }) => {
              setSelectedEmailCalendar(value);
              setFieldValue('externalCalendarEmail', value);
              mainSetFieldValue('externalCalendarEmail', value);
            }}
            items={
              // [{ title: 'Select Prior Template', value: 0, disabled: true }]
              isArray(calendars) &&
              calendars?.map(k => ({
                title: `${k.emailAddress}${k.isDefault ? ' (default)' : ''}`,
                value: k.emailAddress,
              }))
            }
          />
        </div>
      )}
      {false && (
        <>
          {integrationPage === false && (
            <StyledRow container item xs={12}>
              <Grid item sm={6} xs={12}>
                <StyledLabel>Prevent Double Booking</StyledLabel>
                <StyledContent>
                  Add your personal calendars to prevent schedule conflicts.
                  <br />
                  <StyledLabelSecondary>
                    Nylas is a trusted partner of Cohere. They power our connected calendar features.
                  </StyledLabelSecondary>
                </StyledContent>
              </Grid>
              <Grid item sm={6} xs={12}>
                <AddCalendarModalBox
                  additionalField={<StyledLink onClick={onLinkClick}>Add another calendar</StyledLink>}
                >
                  {calendars.length ? (
                    <Select
                      name={ADD_CALENDAR_MODAL_FIELDS.CHECKED_CALENDARS}
                      fullWidth
                      multiple
                      items={preparedValues}
                      defaultValue={[selectDefaultValue]}
                    />
                  ) : (
                    <StyledButton onClick={onLinkClick}>Add first calendar</StyledButton>
                  )}
                </AddCalendarModalBox>
              </Grid>
            </StyledRow>
          )}

          {showsetInviteCalendarRow == 'true' || integrationPage === true ? (
            <StyledRow container item xs={12}>
              {integrationPage === true ? (
                <Grid item sm={6} xs={12}>
                  <StyledLabel>Default Calendar to Send Invites From</StyledLabel>
                  <StyledContent>
                    Select the calendar you wish to send an invite from to your client, To prevent double booking on 1:1
                    Services, you can add connected calendars on step 3 of 'Create a Service'
                    <br />
                    {/* <StyledLabelSecondary>
              Nylas is a trusted partner of Cohere. They power our connected calendar features.
            </StyledLabelSecondary> */}
                  </StyledContent>
                </Grid>
              ) : (
                <Grid item sm={6} xs={12}>
                  <StyledLabel>Default Calendar to Send Invites From</StyledLabel>
                  <StyledContent>
                    Select the calendar you wish to send an invite from to your client
                    <br />
                    {/* <StyledLabelSecondary>
              Nylas is a trusted partner of Cohere. They power our connected calendar features.
            </StyledLabelSecondary> */}
                  </StyledContent>
                </Grid>
              )}
              <Grid item sm={6} xs={12}>
                <SendInviteCalendarModalBox>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <StyledLink></StyledLink>
                    <StyledLink onClick={onLinkClick}>Add another calendar</StyledLink>
                  </div>

                  {calendars.length > 0 ? (
                    <Select
                      name={SEND_INVITE_MODAL_FIELDS.externalCalendarEmail}
                      fullWidth
                      // multiple
                      value={values.externalCalendarEmail}
                      onChange={e => {
                        setSelectedEmailCalendar(e.target.value);
                        setFieldValue('externalCalendarEmail', e.target.value);
                        mainSetFieldValue('externalCalendarEmail', e.target.value);
                      }}
                      items={getValues()}
                      // defaultValue={[invitesFromSelectDefaultValue]}
                    />
                  ) : (
                    <StyledButton onClick={onLinkClick}>Add first calendar</StyledButton>
                  )}
                </SendInviteCalendarModalBox>
              </Grid>
            </StyledRow>
          ) : null}
        </>
      )}

      {/* To do in future release */}

      {/* <StyledRow container item xs={12}>
        <Grid item sm={6} xs={12}>
          <StyledLabel>Receive Calendar Invites</StyledLabel>
          <StyledContent>Add your primary calendar to receive confirmed client bookings and course sessions.</StyledContent>
        </Grid>
        <Grid item sm={6} xs={12}>
          <AddCalendarModalBox>
          {calendars.length ?
            <Select
              name={ADD_CALENDAR_MODAL_FIELDS.CALENDAR}
              fullWidth
              renderValue={renderAddToCalendarValue}
              items={preparedValues}
            />
            :
            <Select
            name={ADD_CALENDAR_MODAL_FIELDS.CHECKED_CALENDARS}
            fullWidth
            items={firstSelectValue}
            onChange={onLinkClick}
          />
        }
          </AddCalendarModalBox>
        </Grid>
      </StyledRow> */}
    </Grid>
  );
};
