import * as R from 'ramda';

import { combineActions, handleActions } from 'redux-actions';

import * as actions from 'actions/leadMagnets';
import {
  //   SET_INVITE_CODE,
  //   SET_PRICE_CODE,
  //   SET_POP_UP,
  //   SET_POP_UP_BANK,
  //   SET_SINGLE_CODE,
  //   SET_COUPON_CODE,
  //   SET_SHOW_THANK_YOU_PAGE,
  //   SET_RESCHEDULE_MODAL,
  //   SET_PICK_ANOTHER_TIME,
  SHOW_ERROR_POPUP,
  //   SET_REFETCH_CONTRIBUTION,
  //   SET_CONTRIBUTIONS_LOADER,
  //   SET_SESSION_CODE,
} from 'actions/contributions';

const initialState = {
  loading: false,
  error: null,
  // firstLoad: true,
  leadMagnetsCollection: [],
  // TODO: uncomment below line to start with null, remove hardcoded object below
  activeLeadMagnet: null,
  // activeLeadMagnet: {
  //   sessions: [
  //     {
  //       id: '71a19a8c-a2f1-4db7-b93c-e2e1d82eef56',
  //       name: 'Session',
  //       title: 'Session 1',
  //       minParticipantsNumber: 2,
  //       maxParticipantsNumber: 20,
  //       sessionTimes: [
  //         {
  //           id: 'c4057bdc-ecf7-4b27-a08f-15c38712b932',
  //           videoRoomInfo: null,
  //           recordingInfos: [],
  //           participantInfos: [],
  //           participantsIds: [],
  //           eventInfos: [],
  //           scheduledNotficationJobId: null,
  //           podId: null,
  //           isCompleted: false,
  //           completedSelfPacedParticipantIds: [],
  //           completedDateTime: null,
  //           prerecordedSession: null,
  //           attachments: [],
  //           moreInfo: null,
  //           subCategoryName: null,
  //           zoomMeetingData: null,
  //           ignoreDateAvailable: false,
  //           mustWatchPriorSelfPacedRecords: false,
  //           usersWhoViewedRecording: [],
  //           passCode: null,
  //           isPassCodeEnabled: false,
  //           recurringSessionTime: null,
  //           selfPacedThumbnailUrl: null,
  //           embeddedVideoUrl: null,
  //           startTime: '2024-03-14T09:00:09',
  //           endTime: '2024-03-14T10:00:09',
  //         },
  //       ],
  //       isCompleted: false,
  //       completedDateTime: null,
  //       attachments: [],
  //       isPrerecorded: false,
  //       dateAvailable: null,
  //       prerecordedSession: null,
  //       moreInfo: null,
  //       isHappeningToday: false,
  //     },
  //   ],
  //   enrollment: {
  //     fromDate: '2024-03-12T00:00:00',
  //     toDate: '2024-03-13T23:59:59.999',
  //     anyTime: false,
  //   },
  //   archivingAllowed: false,
  //   userId: '65c1c1ccfac0b365b9a3fb30',
  //   title: 'Making Your Business Grow',
  //   serviceProviderName: 'Pikachu Coco',
  //   serviceProviderEmail: null,
  //   status: 'Approved',
  //   timeZoneId: 'Asia/Karachi',
  //   categories: ['Leadership', 'Business Mentorship', 'Business Coaching'],
  //   type: 'ContributionCourse',
  //   stripeAccount: null,
  //   bufferTimeBefore: 0,
  //   bufferTimeAfter: 0,
  //   allowClientsToInvite: false,
  //   inviteClientType: 'Both',
  //   previewContentUrls: [
  //     'https://coherepublic-test.s3.amazonaws.com/65c1c1b4fac0b365b9a3fb2c/5dae9e05-ec37-4aa6-912a-733b12f8e7bd',
  //   ],
  //   contributionTagline: 'One place where you can learn all, and grow.',
  //   purpose:
  //     '<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&#39;s standard dummy text ever since the 1500s.</p>',
  //   whoIAm:
  //     '<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&#39;s standard dummy text ever since the 1500s.</p>',
  //   whatWeDo:
  //     '<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&#39;s standard dummy text ever since the 1500s.</p>',
  //   isDownloadEnabled: false,
  //   preparation:
  //     '<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&#39;s standard dummy text ever since the 1500s.</p>',
  //   whoIAmLabel: 'Who this is for',
  //   whatWeDoLabel: 'What to expect',
  //   isElectronicSignatureActive: false,
  //   whoIAmIcon:
  //     'M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,6A2,2 0 0,0 10,8A2,2 0 0,0 12,10A2,2 0 0,0 14,8A2,2 0 0,0 12,6M12,13C14.67,13 20,14.33 20,17V20H4V17C4,14.33 9.33,13 12,13M12,14.9C9.03,14.9 5.9,16.36 5.9,17V18.1H18.1V17C18.1,16.36 14.97,14.9 12,14.9Z',
  //   whatWeDoIcon:
  //     'M12,2A7,7 0 0,0 5,9C5,11.38 6.19,13.47 8,14.74V17A1,1 0 0,0 9,18H15A1,1 0 0,0 16,17V14.74C17.81,13.47 19,11.38 19,9A7,7 0 0,0 12,2M9,21A1,1 0 0,0 10,22H14A1,1 0 0,0 15,21V20H9V21Z',
  //   purposeIcon:
  //     'M12,21.35L10.55,20.03C5.4,15.36 2,12.27 2,8.5C2,5.41 4.42,3 7.5,3C9.24,3 10.91,3.81 12,5.08C13.09,3.81 14.76,3 16.5,3C19.58,3 22,5.41 22,8.5C22,12.27 18.6,15.36 13.45,20.03L12,21.35Z',
  //   preparationIcon:
  //     'M19,19H4V3H19M11.5,23A1.5,1.5 0 0,1 10,21.5A1.5,1.5 0 0,1 11.5,20A1.5,1.5 0 0,1 13,21.5A1.5,1.5 0 0,1 11.5,23M18.5,0H4.5A2.5,2.5 0 0,0 2,2.5V21.5A2.5,2.5 0 0,0 4.5,24H18.5A2.5,2.5 0 0,0 21,21.5V2.5A2.5,2.5 0 0,0 18.5,0Z',
  //   purposeLabel: 'Why this was created',
  //   isWorkshop: false,
  //   preparationLabel: 'Additional details',
  //   viewPurpose: false,
  //   viewWhoIAm: false,
  //   viewWhatWeDo: false,
  //   viewPreparation: false,
  //   isCustomBrandingColorsActive: true,
  //   isLegacyColorsActive: true,
  //   isDarkModeEnabled: true,
  //   isCoachSelectedDarkModeEnabled: false,
  //   brandingColors: {
  //     PrimaryColorCode: '#287360',
  //     AccentColorCode: '#4c1007',
  //     TertiaryColorCode: '#F6E8BO',
  //     TextColorCode: '#FFFFFF',
  //   },
  //   coachSelectedBrandingColors: {
  //     PrimaryColorCode: '#287360',
  //     AccentColorCode: '#4c1007',
  //     TertiaryColorCode: '#F6E8BO',
  //     TextColorCode: '#FFFFFF',
  //   },
  //   activeTemplate: 'TemplateTwo',
  //   customToS: null,
  //   customLogo: null,
  //   instagramUrl: 'https://instagram.com',
  //   linkedInUrl: 'https://linkedin.com',
  //   youtubeUrl: 'https://youtube.com',
  //   isMembersHiddenInCommunity: false,
  //   isMembersHiddenInGroupChat: false,
  //   isGroupChatHidden: false,
  //   isLiveSessionMembersHidden: false,
  //   facebookUrl: 'https://facebook.com',
  //   tiktokUrl: 'https://tiktok.com',
  //   websiteUrl: 'https://tiktok.com',
  //   thankYouPageRedirectUrl: null,
  //   languageCodes: ['En'],
  //   isSpeakingLanguageRequired: true,
  //   minAge: '18+',
  //   gender: 'NoRequirement',
  //   paymentInfo: {
  //     cost: 6000,
  //     splittedCost: 2000,
  //     multiplePriceList: [
  //       {
  //         cost: 6000,
  //         sessionDuration: null,
  //         splittedCost: 2000,
  //         billingPlanInfo: {
  //           productBillingPlanId: 'price_1OtOtkCQ8tCmDBm0sn86nLa3',
  //           billingPlanGrossCost: 2000,
  //           billingPlanPureCost: 2000,
  //           billingPlanTransferCost: 1941.7,
  //           totalBillingGrossCost: 6000,
  //           totalBillingPureCost: 6000,
  //         },
  //         id: 'p_1db0f7f7-b75a-4b00-82d0-103fb89527c7',
  //         isDefault: true,
  //         packageCost: null,
  //       },
  //     ],
  //     paymentOptions: ['EntireCourse', 'Free', 'SplitPayments'],
  //     splitNumbers: 3,
  //     splitPeriod: 'Monthly',
  //     packageSessionNumbers: null,
  //     packageSessionDiscountPercentage: null,
  //     monthlySessionSubscriptionInfo: null,
  //     membershipInfo: null,
  //     billingPlanInfo: {
  //       productBillingPlanId: 'price_1OtOtkCQ8tCmDBm0sn86nLa3',
  //       billingPlanGrossCost: 2000,
  //       billingPlanPureCost: 2000,
  //       billingPlanTransferCost: 1941.7,
  //       totalBillingGrossCost: 6000,
  //       totalBillingPureCost: 6000,
  //     },
  //     coachPaysStripeFee: true,
  //     packageCost: null,
  //   },
  //   paymentType: 'Advance',
  //   taxType: 'No',
  //   isInvoiced: false,
  //   bio:
  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  //   coachCountry: 'Pakistan',
  //   arePublicPostsAllowed: true,
  //   isCommunityFeatureHiddden: false,
  //   enableCommunityPosts: true,
  //   areClientPublicPostsAllowed: true,
  //   invitationOnly: true,
  //   unreadPostCount: 0,
  //   latestDraftPostId: null,
  //   rating: 0,
  //   customTitleForMeetYourCoach: 'Coach',
  //   likesNumber: 0,
  //   percentageCompleted: 0,
  //   defaultCurrency: 'usd',
  //   defaultSymbol: '$',
  //   externalCalendarEmail: null,
  //   isApplicationFormBannerPreviewed: 'false',
  //   applicationFormViewModel: null,
  //   availableCurrencies: [
  //     {
  //       countryCode: 'US',
  //       code: 'usd',
  //       symbol: '$',
  //       isUserDefaultCurrency: false,
  //       id: '621ec30535d2bf63c1162677',
  //       createTime: '0001-01-01T00:00:00',
  //       updateTime: '0001-01-01T00:00:00',
  //     },
  //   ],
  //   reviews: [],
  //   purchaseStatus: 'unpurchased',
  //   isPurchased: false,
  //   isAccessRevokedByCoach: false,
  //   hasAgreedContributionTerms: true,
  //   adminReviewNotes: [
  //     {
  //       userId: '5ef453113f1b0c0b851ae9f6',
  //       description: 'Auto approved due to invite only',
  //       dateUtc: '2024-03-12T06:33:15.278Z',
  //       status: 'Approved',
  //     },
  //   ],
  //   participants: [],
  //   testimonials: null,
  //   chat: {
  //     sid: 'CHe2cac07a149c44efac9d69bc4ebb2da3',
  //     friendlyName: 'Making Your Business Grow',
  //     previewImageUrl:
  //       'https://coherepublic-test.s3.amazonaws.com/65c1c1b4fac0b365b9a3fb2c/5dae9e05-ec37-4aa6-912a-733b12f8e7bd',
  //     cohealerPeerChatSids: {},
  //     partnerChats: [],
  //     partnerClientChatSid: {},
  //     revokedParticipantIdFromGroupChat: [],
  //   },
  //   earnedRevenue: 0,
  //   partners: [],
  //   closestClassForBanner: null,
  //   liveVideoServiceProvider: {
  //     providerName: 'Cohere',
  //     customLink: null,
  //     linkType: null,
  //   },
  //   customInvitationBody:
  //     'When it\'s time for your session, please launch Cohere by going to the following URL to login: App.Cohere.Live.\nIf you need to contact the other session participant(s), this can also be done by going to App.Cohere.Live and then visiting "Conversations".\nIf you need to reschedule, this can done by going to App.Cohere.Live and then visiting the Sessions tab. \nIf you are a Client, please go to "My Journey", click on the session, and then visit the "Sessions" tab to find the reschedule button. \nIf you are a service provider, please open your Service and go to the "Sessions"\nPLEASE NOTE, THIS IS A NO-REPLY EMAIL ACCOUNT.',
  //   customWaiverId: null,
  //   customWaiverTemplateName: null,
  //   notes: [
  //     {
  //       userId: '65c1c1ccfac0b365b9a3fb30',
  //       contributionId: '65eff6ae99167927fc78959c',
  //       classId: '71a19a8c-a2f1-4db7-b93c-e2e1d82eef56',
  //       title: 'Session 1',
  //       subClassId: null,
  //       textContent: null,
  //       isPrerecorded: false,
  //       id: '65eff72b99167927fc7895b1',
  //       createTime: '2024-03-12T06:33:15.949Z',
  //       updateTime: '2024-03-12T06:33:15.949Z',
  //     },
  //   ],
  //   zoomMeetigsIds: [],
  //   deletingAllowed: true,
  //   oneToOneSessionDataUi: null,
  //   contributionPartners: [],
  //   coachAvatarUrl:
  //     'https://coherepublic-test.s3.amazonaws.com/65c1c1b4fac0b365b9a3fb2c/c68ee088-3917-430b-b997-812f8eda5c5f.jpeg',
  //   timeZoneShortName: 'PKT',
  //   isRecordingPublic: false,
  //   thankyouPage: {
  //     profileImage: null,
  //     bannerImage: null,
  //     tagline: null,
  //     contributionRedirectText: null,
  //     description: null,
  //     isVideoBanner: false,
  //     isSessionTab: false,
  //   },
  //   isThankyouPageAdded: false,
  //   isThankyouPageViewd: false,
  //   isCouponAvailable: false,
  //   userProgressbarData: {
  //     IsPlanPurchased: true,
  //     IsProfileUploaded: true,
  //     IntegrationsDone: true,
  //     FirstContributionDone: true,
  //     InviteLink: true,
  //     IsWebsiteAdded: true,
  //     IsBankAccountConnected: true,
  //   },
  //   progressBarPercentage: 100,
  //   parentContributionId: null,
  //   isDuplicate: false,
  //   isDuplicated: false,
  //   clientPurchasedPriceId: null,
  //   isExternalCalendarDisconnected: false,
  //   isCustomEnrollmentFormAdded: false,
  //   enrollmentFormViewModel: null,
  //   isPhoneNumberAdded: false,
  //   isCoachNumberAdded: false,
  //   coachPhoneNumber: null,
  //   isPriceHidden: false,
  //   accessRevokedUsers: {},
  //   isCollectiveContribution: false,
  //   isFavouriteToRequestedUser: false,
  //   customAvailableDates: [],
  //   sessionsCount: 1,
  //   smsPermissionsPerContribution: {
  //     SessionReminder: false,
  //   },
  //   subCategorySmsPermissionsPerContribution: {
  //     OneHourSession: false,
  //     TwentyFourHourSession: false,
  //   },
  //   customText: {},
  //   isPostApprovalRequired: false,
  //   closetSessionTime: null,
  //   isZoomDefaultServiceProvider: false,
  //   shareInCollective: false,
  //   multipleSessionIcrement: null,
  //   closetSessionEndTime: null,
  //   isCalendarHidden: false,
  //   isUserEnrolledInContributionForCollective: false,
  //   id: '65eff6ae99167927fc78959c',
  //   createTime: '2024-03-12T06:31:10.06Z',
  //   updateTime: '2024-03-12T07:56:48.483Z',
  // },
  // adminContributions: null,
  // inviteCode: null,
  // priceCode: null,
  // singleCode: null,
  // showPopUp: false,
  // showPopUpBank: false,
  // couponCode: null,
  // showThankYouPage: false,
  // reschedulePopUp: false,
  // pickAnotherTime: null,
  showError: false,
  // refetchContributons: false,
  // loader: false,
  // sessionId: null,
  updating: false,
  leadUser: null,
  welcomeEmailEnabled: false,
};

const reducerLeadMagnet = handleActions(
  new Map([
    [
      combineActions(
        actions.fetchLeadMagnetsActions.request,
        actions.fetchArchivedLeadMagnetsActions.request,
        // actions.fetchContributionAdminActions.request
      ),
      state => ({
        ...state,
        error: null,
        loading: true,
        // activeLeadMagnet: null,
        leadMagnetsCollection: [],
      }),
    ],
    [
      combineActions(actions.createLeadMagnetActions.request, actions.fetchLeadMagnetActions.request),
      state => ({
        ...state,
        error: null,
        loading: true,
        activeLeadMagnet: null,
      }),
    ],
    [
      combineActions(actions.fetchLeadMagnetsActions.success, actions.fetchArchivedLeadMagnetsActions.success),
      (state, action) => ({
        ...state,
        loading: false,
        error: null,
        leadMagnetsCollection: action.payload,
      }),
    ],
    [
      combineActions(actions.fetchLeadMagnetsActions.update, actions.fetchArchivedLeadMagnetsActions.update),
      (state, action) => ({
        ...state,
        loading: false,
        error: null,
        leadMagnetsCollection: [...(state.leadMagnetsCollection || []), ...(action.payload || [])],
      }),
    ],
    [
      combineActions(
        actions.fetchLeadMagnetActions.success,
        // actions.getContributionFromLS,
        actions.saveLeadMagnetToLS,
        actions.saveLeadMagnet,
      ),
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: null,
          activeLeadMagnet: action.payload,
        };
      },
    ],
    // [
    //   actions.fetchContributionAdminActions.success,
    //   (state, action) => ({
    //     ...state,
    //     loading: false,
    //     error: null,
    //     adminContributions: action.payload,
    //   }),
    // ],
    [
      combineActions(
        actions.fetchLeadMagnetsActions.error,
        actions.fetchLeadMagnetActions.error,
        actions.fetchArchivedLeadMagnetsActions.error,
        // actions.fetchContributionAdminActions.error,
      ),
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
        leadMagnetsCollection: [],
        activeLeadMagnet: null,
        adminContributions: [],
      }),
    ],
    [
      actions.fetchLeadMagnetActions.cancel,
      state => ({
        ...state,
        loading: false,
      }),
    ],
    [
      actions.resetLeadMagnet,
      state => ({
        ...state,
        activeLeadMagnet: null,
      }),
    ],
    [
      actions.createLeadMagnetActions.error,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
      }),
    ],
    [
      actions.createLeadMagnetActions.success,
      (state, action) => ({
        ...state,
        loading: false,
        error: null,
        activeLeadMagnet: null,
      }),
    ],
    // [
    //   actions.rescheduleSessionActions.request,
    //   state => ({
    //     ...state,
    //   }),
    // ],
    // [
    //   actions.rescheduleSessionActions.error,
    //   (state, action) => ({
    //     ...state,
    //     loading: false,
    //     error: action.payload,
    //   }),
    // ],
    // [
    //   actions.rescheduleSessionActions.success,
    //   (state, action) => ({
    //     ...state,
    //     loading: false,
    //     error: null,
    //     activeContribution: action.payload,
    //   }),
    // ],
    // [
    //   actions.updateSessionAttachments,
    //   (state, { payload }) => {
    //     const { sessionId, documentId } = payload;
    //     const {
    //       activeContribution: { sessions, timeZoneId },
    //     } = state;
    //     const currentSessionIdx = R.findIndex(R.propEq('id', sessionId))(sessions);
    //     const currentSession = R.find(R.propEq('id', sessionId))(sessions);

    //     return {
    //       ...state,
    //       activeContribution: {
    //         ...state.activeContribution,
    //         timeZoneId,
    //         sessions: R.update(
    //           currentSessionIdx,
    //           {
    //             ...currentSession,
    //             attachments: R.remove(
    //               R.findIndex(R.propEq('id', documentId))(currentSession.attachments),
    //               1,
    //               currentSession.attachments,
    //             ),
    //           },
    //           sessions,
    //         ),
    //       },
    //     };
    //   },
    // ],
    // [
    //   actions.updateSessionTimeAttachments,
    //   (state, { payload }) => {
    //     const { sessionId, sessionTimeId, documentId } = payload;
    //     const {
    //       activeContribution: { sessions, timeZoneId },
    //     } = state;
    //     const currentSessionIdx = R.findIndex(R.propEq('id', sessionId))(sessions);
    //     const currentSession = R.find(R.propEq('id', sessionId))(sessions);
    //     const currentSessionTimeIdx = R.findIndex(R.propEq('id', sessionTimeId))(currentSession.sessionTimes);
    //     const currentSessionTime = R.find(R.propEq('id', sessionTimeId))(currentSession.sessionTimes);
    //     return {
    //       ...state,
    //       activeContribution: {
    //         ...state.activeContribution,
    //         timeZoneId,
    //         sessions: R.update(
    //           currentSessionIdx,
    //           {
    //             ...currentSession,
    //             sessionTimes: R.update(
    //               currentSessionTimeIdx,
    //               {
    //                 ...currentSessionTime,
    //                 attachments: R.remove(
    //                   R.findIndex(R.propEq('id', documentId))(currentSessionTime.attachments),
    //                   1,
    //                   currentSessionTime.attachments,
    //                 ),
    //               },
    //               currentSession.sessionTimes,
    //             ),
    //           },
    //           sessions,
    //         ),
    //       },
    //     };
    //   },
    // ],
    // [
    //   actions.updateSession,
    //   (state, { payload: { sessionId, sessionTimeId, userId } }) => {
    //     const {
    //       activeContribution: { sessions },
    //     } = state;
    //     const currentSessionIdx = R.findIndex(R.propEq('id', sessionId))(sessions);
    //     const currentSession = R.find(R.propEq('id', sessionId))(sessions);
    //     const currentSessionTimeIdx = R.findIndex(R.propEq('id', sessionTimeId))(currentSession.sessionTimes);
    //     const currentSessionTime = R.find(R.propEq('id', sessionTimeId))(currentSession.sessionTimes);
    //     let sessz = [...sessions];
    //     sessz[currentSessionIdx] = {
    //       ...currentSession,
    //       sessionTimes: [
    //         {
    //           ...currentSessionTime,
    //           usersWhoViewedRecording: currentSessionTime.usersWhoViewedRecording.includes(userId)
    //             ? currentSessionTime.usersWhoViewedRecording
    //             : [...currentSessionTime.usersWhoViewedRecording, userId],
    //         },
    //       ],
    //     };
    //     return {
    //       ...state,
    //       activeContribution: {
    //         ...state.activeContribution,
    //         sessions: sessz,
    //       },
    //     };
    //   },
    // ],
    // [
    //   actions.updateSessionCompletedList,
    //   (state, { payload: { sessionId, sessionTimeId, userId } }) => {
    //     const {
    //       activeContribution: { sessions },
    //     } = state;
    //     const currentSessionIdx = R.findIndex(R.propEq('id', sessionId))(sessions);
    //     const currentSession = R.find(R.propEq('id', sessionId))(sessions);
    //     const currentSessionTimeIdx = R.findIndex(R.propEq('id', sessionTimeId))(currentSession.sessionTimes);
    //     const currentSessionTime = R.find(R.propEq('id', sessionTimeId))(currentSession.sessionTimes);

    //     let sessz = [...sessions];
    //     let times = [...currentSession.sessionTimes];
    //     times[currentSessionTimeIdx] = {
    //       ...currentSessionTime,
    //       completedSelfPacedParticipantIds: currentSessionTime.completedSelfPacedParticipantIds.includes(userId)
    //         ? currentSessionTime.completedSelfPacedParticipantIds
    //         : [...currentSessionTime.completedSelfPacedParticipantIds, userId],
    //     };
    //     sessz[currentSessionIdx] = {
    //       ...currentSession,
    //       sessionTimes: [...times],
    //     };

    //     return {
    //       ...state,
    //       activeContribution: {
    //         ...state.activeContribution,
    //         sessions: sessz,
    //       },
    //     };
    //   },
    // ],
    // [
    //   actions.addSessionAttachments,
    //   (state, { payload: { sessions } }) => {
    //     return {
    //       ...state,
    //       activeContribution: {
    //         ...state.activeContribution,
    //         sessions,
    //       },
    //     };
    //   },
    // ],
    // [
    //   actions.updateOneToOneSessionAttachments,
    //   (state, { payload: { documentId } }) => {
    //     const {
    //       activeContribution: { availabilityTimes },
    //     } = state;

    //     const times = availabilityTimes.map(time => ({
    //       ...time,
    //       bookedTimes: time.bookedTimes.map(booked => ({
    //         ...booked,
    //         attachments: booked.attachments.filter(attach => attach.id !== documentId),
    //       })),
    //     }));

    //     return {
    //       ...state,
    //       activeContribution: {
    //         ...state.activeContribution,
    //         availabilityTimes: times,
    //       },
    //     };
    //   },
    // ],
    // [
    //   actions.updateSubscriptionInfo,
    //   (state, { payload }) => {
    //     return {
    //       ...state,
    //       activeContribution: {
    //         ...state.activeContribution,
    //         subscriptionStatus: payload,
    //       },
    //     };
    //   },
    // ],
    [
      combineActions(actions.showDuplicateLoading, actions.updateLeadMagnetActions.request),
      state => ({
        ...state,
        updating: true,
      }),
    ],
    [
      actions.hideDuplicateLoading,
      (state, action) => ({
        ...state,
        updating: false,
        error: null,
      }),
    ],
    [
      actions.updateLeadMagnetActions.success,
      (state, action) => ({
        ...state,
        updating: false,
        showError: false,
        activeLeadMagnet: {
          ...action.payload,
          // serviceProviderName: state.activeContribution.serviceProviderName,
        },
      }),
    ],
    [
      actions.updateLeadMagnetActions.error,
      (state, action) => ({
        ...state,
        updating: false,
        showError: true,
        error: {
          ...action.payload,
        },
      }),
    ],
    // [
    //   actions.fetchContributionTimesActions.request,
    //   state => ({
    //     ...state,
    //     loadingTimes: false,
    //   }),
    // ],
    // [
    //   actions.fetchContributionTimesActions.success,
    //   (state, action) => ({
    //     ...state,
    //     loadingTimes: false,
    //     contributionTimes: action.payload,
    //   }),
    // ],
    // [
    //   actions.fetchContributionTimesActions.error,
    //   (state, { payload: { message } }) => ({
    //     ...state,
    //     loadingTimes: false,
    //     error: {
    //       message,
    //     },
    //   }),
    // ],
    // [
    //   actions.fetchEditContributionTimesActions.request,
    //   state => ({
    //     ...state,
    //     loadingTimes: true,
    //   }),
    // ],
    // [
    //   actions.fetchEditContributionTimesActions.success,
    //   (state, action) => ({
    //     ...state,
    //     loadingTimes: false,
    //     editContributionTimes: action.payload,
    //   }),
    // ],
    // [
    //   actions.fetchEditContributionTimesActions.error,
    //   (state, { payload: { message } }) => ({
    //     ...state,
    //     loadingTimes: false,
    //     error: {
    //       message,
    //     },
    //   }),
    // ],
    // [
    //   actions.calculateCreateSlotsActions.request,
    //   state => ({
    //     ...state,
    //     loadingTimes: true,
    //   }),
    // ],
    // [
    //   actions.calculateCreateSlotsActions.success,
    //   (state, action) => ({
    //     ...state,
    //     loadingTimes: false,
    //     calculatedSlots: action.payload,
    //   }),
    // ],
    // [
    //   SET_INVITE_CODE,
    //   (state, action) => ({
    //     ...state,
    //     inviteCode: action.payload,
    //   }),
    // ],
    // [
    //   SET_POP_UP,
    //   (state, action) => ({
    //     ...state,
    //     showPopUp: action.payload,
    //   }),
    // ],
    // [
    //   SET_POP_UP_BANK,
    //   (state, action) => ({
    //     ...state,
    //     showPopUpBank: action.payload,
    //   }),
    // ],
    // [
    //   SET_PRICE_CODE,
    //   (state, action) => ({
    //     ...state,
    //     priceCode: action.payload,
    //   }),
    // ],
    [
      actions.SET_ERROR_MESSAGE,
      (state, action) => ({
        ...state,
        error: action.payload,
      }),
    ],
    [
      SHOW_ERROR_POPUP,
      (state, action) => ({
        ...state,
        showError: action.payload,
      }),
    ],
    // [
    //   SET_SHOW_THANK_YOU_PAGE,
    //   (state, action) => ({
    //     ...state,
    //     showThankYouPage: action.payload,
    //   }),
    // ],
    // [
    //   SET_SINGLE_CODE,
    //   (state, action) => ({
    //     ...state,
    //     singleCode: action.payload,
    //   }),
    // ],
    // [
    //   SET_COUPON_CODE,
    //   (state, action) => ({
    //     ...state,
    //     couponCode: action.payload,
    //   }),
    // ],
    // [
    //   SET_CONTRIBUTIONS_LOADER,
    //   (state, action) => ({
    //     ...state,
    //     loader: action.payload,
    //   }),
    // ],
    // [
    //   SET_SESSION_CODE,
    //   (state, action) => ({
    //     ...state,
    //     sessionId: action.payload,
    //   }),
    // ],
    // [
    //   SET_REFETCH_CONTRIBUTION,
    //   (state, action) => ({
    //     ...state,
    //     refetchContributons: action.payload,
    //   }),
    // ],
    // [
    //   SET_PICK_ANOTHER_TIME,
    //   (state, action) => ({
    //     ...state,
    //     pickAnotherTime: action.payload,
    //   }),
    // ],
    // [
    //   SET_RESCHEDULE_MODAL,
    //   (state, action) => ({
    //     ...state,
    //     reschedulePopUp: action.payload,
    //   }),
    // ],
    // [
    //   'UPDATE_FOR_FIRST_REDIRECT',
    //   (state, action) => ({
    //     ...state,
    //     firstLoad: false,
    //   }),
    // ],
    // [
    //   actions.calculateCreateSlotsActions.error,
    //   (state, { payload: { message } }) => ({
    //     ...state,
    //     loadingTimes: false,
    //     error: {
    //       message,
    //     },
    //   }),
    // ],
    [
      actions.setLeadMagnetUserAction.request,
      (state, action) => ({
        ...state,
        error: null,
        loading: true,
        leadUser: null,
      }),
    ],
    [
      actions.setLeadMagnetUserAction.success,
      (state, action) => ({
        ...state,
        error: null,
        loading: false,
        leadUser: action.payload || null,
      }),
    ],
    [
      actions.setLeadMagnetUserAction.error,
      (state, { payload: { message } }) => ({
        ...state,
        loading: false,
        leadUser: null,
        error: { message },
      }),
    ],
    [
      actions.resetLeadMagnetUserAction,
      (state, action) => ({
        ...state,
        error: null,
        loading: false,
        leadUser: null,
      }),
    ],
    [
      actions.SET_WELCOME_EMAIL_ENABLED,
      (state, action) => ({
        ...state,
        welcomeEmailEnabled: action.payload,
      }),
    ],
  ]),
  initialState,
);

export default reducerLeadMagnet;
