import React, { useState, useEffect } from 'react';
import { Box, FormControlLabel, Radio, TextField, Tooltip, Typography } from '@material-ui/core';
import navigationIcon from '../../../assets/navigation-icon.png';
import Item from 'components/UI/SidebarDropDown/List/Item';
import Button from 'components/FormUI/Button';
import styled from 'styled-components';
import InfoIcon from '@material-ui/icons/Info';
import { colors } from 'utils/styles';
import { TOOLTIP } from '../../../constants';
import { Autocomplete } from '@mui/material';
import { mdiChevronDown } from '@mdi/js';
import { Icon } from '@mdi/react';
import classes from './ManagAvailability.module.scss';
import MasterCalendarPage from 'pages/MasterCalendarPage/MasterCalendarPage';
import { useDispatch, useSelector } from 'react-redux';
import {
  oneToOneAvailabilityAction,
  setMasterAvailabilitySelectedContribution,
  setPreSelectedMasterCalendarDate,
  toggleMasterAvailabilitySelection,
} from 'actions/masterCalendarSidebar.actions';
import { getOneOnOneContributons } from 'services/contributions.service';
import {
  setFetchSlotsCalendar,
  setMasterCalendarArray,
  setMasterCalendarAvailabilityPopUp,
} from 'actions/contributions';
import { redirectTo } from 'services/links';
import { useHistory } from 'react-router-dom';
import { setDefaultWeeklyData } from 'actions/oneToOneData';

const StyledOutlinedPrimaryButton = styled(Button)`
  background-color: white;
  cursor: pointer;
  color: #215c73;
  padding: 12px 16px;
  margin-top: 12px;
  font-size: 14px;
  min-width: 182px;
  border: 1px solid #215c73;
  &:hover {
    background-color: white;
    border: 1px solid #215c73;
  }
`;

const StyledTextField = styled(TextField)`
  .MuiInput-input {
    padding: 4px 20px 4px 0px !important;
  }
`;

const StyledAutocomplete = styled(Autocomplete)`
  .MuiAutocomplete-inputRoot {
    padding: 8px !important;
  }
  .MuiInput-formControl {
    margin-top: 0px !important;
  }
  .MuiAutocomplete-endAdornment {
    top: auto;
  }
`;
const StyledIcon = styled(Icon)`
  color: #8a8a8a;
`;

const ManageAvailability = () => {
  const dispatch = useDispatch();
  const { manageAvailabilitySelection } = useSelector(state => state.masterCalendar);
  const masterCalendarArray = useSelector(state => state?.contributions?.masterCalendarArray);
  const [servicesList, setServicesList] = useState([]);
  const [selectedContribtuion, setSelectedContribtuion] = useState(null);
  const history = useHistory();
  useEffect(() => {
    if (manageAvailabilitySelection === 'booking-availability') {
      fetchOneOnOneContributions();
      dispatch(setMasterCalendarAvailabilityPopUp(false));
    }
  }, [manageAvailabilitySelection]);

  const fetchOneOnOneContributions = () => {
    dispatch(setFetchSlotsCalendar(true));
    getOneOnOneContributons()
      .then(res => {
        const list = res.map(item => ({ title: item?.name, value: item?.id }));
        setServicesList(list);
        const concatedList = [{ title: '+ Create a New 1:1 Service', value: 'Create a new 1:1 service' }].concat(list);
        setServicesList(concatedList);
        setSelectedContribtuion(list[0]);
        dispatch(setMasterAvailabilitySelectedContribution(list[0].value));
        dispatch(setMasterCalendarArray([{ id: list[0].value, label: list[0].title }]));
        dispatch(setFetchSlotsCalendar(false));
      })
      .catch(() => {
        setServicesList([]);
        setServicesList([{ title: '+ Create a New 1:1 Service', value: 'Create a new 1:1 service' }]);
        setSelectedContribtuion(null);
        dispatch(setMasterAvailabilitySelectedContribution(null));
        dispatch(setMasterCalendarArray([]));
        dispatch(setFetchSlotsCalendar(false));
      });
  };

  useEffect(() => {
    if (masterCalendarArray?.length > 0) {
      dispatch(setMasterAvailabilitySelectedContribution(masterCalendarArray[0]?.id));
      setSelectedContribtuion({ title: masterCalendarArray[0]?.label, value: masterCalendarArray[0]?.id });
    }
  }, [masterCalendarArray]);

  return (
    <>
      <Box className={classes.container}>
        <Item
          title="Manage Availability"
          id="coach-manage-availability-list"
          isList
          titleFontWeight={700}
          listPadding="0px"
          defaultExpanded={true}
        >
          <Box maxWidth="220px" padding="12px 0px 0px">
            <div className={classes.confirmed_session_container}>
              <FormControlLabel
                control={
                  <Radio
                    className={classes.radio}
                    checked={manageAvailabilitySelection === 'confirmed-session' ? true : false}
                    onChange={e => {
                      dispatch(toggleMasterAvailabilitySelection('confirmed-session'));
                      dispatch(setMasterAvailabilitySelectedContribution(null));
                      dispatch(setMasterCalendarArray([]));
                    }}
                    color="primary"
                  />
                }
                label={
                  <Box display="flex" alignItems="center" sx={{ gap: '8px' }}>
                    <Typography className={classes.label}>Show Confirmed Sessions</Typography>
                    <Tooltip
                      style={{ height: '20px' }}
                      title="This shows all your confirmed one-on-one, group, community, memberships and workshop sessions, and sessions from externally connected calendars.
"
                      arrow
                      enterTouchDelay={TOOLTIP.ENTER_DELAY}
                      leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                    >
                      <InfoIcon htmlColor={colors.lightBrown} style={{ cursor: 'pointer' }} />
                    </Tooltip>{' '}
                  </Box>
                }
              />
            </div>
            <div className={classes.book_availability_container}>
              <FormControlLabel
                control={
                  <Radio
                    className={classes.radio}
                    checked={manageAvailabilitySelection === 'booking-availability' ? true : false}
                    onChange={e => {
                      dispatch(toggleMasterAvailabilitySelection('booking-availability'));
                    }}
                    color="primary"
                  />
                }
                label={
                  <Box display="flex" alignItems="center" sx={{ gap: '8px' }}>
                    <Typography className={classes.label}>Show 1:1 Booking Availability</Typography>
                    <Tooltip
                      style={{ height: '20px' }}
                      title="Once you select this option, the availability of the chosen service will be displayed on the calendar."
                      arrow
                      enterTouchDelay={TOOLTIP.ENTER_DELAY}
                      leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                    >
                      <InfoIcon htmlColor={colors.lightBrown} style={{ cursor: 'pointer' }} />
                    </Tooltip>{' '}
                  </Box>
                }
              />
            </div>
          </Box>
          {manageAvailabilitySelection === 'booking-availability' && (
            <Box style={{ marginBottom: '10px' }}>
              <Typography fontSize="14px" style={{ padding: '12px 0px 4px' }} className={classes.select_service}>
                Select Service
              </Typography>
              <StyledAutocomplete
                options={servicesList}
                getOptionLabel={option => option.title}
                clearIcon={false}
                popupIcon={<StyledIcon size={1} path={mdiChevronDown} />}
                value={selectedContribtuion}
                onChange={(event, value) => {
                  if (value.value.includes('Create a new 1:1 service')) {
                    dispatch(setDefaultWeeklyData());
                    history.push('/create-contribution/basic/1:1');
                    return;
                  } else {
                    setSelectedContribtuion(value);
                    dispatch(setMasterAvailabilitySelectedContribution(value.value));
                    dispatch(setMasterCalendarArray([{ label: value.title, id: value.value }]));
                  }
                }}
                style={{
                  fontFamily: 'Avenir',
                  whiteSpace: 'break-spaces',
                  border: '1px solid #DFE3E4',
                  borderRadius: '4px',
                }}
                renderInput={params => (
                  <StyledTextField
                    variant="standard"
                    className="contributionLabelText"
                    {...params}
                    placeholder="Select Service"
                  />
                )}
                renderOption={(props, option) => (
                  <Box
                    {...props}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '8px 16px',
                      '&:hover': {
                        backgroundColor: '#f5f5f5',
                      },
                    }}
                  >
                    <div
                      style={
                        option.title.includes('Create a New 1:1 Service')
                          ? {
                              fontFamily: 'Avenir',
                              fontSize: '14px',
                              fontWeight: 800,
                              color: '#4A4A4A',
                            }
                          : {
                              fontFamily: 'Avenir',
                              fontSize: '14px',
                              fontWeight: 350,
                              color: '#000000DE',
                            }
                      }
                    >
                      {option.title}
                    </div>
                  </Box>
                )}
              />
            </Box>
          )}

          <StyledOutlinedPrimaryButton
            onClick={() => {
              dispatch(oneToOneAvailabilityAction(true));
              dispatch(setPreSelectedMasterCalendarDate(null));
            }}
            variant="outlined"
          >
            Update 1:1 Availability
          </StyledOutlinedPrimaryButton>
        </Item>
      </Box>
    </>
  );
};

export default ManageAvailability;
