import React from 'react';

const DeleteIcon = () => {
  return (
    <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.9994 1.59998H10.4998L9.49986 0.599976H4.50032L3.50041 1.59998H0.000732422V3.59998H13.9994M1.00064 16.6C1.00064 17.1304 1.21133 17.6391 1.58637 18.0142C1.96141 18.3893 2.47007 18.6 3.00046 18.6H10.9997C11.5301 18.6 12.0388 18.3893 12.4138 18.0142C12.7888 17.6391 12.9995 17.1304 12.9995 16.6V4.59998H1.00064V16.6Z"
        fill="#215C73"
      />
    </svg>
  );
};

export default DeleteIcon;
