import useMakeStickyOnScroll from 'hooks/useMakeStickyOnScroll';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const StyledNewPosts = styled.div`
  position: ${({ mobileView }) => mobileView && 'relative'};
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledNewPostContainer = styled.div`
  position: ${({ mobileView }) => !mobileView && 'fixed'};
  background-color: ${({ colorToUse }) => colorToUse?.PrimaryColorCode};
  color: #fff;
  border-radius: 35px;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
  z-index: 100;
  height: 40px;
  padding: 10px;
  gap: 10px;
`;

function NewPosts({ unreadPostCount, mobileView, colorToUse, parentContainerId, setShowAllPostsTag }) {
  const { setTargetElement, setParentContainerId } = useMakeStickyOnScroll();
  const newPostsElement = useRef(null);

  useEffect(() => {
    if (newPostsElement.current) {
      setTargetElement(newPostsElement.current);
      setParentContainerId(parentContainerId);
    }
  }, [newPostsElement.current]);

  return (
    <StyledNewPosts id="parent-div-new-posts" ref={newPostsElement} mobileView={mobileView}>
      <StyledNewPostContainer colorToUse={colorToUse} mobileView={mobileView}>
        <ArrowDownwardIcon />
        {unreadPostCount === 1 ? (
          <span style={{ fontSize: '14px', fontWeight: '900' }}>New Post</span>
        ) : (
          <span style={{ fontSize: '14px', fontWeight: '900' }}>New Posts</span>
        )}
        <CloseIcon
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setShowAllPostsTag(false);
          }}
        />
      </StyledNewPostContainer>
    </StyledNewPosts>
  );
}

export default NewPosts;
