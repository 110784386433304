import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import styled from 'styled-components';
import Button from 'components/FormUI/Button';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ContributionReviewModal from 'pages/ContributionView/ContributionReviews/components/ContributionReviewModal';
import Loader from 'components/UI/Loader';
import { useHttp } from 'hooks/index';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  addTestimonial,
  addTestimonialAvatar,
  deleteTestimonial,
  editTestimonial,
  toggleShowcase,
} from 'services/testimonials.service';
import { fetchTestimonialTemplates, fetchTestimonials } from 'actions/testimonials';
import { getAllTestimonials } from 'selectors/testimonials';

import { determineColorToUse, getThemedColors } from 'services/contributions.service';
import { lightOrDark } from 'utils/utils';
import TestimonialModal from './components/TestimonialModal';
import TestimonialTemplateModal from './components/TestimonialTemplateModal';
import sassClasses from './ContributionReviews.module.scss';
const TestimonialIcon = ({ color = '#D1B989' }) => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.88851 0C3.53206 0 0 3.53206 0 7.88851C0 10.1422 0.949809 12.1692 2.46467 13.6057L2.20076 18L5.94774 15.5262C6.56962 15.6836 7.21764 15.7764 7.88851 15.7764C12.2456 15.7764 15.777 12.245 15.777 7.88786C15.7777 3.53206 12.2456 0 7.88851 0ZM7.12618 7.40904C7.12618 8.21905 6.95765 8.85857 6.62058 9.32695C6.28351 9.79532 5.70604 10.2101 4.88688 10.5681L4.26957 9.39946C4.77322 9.16364 5.12139 8.92782 5.31345 8.69396C5.50615 8.45879 5.61328 8.18182 5.63549 7.86238H4.26957V5.20893H7.12618V7.40904ZM11.5075 7.40904C11.5075 8.21905 11.3389 8.85857 11.0019 9.32695C10.6648 9.79662 10.0873 10.2101 9.26815 10.5681L8.6515 9.39946C9.15514 9.16364 9.50332 8.92782 9.69603 8.69396C9.88873 8.45879 9.99586 8.18182 10.0181 7.86238H8.65215V5.20893H11.5088L11.5075 7.40904Z"
        fill={color}
      />
    </svg>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: 335,
    overflow: 'visible',
    backgroundColor: '#fafafa',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
    borderRadius: 16,
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: 50,
    paddingLeft: 70,
  },
  containerMobile: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: 50,
    paddingLeft: 70,
  },
  avatar: {
    backgroundColor: '#c7c7c7',
    width: '87px',
    height: '87px',
    left: '-33px',
    top: '15px',
    border: '2px solid white',
    position: 'absolute',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
  },
  header: {
    position: 'relative',
    paddingLeft: 46,
    paddingBottom: 0,
    paddingTop: 10,
    '& .MuiCardHeader-content': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  title: {
    color: '#000',
    fontSize: 16,
    fontWeight: 500,
  },
  subTitle: {
    fontSize: 14,
  },
  content: {
    paddingLeft: 63,
    paddingTop: 0,
    overflow: 'hidden',
  },
  createReviewContainer: {
    marginBottom: 25,
  },
  menuPaper: {
    backgroundColor: ({ cardBackgroundColor }) => cardBackgroundColor,
    color: ({ color }) => color,
  },
}));

const StyledTypography = styled(Typography)`
  margin-left: 45px;
  margin-top: 25px;
  font-size: 1rem;
`;
function ContributionReviews({ getAllTestimonialTemplates }) {
  const StyledButton = styled(Button)`
    margin-right: 20px;
    margin-left: 5px;
    margin-top: 10px;
    padding: 22px 24px !important;
  `;
  const contribution = useContribution();
  const { themedColor, themedCardBackgroundColor } = getThemedColors(contribution);

  let colorToUse = determineColorToUse(contribution);
  let textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const styledProps = {
    cardBackgroundColor: themedCardBackgroundColor,
    color: themedColor,
  };
  const classes = useStyles(styledProps);
  const [modalOpened, setModalOpened] = useState(false);
  const [avatarError, setAvatarError] = useState('');
  const { request, loading } = useHttp();
  const { id: contributionId } = useContribution();
  const dispatch = useDispatch();
  const testimonials = useSelector(getAllTestimonials);
  const testimonalLoader = useSelector(state => state.testimonials.testimonialLoader);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState(null);
  const [openedModal, setOpenedModal] = useState(null);
  const [menus, setMenus] = useState({});
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const [disablePublishButton, setDisablePublishButton] = useState(false);
  const handleSubmit = ({ file, name, role, description, id, addedToShowcase, avatarUrl, isTemplate }) => {
    setDisablePublishButton(true);
    const formData = new FormData();
    formData.append('file', file);
    if (id || isTemplate) {
      if (file) {
        return addTestimonialAvatar(contributionId, formData, avatarUrl)
          .then(res => {
            setDisablePublishButton(false);
            if (isTemplate) {
              return addTestimonial({
                contributionId,
                name,
                role,
                description,
                avatarUrl: res,
              }).then(() => {
                dispatch(fetchTestimonials(contributionId));
                setOpenedModal(null);
                setSelected(null);
              });
            }

            return editTestimonial(id, {
              contributionId,
              name,
              role,
              description,
              avatarUrl: res,
              addedToShowcase,
            });
          })
          .then(() => {
            dispatch(fetchTestimonials(contributionId));
            getAllTestimonialTemplates();
            setOpenedModal(null);
            setSelected(null);
          })
          .catch(() => {
            setOpenedModal(null);
            setSelected(null);
          });
      }

      if (isTemplate) {
        return addTestimonial({
          contributionId,
          name,
          role,
          description,
          avatarUrl,
        }).then(() => {
          dispatch(fetchTestimonials(contributionId));
          getAllTestimonialTemplates();
          setOpenedModal(null);
          setSelected(null);
        });
      }

      return editTestimonial(id, {
        contributionId,
        name,
        role,
        description,
        avatarUrl,
        addedToShowcase,
      })
        .then(() => {
          dispatch(fetchTestimonials(contributionId));
          getAllTestimonialTemplates();
          setOpenedModal(null);
          setSelected(null);
        })
        .catch(() => {
          setOpenedModal(null);
          setSelected(null);
        });
    }

    if (file) {
      return addTestimonialAvatar(contributionId, formData)
        .then(res => {
          return addTestimonial({
            contributionId,
            name,
            role,
            description,
            avatarUrl: res,
          });
        })
        .then(() => {
          dispatch(fetchTestimonials(contributionId));
          getAllTestimonialTemplates();
          setSelected(null);
          setOpenedModal(null);
        })
        .catch(error => {
          if (error.response.status === 400) {
            setAvatarError('Please upload an image');
          }
        });
    } else {
      return addTestimonial({
        contributionId,
        name,
        role,
        description,
        avatarUrl,
      }).then(() => {
        dispatch(fetchTestimonials(contributionId));
        getAllTestimonialTemplates();
        setOpenedModal(null);
        setSelected(null);
      });
    }
  };

  const handleClose = () => {
    setModalOpened(false);
  };

  const handleModalOpen = () => {
    setDisablePublishButton(false);
    setModalOpened(true);
  };

  const handleDelete = id => {
    setMenus(prev => ({ ...prev, [id]: false }));
    setAnchorEl(null);

    deleteTestimonial(id).then(() => {
      dispatch(fetchTestimonials(contributionId));
    });
  };

  const handleEdit = id => {
    setDisablePublishButton(false);
    setSelected(testimonials.find(el => el.id === id));

    setOpenedModal('new');
    setAnchorEl(null);
    setMenus(prev => ({ ...prev, [id]: false }));
  };

  const handleAddToShowcase = id => {
    setAnchorEl(null);
    setMenus(prev => ({ ...prev, [id]: false }));

    toggleShowcase(id).then(() => {
      dispatch(fetchTestimonials(contributionId));
    });
  };

  useEffect(() => {
    dispatch(fetchTestimonials(contributionId));
  }, [dispatch, contributionId]);

  useEffect(() => {
    getAllTestimonialTemplates();
  }, []);

  const handleTestimonialModalClick = type => {
    setOpenedModal(type);
    setModalOpened(false);
  };

  return (
    <>
      {testimonalLoader && <Loader />}
      <Grid container justify="space-between" className={classes.createReviewContainer}>
        <Grid item>
          {testimonials?.length === 0 && (
            <StyledTypography style={{ color: themedColor }} variant="h6">
              There are no Testimonials added yet.
            </StyledTypography>
          )}
        </Grid>
        <Grid item>
          <StyledButton
            style={{ backgroundColor: colorToUse.PrimaryColorCode, color: textColor }}
            autoWidth
            onClick={handleModalOpen}
          >
            Create Testimonial
          </StyledButton>
        </Grid>
      </Grid>
      <div className={mobileView ? classes.containerMobile : classes.container}>
        {testimonials.map(({ avatarUrl, name, role, description, id, addedToShowcase }) => (
          <div key={id}>
            <Card style={{ backgroundColor: themedCardBackgroundColor }} className={classes.root}>
              <CardHeader
                className={classes.header}
                avatar={
                  <Avatar src={avatarUrl} className={classes.avatar}>
                    {name.charAt()}
                  </Avatar>
                }
                title={
                  <Typography style={{ color: themedColor }} variant="h6">
                    {name}
                  </Typography>
                }
                subheader={
                  <Typography style={{ color: themedColor }} variant="subtitle1" color="textSecondary">
                    {role}
                  </Typography>
                }
                action={
                  <>
                    <TestimonialIcon color={colorToUse?.PrimaryColorCode} />
                    <IconButton
                      onClick={e => {
                        setAnchorEl(() => e.currentTarget);
                        setMenus(prev => ({ ...prev, [id]: true }));
                      }}
                      aria-label="settings"
                    >
                      <MoreVertIcon style={{ color: themedColor }} />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={menus?.[id]}
                      classes={{ paper: classes.menuPaper }}
                      onClose={() => {
                        setMenus(prev => ({ ...prev, [id]: false }));
                        setAnchorEl(null);
                      }}
                    >
                      <MenuItem onClick={() => handleAddToShowcase(id)}>
                        {addedToShowcase ? 'Remove from Showcase' : 'Add to Showcase'}
                      </MenuItem>
                      <MenuItem onClick={() => handleEdit(id)}>Edit</MenuItem>
                      <MenuItem onClick={() => handleDelete(id)}>Delete</MenuItem>
                    </Menu>
                  </>
                }
              />
              <CardContent className={classes.content}>
                <Typography
                  className={sassClasses.text_container}
                  style={{ color: themedColor }}
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  {description}
                </Typography>
              </CardContent>
            </Card>
          </div>
        ))}
      </div>
      {modalOpened && (
        <TestimonialModal handleClick={handleTestimonialModalClick} handleClose={handleClose} isOpen={modalOpened} />
      )}
      {openedModal === 'new' && (
        <ContributionReviewModal
          item={selected}
          isOpen
          handleSubmit={handleSubmit}
          setAvatarError={setAvatarError}
          avatarError={avatarError}
          handleClose={() => {
            setOpenedModal(null);
            setSelected(null);
          }}
          disablePublishButton={disablePublishButton}
        />
      )}
      {openedModal === 'template' && (
        <TestimonialTemplateModal
          isOpen
          handleSubmit={testimonial => {
            setSelected({ ...testimonial, isTemplate: true });
            setOpenedModal('new');
          }}
          handleClose={() => {
            setOpenedModal(null);
            setModalOpened(true);
          }}
        />
      )}
      {loading && <Loader />}
    </>
  );
}

const actions = {
  getAllTestimonialTemplates: fetchTestimonialTemplates,
};

export default connect(null, actions)(ContributionReviews);
