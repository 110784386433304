import moment from 'moment';
import * as R from 'ramda';
import htmlParser from 'html-react-parser';
import isEmpty from 'lodash/isEmpty';
import axiosInstance from 'utils/axiosInstance';
import isDarkColor from 'is-dark-color';
import styled from 'styled-components';
import {
  CONTRIBUTION_COLORS,
  EMBEDDED_LINK_ALLOWED_AUDIO_PLATFORMS,
  EMBEDDED_LINK_ALLOWED_VIDEO_PLATFORMS,
  JOIN_CONTRIBUTION_STATUS,
  PLATFORMS_ALLOWED,
} from '../constants';
import { determineColorToUse } from 'services/contributions.service';

export const isNotNil = R.complement(R.isNil);
export const isNotEmpty = R.complement(R.isEmpty);
export const isEmptyOrNil = R.anyPass([R.isNil, R.isEmpty]);

/**
 * Converts passed value to `String` type (if needed)
 */
export const toString = R.cond([
  [R.isNil, R.always('')],
  [R.is(String), R.identity],
  [R.T, R.toString],
]);

export const extractGtmId = code => {
  const regex = /GTM-[A-Z0-9]+/;
  const match = code.match(regex);

  if (match) {
    return match[0]; // Return the GTM ID
  }

  return null;
};

export const extractMetaPixelId = code => {
  const regex = /fbq\('init',\s*'(\d+)'\)/;
  const match = code.match(regex);

  if (match) {
    return match[1];
  }

  return null;
};
export const downloadFile = (text, documentName, contentType) => {
  const blob = new Blob([text], {
    type: contentType,
  });

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = documentName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

export const downloadFileByLink = (url, documentName) => {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = documentName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

export const parseTimeFrom12hTo24h = time => moment(time, ['h:mm A']).format('HH:mm');

export const wrapLink = link =>
  link.indexOf('http://') === 0 || link.indexOf('https://') === 0 ? link : `https://${link}`;

export const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const replaceHtmlEscapedSpaceToNormal = text => text.replace(/&nbsp;/g, ' ');

// export const replaceBreakToBr = text => (text ? text.replace(/(<br \/>\n)+/g, '<br />\n') : '');
export const replaceBreakToBr = text => (text ? text.replace(/(\n)+/g, '\n') : '');

export const wrapText = text => htmlParser(text);
export const wrapTextWithColoredLinks = (text, color) =>
  htmlParser(text, {
    replace: node => {
      if (node.type === 'tag' && node.name === 'a') {
        node.attribs = {
          ...node.attribs,
          style: `color: ${color}`,
        };
        return node;
      }
    },
  });

export const lightOrDark = color => {
  try {
    if (color === '#C9B382') {
      return '#fff';
    } else if (color === '#c9b382') {
      return '#fff';
    } else if (color === '#DDD0B2') {
      return '#fff';
    } else if (color === '#CDBA8F') {
      return '#fff';
    } else if (isDarkColor(color)) {
      return '#fff';
    } else {
      return '#000';
    }
  } catch (e) {
    return '#000';
  }
};

export const joinStatus = async (contribution, user) => {
  let buttonText = '';
  if (!window.location.pathname.includes('apply-contribution-view')) {
    buttonText = JOIN_CONTRIBUTION_STATUS.join;
    return buttonText;
  }
  const isUserLoggedIn = !isEmpty(user);
  if (contribution.applicationFormViewModel && !isUserLoggedIn) {
    buttonText = JOIN_CONTRIBUTION_STATUS.apply;
  }

  if (!isEmpty(contribution.applicationFormViewModel) && isUserLoggedIn) {
    buttonText = await axiosInstance
      .get(`/ApplicationForm/IsApplicationFormSubmitted/${contribution.id}`)
      .then(response => {
        if (response.data) {
          if (!response.data?.status || response.data?.status === 'Draft') {
            return JOIN_CONTRIBUTION_STATUS.apply;
          }
          if (response.data?.status === 'Pending') {
            return JOIN_CONTRIBUTION_STATUS.pending;
          }
          if (response.data?.status === 'Approved') {
            return JOIN_CONTRIBUTION_STATUS.join;
          }
        }
        return '';
      })
      .catch(error => {});
  }
  return buttonText;
};

export const addhttp = url => {
  if (!/^https?:\/\//i.test(url)) {
    return 'http://'.concat(url);
  }
  return url;
};

export const getStyledButton = (contribution = null, contributionTextColorCode = null) => styled.button`
  color: ${contributionTextColorCode || CONTRIBUTION_COLORS.TertiaryColorCode};
  background-color: ${determineColorToUse(contribution)?.PrimaryColorCode || CONTRIBUTION_COLORS.PrimaryColorCode};
  &:hover {
    color: ${contributionTextColorCode || CONTRIBUTION_COLORS.TertiaryColorCode};
    background-color: ${determineColorToUse(contribution)?.PrimaryColorCode || CONTRIBUTION_COLORS.PrimaryColorCode};
  }
`;

export const getStyledSecondaryButton = () => styled.button`
  color: black;
  background-color: white;
  border: 1px solid black;
  font-weight: bold;
  margin-right: 10px;
  &:hover {
    color: balck;
    background-color: white;
  }
`;
export const getPressEffectButton = () => styled.button`
  background: none;
  border: none;
  &:active {
    opacity: 0.4;
    transform: scale(0.8);
  }
`;

export function formatDate(dateString) {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return 'Invalid Date';
  }
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const suffixes = ['th', 'st', 'nd', 'rd'];

  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  let suffix;
  if (day % 10 === 1 && day !== 11) {
    suffix = suffixes[1];
  } else if (day % 10 === 2 && day !== 12) {
    suffix = suffixes[2];
  } else if (day % 10 === 3 && day !== 13) {
    suffix = suffixes[3];
  } else {
    suffix = suffixes[0];
  }

  return `${month} ${day}${suffix} ${year}`;
}

export const formatTime = date => {
  const DATE_VIEW = 'MMMM Do YYYY';
  return moment(date).format(DATE_VIEW);
};

export const formatTimeWithComma = date => {
  const DATE_VIEW = 'MMM Do, YYYY';
  return moment(date).format(DATE_VIEW);
};

export const convertToPlainText = html => {
  // Create a new div element
  const tempDivElement = document.createElement('div');

  // Set the HTML content with the given value
  tempDivElement.innerHTML = html;
  // Retrieve the text property of the element
  return tempDivElement.textContent || tempDivElement.innerText || '';
};

export const CanClinetInviteFrieds = contrib => {
  const paymentOptions = contrib?.paymentInfo?.paymentOptions;
  if (!contrib.allowClientsToInvite) {
    return false;
  }
  if (
    contrib.inviteClientType === 'Free' &&
    (paymentOptions.includes('Free') || paymentOptions.includes('FreeSessionsPackage'))
  ) {
    return true;
  }
  if (contrib.inviteClientType === 'Paid') {
    if (
      paymentOptions.length === 1 &&
      (paymentOptions.includes('Free') || paymentOptions.includes('FreeSessionsPackage'))
    ) {
      return false;
    }
    if (
      paymentOptions.length === 2 &&
      paymentOptions.includes('Free') &&
      paymentOptions.includes('FreeSessionsPackage')
    ) {
      return false;
    }
  }
  return true;
};

export const isValidUrl = url => {
  const pattern = /^(http|https):\/\/([\w.-]+)(\/[\w.-]*)*\/?$/;
  return pattern.test(url);
};

export const pluckKeys = (array, keys) => {
  return array?.map(item => {
    if (Array.isArray(keys)) {
      const extractedValues = {};
      keys.forEach(key => {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          extractedValues[key] = item[key];
        }
      });
      return extractedValues;
    }

    if (Object.prototype.hasOwnProperty.call(item, keys)) {
      return item[keys];
    }
    return [];
  });
};

export const findValueByKey = (arr, key, value) => {
  return arr?.filter(item => item[key] === value)[0];
};

export const findMaxOfKey = (arr, key, initialValue) => {
  return arr?.reduce((max, obj) => (obj[key] > max ? obj[key] : max), initialValue) ?? initialValue;
};

export const isValidLink = urlString => {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d@%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // validate fragment locator

  return urlPattern.test(urlString);
};

export const hexToRgba = (hex, opacity = 1) => {
  // Remove the hash if it exists
  const newHex = hex.replace('#', '');

  // Parse the hex values to get the individual R, G, B values
  const r = parseInt(newHex.substring(0, 2), 16);
  const g = parseInt(newHex.substring(2, 4), 16);
  const b = parseInt(newHex.substring(4, 6), 16);

  // Return the RGB values as an object
  return `${r},${g},${b},${opacity}`;
};

export const rgbaToHex = (r, g, b, a) => {
  // Ensure that the values are in the valid range (0 to 255 for RGB, 0.0 to 1.0 for alpha)
  r = Math.min(255, Math.max(0, r));
  g = Math.min(255, Math.max(0, g));
  b = Math.min(255, Math.max(0, b));
  a = Math.min(1, Math.max(0, a));

  // Convert RGB to hexadecimal
  const hexR = r.toString(16).padStart(2, '0');
  const hexG = g.toString(16).padStart(2, '0');
  const hexB = b.toString(16).padStart(2, '0');

  // Convert alpha to hexadecimal
  const hexA = Math.round(a * 255)
    .toString(16)
    .padStart(2, '0');

  // Concatenate the values
  const hexColor = `#${hexR}${hexG}${hexB}${hexA}`;

  return hexColor.toUpperCase(); // Convert to uppercase for consistency
};

export const splitRgbaColorString = (rgbaColorString = '') => {
  const rgbaColor = rgbaColorString?.match(/\d+/g);
  return {
    r: rgbaColor[0],
    g: rgbaColor[1],
    b: rgbaColor[2],
    a: rgbaColor[3],
  };
};

export const splitRgbaToArray = rgbaColor => rgbaColor?.match(/\d+/g).map(Number); // Extract RGB and alpha values

export const getIpGlobal = async () => {
  const res = await fetch('https://api.ipify.org/?format=json');
  const data = await res.json();
  return data.ip;
};

export const inAllowedPlatforms = link => {
  if (!link) {
    return false;
  }
  let inAllowedPlatforms = false;
  for (let i = 0; i < PLATFORMS_ALLOWED.length; i++) {
    if (link.toLowerCase().includes(PLATFORMS_ALLOWED[i].toLowerCase())) {
      inAllowedPlatforms = true;
      break;
    }
  }
  return inAllowedPlatforms;
};
export const isEmbeddedAudioPlatform = link => {
  if (!link) {
    return false;
  }
  let inAllowedAudioPlatform = false;
  for (let i = 0; i < EMBEDDED_LINK_ALLOWED_AUDIO_PLATFORMS.length; i++) {
    if (link.toLowerCase().includes(EMBEDDED_LINK_ALLOWED_AUDIO_PLATFORMS[i].toLowerCase())) {
      inAllowedAudioPlatform = true;
      break;
    }
  }
  return inAllowedAudioPlatform;
};
export const isEmbeddedVideoPlatform = link => {
  if (!link) {
    return false;
  }
  let inAllowedVideoPlatform = false;
  for (let i = 0; i < EMBEDDED_LINK_ALLOWED_VIDEO_PLATFORMS.length; i++) {
    if (link.toLowerCase().includes(EMBEDDED_LINK_ALLOWED_VIDEO_PLATFORMS[i].toLowerCase())) {
      inAllowedVideoPlatform = true;
      break;
    }
  }
  return inAllowedVideoPlatform;
};
export const localeAwareNumberFormatting = (number, locale = 'en-US', options = {}) => {
  return new Intl.NumberFormat(locale, options).format(number);
};
export const formatNumberTo2Decimal = value => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0, // Allow zero decimal places
    maximumFractionDigits: 2, // Maximum of two decimal places
  });

  if (value === 0) {
    return '0.00';
  }
  return formatter.format(value);
};

export const getPaymentTypeForUi = (paymentInfo, query, queryCode = null) => {
  const paymentCode = queryCode ?? query.code;
  if (!paymentCode || paymentCode.includes('p_')) {
    return paymentInfo?.paymentOptions?.filter(paymentOption => paymentOption !== 'Free').length > 0 ? 'PAID' : 'FREE';
  }
  if (!paymentCode?.includes('p_')) {
    if (paymentInfo?.paymentOptions?.includes('Free')) {
      return 'FREE';
    }
    return paymentInfo?.paymentOptions?.filter(paymentOption => paymentOption !== 'Free').length > 0 ? 'PAID' : 'FREE';
  }
};

export const formatPostTime = dateString => {
  const now = moment();
  const postTime = moment(dateString);
  const duration = moment.duration(now.diff(postTime));
  const days = duration.asDays();
  const hours = duration.asHours();
  const minutes = duration.asMinutes();

  if (days >= 30) {
    return postTime.format('MMM DD');
  }
  if (days >= 1) {
    return `${Math.floor(days)}d`;
  }
  if (hours >= 1) {
    return `${Math.floor(hours)}h`;
  }
  if (Math.floor(minutes) === 0) {
    return `Just Now`;
  }
  return `${Math.floor(minutes)}m`;
};
