import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { startTestVideoChat } from 'actions/testVideoChart';
import Button from 'components/FormUI/Button';
import ChatAndGetStartedButtons from 'pages/Dashboard/components/CohealerDashboard/LiveChat/LiveChatContainer';
import { ZoomIntegration } from 'integrations/Zoom';
import * as contributionActions from 'actions/contributions';
import { getLatestApproved, getUpcomingCreated } from 'services/contributions.service';
import { UpdateUserProgressBarData } from 'services/user.service';
import { updateUser } from 'actions/update-user';
import { useHistory } from 'react-router-dom';
import useAccount from 'hooks/useAccount';
import { useMediaQuery, useTheme } from '@material-ui/core';
import CoachImage from 'assets/chatlogo.png';
import Joyride, {
  CallBackProps,
  STATUS,
  Step,
  EVENTS,
  StoreHelpers,
  BeaconRenderProps,
  TooltipRenderProps,
} from 'react-joyride';
import { UserRoles } from 'helpers/constants';

const StyledDiv = styled.div``;

const StyledImg = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 40px;
  align-items: center;
  z-index: 1;
`;
const ZoomWrapper = styled.div`
  border-bottom: 1px solid #dfe3e4;
`;
const VideoWrapper = styled.div`
  padding-top: 20px;
`;
const Heading = styled.div`
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  color: #000000de;
  padding-bottom: 10px;
`;
const InfoText = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 350;
  line-height: 24px;
  color: #000000de;
  padding-bottom: 8px;
`;

export const Video = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const showCompletedGetStart = useSelector(state => state.contributions.showCompletedGetStart);
  const refreshStep = showCompletedGetStart === 'bankAccountRefresh';
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(4);
  const [showModal, setShowModal] = useState(false);
  const [contibId, setContibId] = useState(null);
  const theme = useTheme();
  const { coachLoginInfo, currentRole, user } = useAccount();
  const isCohealer = currentRole === UserRoles.cohealer;
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  let joyRideHelpers;
  useEffect(() => {
    getLatestApproved().then(res => {
      setContibId(res);
    });
  }, []);
  const [steps, setSteps] = useState([
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      // floaterProps: { disableFlip: true },
      content: (
        <div className="step4">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Verify Your Information
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Verify your information to create a Service and collect revenue.
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '100%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF('isPlan');
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.next();
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integration100',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      // floaterProps: { disableFlip: true },
      content: (
        <div className="step4">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Verify Your Information
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Verify your information to create a Service and collect revenue.
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '100%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF('isPlan');
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.next();
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integration10999',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      floaterProps: { disableFlip: true },
      content: (
        <div className="step4">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Verify Your Information
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Verify your information to create a Service and collect revenue.
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '100%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF('isPlan');
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.next();
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integration1222',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      floaterProps: { disableFlip: true },
      content: (
        <div className="step4">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Verify Your Information
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Verify your information to create a Service and collect revenue.
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '100%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF('isPlan');
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.next();
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integration111',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      // floaterProps: { disableFlip: true },
      content: (
        <div className="step4">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Connect Your Zoom Account
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            If you have an upgraded Zoom account that you wish to use for your sessions, please connect it here.
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            {refreshStep === false && (
              <div>
                <Button
                  className="btn-left"
                  autoWidth
                  backgroundColor={'white'}
                  invert
                  style={{
                    marginTop: '40px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    width: '100%',
                    borderColor: '#A58B62',
                    borderWidth: '1px',
                    borderRadius: '5px',
                    color: '#A58B62',
                  }}
                  onClick={() => {
                    updateUserF();
                    // setRun(false);
                  }}
                >
                  Mark as Complete
                </Button>
                <Button
                  className="btn-left"
                  autoWidth
                  backgroundColor={'white'}
                  invert
                  style={{
                    marginTop: '10px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    width: '100%',
                    borderColor: '#215C73',
                    borderWidth: '1px',
                    borderRadius: '5px',
                    color: '#215C73',
                  }}
                  onClick={() => {
                    updateUserF('', true);
                    // setRun(false);
                  }}
                >
                  Complete & Next
                </Button>
              </div>
            )}
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integration3',
    },
  ]);

  useEffect(() => {
    if (mobileView && (user.userProgressbarData.IntegrationsDone === false || refreshStep)) {
      setRun(true);
    } else if (user.userProgressbarData.IntegrationsDone === false || refreshStep) {
      setRun(true);
    }
  }, [mobileView, joyRideHelpers]);

  const handleJoyrideCallback = data => {
    const { status, type, index } = data;
    const nextStepIndex = index + 1;

    const finishedStatuses = ['finished', 'skipped'];
    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
    if ([EVENTS.STEP_AFTER].includes(type)) {
      setStepIndex(nextStepIndex);
    }
    if (data.action === 'close') {
      setRun(false);
    }

    console.groupCollapsed(type);
    console.log(data);
    console.groupEnd();
  };

  const getHelpers = helpers => {
    joyRideHelpers = helpers;
  };
  const updateUserF = async (item, goToNextStep = false) => {
    let obj;
    obj = {
      id: user.id,
      userProgressbarData: {
        ...user.userProgressbarData,
        IntegrationsDone: true,
      },
      progressBarPercentage: user.progressBarPercentage,
    };

    await UpdateUserProgressBarData(obj).then(res => {
      let u = {
        ...user,
        userProgressbarData: { ...res.userProgressbarData },
        progressBarPercentage: res.progressBarPercentage,
      };
      dispatch(updateUser(u));
      if (res.progressBarPercentage == 100) {
        setShowModal(true);
      }
      if (goToNextStep) {
        if (res.userProgressbarData?.IsPlanPurchased === false) {
          history.push('/account/payment', { from: 'verify' });
        } else if (res?.userProgressbarData?.IsBankAccountConnected === false) {
          history.push('/account/payment', { from: 'bankAccount' });
        } else if (res?.userProgressbarData?.IntegrationsDone === false) {
          history.push('/account/integrations', { from: 'bankAccount' });
        } else if (res?.userProgressbarData?.IsProfileUploaded === false) {
          history.push('/account/profile');
        } else if (res?.userProgressbarData?.FirstContributionDone === false) {
          dispatch(contributionActions.setPopUp(true));
          dispatch(contributionActions.setShowCompletedGetStart('create'));
          history.push('/dashboard');
        } else if (res?.userProgressbarData?.IsWebsiteAdded === false) {
          history.push('/account/profile', { from: 'Manage Your Website Information' });
        } else if (res?.userProgressbarData?.InviteLink === false) {
          contibId === null ? console.log('Helloo') : history.push(`contribution-view/${contibId}/about`);
        }
      }
    });
    joyRideHelpers.skip();
  };

  const onClick = () => {
    dispatch(startTestVideoChat());
  };

  return (
    <StyledDiv>
      <Joyride
        // tooltipComponent={Tooltip}
        callback={handleJoyrideCallback}
        continuous={true}
        getHelpers={getHelpers}
        run={run}
        stepIndex={stepIndex}
        showProgress={false}
        showSkipButton={false}
        steps={steps}
        styles={{
          options: {
            primaryColor: '#CDBA8F',
            zIndex: 10000000,
          },
        }}
      />
      {isCohealer && (
        <ZoomWrapper>
          <ZoomIntegration />
        </ZoomWrapper>
      )}
      <VideoWrapper>
        <Heading>Cohere Video</Heading>
        <InfoText>
          The “Test Video” button will open a test session room that will allow you to confirm video works with your
          Computer or Tablet. You will also be able to familiarize yourself with how to change the default microphone
          and/or camera with your setup.
        </InfoText>
        <InfoText>
          <b>Note: if you plan to screen share, we highly recommend you use the browser Chrome.</b> If you use Mac OS,
          you will need to allow Chrome access to screen recording. You can do this by going to System Preferences &gt;
          Security & Privacy &gt; Privacy &gt; Screen Recording. Check the option for your browser (e.g. if you use
          Chrome, select Chrome).
        </InfoText>
        <InfoText>
          TIP: When screen sharing, we recommend selecting “Application Window” and not “Your Entire Screen”. This way
          your clients will only view a PDF, PowerPoint, or any other applications that you wish to share. If you decide
          to share “Your Entire Screen” please note clients will be able to view your browser and any private notes.
        </InfoText>
        <Button onClick={onClick}>Test video</Button>
        {isCohealer && <ChatAndGetStartedButtons page={'ProfilePage'} />}
      </VideoWrapper>
    </StyledDiv>
  );
};
