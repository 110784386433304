import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from 'utils/styles';
import { useMediaQuery, useTheme, makeStyles, Grid, Radio, TextField, FormControlLabel } from '@material-ui/core';
import { getThemedColors } from 'services/contributions.service';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { Autocomplete } from '@mui/material';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import './ClientsView.scss';

const useStyles = makeStyles(theme => ({
  inputRoot: {
    paddingRight: '0px !important',
  },
  endAdornment: {
    color: 'black !important',

    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  endAdornmentDark: {
    color: 'white !important',

    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
}));

export const StyledSlotContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 10px;
`;

export const StyledTimeSlots = styled.div`
  display: inline-block;
  width: 100px;
  height: 35px;
  border: 1px solid #fafafa;
  border-radius: 8px;
  margin: ${({ margin }) => (margin ? margin : '5px')};
  color: darkgrey;
  background-color: #fffff;

  &:hover {
    cursor: pointer;
  }

  ${({ mobileView }) =>
    mobileView &&
    `
      width: 80px;
  `}

  ${({ isSelected, colorForSelected }) =>
    isSelected &&
    `
    background-color: ${colorForSelected};
    border-color: ${colorForSelected};
    color: white;
  `}
  ${({ isSelected }) =>
    !isSelected &&
    `
    background-color: white;
  `}

  ${({ isBooked }) =>
    isBooked &&
    `
    color: ${colors.stroke};
    
    &:hover {
      cursor: default;
    }
  `}
`;
export const StyledTimeSlotValue = styled.div`
  font-weight: 500;
  font-size: 13px;
  margin: 7px 0;
  text-align: center;
`;

const StyledButton = styled.button`
  color: ${({ color }) => color};
  border: ${({ borderColor }) => `1px solid ${borderColor}`};
  background-color: ${({ backgroundColor }) => backgroundColor};

  ${({ mobileView }) =>
    mobileView &&
    `
    font-size: 12px;
    padding: 8px 16px;
    min-width: 8rem;
  `}
`;

const FieldLabel = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 500;
  line-height: 24.8px;
  text-align: left;
  ${({ textColor }) => textColor && `color: ${textColor};`};
`;

const NewClientContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 30px;
`;

const RadioSpan = styled.span`
  font-family: Avenir;
  font-size: 18px;
  font-weight: 800;
  line-height: 28.12px;
  text-align: left;
`;

const StyledRadio = styled(Radio)`
  // .MuiSvgIcon-root {
  //   height: 20px !important;
  // }
`;

const StyledTextField = styled(TextField)`
  margin-top: 0px;
  margin-bottom: 0px;

  .MuiInput-underline:before,
  .MuiInput-underline:after,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0px;
  }

  .MuiInput-root {
    border-radius: 4px;
    border: 1px solid #dfe3e4;
    padding: 0px 8px 0px 8px;
    height: 40px;
    margin-top: 0px;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FooterContainer = styled.div`
  margin-top: auto;
`;

const StyledAutocomplete = styled(Autocomplete)`
  position: relative;
  border-radius: 0.375rem;
  border: 1px solid var(--Cohere-Greys-Grey-Tint, #dfe3e4);

  .MuiSelect-icon {
    color: ${({ iconColor }) => iconColor};
  }

  .MuiSvgIcon-root {
    margin-right: 15px;
    margin-top: 5px;
  }

  .MuiAutocomplete-popupIndicatorOpen {
    .MuiSvgIcon-root {
      margin-right: -15px !important;
      margin-top: 0px !important;
    }
  }

  .MuiInput-underline:before,
  .MuiInput-underline:after,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0px;
  }

  .MuiInput-root {
    border-radius: 4px;
    border: 1px solid #dfe3e4;
    padding: 0px 8px 0px 8px;
    height: 40px;
  }

  .MuiAutocomplete-inputRoot {
    padding-right: 25px !important;
  }
`;

const ClientsView = React.memo(
  ({
    onClose,
    onSubmit,
    isExistingClient,
    setIsExistingClient,
    isDarkModeEnabled,
    colorToUse,
    paymentInfo,
    selectOptions,
    onOptionSelect,
    closeDropDown,
    formik,
    meetingForm,
    setMeetingForm,
    onChangeClient,
    filterClient,
    fromMasterCalendar,
  }) => {
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
    const currentContribution = useContribution();
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const { newThemedCardColor } = getThemedColors(currentContribution);
    const dropdownRef = useRef(null);
    const classes = useStyles();

    useEffect(() => {
      if (isDropdownVisible) {
        onOptionSelect(false);
      }
    }, [closeDropDown, isDropdownVisible]);

    const onConfirm = async () => {
      onSubmit();
    };

    return (
      <>
        <div
          className="one-one-session-slots-card"
          style={{
            backgroundColor: newThemedCardColor,
            marginTop: '0px',
            padding: '0px 0px 0px 15px',
            justifyContent: 'space-between',
            minHeight: '60vh',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <MainContainer>
            <div style={{ display: 'flex' }}>
              <p className="booking-title" style={{ color: colorToUse?.AccentColorCode }}>
                Select Your Client
              </p>
            </div>

            <>
              <Grid container style={{ flexDirection: 'column' }}>
                <div className="radio-icon-style" style={{ alignSelf: 'start' }}>
                  <FormControlLabel
                    control={
                      <StyledRadio
                        disabled={
                          paymentInfo?.paymentOptions?.length === 1 &&
                          paymentInfo?.paymentOptions?.includes('FreeSessionsPackage')
                        }
                        style={{ color: colorToUse?.AccentColorCode }}
                        color="primary"
                        checked={!isExistingClient}
                        onChange={e => {
                          formik.setFieldValue('client', '');
                          formik.setFieldValue('price', '');
                          if (
                            !(
                              paymentInfo?.paymentOptions?.length === 1 &&
                              paymentInfo?.paymentOptions?.includes('FreeSessionsPackage')
                            )
                          ) {
                            formik.setErrors({});
                            setIsExistingClient(false);
                          }
                          setMeetingForm({
                            ...meetingForm,
                            client: '',
                          });
                          onChangeClient();
                        }}
                      />
                    }
                    label={<RadioSpan>New Client</RadioSpan>}
                  />
                </div>
                {!isExistingClient && (
                  <NewClientContainer>
                    <FieldLabel textColor={colorToUse?.AccentColorCode}>Client Email</FieldLabel>
                    <StyledTextField
                      className={isDarkModeEnabled ? 'darkModeEnabled' : ''}
                      id="standard-full-width"
                      margin="0px"
                      fullWidth
                      disabled={isExistingClient}
                      type="email"
                      placeholder="example@cohere.live"
                      onChange={e => {
                        formik.setFieldValue('email', e?.target?.value);
                        formik.setFieldValue('price', '');
                      }}
                      value={formik.values.email}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label=" "
                    />
                    {!isExistingClient && formik?.touched?.email && formik?.errors?.email && (
                      <div>
                        <CommonErrorMessage message={formik?.errors?.email} align="left" color="red" />
                      </div>
                    )}
                    <FieldLabel textColor={colorToUse?.AccentColorCode} style={{ marginTop: '10px' }}>
                      Client Name
                    </FieldLabel>
                    <StyledTextField
                      value={formik?.values?.name}
                      onChange={e => {
                        formik.setFieldValue('name', e?.target?.value);
                        formik.setFieldValue('price', '');
                        setMeetingForm({
                          ...meetingForm,
                          name: e?.target?.value,
                        });
                      }}
                      id="standard-full-width"
                      placeholder="Example: John Doe"
                      fullWidth
                      className="coach-client-name-margins"
                      disabled={isExistingClient}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin="0px"
                      inputProps={{ maxLength: 250 }}
                    />
                    {!isExistingClient && formik?.touched?.name && formik?.errors?.name && (
                      <div>
                        <CommonErrorMessage message={formik?.errors?.name} align="left" color="red" />
                      </div>
                    )}
                  </NewClientContainer>
                )}
              </Grid>
              <Grid container style={{ marginTop: '16px' }}>
                <div className="radio-icon-style" style={{ alignSelf: 'start' }}>
                  <FormControlLabel
                    control={
                      <StyledRadio
                        color="primary"
                        checked={isExistingClient}
                        style={{ color: colorToUse?.AccentColorCode }}
                        disabled={selectOptions?.length === 0}
                        onChange={e => {
                          formik.setErrors({});
                          formik.setFieldValue('email', '');
                          formik.setFieldValue('name', '');
                          formik.setFieldValue('price', '');
                          setMeetingForm({
                            ...meetingForm,
                            name: '',
                            email: '',
                          });
                          setIsExistingClient(true);
                          formik.setErrors({});
                          formik.setTouched({ price: false });
                          filterClient();
                        }}
                      />
                    }
                    label={<RadioSpan>Existing Client</RadioSpan>}
                  />
                </div>
                {isExistingClient && (
                  <Grid container className="AutoSelectField" style={{ paddingLeft: 30, gap: '4px' }}>
                    {selectOptions?.length === 0 ? (
                      <div style={{ color: isDarkModeEnabled ? 'white' : 'black' }}>No Available Clients</div>
                    ) : (
                      <>
                        <FieldLabel textColor={colorToUse?.AccentColorCode}>Client Name</FieldLabel>
                        <Grid
                          item
                          xs={12}
                          style={{ cursor: 'pointer' }}
                          onClick={e => {
                            e.stopPropagation();
                            setIsDropdownVisible(!isDropdownVisible);
                            formik.setErrors({});
                            formik.setTouched({ price: false });
                          }}
                        >
                          <StyledAutocomplete
                            name="client"
                            fullWidth
                            classes={{
                              popper: `client-popper ${isDarkModeEnabled ? 'dark-theme-enabled' : ''}`,
                              popupIndicator: isDarkModeEnabled ? classes.endAdornmentDark : classes.endAdornment,
                              input: classes.input_root,
                              inputRoot: isDarkModeEnabled && classes.inputRoot,
                            }}
                            style={{
                              whiteSpace: 'break-spaces',
                            }}
                            onBlur={() => {
                              setIsDropdownVisible(false);
                            }}
                            className="AutocompleteField"
                            clearIcon={false}
                            error={false}
                            ref={dropdownRef}
                            open={isDropdownVisible}
                            title={
                              formik?.values?.client
                                ? `${formik.values.client.clientName} - ${formik.values.client.clientEmail}`
                                : ''
                            }
                            value={formik?.values?.client}
                            noOptionsText={
                              <p
                                className="dropdownList"
                                style={{
                                  margin: '5px',
                                  color: isDarkModeEnabled ? 'white' : 'black',
                                  fontSize: '0.875rem',
                                  fontFamily: 'Avenir',
                                  letterSpacing: '0.4px',
                                }}
                              >
                                No Client Found
                              </p>
                            }
                            getOptionLabel={option => {
                              if (option) {
                                return `(${option?.clientName}) - ${option?.clientEmail}`;
                              } else return '';
                            }}
                            renderOption={(props, option) =>
                              option?.clientName?.match(/^(.*?)\s*\((.*?)\)\s*(.*)$/) ? (
                                <div
                                  className="dropdownList"
                                  style={{
                                    cursor: 'pointer',
                                    borderTop: '1px solid #f2f2f2',
                                    padding: '4px',
                                    color: isDarkModeEnabled ? 'white' : 'black',
                                  }}
                                  ref={dropdownRef}
                                  onClick={() => {
                                    setIsDropdownVisible(!isDropdownVisible);
                                    onChangeClient(option);
                                    formik.setErrors({});
                                    formik.setTouched({ price: false });
                                    formik.setFieldValue('email', '');
                                    formik.setFieldValue('name', '');
                                    formik.setFieldValue('price', '');
                                    setMeetingForm({
                                      ...meetingForm,
                                      name: '',
                                      email: '',
                                      clientId: option.value,
                                      clientName: option.clientName.match(/^(.*?)\s*\((.*?)\)\s*(.*)$/)
                                        ? option.clientName?.match(/^(.*?)\s*\((.*?)\)\s*(.*)$/)[1]
                                        : option.clientName,
                                    });
                                    filterClient();
                                  }}
                                >
                                  <p
                                    className="dropdownList"
                                    style={{
                                      margin: '10px',
                                      color: 'rgba(0, 0, 0, 0.87)',
                                      color: isDarkModeEnabled ? 'white' : 'black',
                                      fontSize: '0.875rem',
                                      fontFamily: 'Avenir',
                                      letterSpacing: '0.4px',
                                    }}
                                  >
                                    ({option?.clientName?.match(/^(.*?)\s*\((.*?)\)\s*(.*)$/)[1]}{' '}
                                    <span
                                      className="dropdownList"
                                      style={{ color: isDarkModeEnabled ? 'white' : 'gray' }}
                                    >
                                      ({option?.clientName?.match(/^(.*?)\s*\((.*?)\)\s*(.*)$/)[2]})
                                    </span>
                                    ) - {option?.clientEmail}
                                  </p>
                                </div>
                              ) : (
                                <div
                                  className="dropdownList"
                                  style={{ borderTop: '1px solid #f2f2f2', cursor: 'pointer' }}
                                  onClick={() => {
                                    formik.setFieldValue('email', '');
                                    formik.setFieldValue('name', '');
                                    formik.setFieldValue('price', '');
                                    setMeetingForm({
                                      ...meetingForm,
                                      name: '',
                                      email: '',
                                      clientId: option.value,
                                      clientName: option.clientName.match(/^(.*?)\s*\((.*?)\)\s*(.*)$/)
                                        ? option.clientName?.match(/^(.*?)\s*\((.*?)\)\s*(.*)$/)[1]
                                        : option.clientName,
                                    });
                                    setIsDropdownVisible(!isDropdownVisible);
                                    onChangeClient(option);
                                    filterClient();
                                  }}
                                >
                                  <p
                                    className="dropdownList"
                                    style={{
                                      margin: '10px',
                                      color: isDarkModeEnabled ? 'white' : 'black',
                                      fontSize: '0.875rem',
                                      fontFamily: 'Avenir',
                                      letterSpacing: '0.4px',
                                    }}
                                  >
                                    ({option?.clientName}) - {option?.clientEmail}
                                  </p>
                                </div>
                              )
                            }
                            renderInput={params => (
                              <StyledTextField
                                {...params}
                                placeholder="Select Client"
                                onClick={() => {
                                  if (!isDropdownVisible) {
                                    setIsDropdownVisible(true);
                                    formik.setErrors({});
                                  }
                                }}
                                onChange={() => {
                                  if (!isDropdownVisible) {
                                    setIsDropdownVisible(true);
                                    formik.setErrors({});
                                  }
                                }}
                                classes={{
                                  root: classes.input_root,
                                }}
                              />
                            )}
                            options={selectOptions}
                            disabled={!isExistingClient}
                          />
                          {isExistingClient && formik?.touched?.client && formik?.errors?.client && (
                            <div>
                              <CommonErrorMessage message={formik?.errors?.client} align="left" color="red" />
                            </div>
                          )}
                        </Grid>
                      </>
                    )}
                  </Grid>
                )}
              </Grid>
            </>
          </MainContainer>
          <FooterContainer>
            <div className="easy-booking-slot-buttons" style={{ margin: '20px 0px' }}>
              <StyledButton
                backgroundColor="white"
                borderColor="black"
                color="black"
                isDarkModeEnabled={isDarkModeEnabled ?? false}
                onClick={onClose}
              >
                Back
              </StyledButton>
              <StyledButton
                backgroundColor={colorToUse?.PrimaryColorCode}
                borderColor={colorToUse?.PrimaryColorCode}
                color="white"
                isDarkModeEnabled={isDarkModeEnabled ?? false}
                onClick={onConfirm}
              >
                Next
              </StyledButton>
            </div>
          </FooterContainer>
        </div>
      </>
    );
  },
);

ClientsView.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onOptionSelect: PropTypes.func.isRequired,
  closeDropDown: PropTypes.bool.isRequired,
  isExistingClient: PropTypes.bool.isRequired,
  setIsExistingClient: PropTypes.func.isRequired,
  filterClient: PropTypes.func.isRequired,
  formik: PropTypes.shape({
    setErrors: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setTouched: PropTypes.func.isRequired,
    values: PropTypes.shape({
      client: PropTypes.oneOfType([
        PropTypes.shape({
          clientName: PropTypes.string.isRequired,
          clientEmail: PropTypes.string.isRequired,
        }),
        PropTypes.string,
      ]).isRequired,
      email: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.string.isRequired,
    }).isRequired,
    touched: PropTypes.shape({
      email: PropTypes.bool,
      name: PropTypes.bool,
      client: PropTypes.bool,
    }).isRequired,
    errors: PropTypes.shape({
      email: PropTypes.string,
      name: PropTypes.string,
      client: PropTypes.string,
    }).isRequired,
  }).isRequired,
  isDarkModeEnabled: PropTypes.bool.isRequired,
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      clientName: PropTypes.string.isRequired,
      clientEmail: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ).isRequired,
  meetingForm: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.oneOf([null])]).isRequired,
    clientName: PropTypes.string,
  }).isRequired,
  setMeetingForm: PropTypes.func.isRequired,
  onChangeClient: PropTypes.func.isRequired,
  paymentInfo: PropTypes.shape({
    paymentOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  colorToUse: PropTypes.shape({
    PrimaryColorCode: PropTypes.string.isRequired,
    AccentColorCode: PropTypes.string.isRequired,
  }).isRequired,
  fromMasterCalendar: PropTypes.bool.isRequired,
};

ClientsView.defaultProps = {};

export default ClientsView;
