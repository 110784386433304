import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { colors } from 'utils/styles';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';

import useDiscountCodes from 'hooks/useDiscountCodes';
import usePaidTier from 'hooks/usePaidTier';
import * as paidTierActions from 'actions/paidTierOptions';
import { setCohereAcademyStatus } from 'actions/update-user';
import * as paidTierService from 'services/paidTierOptions.service';
import { getBoughtByType } from 'services/contributions.service';
import * as paidTier from 'selectors/paidTier';
import '../../Payment.scss';
import { Link } from 'react-router-dom';
import { ROUTES, TOOLTIP } from 'constants.js';
import Button from 'components/FormUI/Button';
import { useMediaQuery, useTheme } from '@material-ui/core';
import CouponItem from './CouponItem';
import useShallowEqualSelector from 'hooks/useShallowEqualSelector';
import { PAID_TIER_TITLES } from 'constants.js';
import { ContributionType } from 'helpers/constants';
const CouponsCard = ({ setShowLoader = () => {} }) => {
  const { codes } = useDiscountCodes();
  const dispatch = useDispatch();
  // const { isLaunchPlan } = usePaidTier();
  const [isLoader, setIsLoader] = useState({
    paidTierPlans: false,
    activePaidTierPlan: false,
    academyMemberships: false,
    activeAcademyMemberships: false,
  });
  const getActivePaidTier = useCallback(() => paidTierService.getCurrentPlan(), []);
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  const academyContributions = useSelector(paidTier.cohereAcademyContributionsSelector);
  const getAllPaidTiers = useCallback(() => paidTierService.getPaidTierOptions(), []);

  const isLaunchPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch;
  // Correct behavior - 100%
  const filterMembership = useCallback(
    res => {
      const filteredMemberships = [];

      academyContributions.forEach(item => {
        const matchedMembership = res.find(el => el.id === item.id);

        if (matchedMembership && matchedMembership.isPurchased) {
          filteredMemberships.push(item);
        }
        return true;
      });

      return filteredMemberships;
    },
    [academyContributions],
  );

  const updateActiveContributionsState = useCallback(() => {
    return getBoughtByType(ContributionType.contributionMembership)
      .then(filterMembership)
      .then(matchedMemberships => {
        dispatch(paidTierActions.setActivePaidTierContributions(matchedMemberships));
      });
  }, [filterMembership]);

  useEffect(() => {
    // this is a temp solution, we need to find a way to not repopulate it again if not needed
    // TODO: don't get paid tiers plans if the right version for existing user is already loaded
    // if (paidTierPlans.length) {
    //   return;
    // }
    setIsLoader(s => ({ ...s, paidTierPlans: true }));

    getAllPaidTiers().then(data => {
      dispatch(paidTierActions.setPaidTierPlans(data));
      setIsLoader(s => ({ ...s, paidTierPlans: false }));
    });
  }, []);

  useEffect(() => {
    setIsLoader(s => ({ ...s, activePaidTierPlan: true }));

    getActivePaidTier().then(data => {
      dispatch(paidTierActions.setActivePaidTierPlan(data));
      setIsLoader(s => ({ ...s, activePaidTierPlan: false }));
    });
  }, []);

  useEffect(() => {
    if (activePaidTierCurrentPlan?.default) {
      dispatch(setCohereAcademyStatus(true));
    } else {
      dispatch(setCohereAcademyStatus(false));
    }
  }, [activePaidTierCurrentPlan, dispatch]);

  useEffect(() => {
    setIsLoader(s => ({ ...s, academyMemberships: true }));

    paidTierService.getAcademy().then(data => {
      dispatch(paidTierActions.setPaidTierContribution(data));
      setIsLoader(s => ({ ...s, academyMemberships: false }));
    });
  }, []);

  useEffect(() => {
    setIsLoader(s => ({ ...s, activeAcademyMemberships: true }));

    updateActiveContributionsState().then(() => {
      setIsLoader(s => ({ ...s, activeAcademyMemberships: false }));
    });
  }, []);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const renderPaidFeatureIcon = () => (
    <Tooltip
      title="Upgrade your account to start using Coupon Codes"
      arrow
      enterTouchDelay={TOOLTIP.ENTER_DELAY}
      leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
    >
      <InfoIcon htmlColor={colors.lightBrown} />
    </Tooltip>
  );

  return (
    <Grid item xs={10} classes={{ root: 'card-container' }}>
      <Card maxHeight>
        <CardHeader>
          <Grid container item md={12} xs={12} classes={{ root: 'rows' }}>
            <Grid container item md={6} xs={12}>
              <PageTitleSecond>Coupons {isLaunchPlan && renderPaidFeatureIcon()}</PageTitleSecond>
            </Grid>
            <Grid container item md={6} xs={12} justify={mobileView ? '' : 'flex-end'} alignContent="center">
              <Link to={ROUTES.ACCOUNT_COUPON_CREATE}>
                <Button autoWidth variant="primary" disabled={isLaunchPlan}>
                  Create New Coupon
                </Button>
              </Link>
            </Grid>
          </Grid>
        </CardHeader>
        <CardBody className="card-body-container">
          <Grid container alignItems="center">
            {codes.map(item => (
              <CouponItem coupon={item} title={item.name} isLaunchPlan={isLaunchPlan} codes={codes} />
            ))}
          </Grid>
        </CardBody>
      </Card>
    </Grid>
  );
};

export default CouponsCard;
