import useMakeStickyOnScroll from 'hooks/useMakeStickyOnScroll';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const StyledSeeMore = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledSeeMoreContainer = styled.div`
  position: absolute;
  background-color: ${({ isDarkThemeEnabled }) =>
    isDarkThemeEnabled ? 'rgba(74, 74, 74, 0.2)' : 'rgba(255, 255, 255, 0.2)'};
  color: ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? 'rgba(255, 255, 255, 1)' : 'rgba(74, 74, 74, 1)')};
  border: 1px solid rgba(223, 227, 228, 1);
  border-radius: 35px;
  display: flex;
  height: 40px;
  align-items: center;
  z-index: 100;
  padding: 10px;
  gap: 10px;
  font-weight: 800;
  font-size: 14px;
  bottom: 30px;
  backdrop-filter: blur(2px);

  svg {
    color: ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? 'rgba(255, 255, 255, 1)' : 'rgba(74, 74, 74, 1)')};
  }
`;

function SeeMore({ mobileView, isDarkThemeEnabled, parentContainerId }) {
  const { setTargetElement, setParentContainerId } = useMakeStickyOnScroll();
  const newPostsElement = useRef(null);

  useEffect(() => {
    if (newPostsElement.current) {
      setTargetElement(newPostsElement.current);
      setParentContainerId(parentContainerId);
    }
  }, [newPostsElement.current]);

  return (
    <StyledSeeMore ref={newPostsElement} mobileView={mobileView}>
      <StyledSeeMoreContainer isDarkThemeEnabled={isDarkThemeEnabled} mobileView={mobileView}>
        <ArrowDownwardIcon />
        <span style={{ fontSize: '14px', fontWeight: '600' }}>Scroll to see more</span>
      </StyledSeeMoreContainer>
    </StyledSeeMore>
  );
}

export default SeeMore;
