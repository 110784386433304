import React from 'react';
import PropTypes from 'prop-types';

import { StyledTitleForVideo, StyledVideo } from '../GettingStarted.styles';

const Invitation = ({ mobileView }) => {
  return (
    <>
      <StyledVideo controls="controls" preload="metadata">
        <source
          src={`https://coherepublic-prod.s3.amazonaws.com/DemoVideos/COHERE+INVITE+CLIENTS+TO+JOIN+(VIDEO+4_4).mp4`}
        />
      </StyledVideo>

      <StyledTitleForVideo mobileView={mobileView}>
        After you’ve created a Service, you can start inviting clients, make sales and earn revenue!
      </StyledTitleForVideo>
      <StyledTitleForVideo mobileView={mobileView}>
        When clients enroll into your Services they create their own client login that allows them to access your
        upcoming sessions, content, chat and more!
      </StyledTitleForVideo>
    </>
  );
};

Invitation.propTypes = {
  mobileView: PropTypes.bool,
};

Invitation.defaultProps = {
  mobileView: false,
};

export default Invitation;
