import React, { useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { Formik, getIn } from 'formik';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  SvgIcon,
  TextField,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/build/index.css';
import PropTypes, { array } from 'prop-types';
import styled, { css } from 'styled-components';
import { TemplateType } from 'helpers/constants/templateType';
import { darken } from 'polished';
import './EnrollmentForm.scss';
import { lightOrDark } from 'utils/utils';
import useAccount from 'hooks/useAccount';
import { determineDarkThemedColorToUse, getThemedColors, submitEnrollmentForm } from 'services/contributions.service';
import useRouter from 'hooks/useRouter';
import { useDefaultTimezone } from 'hooks';
import SeeMore from './PurchaseModal/SeeMore';

const StyledButton = styled.button`
  width: 100%;
  position: relative;
  padding: 17px 31px;
  font-size: 16px;
  border-radius: 8px;
  border: none;
  color: #fff;
  fill: #fff; /* for svg */
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  line-height: 1rem;
  letter-spacing: 1.25px;
  transition: background 0.2s;
  font-family: 'Avenir';
  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: 24px;
    `}

  ${({ variant, backgroundColor, activeTemplate }) => {
    if (backgroundColor) {
      return css`
        background-color: ${backgroundColor};
      `;
    }
    return activeTemplate === TemplateType.TemplateOne
      ? css`
          background-color: #6999ca;
        `
      : css`
          background-color: #b78aea;
        `;
  }}

  ${({ invert }) =>
    invert &&
    css`
      color: rgba(0, 0, 0, 0.87);
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.87);
    `}

  &:hover {
    ${({ variant, backgroundColor, activeTemplate }) => {
      if (backgroundColor) {
        return css`
          background-color: ${darken(0.05, backgroundColor)};
        `;
      }
      return activeTemplate === TemplateType.TemplateOne
        ? css`
            background-color: ${darken(0.05, '#6999CA')};
          `
        : css`
            background-color: ${darken(0.05, '#B78AEA')};
          `;
    }}

    ${({ invert }) =>
      invert &&
      css`
        background-color: ${darken(0.05, 'white')};
      `}
  }

  :disabled {
    background-color: #ededed;
    color: #9b9b9b;
    fill: #9b9b9b; /* for svg */
    cursor: not-allowed;
    border: none;
  }

  ${({ mobileView }) =>
    mobileView &&
    `
    font-size: 12px;
    padding: 8px 16px;
    min-width: 8rem;
  `}
`;

const StyledHeading = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  font-style: normal;
  font-size: 20px;
  font-weight: 400;

  ${({ easyBooking }) =>
    easyBooking &&
    css`
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: 800;
      line-height: 27px;
    `}

  color: ${props => {
    if (props.easyBooking) {
      return props.color || '#116582';
    }
    if (props.isDarkThemeEnabled) {
      return 'white';
    }
    return '#282b2b';
  }};
`;

const StyledSubHeading = styled.p`
  font-size: 14px;
  font-family: 'Poppins';
  font-weight: normal;
  margin-bottom: 24px;
  color: ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? 'white' : '#656d6d')};
`;

const CustomContainer = styled.div`
  background-color: #f7f9f9;
  padding: 32px 25px;
`;

const CustomRadio = styled(Radio)`
  .MuiIconButton-label {
    color: ${({ color }) => color};
  }
`;
const CustomCheckBox = styled(Checkbox)`
  .MuiIconButton-label {
    color: ${({ color }) => color};
  }
`;

const phoneUtil = PhoneNumberUtil.getInstance();
const validatePhoneNumber = phone => {
  if (phone === '') {
    return false;
  }
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const validationSchema = Yup.array().of(
  Yup.object().shape({
    id: Yup.string().required('Question ID is required'),
    name: Yup.string(),
    questionType: Yup.string().required('Question type is required'),
    options: Yup.mixed(),
    isOptional: Yup.boolean(),
    userResponse: Yup.mixed()
      .when(['questionType', 'isOptional'], {
        is: (questionType, isOptional) => (questionType === 'Radio' || questionType === 'Text') && !isOptional,
        then: Yup.string().required('This is a required field'),
      })
      .when(['questionType', 'isOptional'], {
        is: (questionType, isOptional) => questionType === 'PhoneNo' && !isOptional,
        then: Yup.string()
          .required('Phone number is a required field')
          .test('valid-phone', 'Invalid phone number', value => {
            return validatePhoneNumber(value);
          }),
      })
      .when(['questionType', 'isOptional'], {
        is: (questionType, isOptional) => questionType === 'CheckBox' && !isOptional,
        then: Yup.array().min(1).required('This is a required Field'),
      })
      .when(['questionType', 'isOptional'], {
        is: (questionType, isOptional) => questionType === 'Email' && !isOptional,
        then: Yup.string().email('Enter a valid email').required('Email is a required field'),
      }),
  }),
);

const EnrollmentForm = ({
  enrollmentForm,
  colorToUse,
  contribution,
  activeTemplate,
  style,
  onSubmitSuccess,
  onClose,
  selectedTimezone,
  timeZones,
  countries,
}) => {
  const phoneInputRef = useRef(null);
  const [questions, setQuestions] = useState([]);
  const [coachPhoneNumber, setCoachPhoneNumber] = useState('');
  const [coachPhoneNumberFocused, setCoachPhoneNumberFocused] = useState(false);
  const [defaultCountry, setDefaultCountry] = useState();
  const { newThemedCardColor } = getThemedColors(contribution);
  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);
  const { user } = useAccount();
  const { pathname } = useRouter();
  const { fetchUserCountryCode } = useDefaultTimezone();
  const [phoneInputKey, setPhoneInputKey] = useState(0);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const [showSeeMore, setShowSeeMore] = useState(true);
  const reserveButtonRef = useRef(null);

  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';

  const setDefaultCountryCode = async () => {
    const countryCode = await fetchUserCountryCode(selectedTimezone, timeZones, countries);
    setDefaultCountry(countryCode);
  };

  useEffect(() => {
    if (timeZones?.length > 0 && countries?.length > 0) setDefaultCountryCode();
  }, [selectedTimezone, timeZones, countries]);

  useEffect(() => {
    setQuestions(
      enrollmentForm?.questions.map(question => {
        return { ...question, userResponse: '' };
      }),
    );
  }, [enrollmentForm]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setShowSeeMore(!entry.isIntersecting);
      },
      { threshold: 0.5 },
    );

    if (reserveButtonRef.current) {
      observer.observe(reserveButtonRef.current);
    }

    return () => {
      if (reserveButtonRef.current) {
        observer.unobserve(reserveButtonRef.current);
      }
    };
  }, []);

  const applyFocusEventOnPhonInput = () => {
    if (phoneInputRef.current) {
      const phoneInput = phoneInputRef.current.querySelector(
        '.enrollment-phone-input .react-international-phone-input',
      );
      if (phoneInput) {
        phoneInput.addEventListener('focus', () => {
          setCoachPhoneNumberFocused(true);
        });
      }
      return phoneInput;
    }
    return null;
  };

  useEffect(() => {
    if (phoneInputRef.current) {
      const phoneInput = applyFocusEventOnPhonInput();
      return () => {
        if (phoneInput) {
          phoneInput.removeEventListener('focus', () => {
            setCoachPhoneNumberFocused(false);
            setCoachPhoneNumber('');
          });
        }
      };
    }
  }, [phoneInputRef.current, questions, coachPhoneNumber]);

  useEffect(() => {
    setPhoneInputKey(phoneInputKey + 1);
  }, [defaultCountry]);

  useEffect(() => {
    applyFocusEventOnPhonInput();
  }, [phoneInputKey]);

  useEffect(() => {
    if (coachPhoneNumberFocused && phoneInputRef.current) {
      const phoneInput = phoneInputRef.current.querySelector(
        '.enrollment-phone-input .react-international-phone-input',
      );
      if (phoneInput) {
        phoneInput.focus();
      }
    }
  }, [coachPhoneNumberFocused]);

  return (
    <>
      <CustomContainer
        style={{ backgroundColor: newThemedCardColor, ...style } || {}}
        className={`enrollment-form ${isDarkThemeEnabled ? 'dark-mode' : ''}`}
        id="enrollment-form-container"
      >
        {!pathname.includes('sessions') && (
          <div
            className="single-session-back-button"
            style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '15px' }}
          >
            <div role="button" tabIndex="0" onKeyDown={() => {}} style={{ cursor: 'pointer' }} onClick={onClose}>
              <b>{`< Back`}</b>
            </div>
          </div>
        )}
        <StyledHeading
          isDarkThemeEnabled={isDarkThemeEnabled}
          easyBooking={pathname.includes('sessions')}
          color={contribution?.brandingColors?.AccentColorCode}
        >
          {pathname.includes('sessions') ? (
            <>
              <SvgIcon
                style={{ width: '7px', height: '13px', cursor: 'pointer' }}
                viewBox="0 0 7 13"
                onClick={() => onClose()}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="7" height="13" viewBox="0 0 7 13" fill="none">
                  <path
                    d="M6 1.8501L1.35355 6.49654C1.15829 6.69181 1.15829 7.00839 1.35355 7.20365L6 11.8501"
                    stroke={contribution?.brandingColors?.AccentColorCode}
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </SvgIcon>{' '}
              Complete Your Enrollment
            </>
          ) : (
            'Complete Your Enrollment'
          )}
        </StyledHeading>

        {!pathname.includes('sessions') && (
          <StyledSubHeading isDarkThemeEnabled={isDarkThemeEnabled}>Enter your details</StyledSubHeading>
        )}
        <Formik
          initialValues={questions}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={values => {
            const newQuestions = values.map(question => {
              return { ...question, userResponse: question.userResponse !== '' ? question.userResponse : [] };
            });
            const data = {
              clientId: user?.id,
              userId: contribution.userId,
              formLinkId: enrollmentForm?.formLinkId,
              questions: newQuestions,
              clientName: user?.name,
              contributionId: contribution?.id,
              contributionName: contribution?.title,
            };
            submitEnrollmentForm(data).then(() => {
              onSubmitSuccess();
            });
          }}
        >
          {({ values, setFieldValue, touched, errors, handleSubmit }) => (
            <>
              <div className="enrollment-questions">
                {questions.map((question, questionIndex) => (
                  <>
                    {(question.questionType === 'Text' || question.questionType === 'Email') && (
                      <>
                        <FormControl component="fieldset" fullWidth>
                          <FormLabel
                            id={question.id}
                            style={{
                              color: isDarkThemeEnabled ? 'white' : '#213649',
                              fontSize: '18px',
                              fontWeight: '500',
                              fontFamily: 'Avenir',
                              marginBottom: '16px',
                              lineHeight: '1.5',
                            }}
                            component="legend"
                          >
                            {question.name} {question.isOptional ? ' (optional)' : ''}
                          </FormLabel>
                          <TextField
                            placeholder="Answer"
                            value={values[questionIndex]?.userResponse}
                            onChange={event => {
                              setFieldValue(`[${questionIndex}].userResponse`, [event.target.value]);
                            }}
                            fullWidth
                            multiline
                            maxRows={6}
                            variant="outlined"
                            id="margin-none"
                            error={
                              getIn(errors, `[${questionIndex}].userResponse`) &&
                              getIn(touched, `[${questionIndex}].userResponse`)
                            }
                          />
                          {getIn(errors, `[${questionIndex}].userResponse`) &&
                            getIn(touched, `[${questionIndex}].userResponse`) && (
                              <span className="text-danger">{getIn(errors, `[${questionIndex}].userResponse`)}</span>
                            )}
                        </FormControl>
                      </>
                    )}
                    {question.questionType === 'PhoneNo' && (
                      <>
                        <FormControl component="fieldset" fullWidth>
                          <FormLabel
                            id={question.id}
                            style={{
                              color: isDarkThemeEnabled ? 'white' : '#213649',
                              fontSize: '18px',
                              fontWeight: '500',
                              fontFamily: 'Avenir',
                              marginBottom: '16px',
                              lineHeight: '1.5',
                            }}
                            component="legend"
                          >
                            {question.name} {question.isOptional ? ' (optional)' : ''}
                          </FormLabel>
                          <div ref={phoneInputRef}>
                            {defaultCountry && (
                              <PhoneInput
                                key={coachPhoneNumberFocused + phoneInputKey}
                                value={coachPhoneNumber}
                                defaultCountry={defaultCountry}
                                {...(!coachPhoneNumberFocused && { disableDialCodePrefill: true })}
                                {...(coachPhoneNumberFocused && { forceDialCode: true })}
                                disableCountryGuess
                                className={`enrollment-phone-input ${
                                  getIn(errors, `[${questionIndex}].userResponse`) &&
                                  getIn(touched, `[${questionIndex}].userResponse`)
                                    ? 'phone-error'
                                    : ''
                                }`}
                                placeholder="Answer"
                                onChange={phone => {
                                  setFieldValue(`[${questionIndex}].userResponse`, [phone]);
                                  setCoachPhoneNumber(phone);
                                }}
                              />
                            )}
                            {getIn(errors, `[${questionIndex}].userResponse`) &&
                              getIn(touched, `[${questionIndex}].userResponse`) && (
                                <span className="text-danger">{getIn(errors, `[${questionIndex}].userResponse`)}</span>
                              )}
                          </div>
                        </FormControl>
                      </>
                    )}
                    {question.questionType === 'CheckBox' && (
                      <FormControl
                        component="fieldset"
                        error={
                          getIn(errors, `[${questionIndex}].userResponse`) &&
                          getIn(touched, `[${questionIndex}].userResponse`) &&
                          'This is a required field'
                        }
                        fullWidth
                        variant="standard"
                      >
                        <FormLabel
                          id={question.id}
                          style={{
                            color: isDarkThemeEnabled ? 'white' : '#213649',
                            fontSize: '18px',
                            fontWeight: '500',
                            fontFamily: 'Avenir',
                            marginBottom: '16px',
                            lineHeight: '1.5',
                          }}
                          component="legend"
                        >
                          {question.name} {question.isOptional ? ' (optional)' : ''}
                        </FormLabel>
                        <FormGroup>
                          <div className="options-container">
                            {question.options.map(option => (
                              <FormControlLabel
                                control={
                                  <CustomCheckBox
                                    color={contribution?.brandingColors?.AccentColorCode}
                                    disableRipple
                                    onChange={event => {
                                      let userResponse = values[questionIndex]?.userResponse;
                                      if (!userResponse) {
                                        userResponse = [];
                                      }
                                      if (event.target.checked) {
                                        setFieldValue(`[${questionIndex}].userResponse`, [...userResponse, option]);
                                      } else {
                                        setFieldValue(
                                          `[${questionIndex}].userResponse`,
                                          userResponse.filter(response => response !== option).length === 0
                                            ? null
                                            : userResponse.filter(response => response !== option),
                                        );
                                      }
                                    }}
                                    name={option}
                                  />
                                }
                                label={option}
                              />
                            ))}
                          </div>
                        </FormGroup>
                        <FormHelperText>
                          {getIn(errors, `[${questionIndex}].userResponse`) &&
                            getIn(touched, `[${questionIndex}].userResponse`) &&
                            'This is a required field'}
                        </FormHelperText>
                      </FormControl>
                    )}
                    {question.questionType === 'Radio' && (
                      <FormControl
                        error={
                          getIn(errors, `[${questionIndex}].userResponse`) &&
                          getIn(touched, `[${questionIndex}].userResponse`) &&
                          'This is a required field'
                        }
                        fullWidth
                      >
                        <FormLabel
                          id={question.id}
                          style={{
                            color: isDarkThemeEnabled ? 'white' : '#213649',
                            fontSize: '18px',
                            fontWeight: '500',
                            fontFamily: 'Avenir',
                            marginBottom: '16px',
                            lineHeight: '1.5',
                          }}
                        >
                          {question.name} {question.isOptional ? ' (optional)' : ''}
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby={question.id}
                          name="radio-buttons-group"
                          onChange={event => setFieldValue(`[${questionIndex}].userResponse`, [event.target.value])}
                        >
                          <div className="options-container">
                            {question.options.map(option => (
                              <FormControlLabel
                                value={option}
                                control={
                                  <CustomRadio color={contribution?.brandingColors?.AccentColorCode} disableRipple />
                                }
                                label={option}
                              />
                            ))}
                          </div>
                        </RadioGroup>
                        <FormHelperText>
                          {getIn(errors, `[${questionIndex}].userResponse`) &&
                            getIn(touched, `[${questionIndex}].userResponse`) &&
                            'This is a required field'}
                        </FormHelperText>
                      </FormControl>
                    )}
                  </>
                ))}
              </div>
              <StyledButton
                fullWidth
                style={{ color: textColor }}
                activeTemplate={activeTemplate}
                backgroundColor={colorToUse?.PrimaryColorCode}
                marginTop
                onClick={handleSubmit}
                ref={reserveButtonRef}
              >
                Reserve
              </StyledButton>
            </>
          )}
        </Formik>
      </CustomContainer>
      {showSeeMore && (
        <SeeMore
          isDarkThemeEnabled={isDarkThemeEnabled}
          mobileView={mobileView}
          parentContainerId={'enrollment-form-container'}
        />
      )}
    </>
  );
};

EnrollmentForm.propTypes = {
  enrollmentForm: PropTypes.shape({ questions: array }).isRequired,
  colorToUse: PropTypes.shape({}).isRequired,
  activeTemplate: PropTypes.shape({}).isRequired,
  contribution: PropTypes.shape({}).isRequired,
  style: PropTypes.shape({}).isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedTimezone: PropTypes.string.isRequired,
  timeZones: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default EnrollmentForm;
