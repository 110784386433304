import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { useMediaQuery, useTheme } from '@material-ui/core';

import Modal from 'components/UI/Modal';
import copyContributionLinkToClipboard from 'utils/copyContributionLinkToClipboard';
import { createAccessCode } from 'services/contributions.service';
import ShareModalView from './Content';
import InviteFriendModalView from './Content/InviteFriendModalView';
import { ContributionType } from 'helpers/constants';

const ShareModalContainer = ({
  isOpen,
  onModalClose,
  onSubmit,
  emailsError,
  setEmailsError,
  contributionId,
  type,
  paymentInfo,
  isInvoiced,
  symbol,
  IsModalForClient,
  inviteClientType,
}) => {
  const [emails, setEmails] = useState([]);
  const [priceId, setPriceid] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [premiumTooltipOpen, setPremiumTooltipOpen] = useState(false);
  const isOneToOneType = type === ContributionType.contributionOneToOne;
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCopyToClipboard = useCallback(
    (event, isApplication = false) => {
      if (event) event.preventDefault();
      copyContributionLinkToClipboard({ contributionId, isApplication });
      if (!isApplication) {
        setTooltipOpen(true);
      }
    },
    [contributionId],
  );

  const handlePremiumCopyToClipboard = useCallback(
    (event, isApplication = false, isEasyBooking = false) => {
      event.preventDefault();
      createAccessCode(contributionId).then(data => {
        copyContributionLinkToClipboard({ contributionId, code: data.code, isApplication, isEasyBooking });
      });
      if (!isApplication && !isEasyBooking) {
        setPremiumTooltipOpen(true);
      }
    },
    [contributionId],
  );

  const handleSubmit = useCallback(
    event => {
      event.preventDefault();

      onSubmit(emails, priceId);
    },
    [onSubmit, emails],
  );

  return (
    <>
      <Modal
        title={isOneToOneType ? 'Client Self-booking Links' : 'Share Service'}
        applyTheming
        isOpen={isOpen}
        onCancel={onModalClose}
        disableFooter
        // cancelTitle={mobileView ? 'Cancel' : 'Cancel Invitations'}
        submitTitle={mobileView ? 'Send' : 'Send Invitations'}
        maxWidth={mobileView ? '310px' : '800px'}
      >
        {!IsModalForClient ? (
          <ShareModalView
            emails={emails}
            contributionId={contributionId}
            paymentInfo={paymentInfo}
            type={type}
            applyTheming
            onSetEmails={setEmails}
            onCopyToClipboard={handleCopyToClipboard}
            onPremiumCopyToClipboard={handlePremiumCopyToClipboard}
            emailsError={emailsError}
            setEmailsError={setEmailsError}
            mobileView={mobileView}
            tooltipOpen={tooltipOpen}
            setTooltipOpen={setTooltipOpen}
            premiumTooltipOpen={premiumTooltipOpen}
            setPremiumTooltipOpen={setPremiumTooltipOpen}
            isInvoiced={isInvoiced}
            setPriceId={setPriceid}
            symbol={symbol}
            handleSubmitEmails={handleSubmit}
          />
        ) : (
          <InviteFriendModalView
            contributionId={contributionId}
            paymentInfo={paymentInfo}
            type={type}
            applyTheming
            onCopyToClipboard={handleCopyToClipboard}
            onPremiumCopyToClipboard={handlePremiumCopyToClipboard}
            mobileView={mobileView}
            tooltipOpen={tooltipOpen}
            setTooltipOpen={setTooltipOpen}
            premiumTooltipOpen={premiumTooltipOpen}
            setPremiumTooltipOpen={setPremiumTooltipOpen}
            isInvoiced={isInvoiced}
            setPriceId={setPriceid}
            symbol={symbol}
            handleSubmitEmails={handleSubmit}
            priceId={priceId}
            inviteClientType={inviteClientType}
          />
        )}
      </Modal>
    </>
  );
};

ShareModalContainer.propTypes = {
  onModalClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  emailsError: PropTypes.shape({
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
  }).isRequired,
  setEmailsError: PropTypes.func.isRequired,
  contributionId: PropTypes.string,
  inviteClientType: PropTypes.string,
};

ShareModalContainer.defaultProps = {
  contributionId: '',
  id: null,
};

export default ShareModalContainer;
