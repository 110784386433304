import React from 'react';

const BurgerMenuSvg = ({ width = '24px', height = '24px', color = '#C0A577' }) => {
  return (
    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="12" height="2" rx="1" fill={color} />
      <rect y="10" width="8" height="2" rx="1" fill={color} />
      <rect y="5" width="18" height="2" rx="1" fill={color} />
    </svg>
  );
};

export default BurgerMenuSvg;
